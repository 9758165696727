/* eslint-disable */
import * as Operation from './operations';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const GroupEssentialsFragmentDoc = gql`
    fragment groupEssentials on Group {
  id
  name
  slug
  hasSubgroups
  description
}
    `;
export const RoleEssentialsFragmentDoc = gql`
    fragment roleEssentials on Role {
  id
  name
  slug
}
    `;
export const GroupDetailsFragmentDoc = gql`
    fragment groupDetails on Group {
  ...groupEssentials
  subgroups {
    ...groupEssentials
  }
  permissions
  inheritedPermissions
  roles {
    ...roleEssentials
  }
}
    ${GroupEssentialsFragmentDoc}
${RoleEssentialsFragmentDoc}`;
export const PermissionEssentialsFragmentDoc = gql`
    fragment permissionEssentials on PermissionDetails {
  id
  resource
  slug
}
    `;
export const PermissionDetailsFragmentDoc = gql`
    fragment permissionDetails on PermissionDetails {
  ...permissionEssentials
  roles {
    ...roleEssentials
  }
}
    ${PermissionEssentialsFragmentDoc}
${RoleEssentialsFragmentDoc}`;
export const RoleDetailsFragmentDoc = gql`
    fragment roleDetails on Role {
  ...roleEssentials
  groups {
    ...groupEssentials
  }
  permissions
}
    ${RoleEssentialsFragmentDoc}
${GroupEssentialsFragmentDoc}`;
export const BackgroundJobEssentialsFragmentDoc = gql`
    fragment backgroundJobEssentials on Job {
  id
  guid
  accountId
  jobId
  jobType
  state
  lastError
  lastErrorAt
  cancelledAt
  completedAt
  createdAt
  updatedAt
  workableId
  workableType
  args
  jobStatus
  performAt
  queue
  updateTime
  worker
  jobInRedis
  rawJobData
  account {
    id
    subdomain
  }
}
    `;
export const PracticeSidekiqJobFragmentDoc = gql`
    fragment practiceSidekiqJob on Job {
  id
  guid
  state
  lastError
  completedAt
  createdAt
  jobStatus
}
    `;
export const PracticeLocationMinimalFragmentDoc = gql`
    fragment practiceLocationMinimal on PracticeLocation {
  id
  uuid
  name
  shortName
  colour
}
    `;
export const CustomImportDetailedFragmentDoc = gql`
    fragment customImportDetailed on CustomImport {
  id
  folderName
  practiceId
  practiceLocationId
  state
  jobId
  errorMessage
  importType
  importTask
  startedAt
  completedAt
  failedAt
  mailedAt
  createdAt
  updatedAt
  practiceLocation {
    ...practiceLocationMinimal
  }
  practice {
    id
    name
  }
}
    ${PracticeLocationMinimalFragmentDoc}`;
export const PracticeListFragmentDoc = gql`
    fragment practiceList on Practice {
  id
  uuid
  guid
  name
}
    `;
export const ImportFileEssentialsFragmentDoc = gql`
    fragment importFileEssentials on ImportFile {
  id
  state
  jobId
  rowsCount
  validCount
  invalidCount
  duplicateCount
  createdById
  updatedAt
  createdAt
  practiceLocation {
    ...practiceLocationMinimal
  }
  practice {
    ...practiceList
  }
}
    ${PracticeLocationMinimalFragmentDoc}
${PracticeListFragmentDoc}`;
export const AlertDetailedFragmentDoc = gql`
    fragment alertDetailed on Alert {
  id
  text
  icon
  colour
  active
  isSuper
  createdAt
  updatedAt
}
    `;
export const UserPracticeConnectionAlertDetailedFragmentDoc = gql`
    fragment userPracticeConnectionAlertDetailed on UserPracticeConnectionAlert {
  id
  alertId
  userPracticeConnectionId
  note
  createdAt
  updatedAt
  alert {
    ...alertDetailed
  }
}
    ${AlertDetailedFragmentDoc}`;
export const AppEnvFragmentDoc = gql`
    fragment appEnv on ApplicationEnvironment {
  bundleVersion
  commit
  version
  releaseTime
  environment
  onlineBookingBaseUrl
  thirdParty {
    appSignal {
      apiKey
    }
    aggrid {
      licenseKey
    }
    dynamsoft {
      twainProductKey
      twainOrgId
    }
    fullCalendar {
      licenseKey
    }
    syncFusion {
      licenseKey
      backendUrl
    }
    intercom {
      appId
    }
    tyro {
      apiKey
    }
    hicaps {
      bridgeVersion
    }
    stripe {
      publicKey
    }
  }
}
    `;
export const AvailabilityTimeslotFragmentDoc = gql`
    fragment availabilityTimeslot on CalendarTimeSlot {
  startTime
  endTime
  slot
  status
  practitioner {
    id
    guid
    practiceLocationId
    profile {
      id
      guid
      fullName
    }
  }
  rosterShift {
    id
    guid
    shiftType {
      id
      guid
      name
      colour
    }
  }
}
    `;
export const AvailabilityResultFragmentDoc = gql`
    fragment availabilityResult on CalendarAvailability {
  id
  timeslots {
    ...availabilityTimeslot
  }
}
    ${AvailabilityTimeslotFragmentDoc}`;
export const BadgesTemplatesTemplateTypeFragmentDoc = gql`
    fragment badgesTemplatesTemplateType on BadgesTemplateTypeTranslation {
  id
  type
  name
  description
  deprecated
}
    `;
export const BadgesTemplateMinimalFragmentDoc = gql`
    fragment badgesTemplateMinimal on BadgesTemplate {
  id
  slug
  name
  icon
  colour
}
    `;
export const UpcIdFragmentDoc = gql`
    fragment upcId on UserPracticeLink {
  id
  uuid
  guid
}
    `;
export const AvatarFragmentDoc = gql`
    fragment avatar on Avatar {
  id
  small
  medium
}
    `;
export const UserPracticeProfileMinimalFragmentDoc = gql`
    fragment userPracticeProfileMinimal on UserPracticeProfile {
  id
  uuid
  initials
  avatarColor
  fullNameWithTitle
  guid
  email
  title
  firstName
  lastName
  fullName
  preferredName
  middleName
  gender
}
    `;
export const UpcTinyFragmentDoc = gql`
    fragment upcTiny on UserPracticeLink {
  ...upcId
  slug
  practiceLocationId
  role {
    id
    name
    slug
  }
  photo {
    ...avatar
  }
  profile {
    ...userPracticeProfileMinimal
  }
}
    ${UpcIdFragmentDoc}
${AvatarFragmentDoc}
${UserPracticeProfileMinimalFragmentDoc}`;
export const UserPracticeConnectionMinimalFragmentDoc = gql`
    fragment userPracticeConnectionMinimal on UserPracticeLink {
  ...upcTiny
  state
  primaryPractitionerId
  secondaryPractitionerId
  practiceLocationId
  practiceId
  priceListId
  accountCredit
  createdAt
  scanIdentifier
  profile {
    ...userPracticeProfileMinimal
    mobilePhone
    age
    internationalMobile
    externalReference
    useExternalReference
    dateOfBirth
  }
}
    ${UpcTinyFragmentDoc}
${UserPracticeProfileMinimalFragmentDoc}`;
export const BadgesTemplateEssentialsFragmentDoc = gql`
    fragment badgesTemplateEssentials on BadgesTemplate {
  ...badgesTemplateMinimal
  badgeCount
  templateType
  discardedAt
  createdAt
  updatedAt
  practitioner {
    ...userPracticeConnectionMinimal
  }
  practiceGuid
  practitionerGuid
}
    ${BadgesTemplateMinimalFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const BadgesTemplateDetailedFragmentDoc = gql`
    fragment badgesTemplateDetailed on BadgesTemplate {
  ...badgesTemplateEssentials
}
    ${BadgesTemplateEssentialsFragmentDoc}`;
export const BadgeLinkMinimalFragmentDoc = gql`
    fragment badgeLinkMinimal on BadgesBadgeLink {
  id
  badgeableUuid
  template {
    ...badgesTemplateMinimal
  }
}
    ${BadgesTemplateMinimalFragmentDoc}`;
export const VisibilityFragmentDoc = gql`
    fragment visibility on VisibilityDefinition {
  id
  name
  description
  level
}
    `;
export const AttachmentMinimalFragmentDoc = gql`
    fragment attachmentMinimal on Attachment {
  id
  uuid
  guid
  createdAt
  updatedAt
  processing
  processedAt
  editable
  name
  mimeType
  directoryId
  fileExtension
  filesize
  visibility {
    ...visibility
  }
}
    ${VisibilityFragmentDoc}`;
export const TemplateVariableFragmentDoc = gql`
    fragment templateVariable on TemplateVariable {
  name
  group
  description
  exampleValue
  type
  contentType
  isList
  path
}
    `;
export const RichTextClassFragmentDoc = gql`
    fragment richTextClass on RichTextClassInfo {
  id
  name
  className
  description
  variables {
    ...templateVariable
  }
}
    ${TemplateVariableFragmentDoc}`;
export const RichTextMinimalFragmentDoc = gql`
    fragment richTextMinimal on RichText {
  id
  uuid
  guid
  classType {
    ...richTextClass
  }
  parentId
  recordUuid
  name
  template
  default
  docx
  png
  pdf
  createdAt
  updatedAt
}
    ${RichTextClassFragmentDoc}`;
export const BadgeableWithBadgesFragmentDoc = gql`
    fragment badgeableWithBadges on Badgeable {
  ... on Directory {
    id
    guid
    name
    path
    uuid
    isRoot
    badges {
      ...badgeLinkMinimal
    }
  }
  ... on Attachment {
    ...attachmentMinimal
    badges {
      ...badgeLinkMinimal
    }
  }
  ... on Appointment {
    id
    guid
    uuid
    badges {
      ...badgeLinkMinimal
    }
  }
  ... on RichText {
    ...richTextMinimal
    badges {
      ...badgeLinkMinimal
    }
  }
}
    ${BadgeLinkMinimalFragmentDoc}
${AttachmentMinimalFragmentDoc}
${RichTextMinimalFragmentDoc}`;
export const BadgeableFragmentDoc = gql`
    fragment badgeable on Badgeable {
  ... on Directory {
    guid
    name
    path
    uuid
    isRoot
  }
  ... on Attachment {
    ...attachmentMinimal
  }
}
    ${AttachmentMinimalFragmentDoc}`;
export const BadgeLinkEssentialsFragmentDoc = gql`
    fragment badgeLinkEssentials on BadgesBadgeLink {
  ...badgeLinkMinimal
  badgeable {
    ...badgeable
  }
}
    ${BadgeLinkMinimalFragmentDoc}
${BadgeableFragmentDoc}`;
export const AppointmentGuestTinyFragmentDoc = gql`
    fragment appointmentGuestTiny on AppointmentGuest {
  id
  uuid
  guid
  arrivedAt
  missedAt
  guestStatus: state
}
    `;
export const AppointmentGuestMinimalFragmentDoc = gql`
    fragment appointmentGuestMinimal on AppointmentGuest {
  ...appointmentGuestTiny
  state
  rejectedAt
  smsSentAt
  userPracticeConnectionId
  emailSentAt
  confirmedAt
  createdAt
  updatedAt
}
    ${AppointmentGuestTinyFragmentDoc}`;
export const AppointmentGuestListItemFragmentDoc = gql`
    fragment appointmentGuestListItem on AppointmentGuest {
  ...appointmentGuestMinimal
  appointment {
    id
    guid
    title
    startTime
    endTime
    duration
    practitionerName
  }
}
    ${AppointmentGuestMinimalFragmentDoc}`;
export const UserPracticeProfileEssentialsFragmentDoc = gql`
    fragment userPracticeProfileEssentials on UserPracticeProfile {
  ...userPracticeProfileMinimal
  externalReference
  useExternalReference
  gender
  dateOfBirth
  age
  internationalMobile
  homePhone
}
    ${UserPracticeProfileMinimalFragmentDoc}`;
export const UserPracticeConnectionBasicFieldsFragmentDoc = gql`
    fragment userPracticeConnectionBasicFields on UserPracticeLink {
  ...userPracticeConnectionMinimal
  slug
  state
  uuid
  practiceId
  priceListId
  priceList {
    id
    name
  }
  accountCredit
  isGlobalAdmin
  demo
  firstAppointmentTime
  firstVisitTime
  lastAppointmentTime
  lastVisitTime
  nextAppointmentTime
  lastMedicalFormTime
  lastXrayTime
  lastExamTime
  lastMessageAt
  lastMessageReadAt
  notesCount
  treatmentPlansCount
  emailsCount
  shortMessagesCount
  conversationsCount
  invoicesCount
  appointmentsCount
  recallsCount
  attachmentsCount
  unreadMessageCount
  familyId
  consecutiveMissedAppointmentsCount
  role {
    id
    name
    slug
  }
  photo {
    ...avatar
  }
  profile {
    ...userPracticeProfileEssentials
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}
${AvatarFragmentDoc}
${UserPracticeProfileEssentialsFragmentDoc}`;
export const LocationInfoFragmentDoc = gql`
    fragment locationInfo on Location {
  addressLine1
  addressLine2
  addressLine3
  city
  postcode
  country
  state
  formattedAddress
  placeId
  latitude
  longitude
}
    `;
export const LocationEssentialsFragmentDoc = gql`
    fragment locationEssentials on Location {
  id
  guid
  addressLines
  ...locationInfo
}
    ${LocationInfoFragmentDoc}`;
export const UserPracticeProfileDetailedFragmentDoc = gql`
    fragment userPracticeProfileDetailed on UserPracticeProfile {
  ...userPracticeProfileEssentials
  mobilePhone
  mobileRegionCode
  mobileRegionShort
  nationality
  occupation
  outdated
  onboarded
  title
  avatarColor
  workPhone
  calendarAvailability
  sendDailySummary
  dailySummaryEmail
  homeAddress {
    ...locationEssentials
  }
}
    ${UserPracticeProfileEssentialsFragmentDoc}
${LocationEssentialsFragmentDoc}`;
export const UserPracticeConnectionEssentialsFragmentDoc = gql`
    fragment userPracticeConnectionEssentials on UserPracticeLink {
  ...userPracticeConnectionBasicFields
  scanIdentifier
  recallDueTime
  recallVisitDueTime
  recallExamDueTime
  recallCompletedTime
  recallDropOffTime
  recallWorkflow {
    id
    guid
    name
  }
  user {
    id
    guid
    authenticatorEnabled
  }
  recallWorkflowId
  autoRecalls
  recallPeriod
  practice {
    id
    guid
    uuid
    name
  }
  practiceLocation {
    ...practiceLocationMinimal
  }
  account {
    id
    subdomain
  }
  metadata {
    id
    hasPublicHealthCare
    hasPrivateHealthCare
  }
  role {
    id
    slug
    name
  }
  hideEmptyNotesTitle
  primaryPractitionerId
  secondaryPractitionerId
  profile {
    ...userPracticeProfileDetailed
  }
  updatedProfile {
    ...userPracticeProfileDetailed
  }
}
    ${UserPracticeConnectionBasicFieldsFragmentDoc}
${PracticeLocationMinimalFragmentDoc}
${UserPracticeProfileDetailedFragmentDoc}`;
export const PracticeMinimalFragmentDoc = gql`
    fragment practiceMinimal on Practice {
  id
  uuid
  guid
  name
  phone
  email
  emailAccount
  website
  logo {
    id
    small
    medium
  }
}
    `;
export const PracticeConfigurationPaymentsFragmentDoc = gql`
    fragment practiceConfigurationPayments on PracticeConfiguration {
  id
  taxAmount
}
    `;
export const PracticeConfigurationContactFragmentDoc = gql`
    fragment practiceConfigurationContact on PracticeConfiguration {
  id
  openHours
  socialLinks {
    instagramUrl
    facebookUrl
    youtubeUrl
    linkedinUrl
  }
}
    `;
export const PracticeLocalizationLabelsFragmentDoc = gql`
    fragment practiceLocalizationLabels on LabelConfiguration {
  practitioner
  manager
  treatmentPlan
  appointment
  calendar
  client
  recall
  staff
}
    `;
export const PracticeAppointmentIconsFragmentDoc = gql`
    fragment practiceAppointmentIcons on AppointmentIconConfiguration {
  onlineBookings
  groupAppointment
  firstVisit
  outstandingBalance
  notes
  smartFill
  onlineMeetingLink
  confirmed
  communicationsFailed
  recallLinked
  labworkInProgress
  labworkSent
  labworkComplete
  depositPending
  depositPaid
  onlineBookingCredit
}
    `;
export const PracticeConfigurationLocalizationFragmentDoc = gql`
    fragment practiceConfigurationLocalization on PracticeConfiguration {
  id
  localization {
    id
    currency
    timezone
    defaultLocale
    labels {
      ...practiceLocalizationLabels
    }
    appointmentIcons {
      ...practiceAppointmentIcons
    }
    country
    mobileRegionShort
    mobileRegionCode
    taxCode
    taxAmount
  }
}
    ${PracticeLocalizationLabelsFragmentDoc}
${PracticeAppointmentIconsFragmentDoc}`;
export const PracticeConfigurationEssentialsFragmentDoc = gql`
    fragment practiceConfigurationEssentials on PracticeConfiguration {
  id
  hasSmsCredits
  hasEmailSettings
  patientCancelAppointment
  ...practiceConfigurationPayments
  ...practiceConfigurationContact
  ...practiceConfigurationLocalization
}
    ${PracticeConfigurationPaymentsFragmentDoc}
${PracticeConfigurationContactFragmentDoc}
${PracticeConfigurationLocalizationFragmentDoc}`;
export const OnlineBookingSettingsMinimalFragmentDoc = gql`
    fragment onlineBookingSettingsMinimal on OnlineBookingSetting {
  id
  guid
  enabled
  minCancellationNoticePeriod
  minCancellationNoticeFrequency
  smsNotifications
  emailNotifications
  requirePatientAddress
  requireDeposit
  termsAndConditions
  leadTimeToBookPeriod
  leadTimeToBookFrequency
  howFarCanBookPeriod
  howFarCanBookFrequency
  themeColor
  bookingInterval
  allowPendingPatients
  onlineBookingsEmail
  onlineBookingsMobileNumber
  redirectOnlineBookingsNotifications
  practiceLocationId
  successUrl
  defaultNewPatientState
  createdAt
  updatedAt
}
    `;
export const RecallSettingsFragmentDoc = gql`
    fragment recallSettings on RecallSettings {
  autoProcess
  recallTrigger
  triggerTime
  onlyActivePatients
  skipUpcoming
  skipActiveRecall
  skipWithDropOff
  missedAppointments
}
    `;
export const PracticeConfigurationBookingsFragmentDoc = gql`
    fragment practiceConfigurationBookings on PracticeConfiguration {
  id
  onlineBookingSettings {
    ...onlineBookingSettingsMinimal
  }
  recallSettings {
    ...recallSettings
  }
  allowedIpRange
  allowedIpRangeBypassBy
  patientCancelAppointment
  appointmentReminderScheduleInterval
  appointmentConfirmationScheduleInterval
  enableAppointmentConfirmations
  enableAppointmentEmailConfirmations
  enableAppointmentSmsConfirmations
  enableAppointmentEmailReminders
  enableAppointmentSmsReminders
  appointmentConfirmedNotification
  unconfirmedAppointmentSendReminders
  onlineBookingsNotifyPractitioner
  onlineBookingsNotifyPatient
  onlineBookingsAutoConfirm
  appointmentSendImmediateEmail
  appointmentSendImmediateSms
  enableAppointmentConfirmationsInterval
  onlineBookingsAutoConfirmInterval
  onlineBookingsNotifyPractitionerNewPatient
  enabledScanners
  lowCreditsWarning
  onlineAppointmentEmailConfirmations
  onlineAppointmentSmsConfirmations
  onlineAppointmentEmailReminders
  onlineAppointmentSmsReminders
}
    ${OnlineBookingSettingsMinimalFragmentDoc}
${RecallSettingsFragmentDoc}`;
export const PracticeConfigurationForPublicPagesFragmentDoc = gql`
    fragment practiceConfigurationForPublicPages on PracticeConfiguration {
  ...practiceConfigurationEssentials
  ...practiceConfigurationBookings
}
    ${PracticeConfigurationEssentialsFragmentDoc}
${PracticeConfigurationBookingsFragmentDoc}`;
export const CommunicationSettingsEssentialsFragmentDoc = gql`
    fragment communicationSettingsEssentials on CommunicationSettings {
  id
  communicationType
  email
  letters
  portal
  sms
}
    `;
export const InvoiceSettingsMinimalFragmentDoc = gql`
    fragment invoiceSettingsMinimal on InvoiceSetting {
  id
  guid
  default
  businessName
  abn
  invoiceFooter
  email
  website
  logo
  fullAddress
  defaultDueDateDaysFromNow
  phone
  discardedAt
  createdAt
  updatedAt
}
    `;
export const PracticeConfigurationExhaustiveFragmentDoc = gql`
    fragment practiceConfigurationExhaustive on PracticeConfiguration {
  ...practiceConfigurationForPublicPages
  enableAutoNotes
  publishDraftNotes
  autoCompleteRecalls
  formEmailNotifications
  depositReceiptEmails
  formCompletedUrl
  sendDailySummary
  dailySummaryEmails
  defaultCommunicationSettings {
    ...communicationSettingsEssentials
  }
  invoiceSettings {
    ...invoiceSettingsMinimal
  }
}
    ${PracticeConfigurationForPublicPagesFragmentDoc}
${CommunicationSettingsEssentialsFragmentDoc}
${InvoiceSettingsMinimalFragmentDoc}`;
export const AccountEssentialsFragmentDoc = gql`
    fragment accountEssentials on Account {
  id
  guid
  subdomain
  verified
}
    `;
export const AccountPlanEssentialsFragmentDoc = gql`
    fragment accountPlanEssentials on AccountPlan {
  id
  state
  isPremium
  storage
  maxLocation
  maxPatientCount
  maxPractitionerCount
  name
  planId
  smsCredits
  storageSizeUsed
  noOfLocationAdded
  noOfLicenseUsed
  patientsAdded
}
    `;
export const AccountPlanSubscriptionFragmentDoc = gql`
    fragment accountPlanSubscription on StripeSubscription {
  currentPeriodEnd
  currentPeriodStart
}
    `;
export const AccountPlanDetailedFragmentDoc = gql`
    fragment accountPlanDetailed on AccountPlan {
  ...accountPlanEssentials
  subscription {
    ...accountPlanSubscription
  }
}
    ${AccountPlanEssentialsFragmentDoc}
${AccountPlanSubscriptionFragmentDoc}`;
export const PracticeDetailedFragmentDoc = gql`
    fragment practiceDetailed on Practice {
  ...practiceMinimal
  activeRoomsCount
  coverPhoto {
    preview
    cover
  }
  industry {
    id
    name
  }
  location {
    ...locationEssentials
  }
  configuration {
    ...practiceConfigurationExhaustive
  }
  account {
    ...accountEssentials
  }
  accountPlan {
    ...accountPlanDetailed
  }
}
    ${PracticeMinimalFragmentDoc}
${LocationEssentialsFragmentDoc}
${PracticeConfigurationExhaustiveFragmentDoc}
${AccountEssentialsFragmentDoc}
${AccountPlanDetailedFragmentDoc}`;
export const AppointmentPublicPageFragmentDoc = gql`
    fragment appointmentPublicPage on Appointment {
  id
  description
  colour
  minutes
  title
  startTime
  endTime
  duration
  subject
  reference
  state
  userPracticeConnection {
    ...userPracticeConnectionMinimal
  }
  practice {
    ...practiceDetailed
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}
${PracticeDetailedFragmentDoc}`;
export const AppointmentGuestPublicPageFragmentDoc = gql`
    fragment appointmentGuestPublicPage on AppointmentGuest {
  ...appointmentGuestMinimal
  userPracticeConnection {
    ...userPracticeConnectionEssentials
  }
  appointment {
    ...appointmentPublicPage
  }
}
    ${AppointmentGuestMinimalFragmentDoc}
${UserPracticeConnectionEssentialsFragmentDoc}
${AppointmentPublicPageFragmentDoc}`;
export const CatalogueItemMinimalFragmentDoc = gql`
    fragment catalogueItemMinimal on CatalogueItem {
  id
  uuid
  guid
  name
  code
  type
  nameAndCode
  surfaceCount
  priceIncTax
}
    `;
export const CatalogueItemEssentialsFragmentDoc = gql`
    fragment catalogueItemEssentials on CatalogueItem {
  ...catalogueItemMinimal
  autoNote
  description
  active
  taxCode
  costPrice
  enableStock
  isPackage
  totalPrice
  priceExTax
  addedTax
  addedTaxPercentage
  taxAmount
  online
  prettyModality
  modality
  modalityCode
  minutes
  createdAt
  masterPrice
  isDental
  requiresFdi
  requiresSurfaceCode
  dentalTreatmentType
  dentalTeeth
  dentalJaw
  dentalPricing
  dentalClassification
  groupName
  healthFundItemNumber
  healthFundItemNumberWithModality
  updatesXray
  updatesExam
  requireDeposit
  depositAmount
  showTotalCost
}
    ${CatalogueItemMinimalFragmentDoc}`;
export const CatalogueItemAppointmentReasonDetailedFragmentDoc = gql`
    fragment catalogueItemAppointmentReasonDetailed on CatalogueItemAppointmentReason {
  id
  guid
  appointmentReasonId
  catalogueItemId
  price
  discount
  masterPrice
  priceIncTax
  minutes
  nameAndCode
  createdAt
  updatedAt
  prices {
    guid
    priceListId
    name
    priceIncTax
  }
  catalogueItem {
    ...catalogueItemEssentials
  }
}
    ${CatalogueItemEssentialsFragmentDoc}`;
export const AppointmentReasonListItemFragmentDoc = gql`
    fragment appointmentReasonListItem on AppointmentReason {
  id
  guid
  colour
  visible
  description
  minutes
  name
  autoInvoiceItems
}
    `;
export const TemplateBasicFragmentDoc = gql`
    fragment templateBasic on TemplateInterface {
  id
  guid
  uuid
  type
  name
}
    `;
export const CatalogueItemAppointmentReasonEssentialsFragmentDoc = gql`
    fragment catalogueItemAppointmentReasonEssentials on CatalogueItemAppointmentReason {
  id
  guid
  appointmentReasonId
  catalogueItemId
  price
  discount
  masterPrice
  priceIncTax
  minutes
  nameAndCode
  createdAt
  updatedAt
  prices {
    guid
    priceListId
    name
    priceIncTax
  }
  catalogueItem {
    ...catalogueItemEssentials
  }
}
    ${CatalogueItemEssentialsFragmentDoc}`;
export const AppointmentReasonsSelectFragmentDoc = gql`
    fragment appointmentReasonsSelect on AppointmentReason {
  ...appointmentReasonListItem
  catalogueItems {
    ...catalogueItemEssentials
  }
  createdTemplate {
    ...templateBasic
  }
  reminderTemplate {
    ...templateBasic
  }
  confirmationTemplate {
    ...templateBasic
  }
  catalogueItemAppointmentReasons {
    ...catalogueItemAppointmentReasonEssentials
  }
}
    ${AppointmentReasonListItemFragmentDoc}
${CatalogueItemEssentialsFragmentDoc}
${TemplateBasicFragmentDoc}
${CatalogueItemAppointmentReasonEssentialsFragmentDoc}`;
export const CalendarRosterEventShiftFragmentDoc = gql`
    fragment calendarRosterEventShift on RosterShift {
  id
  guid
  startTime
  endTime
  shiftType {
    name
    colour
  }
}
    `;
export const CalendarRosterEventFragmentDoc = gql`
    fragment calendarRosterEvent on RosterEvent {
  userPracticeConnectionId
  shifts {
    ...calendarRosterEventShift
  }
}
    ${CalendarRosterEventShiftFragmentDoc}`;
export const CalendarRosterDayFragmentDoc = gql`
    fragment calendarRosterDay on RosterDay {
  day
  events {
    ...calendarRosterEvent
  }
}
    ${CalendarRosterEventFragmentDoc}`;
export const AppointmentStatusHistoryFragmentDoc = gql`
    fragment appointmentStatusHistory on Appointment {
  id
  guid
  uuid
  state
  startTime
  endTime
  startedAt
  cancelledAt
  completedAt
  scheduledAt
  arrivedAt
  readyAt
  missedAt
  lateCancellationAt
  onHoldDate
  confirmedAt
  createdAt
  updatedAt
}
    `;
export const AppointmentPatientRecordItemEssentialsFragmentDoc = gql`
    fragment appointmentPatientRecordItemEssentials on PatientRecordItem {
  id
  code
  name
  state
  quantity
  total
  discount
  totalDiscount
  priceIncTax
  createdAt
  discardedAt
  updatedAt
  invoice {
    id
    invoiceState: state
    paid
    paidTotal
    balance
    issuedAt
  }
  userPracticeConnection {
    guid
    slug
  }
  invoiceItem {
    id
    total
    quantity
    discount
    totalDiscount
    invoiceId
    discardedAt
  }
}
    `;
export const AppointmentStateEventFragmentDoc = gql`
    fragment appointmentStateEvent on Appointment {
  ...appointmentStatusHistory
  patientRecordItems {
    ...appointmentPatientRecordItemEssentials
  }
}
    ${AppointmentStatusHistoryFragmentDoc}
${AppointmentPatientRecordItemEssentialsFragmentDoc}`;
export const AppointmentListFragmentDoc = gql`
    fragment appointmentList on Appointment {
  id
  uuid
  guid
  state
  startTime
  endTime
  title
  subject
  description
  guestNames
  guestIds
  guestContactNumbers
  guestContactEmails
  minutes
  duration
  colour
  practitionerName
}
    `;
export const AppointmentTinyFragmentDoc = gql`
    fragment appointmentTiny on Appointment {
  id
  slug
  uuid
  guid
  subject
  startTime
  endTime
  createdAt
  updatedAt
  title
  practitionerName
  guestNames
  guestIds
  duration
  colour
  state
  position
  userPracticeConnectionId
  roomId
}
    `;
export const AppointmentCalendarEventFragmentDoc = gql`
    fragment appointmentCalendarEvent on Appointment {
  ...appointmentTiny
  state
  appNotes
  notes
  privateNote
  guestContactNumbers
  guestContactEmails
  minutes
  estimatedMinutes
  allowReminderEmail
  allowReminderSms
  allowConfirmationEmail
  allowConfirmationSms
  allowAppointmentCreatedEmail
  allowAppointmentCreatedSms
  sendConfirmationDaysBefore
  sendReminderDaysBefore
  waitlistId
  reminderTemplateId
  confirmationTemplateId
  appointmentCreatedTemplateId
  appointmentUpdatedTemplateId
  onHoldDate
  onHoldExpired
  isFirstVisit
  isConfirmed
  isConfirmationRequired
  isGuestAppointment
  isPrivateAppointment
  isGroupAppointment
  isOnlineBooking
  isOnlineMeeting
  autoInvoice
  isCommunicationDelivered
  isCommunicationFailed
  patientHasOutstandingBalance
  smartFill
  labworksCompleteCount
  labworksSentCount
  labworksInProgressCount
  requestsInProgressCount
  requestsCompletedCount
  onlineBookingCreditGuid
  billablesReadyToInvoiceTotal
  badgeSlugs
}
    ${AppointmentTinyFragmentDoc}`;
export const ReadyToBookAppointmentCalendarEventFragmentDoc = gql`
    fragment readyToBookAppointmentCalendarEvent on Appointment {
  ...appointmentCalendarEvent
  appointmentGuests {
    userPracticeConnectionId
  }
}
    ${AppointmentCalendarEventFragmentDoc}`;
export const ContactEssentialsFragmentDoc = gql`
    fragment contactEssentials on Contact {
  id
  guid
  relayCommunications
  userPracticeConnection {
    ...userPracticeConnectionMinimal
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;
export const UserPracticeConnectionCommunicationEssentialsFragmentDoc = gql`
    fragment userPracticeConnectionCommunicationEssentials on UserPracticeLink {
  ...userPracticeConnectionMinimal
  contacts {
    ...contactEssentials
  }
  communicationSettings {
    id
    communicationType
    email
    letters
    portal
    sms
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}
${ContactEssentialsFragmentDoc}`;
export const AppointmentGuestEssentialsFragmentDoc = gql`
    fragment appointmentGuestEssentials on AppointmentGuest {
  ...appointmentGuestMinimal
  userPracticeConnection {
    ...userPracticeConnectionCommunicationEssentials
  }
}
    ${AppointmentGuestMinimalFragmentDoc}
${UserPracticeConnectionCommunicationEssentialsFragmentDoc}`;
export const AppointmentGuestDetailsFragmentDoc = gql`
    fragment appointmentGuestDetails on Appointment {
  id
  uuid
  appointmentGuests {
    ...appointmentGuestEssentials
  }
}
    ${AppointmentGuestEssentialsFragmentDoc}`;
export const UnlinkedAppointmentFragmentDoc = gql`
    fragment unlinkedAppointment on Appointment {
  id
  guid
  title
  description
  startTime
  state
  practitionerName
  duration
  appNotes
  colour
}
    `;
export const AppointmentMinimalFragmentDoc = gql`
    fragment appointmentMinimal on Appointment {
  id
  uuid
  guid
  title
  slug
  confirmedGuestCount
  guestNames
  guestIds
  guestContactNumbers
  guestContactEmails
  practitionerName
  notes
  privateNote
  duration
  subject
  state
  colour
  userPracticeConnectionId
  minutes
  position
  estimatedMinutes
  isConfirmed
  isOnlineBooking
  isOnlineMeeting
  isCommunicationDelivered
  isCommunicationFailed
  labworksInProgressCount
  labworksSentCount
  labworksCompleteCount
  requestsInProgressCount
  requestsCompletedCount
  onlineBookingCreditGuid
  labworksCount
  billablesReadyToInvoiceTotal
  isFirstVisit
  patientHasOutstandingBalance
  smartFill
  reference
  startTime
  endTime
  isConfirmationRequired
  allowReminderEmail
  allowReminderSms
  allowConfirmationEmail
  allowConfirmationSms
  allowAppointmentCreatedEmail
  allowAppointmentCreatedSms
  groupMinSize
  groupMaxSize
  createdAt
  updatedAt
  onHoldDate
  onHoldExpired
  finishedAt
  description
  confirmedAt
  appointmentReasonId
  autoInvoice
  waitlistId
  proposedDate
  appNotes
  reason
  roomId
  recallId
  isGuestAppointment
  isPrivateAppointment
  isGroupAppointment
  badgeSlugs
  startedAt
  cancelledAt
  completedAt
  scheduledAt
  arrivedAt
  readyAt
  missedAt
  lateCancellationAt
}
    `;
export const UserPracticeConnectionSearchFragmentDoc = gql`
    fragment userPracticeConnectionSearch on UserPracticeLink {
  ...upcId
  slug
  state
  practiceLocationId
  primaryPractitionerId
  secondaryPractitionerId
  createdAt
  photo {
    ...avatar
  }
  metadata {
    hasPublicHealthCare
    hasPrivateHealthCare
  }
  profile {
    id
    initials
    fullName
    fullNameWithTitle
    preferredName
    email
    internationalMobile
    dateOfBirth
    externalReference
  }
  lastAppointmentTime
  nextAppointmentTime
  firstAppointmentTime
  firstVisitTime
  lastVisitTime
  lastMedicalFormTime
  lastXrayTime
  lastExamTime
}
    ${UpcIdFragmentDoc}
${AvatarFragmentDoc}`;
export const AppointmentAgGridFragmentDoc = gql`
    fragment appointmentAgGrid on Appointment {
  ...appointmentMinimal
  appointmentGuests {
    ...appointmentGuestTiny
    userPracticeConnection {
      ...userPracticeConnectionSearch
    }
  }
}
    ${AppointmentMinimalFragmentDoc}
${AppointmentGuestTinyFragmentDoc}
${UserPracticeConnectionSearchFragmentDoc}`;
export const AppointmentHistoryFragmentDoc = gql`
    fragment appointmentHistory on Appointment {
  id
  slug
  uuid
  guid
  state
  startTime
  endTime
  duration
  guestNames
  guestIds
  room {
    id
    guid
    name
  }
  title
  colour
  isOnlineBooking
  isConfirmed
  scheduledAt
  createdAt
  cancellationReason {
    name
  }
  treatment {
    slug
  }
  treatmentPlan {
    slug
  }
  practitionerName
  practitioner {
    id
    guid
    practiceLocationId
  }
}
    `;
export const AppointmentReasonEssentialsFragmentDoc = gql`
    fragment appointmentReasonEssentials on AppointmentReason {
  id
  guid
  reasonState: state
  onlineBookingEnabled
  requireDeposit
  depositAmount
  showTotalCost
  allowSmsConfirmation
  autoInvoiceItems
  colour
  visible
  description
  coverPhoto {
    preview
    cover
  }
  minutes
  name
}
    `;
export const TemplateGroupTranslationFragmentDoc = gql`
    fragment templateGroupTranslation on TemplateGroupTranslation {
  id
  name
  slug
  description
}
    `;
export const TemplateTypeTranslationFragmentDoc = gql`
    fragment templateTypeTranslation on TemplateTypeTranslation {
  id
  type
  name
  description
  deprecated
  group {
    ...templateGroupTranslation
  }
  drop {
    name
    type
    description
    accepts
  }
}
    ${TemplateGroupTranslationFragmentDoc}`;
export const TemplateInterfaceFragmentDoc = gql`
    fragment templateInterface on TemplateInterface {
  id
  guid
  uuid
  description
  type
  state
  name
  default
  createdAt
  group {
    ...templateGroupTranslation
  }
  typeDefinition {
    ...templateTypeTranslation
  }
}
    ${TemplateGroupTranslationFragmentDoc}
${TemplateTypeTranslationFragmentDoc}`;
export const AttachmentEssentialsFragmentDoc = gql`
    fragment attachmentEssentials on Attachment {
  ...attachmentMinimal
  description
  isImage
  textContent
  badges {
    ...badgeLinkMinimal
  }
  attachment {
    url
    mediumUrl
    largeUrl
    xLargeUrl
  }
  patient {
    ...userPracticeConnectionMinimal
  }
  practitioner {
    ...userPracticeConnectionMinimal
  }
}
    ${AttachmentMinimalFragmentDoc}
${BadgeLinkMinimalFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const CommunicationTemplateEssentialsFragmentDoc = gql`
    fragment communicationTemplateEssentials on CommunicationTemplate {
  ...templateInterface
  subject
  attachments {
    ...attachmentEssentials
  }
  html
  plainText
}
    ${TemplateInterfaceFragmentDoc}
${AttachmentEssentialsFragmentDoc}`;
export const FormTemplateEssentialsFragmentDoc = gql`
    fragment formTemplateEssentials on FormTemplate {
  ...templateInterface
  communicationTemplate {
    ...communicationTemplateEssentials
  }
  numCompleted
  practiceId
  numSent
  isMedical
  html
  plainText
  subject
}
    ${TemplateInterfaceFragmentDoc}
${CommunicationTemplateEssentialsFragmentDoc}`;
export const TemplateDropConfigFragmentDoc = gql`
    fragment templateDropConfig on LiquidDropField {
  id
  key
  name
  drop
}
    `;
export const DocumentTemplateEssentialsFragmentDoc = gql`
    fragment documentTemplateEssentials on DocumentTemplate {
  ...templateInterface
  html
  practiceId
  drops {
    ...templateDropConfig
  }
}
    ${TemplateInterfaceFragmentDoc}
${TemplateDropConfigFragmentDoc}`;
export const TemplateEssentialsFragmentDoc = gql`
    fragment templateEssentials on TemplateInterface {
  ... on CommunicationTemplate {
    ...communicationTemplateEssentials
  }
  ... on FormTemplate {
    ...formTemplateEssentials
  }
  ... on DocumentTemplate {
    ...documentTemplateEssentials
  }
}
    ${CommunicationTemplateEssentialsFragmentDoc}
${FormTemplateEssentialsFragmentDoc}
${DocumentTemplateEssentialsFragmentDoc}`;
export const AppointmentReasonWithTemplatesFragmentDoc = gql`
    fragment appointmentReasonWithTemplates on AppointmentReason {
  ...appointmentReasonEssentials
  reminderTemplate {
    ...templateEssentials
  }
  confirmationTemplate {
    ...templateEssentials
  }
  createdTemplate {
    ...templateEssentials
  }
  updatedTemplate {
    ...templateEssentials
  }
}
    ${AppointmentReasonEssentialsFragmentDoc}
${TemplateEssentialsFragmentDoc}`;
export const AppointmentReasonDetailedFragmentDoc = gql`
    fragment appointmentReasonDetailed on AppointmentReason {
  ...appointmentReasonWithTemplates
  practitioners {
    ...userPracticeConnectionMinimal
  }
  catalogueItems {
    ...catalogueItemEssentials
  }
  catalogueItemAppointmentReasons {
    ...catalogueItemAppointmentReasonEssentials
  }
}
    ${AppointmentReasonWithTemplatesFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${CatalogueItemEssentialsFragmentDoc}
${CatalogueItemAppointmentReasonEssentialsFragmentDoc}`;
export const PracticeLocationEssentialsFragmentDoc = gql`
    fragment practiceLocationEssentials on PracticeLocation {
  ...practiceLocationMinimal
  logo
  default
  coverPhoto
  website
  phone
  openingHours
  discardedAt
  createdAt
  updatedAt
  address {
    addressLine1
    addressLine2
    addressLine3
    city
    postcode
    state
    country
    latitude
    longitude
    placeId
    formattedAddress
  }
  socialLinks {
    linkedinUrl
    facebookUrl
    youtubeUrl
    instagramUrl
    xUrl
  }
  practice {
    id
    guid
    name
  }
}
    ${PracticeLocationMinimalFragmentDoc}`;
export const InvoiceEssentialsFragmentDoc = gql`
    fragment invoiceEssentials on Invoice {
  id
  uuid
  guid
  businessName
  abn
  website
  phone
  issuedAt
  invoiceState: state
  total
  totalCost
  balance
  tax
  paid
  paidTotal
  discount
  totalExTax
  discountAmount
  quantity
  providerName
  providerId
  dueDate
  invoiceFooter
  fullAddress
  billTo
  remindDaysAfterDue
  createTime: createdAt
  lastSentAt
  practiceLocationId
  visibility {
    ...visibility
  }
  patient {
    ...userPracticeConnectionMinimal
  }
  practiceLocation {
    ...practiceLocationEssentials
  }
  practice {
    overseas
    ...practiceMinimal
  }
}
    ${VisibilityFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${PracticeLocationEssentialsFragmentDoc}
${PracticeMinimalFragmentDoc}`;
export const TreatmentMinimalFragmentDoc = gql`
    fragment treatmentMinimal on Treatment {
  id
  guid
  uuid
  title
  treatmentSlug: slug
  treatmentState: state
  practitionerId
  patientId
  dateStarted
  updatedAt
  createdAt
  referralId
}
    `;
export const TreatmentPlanMinimalFragmentDoc = gql`
    fragment treatmentPlanMinimal on TreatmentPlan {
  id
  guid
  uuid
  slug
  treatmentPlanState: state
  name
  proposedCost
  completedCost
  totalCost
  totalCount
  completedItemsCount
  proposedItemsCount
  visibility {
    ...visibility
  }
  signature
}
    ${VisibilityFragmentDoc}`;
export const TreatmentPlanEssentialsFragmentDoc = gql`
    fragment treatmentPlanEssentials on TreatmentPlan {
  ...treatmentPlanMinimal
  treatmentPlanState: state
  colour
  treatmentId
  dateStarted
  totalCost
  totalCount
  proposedItemsCount
  completedItemsCount
  requiredVisits
  completionPercentage
  pdfIncludeChart
  pdfIncludePerioChart
  pdfShowIncompleteOnly
  pdfShowUnassigned
  pdfIncludeNotes
  pdfChartGeneratedAt
  pdfSummary
  pdfChartGeneratedAt
  acceptedAt
  rejectedAt
  chartImage {
    url
    coverUrl
    previewUrl
  }
  pdf {
    attachment {
      url
    }
  }
  createdAt
  updatedAt
}
    ${TreatmentPlanMinimalFragmentDoc}`;
export const TreatmentEssentialsFragmentDoc = gql`
    fragment treatmentEssentials on Treatment {
  ...treatmentMinimal
  treatmentPlans {
    ...treatmentPlanEssentials
  }
  patient {
    ...userPracticeConnectionMinimal
  }
  practitioner {
    ...userPracticeConnectionMinimal
  }
}
    ${TreatmentMinimalFragmentDoc}
${TreatmentPlanEssentialsFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const AppointmentGuestDetailedFragmentDoc = gql`
    fragment appointmentGuestDetailed on AppointmentGuest {
  ...appointmentGuestMinimal
  userPracticeConnectionId
  userPracticeConnection {
    ...userPracticeConnectionEssentials
  }
}
    ${AppointmentGuestMinimalFragmentDoc}
${UserPracticeConnectionEssentialsFragmentDoc}`;
export const AppointmentCalendarEventDetailedFragmentDoc = gql`
    fragment appointmentCalendarEventDetailed on Appointment {
  ...appointmentMinimal
  appointmentReason {
    ...appointmentReasonDetailed
  }
  userPracticeConnection {
    ...userPracticeConnectionMinimal
  }
  invoices {
    ...invoiceEssentials
  }
  treatment {
    ...treatmentEssentials
  }
  treatmentPlan {
    ...treatmentPlanEssentials
  }
  appointmentGuests {
    ...appointmentGuestDetailed
  }
  appointmentReason {
    ...appointmentReasonDetailed
  }
  badges {
    ...badgeLinkMinimal
  }
}
    ${AppointmentMinimalFragmentDoc}
${AppointmentReasonDetailedFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${InvoiceEssentialsFragmentDoc}
${TreatmentEssentialsFragmentDoc}
${TreatmentPlanEssentialsFragmentDoc}
${AppointmentGuestDetailedFragmentDoc}
${BadgeLinkMinimalFragmentDoc}`;
export const UserPracticeConnectionRecallsFragmentDoc = gql`
    fragment userPracticeConnectionRecalls on UserPracticeLink {
  ...userPracticeConnectionMinimal
  recallDueTime
  recallVisitDueTime
  recallExamDueTime
  recallCompletedTime
  recallDropOffTime
  autoRecalls
  recallWorkflowId
  recallPeriod
  appointmentsCount
  firstAppointmentTime
  firstVisitTime
  lastAppointmentTime
  lastVisitTime
  nextAppointmentTime
  lastMedicalFormTime
  lastXrayTime
  lastExamTime
  consecutiveMissedAppointmentsCount
  priceList {
    id
    guid
    name
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;
export const RecallEssentialsFragmentDoc = gql`
    fragment recallEssentials on Recall {
  id
  uuid
  guid
  name
  recallState: state
  recallType
  stateLabel
  beginAt
  endAt
  expiredAt
  period
  frequency
  recalled
  reminderFrequency
  reminderIncrement
  reminderPeriodLabel
  remindersNumber
  recallCompletedAt: completedAt
  scheduledDropOff
  recallCancelledAt: cancelledAt
  createdAt
  updatedAt
  dueDate
  colour
  canCancel
  canComplete
  smsContent
  emailContent
  recallable {
    __typename
    ... on Practice {
      id
    }
    ... on UserPracticeLink {
      ...userPracticeConnectionRecalls
    }
    ... on Patient {
      id
      lastName
      firstName
    }
    ... on PatientRecord {
      id
      email
      firstName
      lastName
      patient {
        id
        firstName
        lastName
      }
    }
    ... on Treatment {
      id
      treatmentSlug: slug
      treatmentState: state
      title
      dateStarted
      updatedAt
      createdAt
      primaryPractitioner {
        id
        firstName
        lastName
        fullNameWithTitle
        emailAccount
        phone
        mobile
        avatarColor
      }
    }
  }
}
    ${UserPracticeConnectionRecallsFragmentDoc}`;
export const AppointmentItemEssentialsFragmentDoc = gql`
    fragment appointmentItemEssentials on AppointmentItem {
  id
  quantity
  tax
  price
  discount
  priceIncTax
  priceExTax
  total
  totalExTax
  totalDiscount
  totalTax
  minutes
  catalogueItem {
    ...catalogueItemEssentials
  }
}
    ${CatalogueItemEssentialsFragmentDoc}`;
export const RoomEssentialsFragmentDoc = gql`
    fragment roomEssentials on Room {
  id
  name
  state
  practiceLocationId
}
    `;
export const AppointmentExhaustiveFragmentDoc = gql`
    fragment appointmentExhaustive on Appointment {
  ...appointmentCalendarEventDetailed
  dailySmsBudget
  smsMessage
  emailMessage
  dailyEmailBudget
  batchSize
  isPrivateAppointment
  reminderSmsState
  reminderEmailState
  onlineMeetingUrl
  treatment {
    ...treatmentEssentials
  }
  treatmentPlan {
    ...treatmentPlanEssentials
  }
  patientRecordItems {
    ...appointmentPatientRecordItemEssentials
  }
  recall {
    ...recallEssentials
  }
  billableItems {
    ...appointmentItemEssentials
  }
  reminderTemplate {
    ...templateBasic
  }
  confirmationTemplate {
    ...templateBasic
  }
  appointmentCreatedTemplate {
    ...templateBasic
  }
  appointmentUpdatedTemplate {
    ...templateBasic
  }
  room {
    ...roomEssentials
  }
  badges {
    ...badgeLinkMinimal
  }
  practitioner {
    ...userPracticeConnectionMinimal
  }
}
    ${AppointmentCalendarEventDetailedFragmentDoc}
${TreatmentEssentialsFragmentDoc}
${TreatmentPlanEssentialsFragmentDoc}
${AppointmentPatientRecordItemEssentialsFragmentDoc}
${RecallEssentialsFragmentDoc}
${AppointmentItemEssentialsFragmentDoc}
${TemplateBasicFragmentDoc}
${RoomEssentialsFragmentDoc}
${BadgeLinkMinimalFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const AppointmentsDrawerDefaultsFragmentDoc = gql`
    fragment appointmentsDrawerDefaults on Appointment {
  id
  slug
  uuid
  guid
  slug
  colour
  state
  title
  startTime
  endTime
  duration
  minutes
  appNotes
  createdAt
  practitionerName
  userPracticeConnection {
    id
    guid
    practiceLocationId
    profile {
      id
      guid
      fullName
    }
  }
  appointmentGuests {
    userPracticeConnection {
      id
      guid
      practiceLocationId
      priceList {
        id
        guid
        name
      }
      primaryPractitioner {
        id
        guid
        practiceLocationId
        profile {
          id
          guid
          fullNameWithTitle
        }
      }
      secondaryPractitioner {
        id
        guid
        practiceLocationId
        profile {
          id
          guid
          fullNameWithTitle
        }
      }
      firstVisitTime
      lastVisitTime
      lastAppointmentTime
      nextAppointmentTime
      lastMedicalFormTime
      lastXrayTime
      lastExamTime
      recallExamDueTime
      recallVisitDueTime
      profile {
        id
        guid
        email
        fullName
        preferredFullName
        internationalMobile
        homePhone
        workPhone
      }
      activeUpcAlerts {
        id
        note
        alert {
          id
          icon
          colour
          text
        }
      }
    }
  }
  appointmentReason {
    id
    guid
    name
    minutes
  }
  badges {
    ...badgeLinkMinimal
  }
  room {
    id
    name
    practiceLocationId
  }
}
    ${BadgeLinkMinimalFragmentDoc}`;
export const AppointmentsOnHoldFragmentDoc = gql`
    fragment appointmentsOnHold on Appointment {
  ...appointmentsDrawerDefaults
  onHoldDate
}
    ${AppointmentsDrawerDefaultsFragmentDoc}`;
export const AppointmentsReadyToBookFragmentDoc = gql`
    fragment appointmentsReadyToBook on Appointment {
  ...appointmentsDrawerDefaults
  treatment {
    id
    guid
    title
    state
    dateStarted
    practitioner {
      id
      guid
      profile {
        id
        guid
        fullNameWithTitle
      }
    }
  }
  treatmentPlan {
    id
    guid
    treatmentPlanState: state
    proposedCost
    totalCost
  }
}
    ${AppointmentsDrawerDefaultsFragmentDoc}`;
export const AppointmentsWaitlistFragmentDoc = gql`
    fragment appointmentsWaitlist on Appointment {
  ...appointmentsDrawerDefaults
  waitlist {
    id
    guid
    state
    urgent
    catalogueItemIds
    preferredDays
    preferredTimes
    notes
    updatedAt
    createdAt
  }
}
    ${AppointmentsDrawerDefaultsFragmentDoc}`;
export const AppointmentCommunicationSettingFragmentDoc = gql`
    fragment appointmentCommunicationSetting on AppointmentCommunicationSetting {
  id
  guid
  startTime
  appointmentId
  appointmentGuid
  updateTemplateId
  sendUpdateEmail
  sendUpdateSms
  createTemplateId
  sendCreateEmail
  sendCreateSms
  confirmationTemplateId
  isConfirmationRequired
  allowConfirmationEmail
  allowConfirmationSms
  reminderTemplateId
  allowReminderEmail
  allowReminderSms
}
    `;
export const CalendarEventFragmentDoc = gql`
    fragment calendarEvent on CalendarEvent {
  id
  slug
  uuid
  guid
  startTime
  endTime
  createdAt
  updatedAt
  title
  practitionerName
  guestNames
  guestIds
  duration
  colour
  state
  userPracticeConnectionId
  roomId
  state
  appNotes
  notes
  guestContactNumbers
  guestContactEmails
  minutes
  estimatedMinutes
  allowReminderEmail
  allowReminderSms
  allowConfirmationEmail
  allowConfirmationSms
  allowAppointmentCreatedEmail
  allowAppointmentCreatedSms
  sendConfirmationDaysBefore
  sendReminderDaysBefore
  waitlistId
  reminderTemplateId
  confirmationTemplateId
  appointmentCreatedTemplateId
  appointmentUpdatedTemplateId
  onlineBookingCreditGuid
  onHoldDate
  onHoldExpired
  isFirstVisit
  isConfirmed
  isConfirmationRequired
  isGuestAppointment
  isPrivateAppointment
  isGroupAppointment
  isOnlineBooking
  isOnlineMeeting
  autoInvoice
  isCommunicationDelivered
  isCommunicationFailed
  patientHasOutstandingBalance
  smartFill
  labworksCompleteCount
  labworksSentCount
  labworksInProgressCount
  requestsInProgressCount
  requestsCompletedCount
  billablesReadyToInvoiceTotal
  badgeSlugs
}
    `;
export const CancellationReasonEssentialsFragmentDoc = gql`
    fragment cancellationReasonEssentials on CancellationReason {
  id
  guid
  reasonState: state
  colour
  name
}
    `;
export const VerifySlotFragmentDoc = gql`
    fragment verifySlot on VerifySlot {
  id
  valid
  errors
}
    `;
export const WaitlistMinimalFragmentDoc = gql`
    fragment waitlistMinimal on Waitlist {
  id
  guid
  state
  urgent
  catalogueItemIds
  preferredDays
  preferredTimes
  notes
  updatedAt
  createdAt
  discardedAt
}
    `;
export const WaitlistEssentialsFragmentDoc = gql`
    fragment waitlistEssentials on Waitlist {
  ...waitlistMinimal
  userPracticeConnection {
    ...upcTiny
  }
  practitioner {
    ...upcTiny
  }
}
    ${WaitlistMinimalFragmentDoc}
${UpcTinyFragmentDoc}`;
export const WaitlistDetailedFragmentDoc = gql`
    fragment waitlistDetailed on Waitlist {
  ...waitlistEssentials
  appointment {
    ...appointmentTiny
  }
}
    ${WaitlistEssentialsFragmentDoc}
${AppointmentTinyFragmentDoc}`;
export const CatalogueItemPricingFragmentDoc = gql`
    fragment catalogueItemPricing on CatalogueItem {
  ...catalogueItemMinimal
  isDental
  requiresFdi
  requiresSurfaceCode
  dentalTreatmentType
  minutes
  price(priceListId: $priceListId) {
    name
    priceIncTax
  }
}
    ${CatalogueItemMinimalFragmentDoc}`;
export const CatalogueItemPricingSettingsFragmentDoc = gql`
    fragment catalogueItemPricingSettings on CatalogueItem {
  id
  guid
  code
  name
  taxCode
  priceIncludesTax
  taxAmount
  prices(priceListIds: $priceListIds) {
    id
    guid
    amount
    priceListId
  }
}
    `;
export const BookableAppointmentReasonFragmentDoc = gql`
    fragment bookableAppointmentReason on AppointmentReason {
  ...appointmentReasonEssentials
  reminderTemplate {
    ...templateEssentials
  }
  confirmationTemplate {
    ...templateEssentials
  }
  practitioners {
    ...userPracticeConnectionMinimal
  }
}
    ${AppointmentReasonEssentialsFragmentDoc}
${TemplateEssentialsFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const AdviceItemEssentialsFragmentDoc = gql`
    fragment adviceItemEssentials on AdviceItem {
  id
  name
  content
  dueIn
  frequency
  mediaType
  description
}
    `;
export const DentalTreatmentTranslationFragmentDoc = gql`
    fragment dentalTreatmentTranslation on DentalTreatmentTranslation {
  deprecated
  description
  id
  name
  type
}
    `;
export const MaterialEssentialsFragmentDoc = gql`
    fragment materialEssentials on Material {
  id
  name
  colour
  treatments
  treatmentDefinitions {
    ...dentalTreatmentTranslation
  }
  state
  prettyTreatments
  createdAt
  updatedAt
}
    ${DentalTreatmentTranslationFragmentDoc}`;
export const CatalogueItemDetailedFragmentDoc = gql`
    fragment catalogueItemDetailed on CatalogueItem {
  ...catalogueItemEssentials
  packageLimit
  groupName
  masterPrice
  expiryMonths
  dentalJaw
  dentalTeeth
  priceIncludesTax
  surfaceCount
  coverPhoto
  adviceItemIds
  dentalPricing
  dentalClassification
  dentalTreatmentType
  catalogueItem {
    id
    name
    nameAndCode
  }
  bookableAppointmentReason {
    ...bookableAppointmentReason
  }
  adviceItems {
    ...adviceItemEssentials
  }
  category {
    id
    name
  }
  material {
    ...materialEssentials
  }
}
    ${CatalogueItemEssentialsFragmentDoc}
${BookableAppointmentReasonFragmentDoc}
${AdviceItemEssentialsFragmentDoc}
${MaterialEssentialsFragmentDoc}`;
export const CategoryEssentialFragmentDoc = gql`
    fragment categoryEssential on Category {
  id
  practiceId
  name
  type
  industryId
  active
  state
  createdAt
  updatedAt
}
    `;
export const PriceListEssentialsFragmentDoc = gql`
    fragment priceListEssentials on PriceList {
  id
  guid
  name
  state
  prefix
  isDefault
  createdAt
  updatedAt
}
    `;
export const PriceListDetailedFragmentDoc = gql`
    fragment priceListDetailed on PriceList {
  ...priceListEssentials
}
    ${PriceListEssentialsFragmentDoc}`;
export const PriceMinimalFragmentDoc = gql`
    fragment priceMinimal on Price {
  id
  guid
  name
  amount
  priceIncTax
  priceExTax
  taxCode
  priceIncludesTax
  discount
  taxAmount
  priceListId
}
    `;
export const PriceExhaustiveFragmentDoc = gql`
    fragment priceExhaustive on Price {
  ...priceMinimal
  priceList {
    ...priceListEssentials
  }
}
    ${PriceMinimalFragmentDoc}
${PriceListEssentialsFragmentDoc}`;
export const TagEssentialsFragmentDoc = gql`
    fragment tagEssentials on Tag {
  id
  name
}
    `;
export const TagDetailedFragmentDoc = gql`
    fragment tagDetailed on Tag {
  id
  name
  taggable {
    ... on Attachment {
      ...attachmentEssentials
    }
  }
}
    ${AttachmentEssentialsFragmentDoc}`;
export const Base64FilesFragmentDoc = gql`
    fragment base64Files on Base64File {
  id
  data
  filename
  fileType
  filesize
}
    `;
export const ConversationEssentialsFragmentDoc = gql`
    fragment conversationEssentials on Conversation {
  id
  createdAt
  messageCount
}
    `;
export const UserPracticeConnectionContactDetailsFragmentDoc = gql`
    fragment userPracticeConnectionContactDetails on UserPracticeLink {
  ...upcId
  slug
  photo {
    ...avatar
  }
  profile {
    ...userPracticeProfileMinimal
  }
}
    ${UpcIdFragmentDoc}
${AvatarFragmentDoc}
${UserPracticeProfileMinimalFragmentDoc}`;
export const AttachmentProxyFragmentDoc = gql`
    fragment attachmentProxy on AttachmentProxy {
  id
  name
  url
  mimeType
  fileExtension
}
    `;
export const EmailEssentialsFragmentDoc = gql`
    fragment emailEssentials on Email {
  id
  guid
  content
  communicationType
  messageableId
  messageableType
  discardedAt
  recipient {
    ... on Practice {
      ...practiceMinimal
    }
    ... on UserPracticeLink {
      ...userPracticeConnectionContactDetails
    }
  }
  sender {
    ... on Practice {
      ...practiceMinimal
    }
    ... on UserPracticeLink {
      ...userPracticeConnectionContactDetails
    }
  }
  attachments {
    ...attachmentProxy
  }
  template {
    ... on CommunicationTemplate {
      id
      name
    }
  }
  toEmail
  failureCauses
  createdAt
  state
  subject
  sendAt
}
    ${PracticeMinimalFragmentDoc}
${UserPracticeConnectionContactDetailsFragmentDoc}
${AttachmentProxyFragmentDoc}`;
export const SmsEssentialsFragmentDoc = gql`
    fragment smsEssentials on ShortMessage {
  id
  guid
  communicationType
  inbound
  outbound
  messageableId
  messageableType
  discardedAt
  recipient {
    ... on Practice {
      ...practiceMinimal
    }
    ... on UserPracticeLink {
      ...userPracticeConnectionContactDetails
    }
  }
  sender {
    ... on Practice {
      ...practiceMinimal
    }
    ... on UserPracticeLink {
      ...userPracticeConnectionContactDetails
    }
  }
  autoReplyAction
  template {
    ... on CommunicationTemplate {
      id
      name
    }
  }
  failureCauses
  createdAt
  toMobile
  state
  content
  sendAt
}
    ${PracticeMinimalFragmentDoc}
${UserPracticeConnectionContactDetailsFragmentDoc}`;
export const LetterEssentialsFragmentDoc = gql`
    fragment letterEssentials on Letter {
  id
  guid
  content
  toAddress
  sendAt
  messageableId
  messageableType
  recipient {
    ... on Practice {
      ...practiceMinimal
    }
    ... on UserPracticeLink {
      ...userPracticeConnectionContactDetails
    }
  }
  sender {
    ... on Practice {
      ...practiceMinimal
    }
    ... on UserPracticeLink {
      ...userPracticeConnectionContactDetails
    }
  }
  createdAt
  state
  subject
}
    ${PracticeMinimalFragmentDoc}
${UserPracticeConnectionContactDetailsFragmentDoc}`;
export const MessageEssentialsFragmentDoc = gql`
    fragment messageEssentials on Message {
  id
  createdAt
  note
  inbound
  outbound
  discardedAt
  receiverId
  senderId
  createdBy {
    ...userPracticeConnectionContactDetails
  }
  recipient {
    ... on Practice {
      ...practiceMinimal
    }
    ... on UserPracticeLink {
      ...userPracticeConnectionContactDetails
    }
  }
  sender {
    ... on Practice {
      ...practiceMinimal
    }
    ... on UserPracticeLink {
      ...userPracticeConnectionContactDetails
    }
  }
  email {
    ...emailEssentials
  }
  sms {
    ...smsEssentials
  }
  letter {
    ...letterEssentials
  }
}
    ${UserPracticeConnectionContactDetailsFragmentDoc}
${PracticeMinimalFragmentDoc}
${EmailEssentialsFragmentDoc}
${SmsEssentialsFragmentDoc}
${LetterEssentialsFragmentDoc}`;
export const ConversationDetailedFragmentDoc = gql`
    fragment conversationDetailed on Conversation {
  ...conversationEssentials
  messages(before: $before) {
    totalCount
    edges {
      cursor
      node {
        ...messageEssentials
      }
    }
  }
}
    ${ConversationEssentialsFragmentDoc}
${MessageEssentialsFragmentDoc}`;
export const ConversationParticipantFragmentDoc = gql`
    fragment conversationParticipant on Participant {
  ... on Practice {
    ...practiceMinimal
  }
  ... on UserPracticeLink {
    ...userPracticeConnectionContactDetails
  }
}
    ${PracticeMinimalFragmentDoc}
${UserPracticeConnectionContactDetailsFragmentDoc}`;
export const FormFieldInterfaceFragmentDoc = gql`
    fragment formFieldInterface on FormFieldInterface {
  id
  sectionId
  cols
  position
  offset
  name
  description
  placeholder
  fieldType
  key
  patientColumn
  required
}
    `;
export const FormTextFieldFragmentDoc = gql`
    fragment formTextField on FormTextField {
  ...formFieldInterface
  value
}
    ${FormFieldInterfaceFragmentDoc}`;
export const FormFieldChoiceFragmentDoc = gql`
    fragment formFieldChoice on FormFieldOptionType {
  label
  alertId
}
    `;
export const FormSelectFieldMultiFragmentDoc = gql`
    fragment formSelectFieldMulti on FormSelectMultiField {
  ...formFieldInterface
  multiple
  options {
    ...formFieldChoice
  }
  values
}
    ${FormFieldInterfaceFragmentDoc}
${FormFieldChoiceFragmentDoc}`;
export const FormSelectFieldSingleFragmentDoc = gql`
    fragment formSelectFieldSingle on FormSelectSingleField {
  ...formFieldInterface
  multiple
  options {
    ...formFieldChoice
  }
  value
}
    ${FormFieldInterfaceFragmentDoc}
${FormFieldChoiceFragmentDoc}`;
export const FormCheckboxFieldFragmentDoc = gql`
    fragment formCheckboxField on FormCheckboxField {
  ...formFieldInterface
  values
  options {
    ...formFieldChoice
  }
}
    ${FormFieldInterfaceFragmentDoc}
${FormFieldChoiceFragmentDoc}`;
export const FormRadioFieldFragmentDoc = gql`
    fragment formRadioField on FormRadioField {
  ...formFieldInterface
  value
  options {
    ...formFieldChoice
  }
}
    ${FormFieldInterfaceFragmentDoc}
${FormFieldChoiceFragmentDoc}`;
export const FormNumberFieldFragmentDoc = gql`
    fragment formNumberField on FormNumberField {
  ...formFieldInterface
  number
  min
  max
}
    ${FormFieldInterfaceFragmentDoc}`;
export const FormDateTimeFieldFragmentDoc = gql`
    fragment formDateTimeField on FormDateTimeField {
  ...formFieldInterface
  datetime
}
    ${FormFieldInterfaceFragmentDoc}`;
export const FormFieldAttachmentFragmentDoc = gql`
    fragment formFieldAttachment on FormFieldAttachment {
  id
  name
  base64
  attachmentId
  url
}
    `;
export const FormDrawingFieldFragmentDoc = gql`
    fragment formDrawingField on FormDrawingField {
  ...formFieldInterface
  attachment {
    ...formFieldAttachment
  }
}
    ${FormFieldInterfaceFragmentDoc}
${FormFieldAttachmentFragmentDoc}`;
export const FormSignatureFieldFragmentDoc = gql`
    fragment formSignatureField on FormSignatureField {
  ...formFieldInterface
  attachment {
    ...formFieldAttachment
  }
}
    ${FormFieldInterfaceFragmentDoc}
${FormFieldAttachmentFragmentDoc}`;
export const FormUploadFieldFragmentDoc = gql`
    fragment formUploadField on FormUploadField {
  ...formFieldInterface
  files {
    ...formFieldAttachment
  }
  multiple
  acceptedFileTypes
}
    ${FormFieldInterfaceFragmentDoc}
${FormFieldAttachmentFragmentDoc}`;
export const FormVideoFieldFragmentDoc = gql`
    fragment formVideoField on FormVideoField {
  ...formFieldInterface
  value
}
    ${FormFieldInterfaceFragmentDoc}`;
export const FormMobileFieldFragmentDoc = gql`
    fragment formMobileField on FormMobileField {
  ...formFieldInterface
  phoneNumber {
    short
    code
    phone
  }
}
    ${FormFieldInterfaceFragmentDoc}`;
export const FormFieldFragmentDoc = gql`
    fragment formField on FormFieldInterface {
  id
  ... on FormTextField {
    ...formTextField
  }
  ... on FormSelectMultiField {
    ...formSelectFieldMulti
  }
  ... on FormSelectSingleField {
    ...formSelectFieldSingle
  }
  ... on FormCheckboxField {
    ...formCheckboxField
  }
  ... on FormRadioField {
    ...formRadioField
  }
  ... on FormParagraphField {
    ...formFieldInterface
  }
  ... on FormTitleField {
    ...formFieldInterface
  }
  ... on FormNumberField {
    ...formNumberField
  }
  ... on FormDateTimeField {
    ...formDateTimeField
  }
  ... on FormDrawingField {
    ...formDrawingField
  }
  ... on FormSignatureField {
    ...formSignatureField
  }
  ... on FormUploadField {
    ...formUploadField
  }
  ... on FormVideoField {
    ...formVideoField
  }
  ... on FormMobileField {
    ...formMobileField
  }
  ... on LiquidDropField {
    ...formFieldInterface
  }
}
    ${FormTextFieldFragmentDoc}
${FormSelectFieldMultiFragmentDoc}
${FormSelectFieldSingleFragmentDoc}
${FormCheckboxFieldFragmentDoc}
${FormRadioFieldFragmentDoc}
${FormFieldInterfaceFragmentDoc}
${FormNumberFieldFragmentDoc}
${FormDateTimeFieldFragmentDoc}
${FormDrawingFieldFragmentDoc}
${FormSignatureFieldFragmentDoc}
${FormUploadFieldFragmentDoc}
${FormVideoFieldFragmentDoc}
${FormMobileFieldFragmentDoc}`;
export const FormSectionFragmentDoc = gql`
    fragment formSection on FormSection {
  id
  name
  position
  fields {
    ...formField
  }
}
    ${FormFieldFragmentDoc}`;
export const FormSectionFieldFragmentDoc = gql`
    fragment formSectionField on FormSectionField {
  id
  key
  name
  fieldType
  description
}
    `;
export const FormFieldsFragmentDoc = gql`
    fragment formFields on FormFieldInterface {
  ... on FormTextField {
    ...formTextField
  }
  ... on FormSectionField {
    ...formSectionField
  }
  ... on FormSelectMultiField {
    ...formSelectFieldMulti
  }
  ... on FormSelectSingleField {
    ...formSelectFieldSingle
  }
  ... on FormCheckboxField {
    ...formCheckboxField
  }
  ... on FormRadioField {
    ...formRadioField
  }
  ... on FormParagraphField {
    ...formFieldInterface
  }
  ... on FormTitleField {
    ...formFieldInterface
  }
  ... on FormNumberField {
    ...formNumberField
  }
  ... on FormDateTimeField {
    ...formDateTimeField
  }
  ... on FormDrawingField {
    ...formDrawingField
  }
  ... on FormSignatureField {
    ...formSignatureField
  }
  ... on FormUploadField {
    ...formUploadField
  }
  ... on FormVideoField {
    ...formVideoField
  }
  ... on FormMobileField {
    ...formMobileField
  }
  ... on LiquidDropField {
    ...formFieldInterface
  }
}
    ${FormTextFieldFragmentDoc}
${FormSectionFieldFragmentDoc}
${FormSelectFieldMultiFragmentDoc}
${FormSelectFieldSingleFragmentDoc}
${FormCheckboxFieldFragmentDoc}
${FormRadioFieldFragmentDoc}
${FormFieldInterfaceFragmentDoc}
${FormNumberFieldFragmentDoc}
${FormDateTimeFieldFragmentDoc}
${FormDrawingFieldFragmentDoc}
${FormSignatureFieldFragmentDoc}
${FormUploadFieldFragmentDoc}
${FormVideoFieldFragmentDoc}
${FormMobileFieldFragmentDoc}`;
export const FormTemplateDetailedFragmentDoc = gql`
    fragment formTemplateDetailed on FormTemplate {
  ...formTemplateEssentials
  sections {
    ...formSection
  }
  jsonContent {
    ...formFields
  }
}
    ${FormTemplateEssentialsFragmentDoc}
${FormSectionFragmentDoc}
${FormFieldsFragmentDoc}`;
export const InboxMessagePracticeFragmentDoc = gql`
    fragment inboxMessagePractice on Practice {
  id
  guid
  name
  logo {
    id
    medium
  }
}
    `;
export const InboxMessageUpcFragmentDoc = gql`
    fragment inboxMessageUpc on UserPracticeLink {
  id
  guid
  photo {
    id
    medium
  }
  profile {
    id
    fullNameWithTitle
    avatarColor
  }
}
    `;
export const InboxMessageFragmentDoc = gql`
    fragment inboxMessage on ShortMessage {
  id
  guid
  toMobile
  state
  content
  recipient {
    ... on Practice {
      ...inboxMessagePractice
    }
    ... on UserPracticeLink {
      ...inboxMessageUpc
    }
  }
  sender {
    ... on Practice {
      ...inboxMessagePractice
    }
    ... on UserPracticeLink {
      ...inboxMessageUpc
    }
  }
  failureCauses
  sendAt
  createdAt
}
    ${InboxMessagePracticeFragmentDoc}
${InboxMessageUpcFragmentDoc}`;
export const ReminderEssentialsFragmentDoc = gql`
    fragment reminderEssentials on Reminder {
  id
  state
  triggeredAt
  remindableType
  smsContent
  emailContent
  contactType
  period
  frequency
  position
  templateId
  subject
  taskAssigneeIds
  failureCauses
}
    `;
export const ReminderDetailedFragmentDoc = gql`
    fragment reminderDetailed on Reminder {
  ...reminderEssentials
}
    ${ReminderEssentialsFragmentDoc}`;
export const SnippetDetailedFragmentDoc = gql`
    fragment snippetDetailed on Snippet {
  id
  name
  content
  isPrivate
  state
  snippetType
  createdAt
  updatedAt
  userPracticeConnection {
    id
    guid
  }
}
    `;
export const TemplateDropFragmentDoc = gql`
    fragment templateDrop on LiquidDrop {
  name
  type
  description
  variables {
    ...templateVariable
  }
  accepts
}
    ${TemplateVariableFragmentDoc}`;
export const FormListItemFragmentDoc = gql`
    fragment formListItem on Form {
  id
  uuid
  guid
  name
  createdAt
  updatedAt
  completedAt
  state
  type
  isMedical
  updatedBy {
    ...userPracticeConnectionMinimal
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;
export const FormFragmentDoc = gql`
    fragment form on Form {
  ...formListItem
  smsLink
  emailLink
  patientOnly
  authToken
  template {
    id
    guid
    name
  }
  restrictedPinLink {
    id
    url
    pinCode
    expiresAt
  }
  restrictedOtpLink {
    id
    url
    expiresAt
  }
  recipient {
    ...userPracticeConnectionMinimal
  }
  jsonContent {
    ...formFields
  }
}
    ${FormListItemFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${FormFieldsFragmentDoc}`;
export const TemplateDetailsFragmentDoc = gql`
    fragment templateDetails on TemplateInterface {
  ...templateEssentials
  ... on CommunicationTemplate {
    subject
    plainText
    html
  }
  ... on DocumentTemplate {
    html
    drops {
      ...templateDropConfig
    }
  }
  ... on FormTemplate {
    html
    plainText
    jsonContent {
      ...formFields
    }
  }
  assigned {
    __typename
    ... on Recall {
      ...recallEssentials
    }
    ... on Invoice {
      ...invoiceEssentials
    }
    ... on Form {
      ...form
    }
    ... on AppointmentReason {
      ...appointmentReasonEssentials
    }
  }
}
    ${TemplateEssentialsFragmentDoc}
${TemplateDropConfigFragmentDoc}
${FormFieldsFragmentDoc}
${RecallEssentialsFragmentDoc}
${InvoiceEssentialsFragmentDoc}
${FormFragmentDoc}
${AppointmentReasonEssentialsFragmentDoc}`;
export const CustomerRequestMetadataFragmentDoc = gql`
    fragment customerRequestMetadata on CustomerRequestMetadata {
  depositAmount
}
    `;
export const CustomerRequestDetailedFragmentDoc = gql`
    fragment customerRequestDetailed on CustomerRequest {
  id
  uuid
  practiceId
  accountId
  patientId
  state
  expireAt
  completedAt
  pendingAt
  cancelledAt
  requestType
  url
  description
  discardedAt
  practiceLocationId
  paymentTransactionId
  invoiceTransactionId
  metadata {
    ...customerRequestMetadata
  }
  practitioner {
    guid
    profile {
      id
      guid
      fullNameWithTitle
      fullName
    }
  }
  patient {
    id
    guid
    state
    profile {
      guid
      fullNameWithTitle
      email
      fullName
    }
  }
  invoiceTransaction {
    id
    guid
    amount
    surcharge
    patientPaid
    practiceReceived
  }
  createdAt
  updatedAt
}
    ${CustomerRequestMetadataFragmentDoc}`;
export const PublicCustomerRequestFragmentDoc = gql`
    fragment publicCustomerRequest on CustomerRequest {
  ...customerRequestDetailed
  practiceLocation {
    ...practiceLocationEssentials
  }
}
    ${CustomerRequestDetailedFragmentDoc}
${PracticeLocationEssentialsFragmentDoc}`;
export const DentalClassificationTranslationFragmentDoc = gql`
    fragment dentalClassificationTranslation on DentalClassificationTranslation {
  deprecated
  description
  id
  name
  type
}
    `;
export const DentalPricingTranslationFragmentDoc = gql`
    fragment dentalPricingTranslation on DentalPricingTranslation {
  deprecated
  description
  id
  name
  type
}
    `;
export const DentalJawTranslationFragmentDoc = gql`
    fragment dentalJawTranslation on DentalJawTranslation {
  deprecated
  description
  id
  name
  type
}
    `;
export const DentalTeethTranslationFragmentDoc = gql`
    fragment dentalTeethTranslation on DentalTeethTranslation {
  deprecated
  description
  id
  name
  type
}
    `;
export const DentalToothPositionFragmentDoc = gql`
    fragment dentalToothPosition on DentalToothPosition {
  x
  y
  z
  r1
  r2
}
    `;
export const DentalToothFragmentDoc = gql`
    fragment dentalTooth on DentalTooth {
  id
  fdi
  identifier
  quadrant
  missing
  permanent
  position {
    ...dentalToothPosition
  }
  toothState: state
  createdAt
  updatedAt
}
    ${DentalToothPositionFragmentDoc}`;
export const DentalChartFragmentDoc = gql`
    fragment dentalChart on DentalChart {
  id
  createdAt
  updatedAt
  teeth {
    ...dentalTooth
  }
}
    ${DentalToothFragmentDoc}`;
export const DentalPerioExamMinimalFragmentDoc = gql`
    fragment dentalPerioExamMinimal on DentalPerioExam {
  id
  date
  movementAuto
  bleedingPercent
  supperationPercent
  plaquePercent
  furcationPercent
  practitionerId
  patientId
  chartImage {
    url
  }
  discardedAt
  createdAt
  updatedAt
}
    `;
export const DentalToothLocationEssentialsFragmentDoc = gql`
    fragment dentalToothLocationEssentials on DentalToothLocation {
  id
  quadrant
  identifier
}
    `;
export const DentalPerioEntryMinimalFragmentDoc = gql`
    fragment dentalPerioEntryMinimal on DentalPerioEntry {
  id
  fdi
  missing
  toothFace
  pocketDepth
  gingivalMargin
  clinicalAttachmentLoss
  furcation
  bleeding
  plaque
  supperation
  mobility
  boneLoss
  discardedAt
  createdAt
  updatedAt
  toothLocation {
    ...dentalToothLocationEssentials
  }
}
    ${DentalToothLocationEssentialsFragmentDoc}`;
export const DentalPerioExamEssentialsFragmentDoc = gql`
    fragment dentalPerioExamEssentials on DentalPerioExam {
  ...dentalPerioExamMinimal
  perioEntries {
    ...dentalPerioEntryMinimal
  }
}
    ${DentalPerioExamMinimalFragmentDoc}
${DentalPerioEntryMinimalFragmentDoc}`;
export const DentalPerioExamDetailsFragmentDoc = gql`
    fragment dentalPerioExamDetails on DentalPerioExam {
  ...dentalPerioExamEssentials
  patient {
    ...userPracticeConnectionMinimal
  }
  practitioner {
    ...userPracticeConnectionMinimal
  }
}
    ${DentalPerioExamEssentialsFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const DentalTreatmentMinimalFragmentDoc = gql`
    fragment dentalTreatmentMinimal on DentalToothTreatment {
  id
  toothId
  date
  name
  treatmentType
  surfaceCode
  colour
  state
  fdi
  recordType
}
    `;
export const PatientRecordItemMinimalFragmentDoc = gql`
    fragment patientRecordItemMinimal on PatientRecordItem {
  id
  guid
  uuid
  name
  code
  invoiceId
  practitionerId
  priceListId
  appointmentId
  userPracticeConnectionId
  treatmentId
  treatmentPlanId
  price
  priceIncTax
  priceExTax
  total
  totalExTax
  totalDiscount
  totalTax
  quantity
  tax
  discount
  price
  state
  position
  declinedAt
  discardedAt
  createdAt
  readyToInvoiceAt
  updatedAt
}
    `;
export const PatientRecordItemEssentialsFragmentDoc = gql`
    fragment patientRecordItemEssentials on PatientRecordItem {
  ...patientRecordItemMinimal
  discount
  minutes
  surfaceCode
  fdi
}
    ${PatientRecordItemMinimalFragmentDoc}`;
export const DentalTreatmentDetailedFragmentDoc = gql`
    fragment dentalTreatmentDetailed on DentalToothTreatment {
  ...dentalTreatmentMinimal
  treatment {
    id
  }
  treatmentPlanId
  patientRecordItem {
    ...patientRecordItemEssentials
  }
}
    ${DentalTreatmentMinimalFragmentDoc}
${PatientRecordItemEssentialsFragmentDoc}`;
export const ImageMinimalFragmentDoc = gql`
    fragment imageMinimal on DocumentImage {
  id
  url
  createdAt
}
    `;
export const DirectoryInfoFragmentDoc = gql`
    fragment directoryInfo on Directory {
  id
  guid
  name
  access
  path
  uuid
  isRoot
  contentType
  patientGuid
  ownerGuid
}
    `;
export const DirectoryPathInfoFragmentDoc = gql`
    fragment directoryPathInfo on Directory {
  ...directoryInfo
  parentPath {
    ...directoryInfo
  }
  directories {
    ...directoryInfo
  }
}
    ${DirectoryInfoFragmentDoc}`;
export const SubdirectoryFragmentDoc = gql`
    fragment subdirectory on Directory {
  ...directoryInfo
  fileCount
  directoryCount
  updatedAt
  createdAt
  badges {
    ...badgeLinkMinimal
  }
  createdBy {
    ...userPracticeConnectionMinimal
  }
  lastUpdatedBy {
    ...userPracticeConnectionMinimal
  }
}
    ${DirectoryInfoFragmentDoc}
${BadgeLinkMinimalFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const RichTextDirectoryItemFragmentDoc = gql`
    fragment richTextDirectoryItem on RichText {
  ...richTextMinimal
  createdBy {
    ...userPracticeConnectionMinimal
  }
  badges {
    ...badgeLinkMinimal
  }
}
    ${RichTextMinimalFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${BadgeLinkMinimalFragmentDoc}`;
export const MovedDirectoryFragmentDoc = gql`
    fragment movedDirectory on Directory {
  ...subdirectory
  directories {
    ...directoryInfo
  }
  parent {
    ...directoryInfo
  }
  parentPath {
    ...directoryInfo
  }
  files {
    edges {
      node {
        ...attachmentMinimal
      }
    }
  }
  richTexts {
    ...richTextDirectoryItem
  }
}
    ${SubdirectoryFragmentDoc}
${DirectoryInfoFragmentDoc}
${AttachmentMinimalFragmentDoc}
${RichTextDirectoryItemFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment pageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  endCursor
  startCursor
}
    `;
export const PaginatedDirectoryFragmentDoc = gql`
    fragment paginatedDirectory on Directory {
  ...subdirectory
  patient {
    ...userPracticeConnectionMinimal
  }
  parent {
    ...directoryInfo
  }
  parentPath {
    ...directoryInfo
  }
  directories {
    ...subdirectory
  }
  files(first: $first, after: $after, contentFilter: $contentFilter) {
    pageInfo {
      ...pageInfo
    }
    edges {
      node {
        ...attachmentEssentials
      }
    }
  }
  richTexts {
    ...richTextDirectoryItem
  }
}
    ${SubdirectoryFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${DirectoryInfoFragmentDoc}
${PageInfoFragmentDoc}
${AttachmentEssentialsFragmentDoc}
${RichTextDirectoryItemFragmentDoc}`;
export const RichTextEssentialsFragmentDoc = gql`
    fragment richTextEssentials on RichText {
  ...richTextMinimal
  badges {
    ...badgeLinkMinimal
  }
  parent {
    ...richTextMinimal
  }
  patient {
    ...userPracticeConnectionMinimal
  }
  createdBy {
    ...userPracticeConnectionMinimal
  }
  updatedBy {
    ...userPracticeConnectionMinimal
  }
}
    ${RichTextMinimalFragmentDoc}
${BadgeLinkMinimalFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const DirectoryFragmentDoc = gql`
    fragment directory on Directory {
  ...subdirectory
  patient {
    ...userPracticeConnectionMinimal
  }
  parent {
    ...directoryInfo
  }
  parentPath {
    ...directoryInfo
  }
  directories {
    ...subdirectory
  }
  files {
    pageInfo {
      ...pageInfo
    }
    edges {
      node {
        ...attachmentEssentials
      }
    }
  }
  richTexts {
    ...richTextDirectoryItem
  }
}
    ${SubdirectoryFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${DirectoryInfoFragmentDoc}
${PageInfoFragmentDoc}
${AttachmentEssentialsFragmentDoc}
${RichTextDirectoryItemFragmentDoc}`;
export const RichTextDetailedFragmentDoc = gql`
    fragment richTextDetailed on RichText {
  ...richTextEssentials
  directory {
    ...directory
  }
}
    ${RichTextEssentialsFragmentDoc}
${DirectoryFragmentDoc}`;
export const FamilyMinimalFragmentDoc = gql`
    fragment familyMinimal on Family {
  id
  name
  membersCount
  createdAt
  updatedAt
}
    `;
export const FamilyMemberMinimalFragmentDoc = gql`
    fragment familyMemberMinimal on FamilyMember {
  id
  familyId
  userPracticeConnectionId
}
    `;
export const FamilyMemberEssentialsFragmentDoc = gql`
    fragment familyMemberEssentials on FamilyMember {
  ...familyMemberMinimal
  family {
    ...familyMinimal
  }
}
    ${FamilyMemberMinimalFragmentDoc}
${FamilyMinimalFragmentDoc}`;
export const FamilyEssentialsFragmentDoc = gql`
    fragment familyEssentials on Family {
  ...familyMinimal
  familyMembers {
    ...familyMemberEssentials
  }
}
    ${FamilyMinimalFragmentDoc}
${FamilyMemberEssentialsFragmentDoc}`;
export const FamilyMemberExhaustiveFragmentDoc = gql`
    fragment familyMemberExhaustive on FamilyMember {
  ...familyMemberEssentials
  userPracticeConnection {
    ...userPracticeConnectionMinimal
  }
}
    ${FamilyMemberEssentialsFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const FamilyExhaustiveFragmentDoc = gql`
    fragment familyExhaustive on Family {
  ...familyEssentials
  familyMembers {
    ...familyMemberExhaustive
  }
}
    ${FamilyEssentialsFragmentDoc}
${FamilyMemberExhaustiveFragmentDoc}`;
export const NewFamilyMemberFragmentDoc = gql`
    fragment newFamilyMember on UserPracticeLink {
  id
  guid
  profile {
    ...userPracticeProfileMinimal
  }
  familyId
  family {
    id
    name
  }
}
    ${UserPracticeProfileMinimalFragmentDoc}`;
export const NewContactConnectionFragmentDoc = gql`
    fragment newContactConnection on UserPracticeLink {
  id
  guid
  profile {
    ...userPracticeProfileMinimal
  }
  role {
    id
    name
  }
}
    ${UserPracticeProfileMinimalFragmentDoc}`;
export const IconTranslationFragmentDoc = gql`
    fragment iconTranslation on IconTranslation {
  icon
  description
  value
  type
}
    `;
export const IntercomConfigurationDetailedFragmentDoc = gql`
    fragment IntercomConfigurationDetailed on IntercomConfiguration {
  name
  userId
  email
  phone
  practices {
    id
    name
    industry
    companyId
    createdAt
    features
  }
  account {
    companyId
    name
    createdAt
    plan
    size
    customAttributes {
      practiceCount
      patientCount
      subscriptionStatus
      activeUsersCount
      inactiveUsersCount
      practices {
        id
        name
        industry
        companyId
        createdAt
        features
      }
    }
  }
  accountSubdomains
}
    `;
export const LocationSearchFragmentDoc = gql`
    fragment locationSearch on LocationSearchResult {
  placeId
  formattedAddress
}
    `;
export const ResponseMessageFragmentDoc = gql`
    fragment responseMessage on ResponseMessage {
  message
  type
}
    `;
export const HealthFundProviderBasicsFragmentDoc = gql`
    fragment healthFundProviderBasics on HealthFundProviderInterface {
  id
  name
  slug
  public
  gateways
  createdAt
  updatedAt
}
    `;
export const UpcHealthFundIdentifierFragmentDoc = gql`
    fragment upcHealthFundIdentifier on HealthFundConsumer {
  id
  primaryIdentifier
  secondaryIdentifier
  provider {
    id
    name
    public
  }
}
    `;
export const HealthFundsClaimItemAttributesFragmentDoc = gql`
    fragment healthFundsClaimItemAttributes on HealthFundsClaimItemAttributes {
  medicareItemOverrideCode
  medicareSelfDeemedCode
  medicareLspn
}
    `;
export const HealthFundsClaimItemMinimalFragmentDoc = gql`
    fragment healthFundsClaimItemMinimal on HealthFundsClaimItem {
  id
  code
  name
  date
  state
  patientRef
  serviceRef
  healthFundName
  requestType
  originalAmount
  amount
  rebate
  gap
  responseCode
  responseMessage
}
    `;
export const HealthFundsPlannerClaimItemFragmentDoc = gql`
    fragment healthFundsPlannerClaimItem on PatientRecordItem {
  id
  guid
  code
  name
  total
  healthFundModality
  healthFundModalityCode
  healthFundServiceDate
  healthFundPatientRef
  healthFundServiceRef
  healthFundProviderRef
  healthFundAmount
  healthFundRebate
  healthFundGap
  healthFundResponseCode
  healthFundResponseMessage
  healthFundGateway
  healthFundRequestType
  healthFundRequestAt
  healthFundAttributes {
    ...healthFundsClaimItemAttributes
  }
  patient {
    ...upcTiny
  }
  practitioner {
    ...upcTiny
  }
  healthFundItem {
    ...healthFundsClaimItemMinimal
  }
}
    ${HealthFundsClaimItemAttributesFragmentDoc}
${UpcTinyFragmentDoc}
${HealthFundsClaimItemMinimalFragmentDoc}`;
export const HealthFundsInvoiceClaimItemFragmentDoc = gql`
    fragment healthFundsInvoiceClaimItem on InvoiceItem {
  id
  guid
  code
  name
  total
  totalPaid
  balance
  fdi
  patient {
    ...upcTiny
  }
  healthFundModality
  healthFundModalityCode
  healthFundServiceDate
  healthFundPatientRef
  healthFundServiceRef
  healthFundProviderRef
  healthFundAmount
  healthFundRebate
  healthFundGap
  healthFundResponseCode
  healthFundResponseMessage
  healthFundGateway
  healthFundRequestType
  healthFundRequestAt
  healthFundAttributes {
    ...healthFundsClaimItemAttributes
  }
  healthFundItem {
    ...healthFundsClaimItemMinimal
  }
  practitioner {
    ...upcTiny
  }
}
    ${UpcTinyFragmentDoc}
${HealthFundsClaimItemAttributesFragmentDoc}
${HealthFundsClaimItemMinimalFragmentDoc}`;
export const HealthFundsRequestMinimalFragmentDoc = gql`
    fragment healthFundsRequestMinimal on HealthFundsRequest {
  id
  date
  state
  gateway
  requestType
  responseCode
  responseMessage
  healthFundName
  amount
  rebate
  gap
  createdAt
  updatedAt
  receiptPrintedAt
}
    `;
export const HealthFundsRequestEssentialsFragmentDoc = gql`
    fragment healthFundsRequestEssentials on HealthFundsRequest {
  ...healthFundsRequestMinimal
  providerRef
  merchantRef
  terminalRef
  rrn
  cardNumber
  cardExpiry
  claimItems {
    ...healthFundsClaimItemMinimal
  }
}
    ${HealthFundsRequestMinimalFragmentDoc}
${HealthFundsClaimItemMinimalFragmentDoc}`;
export const HealthFundsRequestExhaustiveFragmentDoc = gql`
    fragment healthFundsRequestExhaustive on HealthFundsRequest {
  ...healthFundsRequestEssentials
}
    ${HealthFundsRequestEssentialsFragmentDoc}`;
export const HealthFundsRequestReceiptFragmentDoc = gql`
    fragment healthFundsRequestReceipt on HealthFundsRequest {
  ...healthFundsRequestEssentials
  originalReceiptUri
}
    ${HealthFundsRequestEssentialsFragmentDoc}`;
export const HealthFundsRequestWithReprintPatientReceiptFragmentDoc = gql`
    fragment healthFundsRequestWithReprintPatientReceipt on HealthFundsRequest {
  ...healthFundsRequestEssentials
  patientReceiptDataUri
}
    ${HealthFundsRequestEssentialsFragmentDoc}`;
export const HealthFundsRequestWithReprintProviderReceiptFragmentDoc = gql`
    fragment healthFundsRequestWithReprintProviderReceipt on HealthFundsRequest {
  ...healthFundsRequestEssentials
  providerReceiptDataUri
}
    ${HealthFundsRequestEssentialsFragmentDoc}`;
export const ContractIdentifierEssentialsFragmentDoc = gql`
    fragment contractIdentifierEssentials on ContractIdentifier {
  id
  name
  providerId
  providerName
  updatedAt
  createdAt
  merchantId
  userPracticeConnectionGuid
}
    `;
export const UpcProfileIdFragmentDoc = gql`
    fragment upcProfileId on UserPracticeLink {
  ...upcId
  practiceLocationId
  profile {
    id
    uuid
    guid
    fullName
  }
}
    ${UpcIdFragmentDoc}`;
export const ContractIdentifierDetailedFragmentDoc = gql`
    fragment contractIdentifierDetailed on ContractIdentifier {
  ...contractIdentifierEssentials
  userPracticeConnection {
    ...upcProfileId
  }
}
    ${ContractIdentifierEssentialsFragmentDoc}
${UpcProfileIdFragmentDoc}`;
export const InvoiceSettingsEssentialsFragmentDoc = gql`
    fragment invoiceSettingsEssentials on InvoiceSetting {
  ...invoiceSettingsMinimal
  userPracticeConnections {
    id
    guid
    uuid
    profile {
      id
      guid
      uuid
      fullName
      avatarColor
    }
  }
  contractIdentifier {
    ...contractIdentifierDetailed
  }
}
    ${InvoiceSettingsMinimalFragmentDoc}
${ContractIdentifierDetailedFragmentDoc}`;
export const NoteTypeEssentialsFragmentDoc = gql`
    fragment noteTypeEssentials on NoteTypeTranslation {
  id
  name
  type
  description
}
    `;
export const NoteMetadataFragmentDoc = gql`
    fragment noteMetadata on NoteMetadata {
  fdi
  surfaceCode
  noteTitle
  itemCost
}
    `;
export const NoteMinimalFragmentDoc = gql`
    fragment noteMinimal on Note {
  id
  guid
  slug
  state
  uuid
  autoNote
  noteType {
    ...noteTypeEssentials
  }
  metadata {
    ...noteMetadata
  }
  content
  sticky
  createdAt
  updatedAt
  discardedAt
}
    ${NoteTypeEssentialsFragmentDoc}
${NoteMetadataFragmentDoc}`;
export const PatientRecordItemDetailedFragmentDoc = gql`
    fragment patientRecordItemDetailed on PatientRecordItem {
  ...patientRecordItemEssentials
  dentalToothTreatment {
    ...dentalTreatmentMinimal
  }
  treatmentPlan {
    ...treatmentPlanMinimal
  }
  treatment {
    ...treatmentMinimal
  }
  note {
    ...noteMinimal
  }
  catalogueItem {
    id
    guid
    isDental
    requiresFdi
    requiresSurfaceCode
    surfaceCount
  }
  appointmentId
  practiceProfessionalId
  patientRecordId
}
    ${PatientRecordItemEssentialsFragmentDoc}
${DentalTreatmentMinimalFragmentDoc}
${TreatmentPlanMinimalFragmentDoc}
${TreatmentMinimalFragmentDoc}
${NoteMinimalFragmentDoc}`;
export const InvoiceItemMetadataFragmentDoc = gql`
    fragment invoiceItemMetadata on InvoiceItemMetadata {
  tyroHealthPointClaimAmount
  tyroHealthPointGapAmount
  tyroHealthPointDescription
  tyroHealthPointPatientId
  tyroHealthPointResponseCode
  tyroHealthPointRebateAmount
  tyroHealthPointServiceCode
  tyroHealthPointServiceDate
  tyroHealthPointServiceReference
  tyroHealthPointProviderId
  medipassClaimId
  medipassClaimDisplayName
  medipassClaimAmount
  medipassClaimRebate
  medipassClaimItemId
  medipassClaimGapAmount
  medipassClaimStatus
  medipassClaimExpectedBenefit
  medipassClaimActualBenefit
  medipassClaimItemCode
  medipassClaimServiceDate
}
    `;
export const InvoiceItemEssentialsFragmentDoc = gql`
    fragment invoiceItemEssentials on InvoiceItem {
  id
  guid
  name
  code
  description
  price
  quantity
  tax
  discount
  priceIncTax
  fdi
  surfaceCode
  priceExTax
  total
  totalExTax
  totalDiscount
  totalTax
  totalPaid
  balance
  discardedAt
  createdAt
  healthFundModality
  healthFundModalityCode
  healthFundServiceDate
  healthFundPatientRef
  healthFundServiceRef
  healthFundProviderRef
  healthFundAmount
  healthFundRebate
  healthFundGap
  healthFundResponseCode
  healthFundResponseMessage
  healthFundGateway
  healthFundRequestType
  healthFundRequestAt
  practitionerId
  patient {
    ...upcTiny
  }
  practitioner {
    id
    guid
    contractIdentifiers {
      id
      providerId
      merchantId
    }
    profile {
      fullName
    }
  }
  invoiceTransaction {
    id
    transactionType
    state
  }
  metadata {
    ...invoiceItemMetadata
  }
  catalogueItem {
    id
    guid
    uuid
    code
    type
    healthFundItemNumber
    modalityCode
    prettyModality
  }
}
    ${UpcTinyFragmentDoc}
${InvoiceItemMetadataFragmentDoc}`;
export const InvoiceItemDetailedFragmentDoc = gql`
    fragment invoiceItemDetailed on InvoiceItem {
  ...invoiceItemEssentials
  patientRecordItem {
    id
    guid
    uuid
    name
    code
    invoiceId
    practitionerId
    priceListId
    appointmentId
    userPracticeConnectionId
    price
    priceIncTax
    priceExTax
    total
    totalExTax
    totalDiscount
    totalTax
    quantity
    tax
    discount
    price
    state
    position
    declinedAt
    discardedAt
    createdAt
    readyToInvoiceAt
    updatedAt
    treatmentPlan {
      id
      slug
    }
  }
  catalogueItem {
    id
    guid
    isDental
    requiresFdi
    requiresSurfaceCode
    surfaceCount
  }
}
    ${InvoiceItemEssentialsFragmentDoc}`;
export const PaymentIntentFragmentDoc = gql`
    fragment paymentIntent on PaymentIntent {
  id
  amount
  amountCapturable
  amountReceived
  application
  applicationFeeAmount
  canceledAt
  cancellationReason
  charges
  captureMethod
  clientSecret
  confirmationMethod
  currency
  customer
  description
  invoice
  lastPaymentError
  nextAction
  onBehalfOf
  paymentMethod
  receiptEmail
  review
  shipping
  setupFutureUsage
  statementDescriptor
  status
}
    `;
export const InvoiceTransactionMinimalFragmentDoc = gql`
    fragment invoiceTransactionMinimal on InvoiceTransaction {
  id
  balance
  batchId
  note
  privateNote
  returnNote
  returnProduct
  state
  prettyState
  discardedAt
  amount
  date
  surcharge
  applySurcharge
  createdAt
  metadata {
    stripePaymentIntentId
    stripeResponse {
      ... on InvoiceTransactionStripeResponse {
        id
        object
        amount
        amountCapturable
        amountReceived
        application
        applicationFeeAmount
        canceledAt
        cancellationReason
        captureMethod
        clientSecret
        confirmationMethod
        created
        currency
        customer
        description
        lastPaymentError
        latestCharge
        livemode
        metadata
        paymentMethod
        paymentMethodOptions
        paymentMethodTypes
        processing
        status
        transferData
        transferGroup
        paymentIntent {
          ...paymentIntent
        }
      }
    }
    tyroTransactionId
    tyroRrn
    tyroReference
    tyroCustomerReceipt
    tyroMerchantReceipt
    tyroStatus
    tyroRawResponse
    tyroProviderId
    tyroMerchantId
    tyroTerminalId
    tyroHealthPointTotalBenefitAmount
    tyroHealthPointGapAmount
    tyroHealthPointReference
    tyroResponse {
      ... on InvoiceTransactionTyroHealthpointResponse {
        result
        transactionId
        healthpointRefTag
        healthpointTotalBenefitAmount
        healthpointSettlementDateTime
        healthpointTerminalDateTime
        healthpointMemberNumber
        healthpointProviderId
        healthpointServiceType
        healthpointGapAmount
        healthpointPhfResponseCode
        healthpointPhfResponseCodeDescription
        healthpointHealthFundName
        healthpointHealthFundIdentifyingDigits
        healthpointClaimItems {
          claimAmount
          rebateAmount
          serviceCode
          description
          serviceReference
          patientId
          serviceDate
          responseCode
        }
      }
      ... on InvoiceTransactionTyroEftposResponse {
        result
        transactionId
        cardType
        transactionReference
        authorisationCode
        issuerActionCode
        elidedPan
        rrn
        baseAmount
        transactionAmount
      }
    }
  }
}
    ${PaymentIntentFragmentDoc}`;
export const UserPracticeConnectionPatientPractitionerFragmentDoc = gql`
    fragment userPracticeConnectionPatientPractitioner on UserPracticeLink {
  ...upcId
  slug
  state
  practiceLocationId
  photo {
    ...avatar
  }
  profile {
    ...userPracticeProfileMinimal
  }
}
    ${UpcIdFragmentDoc}
${AvatarFragmentDoc}
${UserPracticeProfileMinimalFragmentDoc}`;
export const InvoiceMinimalFragmentDoc = gql`
    fragment invoiceMinimal on Invoice {
  id
  uuid
  guid
  businessName
  issuedAt
  invoiceState: state
  total
  totalCost
  balance
  tax
  paid
  paidTotal
  discount
  totalExTax
  discountAmount
  quantity
  providerName
  providerId
  dueDate
  billTo
  practiceLocationId
  createTime: createdAt
  visibility {
    ...visibility
  }
}
    ${VisibilityFragmentDoc}`;
export const InvoiceTransactionEssentialsFragmentDoc = gql`
    fragment invoiceTransactionEssentials on InvoiceTransaction {
  ...invoiceTransactionMinimal
  practitioner {
    ...userPracticeConnectionPatientPractitioner
  }
  invoice {
    ...invoiceMinimal
  }
  healthFundsRequest {
    ...healthFundsRequestMinimal
  }
  transactionType
  transactionMethod
  prettyTransactionType
  prettyTransactionMethod
  patientPaid
  practiceReceived
}
    ${InvoiceTransactionMinimalFragmentDoc}
${UserPracticeConnectionPatientPractitionerFragmentDoc}
${InvoiceMinimalFragmentDoc}
${HealthFundsRequestMinimalFragmentDoc}`;
export const PaymentMethodEssentialsFragmentDoc = gql`
    fragment paymentMethodEssentials on PaymentMethod {
  id
  default
  discardedAt
  enabled
  enableSurcharge
  applyPatientSurcharge
  surchargePercentage
  name
  slug
  paymentProvider {
    id
    name
  }
}
    `;
export const PaymentProviderMinimalFragmentDoc = gql`
    fragment paymentProviderMinimal on PaymentProvider {
  id
  name
  enabled
  applyPatientSurcharge
  isTyro
  isStripe
  isDefault
  practiceId
  practiceLocationId
}
    `;
export const PaymentTransactionEssentialsFragmentDoc = gql`
    fragment paymentTransactionEssentials on PaymentTransaction {
  amount
  id
  date
  note
}
    `;
export const InvoiceTransactionDetailedFragmentDoc = gql`
    fragment invoiceTransactionDetailed on InvoiceTransaction {
  ...invoiceTransactionEssentials
  paymentMethod {
    ...paymentMethodEssentials
  }
  paymentProvider {
    ...paymentProviderMinimal
  }
  paymentTransaction {
    ...paymentTransactionEssentials
  }
  healthFundsRequest {
    ...healthFundsRequestEssentials
  }
}
    ${InvoiceTransactionEssentialsFragmentDoc}
${PaymentMethodEssentialsFragmentDoc}
${PaymentProviderMinimalFragmentDoc}
${PaymentTransactionEssentialsFragmentDoc}
${HealthFundsRequestEssentialsFragmentDoc}`;
export const PaymentTransactionDetailedFragmentDoc = gql`
    fragment paymentTransactionDetailed on PaymentTransaction {
  id
  amount
  note
  date
  patientPaid
  practiceReceived
  createdAt
  updatedAt
  practitioner {
    ...userPracticeConnectionPatientPractitioner
  }
  invoiceTransactions {
    ...invoiceTransactionMinimal
  }
  invoice {
    ...invoiceMinimal
  }
}
    ${UserPracticeConnectionPatientPractitionerFragmentDoc}
${InvoiceTransactionMinimalFragmentDoc}
${InvoiceMinimalFragmentDoc}`;
export const PracticePublicDetailedFragmentDoc = gql`
    fragment practicePublicDetailed on Practice {
  ...practiceMinimal
  coverPhoto {
    preview
    cover
  }
  industry {
    id
    name
  }
  location {
    ...locationEssentials
  }
  configuration {
    ...practiceConfigurationLocalization
  }
}
    ${PracticeMinimalFragmentDoc}
${LocationEssentialsFragmentDoc}
${PracticeConfigurationLocalizationFragmentDoc}`;
export const InvoicePublicDetailedFragmentDoc = gql`
    fragment invoicePublicDetailed on Invoice {
  ...invoiceEssentials
  invoiceTransactions {
    ...invoiceTransactionDetailed
  }
  items {
    ...invoiceItemEssentials
  }
  paymentTransactions {
    ...paymentTransactionDetailed
  }
  practice {
    ...practicePublicDetailed
  }
  practiceLocation {
    ...practiceLocationEssentials
  }
}
    ${InvoiceEssentialsFragmentDoc}
${InvoiceTransactionDetailedFragmentDoc}
${InvoiceItemEssentialsFragmentDoc}
${PaymentTransactionDetailedFragmentDoc}
${PracticePublicDetailedFragmentDoc}
${PracticeLocationEssentialsFragmentDoc}`;
export const UserPracticeConnectionScanningIntegrationsFragmentDoc = gql`
    fragment userPracticeConnectionScanningIntegrations on UserPracticeLink {
  ...userPracticeConnectionMinimal
  scanningIntegrations {
    cliniview
    ezdent
    idixel
    newtom
    romexis
    sidexis
    trios
    vistasoft
    dbswin
    scanora
    floridaprobe
    csimaging
    steriStock
    examinepro
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;
export const HealthFundProviderEssentialsFragmentDoc = gql`
    fragment healthFundProviderEssentials on HealthFundProvider {
  id
  name
  slug
  public
  gateways
  createdAt
  updatedAt
}
    `;
export const HealthFundConsumerEssentialsFragmentDoc = gql`
    fragment healthFundConsumerEssentials on HealthFundConsumer {
  id
  primaryIdentifier
  secondaryIdentifier
  provider {
    ...healthFundProviderEssentials
  }
}
    ${HealthFundProviderEssentialsFragmentDoc}`;
export const UserMinimalFragmentDoc = gql`
    fragment userMinimal on User {
  id
  guid
  email
  firstName
  lastName
  mobilePhone
  mobileRegionCode
  mobileRegionShort
  registered
  authenticatorEnabled
  name
  createdAt
}
    `;
export const UserEssentialsFragmentDoc = gql`
    fragment userEssentials on User {
  ...userMinimal
  username
}
    ${UserMinimalFragmentDoc}`;
export const EmergencyContactDetailedFragmentDoc = gql`
    fragment emergencyContactDetailed on EmergencyContact {
  id
  patientId
  userPracticeConnectionId
  title
  firstName
  lastName
  fullNameWithTitle
  fullName
  contactNumber
  relationship
}
    `;
export const UserPracticeConnectionDetailedFragmentDoc = gql`
    fragment userPracticeConnectionDetailed on UserPracticeLink {
  ...userPracticeConnectionEssentials
  ...userPracticeConnectionScanningIntegrations
  accountCredit
  defaultPublicHealthFund {
    ...healthFundConsumerEssentials
  }
  defaultPrivateHealthFund {
    ...healthFundConsumerEssentials
  }
  healthFundIdentifiers {
    ...healthFundConsumerEssentials
  }
  priceList {
    id
    name
  }
  practice {
    id
    name
  }
  user {
    ...userEssentials
  }
  profile {
    ...userPracticeProfileDetailed
  }
  communicationSettings {
    ...communicationSettingsEssentials
  }
  emergencyContact {
    ...emergencyContactDetailed
  }
  outstandingBalanceCount
  primaryPractitioner {
    ...userPracticeConnectionPatientPractitioner
  }
  secondaryPractitioner {
    ...userPracticeConnectionPatientPractitioner
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}
${UserPracticeConnectionScanningIntegrationsFragmentDoc}
${HealthFundConsumerEssentialsFragmentDoc}
${UserEssentialsFragmentDoc}
${UserPracticeProfileDetailedFragmentDoc}
${CommunicationSettingsEssentialsFragmentDoc}
${EmergencyContactDetailedFragmentDoc}
${UserPracticeConnectionPatientPractitionerFragmentDoc}`;
export const InvoiceDetailedFragmentDoc = gql`
    fragment invoiceDetailed on Invoice {
  ...invoiceEssentials
  practitioner {
    ...upcId
  }
  patient {
    ...userPracticeConnectionDetailed
  }
  items {
    ...invoiceItemEssentials
  }
  reminders {
    ...reminderEssentials
  }
}
    ${InvoiceEssentialsFragmentDoc}
${UpcIdFragmentDoc}
${UserPracticeConnectionDetailedFragmentDoc}
${InvoiceItemEssentialsFragmentDoc}
${ReminderEssentialsFragmentDoc}`;
export const PaymentProviderEssentialsFragmentDoc = gql`
    fragment paymentProviderEssentials on PaymentProvider {
  ...paymentProviderMinimal
  practiceLocation {
    ...practiceLocationMinimal
  }
  configuration {
    ... on PaymentProviderTyroConfiguration {
      environment
    }
    ... on PaymentProviderHicapsConfiguration {
      bridgeHost
      https
    }
    ... on PaymentProviderMedipassConfiguration {
      appId
      authToken
      apiKey
      medipassEnvironment: environment
    }
    ... on PaymentProviderStripeConfiguration {
      stripeAccountId
      chargesEnabled
      payoutsEnabled
    }
  }
}
    ${PaymentProviderMinimalFragmentDoc}
${PracticeLocationMinimalFragmentDoc}`;
export const PaymentTerminalEssentialsFragmentDoc = gql`
    fragment paymentTerminalEssentials on PaymentTerminal {
  id
  name
  merchantId
  merchantIds
  additionalMerchantIds
  terminalId
  practiceLocationId
  feedback
  integrationKey
  discardedAt
  createdAt
  updatedAt
  practiceLocation {
    ...practiceLocationEssentials
  }
}
    ${PracticeLocationEssentialsFragmentDoc}`;
export const PaymentProviderDetailedFragmentDoc = gql`
    fragment paymentProviderDetailed on PaymentProvider {
  ...paymentProviderEssentials
  paymentMethods {
    ...paymentMethodEssentials
  }
  paymentTerminals {
    ...paymentTerminalEssentials
  }
}
    ${PaymentProviderEssentialsFragmentDoc}
${PaymentMethodEssentialsFragmentDoc}
${PaymentTerminalEssentialsFragmentDoc}`;
export const PaymentProviderStripeDetailedFragmentDoc = gql`
    fragment paymentProviderStripeDetailed on PaymentProvider {
  ...paymentProviderDetailed
  configuration {
    ... on PaymentProviderStripeConfiguration {
      capabilities {
        transfers
        cardPayments
      }
      chargesEnabled
      payoutsEnabled
      stripeZavyFee
      stripeBaseFee
      canProcessPayments
      canProcessAndApplyPatientSurcharge
    }
  }
}
    ${PaymentProviderDetailedFragmentDoc}`;
export const InvoiceBillableUpcFragmentDoc = gql`
    fragment invoiceBillableUpc on UserPracticeLink {
  ...upcTiny
  id
  guid
  familyId
  priceListId
  primaryPractitionerId
  secondaryPractitionerId
}
    ${UpcTinyFragmentDoc}`;
export const LabWorkEssentialsFragmentDoc = gql`
    fragment labWorkEssentials on LabWork {
  id
  appointmentId
  contactFullName
  contactId
  cost
  dueAt
  state
  prettyState
  notes
  updatedAt
  createdAt
  discardedAt
}
    `;
export const AppointmentEssentialsFragmentDoc = gql`
    fragment appointmentEssentials on Appointment {
  ...appointmentMinimal
  reason
  appointmentReason {
    ...appointmentReasonDetailed
  }
  appointmentGuests {
    ...appointmentGuestDetailed
  }
}
    ${AppointmentMinimalFragmentDoc}
${AppointmentReasonDetailedFragmentDoc}
${AppointmentGuestDetailedFragmentDoc}`;
export const LabWorkDetailedFragmentDoc = gql`
    fragment labWorkDetailed on LabWork {
  ...labWorkEssentials
  appointment {
    ...appointmentEssentials
  }
  userPracticeConnection {
    ...userPracticeConnectionMinimal
  }
  userProfile {
    ...userPracticeProfileDetailed
  }
  requestedBy {
    ...userPracticeConnectionMinimal
  }
  contact {
    ...userPracticeConnectionMinimal
  }
  requesterProfile {
    ...userPracticeProfileDetailed
  }
}
    ${LabWorkEssentialsFragmentDoc}
${AppointmentEssentialsFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${UserPracticeProfileDetailedFragmentDoc}`;
export const PatientEssentialsFragmentDoc = gql`
    fragment patientEssentials on Patient {
  id
  lastName
  firstName
  workPhone
  dob
  slug
  homePhone
  email
  mobilePhone
  mobileRegionCode
  mobileRegionShort
  updatedAt
  createdAt
  userPracticeConnection {
    id
    guid
  }
}
    `;
export const PatientDetailedFragmentDoc = gql`
    fragment patientDetailed on Patient {
  ...patientEssentials
  forms {
    ...form
  }
}
    ${PatientEssentialsFragmentDoc}
${FormFragmentDoc}`;
export const AccountDetailedFragmentDoc = gql`
    fragment accountDetailed on Account {
  ...accountEssentials
  maxPractitionerCount
  smsCredits
  planName
  isPremium
  activePractitionersCount
  createdAt
}
    ${AccountEssentialsFragmentDoc}`;
export const MailSettingsDetailedFragmentDoc = gql`
    fragment mailSettingsDetailed on PracticeMailSettings {
  id
  guid
  address
  port
  domain
  userName
  password
  authentication
  enableStarttlsAuto
  fromEmailAddress
  practiceId
  createdAt
  updatedAt
}
    `;
export const OnlineBookingSettingsEssentialsFragmentDoc = gql`
    fragment onlineBookingSettingsEssentials on OnlineBookingSetting {
  ...onlineBookingSettingsMinimal
  practiceLocation {
    ...practiceLocationMinimal
  }
}
    ${OnlineBookingSettingsMinimalFragmentDoc}
${PracticeLocationMinimalFragmentDoc}`;
export const PracticePreferenceFragmentDoc = gql`
    fragment practicePreference on PracticePreference {
  id
  value
}
    `;
export const UpcAvatarFragmentDoc = gql`
    fragment upcAvatar on UserPracticeLink {
  ...upcId
  slug
  userId
  practiceGuid
  profile {
    ...userPracticeProfileMinimal
  }
}
    ${UpcIdFragmentDoc}
${UserPracticeProfileMinimalFragmentDoc}`;
export const PracticeExhaustiveFragmentDoc = gql`
    fragment practiceExhaustive on Practice {
  ...practiceDetailed
  practitioners {
    ...upcAvatar
  }
}
    ${PracticeDetailedFragmentDoc}
${UpcAvatarFragmentDoc}`;
export const PracticeSelectEssentialsFragmentDoc = gql`
    fragment practiceSelectEssentials on AccountPractice {
  id
  guid
  name
}
    `;
export const PracticeLocationSelectEssentialsFragmentDoc = gql`
    fragment practiceLocationSelectEssentials on AdminPracticeLocation {
  id
  name
  shortName
}
    `;
export const AccountStripeDetailsFragmentDoc = gql`
    fragment accountStripeDetails on Account {
  ...accountEssentials
  last4
  creditCardBrand
  expiringMonth
  expiringYear
  creditCardName
  createdAt
}
    ${AccountEssentialsFragmentDoc}`;
export const PracticeSubscriptionFragmentDoc = gql`
    fragment practiceSubscription on Practice {
  id
  guid
  name
  account {
    ...accountStripeDetails
  }
  accountPlan {
    ...accountPlanDetailed
  }
}
    ${AccountStripeDetailsFragmentDoc}
${AccountPlanDetailedFragmentDoc}`;
export const DashboardAnnotationFragmentDoc = gql`
    fragment dashboardAnnotation on DashboardAnnotation {
  id
  widgetType
  label
  value
  colour
}
    `;
export const PracticeConfigurationDashboardFragmentDoc = gql`
    fragment practiceConfigurationDashboard on PracticeConfiguration {
  id
  dashboardAnnotations {
    ...dashboardAnnotation
  }
}
    ${DashboardAnnotationFragmentDoc}`;
export const ReminderMinimalFragmentDoc = gql`
    fragment reminderMinimal on Reminder {
  id
  state
  triggeredAt
  contactType
  failureCauses
}
    `;
export const RecallDetailedFragmentDoc = gql`
    fragment recallDetailed on Recall {
  ...recallEssentials
  reminders {
    ...reminderMinimal
  }
  template {
    ...templateEssentials
  }
}
    ${RecallEssentialsFragmentDoc}
${ReminderMinimalFragmentDoc}
${TemplateEssentialsFragmentDoc}`;
export const ReferralSourceEssentialsFragmentDoc = gql`
    fragment referralSourceEssentials on ReferralSource {
  id
  guid
  practiceId
  categoryId
  name
  description
  state
  category {
    id
    guid
    name
  }
  createdAt
  updatedAt
}
    `;
export const ReferralMinimalFragmentDoc = gql`
    fragment referralMinimal on Referral {
  id
  guid
  uuid
  referralState: state
  referrerType
  referrerId
  referrerName
  referrerGuid
  referrerRole
  referentType
  referentId
  referentName
  referentGuid
  referralType
  createdAt
  updatedAt
}
    `;
export const ReferralDetailedFragmentDoc = gql`
    fragment referralDetailed on Referral {
  ...referralMinimal
  state
  practiceId
  initialSource
  description
  totalAppointments
  lastAppointment
  nextAppointment
  totalInvoiced
  attachments {
    ...attachmentEssentials
  }
  richTexts {
    ...richTextMinimal
  }
}
    ${ReferralMinimalFragmentDoc}
${AttachmentEssentialsFragmentDoc}
${RichTextMinimalFragmentDoc}`;
export const AppointmentSummaryEssentialsFragmentDoc = gql`
    fragment appointmentSummaryEssentials on AppointmentSummary {
  completed
  cancelled
  missed
  lateCancellation
  inProgress
  scheduled
  unconfirmed
  totalBooked
  total
}
    `;
export const FinanceSummaryEssentialsFragmentDoc = gql`
    fragment financeSummaryEssentials on FinanceSummary {
  name
  previous
  current
}
    `;
export const InvoiceTransactionSummaryEssentialsFragmentDoc = gql`
    fragment invoiceTransactionSummaryEssentials on InvoiceTransactionSummary {
  invoiceTransactions {
    transactionMethod
    amount
    noOfTransactions
  }
  pieChart
}
    `;
export const ReferralSourcesSummaryEssentialsFragmentDoc = gql`
    fragment referralSourcesSummaryEssentials on ReferralSourcesSummary {
  referrerId
  referrerType
  referrerGuid
  referrerTypeLabel
  name
  lifetimeValue
  total
}
    `;
export const MarketingStatisticsEssentialsFragmentDoc = gql`
    fragment marketingStatisticsEssentials on MarketingStatistics {
  topReferrerName
  topReferrerValue
  highestRevenueReferentName
  highestRevenueReferral
  totalReferralIn
  totalReferralOut
}
    `;
export const ReferralSourceStatisticsEssentialsFragmentDoc = gql`
    fragment referralSourceStatisticsEssentials on ReferralSourceStatistics {
  referrerName
  total
  totalRevenue
  averageReferralRevenue
}
    `;
export const ReportEssentialsFragmentDoc = gql`
    fragment reportEssentials on Report {
  chartData {
    columnChart {
      date
      value
      group
      name
    }
    bulletChart {
      professional
      ranges
      measures
      target
    }
    pieChart {
      type
      value
    }
    bidirectionalChart {
      type
      refund
      paid
    }
  }
  reportType
  detailedList {
    ... on AppointmentDetailedList {
      id
      slug
      state
      type
      patientName
      patientGuid
      subject
      practitionerName
      duration
      patientRecordId
      practiceId
      start
      end
      appointmentType
      appointmentGuests {
        patientName
        patientGuid
      }
    }
    ... on BirthdayDetailedList {
      id
      fullName
      dob
      age
    }
    ... on EmailDetailedList {
      id
      content
      createdAtLong
      state
      whom
      sendAtLong
      state
      toEmail
      fromEmail
    }
    ... on InvoiceItemDetailedList {
      id
      description
      quantity
      price
      total
      invoiceId
      discount
      tax
      code
      name
      practiceId
      patientName
      displayCreatedAt
      displayReadyToInvoiceAt
      displayItemCreatedAt
      displayTaxTotal
      itemName
      itemCode
      patientGuid
      practiceProfessionalName
      patientRecordId
    }
    ... on InvoiceTransactionDetailedList {
      id
      invoiceId
      practiceProfessionalId
      transactionType
      state
      amount
      displayAmount
      fullName
      practiceId
      practiceName
      patientGuid
      transactionMethod
      createdAt
      createdAtLabel
      updatedAt
      note
      returnNote
      privateNote
      transactionTypeLabel
      transactionMethodLabel
      stateLabel
      balance
      patientRecordId
      displayTaxTotal
    }
    ... on OutstandingInvoiceDetailedList {
      id
      displayState
      displayIssuedAt
      displayBalance
      displayInvoiceTotal
      displayPaidTotal
      displayTaxTotal
      practiceId
      patientGuid
      practiceName
      patientName
      patientRecordId
    }
    ... on ProductivityDetailedList {
      completedItems
      totalAppointments
      treatmentAcceptance
      hoursAvailable
      hoursWorked
      inefficientHours
      hourlyRate
      potentialEarnings
    }
    ... on ShortMessageDetailedList {
      id
      content
      createdAt
      createdAtLong
      whom
      state
      receiver
      sendAtLong
      failureCauses
      initials
      displayState
    }
  }
  summaryList {
    ... on AppointmentSummaryList {
      appointmentState: state
      count
    }
    ... on OutstandingInvoiceSummaryList {
      id
      name
      tax
      amount
    }
    ... on InvoiceItemSummaryList {
      name
      noOfItems
      tax
      masterPrice
    }
    ... on InvoiceTransactionsSummaryList {
      id
      name
      tax
      amount
    }
    ... on EmailSummaryList {
      emailState: state
      noOfMessage
    }
  }
}
    `;
export const ReportsSavedReportEssentialsFragmentDoc = gql`
    fragment reportsSavedReportEssentials on ReportsSavedReport {
  id
  name
  description
  reportType
  state {
    datePreset
    columnState
    filterModel
    groupState
  }
  practice {
    id
    guid
    name
  }
  practitioner {
    id
    guid
    photo {
      id
      medium
    }
    profile {
      id
      guid
      fullName
      avatarColor
    }
  }
  createdAt
  updatedAt
  discardedAt
}
    `;
export const RosterShiftMinimalFragmentDoc = gql`
    fragment rosterShiftMinimal on RosterShift {
  id
  guid
  startTime
  endTime
  onlineBookings
}
    `;
export const RosterShiftTypeMinimalFragmentDoc = gql`
    fragment rosterShiftTypeMinimal on RosterShiftType {
  id
  guid
  name
  state
}
    `;
export const RosterShiftTypeEssentialsFragmentDoc = gql`
    fragment rosterShiftTypeEssentials on RosterShiftType {
  ...rosterShiftTypeMinimal
  blockAvailability
  colour
}
    ${RosterShiftTypeMinimalFragmentDoc}`;
export const RosterShiftEssentialsFragmentDoc = gql`
    fragment rosterShiftEssentials on RosterShift {
  ...rosterShiftMinimal
  shiftType {
    ...rosterShiftTypeEssentials
  }
}
    ${RosterShiftMinimalFragmentDoc}
${RosterShiftTypeEssentialsFragmentDoc}`;
export const RosterEventEssentialsFragmentDoc = gql`
    fragment rosterEventEssentials on RosterEvent {
  id
  endDate
  userPracticeConnectionId
  recurringFrequency
  recurringType
  shifts {
    ...rosterShiftEssentials
  }
}
    ${RosterShiftEssentialsFragmentDoc}`;
export const RosterEventExhaustiveFragmentDoc = gql`
    fragment rosterEventExhaustive on RosterEvent {
  ...rosterEventEssentials
  id
  endDate
  recurringRate
  recurringFrequency
  recurringType
  shifts {
    ...rosterShiftEssentials
  }
}
    ${RosterEventEssentialsFragmentDoc}
${RosterShiftEssentialsFragmentDoc}`;
export const RosterShiftTypeExhaustiveFragmentDoc = gql`
    fragment rosterShiftTypeExhaustive on RosterShiftType {
  ...rosterShiftTypeEssentials
  blockAvailability
  colour
}
    ${RosterShiftTypeEssentialsFragmentDoc}`;
export const RosterShiftExhaustiveFragmentDoc = gql`
    fragment rosterShiftExhaustive on RosterShift {
  ...rosterShiftMinimal
  shiftType {
    ...rosterShiftTypeExhaustive
  }
}
    ${RosterShiftMinimalFragmentDoc}
${RosterShiftTypeExhaustiveFragmentDoc}`;
export const SterilisationAutoclaveMinimalFragmentDoc = gql`
    fragment sterilisationAutoclaveMinimal on SterilisationAutoclave {
  id
  name
  description
  model
  serial
  manufacture
  practiceLocationId
  cycleCount
  discardedAt
  createdAt
  updatedAt
}
    `;
export const SterilisationAutoclaveEssentialsFragmentDoc = gql`
    fragment sterilisationAutoclaveEssentials on SterilisationAutoclave {
  ...sterilisationAutoclaveMinimal
  photo {
    id
    preview
    cover
  }
  practitioner {
    ...userPracticeConnectionMinimal
  }
  practiceLocation {
    ...practiceLocationMinimal
  }
  practiceGuid
  practitionerGuid
  purchaseDate
  purchaseSupplier
  purchaseCost
  purchaseWarrantyMonths
}
    ${SterilisationAutoclaveMinimalFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${PracticeLocationMinimalFragmentDoc}`;
export const SterilisationAutoclaveDetailedFragmentDoc = gql`
    fragment sterilisationAutoclaveDetailed on SterilisationAutoclave {
  ...sterilisationAutoclaveEssentials
}
    ${SterilisationAutoclaveEssentialsFragmentDoc}`;
export const SterilisationCycleTypeMinimalFragmentDoc = gql`
    fragment sterilisationCycleTypeMinimal on SterilisationCycleType {
  id
  name
  cycleCount
  discardedAt
  createdAt
  updatedAt
}
    `;
export const SterilisationCycleTypeEssentialsFragmentDoc = gql`
    fragment sterilisationCycleTypeEssentials on SterilisationCycleType {
  ...sterilisationCycleTypeMinimal
  practitioner {
    ...userPracticeConnectionMinimal
  }
  practiceGuid
  practitionerGuid
}
    ${SterilisationCycleTypeMinimalFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const SterilisationCycleTypeDetailedFragmentDoc = gql`
    fragment sterilisationCycleTypeDetailed on SterilisationCycleType {
  ...sterilisationCycleTypeEssentials
}
    ${SterilisationCycleTypeEssentialsFragmentDoc}`;
export const SterilisationTrackingMinimalFragmentDoc = gql`
    fragment sterilisationTrackingMinimal on SterilisationTracking {
  id
  cycleId
  discardedAt
  createdAt
  updatedAt
}
    `;
export const SterilisationCycleMinimalFragmentDoc = gql`
    fragment sterilisationCycleMinimal on SterilisationCycle {
  id
  date
  number
  barcode
  notes
  cycleState
  practiceLocationId
  inspectionState
  locked
  lockedAt
  discardedAt
  createdAt
  updatedAt
}
    `;
export const SterilisationTrackingEssentialsFragmentDoc = gql`
    fragment sterilisationTrackingEssentials on SterilisationTracking {
  ...sterilisationTrackingMinimal
  cycle {
    ...sterilisationCycleMinimal
  }
  practitioner {
    ...userPracticeConnectionMinimal
  }
  patient {
    ...userPracticeConnectionMinimal
  }
  trackableType
  trackableId
  patientGuid
  practiceGuid
  practitionerGuid
}
    ${SterilisationTrackingMinimalFragmentDoc}
${SterilisationCycleMinimalFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const SterilisationCycleEssentialsFragmentDoc = gql`
    fragment sterilisationCycleEssentials on SterilisationCycle {
  ...sterilisationCycleMinimal
  cycleType {
    ...sterilisationCycleTypeMinimal
  }
  autoclave {
    ...sterilisationAutoclaveMinimal
  }
  practitioner {
    ...userPracticeConnectionMinimal
  }
  practiceLocation {
    ...practiceLocationMinimal
  }
  cycleTypeId
  autoclaveId
  expireAt
  startedAt
  startedByGuid
  loadedAt
  loadedByGuid
  completedAt
  completedByGuid
  unloadedAt
  unloadedByGuid
  inspectedAt
  inspectedByGuid
  practiceGuid
  practitionerGuid
}
    ${SterilisationCycleMinimalFragmentDoc}
${SterilisationCycleTypeMinimalFragmentDoc}
${SterilisationAutoclaveMinimalFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${PracticeLocationMinimalFragmentDoc}`;
export const SterilisationCycleDetailedFragmentDoc = gql`
    fragment sterilisationCycleDetailed on SterilisationCycle {
  ...sterilisationCycleEssentials
  startedBy {
    ...userPracticeConnectionMinimal
  }
  loadedBy {
    ...userPracticeConnectionMinimal
  }
  completedBy {
    ...userPracticeConnectionMinimal
  }
  unloadedBy {
    ...userPracticeConnectionMinimal
  }
  inspectedBy {
    ...userPracticeConnectionMinimal
  }
}
    ${SterilisationCycleEssentialsFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const SterilisationTrackingDetailedFragmentDoc = gql`
    fragment sterilisationTrackingDetailed on SterilisationTracking {
  ...sterilisationTrackingEssentials
  cycle {
    ...sterilisationCycleDetailed
  }
  trackable {
    ... on UserPracticeLink {
      ...userPracticeConnectionMinimal
    }
  }
}
    ${SterilisationTrackingEssentialsFragmentDoc}
${SterilisationCycleDetailedFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}`;
export const TreatmentPlanAppointmentFragmentDoc = gql`
    fragment treatmentPlanAppointment on Appointment {
  id
  slug
  uuid
  guid
  state
  colour
  startTime
  endTime
  subject
  minutes
  notes
  privateNote
  treatmentPlanId
  labworksCount
  requestsInProgressCount
  requestsCompletedCount
  position
}
    `;
export const TreatmentPlanWithItemsFragmentDoc = gql`
    fragment treatmentPlanWithItems on TreatmentPlan {
  ...treatmentPlanEssentials
  patientRecordItems {
    id
    name
    code
    state
    minutes
    priceIncTax
    guid
    fdi
    surfaceCode
    quantity
    tax
    total
    totalExTax
    totalTax
  }
}
    ${TreatmentPlanEssentialsFragmentDoc}`;
export const TreatmentPlanDetailedFragmentDoc = gql`
    fragment treatmentPlanDetailed on TreatmentPlan {
  ...treatmentPlanWithItems
  treatment {
    ...treatmentMinimal
    patientRecordId
    practitioner {
      ...upcId
    }
    defaultPriceList {
      id
      name
    }
  }
  lexicalPdf {
    ...attachmentEssentials
  }
  appointments {
    id
    position
    startTime
    createdAt
  }
}
    ${TreatmentPlanWithItemsFragmentDoc}
${TreatmentMinimalFragmentDoc}
${UpcIdFragmentDoc}
${AttachmentEssentialsFragmentDoc}`;
export const TreatmentListPatientRecordItemFragmentDoc = gql`
    fragment treatmentListPatientRecordItem on PatientRecordItem {
  id
  guid
  code
  state
}
    `;
export const TreatmentPlanListItemFragmentDoc = gql`
    fragment treatmentPlanListItem on TreatmentPlan {
  ...treatmentPlanMinimal
  treatmentPlanState: state
  requiredVisits
  totalCost
  patientRecordItems {
    ...treatmentListPatientRecordItem
  }
}
    ${TreatmentPlanMinimalFragmentDoc}
${TreatmentListPatientRecordItemFragmentDoc}`;
export const TreatmentListItemFragmentDoc = gql`
    fragment treatmentListItem on Treatment {
  ...treatmentMinimal
  treatmentPlans {
    ...treatmentPlanListItem
  }
  practitioner {
    ...userPracticeConnectionMinimal
  }
  referral {
    ...referralMinimal
  }
}
    ${TreatmentMinimalFragmentDoc}
${TreatmentPlanListItemFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${ReferralMinimalFragmentDoc}`;
export const TreatmentDetailedFragmentDoc = gql`
    fragment treatmentDetailed on Treatment {
  ...treatmentEssentials
  practitionerId
  patientRecord {
    id
    fullName
  }
  defaultPriceList {
    id
    name
  }
  treatmentPlans {
    ...treatmentPlanWithItems
  }
}
    ${TreatmentEssentialsFragmentDoc}
${TreatmentPlanWithItemsFragmentDoc}`;
export const NoteEssentialsFragmentDoc = gql`
    fragment noteEssentials on Note {
  ...noteMinimal
  attachments {
    ...attachmentEssentials
  }
  notable {
    ... on UserPracticeLink {
      ...userPracticeConnectionMinimal
    }
    ... on Treatment {
      ...treatmentEssentials
    }
    ... on TreatmentPlan {
      ...treatmentPlanMinimal
    }
    ... on DentalTooth {
      ...dentalTooth
    }
  }
  author {
    ...userPracticeConnectionMinimal
  }
  updatedBy {
    ...userPracticeConnectionMinimal
  }
  patient {
    ...userPracticeConnectionMinimal
  }
}
    ${NoteMinimalFragmentDoc}
${AttachmentEssentialsFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${TreatmentEssentialsFragmentDoc}
${TreatmentPlanMinimalFragmentDoc}
${DentalToothFragmentDoc}`;
export const PatientRecordItemsTableRowFragmentDoc = gql`
    fragment patientRecordItemsTableRow on PatientRecordItem {
  id
  name
  code
  state
  minutes
  priceIncTax
  guid
  fdi
  surfaceCode
  quantity
  tax
  total
  totalExTax
  totalTax
  appointmentId
}
    `;
export const TreatmentPlanPdfFragmentDoc = gql`
    fragment treatmentPlanPdf on TreatmentPlan {
  ...treatmentPlanEssentials
  chartImage {
    url
  }
  notes {
    ...noteEssentials
  }
  patientRecordItems {
    ...patientRecordItemsTableRow
  }
  appointments {
    id
    guid
    uuid
    subject
    title
    state
    minutes
    estimatedMinutes
    appNotes
    notes
    patientRecordItems {
      ...patientRecordItemsTableRow
    }
  }
}
    ${TreatmentPlanEssentialsFragmentDoc}
${NoteEssentialsFragmentDoc}
${PatientRecordItemsTableRowFragmentDoc}`;
export const TreatmentPdfFragmentDoc = gql`
    fragment treatmentPDF on Treatment {
  ...treatmentEssentials
  treatmentPlans {
    ...treatmentPlanPdf
  }
  patient {
    ...userPracticeConnectionMinimal
    profile {
      ...userPracticeProfileMinimal
      homeAddress {
        ...locationEssentials
      }
    }
  }
  practitioner {
    ...userPracticeConnectionMinimal
    practice {
      ...practiceMinimal
      location {
        ...locationEssentials
      }
    }
  }
}
    ${TreatmentEssentialsFragmentDoc}
${TreatmentPlanPdfFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${UserPracticeProfileMinimalFragmentDoc}
${LocationEssentialsFragmentDoc}
${PracticeMinimalFragmentDoc}`;
export const PatientRecordEssentialsFragmentDoc = gql`
    fragment patientRecordEssentials on PatientRecord {
  id
  fullName
  firstName
  lastName
  state
  email
  mobilePhone
}
    `;
export const PracticeProfessionalsEssentialsFragmentDoc = gql`
    fragment practiceProfessionalsEssentials on PracticeProfessional {
  id
  avatarColour
  firstName
  fullName
  fullNameWithTitle
  initials
}
    `;
export const RelativeDetailedFragmentDoc = gql`
    fragment relativeDetailed on Relative {
  id
  relationship
  isDefault
  firstName
  lastName
  dateOfBirth
  internationalMobile
}
    `;
export const UpcPreferencesFragmentDoc = gql`
    fragment upcPreferences on Preferences {
  id
  hideEmptyNotesTitle
  showTaskHistory
  showRecallTasks
  showUserTasks
  showPatientTreatmentNotes
  calendarDefaultToday
  calendarSlotDuration
  calendarInitialDate
  calendarPractitionerIds
  calendarPractitionerSortedIds {
    id
    index
  }
  catalogueItemFavoriteIds
  calendarExcludePractitionerIds
  calendarDefaultView
  calendarSingleClickDrawer
  calendarAutoshowQuickAvailability
  calendarExcludeCancelled
  calendarSlotMinTime
  calendarSlotMaxTime
  calendarHideSaturday
  calendarHideSunday
  calendarDisplayCompletedColour
  calendarDisplayCancelledColour
  calendarRoomIds
  calendarResourceType
  calendarColumnWidth
}
    `;
export const UpcDashboardWidgetPreferenceFragmentDoc = gql`
    fragment upcDashboardWidgetPreference on DashboardWidget {
  id
  i
  x
  y
  w
  h
}
    `;
export const UpcDashboardWidgetLayoutPreferenceFragmentDoc = gql`
    fragment upcDashboardWidgetLayoutPreference on DashboardWidgetLayout {
  id
  lg {
    ...upcDashboardWidgetPreference
  }
  md {
    ...upcDashboardWidgetPreference
  }
  sm {
    ...upcDashboardWidgetPreference
  }
  xs {
    ...upcDashboardWidgetPreference
  }
  xxs {
    ...upcDashboardWidgetPreference
  }
}
    ${UpcDashboardWidgetPreferenceFragmentDoc}`;
export const UpcDashboardPreferencesFragmentDoc = gql`
    fragment upcDashboardPreferences on Preferences {
  id
  dashboardWidgets {
    ...upcDashboardWidgetLayoutPreference
  }
}
    ${UpcDashboardWidgetLayoutPreferenceFragmentDoc}`;
export const PatientCreditHistoryEssentialsFragmentDoc = gql`
    fragment patientCreditHistoryEssentials on PatientRecordCredit {
  id
  amount
  date
  currentBalance
  paymentTransactionId
  practitioner {
    ...userPracticeConnectionMinimal
  }
  description
  creditableId
  creditableSlug
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;
export const UpcInvoiceSummaryFragmentDoc = gql`
    fragment upcInvoiceSummary on UserPracticeLink {
  ...upcId
  outstandingBalanceCount
  readyToInvoiceItemsCost
  proposedInvoiceItemsCost
  accountCredit
}
    ${UpcIdFragmentDoc}`;
export const UpcCommunicationRecipientFieldsFragmentDoc = gql`
    fragment upcCommunicationRecipientFields on UserPracticeLink {
  ...upcId
  contacts {
    ...contactEssentials
  }
  role {
    id
    slug
    name
  }
  profile {
    id
    email
    internationalMobile
    fullName
    homeAddress {
      ...locationEssentials
    }
  }
}
    ${UpcIdFragmentDoc}
${ContactEssentialsFragmentDoc}
${LocationEssentialsFragmentDoc}`;
export const UpcShortMessageFieldsFragmentDoc = gql`
    fragment upcShortMessageFields on UserPracticeLink {
  ...upcId
  unreadMessageCount
  lastMessageAt
  lastMessageReadAt
  photo {
    ...avatar
  }
  profile {
    id
    initials
    internationalMobile
    fullNameWithTitle
    avatarColor
  }
  lastMessage {
    content
    createdAt
  }
}
    ${UpcIdFragmentDoc}
${AvatarFragmentDoc}`;
export const UserPracticeConnectionStaffListFragmentDoc = gql`
    fragment userPracticeConnectionStaffList on UserPracticeLink {
  ...userPracticeConnectionContactDetails
  isGlobalAdmin
  practiceLocationId
  profile {
    ...userPracticeProfileMinimal
    calendarAvailability
  }
}
    ${UserPracticeConnectionContactDetailsFragmentDoc}
${UserPracticeProfileMinimalFragmentDoc}`;
export const UpcStaffListFragmentDoc = gql`
    fragment upcStaffList on UserPracticeLink {
  ...userPracticeConnectionMinimal
  isGlobalAdmin
  user {
    id
    authenticatorEnabled
  }
  practiceLocationId
  profile {
    ...userPracticeProfileMinimal
    calendarAvailability
    sendDailySummary
    dailySummaryEmail
  }
  invite {
    id
    guid
    invitationUrl
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}
${UserPracticeProfileMinimalFragmentDoc}`;
export const UserPracticeConnectionPractitionerEssentialsFragmentDoc = gql`
    fragment userPracticeConnectionPractitionerEssentials on UserPracticeLink {
  ...userPracticeConnectionEssentials
  prescriberNumber
  qualifications
  contractIdentifiers {
    ...contractIdentifierEssentials
  }
  user {
    ...userEssentials
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}
${ContractIdentifierEssentialsFragmentDoc}
${UserEssentialsFragmentDoc}`;
export const UserPracticeConnectionStaffMinimalFragmentDoc = gql`
    fragment userPracticeConnectionStaffMinimal on UserPracticeLink {
  ...userPracticeConnectionEssentials
  appointmentReasons {
    ...appointmentReasonEssentials
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}
${AppointmentReasonEssentialsFragmentDoc}`;
export const UserPracticeConnectionStaffEssentialsFragmentDoc = gql`
    fragment userPracticeConnectionStaffEssentials on UserPracticeLink {
  ...userPracticeConnectionEssentials
  user {
    id
    username
  }
  contractIdentifiers {
    ...contractIdentifierEssentials
  }
  appointmentReasons {
    ...appointmentReasonEssentials
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}
${ContractIdentifierEssentialsFragmentDoc}
${AppointmentReasonEssentialsFragmentDoc}`;
export const UserPracticeConnectionStaffRosterFragmentDoc = gql`
    fragment userPracticeConnectionStaffRoster on UserPracticeLink {
  ...upcId
  profile {
    id
    fullName
    fullNameWithTitle
  }
  practiceLocationId
  rosterDays(startDate: $startDate, endDate: $endDate) {
    day
    event {
      ...rosterEventEssentials
    }
  }
}
    ${UpcIdFragmentDoc}
${RosterEventEssentialsFragmentDoc}`;
export const UserPracticeConnectionEmergencyContactsFragmentDoc = gql`
    fragment userPracticeConnectionEmergencyContacts on UserPracticeLink {
  ...upcId
  emergencyContact {
    ...emergencyContactDetailed
  }
}
    ${UpcIdFragmentDoc}
${EmergencyContactDetailedFragmentDoc}`;
export const UserPracticeConnectionCommunicationSettingsFragmentDoc = gql`
    fragment userPracticeConnectionCommunicationSettings on UserPracticeLink {
  ...upcId
  communicationSettings {
    ...communicationSettingsEssentials
  }
}
    ${UpcIdFragmentDoc}
${CommunicationSettingsEssentialsFragmentDoc}`;
export const UserPracticeConnectionDefaultHealthFundsFragmentDoc = gql`
    fragment userPracticeConnectionDefaultHealthFunds on UserPracticeLink {
  ...upcId
  defaultPublicHealthFund {
    ...healthFundConsumerEssentials
  }
  defaultPrivateHealthFund {
    ...healthFundConsumerEssentials
  }
}
    ${UpcIdFragmentDoc}
${HealthFundConsumerEssentialsFragmentDoc}`;
export const UserDetailedFragmentDoc = gql`
    fragment userDetailed on User {
  confirmationToken
  confirmedAt
  ...userEssentials
}
    ${UserEssentialsFragmentDoc}`;
export const UserPracticeConnectionPollFragmentDoc = gql`
    fragment userPracticeConnectionPoll on UserPracticeLink {
  ...upcId
  user {
    ...userDetailed
  }
}
    ${UpcIdFragmentDoc}
${UserDetailedFragmentDoc}`;
export const VersionHistoryFragmentDoc = gql`
    fragment versionHistory on Version {
  id
  fields {
    id
    owner
    date
    changes
  }
}
    `;
export const RecurringTaskEssentialsFragmentDoc = gql`
    fragment recurringTaskEssentials on RecurringTask {
  id
  state
  title
  description
  recurringFrequency
  dueAtPeriod
  dueAtFrequency
  endAt
  startAt
  lastRunAt
  color
  updatedAt
  createdAt
  taskAssignees {
    ...userPracticeConnectionMinimal
  }
  days
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;
export const TaskAssigneeEssentialsFragmentDoc = gql`
    fragment taskAssigneeEssentials on TaskAssignee {
  id
  taskId
  practiceProfessionalId
  userPracticeConnectionId
  userPracticeConnection {
    ...userPracticeConnectionMinimal
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;
export const TaskEssentialsFragmentDoc = gql`
    fragment taskEssentials on Task {
  id
  state
  title
  description
  dueAt
  dueAtLabel
  color
  updatedAt
  createdAt
  recurringTask {
    ...recurringTaskEssentials
  }
  taskAssignees {
    ...taskAssigneeEssentials
  }
  reminder {
    ...reminderEssentials
  }
}
    ${RecurringTaskEssentialsFragmentDoc}
${TaskAssigneeEssentialsFragmentDoc}
${ReminderEssentialsFragmentDoc}`;
export const PracticeEssentialsFragmentDoc = gql`
    fragment practiceEssentials on Practice {
  ...practiceMinimal
  configuration {
    ...practiceConfigurationEssentials
  }
}
    ${PracticeMinimalFragmentDoc}
${PracticeConfigurationEssentialsFragmentDoc}`;
export const WorkflowStepEssentialsFragmentDoc = gql`
    fragment workflowStepEssentials on WorkflowStep {
  id
  guid
  contactType
  templateId
  position
  smsContent
  emailContent
  subject
  frequency
  period
  assignableRole
  createdAt
  updatedAt
}
    `;
export const WorkflowDetailedFragmentDoc = gql`
    fragment workflowDetailed on Workflow {
  id
  guid
  name
  state
  workflowType
  updatedAt
  createdAt
  practice {
    ...practiceEssentials
  }
  steps {
    ...workflowStepEssentials
  }
}
    ${PracticeEssentialsFragmentDoc}
${WorkflowStepEssentialsFragmentDoc}`;
export const WorkflowMinimalFragmentDoc = gql`
    fragment workflowMinimal on Workflow {
  id
  guid
  name
  state
}
    `;
export const UuidNodeFragmentDoc = gql`
    fragment UUIDNode on UUIDNode {
  ... on Appointment {
    ...appointmentTiny
  }
  ... on AppointmentGuest {
    ...appointmentGuestTiny
  }
  ... on Attachment {
    ...attachmentMinimal
  }
  ... on CatalogueItem {
    ...catalogueItemMinimal
  }
  ... on Invoice {
    ...invoiceMinimal
  }
  ... on Treatment {
    ...treatmentMinimal
  }
  ... on TreatmentPlan {
    ...treatmentPlanMinimal
  }
  ... on UserPracticeLink {
    ...upcTiny
  }
  ... on Referral {
    ...referralMinimal
  }
}
    ${AppointmentTinyFragmentDoc}
${AppointmentGuestTinyFragmentDoc}
${AttachmentMinimalFragmentDoc}
${CatalogueItemMinimalFragmentDoc}
${InvoiceMinimalFragmentDoc}
${TreatmentMinimalFragmentDoc}
${TreatmentPlanMinimalFragmentDoc}
${UpcTinyFragmentDoc}
${ReferralMinimalFragmentDoc}`;
export const SessionFragmentDoc = gql`
    fragment session on Session {
  id
  expiresAt
  accessRestriction {
    id
    reference
    name
    upcId
    authenticationType
  }
  user {
    ...userDetailed
    intercomUserHash
  }
  account {
    id
    guid
    subdomain
    verified
    baseUrl
  }
  practice {
    ...practiceMinimal
    activeRoomsCount
    overseas
    coverPhoto {
      id
      preview
      cover
    }
    configuration {
      ...practiceConfigurationExhaustive
    }
    accountPlan {
      ...accountPlanDetailed
    }
  }
  practiceConnection {
    ...userPracticeConnectionMinimal
    profile {
      ...userPracticeProfileMinimal
      onboarded
    }
    permissions
    isGlobalAdmin
  }
  practiceLocation {
    ...practiceLocationMinimal
  }
  env {
    environment
    commit
    version
    releaseTime
  }
}
    ${UserDetailedFragmentDoc}
${PracticeMinimalFragmentDoc}
${PracticeConfigurationExhaustiveFragmentDoc}
${AccountPlanDetailedFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${UserPracticeProfileMinimalFragmentDoc}
${PracticeLocationMinimalFragmentDoc}`;
export const AppointmentHoverCardFragmentDoc = gql`
    fragment appointmentHoverCard on Appointment {
  id
  guid
  uuid
  startTime
  endTime
  isConfirmed
  state
  minutes
  colour
  treatmentPlanId
  practitioner {
    ...userPracticeConnectionMinimal
  }
  badges {
    ...badgeLinkMinimal
  }
  appointmentGuests {
    guid
    id
    patient {
      ...userPracticeConnectionMinimal
      nextAppointmentTime
      lastMedicalFormTime
      lastExamTime
      outstandingBalanceCount
    }
  }
  room {
    ...roomEssentials
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}
${BadgeLinkMinimalFragmentDoc}
${RoomEssentialsFragmentDoc}`;
export const PatientAppConfigInviteFragmentDoc = gql`
    fragment patientAppConfigInvite on PatientAppInvite {
  id
  createdAt
  lastLoginAt
  state
  inviteUrl
  inviteCode
  practiceGuid
}
    `;
export const PatientAppConfigMinimalFragmentDoc = gql`
    fragment patientAppConfigMinimal on PatientAppConfiguration {
  id
  createdAt
  lastLoginAt
  state
  inviteCode
  inviteUrl
}
    `;
export const ColumnHistoryFragmentDoc = gql`
    fragment columnHistory on ColumnHistory {
  id
  columnName
  valueBefore
  valueAfter
  createdAt
  createdBy {
    ...upcAvatar
  }
}
    ${UpcAvatarFragmentDoc}`;
export const PatientAppConfigDetailedFragmentDoc = gql`
    fragment patientAppConfigDetailed on PatientAppConfiguration {
  ...patientAppConfigMinimal
  stateHistory {
    ...columnHistory
  }
  patients {
    ...upcAvatar
  }
}
    ${PatientAppConfigMinimalFragmentDoc}
${ColumnHistoryFragmentDoc}
${UpcAvatarFragmentDoc}`;
export const CreateLabWorkDocument = gql`
    mutation CreateLabWork($input: CreateLabWorkInput!) {
  createLabWork(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    labWork {
      ...labWorkDetailed
    }
  }
}
    ${LabWorkDetailedFragmentDoc}`;
export type CreateLabWorkMutationFn = Apollo.MutationFunction<Operation.CreateLabWorkMutation, Operation.CreateLabWorkMutationVariables>;

/**
 * __useCreateLabWorkMutation__
 *
 * To run a mutation, you first call `useCreateLabWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabWorkMutation, { data, loading, error }] = useCreateLabWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLabWorkMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateLabWorkMutation, Operation.CreateLabWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateLabWorkMutation, Operation.CreateLabWorkMutationVariables>(CreateLabWorkDocument, options);
      }
export type CreateLabWorkMutationHookResult = ReturnType<typeof useCreateLabWorkMutation>;
export type CreateLabWorkMutationResult = Apollo.MutationResult<Operation.CreateLabWorkMutation>;
export type CreateLabWorkMutationOptions = Apollo.BaseMutationOptions<Operation.CreateLabWorkMutation, Operation.CreateLabWorkMutationVariables>;
export const UpdateLabWorkDocument = gql`
    mutation UpdateLabWork($input: UpdateLabWorkInput!) {
  updateLabWork(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    labWork {
      ...labWorkDetailed
    }
  }
}
    ${LabWorkDetailedFragmentDoc}`;
export type UpdateLabWorkMutationFn = Apollo.MutationFunction<Operation.UpdateLabWorkMutation, Operation.UpdateLabWorkMutationVariables>;

/**
 * __useUpdateLabWorkMutation__
 *
 * To run a mutation, you first call `useUpdateLabWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabWorkMutation, { data, loading, error }] = useUpdateLabWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLabWorkMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateLabWorkMutation, Operation.UpdateLabWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateLabWorkMutation, Operation.UpdateLabWorkMutationVariables>(UpdateLabWorkDocument, options);
      }
export type UpdateLabWorkMutationHookResult = ReturnType<typeof useUpdateLabWorkMutation>;
export type UpdateLabWorkMutationResult = Apollo.MutationResult<Operation.UpdateLabWorkMutation>;
export type UpdateLabWorkMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateLabWorkMutation, Operation.UpdateLabWorkMutationVariables>;
export const RemoveLabWorkDocument = gql`
    mutation RemoveLabWork($input: RemoveLabWorkInput!) {
  removeLabWork(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    labWork {
      ...labWorkDetailed
    }
  }
}
    ${LabWorkDetailedFragmentDoc}`;
export type RemoveLabWorkMutationFn = Apollo.MutationFunction<Operation.RemoveLabWorkMutation, Operation.RemoveLabWorkMutationVariables>;

/**
 * __useRemoveLabWorkMutation__
 *
 * To run a mutation, you first call `useRemoveLabWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLabWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLabWorkMutation, { data, loading, error }] = useRemoveLabWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveLabWorkMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RemoveLabWorkMutation, Operation.RemoveLabWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RemoveLabWorkMutation, Operation.RemoveLabWorkMutationVariables>(RemoveLabWorkDocument, options);
      }
export type RemoveLabWorkMutationHookResult = ReturnType<typeof useRemoveLabWorkMutation>;
export type RemoveLabWorkMutationResult = Apollo.MutationResult<Operation.RemoveLabWorkMutation>;
export type RemoveLabWorkMutationOptions = Apollo.BaseMutationOptions<Operation.RemoveLabWorkMutation, Operation.RemoveLabWorkMutationVariables>;
export const TransitionLabWorkDocument = gql`
    mutation TransitionLabWork($input: TransitionLabWorkInput!) {
  transitionLabWork(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    labWork {
      ...labWorkDetailed
    }
  }
}
    ${LabWorkDetailedFragmentDoc}`;
export type TransitionLabWorkMutationFn = Apollo.MutationFunction<Operation.TransitionLabWorkMutation, Operation.TransitionLabWorkMutationVariables>;

/**
 * __useTransitionLabWorkMutation__
 *
 * To run a mutation, you first call `useTransitionLabWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionLabWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionLabWorkMutation, { data, loading, error }] = useTransitionLabWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransitionLabWorkMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionLabWorkMutation, Operation.TransitionLabWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionLabWorkMutation, Operation.TransitionLabWorkMutationVariables>(TransitionLabWorkDocument, options);
      }
export type TransitionLabWorkMutationHookResult = ReturnType<typeof useTransitionLabWorkMutation>;
export type TransitionLabWorkMutationResult = Apollo.MutationResult<Operation.TransitionLabWorkMutation>;
export type TransitionLabWorkMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionLabWorkMutation, Operation.TransitionLabWorkMutationVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($attributes: AcceptInvitationAttributes!) {
  acceptInvitation(input: {attributes: $attributes}) {
    credentials {
      authToken
      userPracticeConnection {
        ...userPracticeConnectionDetailed
        account {
          id
          subdomain
        }
      }
    }
    success
    fieldErrors {
      field
      message
    }
    errors
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<Operation.AcceptInvitationMutation, Operation.AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AcceptInvitationMutation, Operation.AcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AcceptInvitationMutation, Operation.AcceptInvitationMutationVariables>(AcceptInvitationDocument, options);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<Operation.AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<Operation.AcceptInvitationMutation, Operation.AcceptInvitationMutationVariables>;
export const AddGroupToRoleDocument = gql`
    mutation AddGroupToRole($groupId: ID!, $roleId: ID!) {
  addGroupToRole(input: {groupId: $groupId, roleId: $roleId}) {
    fieldErrors {
      field
      message
    }
    errors
    role {
      ...roleDetails
    }
  }
}
    ${RoleDetailsFragmentDoc}`;
export type AddGroupToRoleMutationFn = Apollo.MutationFunction<Operation.AddGroupToRoleMutation, Operation.AddGroupToRoleMutationVariables>;

/**
 * __useAddGroupToRoleMutation__
 *
 * To run a mutation, you first call `useAddGroupToRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupToRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupToRoleMutation, { data, loading, error }] = useAddGroupToRoleMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useAddGroupToRoleMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AddGroupToRoleMutation, Operation.AddGroupToRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AddGroupToRoleMutation, Operation.AddGroupToRoleMutationVariables>(AddGroupToRoleDocument, options);
      }
export type AddGroupToRoleMutationHookResult = ReturnType<typeof useAddGroupToRoleMutation>;
export type AddGroupToRoleMutationResult = Apollo.MutationResult<Operation.AddGroupToRoleMutation>;
export type AddGroupToRoleMutationOptions = Apollo.BaseMutationOptions<Operation.AddGroupToRoleMutation, Operation.AddGroupToRoleMutationVariables>;
export const GrantPermissionToGroupDocument = gql`
    mutation GrantPermissionToGroup($groupId: ID!, $permissionSlug: String!) {
  grantPermissionToGroup(input: {groupId: $groupId, permission: $permissionSlug}) {
    fieldErrors {
      field
      message
    }
    errors
    group {
      ...groupDetails
    }
  }
}
    ${GroupDetailsFragmentDoc}`;
export type GrantPermissionToGroupMutationFn = Apollo.MutationFunction<Operation.GrantPermissionToGroupMutation, Operation.GrantPermissionToGroupMutationVariables>;

/**
 * __useGrantPermissionToGroupMutation__
 *
 * To run a mutation, you first call `useGrantPermissionToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantPermissionToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantPermissionToGroupMutation, { data, loading, error }] = useGrantPermissionToGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      permissionSlug: // value for 'permissionSlug'
 *   },
 * });
 */
export function useGrantPermissionToGroupMutation(baseOptions?: Apollo.MutationHookOptions<Operation.GrantPermissionToGroupMutation, Operation.GrantPermissionToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.GrantPermissionToGroupMutation, Operation.GrantPermissionToGroupMutationVariables>(GrantPermissionToGroupDocument, options);
      }
export type GrantPermissionToGroupMutationHookResult = ReturnType<typeof useGrantPermissionToGroupMutation>;
export type GrantPermissionToGroupMutationResult = Apollo.MutationResult<Operation.GrantPermissionToGroupMutation>;
export type GrantPermissionToGroupMutationOptions = Apollo.BaseMutationOptions<Operation.GrantPermissionToGroupMutation, Operation.GrantPermissionToGroupMutationVariables>;
export const ClearAccessRestrictionDocument = gql`
    mutation ClearAccessRestriction {
  clearAccessRestriction(input: {}) {
    success
    fieldErrors {
      field
      message
    }
    errors
  }
}
    `;
export type ClearAccessRestrictionMutationFn = Apollo.MutationFunction<Operation.ClearAccessRestrictionMutation, Operation.ClearAccessRestrictionMutationVariables>;

/**
 * __useClearAccessRestrictionMutation__
 *
 * To run a mutation, you first call `useClearAccessRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearAccessRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearAccessRestrictionMutation, { data, loading, error }] = useClearAccessRestrictionMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearAccessRestrictionMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ClearAccessRestrictionMutation, Operation.ClearAccessRestrictionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ClearAccessRestrictionMutation, Operation.ClearAccessRestrictionMutationVariables>(ClearAccessRestrictionDocument, options);
      }
export type ClearAccessRestrictionMutationHookResult = ReturnType<typeof useClearAccessRestrictionMutation>;
export type ClearAccessRestrictionMutationResult = Apollo.MutationResult<Operation.ClearAccessRestrictionMutation>;
export type ClearAccessRestrictionMutationOptions = Apollo.BaseMutationOptions<Operation.ClearAccessRestrictionMutation, Operation.ClearAccessRestrictionMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($attributes: GroupInput!) {
  createGroup(input: {attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    group {
      ...groupDetails
    }
  }
}
    ${GroupDetailsFragmentDoc}`;
export type CreateGroupMutationFn = Apollo.MutationFunction<Operation.CreateGroupMutation, Operation.CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateGroupMutation, Operation.CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateGroupMutation, Operation.CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<Operation.CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<Operation.CreateGroupMutation, Operation.CreateGroupMutationVariables>;
export const GrantPermissionDocument = gql`
    mutation GrantPermission($permission: Permission!, $role: PracticeRole!) {
  grantPermission(input: {attributes: {permission: $permission, role: $role}}) {
    fieldErrors {
      field
      message
    }
    errors
    role {
      id
      name
      slug
      permissions
    }
  }
}
    `;
export type GrantPermissionMutationFn = Apollo.MutationFunction<Operation.GrantPermissionMutation, Operation.GrantPermissionMutationVariables>;

/**
 * __useGrantPermissionMutation__
 *
 * To run a mutation, you first call `useGrantPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantPermissionMutation, { data, loading, error }] = useGrantPermissionMutation({
 *   variables: {
 *      permission: // value for 'permission'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useGrantPermissionMutation(baseOptions?: Apollo.MutationHookOptions<Operation.GrantPermissionMutation, Operation.GrantPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.GrantPermissionMutation, Operation.GrantPermissionMutationVariables>(GrantPermissionDocument, options);
      }
export type GrantPermissionMutationHookResult = ReturnType<typeof useGrantPermissionMutation>;
export type GrantPermissionMutationResult = Apollo.MutationResult<Operation.GrantPermissionMutation>;
export type GrantPermissionMutationOptions = Apollo.BaseMutationOptions<Operation.GrantPermissionMutation, Operation.GrantPermissionMutationVariables>;
export const LoginDocument = gql`
    mutation Login($identifier: String!, $password: String!, $otp: String) {
  login(
    input: {attributes: {identifier: $identifier, password: $password, otp: $otp}}
  ) {
    credentials {
      authToken
      userPracticeConnection {
        ...userPracticeConnectionDetailed
        account {
          id
          subdomain
        }
      }
    }
    fieldErrors {
      field
      message
    }
    errors
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<Operation.LoginMutation, Operation.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *      password: // value for 'password'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<Operation.LoginMutation, Operation.LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.LoginMutation, Operation.LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<Operation.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<Operation.LoginMutation, Operation.LoginMutationVariables>;
export const LoginWithRestrictionDocument = gql`
    mutation LoginWithRestriction($attributes: AccessRestrictionAttributes!) {
  loginWithRestriction(input: {attributes: $attributes}) {
    accessRestriction {
      reference
      authenticationType
      redirectUrl
    }
    fieldErrors {
      field
      message
    }
    errors
  }
}
    `;
export type LoginWithRestrictionMutationFn = Apollo.MutationFunction<Operation.LoginWithRestrictionMutation, Operation.LoginWithRestrictionMutationVariables>;

/**
 * __useLoginWithRestrictionMutation__
 *
 * To run a mutation, you first call `useLoginWithRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithRestrictionMutation, { data, loading, error }] = useLoginWithRestrictionMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useLoginWithRestrictionMutation(baseOptions?: Apollo.MutationHookOptions<Operation.LoginWithRestrictionMutation, Operation.LoginWithRestrictionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.LoginWithRestrictionMutation, Operation.LoginWithRestrictionMutationVariables>(LoginWithRestrictionDocument, options);
      }
export type LoginWithRestrictionMutationHookResult = ReturnType<typeof useLoginWithRestrictionMutation>;
export type LoginWithRestrictionMutationResult = Apollo.MutationResult<Operation.LoginWithRestrictionMutation>;
export type LoginWithRestrictionMutationOptions = Apollo.BaseMutationOptions<Operation.LoginWithRestrictionMutation, Operation.LoginWithRestrictionMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout(input: {}) {
    success
    fieldErrors {
      field
      message
    }
    errors
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<Operation.LogoutMutation, Operation.LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<Operation.LogoutMutation, Operation.LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.LogoutMutation, Operation.LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<Operation.LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<Operation.LogoutMutation, Operation.LogoutMutationVariables>;
export const ReactivateSubscriptionDocument = gql`
    mutation ReactivateSubscription {
  reactivateSubscription(input: {}) {
    errors
    fieldErrors {
      field
      message
    }
    success
  }
}
    `;
export type ReactivateSubscriptionMutationFn = Apollo.MutationFunction<Operation.ReactivateSubscriptionMutation, Operation.ReactivateSubscriptionMutationVariables>;

/**
 * __useReactivateSubscription__
 *
 * To run a mutation, you first call `useReactivateSubscription` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateSubscription` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateSubscription, { data, loading, error }] = useReactivateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useReactivateSubscription(baseOptions?: Apollo.MutationHookOptions<Operation.ReactivateSubscriptionMutation, Operation.ReactivateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ReactivateSubscriptionMutation, Operation.ReactivateSubscriptionMutationVariables>(ReactivateSubscriptionDocument, options);
      }
export type ReactivateSubscriptionHookResult = ReturnType<typeof useReactivateSubscription>;
export type ReactivateSubscriptionMutationResult = Apollo.MutationResult<Operation.ReactivateSubscriptionMutation>;
export type ReactivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<Operation.ReactivateSubscriptionMutation, Operation.ReactivateSubscriptionMutationVariables>;
export const RecoverPasswordDocument = gql`
    mutation RecoverPassword($identifier: String!) {
  recoverPassword(input: {identifier: $identifier}) {
    message
    fieldErrors {
      field
      message
    }
    errors
    success
  }
}
    `;
export type RecoverPasswordMutationFn = Apollo.MutationFunction<Operation.RecoverPasswordMutation, Operation.RecoverPasswordMutationVariables>;

/**
 * __useRecoverPasswordMutation__
 *
 * To run a mutation, you first call `useRecoverPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverPasswordMutation, { data, loading, error }] = useRecoverPasswordMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useRecoverPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RecoverPasswordMutation, Operation.RecoverPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RecoverPasswordMutation, Operation.RecoverPasswordMutationVariables>(RecoverPasswordDocument, options);
      }
export type RecoverPasswordMutationHookResult = ReturnType<typeof useRecoverPasswordMutation>;
export type RecoverPasswordMutationResult = Apollo.MutationResult<Operation.RecoverPasswordMutation>;
export type RecoverPasswordMutationOptions = Apollo.BaseMutationOptions<Operation.RecoverPasswordMutation, Operation.RecoverPasswordMutationVariables>;
export const RefreshAuthTokenDocument = gql`
    mutation RefreshAuthToken {
  refreshToken(input: {}) {
    success
    fieldErrors {
      field
      message
    }
    errors
  }
}
    `;
export type RefreshAuthTokenMutationFn = Apollo.MutationFunction<Operation.RefreshAuthTokenMutation, Operation.RefreshAuthTokenMutationVariables>;

/**
 * __useRefreshAuthTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAuthTokenMutation, { data, loading, error }] = useRefreshAuthTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAuthTokenMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RefreshAuthTokenMutation, Operation.RefreshAuthTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RefreshAuthTokenMutation, Operation.RefreshAuthTokenMutationVariables>(RefreshAuthTokenDocument, options);
      }
export type RefreshAuthTokenMutationHookResult = ReturnType<typeof useRefreshAuthTokenMutation>;
export type RefreshAuthTokenMutationResult = Apollo.MutationResult<Operation.RefreshAuthTokenMutation>;
export type RefreshAuthTokenMutationOptions = Apollo.BaseMutationOptions<Operation.RefreshAuthTokenMutation, Operation.RefreshAuthTokenMutationVariables>;
export const RemoveGroupFromRoleDocument = gql`
    mutation RemoveGroupFromRole($groupId: ID!, $roleId: ID!) {
  removeGroupFromRole(input: {groupId: $groupId, roleId: $roleId}) {
    fieldErrors {
      field
      message
    }
    errors
    role {
      ...roleDetails
    }
  }
}
    ${RoleDetailsFragmentDoc}`;
export type RemoveGroupFromRoleMutationFn = Apollo.MutationFunction<Operation.RemoveGroupFromRoleMutation, Operation.RemoveGroupFromRoleMutationVariables>;

/**
 * __useRemoveGroupFromRoleMutation__
 *
 * To run a mutation, you first call `useRemoveGroupFromRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupFromRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupFromRoleMutation, { data, loading, error }] = useRemoveGroupFromRoleMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRemoveGroupFromRoleMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RemoveGroupFromRoleMutation, Operation.RemoveGroupFromRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RemoveGroupFromRoleMutation, Operation.RemoveGroupFromRoleMutationVariables>(RemoveGroupFromRoleDocument, options);
      }
export type RemoveGroupFromRoleMutationHookResult = ReturnType<typeof useRemoveGroupFromRoleMutation>;
export type RemoveGroupFromRoleMutationResult = Apollo.MutationResult<Operation.RemoveGroupFromRoleMutation>;
export type RemoveGroupFromRoleMutationOptions = Apollo.BaseMutationOptions<Operation.RemoveGroupFromRoleMutation, Operation.RemoveGroupFromRoleMutationVariables>;
export const RequestAuthenticatorCodeDocument = gql`
    mutation RequestAuthenticatorCode($attributes: AuthenticatorCodeAttributes!) {
  requestAuthenticatorCode(input: {attributes: $attributes}) {
    otp {
      id
      receiver
      otpMethod
    }
    success
    fieldErrors {
      field
      message
    }
    errors
  }
}
    `;
export type RequestAuthenticatorCodeMutationFn = Apollo.MutationFunction<Operation.RequestAuthenticatorCodeMutation, Operation.RequestAuthenticatorCodeMutationVariables>;

/**
 * __useRequestAuthenticatorCodeMutation__
 *
 * To run a mutation, you first call `useRequestAuthenticatorCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAuthenticatorCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAuthenticatorCodeMutation, { data, loading, error }] = useRequestAuthenticatorCodeMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useRequestAuthenticatorCodeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RequestAuthenticatorCodeMutation, Operation.RequestAuthenticatorCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RequestAuthenticatorCodeMutation, Operation.RequestAuthenticatorCodeMutationVariables>(RequestAuthenticatorCodeDocument, options);
      }
export type RequestAuthenticatorCodeMutationHookResult = ReturnType<typeof useRequestAuthenticatorCodeMutation>;
export type RequestAuthenticatorCodeMutationResult = Apollo.MutationResult<Operation.RequestAuthenticatorCodeMutation>;
export type RequestAuthenticatorCodeMutationOptions = Apollo.BaseMutationOptions<Operation.RequestAuthenticatorCodeMutation, Operation.RequestAuthenticatorCodeMutationVariables>;
export const RequestOtpDocument = gql`
    mutation RequestOtp($attributes: OtpAttributes!) {
  requestOtp(input: {attributes: $attributes}) {
    otp {
      id
      receiver
      otpMethod
    }
    fieldErrors {
      field
      message
    }
    errors
  }
}
    `;
export type RequestOtpMutationFn = Apollo.MutationFunction<Operation.RequestOtpMutation, Operation.RequestOtpMutationVariables>;

/**
 * __useRequestOtpMutation__
 *
 * To run a mutation, you first call `useRequestOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOtpMutation, { data, loading, error }] = useRequestOtpMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useRequestOtpMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RequestOtpMutation, Operation.RequestOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RequestOtpMutation, Operation.RequestOtpMutationVariables>(RequestOtpDocument, options);
      }
export type RequestOtpMutationHookResult = ReturnType<typeof useRequestOtpMutation>;
export type RequestOtpMutationResult = Apollo.MutationResult<Operation.RequestOtpMutation>;
export type RequestOtpMutationOptions = Apollo.BaseMutationOptions<Operation.RequestOtpMutation, Operation.RequestOtpMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($attributes: ResetPasswordAttributes!) {
  resetPassword(input: {attributes: $attributes}) {
    user {
      ...userEssentials
    }
    fieldErrors {
      field
      message
    }
    errors
    success
  }
}
    ${UserEssentialsFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<Operation.ResetPasswordMutation, Operation.ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ResetPasswordMutation, Operation.ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ResetPasswordMutation, Operation.ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<Operation.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<Operation.ResetPasswordMutation, Operation.ResetPasswordMutationVariables>;
export const RestoreDefaultPermissionGroupsDocument = gql`
    mutation RestoreDefaultPermissionGroups($input: RestoreDefaultsInput!) {
  restoreDefaultPermissionGroups(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    groups {
      ...groupDetails
    }
  }
}
    ${GroupDetailsFragmentDoc}`;
export type RestoreDefaultPermissionGroupsMutationFn = Apollo.MutationFunction<Operation.RestoreDefaultPermissionGroupsMutation, Operation.RestoreDefaultPermissionGroupsMutationVariables>;

/**
 * __useRestoreDefaultPermissionGroupsMutation__
 *
 * To run a mutation, you first call `useRestoreDefaultPermissionGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDefaultPermissionGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDefaultPermissionGroupsMutation, { data, loading, error }] = useRestoreDefaultPermissionGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreDefaultPermissionGroupsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RestoreDefaultPermissionGroupsMutation, Operation.RestoreDefaultPermissionGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RestoreDefaultPermissionGroupsMutation, Operation.RestoreDefaultPermissionGroupsMutationVariables>(RestoreDefaultPermissionGroupsDocument, options);
      }
export type RestoreDefaultPermissionGroupsMutationHookResult = ReturnType<typeof useRestoreDefaultPermissionGroupsMutation>;
export type RestoreDefaultPermissionGroupsMutationResult = Apollo.MutationResult<Operation.RestoreDefaultPermissionGroupsMutation>;
export type RestoreDefaultPermissionGroupsMutationOptions = Apollo.BaseMutationOptions<Operation.RestoreDefaultPermissionGroupsMutation, Operation.RestoreDefaultPermissionGroupsMutationVariables>;
export const RevokePermissionDocument = gql`
    mutation RevokePermission($permission: Permission!, $role: PracticeRole!) {
  revokePermission(input: {attributes: {permission: $permission, role: $role}}) {
    fieldErrors {
      field
      message
    }
    errors
    role {
      id
      name
      slug
      permissions
    }
  }
}
    `;
export type RevokePermissionMutationFn = Apollo.MutationFunction<Operation.RevokePermissionMutation, Operation.RevokePermissionMutationVariables>;

/**
 * __useRevokePermissionMutation__
 *
 * To run a mutation, you first call `useRevokePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokePermissionMutation, { data, loading, error }] = useRevokePermissionMutation({
 *   variables: {
 *      permission: // value for 'permission'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useRevokePermissionMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RevokePermissionMutation, Operation.RevokePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RevokePermissionMutation, Operation.RevokePermissionMutationVariables>(RevokePermissionDocument, options);
      }
export type RevokePermissionMutationHookResult = ReturnType<typeof useRevokePermissionMutation>;
export type RevokePermissionMutationResult = Apollo.MutationResult<Operation.RevokePermissionMutation>;
export type RevokePermissionMutationOptions = Apollo.BaseMutationOptions<Operation.RevokePermissionMutation, Operation.RevokePermissionMutationVariables>;
export const RevokePermissionFromGroupDocument = gql`
    mutation RevokePermissionFromGroup($groupId: ID!, $permissionSlug: String!) {
  revokePermissionFromGroup(
    input: {groupId: $groupId, permission: $permissionSlug}
  ) {
    fieldErrors {
      field
      message
    }
    errors
    group {
      ...groupDetails
    }
  }
}
    ${GroupDetailsFragmentDoc}`;
export type RevokePermissionFromGroupMutationFn = Apollo.MutationFunction<Operation.RevokePermissionFromGroupMutation, Operation.RevokePermissionFromGroupMutationVariables>;

/**
 * __useRevokePermissionFromGroupMutation__
 *
 * To run a mutation, you first call `useRevokePermissionFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokePermissionFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokePermissionFromGroupMutation, { data, loading, error }] = useRevokePermissionFromGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      permissionSlug: // value for 'permissionSlug'
 *   },
 * });
 */
export function useRevokePermissionFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RevokePermissionFromGroupMutation, Operation.RevokePermissionFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RevokePermissionFromGroupMutation, Operation.RevokePermissionFromGroupMutationVariables>(RevokePermissionFromGroupDocument, options);
      }
export type RevokePermissionFromGroupMutationHookResult = ReturnType<typeof useRevokePermissionFromGroupMutation>;
export type RevokePermissionFromGroupMutationResult = Apollo.MutationResult<Operation.RevokePermissionFromGroupMutation>;
export type RevokePermissionFromGroupMutationOptions = Apollo.BaseMutationOptions<Operation.RevokePermissionFromGroupMutation, Operation.RevokePermissionFromGroupMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($attributes: SignUpAttributes!) {
  signUp(input: {attributes: $attributes}) {
    success
    fieldErrors {
      field
      message
    }
    errors
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<Operation.SignUpMutation, Operation.SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<Operation.SignUpMutation, Operation.SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.SignUpMutation, Operation.SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<Operation.SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<Operation.SignUpMutation, Operation.SignUpMutationVariables>;
export const ConfirmSignUpDocument = gql`
    mutation ConfirmSignUp($token: String!) {
  confirmSignUp(input: {token: $token}) {
    credentials {
      authToken
      userPracticeConnection {
        ...userPracticeConnectionMinimal
        account {
          id
          subdomain
        }
      }
    }
    fieldErrors {
      field
      message
    }
    errors
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;
export type ConfirmSignUpMutationFn = Apollo.MutationFunction<Operation.ConfirmSignUpMutation, Operation.ConfirmSignUpMutationVariables>;

/**
 * __useConfirmSignUpMutation__
 *
 * To run a mutation, you first call `useConfirmSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmSignUpMutation, { data, loading, error }] = useConfirmSignUpMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmSignUpMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ConfirmSignUpMutation, Operation.ConfirmSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ConfirmSignUpMutation, Operation.ConfirmSignUpMutationVariables>(ConfirmSignUpDocument, options);
      }
export type ConfirmSignUpMutationHookResult = ReturnType<typeof useConfirmSignUpMutation>;
export type ConfirmSignUpMutationResult = Apollo.MutationResult<Operation.ConfirmSignUpMutation>;
export type ConfirmSignUpMutationOptions = Apollo.BaseMutationOptions<Operation.ConfirmSignUpMutation, Operation.ConfirmSignUpMutationVariables>;
export const SwitchUserDocument = gql`
    mutation SwitchUser($upc: ID!) {
  switchUser(input: {upc: $upc}) {
    session {
      ...session
    }
    fieldErrors {
      field
      message
    }
    errors
  }
}
    ${SessionFragmentDoc}`;
export type SwitchUserMutationFn = Apollo.MutationFunction<Operation.SwitchUserMutation, Operation.SwitchUserMutationVariables>;

/**
 * __useSwitchUserMutation__
 *
 * To run a mutation, you first call `useSwitchUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchUserMutation, { data, loading, error }] = useSwitchUserMutation({
 *   variables: {
 *      upc: // value for 'upc'
 *   },
 * });
 */
export function useSwitchUserMutation(baseOptions?: Apollo.MutationHookOptions<Operation.SwitchUserMutation, Operation.SwitchUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.SwitchUserMutation, Operation.SwitchUserMutationVariables>(SwitchUserDocument, options);
      }
export type SwitchUserMutationHookResult = ReturnType<typeof useSwitchUserMutation>;
export type SwitchUserMutationResult = Apollo.MutationResult<Operation.SwitchUserMutation>;
export type SwitchUserMutationOptions = Apollo.BaseMutationOptions<Operation.SwitchUserMutation, Operation.SwitchUserMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($id: ID!, $attributes: GroupInput!) {
  updateGroup(input: {id: $id, attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    group {
      ...groupDetails
    }
  }
}
    ${GroupDetailsFragmentDoc}`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<Operation.UpdateGroupMutation, Operation.UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateGroupMutation, Operation.UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateGroupMutation, Operation.UpdateGroupMutationVariables>(UpdateGroupDocument, options);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<Operation.UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateGroupMutation, Operation.UpdateGroupMutationVariables>;
export const VerifyInvitationDocument = gql`
    mutation VerifyInvitation($attributes: VerifyInvitationAttributes!) {
  verifyInvitation(input: {attributes: $attributes}) {
    credentials {
      authToken
      userPracticeConnection {
        ...userPracticeConnectionDetailed
        account {
          id
          subdomain
          activePractitionersCount
          maxPractitionerCount
        }
      }
    }
    success
    fieldErrors {
      field
      message
    }
    errors
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;
export type VerifyInvitationMutationFn = Apollo.MutationFunction<Operation.VerifyInvitationMutation, Operation.VerifyInvitationMutationVariables>;

/**
 * __useVerifyInvitationMutation__
 *
 * To run a mutation, you first call `useVerifyInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyInvitationMutation, { data, loading, error }] = useVerifyInvitationMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useVerifyInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Operation.VerifyInvitationMutation, Operation.VerifyInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.VerifyInvitationMutation, Operation.VerifyInvitationMutationVariables>(VerifyInvitationDocument, options);
      }
export type VerifyInvitationMutationHookResult = ReturnType<typeof useVerifyInvitationMutation>;
export type VerifyInvitationMutationResult = Apollo.MutationResult<Operation.VerifyInvitationMutation>;
export type VerifyInvitationMutationOptions = Apollo.BaseMutationOptions<Operation.VerifyInvitationMutation, Operation.VerifyInvitationMutationVariables>;
export const VerifyOtpDocument = gql`
    mutation VerifyOtp($id: ID!, $code: String!) {
  verifyOtp(input: {id: $id, code: $code}) {
    credentials {
      authToken
      userPracticeConnection {
        ...userPracticeConnectionDetailed
        account {
          id
          subdomain
        }
      }
    }
    fieldErrors {
      field
      message
    }
    errors
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;
export type VerifyOtpMutationFn = Apollo.MutationFunction<Operation.VerifyOtpMutation, Operation.VerifyOtpMutationVariables>;

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyOtpMutation(baseOptions?: Apollo.MutationHookOptions<Operation.VerifyOtpMutation, Operation.VerifyOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.VerifyOtpMutation, Operation.VerifyOtpMutationVariables>(VerifyOtpDocument, options);
      }
export type VerifyOtpMutationHookResult = ReturnType<typeof useVerifyOtpMutation>;
export type VerifyOtpMutationResult = Apollo.MutationResult<Operation.VerifyOtpMutation>;
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<Operation.VerifyOtpMutation, Operation.VerifyOtpMutationVariables>;
export const CreateCustomImportDocument = gql`
    mutation CreateCustomImport($attributes: CustomImportInput!) {
  createCustomImport(input: {attributes: $attributes}) {
    customImport {
      id
      folderName
      state
      jobId
      errorMessage
      importType
      importTask
      startedAt
      completedAt
      failedAt
      mailedAt
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateCustomImportMutationFn = Apollo.MutationFunction<Operation.CreateCustomImportMutation, Operation.CreateCustomImportMutationVariables>;

/**
 * __useCreateCustomImportMutation__
 *
 * To run a mutation, you first call `useCreateCustomImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomImportMutation, { data, loading, error }] = useCreateCustomImportMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateCustomImportMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateCustomImportMutation, Operation.CreateCustomImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateCustomImportMutation, Operation.CreateCustomImportMutationVariables>(CreateCustomImportDocument, options);
      }
export type CreateCustomImportMutationHookResult = ReturnType<typeof useCreateCustomImportMutation>;
export type CreateCustomImportMutationResult = Apollo.MutationResult<Operation.CreateCustomImportMutation>;
export type CreateCustomImportMutationOptions = Apollo.BaseMutationOptions<Operation.CreateCustomImportMutation, Operation.CreateCustomImportMutationVariables>;
export const DataMigrateDocument = gql`
    mutation DataMigrate($version: String) {
  dataMigrate(input: {version: $version}) {
    dataMigrations {
      id
      version
      name
      createdAt
      status
    }
  }
}
    `;
export type DataMigrateMutationFn = Apollo.MutationFunction<Operation.DataMigrateMutation, Operation.DataMigrateMutationVariables>;

/**
 * __useDataMigrateMutation__
 *
 * To run a mutation, you first call `useDataMigrateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDataMigrateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dataMigrateMutation, { data, loading, error }] = useDataMigrateMutation({
 *   variables: {
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDataMigrateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DataMigrateMutation, Operation.DataMigrateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DataMigrateMutation, Operation.DataMigrateMutationVariables>(DataMigrateDocument, options);
      }
export type DataMigrateMutationHookResult = ReturnType<typeof useDataMigrateMutation>;
export type DataMigrateMutationResult = Apollo.MutationResult<Operation.DataMigrateMutation>;
export type DataMigrateMutationOptions = Apollo.BaseMutationOptions<Operation.DataMigrateMutation, Operation.DataMigrateMutationVariables>;
export const AdminDeleteAccountDocument = gql`
    mutation AdminDeleteAccount($guid: ID!) {
  adminDeleteAccount(input: {account: $guid}) {
    success
    errors
    fieldErrors {
      message
      field
    }
    account {
      ...accountDetailed
    }
  }
}
    ${AccountDetailedFragmentDoc}`;
export type AdminDeleteAccountMutationFn = Apollo.MutationFunction<Operation.AdminDeleteAccountMutation, Operation.AdminDeleteAccountMutationVariables>;

/**
 * __useAdminDeleteAccountMutation__
 *
 * To run a mutation, you first call `useAdminDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteAccountMutation, { data, loading, error }] = useAdminDeleteAccountMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useAdminDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AdminDeleteAccountMutation, Operation.AdminDeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AdminDeleteAccountMutation, Operation.AdminDeleteAccountMutationVariables>(AdminDeleteAccountDocument, options);
      }
export type AdminDeleteAccountMutationHookResult = ReturnType<typeof useAdminDeleteAccountMutation>;
export type AdminDeleteAccountMutationResult = Apollo.MutationResult<Operation.AdminDeleteAccountMutation>;
export type AdminDeleteAccountMutationOptions = Apollo.BaseMutationOptions<Operation.AdminDeleteAccountMutation, Operation.AdminDeleteAccountMutationVariables>;
export const AdminEnableDentalDocument = gql`
    mutation adminEnableDental($guid: ID!) {
  adminEnableDental(input: {account: $guid}) {
    success
    errors
    fieldErrors {
      message
      field
    }
    account {
      ...accountDetailed
    }
  }
}
    ${AccountDetailedFragmentDoc}`;
export type AdminEnableDentalMutationFn = Apollo.MutationFunction<Operation.AdminEnableDentalMutation, Operation.AdminEnableDentalMutationVariables>;

/**
 * __useAdminEnableDentalMutation__
 *
 * To run a mutation, you first call `useAdminEnableDentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEnableDentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEnableDentalMutation, { data, loading, error }] = useAdminEnableDentalMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useAdminEnableDentalMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AdminEnableDentalMutation, Operation.AdminEnableDentalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AdminEnableDentalMutation, Operation.AdminEnableDentalMutationVariables>(AdminEnableDentalDocument, options);
      }
export type AdminEnableDentalMutationHookResult = ReturnType<typeof useAdminEnableDentalMutation>;
export type AdminEnableDentalMutationResult = Apollo.MutationResult<Operation.AdminEnableDentalMutation>;
export type AdminEnableDentalMutationOptions = Apollo.BaseMutationOptions<Operation.AdminEnableDentalMutation, Operation.AdminEnableDentalMutationVariables>;
export const AdminImportDentalCodesDocument = gql`
    mutation AdminImportDentalCodes($guid: ID!, $codes: [String!], $subdomain: String!, $updatePrice: Boolean!) {
  adminImportDentalCodes(
    input: {practice: $guid, codes: $codes, subdomain: $subdomain, updatePrice: $updatePrice}
  ) {
    success
    errors
    fieldErrors {
      message
      field
    }
    account {
      ...accountDetailed
    }
  }
}
    ${AccountDetailedFragmentDoc}`;
export type AdminImportDentalCodesMutationFn = Apollo.MutationFunction<Operation.AdminImportDentalCodesMutation, Operation.AdminImportDentalCodesMutationVariables>;

/**
 * __useAdminImportDentalCodesMutation__
 *
 * To run a mutation, you first call `useAdminImportDentalCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminImportDentalCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminImportDentalCodesMutation, { data, loading, error }] = useAdminImportDentalCodesMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *      codes: // value for 'codes'
 *      subdomain: // value for 'subdomain'
 *      updatePrice: // value for 'updatePrice'
 *   },
 * });
 */
export function useAdminImportDentalCodesMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AdminImportDentalCodesMutation, Operation.AdminImportDentalCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AdminImportDentalCodesMutation, Operation.AdminImportDentalCodesMutationVariables>(AdminImportDentalCodesDocument, options);
      }
export type AdminImportDentalCodesMutationHookResult = ReturnType<typeof useAdminImportDentalCodesMutation>;
export type AdminImportDentalCodesMutationResult = Apollo.MutationResult<Operation.AdminImportDentalCodesMutation>;
export type AdminImportDentalCodesMutationOptions = Apollo.BaseMutationOptions<Operation.AdminImportDentalCodesMutation, Operation.AdminImportDentalCodesMutationVariables>;
export const ParsePatientsImportDocument = gql`
    mutation ParsePatientsImport($file: Upload!, $practiceLocationId: ID!) {
  parsePatientsImport(
    input: {file: $file, practiceLocationId: $practiceLocationId}
  ) {
    errors
    success
    fieldErrors {
      field
      message
    }
    patientImportFile {
      ...importFileEssentials
    }
  }
}
    ${ImportFileEssentialsFragmentDoc}`;
export type ParsePatientsImportMutationFn = Apollo.MutationFunction<Operation.ParsePatientsImportMutation, Operation.ParsePatientsImportMutationVariables>;

/**
 * __useParsePatientsImportMutation__
 *
 * To run a mutation, you first call `useParsePatientsImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParsePatientsImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parsePatientsImportMutation, { data, loading, error }] = useParsePatientsImportMutation({
 *   variables: {
 *      file: // value for 'file'
 *      practiceLocationId: // value for 'practiceLocationId'
 *   },
 * });
 */
export function useParsePatientsImportMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ParsePatientsImportMutation, Operation.ParsePatientsImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ParsePatientsImportMutation, Operation.ParsePatientsImportMutationVariables>(ParsePatientsImportDocument, options);
      }
export type ParsePatientsImportMutationHookResult = ReturnType<typeof useParsePatientsImportMutation>;
export type ParsePatientsImportMutationResult = Apollo.MutationResult<Operation.ParsePatientsImportMutation>;
export type ParsePatientsImportMutationOptions = Apollo.BaseMutationOptions<Operation.ParsePatientsImportMutation, Operation.ParsePatientsImportMutationVariables>;
export const AdminRefreshSidekiqJobDocument = gql`
    mutation AdminRefreshSidekiqJob($guid: ID!) {
  adminRefreshSidekiqJob(input: {sidekiqJob: $guid}) {
    success
    errors
    fieldErrors {
      message
      field
    }
    sidekiqJob {
      ...backgroundJobEssentials
    }
  }
}
    ${BackgroundJobEssentialsFragmentDoc}`;
export type AdminRefreshSidekiqJobMutationFn = Apollo.MutationFunction<Operation.AdminRefreshSidekiqJobMutation, Operation.AdminRefreshSidekiqJobMutationVariables>;

/**
 * __useAdminRefreshSidekiqJobMutation__
 *
 * To run a mutation, you first call `useAdminRefreshSidekiqJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRefreshSidekiqJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRefreshSidekiqJobMutation, { data, loading, error }] = useAdminRefreshSidekiqJobMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useAdminRefreshSidekiqJobMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AdminRefreshSidekiqJobMutation, Operation.AdminRefreshSidekiqJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AdminRefreshSidekiqJobMutation, Operation.AdminRefreshSidekiqJobMutationVariables>(AdminRefreshSidekiqJobDocument, options);
      }
export type AdminRefreshSidekiqJobMutationHookResult = ReturnType<typeof useAdminRefreshSidekiqJobMutation>;
export type AdminRefreshSidekiqJobMutationResult = Apollo.MutationResult<Operation.AdminRefreshSidekiqJobMutation>;
export type AdminRefreshSidekiqJobMutationOptions = Apollo.BaseMutationOptions<Operation.AdminRefreshSidekiqJobMutation, Operation.AdminRefreshSidekiqJobMutationVariables>;
export const AdminUpdateAccountDocument = gql`
    mutation AdminUpdateAccount($guid: ID!, $attributes: AccountUpdateInput!) {
  adminUpdateAccount(input: {account: $guid, attributes: $attributes}) {
    success
    errors
    fieldErrors {
      message
      field
    }
    account {
      ...accountDetailed
    }
  }
}
    ${AccountDetailedFragmentDoc}`;
export type AdminUpdateAccountMutationFn = Apollo.MutationFunction<Operation.AdminUpdateAccountMutation, Operation.AdminUpdateAccountMutationVariables>;

/**
 * __useAdminUpdateAccountMutation__
 *
 * To run a mutation, you first call `useAdminUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateAccountMutation, { data, loading, error }] = useAdminUpdateAccountMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useAdminUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AdminUpdateAccountMutation, Operation.AdminUpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AdminUpdateAccountMutation, Operation.AdminUpdateAccountMutationVariables>(AdminUpdateAccountDocument, options);
      }
export type AdminUpdateAccountMutationHookResult = ReturnType<typeof useAdminUpdateAccountMutation>;
export type AdminUpdateAccountMutationResult = Apollo.MutationResult<Operation.AdminUpdateAccountMutation>;
export type AdminUpdateAccountMutationOptions = Apollo.BaseMutationOptions<Operation.AdminUpdateAccountMutation, Operation.AdminUpdateAccountMutationVariables>;
export const UpdateConnectionAlertDocument = gql`
    mutation UpdateConnectionAlert($id: ID!, $input: ConnectionAlertUpdateInput!) {
  updateConnectionAlert(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    connectionAlert {
      ...userPracticeConnectionAlertDetailed
    }
  }
}
    ${UserPracticeConnectionAlertDetailedFragmentDoc}`;
export type UpdateConnectionAlertMutationFn = Apollo.MutationFunction<Operation.UpdateConnectionAlertMutation, Operation.UpdateConnectionAlertMutationVariables>;

/**
 * __useUpdateConnectionAlertMutation__
 *
 * To run a mutation, you first call `useUpdateConnectionAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectionAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectionAlertMutation, { data, loading, error }] = useUpdateConnectionAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConnectionAlertMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateConnectionAlertMutation, Operation.UpdateConnectionAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateConnectionAlertMutation, Operation.UpdateConnectionAlertMutationVariables>(UpdateConnectionAlertDocument, options);
      }
export type UpdateConnectionAlertMutationHookResult = ReturnType<typeof useUpdateConnectionAlertMutation>;
export type UpdateConnectionAlertMutationResult = Apollo.MutationResult<Operation.UpdateConnectionAlertMutation>;
export type UpdateConnectionAlertMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateConnectionAlertMutation, Operation.UpdateConnectionAlertMutationVariables>;
export const AdminUpgradeAccountPlanDocument = gql`
    mutation AdminUpgradeAccountPlan($guid: ID!) {
  adminUpgradeAccountPlan(input: {account: $guid}) {
    success
    errors
    fieldErrors {
      message
      field
    }
    account {
      ...accountDetailed
    }
  }
}
    ${AccountDetailedFragmentDoc}`;
export type AdminUpgradeAccountPlanMutationFn = Apollo.MutationFunction<Operation.AdminUpgradeAccountPlanMutation, Operation.AdminUpgradeAccountPlanMutationVariables>;

/**
 * __useAdminUpgradeAccountPlanMutation__
 *
 * To run a mutation, you first call `useAdminUpgradeAccountPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpgradeAccountPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpgradeAccountPlanMutation, { data, loading, error }] = useAdminUpgradeAccountPlanMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useAdminUpgradeAccountPlanMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AdminUpgradeAccountPlanMutation, Operation.AdminUpgradeAccountPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AdminUpgradeAccountPlanMutation, Operation.AdminUpgradeAccountPlanMutationVariables>(AdminUpgradeAccountPlanDocument, options);
      }
export type AdminUpgradeAccountPlanMutationHookResult = ReturnType<typeof useAdminUpgradeAccountPlanMutation>;
export type AdminUpgradeAccountPlanMutationResult = Apollo.MutationResult<Operation.AdminUpgradeAccountPlanMutation>;
export type AdminUpgradeAccountPlanMutationOptions = Apollo.BaseMutationOptions<Operation.AdminUpgradeAccountPlanMutation, Operation.AdminUpgradeAccountPlanMutationVariables>;
export const AdminUpdateUserDocument = gql`
    mutation AdminUpdateUser($guid: ID!, $attributes: UserUpdateInput!) {
  adminUpdateUser(input: {user: $guid, attributes: $attributes}) {
    success
    errors
    fieldErrors {
      message
      field
    }
    user {
      ...userDetailed
    }
  }
}
    ${UserDetailedFragmentDoc}`;
export type AdminUpdateUserMutationFn = Apollo.MutationFunction<Operation.AdminUpdateUserMutation, Operation.AdminUpdateUserMutationVariables>;

/**
 * __useAdminUpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUserMutation, { data, loading, error }] = useAdminUpdateUserMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useAdminUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AdminUpdateUserMutation, Operation.AdminUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AdminUpdateUserMutation, Operation.AdminUpdateUserMutationVariables>(AdminUpdateUserDocument, options);
      }
export type AdminUpdateUserMutationHookResult = ReturnType<typeof useAdminUpdateUserMutation>;
export type AdminUpdateUserMutationResult = Apollo.MutationResult<Operation.AdminUpdateUserMutation>;
export type AdminUpdateUserMutationOptions = Apollo.BaseMutationOptions<Operation.AdminUpdateUserMutation, Operation.AdminUpdateUserMutationVariables>;
export const AdminUpdatePasswordDocument = gql`
    mutation AdminUpdatePassword($guid: ID!, $password: String!, $passwordConfirmation: String!) {
  adminUpdatePassword(
    input: {user: $guid, password: $password, passwordConfirmation: $passwordConfirmation}
  ) {
    success
    fieldErrors {
      field
      message
    }
    errors
    user {
      ...userEssentials
    }
  }
}
    ${UserEssentialsFragmentDoc}`;
export type AdminUpdatePasswordMutationFn = Apollo.MutationFunction<Operation.AdminUpdatePasswordMutation, Operation.AdminUpdatePasswordMutationVariables>;

/**
 * __useAdminUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useAdminUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdatePasswordMutation, { data, loading, error }] = useAdminUpdatePasswordMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useAdminUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AdminUpdatePasswordMutation, Operation.AdminUpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AdminUpdatePasswordMutation, Operation.AdminUpdatePasswordMutationVariables>(AdminUpdatePasswordDocument, options);
      }
export type AdminUpdatePasswordMutationHookResult = ReturnType<typeof useAdminUpdatePasswordMutation>;
export type AdminUpdatePasswordMutationResult = Apollo.MutationResult<Operation.AdminUpdatePasswordMutation>;
export type AdminUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<Operation.AdminUpdatePasswordMutation, Operation.AdminUpdatePasswordMutationVariables>;
export const AssignAlertDocument = gql`
    mutation AssignAlert($alertId: ID!, $connectionId: ID!) {
  assignAlert(input: {alertId: $alertId, connectionId: $connectionId}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    connectionAlert {
      ...userPracticeConnectionAlertDetailed
    }
  }
}
    ${UserPracticeConnectionAlertDetailedFragmentDoc}`;
export type AssignAlertMutationFn = Apollo.MutationFunction<Operation.AssignAlertMutation, Operation.AssignAlertMutationVariables>;

/**
 * __useAssignAlertMutation__
 *
 * To run a mutation, you first call `useAssignAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAlertMutation, { data, loading, error }] = useAssignAlertMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useAssignAlertMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AssignAlertMutation, Operation.AssignAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AssignAlertMutation, Operation.AssignAlertMutationVariables>(AssignAlertDocument, options);
      }
export type AssignAlertMutationHookResult = ReturnType<typeof useAssignAlertMutation>;
export type AssignAlertMutationResult = Apollo.MutationResult<Operation.AssignAlertMutation>;
export type AssignAlertMutationOptions = Apollo.BaseMutationOptions<Operation.AssignAlertMutation, Operation.AssignAlertMutationVariables>;
export const CreateAlertDocument = gql`
    mutation CreateAlert($input: AlertCreateInput!, $assigneeId: ID) {
  createAlert(input: {attributes: $input, assigneeId: $assigneeId}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    alert {
      ...alertDetailed
    }
  }
}
    ${AlertDetailedFragmentDoc}`;
export type CreateAlertMutationFn = Apollo.MutationFunction<Operation.CreateAlertMutation, Operation.CreateAlertMutationVariables>;

/**
 * __useCreateAlertMutation__
 *
 * To run a mutation, you first call `useCreateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertMutation, { data, loading, error }] = useCreateAlertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      assigneeId: // value for 'assigneeId'
 *   },
 * });
 */
export function useCreateAlertMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateAlertMutation, Operation.CreateAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateAlertMutation, Operation.CreateAlertMutationVariables>(CreateAlertDocument, options);
      }
export type CreateAlertMutationHookResult = ReturnType<typeof useCreateAlertMutation>;
export type CreateAlertMutationResult = Apollo.MutationResult<Operation.CreateAlertMutation>;
export type CreateAlertMutationOptions = Apollo.BaseMutationOptions<Operation.CreateAlertMutation, Operation.CreateAlertMutationVariables>;
export const UnassignAlertDocument = gql`
    mutation UnassignAlert($id: ID!) {
  unassignAlert(input: {id: $id}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    connectionAlert {
      ...userPracticeConnectionAlertDetailed
    }
  }
}
    ${UserPracticeConnectionAlertDetailedFragmentDoc}`;
export type UnassignAlertMutationFn = Apollo.MutationFunction<Operation.UnassignAlertMutation, Operation.UnassignAlertMutationVariables>;

/**
 * __useUnassignAlertMutation__
 *
 * To run a mutation, you first call `useUnassignAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignAlertMutation, { data, loading, error }] = useUnassignAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnassignAlertMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UnassignAlertMutation, Operation.UnassignAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UnassignAlertMutation, Operation.UnassignAlertMutationVariables>(UnassignAlertDocument, options);
      }
export type UnassignAlertMutationHookResult = ReturnType<typeof useUnassignAlertMutation>;
export type UnassignAlertMutationResult = Apollo.MutationResult<Operation.UnassignAlertMutation>;
export type UnassignAlertMutationOptions = Apollo.BaseMutationOptions<Operation.UnassignAlertMutation, Operation.UnassignAlertMutationVariables>;
export const UpdateAlertDocument = gql`
    mutation UpdateAlert($id: ID!, $input: AlertUpdateInput!) {
  updateAlert(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    alert {
      ...alertDetailed
    }
  }
}
    ${AlertDetailedFragmentDoc}`;
export type UpdateAlertMutationFn = Apollo.MutationFunction<Operation.UpdateAlertMutation, Operation.UpdateAlertMutationVariables>;

/**
 * __useUpdateAlertMutation__
 *
 * To run a mutation, you first call `useUpdateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertMutation, { data, loading, error }] = useUpdateAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateAlertMutation, Operation.UpdateAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateAlertMutation, Operation.UpdateAlertMutationVariables>(UpdateAlertDocument, options);
      }
export type UpdateAlertMutationHookResult = ReturnType<typeof useUpdateAlertMutation>;
export type UpdateAlertMutationResult = Apollo.MutationResult<Operation.UpdateAlertMutation>;
export type UpdateAlertMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateAlertMutation, Operation.UpdateAlertMutationVariables>;
export const CreateAppointmentReasonDocument = gql`
    mutation createAppointmentReason($attributes: AppointmentReasonInput!) {
  createAppointmentReason(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    appointmentReason {
      ...appointmentReasonDetailed
    }
  }
}
    ${AppointmentReasonDetailedFragmentDoc}`;
export type CreateAppointmentReasonMutationFn = Apollo.MutationFunction<Operation.CreateAppointmentReasonMutation, Operation.CreateAppointmentReasonMutationVariables>;

/**
 * __useCreateAppointmentReasonMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentReasonMutation, { data, loading, error }] = useCreateAppointmentReasonMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateAppointmentReasonMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateAppointmentReasonMutation, Operation.CreateAppointmentReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateAppointmentReasonMutation, Operation.CreateAppointmentReasonMutationVariables>(CreateAppointmentReasonDocument, options);
      }
export type CreateAppointmentReasonMutationHookResult = ReturnType<typeof useCreateAppointmentReasonMutation>;
export type CreateAppointmentReasonMutationResult = Apollo.MutationResult<Operation.CreateAppointmentReasonMutation>;
export type CreateAppointmentReasonMutationOptions = Apollo.BaseMutationOptions<Operation.CreateAppointmentReasonMutation, Operation.CreateAppointmentReasonMutationVariables>;
export const UpdateAppointmentReasonDocument = gql`
    mutation updateAppointmentReason($id: ID!, $attributes: AppointmentReasonInput!) {
  updateAppointmentReason(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    appointmentReason {
      ...appointmentReasonDetailed
    }
  }
}
    ${AppointmentReasonDetailedFragmentDoc}`;
export type UpdateAppointmentReasonMutationFn = Apollo.MutationFunction<Operation.UpdateAppointmentReasonMutation, Operation.UpdateAppointmentReasonMutationVariables>;

/**
 * __useUpdateAppointmentReasonMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentReasonMutation, { data, loading, error }] = useUpdateAppointmentReasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateAppointmentReasonMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateAppointmentReasonMutation, Operation.UpdateAppointmentReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateAppointmentReasonMutation, Operation.UpdateAppointmentReasonMutationVariables>(UpdateAppointmentReasonDocument, options);
      }
export type UpdateAppointmentReasonMutationHookResult = ReturnType<typeof useUpdateAppointmentReasonMutation>;
export type UpdateAppointmentReasonMutationResult = Apollo.MutationResult<Operation.UpdateAppointmentReasonMutation>;
export type UpdateAppointmentReasonMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateAppointmentReasonMutation, Operation.UpdateAppointmentReasonMutationVariables>;
export const AddAuthenticatorDocument = gql`
    mutation AddAuthenticator($secretKey: String!, $otpCode: String!) {
  addAuthenticator(input: {secretKey: $secretKey, otpCode: $otpCode}) {
    success
    fieldErrors {
      field
      message
    }
    errors
  }
}
    `;
export type AddAuthenticatorMutationFn = Apollo.MutationFunction<Operation.AddAuthenticatorMutation, Operation.AddAuthenticatorMutationVariables>;

/**
 * __useAddAuthenticatorMutation__
 *
 * To run a mutation, you first call `useAddAuthenticatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAuthenticatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAuthenticatorMutation, { data, loading, error }] = useAddAuthenticatorMutation({
 *   variables: {
 *      secretKey: // value for 'secretKey'
 *      otpCode: // value for 'otpCode'
 *   },
 * });
 */
export function useAddAuthenticatorMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AddAuthenticatorMutation, Operation.AddAuthenticatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AddAuthenticatorMutation, Operation.AddAuthenticatorMutationVariables>(AddAuthenticatorDocument, options);
      }
export type AddAuthenticatorMutationHookResult = ReturnType<typeof useAddAuthenticatorMutation>;
export type AddAuthenticatorMutationResult = Apollo.MutationResult<Operation.AddAuthenticatorMutation>;
export type AddAuthenticatorMutationOptions = Apollo.BaseMutationOptions<Operation.AddAuthenticatorMutation, Operation.AddAuthenticatorMutationVariables>;
export const RemoveAuthenticatorDocument = gql`
    mutation RemoveAuthenticator($otpCode: String!) {
  removeAuthenticator(input: {otpCode: $otpCode}) {
    success
    fieldErrors {
      field
      message
    }
    errors
  }
}
    `;
export type RemoveAuthenticatorMutationFn = Apollo.MutationFunction<Operation.RemoveAuthenticatorMutation, Operation.RemoveAuthenticatorMutationVariables>;

/**
 * __useRemoveAuthenticatorMutation__
 *
 * To run a mutation, you first call `useRemoveAuthenticatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAuthenticatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAuthenticatorMutation, { data, loading, error }] = useRemoveAuthenticatorMutation({
 *   variables: {
 *      otpCode: // value for 'otpCode'
 *   },
 * });
 */
export function useRemoveAuthenticatorMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RemoveAuthenticatorMutation, Operation.RemoveAuthenticatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RemoveAuthenticatorMutation, Operation.RemoveAuthenticatorMutationVariables>(RemoveAuthenticatorDocument, options);
      }
export type RemoveAuthenticatorMutationHookResult = ReturnType<typeof useRemoveAuthenticatorMutation>;
export type RemoveAuthenticatorMutationResult = Apollo.MutationResult<Operation.RemoveAuthenticatorMutation>;
export type RemoveAuthenticatorMutationOptions = Apollo.BaseMutationOptions<Operation.RemoveAuthenticatorMutation, Operation.RemoveAuthenticatorMutationVariables>;
export const SendRecoveryCodeDocument = gql`
    mutation SendRecoveryCode($email: String, $password: String, $otpMethod: OtpMethod) {
  sendAuthenticatorRecoveryCode(
    input: {email: $email, password: $password, otpMethod: $otpMethod}
  ) {
    success
    fieldErrors {
      field
      message
    }
    errors
  }
}
    `;
export type SendRecoveryCodeMutationFn = Apollo.MutationFunction<Operation.SendRecoveryCodeMutation, Operation.SendRecoveryCodeMutationVariables>;

/**
 * __useSendRecoveryCodeMutation__
 *
 * To run a mutation, you first call `useSendRecoveryCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRecoveryCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRecoveryCodeMutation, { data, loading, error }] = useSendRecoveryCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      otpMethod: // value for 'otpMethod'
 *   },
 * });
 */
export function useSendRecoveryCodeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.SendRecoveryCodeMutation, Operation.SendRecoveryCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.SendRecoveryCodeMutation, Operation.SendRecoveryCodeMutationVariables>(SendRecoveryCodeDocument, options);
      }
export type SendRecoveryCodeMutationHookResult = ReturnType<typeof useSendRecoveryCodeMutation>;
export type SendRecoveryCodeMutationResult = Apollo.MutationResult<Operation.SendRecoveryCodeMutation>;
export type SendRecoveryCodeMutationOptions = Apollo.BaseMutationOptions<Operation.SendRecoveryCodeMutation, Operation.SendRecoveryCodeMutationVariables>;
export const CreateBadgeLinkDocument = gql`
    mutation CreateBadgeLink($attributes: BadgesBadgeLinkInput!) {
  createBadgesBadgeLink(input: {attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    badgeable {
      ...badgeableWithBadges
    }
  }
}
    ${BadgeableWithBadgesFragmentDoc}`;
export type CreateBadgeLinkMutationFn = Apollo.MutationFunction<Operation.CreateBadgeLinkMutation, Operation.CreateBadgeLinkMutationVariables>;

/**
 * __useCreateBadgeLinkMutation__
 *
 * To run a mutation, you first call `useCreateBadgeLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBadgeLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBadgeLinkMutation, { data, loading, error }] = useCreateBadgeLinkMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateBadgeLinkMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateBadgeLinkMutation, Operation.CreateBadgeLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateBadgeLinkMutation, Operation.CreateBadgeLinkMutationVariables>(CreateBadgeLinkDocument, options);
      }
export type CreateBadgeLinkMutationHookResult = ReturnType<typeof useCreateBadgeLinkMutation>;
export type CreateBadgeLinkMutationResult = Apollo.MutationResult<Operation.CreateBadgeLinkMutation>;
export type CreateBadgeLinkMutationOptions = Apollo.BaseMutationOptions<Operation.CreateBadgeLinkMutation, Operation.CreateBadgeLinkMutationVariables>;
export const DestroyBadgeLinkDocument = gql`
    mutation DestroyBadgeLink($attributes: BadgesBadgeLinkInput!) {
  destroyBadgesBadgeLink(input: {attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    badgeable {
      ...badgeableWithBadges
    }
  }
}
    ${BadgeableWithBadgesFragmentDoc}`;
export type DestroyBadgeLinkMutationFn = Apollo.MutationFunction<Operation.DestroyBadgeLinkMutation, Operation.DestroyBadgeLinkMutationVariables>;

/**
 * __useDestroyBadgeLinkMutation__
 *
 * To run a mutation, you first call `useDestroyBadgeLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyBadgeLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyBadgeLinkMutation, { data, loading, error }] = useDestroyBadgeLinkMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useDestroyBadgeLinkMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DestroyBadgeLinkMutation, Operation.DestroyBadgeLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DestroyBadgeLinkMutation, Operation.DestroyBadgeLinkMutationVariables>(DestroyBadgeLinkDocument, options);
      }
export type DestroyBadgeLinkMutationHookResult = ReturnType<typeof useDestroyBadgeLinkMutation>;
export type DestroyBadgeLinkMutationResult = Apollo.MutationResult<Operation.DestroyBadgeLinkMutation>;
export type DestroyBadgeLinkMutationOptions = Apollo.BaseMutationOptions<Operation.DestroyBadgeLinkMutation, Operation.DestroyBadgeLinkMutationVariables>;
export const CreateBadgesTemplateDocument = gql`
    mutation CreateBadgesTemplate($input: CreateBadgesTemplateInput!) {
  createBadgesTemplate(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    badgesTemplate {
      ...badgesTemplateDetailed
    }
  }
}
    ${BadgesTemplateDetailedFragmentDoc}`;
export type CreateBadgesTemplateMutationFn = Apollo.MutationFunction<Operation.CreateBadgesTemplateMutation, Operation.CreateBadgesTemplateMutationVariables>;

/**
 * __useCreateBadgesTemplateMutation__
 *
 * To run a mutation, you first call `useCreateBadgesTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBadgesTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBadgesTemplateMutation, { data, loading, error }] = useCreateBadgesTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBadgesTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateBadgesTemplateMutation, Operation.CreateBadgesTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateBadgesTemplateMutation, Operation.CreateBadgesTemplateMutationVariables>(CreateBadgesTemplateDocument, options);
      }
export type CreateBadgesTemplateMutationHookResult = ReturnType<typeof useCreateBadgesTemplateMutation>;
export type CreateBadgesTemplateMutationResult = Apollo.MutationResult<Operation.CreateBadgesTemplateMutation>;
export type CreateBadgesTemplateMutationOptions = Apollo.BaseMutationOptions<Operation.CreateBadgesTemplateMutation, Operation.CreateBadgesTemplateMutationVariables>;
export const UpdateBadgesTemplateDocument = gql`
    mutation UpdateBadgesTemplate($input: UpdateBadgesTemplateInput!) {
  updateBadgesTemplate(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    badgesTemplate {
      ...badgesTemplateDetailed
    }
  }
}
    ${BadgesTemplateDetailedFragmentDoc}`;
export type UpdateBadgesTemplateMutationFn = Apollo.MutationFunction<Operation.UpdateBadgesTemplateMutation, Operation.UpdateBadgesTemplateMutationVariables>;

/**
 * __useUpdateBadgesTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateBadgesTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBadgesTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBadgesTemplateMutation, { data, loading, error }] = useUpdateBadgesTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBadgesTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateBadgesTemplateMutation, Operation.UpdateBadgesTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateBadgesTemplateMutation, Operation.UpdateBadgesTemplateMutationVariables>(UpdateBadgesTemplateDocument, options);
      }
export type UpdateBadgesTemplateMutationHookResult = ReturnType<typeof useUpdateBadgesTemplateMutation>;
export type UpdateBadgesTemplateMutationResult = Apollo.MutationResult<Operation.UpdateBadgesTemplateMutation>;
export type UpdateBadgesTemplateMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateBadgesTemplateMutation, Operation.UpdateBadgesTemplateMutationVariables>;
export const ConfirmAppointmentGuestDocument = gql`
    mutation ConfirmAppointmentGuest($token: String!) {
  confirmAppointmentGuest(input: {token: $token}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    appointmentGuest {
      ...appointmentGuestDetailed
    }
  }
}
    ${AppointmentGuestDetailedFragmentDoc}`;
export type ConfirmAppointmentGuestMutationFn = Apollo.MutationFunction<Operation.ConfirmAppointmentGuestMutation, Operation.ConfirmAppointmentGuestMutationVariables>;

/**
 * __useConfirmAppointmentGuestMutation__
 *
 * To run a mutation, you first call `useConfirmAppointmentGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAppointmentGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAppointmentGuestMutation, { data, loading, error }] = useConfirmAppointmentGuestMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmAppointmentGuestMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ConfirmAppointmentGuestMutation, Operation.ConfirmAppointmentGuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ConfirmAppointmentGuestMutation, Operation.ConfirmAppointmentGuestMutationVariables>(ConfirmAppointmentGuestDocument, options);
      }
export type ConfirmAppointmentGuestMutationHookResult = ReturnType<typeof useConfirmAppointmentGuestMutation>;
export type ConfirmAppointmentGuestMutationResult = Apollo.MutationResult<Operation.ConfirmAppointmentGuestMutation>;
export type ConfirmAppointmentGuestMutationOptions = Apollo.BaseMutationOptions<Operation.ConfirmAppointmentGuestMutation, Operation.ConfirmAppointmentGuestMutationVariables>;
export const AddAppointmentGuestsDocument = gql`
    mutation AddAppointmentGuests($appointmentId: ID!, $userPracticeConnectionIds: [ID!], $deliverEmail: Boolean, $autoConfirm: Boolean) {
  addAppointmentGuests(
    input: {appointmentId: $appointmentId, attributes: {userPracticeConnectionIds: $userPracticeConnectionIds, deliverEmail: $deliverEmail, autoConfirm: $autoConfirm}}
  ) {
    errors
    fieldErrors {
      message
      field
    }
    appointment {
      ...appointmentCalendarEventDetailed
    }
  }
}
    ${AppointmentCalendarEventDetailedFragmentDoc}`;
export type AddAppointmentGuestsMutationFn = Apollo.MutationFunction<Operation.AddAppointmentGuestsMutation, Operation.AddAppointmentGuestsMutationVariables>;

/**
 * __useAddAppointmentGuestsMutation__
 *
 * To run a mutation, you first call `useAddAppointmentGuestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAppointmentGuestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAppointmentGuestsMutation, { data, loading, error }] = useAddAppointmentGuestsMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      userPracticeConnectionIds: // value for 'userPracticeConnectionIds'
 *      deliverEmail: // value for 'deliverEmail'
 *      autoConfirm: // value for 'autoConfirm'
 *   },
 * });
 */
export function useAddAppointmentGuestsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AddAppointmentGuestsMutation, Operation.AddAppointmentGuestsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AddAppointmentGuestsMutation, Operation.AddAppointmentGuestsMutationVariables>(AddAppointmentGuestsDocument, options);
      }
export type AddAppointmentGuestsMutationHookResult = ReturnType<typeof useAddAppointmentGuestsMutation>;
export type AddAppointmentGuestsMutationResult = Apollo.MutationResult<Operation.AddAppointmentGuestsMutation>;
export type AddAppointmentGuestsMutationOptions = Apollo.BaseMutationOptions<Operation.AddAppointmentGuestsMutation, Operation.AddAppointmentGuestsMutationVariables>;
export const RemoveAppointmentGuestDocument = gql`
    mutation RemoveAppointmentGuest($appointmentId: ID!, $appointmentGuestId: ID!) {
  removeAppointmentGuest(
    input: {appointmentId: $appointmentId, appointmentGuestId: $appointmentGuestId}
  ) {
    errors
    fieldErrors {
      message
      field
    }
    appointment {
      ...appointmentCalendarEventDetailed
    }
  }
}
    ${AppointmentCalendarEventDetailedFragmentDoc}`;
export type RemoveAppointmentGuestMutationFn = Apollo.MutationFunction<Operation.RemoveAppointmentGuestMutation, Operation.RemoveAppointmentGuestMutationVariables>;

/**
 * __useRemoveAppointmentGuestMutation__
 *
 * To run a mutation, you first call `useRemoveAppointmentGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAppointmentGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAppointmentGuestMutation, { data, loading, error }] = useRemoveAppointmentGuestMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      appointmentGuestId: // value for 'appointmentGuestId'
 *   },
 * });
 */
export function useRemoveAppointmentGuestMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RemoveAppointmentGuestMutation, Operation.RemoveAppointmentGuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RemoveAppointmentGuestMutation, Operation.RemoveAppointmentGuestMutationVariables>(RemoveAppointmentGuestDocument, options);
      }
export type RemoveAppointmentGuestMutationHookResult = ReturnType<typeof useRemoveAppointmentGuestMutation>;
export type RemoveAppointmentGuestMutationResult = Apollo.MutationResult<Operation.RemoveAppointmentGuestMutation>;
export type RemoveAppointmentGuestMutationOptions = Apollo.BaseMutationOptions<Operation.RemoveAppointmentGuestMutation, Operation.RemoveAppointmentGuestMutationVariables>;
export const UpdateAppointmentGuestDocument = gql`
    mutation UpdateAppointmentGuest($appointmentGuestId: ID!, $state: AppointmentGuestState!) {
  updateAppointmentGuest(
    input: {appointmentGuestId: $appointmentGuestId, state: $state}
  ) {
    errors
    fieldErrors {
      message
      field
    }
    appointment {
      ...appointmentCalendarEventDetailed
    }
  }
}
    ${AppointmentCalendarEventDetailedFragmentDoc}`;
export type UpdateAppointmentGuestMutationFn = Apollo.MutationFunction<Operation.UpdateAppointmentGuestMutation, Operation.UpdateAppointmentGuestMutationVariables>;

/**
 * __useUpdateAppointmentGuestMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentGuestMutation, { data, loading, error }] = useUpdateAppointmentGuestMutation({
 *   variables: {
 *      appointmentGuestId: // value for 'appointmentGuestId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateAppointmentGuestMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateAppointmentGuestMutation, Operation.UpdateAppointmentGuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateAppointmentGuestMutation, Operation.UpdateAppointmentGuestMutationVariables>(UpdateAppointmentGuestDocument, options);
      }
export type UpdateAppointmentGuestMutationHookResult = ReturnType<typeof useUpdateAppointmentGuestMutation>;
export type UpdateAppointmentGuestMutationResult = Apollo.MutationResult<Operation.UpdateAppointmentGuestMutation>;
export type UpdateAppointmentGuestMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateAppointmentGuestMutation, Operation.UpdateAppointmentGuestMutationVariables>;
export const CreateAppointmentDocument = gql`
    mutation CreateAppointment($input: CreateAppointmentInput!) {
  createAppointment(input: $input) {
    errors
    fieldErrors {
      message
      field
    }
    appointment {
      ...appointmentCalendarEvent
      ...appointmentExhaustive
    }
  }
}
    ${AppointmentCalendarEventFragmentDoc}
${AppointmentExhaustiveFragmentDoc}`;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<Operation.CreateAppointmentMutation, Operation.CreateAppointmentMutationVariables>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateAppointmentMutation, Operation.CreateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateAppointmentMutation, Operation.CreateAppointmentMutationVariables>(CreateAppointmentDocument, options);
      }
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<Operation.CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<Operation.CreateAppointmentMutation, Operation.CreateAppointmentMutationVariables>;
export const CreateAppointmentsDocument = gql`
    mutation CreateAppointments($input: CreateAppointmentsInput!) {
  createAppointments(input: $input) {
    success
    errors
    fieldErrors {
      message
      field
    }
    appointments {
      ...appointmentExhaustive
    }
  }
}
    ${AppointmentExhaustiveFragmentDoc}`;
export type CreateAppointmentsMutationFn = Apollo.MutationFunction<Operation.CreateAppointmentsMutation, Operation.CreateAppointmentsMutationVariables>;

/**
 * __useCreateAppointmentsMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentsMutation, { data, loading, error }] = useCreateAppointmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateAppointmentsMutation, Operation.CreateAppointmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateAppointmentsMutation, Operation.CreateAppointmentsMutationVariables>(CreateAppointmentsDocument, options);
      }
export type CreateAppointmentsMutationHookResult = ReturnType<typeof useCreateAppointmentsMutation>;
export type CreateAppointmentsMutationResult = Apollo.MutationResult<Operation.CreateAppointmentsMutation>;
export type CreateAppointmentsMutationOptions = Apollo.BaseMutationOptions<Operation.CreateAppointmentsMutation, Operation.CreateAppointmentsMutationVariables>;
export const CreateAppointmentForTreatmentPlanDocument = gql`
    mutation CreateAppointmentForTreatmentPlan($input: CreateAppointmentForTreatmentPlanInput!) {
  createAppointmentForTreatmentPlan(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    appointment {
      ...treatmentPlanAppointment
    }
  }
}
    ${TreatmentPlanAppointmentFragmentDoc}`;
export type CreateAppointmentForTreatmentPlanMutationFn = Apollo.MutationFunction<Operation.CreateAppointmentForTreatmentPlanMutation, Operation.CreateAppointmentForTreatmentPlanMutationVariables>;

/**
 * __useCreateAppointmentForTreatmentPlanMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentForTreatmentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentForTreatmentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentForTreatmentPlanMutation, { data, loading, error }] = useCreateAppointmentForTreatmentPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentForTreatmentPlanMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateAppointmentForTreatmentPlanMutation, Operation.CreateAppointmentForTreatmentPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateAppointmentForTreatmentPlanMutation, Operation.CreateAppointmentForTreatmentPlanMutationVariables>(CreateAppointmentForTreatmentPlanDocument, options);
      }
export type CreateAppointmentForTreatmentPlanMutationHookResult = ReturnType<typeof useCreateAppointmentForTreatmentPlanMutation>;
export type CreateAppointmentForTreatmentPlanMutationResult = Apollo.MutationResult<Operation.CreateAppointmentForTreatmentPlanMutation>;
export type CreateAppointmentForTreatmentPlanMutationOptions = Apollo.BaseMutationOptions<Operation.CreateAppointmentForTreatmentPlanMutation, Operation.CreateAppointmentForTreatmentPlanMutationVariables>;
export const CreateWaitlistDocument = gql`
    mutation CreateWaitlist($attributes: WaitlistCreateInput!) {
  createWaitlist(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    waitlist {
      ...waitlistDetailed
    }
  }
}
    ${WaitlistDetailedFragmentDoc}`;
export type CreateWaitlistMutationFn = Apollo.MutationFunction<Operation.CreateWaitlistMutation, Operation.CreateWaitlistMutationVariables>;

/**
 * __useCreateWaitlistMutation__
 *
 * To run a mutation, you first call `useCreateWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWaitlistMutation, { data, loading, error }] = useCreateWaitlistMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateWaitlistMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateWaitlistMutation, Operation.CreateWaitlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateWaitlistMutation, Operation.CreateWaitlistMutationVariables>(CreateWaitlistDocument, options);
      }
export type CreateWaitlistMutationHookResult = ReturnType<typeof useCreateWaitlistMutation>;
export type CreateWaitlistMutationResult = Apollo.MutationResult<Operation.CreateWaitlistMutation>;
export type CreateWaitlistMutationOptions = Apollo.BaseMutationOptions<Operation.CreateWaitlistMutation, Operation.CreateWaitlistMutationVariables>;
export const DeleteWaitlistDocument = gql`
    mutation DeleteWaitlist($id: ID!) {
  deleteWaitlist(input: {id: $id}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    waitlist {
      ...waitlistEssentials
    }
  }
}
    ${WaitlistEssentialsFragmentDoc}`;
export type DeleteWaitlistMutationFn = Apollo.MutationFunction<Operation.DeleteWaitlistMutation, Operation.DeleteWaitlistMutationVariables>;

/**
 * __useDeleteWaitlistMutation__
 *
 * To run a mutation, you first call `useDeleteWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWaitlistMutation, { data, loading, error }] = useDeleteWaitlistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWaitlistMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeleteWaitlistMutation, Operation.DeleteWaitlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeleteWaitlistMutation, Operation.DeleteWaitlistMutationVariables>(DeleteWaitlistDocument, options);
      }
export type DeleteWaitlistMutationHookResult = ReturnType<typeof useDeleteWaitlistMutation>;
export type DeleteWaitlistMutationResult = Apollo.MutationResult<Operation.DeleteWaitlistMutation>;
export type DeleteWaitlistMutationOptions = Apollo.BaseMutationOptions<Operation.DeleteWaitlistMutation, Operation.DeleteWaitlistMutationVariables>;
export const ConfirmOnlineBookingDocument = gql`
    mutation ConfirmOnlineBooking($userPracticeConnectionId: ID!) {
  confirmOnlineBooking(
    input: {userPracticeConnectionId: $userPracticeConnectionId}
  ) {
    errors
    fieldErrors {
      message
      field
    }
    userPracticeConnection {
      ...userPracticeConnectionEssentials
    }
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}`;
export type ConfirmOnlineBookingMutationFn = Apollo.MutationFunction<Operation.ConfirmOnlineBookingMutation, Operation.ConfirmOnlineBookingMutationVariables>;

/**
 * __useConfirmOnlineBookingMutation__
 *
 * To run a mutation, you first call `useConfirmOnlineBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOnlineBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOnlineBookingMutation, { data, loading, error }] = useConfirmOnlineBookingMutation({
 *   variables: {
 *      userPracticeConnectionId: // value for 'userPracticeConnectionId'
 *   },
 * });
 */
export function useConfirmOnlineBookingMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ConfirmOnlineBookingMutation, Operation.ConfirmOnlineBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ConfirmOnlineBookingMutation, Operation.ConfirmOnlineBookingMutationVariables>(ConfirmOnlineBookingDocument, options);
      }
export type ConfirmOnlineBookingMutationHookResult = ReturnType<typeof useConfirmOnlineBookingMutation>;
export type ConfirmOnlineBookingMutationResult = Apollo.MutationResult<Operation.ConfirmOnlineBookingMutation>;
export type ConfirmOnlineBookingMutationOptions = Apollo.BaseMutationOptions<Operation.ConfirmOnlineBookingMutation, Operation.ConfirmOnlineBookingMutationVariables>;
export const RejectOnlineBookingDocument = gql`
    mutation RejectOnlineBooking($userPracticeConnectionId: ID!) {
  rejectOnlineBooking(
    input: {userPracticeConnectionId: $userPracticeConnectionId}
  ) {
    errors
    fieldErrors {
      message
      field
    }
    userPracticeConnection {
      ...userPracticeConnectionEssentials
    }
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}`;
export type RejectOnlineBookingMutationFn = Apollo.MutationFunction<Operation.RejectOnlineBookingMutation, Operation.RejectOnlineBookingMutationVariables>;

/**
 * __useRejectOnlineBookingMutation__
 *
 * To run a mutation, you first call `useRejectOnlineBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectOnlineBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectOnlineBookingMutation, { data, loading, error }] = useRejectOnlineBookingMutation({
 *   variables: {
 *      userPracticeConnectionId: // value for 'userPracticeConnectionId'
 *   },
 * });
 */
export function useRejectOnlineBookingMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RejectOnlineBookingMutation, Operation.RejectOnlineBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RejectOnlineBookingMutation, Operation.RejectOnlineBookingMutationVariables>(RejectOnlineBookingDocument, options);
      }
export type RejectOnlineBookingMutationHookResult = ReturnType<typeof useRejectOnlineBookingMutation>;
export type RejectOnlineBookingMutationResult = Apollo.MutationResult<Operation.RejectOnlineBookingMutation>;
export type RejectOnlineBookingMutationOptions = Apollo.BaseMutationOptions<Operation.RejectOnlineBookingMutation, Operation.RejectOnlineBookingMutationVariables>;
export const RejectAppointmentGuestDocument = gql`
    mutation RejectAppointmentGuest($token: String!) {
  rejectAppointmentGuest(input: {token: $token}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    appointmentGuest {
      ...appointmentGuestDetailed
    }
  }
}
    ${AppointmentGuestDetailedFragmentDoc}`;
export type RejectAppointmentGuestMutationFn = Apollo.MutationFunction<Operation.RejectAppointmentGuestMutation, Operation.RejectAppointmentGuestMutationVariables>;

/**
 * __useRejectAppointmentGuestMutation__
 *
 * To run a mutation, you first call `useRejectAppointmentGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAppointmentGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAppointmentGuestMutation, { data, loading, error }] = useRejectAppointmentGuestMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRejectAppointmentGuestMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RejectAppointmentGuestMutation, Operation.RejectAppointmentGuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RejectAppointmentGuestMutation, Operation.RejectAppointmentGuestMutationVariables>(RejectAppointmentGuestDocument, options);
      }
export type RejectAppointmentGuestMutationHookResult = ReturnType<typeof useRejectAppointmentGuestMutation>;
export type RejectAppointmentGuestMutationResult = Apollo.MutationResult<Operation.RejectAppointmentGuestMutation>;
export type RejectAppointmentGuestMutationOptions = Apollo.BaseMutationOptions<Operation.RejectAppointmentGuestMutation, Operation.RejectAppointmentGuestMutationVariables>;
export const RemoveAppointmentDocument = gql`
    mutation RemoveAppointment($input: RemoveAppointmentInput!) {
  removeAppointment(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    appointment {
      ...appointmentExhaustive
    }
  }
}
    ${AppointmentExhaustiveFragmentDoc}`;
export type RemoveAppointmentMutationFn = Apollo.MutationFunction<Operation.RemoveAppointmentMutation, Operation.RemoveAppointmentMutationVariables>;

/**
 * __useRemoveAppointmentMutation__
 *
 * To run a mutation, you first call `useRemoveAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAppointmentMutation, { data, loading, error }] = useRemoveAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RemoveAppointmentMutation, Operation.RemoveAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RemoveAppointmentMutation, Operation.RemoveAppointmentMutationVariables>(RemoveAppointmentDocument, options);
      }
export type RemoveAppointmentMutationHookResult = ReturnType<typeof useRemoveAppointmentMutation>;
export type RemoveAppointmentMutationResult = Apollo.MutationResult<Operation.RemoveAppointmentMutation>;
export type RemoveAppointmentMutationOptions = Apollo.BaseMutationOptions<Operation.RemoveAppointmentMutation, Operation.RemoveAppointmentMutationVariables>;
export const RescheduleAppointmentDocument = gql`
    mutation RescheduleAppointment($input: RescheduleAppointmentInput!) {
  rescheduleAppointment(input: $input) {
    errors
    fieldErrors {
      message
      field
    }
    success
    appointment {
      ...appointmentCalendarEvent
    }
  }
}
    ${AppointmentCalendarEventFragmentDoc}`;
export type RescheduleAppointmentMutationFn = Apollo.MutationFunction<Operation.RescheduleAppointmentMutation, Operation.RescheduleAppointmentMutationVariables>;

/**
 * __useRescheduleAppointmentMutation__
 *
 * To run a mutation, you first call `useRescheduleAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleAppointmentMutation, { data, loading, error }] = useRescheduleAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RescheduleAppointmentMutation, Operation.RescheduleAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RescheduleAppointmentMutation, Operation.RescheduleAppointmentMutationVariables>(RescheduleAppointmentDocument, options);
      }
export type RescheduleAppointmentMutationHookResult = ReturnType<typeof useRescheduleAppointmentMutation>;
export type RescheduleAppointmentMutationResult = Apollo.MutationResult<Operation.RescheduleAppointmentMutation>;
export type RescheduleAppointmentMutationOptions = Apollo.BaseMutationOptions<Operation.RescheduleAppointmentMutation, Operation.RescheduleAppointmentMutationVariables>;
export const RescheduleAppointmentsDocument = gql`
    mutation RescheduleAppointments($input: RescheduleAppointmentsInput!) {
  rescheduleAppointments(input: $input) {
    success
    errors
    fieldErrors {
      message
      field
    }
    appointments {
      ...appointmentExhaustive
    }
  }
}
    ${AppointmentExhaustiveFragmentDoc}`;
export type RescheduleAppointmentsMutationFn = Apollo.MutationFunction<Operation.RescheduleAppointmentsMutation, Operation.RescheduleAppointmentsMutationVariables>;

/**
 * __useRescheduleAppointmentsMutation__
 *
 * To run a mutation, you first call `useRescheduleAppointmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleAppointmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleAppointmentsMutation, { data, loading, error }] = useRescheduleAppointmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleAppointmentsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RescheduleAppointmentsMutation, Operation.RescheduleAppointmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RescheduleAppointmentsMutation, Operation.RescheduleAppointmentsMutationVariables>(RescheduleAppointmentsDocument, options);
      }
export type RescheduleAppointmentsMutationHookResult = ReturnType<typeof useRescheduleAppointmentsMutation>;
export type RescheduleAppointmentsMutationResult = Apollo.MutationResult<Operation.RescheduleAppointmentsMutation>;
export type RescheduleAppointmentsMutationOptions = Apollo.BaseMutationOptions<Operation.RescheduleAppointmentsMutation, Operation.RescheduleAppointmentsMutationVariables>;
export const SendAppointmentConfirmationNotificationsDocument = gql`
    mutation SendAppointmentConfirmationNotifications($input: SendAppointmentConfirmationNotificationsInput!) {
  sendAppointmentConfirmationNotifications(input: $input) {
    errors
    fieldErrors {
      message
      field
    }
    success
    appointment {
      ...appointmentExhaustive
    }
  }
}
    ${AppointmentExhaustiveFragmentDoc}`;
export type SendAppointmentConfirmationNotificationsMutationFn = Apollo.MutationFunction<Operation.SendAppointmentConfirmationNotificationsMutation, Operation.SendAppointmentConfirmationNotificationsMutationVariables>;

/**
 * __useSendAppointmentConfirmationNotificationsMutation__
 *
 * To run a mutation, you first call `useSendAppointmentConfirmationNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAppointmentConfirmationNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAppointmentConfirmationNotificationsMutation, { data, loading, error }] = useSendAppointmentConfirmationNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAppointmentConfirmationNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.SendAppointmentConfirmationNotificationsMutation, Operation.SendAppointmentConfirmationNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.SendAppointmentConfirmationNotificationsMutation, Operation.SendAppointmentConfirmationNotificationsMutationVariables>(SendAppointmentConfirmationNotificationsDocument, options);
      }
export type SendAppointmentConfirmationNotificationsMutationHookResult = ReturnType<typeof useSendAppointmentConfirmationNotificationsMutation>;
export type SendAppointmentConfirmationNotificationsMutationResult = Apollo.MutationResult<Operation.SendAppointmentConfirmationNotificationsMutation>;
export type SendAppointmentConfirmationNotificationsMutationOptions = Apollo.BaseMutationOptions<Operation.SendAppointmentConfirmationNotificationsMutation, Operation.SendAppointmentConfirmationNotificationsMutationVariables>;
export const SendAppointmentReminderNotificationsDocument = gql`
    mutation SendAppointmentReminderNotifications($input: SendAppointmentReminderNotificationsInput!) {
  sendAppointmentReminderNotifications(input: $input) {
    errors
    fieldErrors {
      message
      field
    }
    success
    appointment {
      ...appointmentExhaustive
    }
  }
}
    ${AppointmentExhaustiveFragmentDoc}`;
export type SendAppointmentReminderNotificationsMutationFn = Apollo.MutationFunction<Operation.SendAppointmentReminderNotificationsMutation, Operation.SendAppointmentReminderNotificationsMutationVariables>;

/**
 * __useSendAppointmentReminderNotificationsMutation__
 *
 * To run a mutation, you first call `useSendAppointmentReminderNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAppointmentReminderNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAppointmentReminderNotificationsMutation, { data, loading, error }] = useSendAppointmentReminderNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAppointmentReminderNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.SendAppointmentReminderNotificationsMutation, Operation.SendAppointmentReminderNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.SendAppointmentReminderNotificationsMutation, Operation.SendAppointmentReminderNotificationsMutationVariables>(SendAppointmentReminderNotificationsDocument, options);
      }
export type SendAppointmentReminderNotificationsMutationHookResult = ReturnType<typeof useSendAppointmentReminderNotificationsMutation>;
export type SendAppointmentReminderNotificationsMutationResult = Apollo.MutationResult<Operation.SendAppointmentReminderNotificationsMutation>;
export type SendAppointmentReminderNotificationsMutationOptions = Apollo.BaseMutationOptions<Operation.SendAppointmentReminderNotificationsMutation, Operation.SendAppointmentReminderNotificationsMutationVariables>;
export const UpdateAppointmentDocument = gql`
    mutation UpdateAppointment($input: UpdateAppointmentInput!) {
  updateAppointment(input: $input) {
    errors
    fieldErrors {
      message
      field
    }
    success
    appointment {
      ...appointmentExhaustive
    }
  }
}
    ${AppointmentExhaustiveFragmentDoc}`;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<Operation.UpdateAppointmentMutation, Operation.UpdateAppointmentMutationVariables>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateAppointmentMutation, Operation.UpdateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateAppointmentMutation, Operation.UpdateAppointmentMutationVariables>(UpdateAppointmentDocument, options);
      }
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<Operation.UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateAppointmentMutation, Operation.UpdateAppointmentMutationVariables>;
export const UpdateAppointmentCalendarEventDocument = gql`
    mutation UpdateAppointmentCalendarEvent($input: UpdateAppointmentInput!) {
  updateAppointment(input: $input) {
    errors
    fieldErrors {
      message
      field
    }
    success
    appointment {
      ...appointmentCalendarEvent
      practitioner {
        ...userPracticeConnectionMinimal
      }
      room {
        ...roomEssentials
      }
    }
  }
}
    ${AppointmentCalendarEventFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${RoomEssentialsFragmentDoc}`;
export type UpdateAppointmentCalendarEventMutationFn = Apollo.MutationFunction<Operation.UpdateAppointmentCalendarEventMutation, Operation.UpdateAppointmentCalendarEventMutationVariables>;

/**
 * __useUpdateAppointmentCalendarEventMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentCalendarEventMutation, { data, loading, error }] = useUpdateAppointmentCalendarEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentCalendarEventMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateAppointmentCalendarEventMutation, Operation.UpdateAppointmentCalendarEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateAppointmentCalendarEventMutation, Operation.UpdateAppointmentCalendarEventMutationVariables>(UpdateAppointmentCalendarEventDocument, options);
      }
export type UpdateAppointmentCalendarEventMutationHookResult = ReturnType<typeof useUpdateAppointmentCalendarEventMutation>;
export type UpdateAppointmentCalendarEventMutationResult = Apollo.MutationResult<Operation.UpdateAppointmentCalendarEventMutation>;
export type UpdateAppointmentCalendarEventMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateAppointmentCalendarEventMutation, Operation.UpdateAppointmentCalendarEventMutationVariables>;
export const UpdateAppointmentStateDocument = gql`
    mutation UpdateAppointmentState($input: UpdateAppointmentInput!) {
  updateAppointment(input: $input) {
    errors
    fieldErrors {
      message
      field
    }
    success
    appointment {
      ...appointmentStateEvent
    }
  }
}
    ${AppointmentStateEventFragmentDoc}`;
export type UpdateAppointmentStateMutationFn = Apollo.MutationFunction<Operation.UpdateAppointmentStateMutation, Operation.UpdateAppointmentStateMutationVariables>;

/**
 * __useUpdateAppointmentStateMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentStateMutation, { data, loading, error }] = useUpdateAppointmentStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentStateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateAppointmentStateMutation, Operation.UpdateAppointmentStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateAppointmentStateMutation, Operation.UpdateAppointmentStateMutationVariables>(UpdateAppointmentStateDocument, options);
      }
export type UpdateAppointmentStateMutationHookResult = ReturnType<typeof useUpdateAppointmentStateMutation>;
export type UpdateAppointmentStateMutationResult = Apollo.MutationResult<Operation.UpdateAppointmentStateMutation>;
export type UpdateAppointmentStateMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateAppointmentStateMutation, Operation.UpdateAppointmentStateMutationVariables>;
export const UpdateAppointmentEssentialsDocument = gql`
    mutation UpdateAppointmentEssentials($input: UpdateAppointmentInput!) {
  updateAppointment(input: $input) {
    errors
    fieldErrors {
      message
      field
    }
    success
    appointment {
      ...appointmentEssentials
    }
  }
}
    ${AppointmentEssentialsFragmentDoc}`;
export type UpdateAppointmentEssentialsMutationFn = Apollo.MutationFunction<Operation.UpdateAppointmentEssentialsMutation, Operation.UpdateAppointmentEssentialsMutationVariables>;

/**
 * __useUpdateAppointmentEssentialsMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentEssentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentEssentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentEssentialsMutation, { data, loading, error }] = useUpdateAppointmentEssentialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentEssentialsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateAppointmentEssentialsMutation, Operation.UpdateAppointmentEssentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateAppointmentEssentialsMutation, Operation.UpdateAppointmentEssentialsMutationVariables>(UpdateAppointmentEssentialsDocument, options);
      }
export type UpdateAppointmentEssentialsMutationHookResult = ReturnType<typeof useUpdateAppointmentEssentialsMutation>;
export type UpdateAppointmentEssentialsMutationResult = Apollo.MutationResult<Operation.UpdateAppointmentEssentialsMutation>;
export type UpdateAppointmentEssentialsMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateAppointmentEssentialsMutation, Operation.UpdateAppointmentEssentialsMutationVariables>;
export const UpdateAppointmentCommunicationsDocument = gql`
    mutation UpdateAppointmentCommunications($input: UpdateCommunicationsInput!) {
  updateAppointmentCommunications(input: $input) {
    errors
    fieldErrors {
      message
      field
    }
    success
    appointment {
      ...appointmentEssentials
    }
  }
}
    ${AppointmentEssentialsFragmentDoc}`;
export type UpdateAppointmentCommunicationsMutationFn = Apollo.MutationFunction<Operation.UpdateAppointmentCommunicationsMutation, Operation.UpdateAppointmentCommunicationsMutationVariables>;

/**
 * __useUpdateAppointmentCommunicationsMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentCommunicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentCommunicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentCommunicationsMutation, { data, loading, error }] = useUpdateAppointmentCommunicationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentCommunicationsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateAppointmentCommunicationsMutation, Operation.UpdateAppointmentCommunicationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateAppointmentCommunicationsMutation, Operation.UpdateAppointmentCommunicationsMutationVariables>(UpdateAppointmentCommunicationsDocument, options);
      }
export type UpdateAppointmentCommunicationsMutationHookResult = ReturnType<typeof useUpdateAppointmentCommunicationsMutation>;
export type UpdateAppointmentCommunicationsMutationResult = Apollo.MutationResult<Operation.UpdateAppointmentCommunicationsMutation>;
export type UpdateAppointmentCommunicationsMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateAppointmentCommunicationsMutation, Operation.UpdateAppointmentCommunicationsMutationVariables>;
export const ReorderTreatmentAppointmentDocument = gql`
    mutation ReorderTreatmentAppointment($input: ReorderTreatmentAppointmentInput!) {
  reorderTreatmentAppointment(input: $input) {
    errors
    fieldErrors {
      message
      field
    }
    success
    treatmentPlan {
      ...treatmentPlanDetailed
    }
  }
}
    ${TreatmentPlanDetailedFragmentDoc}`;
export type ReorderTreatmentAppointmentMutationFn = Apollo.MutationFunction<Operation.ReorderTreatmentAppointmentMutation, Operation.ReorderTreatmentAppointmentMutationVariables>;

/**
 * __useReorderTreatmentAppointmentMutation__
 *
 * To run a mutation, you first call `useReorderTreatmentAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderTreatmentAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderTreatmentAppointmentMutation, { data, loading, error }] = useReorderTreatmentAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderTreatmentAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ReorderTreatmentAppointmentMutation, Operation.ReorderTreatmentAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ReorderTreatmentAppointmentMutation, Operation.ReorderTreatmentAppointmentMutationVariables>(ReorderTreatmentAppointmentDocument, options);
      }
export type ReorderTreatmentAppointmentMutationHookResult = ReturnType<typeof useReorderTreatmentAppointmentMutation>;
export type ReorderTreatmentAppointmentMutationResult = Apollo.MutationResult<Operation.ReorderTreatmentAppointmentMutation>;
export type ReorderTreatmentAppointmentMutationOptions = Apollo.BaseMutationOptions<Operation.ReorderTreatmentAppointmentMutation, Operation.ReorderTreatmentAppointmentMutationVariables>;
export const UpdateWaitlistDocument = gql`
    mutation UpdateWaitlist($id: ID!, $attributes: WaitlistUpdateInput!) {
  updateWaitlist(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    waitlist {
      ...waitlistDetailed
    }
  }
}
    ${WaitlistDetailedFragmentDoc}`;
export type UpdateWaitlistMutationFn = Apollo.MutationFunction<Operation.UpdateWaitlistMutation, Operation.UpdateWaitlistMutationVariables>;

/**
 * __useUpdateWaitlistMutation__
 *
 * To run a mutation, you first call `useUpdateWaitlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWaitlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWaitlistMutation, { data, loading, error }] = useUpdateWaitlistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateWaitlistMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateWaitlistMutation, Operation.UpdateWaitlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateWaitlistMutation, Operation.UpdateWaitlistMutationVariables>(UpdateWaitlistDocument, options);
      }
export type UpdateWaitlistMutationHookResult = ReturnType<typeof useUpdateWaitlistMutation>;
export type UpdateWaitlistMutationResult = Apollo.MutationResult<Operation.UpdateWaitlistMutation>;
export type UpdateWaitlistMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateWaitlistMutation, Operation.UpdateWaitlistMutationVariables>;
export const CreateCancellationReasonDocument = gql`
    mutation createCancellationReason($attributes: CancellationReasonInput!) {
  createCancellationReason(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    cancellationReason {
      ...cancellationReasonEssentials
    }
  }
}
    ${CancellationReasonEssentialsFragmentDoc}`;
export type CreateCancellationReasonMutationFn = Apollo.MutationFunction<Operation.CreateCancellationReasonMutation, Operation.CreateCancellationReasonMutationVariables>;

/**
 * __useCreateCancellationReasonMutation__
 *
 * To run a mutation, you first call `useCreateCancellationReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCancellationReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCancellationReasonMutation, { data, loading, error }] = useCreateCancellationReasonMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateCancellationReasonMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateCancellationReasonMutation, Operation.CreateCancellationReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateCancellationReasonMutation, Operation.CreateCancellationReasonMutationVariables>(CreateCancellationReasonDocument, options);
      }
export type CreateCancellationReasonMutationHookResult = ReturnType<typeof useCreateCancellationReasonMutation>;
export type CreateCancellationReasonMutationResult = Apollo.MutationResult<Operation.CreateCancellationReasonMutation>;
export type CreateCancellationReasonMutationOptions = Apollo.BaseMutationOptions<Operation.CreateCancellationReasonMutation, Operation.CreateCancellationReasonMutationVariables>;
export const UpdateCancellationReasonDocument = gql`
    mutation updateCancellationReason($id: ID!, $attributes: CancellationReasonInput!) {
  updateCancellationReason(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    cancellationReason {
      ...cancellationReasonEssentials
    }
  }
}
    ${CancellationReasonEssentialsFragmentDoc}`;
export type UpdateCancellationReasonMutationFn = Apollo.MutationFunction<Operation.UpdateCancellationReasonMutation, Operation.UpdateCancellationReasonMutationVariables>;

/**
 * __useUpdateCancellationReasonMutation__
 *
 * To run a mutation, you first call `useUpdateCancellationReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCancellationReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCancellationReasonMutation, { data, loading, error }] = useUpdateCancellationReasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateCancellationReasonMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateCancellationReasonMutation, Operation.UpdateCancellationReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateCancellationReasonMutation, Operation.UpdateCancellationReasonMutationVariables>(UpdateCancellationReasonDocument, options);
      }
export type UpdateCancellationReasonMutationHookResult = ReturnType<typeof useUpdateCancellationReasonMutation>;
export type UpdateCancellationReasonMutationResult = Apollo.MutationResult<Operation.UpdateCancellationReasonMutation>;
export type UpdateCancellationReasonMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateCancellationReasonMutation, Operation.UpdateCancellationReasonMutationVariables>;
export const AssignPriceDocument = gql`
    mutation AssignPrice($priceList: ID!, $catalogueItem: ID!, $attributes: PriceAssignInput!) {
  assignPrice(
    input: {priceList: $priceList, catalogueItem: $catalogueItem, attributes: $attributes}
  ) {
    errors
    fieldErrors {
      field
      message
    }
    success
    price {
      ...priceMinimal
    }
    catalogueItem {
      id
      guid
      code
      name
    }
  }
}
    ${PriceMinimalFragmentDoc}`;
export type AssignPriceMutationFn = Apollo.MutationFunction<Operation.AssignPriceMutation, Operation.AssignPriceMutationVariables>;

/**
 * __useAssignPriceMutation__
 *
 * To run a mutation, you first call `useAssignPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPriceMutation, { data, loading, error }] = useAssignPriceMutation({
 *   variables: {
 *      priceList: // value for 'priceList'
 *      catalogueItem: // value for 'catalogueItem'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useAssignPriceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AssignPriceMutation, Operation.AssignPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AssignPriceMutation, Operation.AssignPriceMutationVariables>(AssignPriceDocument, options);
      }
export type AssignPriceMutationHookResult = ReturnType<typeof useAssignPriceMutation>;
export type AssignPriceMutationResult = Apollo.MutationResult<Operation.AssignPriceMutation>;
export type AssignPriceMutationOptions = Apollo.BaseMutationOptions<Operation.AssignPriceMutation, Operation.AssignPriceMutationVariables>;
export const CreateCatalogueItemDocument = gql`
    mutation CreateCatalogueItem($attributes: CatalogueItemInput!) {
  createCatalogueItem(input: {attributes: $attributes}) {
    errors
    success
    fieldErrors {
      field
      message
    }
    catalogueItem {
      ...catalogueItemDetailed
    }
  }
}
    ${CatalogueItemDetailedFragmentDoc}`;
export type CreateCatalogueItemMutationFn = Apollo.MutationFunction<Operation.CreateCatalogueItemMutation, Operation.CreateCatalogueItemMutationVariables>;

/**
 * __useCreateCatalogueItemMutation__
 *
 * To run a mutation, you first call `useCreateCatalogueItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCatalogueItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCatalogueItemMutation, { data, loading, error }] = useCreateCatalogueItemMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateCatalogueItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateCatalogueItemMutation, Operation.CreateCatalogueItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateCatalogueItemMutation, Operation.CreateCatalogueItemMutationVariables>(CreateCatalogueItemDocument, options);
      }
export type CreateCatalogueItemMutationHookResult = ReturnType<typeof useCreateCatalogueItemMutation>;
export type CreateCatalogueItemMutationResult = Apollo.MutationResult<Operation.CreateCatalogueItemMutation>;
export type CreateCatalogueItemMutationOptions = Apollo.BaseMutationOptions<Operation.CreateCatalogueItemMutation, Operation.CreateCatalogueItemMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($input: CategoryCreateInput!) {
  createCategory(input: {attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    category {
      ...categoryEssential
    }
  }
}
    ${CategoryEssentialFragmentDoc}`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<Operation.CreateCategoryMutation, Operation.CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateCategoryMutation, Operation.CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateCategoryMutation, Operation.CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<Operation.CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<Operation.CreateCategoryMutation, Operation.CreateCategoryMutationVariables>;
export const CreatePriceListDocument = gql`
    mutation CreatePriceList($attributes: PriceListInput!) {
  createPriceList(input: {attributes: $attributes}) {
    success
    fieldErrors {
      field
      message
    }
    errors
    priceList {
      ...priceListDetailed
    }
  }
}
    ${PriceListDetailedFragmentDoc}`;
export type CreatePriceListMutationFn = Apollo.MutationFunction<Operation.CreatePriceListMutation, Operation.CreatePriceListMutationVariables>;

/**
 * __useCreatePriceListMutation__
 *
 * To run a mutation, you first call `useCreatePriceListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePriceListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPriceListMutation, { data, loading, error }] = useCreatePriceListMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreatePriceListMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreatePriceListMutation, Operation.CreatePriceListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreatePriceListMutation, Operation.CreatePriceListMutationVariables>(CreatePriceListDocument, options);
      }
export type CreatePriceListMutationHookResult = ReturnType<typeof useCreatePriceListMutation>;
export type CreatePriceListMutationResult = Apollo.MutationResult<Operation.CreatePriceListMutation>;
export type CreatePriceListMutationOptions = Apollo.BaseMutationOptions<Operation.CreatePriceListMutation, Operation.CreatePriceListMutationVariables>;
export const CreateMaterialDocument = gql`
    mutation CreateMaterial($attributes: MaterialInput!) {
  createMaterial(input: {attributes: $attributes}) {
    success
    fieldErrors {
      message
      field
    }
    errors
    material {
      ...materialEssentials
    }
  }
}
    ${MaterialEssentialsFragmentDoc}`;
export type CreateMaterialMutationFn = Apollo.MutationFunction<Operation.CreateMaterialMutation, Operation.CreateMaterialMutationVariables>;

/**
 * __useCreateMaterialMutation__
 *
 * To run a mutation, you first call `useCreateMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaterialMutation, { data, loading, error }] = useCreateMaterialMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateMaterialMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateMaterialMutation, Operation.CreateMaterialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateMaterialMutation, Operation.CreateMaterialMutationVariables>(CreateMaterialDocument, options);
      }
export type CreateMaterialMutationHookResult = ReturnType<typeof useCreateMaterialMutation>;
export type CreateMaterialMutationResult = Apollo.MutationResult<Operation.CreateMaterialMutation>;
export type CreateMaterialMutationOptions = Apollo.BaseMutationOptions<Operation.CreateMaterialMutation, Operation.CreateMaterialMutationVariables>;
export const UpdateMaterialDocument = gql`
    mutation UpdateMaterial($id: ID!, $attributes: MaterialInput!) {
  updateMaterial(input: {material: $id, attributes: $attributes}) {
    success
    fieldErrors {
      message
      field
    }
    errors
    material {
      ...materialEssentials
    }
  }
}
    ${MaterialEssentialsFragmentDoc}`;
export type UpdateMaterialMutationFn = Apollo.MutationFunction<Operation.UpdateMaterialMutation, Operation.UpdateMaterialMutationVariables>;

/**
 * __useUpdateMaterialMutation__
 *
 * To run a mutation, you first call `useUpdateMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaterialMutation, { data, loading, error }] = useUpdateMaterialMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateMaterialMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateMaterialMutation, Operation.UpdateMaterialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateMaterialMutation, Operation.UpdateMaterialMutationVariables>(UpdateMaterialDocument, options);
      }
export type UpdateMaterialMutationHookResult = ReturnType<typeof useUpdateMaterialMutation>;
export type UpdateMaterialMutationResult = Apollo.MutationResult<Operation.UpdateMaterialMutation>;
export type UpdateMaterialMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateMaterialMutation, Operation.UpdateMaterialMutationVariables>;
export const TransitionPriceListStateDocument = gql`
    mutation TransitionPriceListState($guid: ID!, $event: PriceListStateEvent!) {
  transitionPriceList(input: {guid: $guid, event: $event}) {
    success
    fieldErrors {
      field
      message
    }
    errors
    priceList {
      ...priceListEssentials
    }
  }
}
    ${PriceListEssentialsFragmentDoc}`;
export type TransitionPriceListStateMutationFn = Apollo.MutationFunction<Operation.TransitionPriceListStateMutation, Operation.TransitionPriceListStateMutationVariables>;

/**
 * __useTransitionPriceListStateMutation__
 *
 * To run a mutation, you first call `useTransitionPriceListStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionPriceListStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionPriceListStateMutation, { data, loading, error }] = useTransitionPriceListStateMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionPriceListStateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionPriceListStateMutation, Operation.TransitionPriceListStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionPriceListStateMutation, Operation.TransitionPriceListStateMutationVariables>(TransitionPriceListStateDocument, options);
      }
export type TransitionPriceListStateMutationHookResult = ReturnType<typeof useTransitionPriceListStateMutation>;
export type TransitionPriceListStateMutationResult = Apollo.MutationResult<Operation.TransitionPriceListStateMutation>;
export type TransitionPriceListStateMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionPriceListStateMutation, Operation.TransitionPriceListStateMutationVariables>;
export const TransitionStateCategoryDocument = gql`
    mutation TransitionStateCategory($id: ID!, $event: CategoryStateEvent!) {
  transitionStateCategory(input: {id: $id, event: $event}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    category {
      ...categoryEssential
    }
  }
}
    ${CategoryEssentialFragmentDoc}`;
export type TransitionStateCategoryMutationFn = Apollo.MutationFunction<Operation.TransitionStateCategoryMutation, Operation.TransitionStateCategoryMutationVariables>;

/**
 * __useTransitionStateCategoryMutation__
 *
 * To run a mutation, you first call `useTransitionStateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionStateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionStateCategoryMutation, { data, loading, error }] = useTransitionStateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionStateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionStateCategoryMutation, Operation.TransitionStateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionStateCategoryMutation, Operation.TransitionStateCategoryMutationVariables>(TransitionStateCategoryDocument, options);
      }
export type TransitionStateCategoryMutationHookResult = ReturnType<typeof useTransitionStateCategoryMutation>;
export type TransitionStateCategoryMutationResult = Apollo.MutationResult<Operation.TransitionStateCategoryMutation>;
export type TransitionStateCategoryMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionStateCategoryMutation, Operation.TransitionStateCategoryMutationVariables>;
export const UpdateCatalogueItemDocument = gql`
    mutation UpdateCatalogueItem($id: ID!, $attributes: CatalogueItemInput!) {
  updateCatalogueItem(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    catalogueItem {
      ...catalogueItemDetailed
    }
  }
}
    ${CatalogueItemDetailedFragmentDoc}`;
export type UpdateCatalogueItemMutationFn = Apollo.MutationFunction<Operation.UpdateCatalogueItemMutation, Operation.UpdateCatalogueItemMutationVariables>;

/**
 * __useUpdateCatalogueItemMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueItemMutation, { data, loading, error }] = useUpdateCatalogueItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateCatalogueItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateCatalogueItemMutation, Operation.UpdateCatalogueItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateCatalogueItemMutation, Operation.UpdateCatalogueItemMutationVariables>(UpdateCatalogueItemDocument, options);
      }
export type UpdateCatalogueItemMutationHookResult = ReturnType<typeof useUpdateCatalogueItemMutation>;
export type UpdateCatalogueItemMutationResult = Apollo.MutationResult<Operation.UpdateCatalogueItemMutation>;
export type UpdateCatalogueItemMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateCatalogueItemMutation, Operation.UpdateCatalogueItemMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($id: ID!, $input: CategoryUpdateInput!) {
  updateCategory(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    category {
      ...categoryEssential
    }
  }
}
    ${CategoryEssentialFragmentDoc}`;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<Operation.UpdateCategoryMutation, Operation.UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateCategoryMutation, Operation.UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateCategoryMutation, Operation.UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<Operation.UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateCategoryMutation, Operation.UpdateCategoryMutationVariables>;
export const UpdatePriceListDocument = gql`
    mutation UpdatePriceList($guid: ID!, $attributes: PriceListInput!) {
  updatePriceList(input: {guid: $guid, attributes: $attributes}) {
    success
    fieldErrors {
      field
      message
    }
    errors
    priceList {
      ...priceListDetailed
    }
  }
}
    ${PriceListDetailedFragmentDoc}`;
export type UpdatePriceListMutationFn = Apollo.MutationFunction<Operation.UpdatePriceListMutation, Operation.UpdatePriceListMutationVariables>;

/**
 * __useUpdatePriceListMutation__
 *
 * To run a mutation, you first call `useUpdatePriceListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePriceListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePriceListMutation, { data, loading, error }] = useUpdatePriceListMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdatePriceListMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdatePriceListMutation, Operation.UpdatePriceListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdatePriceListMutation, Operation.UpdatePriceListMutationVariables>(UpdatePriceListDocument, options);
      }
export type UpdatePriceListMutationHookResult = ReturnType<typeof useUpdatePriceListMutation>;
export type UpdatePriceListMutationResult = Apollo.MutationResult<Operation.UpdatePriceListMutation>;
export type UpdatePriceListMutationOptions = Apollo.BaseMutationOptions<Operation.UpdatePriceListMutation, Operation.UpdatePriceListMutationVariables>;
export const CreateAttachmentDocument = gql`
    mutation CreateAttachment($attributes: AttachmentCreateInput!) {
  createAttachment(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    attachment {
      ...attachmentEssentials
      directory {
        ...directory
      }
    }
  }
}
    ${AttachmentEssentialsFragmentDoc}
${DirectoryFragmentDoc}`;
export type CreateAttachmentMutationFn = Apollo.MutationFunction<Operation.CreateAttachmentMutation, Operation.CreateAttachmentMutationVariables>;

/**
 * __useCreateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentMutation, { data, loading, error }] = useCreateAttachmentMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateAttachmentMutation, Operation.CreateAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateAttachmentMutation, Operation.CreateAttachmentMutationVariables>(CreateAttachmentDocument, options);
      }
export type CreateAttachmentMutationHookResult = ReturnType<typeof useCreateAttachmentMutation>;
export type CreateAttachmentMutationResult = Apollo.MutationResult<Operation.CreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<Operation.CreateAttachmentMutation, Operation.CreateAttachmentMutationVariables>;
export const MoveDirectoryContentsDocument = gql`
    mutation MoveDirectoryContents($sources: [ID!]!, $destination: ID!) {
  moveDocuments(input: {sources: $sources, destination: $destination}) {
    fieldErrors {
      field
      message
    }
    errors
    directories {
      ...movedDirectory
    }
    sources {
      ... on Directory {
        ...movedDirectory
      }
      ... on Attachment {
        ...attachmentMinimal
      }
    }
  }
}
    ${MovedDirectoryFragmentDoc}
${AttachmentMinimalFragmentDoc}`;
export type MoveDirectoryContentsMutationFn = Apollo.MutationFunction<Operation.MoveDirectoryContentsMutation, Operation.MoveDirectoryContentsMutationVariables>;

/**
 * __useMoveDirectoryContentsMutation__
 *
 * To run a mutation, you first call `useMoveDirectoryContentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDirectoryContentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDirectoryContentsMutation, { data, loading, error }] = useMoveDirectoryContentsMutation({
 *   variables: {
 *      sources: // value for 'sources'
 *      destination: // value for 'destination'
 *   },
 * });
 */
export function useMoveDirectoryContentsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.MoveDirectoryContentsMutation, Operation.MoveDirectoryContentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.MoveDirectoryContentsMutation, Operation.MoveDirectoryContentsMutationVariables>(MoveDirectoryContentsDocument, options);
      }
export type MoveDirectoryContentsMutationHookResult = ReturnType<typeof useMoveDirectoryContentsMutation>;
export type MoveDirectoryContentsMutationResult = Apollo.MutationResult<Operation.MoveDirectoryContentsMutation>;
export type MoveDirectoryContentsMutationOptions = Apollo.BaseMutationOptions<Operation.MoveDirectoryContentsMutation, Operation.MoveDirectoryContentsMutationVariables>;
export const UpdateAttachmentDocument = gql`
    mutation UpdateAttachment($id: ID!, $input: AttachmentInput!) {
  updateAttachment(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    attachment {
      ...attachmentEssentials
    }
  }
}
    ${AttachmentEssentialsFragmentDoc}`;
export type UpdateAttachmentMutationFn = Apollo.MutationFunction<Operation.UpdateAttachmentMutation, Operation.UpdateAttachmentMutationVariables>;

/**
 * __useUpdateAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttachmentMutation, { data, loading, error }] = useUpdateAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateAttachmentMutation, Operation.UpdateAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateAttachmentMutation, Operation.UpdateAttachmentMutationVariables>(UpdateAttachmentDocument, options);
      }
export type UpdateAttachmentMutationHookResult = ReturnType<typeof useUpdateAttachmentMutation>;
export type UpdateAttachmentMutationResult = Apollo.MutationResult<Operation.UpdateAttachmentMutation>;
export type UpdateAttachmentMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateAttachmentMutation, Operation.UpdateAttachmentMutationVariables>;
export const BulkUpdateAttachmentDocument = gql`
    mutation BulkUpdateAttachment($ids: [ID!]!, $attributes: AttachmentMetaInput!) {
  bulkUpdateAttachments(input: {attachments: $ids, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    attachments {
      ...attachmentEssentials
    }
  }
}
    ${AttachmentEssentialsFragmentDoc}`;
export type BulkUpdateAttachmentMutationFn = Apollo.MutationFunction<Operation.BulkUpdateAttachmentMutation, Operation.BulkUpdateAttachmentMutationVariables>;

/**
 * __useBulkUpdateAttachmentMutation__
 *
 * To run a mutation, you first call `useBulkUpdateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateAttachmentMutation, { data, loading, error }] = useBulkUpdateAttachmentMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useBulkUpdateAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.BulkUpdateAttachmentMutation, Operation.BulkUpdateAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.BulkUpdateAttachmentMutation, Operation.BulkUpdateAttachmentMutationVariables>(BulkUpdateAttachmentDocument, options);
      }
export type BulkUpdateAttachmentMutationHookResult = ReturnType<typeof useBulkUpdateAttachmentMutation>;
export type BulkUpdateAttachmentMutationResult = Apollo.MutationResult<Operation.BulkUpdateAttachmentMutation>;
export type BulkUpdateAttachmentMutationOptions = Apollo.BaseMutationOptions<Operation.BulkUpdateAttachmentMutation, Operation.BulkUpdateAttachmentMutationVariables>;
export const ArchiveAttachmentDocument = gql`
    mutation ArchiveAttachment($id: ID!) {
  archiveAttachment(input: {id: $id}) {
    errors
    fieldErrors {
      field
      message
    }
    attachment {
      ...attachmentEssentials
      directory {
        ...directory
      }
    }
  }
}
    ${AttachmentEssentialsFragmentDoc}
${DirectoryFragmentDoc}`;
export type ArchiveAttachmentMutationFn = Apollo.MutationFunction<Operation.ArchiveAttachmentMutation, Operation.ArchiveAttachmentMutationVariables>;

/**
 * __useArchiveAttachmentMutation__
 *
 * To run a mutation, you first call `useArchiveAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAttachmentMutation, { data, loading, error }] = useArchiveAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ArchiveAttachmentMutation, Operation.ArchiveAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ArchiveAttachmentMutation, Operation.ArchiveAttachmentMutationVariables>(ArchiveAttachmentDocument, options);
      }
export type ArchiveAttachmentMutationHookResult = ReturnType<typeof useArchiveAttachmentMutation>;
export type ArchiveAttachmentMutationResult = Apollo.MutationResult<Operation.ArchiveAttachmentMutation>;
export type ArchiveAttachmentMutationOptions = Apollo.BaseMutationOptions<Operation.ArchiveAttachmentMutation, Operation.ArchiveAttachmentMutationVariables>;
export const ArchiveAttachmentsDocument = gql`
    mutation ArchiveAttachments($ids: [ID!]!) {
  archiveAttachments(input: {ids: $ids}) {
    success
    errors
    fieldErrors {
      field
      message
    }
    attachments {
      ...attachmentEssentials
    }
    directories {
      ...directory
    }
  }
}
    ${AttachmentEssentialsFragmentDoc}
${DirectoryFragmentDoc}`;
export type ArchiveAttachmentsMutationFn = Apollo.MutationFunction<Operation.ArchiveAttachmentsMutation, Operation.ArchiveAttachmentsMutationVariables>;

/**
 * __useArchiveAttachmentsMutation__
 *
 * To run a mutation, you first call `useArchiveAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAttachmentsMutation, { data, loading, error }] = useArchiveAttachmentsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ArchiveAttachmentsMutation, Operation.ArchiveAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ArchiveAttachmentsMutation, Operation.ArchiveAttachmentsMutationVariables>(ArchiveAttachmentsDocument, options);
      }
export type ArchiveAttachmentsMutationHookResult = ReturnType<typeof useArchiveAttachmentsMutation>;
export type ArchiveAttachmentsMutationResult = Apollo.MutationResult<Operation.ArchiveAttachmentsMutation>;
export type ArchiveAttachmentsMutationOptions = Apollo.BaseMutationOptions<Operation.ArchiveAttachmentsMutation, Operation.ArchiveAttachmentsMutationVariables>;
export const CreateTagDocument = gql`
    mutation CreateTag($attributes: TagInput!) {
  createTag(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    tag {
      ...tagDetailed
    }
  }
}
    ${TagDetailedFragmentDoc}`;
export type CreateTagMutationFn = Apollo.MutationFunction<Operation.CreateTagMutation, Operation.CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateTagMutation, Operation.CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateTagMutation, Operation.CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<Operation.CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<Operation.CreateTagMutation, Operation.CreateTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: ID!) {
  deleteTag(input: {id: $id}) {
    errors
    fieldErrors {
      field
      message
    }
    tag {
      ...tagDetailed
    }
  }
}
    ${TagDetailedFragmentDoc}`;
export type DeleteTagMutationFn = Apollo.MutationFunction<Operation.DeleteTagMutation, Operation.DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeleteTagMutation, Operation.DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeleteTagMutation, Operation.DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<Operation.DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<Operation.DeleteTagMutation, Operation.DeleteTagMutationVariables>;
export const CancelEmailDocument = gql`
    mutation CancelEmail($id: ID!) {
  cancelEmail(input: {id: $id}) {
    fieldErrors {
      field
      message
    }
    errors
    email {
      ...emailEssentials
    }
  }
}
    ${EmailEssentialsFragmentDoc}`;
export type CancelEmailMutationFn = Apollo.MutationFunction<Operation.CancelEmailMutation, Operation.CancelEmailMutationVariables>;

/**
 * __useCancelEmailMutation__
 *
 * To run a mutation, you first call `useCancelEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEmailMutation, { data, loading, error }] = useCancelEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelEmailMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CancelEmailMutation, Operation.CancelEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CancelEmailMutation, Operation.CancelEmailMutationVariables>(CancelEmailDocument, options);
      }
export type CancelEmailMutationHookResult = ReturnType<typeof useCancelEmailMutation>;
export type CancelEmailMutationResult = Apollo.MutationResult<Operation.CancelEmailMutation>;
export type CancelEmailMutationOptions = Apollo.BaseMutationOptions<Operation.CancelEmailMutation, Operation.CancelEmailMutationVariables>;
export const CancelSmsDocument = gql`
    mutation CancelSms($id: ID!) {
  cancelSms(input: {id: $id}) {
    fieldErrors {
      field
      message
    }
    errors
    sms {
      ...smsEssentials
    }
  }
}
    ${SmsEssentialsFragmentDoc}`;
export type CancelSmsMutationFn = Apollo.MutationFunction<Operation.CancelSmsMutation, Operation.CancelSmsMutationVariables>;

/**
 * __useCancelSmsMutation__
 *
 * To run a mutation, you first call `useCancelSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSmsMutation, { data, loading, error }] = useCancelSmsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelSmsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CancelSmsMutation, Operation.CancelSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CancelSmsMutation, Operation.CancelSmsMutationVariables>(CancelSmsDocument, options);
      }
export type CancelSmsMutationHookResult = ReturnType<typeof useCancelSmsMutation>;
export type CancelSmsMutationResult = Apollo.MutationResult<Operation.CancelSmsMutation>;
export type CancelSmsMutationOptions = Apollo.BaseMutationOptions<Operation.CancelSmsMutation, Operation.CancelSmsMutationVariables>;
export const CreateFormDocument = gql`
    mutation CreateForm($attributes: FormCreateInput!) {
  createForm(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    form {
      ...form
    }
  }
}
    ${FormFragmentDoc}`;
export type CreateFormMutationFn = Apollo.MutationFunction<Operation.CreateFormMutation, Operation.CreateFormMutationVariables>;

/**
 * __useCreateFormMutation__
 *
 * To run a mutation, you first call `useCreateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormMutation, { data, loading, error }] = useCreateFormMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateFormMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateFormMutation, Operation.CreateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateFormMutation, Operation.CreateFormMutationVariables>(CreateFormDocument, options);
      }
export type CreateFormMutationHookResult = ReturnType<typeof useCreateFormMutation>;
export type CreateFormMutationResult = Apollo.MutationResult<Operation.CreateFormMutation>;
export type CreateFormMutationOptions = Apollo.BaseMutationOptions<Operation.CreateFormMutation, Operation.CreateFormMutationVariables>;
export const CreateFormTemplateDocument = gql`
    mutation CreateFormTemplate($attributes: FormTemplateInput!) {
  createFormTemplate(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    template {
      ...formTemplateDetailed
    }
  }
}
    ${FormTemplateDetailedFragmentDoc}`;
export type CreateFormTemplateMutationFn = Apollo.MutationFunction<Operation.CreateFormTemplateMutation, Operation.CreateFormTemplateMutationVariables>;

/**
 * __useCreateFormTemplateMutation__
 *
 * To run a mutation, you first call `useCreateFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormTemplateMutation, { data, loading, error }] = useCreateFormTemplateMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateFormTemplateMutation, Operation.CreateFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateFormTemplateMutation, Operation.CreateFormTemplateMutationVariables>(CreateFormTemplateDocument, options);
      }
export type CreateFormTemplateMutationHookResult = ReturnType<typeof useCreateFormTemplateMutation>;
export type CreateFormTemplateMutationResult = Apollo.MutationResult<Operation.CreateFormTemplateMutation>;
export type CreateFormTemplateMutationOptions = Apollo.BaseMutationOptions<Operation.CreateFormTemplateMutation, Operation.CreateFormTemplateMutationVariables>;
export const CreateNoteDocument = gql`
    mutation CreateNote($attributes: NoteInput!) {
  createNote(input: {attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    note {
      ...noteEssentials
    }
  }
}
    ${NoteEssentialsFragmentDoc}`;
export type CreateNoteMutationFn = Apollo.MutationFunction<Operation.CreateNoteMutation, Operation.CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateNoteMutation, Operation.CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateNoteMutation, Operation.CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<Operation.CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<Operation.CreateNoteMutation, Operation.CreateNoteMutationVariables>;
export const CreateSnippetDocument = gql`
    mutation CreateSnippet($input: SnippetInput!) {
  createSnippet(input: {attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    snippet {
      ...snippetDetailed
    }
  }
}
    ${SnippetDetailedFragmentDoc}`;
export type CreateSnippetMutationFn = Apollo.MutationFunction<Operation.CreateSnippetMutation, Operation.CreateSnippetMutationVariables>;

/**
 * __useCreateSnippetMutation__
 *
 * To run a mutation, you first call `useCreateSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSnippetMutation, { data, loading, error }] = useCreateSnippetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSnippetMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateSnippetMutation, Operation.CreateSnippetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateSnippetMutation, Operation.CreateSnippetMutationVariables>(CreateSnippetDocument, options);
      }
export type CreateSnippetMutationHookResult = ReturnType<typeof useCreateSnippetMutation>;
export type CreateSnippetMutationResult = Apollo.MutationResult<Operation.CreateSnippetMutation>;
export type CreateSnippetMutationOptions = Apollo.BaseMutationOptions<Operation.CreateSnippetMutation, Operation.CreateSnippetMutationVariables>;
export const CreateTemplateDocument = gql`
    mutation CreateTemplate($attributes: TemplateInput!) {
  createTemplate(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    template {
      ...templateDetails
    }
  }
}
    ${TemplateDetailsFragmentDoc}`;
export type CreateTemplateMutationFn = Apollo.MutationFunction<Operation.CreateTemplateMutation, Operation.CreateTemplateMutationVariables>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateTemplateMutation, Operation.CreateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateTemplateMutation, Operation.CreateTemplateMutationVariables>(CreateTemplateDocument, options);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = Apollo.MutationResult<Operation.CreateTemplateMutation>;
export type CreateTemplateMutationOptions = Apollo.BaseMutationOptions<Operation.CreateTemplateMutation, Operation.CreateTemplateMutationVariables>;
export const DeleteFormDocument = gql`
    mutation DeleteForm($formId: ID!) {
  deleteForm(input: {form: $formId}) {
    success
    errors
    fieldErrors {
      field
      message
    }
    form {
      ...form
    }
  }
}
    ${FormFragmentDoc}`;
export type DeleteFormMutationFn = Apollo.MutationFunction<Operation.DeleteFormMutation, Operation.DeleteFormMutationVariables>;

/**
 * __useDeleteFormMutation__
 *
 * To run a mutation, you first call `useDeleteFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormMutation, { data, loading, error }] = useDeleteFormMutation({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useDeleteFormMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeleteFormMutation, Operation.DeleteFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeleteFormMutation, Operation.DeleteFormMutationVariables>(DeleteFormDocument, options);
      }
export type DeleteFormMutationHookResult = ReturnType<typeof useDeleteFormMutation>;
export type DeleteFormMutationResult = Apollo.MutationResult<Operation.DeleteFormMutation>;
export type DeleteFormMutationOptions = Apollo.BaseMutationOptions<Operation.DeleteFormMutation, Operation.DeleteFormMutationVariables>;
export const DuplicateFormTemplateDocument = gql`
    mutation DuplicateFormTemplate($id: ID!) {
  duplicateFormTemplate(input: {id: $id}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    template {
      ...formTemplateDetailed
    }
  }
}
    ${FormTemplateDetailedFragmentDoc}`;
export type DuplicateFormTemplateMutationFn = Apollo.MutationFunction<Operation.DuplicateFormTemplateMutation, Operation.DuplicateFormTemplateMutationVariables>;

/**
 * __useDuplicateFormTemplateMutation__
 *
 * To run a mutation, you first call `useDuplicateFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateFormTemplateMutation, { data, loading, error }] = useDuplicateFormTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DuplicateFormTemplateMutation, Operation.DuplicateFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DuplicateFormTemplateMutation, Operation.DuplicateFormTemplateMutationVariables>(DuplicateFormTemplateDocument, options);
      }
export type DuplicateFormTemplateMutationHookResult = ReturnType<typeof useDuplicateFormTemplateMutation>;
export type DuplicateFormTemplateMutationResult = Apollo.MutationResult<Operation.DuplicateFormTemplateMutation>;
export type DuplicateFormTemplateMutationOptions = Apollo.BaseMutationOptions<Operation.DuplicateFormTemplateMutation, Operation.DuplicateFormTemplateMutationVariables>;
export const PinNoteDocument = gql`
    mutation PinNote($guid: ID!) {
  pinNote(input: {note: $guid}) {
    fieldErrors {
      field
      message
    }
    errors
    note {
      ...noteEssentials
    }
  }
}
    ${NoteEssentialsFragmentDoc}`;
export type PinNoteMutationFn = Apollo.MutationFunction<Operation.PinNoteMutation, Operation.PinNoteMutationVariables>;

/**
 * __usePinNoteMutation__
 *
 * To run a mutation, you first call `usePinNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinNoteMutation, { data, loading, error }] = usePinNoteMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function usePinNoteMutation(baseOptions?: Apollo.MutationHookOptions<Operation.PinNoteMutation, Operation.PinNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.PinNoteMutation, Operation.PinNoteMutationVariables>(PinNoteDocument, options);
      }
export type PinNoteMutationHookResult = ReturnType<typeof usePinNoteMutation>;
export type PinNoteMutationResult = Apollo.MutationResult<Operation.PinNoteMutation>;
export type PinNoteMutationOptions = Apollo.BaseMutationOptions<Operation.PinNoteMutation, Operation.PinNoteMutationVariables>;
export const PrintLetterDocument = gql`
    mutation PrintLetter($letterId: ID!) {
  printLetter(input: {letterId: $letterId}) {
    errors
    fieldErrors {
      field
      message
    }
    message {
      ...messageEssentials
    }
  }
}
    ${MessageEssentialsFragmentDoc}`;
export type PrintLetterMutationFn = Apollo.MutationFunction<Operation.PrintLetterMutation, Operation.PrintLetterMutationVariables>;

/**
 * __usePrintLetterMutation__
 *
 * To run a mutation, you first call `usePrintLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printLetterMutation, { data, loading, error }] = usePrintLetterMutation({
 *   variables: {
 *      letterId: // value for 'letterId'
 *   },
 * });
 */
export function usePrintLetterMutation(baseOptions?: Apollo.MutationHookOptions<Operation.PrintLetterMutation, Operation.PrintLetterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.PrintLetterMutation, Operation.PrintLetterMutationVariables>(PrintLetterDocument, options);
      }
export type PrintLetterMutationHookResult = ReturnType<typeof usePrintLetterMutation>;
export type PrintLetterMutationResult = Apollo.MutationResult<Operation.PrintLetterMutation>;
export type PrintLetterMutationOptions = Apollo.BaseMutationOptions<Operation.PrintLetterMutation, Operation.PrintLetterMutationVariables>;
export const RegenerateFormLinkDocument = gql`
    mutation RegenerateFormLink($attributes: FormGenerateLinkInput!) {
  regenerateFormLink(input: {attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    form {
      ...form
    }
  }
}
    ${FormFragmentDoc}`;
export type RegenerateFormLinkMutationFn = Apollo.MutationFunction<Operation.RegenerateFormLinkMutation, Operation.RegenerateFormLinkMutationVariables>;

/**
 * __useRegenerateFormLinkMutation__
 *
 * To run a mutation, you first call `useRegenerateFormLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateFormLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateFormLinkMutation, { data, loading, error }] = useRegenerateFormLinkMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useRegenerateFormLinkMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RegenerateFormLinkMutation, Operation.RegenerateFormLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RegenerateFormLinkMutation, Operation.RegenerateFormLinkMutationVariables>(RegenerateFormLinkDocument, options);
      }
export type RegenerateFormLinkMutationHookResult = ReturnType<typeof useRegenerateFormLinkMutation>;
export type RegenerateFormLinkMutationResult = Apollo.MutationResult<Operation.RegenerateFormLinkMutation>;
export type RegenerateFormLinkMutationOptions = Apollo.BaseMutationOptions<Operation.RegenerateFormLinkMutation, Operation.RegenerateFormLinkMutationVariables>;
export const RetryEmailDocument = gql`
    mutation RetryEmail($id: ID!) {
  retryEmail(input: {id: $id}) {
    fieldErrors {
      field
      message
    }
    errors
    email {
      ...emailEssentials
    }
  }
}
    ${EmailEssentialsFragmentDoc}`;
export type RetryEmailMutationFn = Apollo.MutationFunction<Operation.RetryEmailMutation, Operation.RetryEmailMutationVariables>;

/**
 * __useRetryEmailMutation__
 *
 * To run a mutation, you first call `useRetryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryEmailMutation, { data, loading, error }] = useRetryEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetryEmailMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RetryEmailMutation, Operation.RetryEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RetryEmailMutation, Operation.RetryEmailMutationVariables>(RetryEmailDocument, options);
      }
export type RetryEmailMutationHookResult = ReturnType<typeof useRetryEmailMutation>;
export type RetryEmailMutationResult = Apollo.MutationResult<Operation.RetryEmailMutation>;
export type RetryEmailMutationOptions = Apollo.BaseMutationOptions<Operation.RetryEmailMutation, Operation.RetryEmailMutationVariables>;
export const RetrySmsDocument = gql`
    mutation RetrySms($id: ID!) {
  retrySms(input: {id: $id}) {
    fieldErrors {
      field
      message
    }
    errors
    sms {
      ...smsEssentials
    }
  }
}
    ${SmsEssentialsFragmentDoc}`;
export type RetrySmsMutationFn = Apollo.MutationFunction<Operation.RetrySmsMutation, Operation.RetrySmsMutationVariables>;

/**
 * __useRetrySmsMutation__
 *
 * To run a mutation, you first call `useRetrySmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrySmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrySmsMutation, { data, loading, error }] = useRetrySmsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetrySmsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RetrySmsMutation, Operation.RetrySmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RetrySmsMutation, Operation.RetrySmsMutationVariables>(RetrySmsDocument, options);
      }
export type RetrySmsMutationHookResult = ReturnType<typeof useRetrySmsMutation>;
export type RetrySmsMutationResult = Apollo.MutationResult<Operation.RetrySmsMutation>;
export type RetrySmsMutationOptions = Apollo.BaseMutationOptions<Operation.RetrySmsMutation, Operation.RetrySmsMutationVariables>;
export const SendCommunicationsDocument = gql`
    mutation SendCommunications($input: SendCommunicationsInput!, $before: String) {
  sendCommunications(input: $input) {
    conversation {
      ...conversationDetailed
    }
    messages {
      ...messageEssentials
    }
    fieldErrors {
      field
      message
    }
    errors
    success
  }
}
    ${ConversationDetailedFragmentDoc}
${MessageEssentialsFragmentDoc}`;
export type SendCommunicationsMutationFn = Apollo.MutationFunction<Operation.SendCommunicationsMutation, Operation.SendCommunicationsMutationVariables>;

/**
 * __useSendCommunicationsMutation__
 *
 * To run a mutation, you first call `useSendCommunicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCommunicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCommunicationsMutation, { data, loading, error }] = useSendCommunicationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSendCommunicationsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.SendCommunicationsMutation, Operation.SendCommunicationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.SendCommunicationsMutation, Operation.SendCommunicationsMutationVariables>(SendCommunicationsDocument, options);
      }
export type SendCommunicationsMutationHookResult = ReturnType<typeof useSendCommunicationsMutation>;
export type SendCommunicationsMutationResult = Apollo.MutationResult<Operation.SendCommunicationsMutation>;
export type SendCommunicationsMutationOptions = Apollo.BaseMutationOptions<Operation.SendCommunicationsMutation, Operation.SendCommunicationsMutationVariables>;
export const SendBulkCommunicationsDocument = gql`
    mutation SendBulkCommunications($input: SendBulkCommunicationsInput!, $before: String) {
  sendBulkCommunications(input: $input) {
    success
    conversation {
      ...conversationDetailed
    }
    fieldErrors {
      field
      message
    }
    errors
  }
}
    ${ConversationDetailedFragmentDoc}`;
export type SendBulkCommunicationsMutationFn = Apollo.MutationFunction<Operation.SendBulkCommunicationsMutation, Operation.SendBulkCommunicationsMutationVariables>;

/**
 * __useSendBulkCommunicationsMutation__
 *
 * To run a mutation, you first call `useSendBulkCommunicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBulkCommunicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBulkCommunicationsMutation, { data, loading, error }] = useSendBulkCommunicationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSendBulkCommunicationsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.SendBulkCommunicationsMutation, Operation.SendBulkCommunicationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.SendBulkCommunicationsMutation, Operation.SendBulkCommunicationsMutationVariables>(SendBulkCommunicationsDocument, options);
      }
export type SendBulkCommunicationsMutationHookResult = ReturnType<typeof useSendBulkCommunicationsMutation>;
export type SendBulkCommunicationsMutationResult = Apollo.MutationResult<Operation.SendBulkCommunicationsMutation>;
export type SendBulkCommunicationsMutationOptions = Apollo.BaseMutationOptions<Operation.SendBulkCommunicationsMutation, Operation.SendBulkCommunicationsMutationVariables>;
export const TransitionFormTemplateStateDocument = gql`
    mutation TransitionFormTemplateState($id: ID!, $event: TemplateStateEvent!) {
  transitionFormTemplateState(input: {id: $id, event: $event}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    template {
      ...formTemplateDetailed
    }
  }
}
    ${FormTemplateDetailedFragmentDoc}`;
export type TransitionFormTemplateStateMutationFn = Apollo.MutationFunction<Operation.TransitionFormTemplateStateMutation, Operation.TransitionFormTemplateStateMutationVariables>;

/**
 * __useTransitionFormTemplateStateMutation__
 *
 * To run a mutation, you first call `useTransitionFormTemplateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionFormTemplateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionFormTemplateStateMutation, { data, loading, error }] = useTransitionFormTemplateStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionFormTemplateStateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionFormTemplateStateMutation, Operation.TransitionFormTemplateStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionFormTemplateStateMutation, Operation.TransitionFormTemplateStateMutationVariables>(TransitionFormTemplateStateDocument, options);
      }
export type TransitionFormTemplateStateMutationHookResult = ReturnType<typeof useTransitionFormTemplateStateMutation>;
export type TransitionFormTemplateStateMutationResult = Apollo.MutationResult<Operation.TransitionFormTemplateStateMutation>;
export type TransitionFormTemplateStateMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionFormTemplateStateMutation, Operation.TransitionFormTemplateStateMutationVariables>;
export const TransitionNoteStateDocument = gql`
    mutation TransitionNoteState($id: ID!, $event: NoteStateEvent!) {
  transitionNoteState(input: {id: $id, event: $event}) {
    fieldErrors {
      field
      message
    }
    errors
    note {
      ...noteEssentials
    }
  }
}
    ${NoteEssentialsFragmentDoc}`;
export type TransitionNoteStateMutationFn = Apollo.MutationFunction<Operation.TransitionNoteStateMutation, Operation.TransitionNoteStateMutationVariables>;

/**
 * __useTransitionNoteStateMutation__
 *
 * To run a mutation, you first call `useTransitionNoteStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionNoteStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionNoteStateMutation, { data, loading, error }] = useTransitionNoteStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionNoteStateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionNoteStateMutation, Operation.TransitionNoteStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionNoteStateMutation, Operation.TransitionNoteStateMutationVariables>(TransitionNoteStateDocument, options);
      }
export type TransitionNoteStateMutationHookResult = ReturnType<typeof useTransitionNoteStateMutation>;
export type TransitionNoteStateMutationResult = Apollo.MutationResult<Operation.TransitionNoteStateMutation>;
export type TransitionNoteStateMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionNoteStateMutation, Operation.TransitionNoteStateMutationVariables>;
export const TransitionStateTemplateDocument = gql`
    mutation TransitionStateTemplate($id: ID!, $event: TemplateStateEvent!) {
  transitionTemplateState(input: {id: $id, event: $event}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    template {
      ...templateDetails
    }
  }
}
    ${TemplateDetailsFragmentDoc}`;
export type TransitionStateTemplateMutationFn = Apollo.MutationFunction<Operation.TransitionStateTemplateMutation, Operation.TransitionStateTemplateMutationVariables>;

/**
 * __useTransitionStateTemplateMutation__
 *
 * To run a mutation, you first call `useTransitionStateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionStateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionStateTemplateMutation, { data, loading, error }] = useTransitionStateTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionStateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionStateTemplateMutation, Operation.TransitionStateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionStateTemplateMutation, Operation.TransitionStateTemplateMutationVariables>(TransitionStateTemplateDocument, options);
      }
export type TransitionStateTemplateMutationHookResult = ReturnType<typeof useTransitionStateTemplateMutation>;
export type TransitionStateTemplateMutationResult = Apollo.MutationResult<Operation.TransitionStateTemplateMutation>;
export type TransitionStateTemplateMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionStateTemplateMutation, Operation.TransitionStateTemplateMutationVariables>;
export const UpdateFormDocument = gql`
    mutation UpdateForm($id: ID!, $jsonContent: [FormFieldInput!]!) {
  updateForm(input: {id: $id, attributes: {jsonContent: $jsonContent}}) {
    errors
    fieldErrors {
      field
      message
    }
    form {
      ...form
    }
  }
}
    ${FormFragmentDoc}`;
export type UpdateFormMutationFn = Apollo.MutationFunction<Operation.UpdateFormMutation, Operation.UpdateFormMutationVariables>;

/**
 * __useUpdateFormMutation__
 *
 * To run a mutation, you first call `useUpdateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormMutation, { data, loading, error }] = useUpdateFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *      jsonContent: // value for 'jsonContent'
 *   },
 * });
 */
export function useUpdateFormMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateFormMutation, Operation.UpdateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateFormMutation, Operation.UpdateFormMutationVariables>(UpdateFormDocument, options);
      }
export type UpdateFormMutationHookResult = ReturnType<typeof useUpdateFormMutation>;
export type UpdateFormMutationResult = Apollo.MutationResult<Operation.UpdateFormMutation>;
export type UpdateFormMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateFormMutation, Operation.UpdateFormMutationVariables>;
export const CreateFormFieldDocument = gql`
    mutation CreateFormField($attributes: FormFieldInput!) {
  createFormField(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    section {
      ...formSection
    }
  }
}
    ${FormSectionFragmentDoc}`;
export type CreateFormFieldMutationFn = Apollo.MutationFunction<Operation.CreateFormFieldMutation, Operation.CreateFormFieldMutationVariables>;

/**
 * __useCreateFormFieldMutation__
 *
 * To run a mutation, you first call `useCreateFormFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormFieldMutation, { data, loading, error }] = useCreateFormFieldMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateFormFieldMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateFormFieldMutation, Operation.CreateFormFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateFormFieldMutation, Operation.CreateFormFieldMutationVariables>(CreateFormFieldDocument, options);
      }
export type CreateFormFieldMutationHookResult = ReturnType<typeof useCreateFormFieldMutation>;
export type CreateFormFieldMutationResult = Apollo.MutationResult<Operation.CreateFormFieldMutation>;
export type CreateFormFieldMutationOptions = Apollo.BaseMutationOptions<Operation.CreateFormFieldMutation, Operation.CreateFormFieldMutationVariables>;
export const UpdateFormFieldDocument = gql`
    mutation UpdateFormField($id: ID, $attributes: FormFieldInput!) {
  updateFormField(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    field {
      ...formField
    }
  }
}
    ${FormFieldFragmentDoc}`;
export type UpdateFormFieldMutationFn = Apollo.MutationFunction<Operation.UpdateFormFieldMutation, Operation.UpdateFormFieldMutationVariables>;

/**
 * __useUpdateFormFieldMutation__
 *
 * To run a mutation, you first call `useUpdateFormFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormFieldMutation, { data, loading, error }] = useUpdateFormFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateFormFieldMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateFormFieldMutation, Operation.UpdateFormFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateFormFieldMutation, Operation.UpdateFormFieldMutationVariables>(UpdateFormFieldDocument, options);
      }
export type UpdateFormFieldMutationHookResult = ReturnType<typeof useUpdateFormFieldMutation>;
export type UpdateFormFieldMutationResult = Apollo.MutationResult<Operation.UpdateFormFieldMutation>;
export type UpdateFormFieldMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateFormFieldMutation, Operation.UpdateFormFieldMutationVariables>;
export const ReorderFormFieldDocument = gql`
    mutation ReorderFormField($id: ID!, $position: Int!, $section: ID) {
  reorderFormField(input: {id: $id, position: $position, section: $section}) {
    errors
    fieldErrors {
      field
      message
    }
    section {
      ...formSection
    }
  }
}
    ${FormSectionFragmentDoc}`;
export type ReorderFormFieldMutationFn = Apollo.MutationFunction<Operation.ReorderFormFieldMutation, Operation.ReorderFormFieldMutationVariables>;

/**
 * __useReorderFormFieldMutation__
 *
 * To run a mutation, you first call `useReorderFormFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderFormFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderFormFieldMutation, { data, loading, error }] = useReorderFormFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      position: // value for 'position'
 *      section: // value for 'section'
 *   },
 * });
 */
export function useReorderFormFieldMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ReorderFormFieldMutation, Operation.ReorderFormFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ReorderFormFieldMutation, Operation.ReorderFormFieldMutationVariables>(ReorderFormFieldDocument, options);
      }
export type ReorderFormFieldMutationHookResult = ReturnType<typeof useReorderFormFieldMutation>;
export type ReorderFormFieldMutationResult = Apollo.MutationResult<Operation.ReorderFormFieldMutation>;
export type ReorderFormFieldMutationOptions = Apollo.BaseMutationOptions<Operation.ReorderFormFieldMutation, Operation.ReorderFormFieldMutationVariables>;
export const UpdateFormSectionDocument = gql`
    mutation UpdateFormSection($id: ID, $template: ID, $name: String, $position: Int) {
  updateFormSection(
    input: {id: $id, template: $template, name: $name, position: $position}
  ) {
    errors
    fieldErrors {
      field
      message
    }
    section {
      ...formSection
    }
  }
}
    ${FormSectionFragmentDoc}`;
export type UpdateFormSectionMutationFn = Apollo.MutationFunction<Operation.UpdateFormSectionMutation, Operation.UpdateFormSectionMutationVariables>;

/**
 * __useUpdateFormSectionMutation__
 *
 * To run a mutation, you first call `useUpdateFormSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormSectionMutation, { data, loading, error }] = useUpdateFormSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      template: // value for 'template'
 *      name: // value for 'name'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdateFormSectionMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateFormSectionMutation, Operation.UpdateFormSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateFormSectionMutation, Operation.UpdateFormSectionMutationVariables>(UpdateFormSectionDocument, options);
      }
export type UpdateFormSectionMutationHookResult = ReturnType<typeof useUpdateFormSectionMutation>;
export type UpdateFormSectionMutationResult = Apollo.MutationResult<Operation.UpdateFormSectionMutation>;
export type UpdateFormSectionMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateFormSectionMutation, Operation.UpdateFormSectionMutationVariables>;
export const DeleteFormSectionDocument = gql`
    mutation DeleteFormSection($id: ID) {
  deleteFormSection(input: {id: $id}) {
    errors
    fieldErrors {
      field
      message
    }
    section {
      ...formSection
    }
  }
}
    ${FormSectionFragmentDoc}`;
export type DeleteFormSectionMutationFn = Apollo.MutationFunction<Operation.DeleteFormSectionMutation, Operation.DeleteFormSectionMutationVariables>;

/**
 * __useDeleteFormSectionMutation__
 *
 * To run a mutation, you first call `useDeleteFormSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormSectionMutation, { data, loading, error }] = useDeleteFormSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormSectionMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeleteFormSectionMutation, Operation.DeleteFormSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeleteFormSectionMutation, Operation.DeleteFormSectionMutationVariables>(DeleteFormSectionDocument, options);
      }
export type DeleteFormSectionMutationHookResult = ReturnType<typeof useDeleteFormSectionMutation>;
export type DeleteFormSectionMutationResult = Apollo.MutationResult<Operation.DeleteFormSectionMutation>;
export type DeleteFormSectionMutationOptions = Apollo.BaseMutationOptions<Operation.DeleteFormSectionMutation, Operation.DeleteFormSectionMutationVariables>;
export const DeleteFormFieldDocument = gql`
    mutation DeleteFormField($id: ID) {
  deleteFormField(input: {id: $id}) {
    errors
    fieldErrors {
      field
      message
    }
    section {
      ...formSection
    }
  }
}
    ${FormSectionFragmentDoc}`;
export type DeleteFormFieldMutationFn = Apollo.MutationFunction<Operation.DeleteFormFieldMutation, Operation.DeleteFormFieldMutationVariables>;

/**
 * __useDeleteFormFieldMutation__
 *
 * To run a mutation, you first call `useDeleteFormFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormFieldMutation, { data, loading, error }] = useDeleteFormFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormFieldMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeleteFormFieldMutation, Operation.DeleteFormFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeleteFormFieldMutation, Operation.DeleteFormFieldMutationVariables>(DeleteFormFieldDocument, options);
      }
export type DeleteFormFieldMutationHookResult = ReturnType<typeof useDeleteFormFieldMutation>;
export type DeleteFormFieldMutationResult = Apollo.MutationResult<Operation.DeleteFormFieldMutation>;
export type DeleteFormFieldMutationOptions = Apollo.BaseMutationOptions<Operation.DeleteFormFieldMutation, Operation.DeleteFormFieldMutationVariables>;
export const ValidateFormSectionDocument = gql`
    mutation ValidateFormSection($id: ID) {
  validateFormSection(input: {id: $id}) {
    errors
    success
    fieldErrors {
      field
      message
    }
  }
}
    `;
export type ValidateFormSectionMutationFn = Apollo.MutationFunction<Operation.ValidateFormSectionMutation, Operation.ValidateFormSectionMutationVariables>;

/**
 * __useValidateFormSectionMutation__
 *
 * To run a mutation, you first call `useValidateFormSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateFormSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateFormSectionMutation, { data, loading, error }] = useValidateFormSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useValidateFormSectionMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ValidateFormSectionMutation, Operation.ValidateFormSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ValidateFormSectionMutation, Operation.ValidateFormSectionMutationVariables>(ValidateFormSectionDocument, options);
      }
export type ValidateFormSectionMutationHookResult = ReturnType<typeof useValidateFormSectionMutation>;
export type ValidateFormSectionMutationResult = Apollo.MutationResult<Operation.ValidateFormSectionMutation>;
export type ValidateFormSectionMutationOptions = Apollo.BaseMutationOptions<Operation.ValidateFormSectionMutation, Operation.ValidateFormSectionMutationVariables>;
export const ToggleFormBuilderV2Document = gql`
    mutation ToggleFormBuilderV2 {
  toggleFormBuilderV2(input: {}) {
    fieldErrors {
      field
      message
    }
    errors
    preference {
      ...practicePreference
    }
  }
}
    ${PracticePreferenceFragmentDoc}`;
export type ToggleFormBuilderV2MutationFn = Apollo.MutationFunction<Operation.ToggleFormBuilderV2Mutation, Operation.ToggleFormBuilderV2MutationVariables>;

/**
 * __useToggleFormBuilderV2Mutation__
 *
 * To run a mutation, you first call `useToggleFormBuilderV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleFormBuilderV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleFormBuilderV2Mutation, { data, loading, error }] = useToggleFormBuilderV2Mutation({
 *   variables: {
 *   },
 * });
 */
export function useToggleFormBuilderV2Mutation(baseOptions?: Apollo.MutationHookOptions<Operation.ToggleFormBuilderV2Mutation, Operation.ToggleFormBuilderV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ToggleFormBuilderV2Mutation, Operation.ToggleFormBuilderV2MutationVariables>(ToggleFormBuilderV2Document, options);
      }
export type ToggleFormBuilderV2MutationHookResult = ReturnType<typeof useToggleFormBuilderV2Mutation>;
export type ToggleFormBuilderV2MutationResult = Apollo.MutationResult<Operation.ToggleFormBuilderV2Mutation>;
export type ToggleFormBuilderV2MutationOptions = Apollo.BaseMutationOptions<Operation.ToggleFormBuilderV2Mutation, Operation.ToggleFormBuilderV2MutationVariables>;
export const UpdateFormTemplateDocument = gql`
    mutation UpdateFormTemplate($id: ID!, $attributes: FormTemplateInput!) {
  updateFormTemplate(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    template {
      ...formTemplateDetailed
    }
  }
}
    ${FormTemplateDetailedFragmentDoc}`;
export type UpdateFormTemplateMutationFn = Apollo.MutationFunction<Operation.UpdateFormTemplateMutation, Operation.UpdateFormTemplateMutationVariables>;

/**
 * __useUpdateFormTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormTemplateMutation, { data, loading, error }] = useUpdateFormTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateFormTemplateMutation, Operation.UpdateFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateFormTemplateMutation, Operation.UpdateFormTemplateMutationVariables>(UpdateFormTemplateDocument, options);
      }
export type UpdateFormTemplateMutationHookResult = ReturnType<typeof useUpdateFormTemplateMutation>;
export type UpdateFormTemplateMutationResult = Apollo.MutationResult<Operation.UpdateFormTemplateMutation>;
export type UpdateFormTemplateMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateFormTemplateMutation, Operation.UpdateFormTemplateMutationVariables>;
export const UpdateLetterDocument = gql`
    mutation UpdateLetter($guid: ID!, $attributes: LetterInput!) {
  updateLetter(input: {guid: $guid, attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    success
    letter {
      ...letterEssentials
    }
  }
}
    ${LetterEssentialsFragmentDoc}`;
export type UpdateLetterMutationFn = Apollo.MutationFunction<Operation.UpdateLetterMutation, Operation.UpdateLetterMutationVariables>;

/**
 * __useUpdateLetterMutation__
 *
 * To run a mutation, you first call `useUpdateLetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLetterMutation, { data, loading, error }] = useUpdateLetterMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateLetterMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateLetterMutation, Operation.UpdateLetterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateLetterMutation, Operation.UpdateLetterMutationVariables>(UpdateLetterDocument, options);
      }
export type UpdateLetterMutationHookResult = ReturnType<typeof useUpdateLetterMutation>;
export type UpdateLetterMutationResult = Apollo.MutationResult<Operation.UpdateLetterMutation>;
export type UpdateLetterMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateLetterMutation, Operation.UpdateLetterMutationVariables>;
export const UpdateMessageNoteDocument = gql`
    mutation UpdateMessageNote($messageId: ID!, $note: String!) {
  updateMessageNote(input: {messageId: $messageId, note: $note}) {
    errors
    fieldErrors {
      field
      message
    }
    message {
      ...messageEssentials
    }
  }
}
    ${MessageEssentialsFragmentDoc}`;
export type UpdateMessageNoteMutationFn = Apollo.MutationFunction<Operation.UpdateMessageNoteMutation, Operation.UpdateMessageNoteMutationVariables>;

/**
 * __useUpdateMessageNoteMutation__
 *
 * To run a mutation, you first call `useUpdateMessageNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageNoteMutation, { data, loading, error }] = useUpdateMessageNoteMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateMessageNoteMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateMessageNoteMutation, Operation.UpdateMessageNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateMessageNoteMutation, Operation.UpdateMessageNoteMutationVariables>(UpdateMessageNoteDocument, options);
      }
export type UpdateMessageNoteMutationHookResult = ReturnType<typeof useUpdateMessageNoteMutation>;
export type UpdateMessageNoteMutationResult = Apollo.MutationResult<Operation.UpdateMessageNoteMutation>;
export type UpdateMessageNoteMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateMessageNoteMutation, Operation.UpdateMessageNoteMutationVariables>;
export const UpdateNoteDocument = gql`
    mutation UpdateNote($guid: ID!, $attributes: NoteInput!) {
  updateNote(input: {note: $guid, attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    note {
      ...noteEssentials
    }
  }
}
    ${NoteEssentialsFragmentDoc}`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<Operation.UpdateNoteMutation, Operation.UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateNoteMutation, Operation.UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateNoteMutation, Operation.UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<Operation.UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateNoteMutation, Operation.UpdateNoteMutationVariables>;
export const UpdateSnippetDocument = gql`
    mutation UpdateSnippet($id: ID!, $input: SnippetInput!) {
  updateSnippet(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    snippet {
      ...snippetDetailed
    }
  }
}
    ${SnippetDetailedFragmentDoc}`;
export type UpdateSnippetMutationFn = Apollo.MutationFunction<Operation.UpdateSnippetMutation, Operation.UpdateSnippetMutationVariables>;

/**
 * __useUpdateSnippetMutation__
 *
 * To run a mutation, you first call `useUpdateSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSnippetMutation, { data, loading, error }] = useUpdateSnippetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSnippetMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateSnippetMutation, Operation.UpdateSnippetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateSnippetMutation, Operation.UpdateSnippetMutationVariables>(UpdateSnippetDocument, options);
      }
export type UpdateSnippetMutationHookResult = ReturnType<typeof useUpdateSnippetMutation>;
export type UpdateSnippetMutationResult = Apollo.MutationResult<Operation.UpdateSnippetMutation>;
export type UpdateSnippetMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateSnippetMutation, Operation.UpdateSnippetMutationVariables>;
export const UpdateTemplateDocument = gql`
    mutation UpdateTemplate($id: ID!, $attributes: TemplateInput!) {
  updateTemplate(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    template {
      ...templateDetails
    }
  }
}
    ${TemplateDetailsFragmentDoc}`;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<Operation.UpdateTemplateMutation, Operation.UpdateTemplateMutationVariables>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateTemplateMutation, Operation.UpdateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateTemplateMutation, Operation.UpdateTemplateMutationVariables>(UpdateTemplateDocument, options);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<Operation.UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateTemplateMutation, Operation.UpdateTemplateMutationVariables>;
export const UploadAttachmentsDocument = gql`
    mutation UploadAttachments($attributes: UploadAttachmentsInput!) {
  uploadAttachments(input: $attributes) {
    errors
    fieldErrors {
      field
      message
    }
    attachments {
      ...attachmentEssentials
    }
  }
}
    ${AttachmentEssentialsFragmentDoc}`;
export type UploadAttachmentsMutationFn = Apollo.MutationFunction<Operation.UploadAttachmentsMutation, Operation.UploadAttachmentsMutationVariables>;

/**
 * __useUploadAttachmentsMutation__
 *
 * To run a mutation, you first call `useUploadAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAttachmentsMutation, { data, loading, error }] = useUploadAttachmentsMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUploadAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UploadAttachmentsMutation, Operation.UploadAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UploadAttachmentsMutation, Operation.UploadAttachmentsMutationVariables>(UploadAttachmentsDocument, options);
      }
export type UploadAttachmentsMutationHookResult = ReturnType<typeof useUploadAttachmentsMutation>;
export type UploadAttachmentsMutationResult = Apollo.MutationResult<Operation.UploadAttachmentsMutation>;
export type UploadAttachmentsMutationOptions = Apollo.BaseMutationOptions<Operation.UploadAttachmentsMutation, Operation.UploadAttachmentsMutationVariables>;
export const CreateCustomerRequestDocument = gql`
    mutation CreateCustomerRequest($attributes: CustomerRequestInput!) {
  createCustomerRequest(input: {attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    success
    customerRequest {
      ...customerRequestDetailed
    }
  }
}
    ${CustomerRequestDetailedFragmentDoc}`;
export type CreateCustomerRequestMutationFn = Apollo.MutationFunction<Operation.CreateCustomerRequestMutation, Operation.CreateCustomerRequestMutationVariables>;

/**
 * __useCreateCustomerRequestMutation__
 *
 * To run a mutation, you first call `useCreateCustomerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerRequestMutation, { data, loading, error }] = useCreateCustomerRequestMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateCustomerRequestMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateCustomerRequestMutation, Operation.CreateCustomerRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateCustomerRequestMutation, Operation.CreateCustomerRequestMutationVariables>(CreateCustomerRequestDocument, options);
      }
export type CreateCustomerRequestMutationHookResult = ReturnType<typeof useCreateCustomerRequestMutation>;
export type CreateCustomerRequestMutationResult = Apollo.MutationResult<Operation.CreateCustomerRequestMutation>;
export type CreateCustomerRequestMutationOptions = Apollo.BaseMutationOptions<Operation.CreateCustomerRequestMutation, Operation.CreateCustomerRequestMutationVariables>;
export const UpdateCustomerRequestDocument = gql`
    mutation UpdateCustomerRequest($id: ID!, $attributes: CustomerRequestUpdateInput!) {
  updateCustomerRequest(input: {id: $id, attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    success
    customerRequest {
      ...customerRequestDetailed
    }
  }
}
    ${CustomerRequestDetailedFragmentDoc}`;
export type UpdateCustomerRequestMutationFn = Apollo.MutationFunction<Operation.UpdateCustomerRequestMutation, Operation.UpdateCustomerRequestMutationVariables>;

/**
 * __useUpdateCustomerRequestMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerRequestMutation, { data, loading, error }] = useUpdateCustomerRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateCustomerRequestMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateCustomerRequestMutation, Operation.UpdateCustomerRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateCustomerRequestMutation, Operation.UpdateCustomerRequestMutationVariables>(UpdateCustomerRequestDocument, options);
      }
export type UpdateCustomerRequestMutationHookResult = ReturnType<typeof useUpdateCustomerRequestMutation>;
export type UpdateCustomerRequestMutationResult = Apollo.MutationResult<Operation.UpdateCustomerRequestMutation>;
export type UpdateCustomerRequestMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateCustomerRequestMutation, Operation.UpdateCustomerRequestMutationVariables>;
export const ResendCustomerRequestDocument = gql`
    mutation ResendCustomerRequest($id: ID!) {
  resendCustomerRequest(input: {id: $id}) {
    fieldErrors {
      field
      message
    }
    errors
    success
    customerRequest {
      ...customerRequestDetailed
    }
  }
}
    ${CustomerRequestDetailedFragmentDoc}`;
export type ResendCustomerRequestMutationFn = Apollo.MutationFunction<Operation.ResendCustomerRequestMutation, Operation.ResendCustomerRequestMutationVariables>;

/**
 * __useResendCustomerRequestMutation__
 *
 * To run a mutation, you first call `useResendCustomerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendCustomerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendCustomerRequestMutation, { data, loading, error }] = useResendCustomerRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendCustomerRequestMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ResendCustomerRequestMutation, Operation.ResendCustomerRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ResendCustomerRequestMutation, Operation.ResendCustomerRequestMutationVariables>(ResendCustomerRequestDocument, options);
      }
export type ResendCustomerRequestMutationHookResult = ReturnType<typeof useResendCustomerRequestMutation>;
export type ResendCustomerRequestMutationResult = Apollo.MutationResult<Operation.ResendCustomerRequestMutation>;
export type ResendCustomerRequestMutationOptions = Apollo.BaseMutationOptions<Operation.ResendCustomerRequestMutation, Operation.ResendCustomerRequestMutationVariables>;
export const ArchiveCustomerRequestDocument = gql`
    mutation ArchiveCustomerRequest($id: ID!) {
  archiveCustomerRequest(input: {id: $id}) {
    fieldErrors {
      field
      message
    }
    errors
    success
    customerRequest {
      ...customerRequestDetailed
    }
  }
}
    ${CustomerRequestDetailedFragmentDoc}`;
export type ArchiveCustomerRequestMutationFn = Apollo.MutationFunction<Operation.ArchiveCustomerRequestMutation, Operation.ArchiveCustomerRequestMutationVariables>;

/**
 * __useArchiveCustomerRequestMutation__
 *
 * To run a mutation, you first call `useArchiveCustomerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCustomerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCustomerRequestMutation, { data, loading, error }] = useArchiveCustomerRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveCustomerRequestMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ArchiveCustomerRequestMutation, Operation.ArchiveCustomerRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ArchiveCustomerRequestMutation, Operation.ArchiveCustomerRequestMutationVariables>(ArchiveCustomerRequestDocument, options);
      }
export type ArchiveCustomerRequestMutationHookResult = ReturnType<typeof useArchiveCustomerRequestMutation>;
export type ArchiveCustomerRequestMutationResult = Apollo.MutationResult<Operation.ArchiveCustomerRequestMutation>;
export type ArchiveCustomerRequestMutationOptions = Apollo.BaseMutationOptions<Operation.ArchiveCustomerRequestMutation, Operation.ArchiveCustomerRequestMutationVariables>;
export const CreateToothTreatmentDocument = gql`
    mutation CreateToothTreatment($input: CreateToothTreatmentInput!) {
  createDentalToothTreatment(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    dentalToothTreatment {
      ...dentalTreatmentDetailed
    }
  }
}
    ${DentalTreatmentDetailedFragmentDoc}`;
export type CreateToothTreatmentMutationFn = Apollo.MutationFunction<Operation.CreateToothTreatmentMutation, Operation.CreateToothTreatmentMutationVariables>;

/**
 * __useCreateToothTreatmentMutation__
 *
 * To run a mutation, you first call `useCreateToothTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateToothTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createToothTreatmentMutation, { data, loading, error }] = useCreateToothTreatmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateToothTreatmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateToothTreatmentMutation, Operation.CreateToothTreatmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateToothTreatmentMutation, Operation.CreateToothTreatmentMutationVariables>(CreateToothTreatmentDocument, options);
      }
export type CreateToothTreatmentMutationHookResult = ReturnType<typeof useCreateToothTreatmentMutation>;
export type CreateToothTreatmentMutationResult = Apollo.MutationResult<Operation.CreateToothTreatmentMutation>;
export type CreateToothTreatmentMutationOptions = Apollo.BaseMutationOptions<Operation.CreateToothTreatmentMutation, Operation.CreateToothTreatmentMutationVariables>;
export const CreateDirectoryDocument = gql`
    mutation CreateDirectory($attributes: DirectoryInput!) {
  createDirectory(input: {attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    directory {
      ...subdirectory
      parent {
        ...directoryInfo
      }
    }
  }
}
    ${SubdirectoryFragmentDoc}
${DirectoryInfoFragmentDoc}`;
export type CreateDirectoryMutationFn = Apollo.MutationFunction<Operation.CreateDirectoryMutation, Operation.CreateDirectoryMutationVariables>;

/**
 * __useCreateDirectoryMutation__
 *
 * To run a mutation, you first call `useCreateDirectoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectoryMutation, { data, loading, error }] = useCreateDirectoryMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateDirectoryMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateDirectoryMutation, Operation.CreateDirectoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateDirectoryMutation, Operation.CreateDirectoryMutationVariables>(CreateDirectoryDocument, options);
      }
export type CreateDirectoryMutationHookResult = ReturnType<typeof useCreateDirectoryMutation>;
export type CreateDirectoryMutationResult = Apollo.MutationResult<Operation.CreateDirectoryMutation>;
export type CreateDirectoryMutationOptions = Apollo.BaseMutationOptions<Operation.CreateDirectoryMutation, Operation.CreateDirectoryMutationVariables>;
export const DeleteDirectoryDocument = gql`
    mutation DeleteDirectory($directory: ID!) {
  deleteDirectory(input: {directory: $directory}) {
    fieldErrors {
      field
      message
    }
    errors
    directory {
      ...directoryInfo
      parent {
        ...directory
      }
    }
  }
}
    ${DirectoryInfoFragmentDoc}
${DirectoryFragmentDoc}`;
export type DeleteDirectoryMutationFn = Apollo.MutationFunction<Operation.DeleteDirectoryMutation, Operation.DeleteDirectoryMutationVariables>;

/**
 * __useDeleteDirectoryMutation__
 *
 * To run a mutation, you first call `useDeleteDirectoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDirectoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDirectoryMutation, { data, loading, error }] = useDeleteDirectoryMutation({
 *   variables: {
 *      directory: // value for 'directory'
 *   },
 * });
 */
export function useDeleteDirectoryMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeleteDirectoryMutation, Operation.DeleteDirectoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeleteDirectoryMutation, Operation.DeleteDirectoryMutationVariables>(DeleteDirectoryDocument, options);
      }
export type DeleteDirectoryMutationHookResult = ReturnType<typeof useDeleteDirectoryMutation>;
export type DeleteDirectoryMutationResult = Apollo.MutationResult<Operation.DeleteDirectoryMutation>;
export type DeleteDirectoryMutationOptions = Apollo.BaseMutationOptions<Operation.DeleteDirectoryMutation, Operation.DeleteDirectoryMutationVariables>;
export const CreateRichTextDocument = gql`
    mutation CreateRichText($attributes: RichTextInput!) {
  createRichText(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    richText {
      ...richTextEssentials
      directory {
        ...directory
      }
    }
  }
}
    ${RichTextEssentialsFragmentDoc}
${DirectoryFragmentDoc}`;
export type CreateRichTextMutationFn = Apollo.MutationFunction<Operation.CreateRichTextMutation, Operation.CreateRichTextMutationVariables>;

/**
 * __useCreateRichTextMutation__
 *
 * To run a mutation, you first call `useCreateRichTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRichTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRichTextMutation, { data, loading, error }] = useCreateRichTextMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateRichTextMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateRichTextMutation, Operation.CreateRichTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateRichTextMutation, Operation.CreateRichTextMutationVariables>(CreateRichTextDocument, options);
      }
export type CreateRichTextMutationHookResult = ReturnType<typeof useCreateRichTextMutation>;
export type CreateRichTextMutationResult = Apollo.MutationResult<Operation.CreateRichTextMutation>;
export type CreateRichTextMutationOptions = Apollo.BaseMutationOptions<Operation.CreateRichTextMutation, Operation.CreateRichTextMutationVariables>;
export const UpdateRichTextDocument = gql`
    mutation UpdateRichText($id: ID!, $attributes: RichTextInput!) {
  updateRichText(input: {richTextId: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    richText {
      ...richTextEssentials
    }
  }
}
    ${RichTextEssentialsFragmentDoc}`;
export type UpdateRichTextMutationFn = Apollo.MutationFunction<Operation.UpdateRichTextMutation, Operation.UpdateRichTextMutationVariables>;

/**
 * __useUpdateRichTextMutation__
 *
 * To run a mutation, you first call `useUpdateRichTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRichTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRichTextMutation, { data, loading, error }] = useUpdateRichTextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateRichTextMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateRichTextMutation, Operation.UpdateRichTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateRichTextMutation, Operation.UpdateRichTextMutationVariables>(UpdateRichTextDocument, options);
      }
export type UpdateRichTextMutationHookResult = ReturnType<typeof useUpdateRichTextMutation>;
export type UpdateRichTextMutationResult = Apollo.MutationResult<Operation.UpdateRichTextMutation>;
export type UpdateRichTextMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateRichTextMutation, Operation.UpdateRichTextMutationVariables>;
export const DeleteRichTextDocument = gql`
    mutation DeleteRichText($id: ID!) {
  deleteRichText(input: {richTextId: $id}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    richText {
      ...richTextEssentials
    }
  }
}
    ${RichTextEssentialsFragmentDoc}`;
export type DeleteRichTextMutationFn = Apollo.MutationFunction<Operation.DeleteRichTextMutation, Operation.DeleteRichTextMutationVariables>;

/**
 * __useDeleteRichTextMutation__
 *
 * To run a mutation, you first call `useDeleteRichTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRichTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRichTextMutation, { data, loading, error }] = useDeleteRichTextMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRichTextMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeleteRichTextMutation, Operation.DeleteRichTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeleteRichTextMutation, Operation.DeleteRichTextMutationVariables>(DeleteRichTextDocument, options);
      }
export type DeleteRichTextMutationHookResult = ReturnType<typeof useDeleteRichTextMutation>;
export type DeleteRichTextMutationResult = Apollo.MutationResult<Operation.DeleteRichTextMutation>;
export type DeleteRichTextMutationOptions = Apollo.BaseMutationOptions<Operation.DeleteRichTextMutation, Operation.DeleteRichTextMutationVariables>;
export const MailMergeDocument = gql`
    mutation MailMerge($docx: Blob!, $uuid: ID!) {
  mergeRichText(input: {docx: $docx, uuid: $uuid}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    url
  }
}
    `;
export type MailMergeMutationFn = Apollo.MutationFunction<Operation.MailMergeMutation, Operation.MailMergeMutationVariables>;

/**
 * __useMailMergeMutation__
 *
 * To run a mutation, you first call `useMailMergeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMailMergeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mailMergeMutation, { data, loading, error }] = useMailMergeMutation({
 *   variables: {
 *      docx: // value for 'docx'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMailMergeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.MailMergeMutation, Operation.MailMergeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.MailMergeMutation, Operation.MailMergeMutationVariables>(MailMergeDocument, options);
      }
export type MailMergeMutationHookResult = ReturnType<typeof useMailMergeMutation>;
export type MailMergeMutationResult = Apollo.MutationResult<Operation.MailMergeMutation>;
export type MailMergeMutationOptions = Apollo.BaseMutationOptions<Operation.MailMergeMutation, Operation.MailMergeMutationVariables>;
export const UpdateDirectoryDocument = gql`
    mutation UpdateDirectory($directory: ID!, $attributes: DirectoryInput!) {
  updateDirectory(input: {directory: $directory, attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    directory {
      ...directory
    }
    previousParent {
      ...directory
    }
  }
}
    ${DirectoryFragmentDoc}`;
export type UpdateDirectoryMutationFn = Apollo.MutationFunction<Operation.UpdateDirectoryMutation, Operation.UpdateDirectoryMutationVariables>;

/**
 * __useUpdateDirectoryMutation__
 *
 * To run a mutation, you first call `useUpdateDirectoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirectoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirectoryMutation, { data, loading, error }] = useUpdateDirectoryMutation({
 *   variables: {
 *      directory: // value for 'directory'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateDirectoryMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateDirectoryMutation, Operation.UpdateDirectoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateDirectoryMutation, Operation.UpdateDirectoryMutationVariables>(UpdateDirectoryDocument, options);
      }
export type UpdateDirectoryMutationHookResult = ReturnType<typeof useUpdateDirectoryMutation>;
export type UpdateDirectoryMutationResult = Apollo.MutationResult<Operation.UpdateDirectoryMutation>;
export type UpdateDirectoryMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateDirectoryMutation, Operation.UpdateDirectoryMutationVariables>;
export const UploadImagesDocument = gql`
    mutation UploadImages($attributes: UploadImageInput!) {
  uploadImages(input: $attributes) {
    errors
    fieldErrors {
      field
      message
    }
    images {
      ...imageMinimal
    }
  }
}
    ${ImageMinimalFragmentDoc}`;
export type UploadImagesMutationFn = Apollo.MutationFunction<Operation.UploadImagesMutation, Operation.UploadImagesMutationVariables>;

/**
 * __useUploadImagesMutation__
 *
 * To run a mutation, you first call `useUploadImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImagesMutation, { data, loading, error }] = useUploadImagesMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUploadImagesMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UploadImagesMutation, Operation.UploadImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UploadImagesMutation, Operation.UploadImagesMutationVariables>(UploadImagesDocument, options);
      }
export type UploadImagesMutationHookResult = ReturnType<typeof useUploadImagesMutation>;
export type UploadImagesMutationResult = Apollo.MutationResult<Operation.UploadImagesMutation>;
export type UploadImagesMutationOptions = Apollo.BaseMutationOptions<Operation.UploadImagesMutation, Operation.UploadImagesMutationVariables>;
export const AddFamilyMemberDocument = gql`
    mutation AddFamilyMember($input: AddFamilyMemberInput!) {
  addFamilyMember(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    family {
      ...familyExhaustive
    }
  }
}
    ${FamilyExhaustiveFragmentDoc}`;
export type AddFamilyMemberMutationFn = Apollo.MutationFunction<Operation.AddFamilyMemberMutation, Operation.AddFamilyMemberMutationVariables>;

/**
 * __useAddFamilyMemberMutation__
 *
 * To run a mutation, you first call `useAddFamilyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFamilyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFamilyMemberMutation, { data, loading, error }] = useAddFamilyMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFamilyMemberMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AddFamilyMemberMutation, Operation.AddFamilyMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AddFamilyMemberMutation, Operation.AddFamilyMemberMutationVariables>(AddFamilyMemberDocument, options);
      }
export type AddFamilyMemberMutationHookResult = ReturnType<typeof useAddFamilyMemberMutation>;
export type AddFamilyMemberMutationResult = Apollo.MutationResult<Operation.AddFamilyMemberMutation>;
export type AddFamilyMemberMutationOptions = Apollo.BaseMutationOptions<Operation.AddFamilyMemberMutation, Operation.AddFamilyMemberMutationVariables>;
export const CreateFamilyDocument = gql`
    mutation CreateFamily($input: CreateFamilyInput!) {
  createFamily(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    family {
      ...familyExhaustive
    }
  }
}
    ${FamilyExhaustiveFragmentDoc}`;
export type CreateFamilyMutationFn = Apollo.MutationFunction<Operation.CreateFamilyMutation, Operation.CreateFamilyMutationVariables>;

/**
 * __useCreateFamilyMutation__
 *
 * To run a mutation, you first call `useCreateFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFamilyMutation, { data, loading, error }] = useCreateFamilyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFamilyMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateFamilyMutation, Operation.CreateFamilyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateFamilyMutation, Operation.CreateFamilyMutationVariables>(CreateFamilyDocument, options);
      }
export type CreateFamilyMutationHookResult = ReturnType<typeof useCreateFamilyMutation>;
export type CreateFamilyMutationResult = Apollo.MutationResult<Operation.CreateFamilyMutation>;
export type CreateFamilyMutationOptions = Apollo.BaseMutationOptions<Operation.CreateFamilyMutation, Operation.CreateFamilyMutationVariables>;
export const RemoveFamilyMemberDocument = gql`
    mutation RemoveFamilyMember($input: RemoveFamilyMemberInput!) {
  removeFamilyMember(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    family {
      ...familyExhaustive
    }
  }
}
    ${FamilyExhaustiveFragmentDoc}`;
export type RemoveFamilyMemberMutationFn = Apollo.MutationFunction<Operation.RemoveFamilyMemberMutation, Operation.RemoveFamilyMemberMutationVariables>;

/**
 * __useRemoveFamilyMemberMutation__
 *
 * To run a mutation, you first call `useRemoveFamilyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFamilyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFamilyMemberMutation, { data, loading, error }] = useRemoveFamilyMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFamilyMemberMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RemoveFamilyMemberMutation, Operation.RemoveFamilyMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RemoveFamilyMemberMutation, Operation.RemoveFamilyMemberMutationVariables>(RemoveFamilyMemberDocument, options);
      }
export type RemoveFamilyMemberMutationHookResult = ReturnType<typeof useRemoveFamilyMemberMutation>;
export type RemoveFamilyMemberMutationResult = Apollo.MutationResult<Operation.RemoveFamilyMemberMutation>;
export type RemoveFamilyMemberMutationOptions = Apollo.BaseMutationOptions<Operation.RemoveFamilyMemberMutation, Operation.RemoveFamilyMemberMutationVariables>;
export const UpdateFamilyDocument = gql`
    mutation UpdateFamily($input: UpdateFamilyInput!) {
  updateFamily(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    family {
      ...familyExhaustive
    }
  }
}
    ${FamilyExhaustiveFragmentDoc}`;
export type UpdateFamilyMutationFn = Apollo.MutationFunction<Operation.UpdateFamilyMutation, Operation.UpdateFamilyMutationVariables>;

/**
 * __useUpdateFamilyMutation__
 *
 * To run a mutation, you first call `useUpdateFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFamilyMutation, { data, loading, error }] = useUpdateFamilyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFamilyMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateFamilyMutation, Operation.UpdateFamilyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateFamilyMutation, Operation.UpdateFamilyMutationVariables>(UpdateFamilyDocument, options);
      }
export type UpdateFamilyMutationHookResult = ReturnType<typeof useUpdateFamilyMutation>;
export type UpdateFamilyMutationResult = Apollo.MutationResult<Operation.UpdateFamilyMutation>;
export type UpdateFamilyMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateFamilyMutation, Operation.UpdateFamilyMutationVariables>;
export const HealthFundsQuoteDocument = gql`
    mutation HealthFundsQuote($requestable: ID!, $patient: ID!, $attributes: HealthFundsQuoteInput!) {
  healthFundsQuote(
    input: {requestable: $requestable, patient: $patient, attributes: $attributes}
  ) {
    errors
    fieldErrors {
      field
      message
    }
    success
    healthFundsRequest {
      ...healthFundsRequestExhaustive
    }
    plannerClaimItems {
      ...healthFundsPlannerClaimItem
    }
    invoiceClaimItems {
      ...healthFundsInvoiceClaimItem
    }
  }
}
    ${HealthFundsRequestExhaustiveFragmentDoc}
${HealthFundsPlannerClaimItemFragmentDoc}
${HealthFundsInvoiceClaimItemFragmentDoc}`;
export type HealthFundsQuoteMutationFn = Apollo.MutationFunction<Operation.HealthFundsQuoteMutation, Operation.HealthFundsQuoteMutationVariables>;

/**
 * __useHealthFundsQuoteMutation__
 *
 * To run a mutation, you first call `useHealthFundsQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthFundsQuoteMutation, { data, loading, error }] = useHealthFundsQuoteMutation({
 *   variables: {
 *      requestable: // value for 'requestable'
 *      patient: // value for 'patient'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useHealthFundsQuoteMutation(baseOptions?: Apollo.MutationHookOptions<Operation.HealthFundsQuoteMutation, Operation.HealthFundsQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.HealthFundsQuoteMutation, Operation.HealthFundsQuoteMutationVariables>(HealthFundsQuoteDocument, options);
      }
export type HealthFundsQuoteMutationHookResult = ReturnType<typeof useHealthFundsQuoteMutation>;
export type HealthFundsQuoteMutationResult = Apollo.MutationResult<Operation.HealthFundsQuoteMutation>;
export type HealthFundsQuoteMutationOptions = Apollo.BaseMutationOptions<Operation.HealthFundsQuoteMutation, Operation.HealthFundsQuoteMutationVariables>;
export const HealthFundsClaimDocument = gql`
    mutation HealthFundsClaim($requestable: ID!, $patient: ID!, $attributes: HealthFundsClaimInput!) {
  healthFundsClaim(
    input: {requestable: $requestable, patient: $patient, attributes: $attributes}
  ) {
    errors
    fieldErrors {
      field
      message
    }
    success
    healthFundsRequest {
      ...healthFundsRequestExhaustive
    }
    invoiceClaimItems {
      ...healthFundsInvoiceClaimItem
    }
  }
}
    ${HealthFundsRequestExhaustiveFragmentDoc}
${HealthFundsInvoiceClaimItemFragmentDoc}`;
export type HealthFundsClaimMutationFn = Apollo.MutationFunction<Operation.HealthFundsClaimMutation, Operation.HealthFundsClaimMutationVariables>;

/**
 * __useHealthFundsClaimMutation__
 *
 * To run a mutation, you first call `useHealthFundsClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthFundsClaimMutation, { data, loading, error }] = useHealthFundsClaimMutation({
 *   variables: {
 *      requestable: // value for 'requestable'
 *      patient: // value for 'patient'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useHealthFundsClaimMutation(baseOptions?: Apollo.MutationHookOptions<Operation.HealthFundsClaimMutation, Operation.HealthFundsClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.HealthFundsClaimMutation, Operation.HealthFundsClaimMutationVariables>(HealthFundsClaimDocument, options);
      }
export type HealthFundsClaimMutationHookResult = ReturnType<typeof useHealthFundsClaimMutation>;
export type HealthFundsClaimMutationResult = Apollo.MutationResult<Operation.HealthFundsClaimMutation>;
export type HealthFundsClaimMutationOptions = Apollo.BaseMutationOptions<Operation.HealthFundsClaimMutation, Operation.HealthFundsClaimMutationVariables>;
export const HealthFundsCancelClaimDocument = gql`
    mutation HealthFundsCancelClaim($request: ID!, $attributes: HealthFundsCancelClaimInput!) {
  healthFundsCancelClaim(input: {request: $request, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    originalHealthFundsRequest {
      ...healthFundsRequestExhaustive
    }
    healthFundsRequest {
      ...healthFundsRequestExhaustive
    }
    invoiceClaimItems {
      ...healthFundsInvoiceClaimItem
    }
  }
}
    ${HealthFundsRequestExhaustiveFragmentDoc}
${HealthFundsInvoiceClaimItemFragmentDoc}`;
export type HealthFundsCancelClaimMutationFn = Apollo.MutationFunction<Operation.HealthFundsCancelClaimMutation, Operation.HealthFundsCancelClaimMutationVariables>;

/**
 * __useHealthFundsCancelClaimMutation__
 *
 * To run a mutation, you first call `useHealthFundsCancelClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsCancelClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthFundsCancelClaimMutation, { data, loading, error }] = useHealthFundsCancelClaimMutation({
 *   variables: {
 *      request: // value for 'request'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useHealthFundsCancelClaimMutation(baseOptions?: Apollo.MutationHookOptions<Operation.HealthFundsCancelClaimMutation, Operation.HealthFundsCancelClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.HealthFundsCancelClaimMutation, Operation.HealthFundsCancelClaimMutationVariables>(HealthFundsCancelClaimDocument, options);
      }
export type HealthFundsCancelClaimMutationHookResult = ReturnType<typeof useHealthFundsCancelClaimMutation>;
export type HealthFundsCancelClaimMutationResult = Apollo.MutationResult<Operation.HealthFundsCancelClaimMutation>;
export type HealthFundsCancelClaimMutationOptions = Apollo.BaseMutationOptions<Operation.HealthFundsCancelClaimMutation, Operation.HealthFundsCancelClaimMutationVariables>;
export const HealthFundsPaymentDocument = gql`
    mutation HealthFundsPayment($requestable: ID!, $patient: ID!, $attributes: HealthFundsPaymentInput!) {
  healthFundsPayment(
    input: {requestable: $requestable, patient: $patient, attributes: $attributes}
  ) {
    errors
    fieldErrors {
      field
      message
    }
    success
    healthFundsRequest {
      ...healthFundsRequestExhaustive
    }
  }
}
    ${HealthFundsRequestExhaustiveFragmentDoc}`;
export type HealthFundsPaymentMutationFn = Apollo.MutationFunction<Operation.HealthFundsPaymentMutation, Operation.HealthFundsPaymentMutationVariables>;

/**
 * __useHealthFundsPaymentMutation__
 *
 * To run a mutation, you first call `useHealthFundsPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthFundsPaymentMutation, { data, loading, error }] = useHealthFundsPaymentMutation({
 *   variables: {
 *      requestable: // value for 'requestable'
 *      patient: // value for 'patient'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useHealthFundsPaymentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.HealthFundsPaymentMutation, Operation.HealthFundsPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.HealthFundsPaymentMutation, Operation.HealthFundsPaymentMutationVariables>(HealthFundsPaymentDocument, options);
      }
export type HealthFundsPaymentMutationHookResult = ReturnType<typeof useHealthFundsPaymentMutation>;
export type HealthFundsPaymentMutationResult = Apollo.MutationResult<Operation.HealthFundsPaymentMutation>;
export type HealthFundsPaymentMutationOptions = Apollo.BaseMutationOptions<Operation.HealthFundsPaymentMutation, Operation.HealthFundsPaymentMutationVariables>;
export const HealthFundsRefundDocument = gql`
    mutation HealthFundsRefund($requestable: ID!, $patient: ID!, $attributes: HealthFundsRefundInput!) {
  healthFundsRefund(
    input: {requestable: $requestable, patient: $patient, attributes: $attributes}
  ) {
    errors
    fieldErrors {
      field
      message
    }
    success
    healthFundsRequest {
      ...healthFundsRequestExhaustive
    }
  }
}
    ${HealthFundsRequestExhaustiveFragmentDoc}`;
export type HealthFundsRefundMutationFn = Apollo.MutationFunction<Operation.HealthFundsRefundMutation, Operation.HealthFundsRefundMutationVariables>;

/**
 * __useHealthFundsRefundMutation__
 *
 * To run a mutation, you first call `useHealthFundsRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthFundsRefundMutation, { data, loading, error }] = useHealthFundsRefundMutation({
 *   variables: {
 *      requestable: // value for 'requestable'
 *      patient: // value for 'patient'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useHealthFundsRefundMutation(baseOptions?: Apollo.MutationHookOptions<Operation.HealthFundsRefundMutation, Operation.HealthFundsRefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.HealthFundsRefundMutation, Operation.HealthFundsRefundMutationVariables>(HealthFundsRefundDocument, options);
      }
export type HealthFundsRefundMutationHookResult = ReturnType<typeof useHealthFundsRefundMutation>;
export type HealthFundsRefundMutationResult = Apollo.MutationResult<Operation.HealthFundsRefundMutation>;
export type HealthFundsRefundMutationOptions = Apollo.BaseMutationOptions<Operation.HealthFundsRefundMutation, Operation.HealthFundsRefundMutationVariables>;
export const UpdateHealthFundsPlannerClaimItemDocument = gql`
    mutation UpdateHealthFundsPlannerClaimItem($id: ID!, $input: PatientRecordItemInput!) {
  updatePatientRecordItem(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    patientRecordItem {
      ...healthFundsPlannerClaimItem
    }
  }
}
    ${HealthFundsPlannerClaimItemFragmentDoc}`;
export type UpdateHealthFundsPlannerClaimItemMutationFn = Apollo.MutationFunction<Operation.UpdateHealthFundsPlannerClaimItemMutation, Operation.UpdateHealthFundsPlannerClaimItemMutationVariables>;

/**
 * __useUpdateHealthFundsPlannerClaimItemMutation__
 *
 * To run a mutation, you first call `useUpdateHealthFundsPlannerClaimItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHealthFundsPlannerClaimItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHealthFundsPlannerClaimItemMutation, { data, loading, error }] = useUpdateHealthFundsPlannerClaimItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHealthFundsPlannerClaimItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateHealthFundsPlannerClaimItemMutation, Operation.UpdateHealthFundsPlannerClaimItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateHealthFundsPlannerClaimItemMutation, Operation.UpdateHealthFundsPlannerClaimItemMutationVariables>(UpdateHealthFundsPlannerClaimItemDocument, options);
      }
export type UpdateHealthFundsPlannerClaimItemMutationHookResult = ReturnType<typeof useUpdateHealthFundsPlannerClaimItemMutation>;
export type UpdateHealthFundsPlannerClaimItemMutationResult = Apollo.MutationResult<Operation.UpdateHealthFundsPlannerClaimItemMutation>;
export type UpdateHealthFundsPlannerClaimItemMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateHealthFundsPlannerClaimItemMutation, Operation.UpdateHealthFundsPlannerClaimItemMutationVariables>;
export const UpdateHealthFundsInvoiceClaimItemDocument = gql`
    mutation UpdateHealthFundsInvoiceClaimItem($id: ID!, $input: InvoiceItemUpdateInput!) {
  updateInvoiceItem(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    invoiceItem {
      ...healthFundsInvoiceClaimItem
    }
  }
}
    ${HealthFundsInvoiceClaimItemFragmentDoc}`;
export type UpdateHealthFundsInvoiceClaimItemMutationFn = Apollo.MutationFunction<Operation.UpdateHealthFundsInvoiceClaimItemMutation, Operation.UpdateHealthFundsInvoiceClaimItemMutationVariables>;

/**
 * __useUpdateHealthFundsInvoiceClaimItemMutation__
 *
 * To run a mutation, you first call `useUpdateHealthFundsInvoiceClaimItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHealthFundsInvoiceClaimItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHealthFundsInvoiceClaimItemMutation, { data, loading, error }] = useUpdateHealthFundsInvoiceClaimItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHealthFundsInvoiceClaimItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateHealthFundsInvoiceClaimItemMutation, Operation.UpdateHealthFundsInvoiceClaimItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateHealthFundsInvoiceClaimItemMutation, Operation.UpdateHealthFundsInvoiceClaimItemMutationVariables>(UpdateHealthFundsInvoiceClaimItemDocument, options);
      }
export type UpdateHealthFundsInvoiceClaimItemMutationHookResult = ReturnType<typeof useUpdateHealthFundsInvoiceClaimItemMutation>;
export type UpdateHealthFundsInvoiceClaimItemMutationResult = Apollo.MutationResult<Operation.UpdateHealthFundsInvoiceClaimItemMutation>;
export type UpdateHealthFundsInvoiceClaimItemMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateHealthFundsInvoiceClaimItemMutation, Operation.UpdateHealthFundsInvoiceClaimItemMutationVariables>;
export const GenerateAppointmentInvoicesDocument = gql`
    mutation GenerateAppointmentInvoices($id: ID!, $deliver: Boolean) {
  generateAppointmentInvoices(input: {id: $id, deliver: $deliver}) {
    errors
    success
    fieldErrors {
      message
      field
    }
    appointment {
      ...appointmentExhaustive
    }
  }
}
    ${AppointmentExhaustiveFragmentDoc}`;
export type GenerateAppointmentInvoicesMutationFn = Apollo.MutationFunction<Operation.GenerateAppointmentInvoicesMutation, Operation.GenerateAppointmentInvoicesMutationVariables>;

/**
 * __useGenerateAppointmentInvoicesMutation__
 *
 * To run a mutation, you first call `useGenerateAppointmentInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAppointmentInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAppointmentInvoicesMutation, { data, loading, error }] = useGenerateAppointmentInvoicesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deliver: // value for 'deliver'
 *   },
 * });
 */
export function useGenerateAppointmentInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<Operation.GenerateAppointmentInvoicesMutation, Operation.GenerateAppointmentInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.GenerateAppointmentInvoicesMutation, Operation.GenerateAppointmentInvoicesMutationVariables>(GenerateAppointmentInvoicesDocument, options);
      }
export type GenerateAppointmentInvoicesMutationHookResult = ReturnType<typeof useGenerateAppointmentInvoicesMutation>;
export type GenerateAppointmentInvoicesMutationResult = Apollo.MutationResult<Operation.GenerateAppointmentInvoicesMutation>;
export type GenerateAppointmentInvoicesMutationOptions = Apollo.BaseMutationOptions<Operation.GenerateAppointmentInvoicesMutation, Operation.GenerateAppointmentInvoicesMutationVariables>;
export const CreateConnectedStripeAccountDocument = gql`
    mutation CreateConnectedStripeAccount($attributes: CreateConnectedAccountAttributes!) {
  createConnectedAccount(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    paymentProvider {
      ...paymentProviderDetailed
    }
    success
  }
}
    ${PaymentProviderDetailedFragmentDoc}`;
export type CreateConnectedStripeAccountMutationFn = Apollo.MutationFunction<Operation.CreateConnectedStripeAccountMutation, Operation.CreateConnectedStripeAccountMutationVariables>;

/**
 * __useCreateConnectedStripeAccountMutation__
 *
 * To run a mutation, you first call `useCreateConnectedStripeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectedStripeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectedStripeAccountMutation, { data, loading, error }] = useCreateConnectedStripeAccountMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateConnectedStripeAccountMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateConnectedStripeAccountMutation, Operation.CreateConnectedStripeAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateConnectedStripeAccountMutation, Operation.CreateConnectedStripeAccountMutationVariables>(CreateConnectedStripeAccountDocument, options);
      }
export type CreateConnectedStripeAccountMutationHookResult = ReturnType<typeof useCreateConnectedStripeAccountMutation>;
export type CreateConnectedStripeAccountMutationResult = Apollo.MutationResult<Operation.CreateConnectedStripeAccountMutation>;
export type CreateConnectedStripeAccountMutationOptions = Apollo.BaseMutationOptions<Operation.CreateConnectedStripeAccountMutation, Operation.CreateConnectedStripeAccountMutationVariables>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($attributes: InvoiceCreateInput!) {
  createInvoice(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      message
      field
    }
    success
    invoice {
      ...invoiceDetailed
    }
  }
}
    ${InvoiceDetailedFragmentDoc}`;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<Operation.CreateInvoiceMutation, Operation.CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateInvoiceMutation, Operation.CreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateInvoiceMutation, Operation.CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<Operation.CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<Operation.CreateInvoiceMutation, Operation.CreateInvoiceMutationVariables>;
export const CreateInvoiceItemDocument = gql`
    mutation CreateInvoiceItem($input: CreateInvoiceItemInput!) {
  createInvoiceItem(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    invoiceItem {
      ...invoiceItemDetailed
    }
  }
}
    ${InvoiceItemDetailedFragmentDoc}`;
export type CreateInvoiceItemMutationFn = Apollo.MutationFunction<Operation.CreateInvoiceItemMutation, Operation.CreateInvoiceItemMutationVariables>;

/**
 * __useCreateInvoiceItemMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceItemMutation, { data, loading, error }] = useCreateInvoiceItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateInvoiceItemMutation, Operation.CreateInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateInvoiceItemMutation, Operation.CreateInvoiceItemMutationVariables>(CreateInvoiceItemDocument, options);
      }
export type CreateInvoiceItemMutationHookResult = ReturnType<typeof useCreateInvoiceItemMutation>;
export type CreateInvoiceItemMutationResult = Apollo.MutationResult<Operation.CreateInvoiceItemMutation>;
export type CreateInvoiceItemMutationOptions = Apollo.BaseMutationOptions<Operation.CreateInvoiceItemMutation, Operation.CreateInvoiceItemMutationVariables>;
export const CreateTyroTerminalDocument = gql`
    mutation CreateTyroTerminal($input: CreateTyroTerminalInput!) {
  createTyroTerminal(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    paymentTerminal {
      ...paymentTerminalEssentials
    }
  }
}
    ${PaymentTerminalEssentialsFragmentDoc}`;
export type CreateTyroTerminalMutationFn = Apollo.MutationFunction<Operation.CreateTyroTerminalMutation, Operation.CreateTyroTerminalMutationVariables>;

/**
 * __useCreateTyroTerminalMutation__
 *
 * To run a mutation, you first call `useCreateTyroTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTyroTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTyroTerminalMutation, { data, loading, error }] = useCreateTyroTerminalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTyroTerminalMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateTyroTerminalMutation, Operation.CreateTyroTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateTyroTerminalMutation, Operation.CreateTyroTerminalMutationVariables>(CreateTyroTerminalDocument, options);
      }
export type CreateTyroTerminalMutationHookResult = ReturnType<typeof useCreateTyroTerminalMutation>;
export type CreateTyroTerminalMutationResult = Apollo.MutationResult<Operation.CreateTyroTerminalMutation>;
export type CreateTyroTerminalMutationOptions = Apollo.BaseMutationOptions<Operation.CreateTyroTerminalMutation, Operation.CreateTyroTerminalMutationVariables>;
export const DeleteInvoiceDocument = gql`
    mutation DeleteInvoice($id: ID!) {
  deleteInvoice(input: {id: $id}) {
    errors
    fieldErrors {
      message
      field
    }
    success
    invoice {
      ...invoiceDetailed
    }
  }
}
    ${InvoiceDetailedFragmentDoc}`;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<Operation.DeleteInvoiceMutation, Operation.DeleteInvoiceMutationVariables>;

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeleteInvoiceMutation, Operation.DeleteInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeleteInvoiceMutation, Operation.DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, options);
      }
export type DeleteInvoiceMutationHookResult = ReturnType<typeof useDeleteInvoiceMutation>;
export type DeleteInvoiceMutationResult = Apollo.MutationResult<Operation.DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<Operation.DeleteInvoiceMutation, Operation.DeleteInvoiceMutationVariables>;
export const DeleteInvoiceItemDocument = gql`
    mutation DeleteInvoiceItem($id: ID!) {
  deleteInvoiceItem(input: {id: $id}) {
    errors
    fieldErrors {
      message
      field
    }
    success
    invoiceItem {
      ...invoiceItemEssentials
    }
  }
}
    ${InvoiceItemEssentialsFragmentDoc}`;
export type DeleteInvoiceItemMutationFn = Apollo.MutationFunction<Operation.DeleteInvoiceItemMutation, Operation.DeleteInvoiceItemMutationVariables>;

/**
 * __useDeleteInvoiceItemMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceItemMutation, { data, loading, error }] = useDeleteInvoiceItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeleteInvoiceItemMutation, Operation.DeleteInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeleteInvoiceItemMutation, Operation.DeleteInvoiceItemMutationVariables>(DeleteInvoiceItemDocument, options);
      }
export type DeleteInvoiceItemMutationHookResult = ReturnType<typeof useDeleteInvoiceItemMutation>;
export type DeleteInvoiceItemMutationResult = Apollo.MutationResult<Operation.DeleteInvoiceItemMutation>;
export type DeleteInvoiceItemMutationOptions = Apollo.BaseMutationOptions<Operation.DeleteInvoiceItemMutation, Operation.DeleteInvoiceItemMutationVariables>;
export const DeliverInvoiceDocument = gql`
    mutation DeliverInvoice($invoiceId: ID!, $attributes: DeliverInput!) {
  deliverInvoice(input: {invoiceId: $invoiceId, attributes: $attributes}) {
    errors
    fieldErrors {
      message
      field
    }
    success
    invoice {
      ...invoiceDetailed
    }
  }
}
    ${InvoiceDetailedFragmentDoc}`;
export type DeliverInvoiceMutationFn = Apollo.MutationFunction<Operation.DeliverInvoiceMutation, Operation.DeliverInvoiceMutationVariables>;

/**
 * __useDeliverInvoiceMutation__
 *
 * To run a mutation, you first call `useDeliverInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliverInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliverInvoiceMutation, { data, loading, error }] = useDeliverInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useDeliverInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeliverInvoiceMutation, Operation.DeliverInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeliverInvoiceMutation, Operation.DeliverInvoiceMutationVariables>(DeliverInvoiceDocument, options);
      }
export type DeliverInvoiceMutationHookResult = ReturnType<typeof useDeliverInvoiceMutation>;
export type DeliverInvoiceMutationResult = Apollo.MutationResult<Operation.DeliverInvoiceMutation>;
export type DeliverInvoiceMutationOptions = Apollo.BaseMutationOptions<Operation.DeliverInvoiceMutation, Operation.DeliverInvoiceMutationVariables>;
export const DuplicateInvoiceItemDocument = gql`
    mutation DuplicateInvoiceItem($id: ID!, $amount: Int!) {
  duplicateInvoiceItem(input: {id: $id, amount: $amount}) {
    errors
    fieldErrors {
      message
      field
    }
    success
    invoiceItems {
      ...invoiceItemDetailed
    }
  }
}
    ${InvoiceItemDetailedFragmentDoc}`;
export type DuplicateInvoiceItemMutationFn = Apollo.MutationFunction<Operation.DuplicateInvoiceItemMutation, Operation.DuplicateInvoiceItemMutationVariables>;

/**
 * __useDuplicateInvoiceItemMutation__
 *
 * To run a mutation, you first call `useDuplicateInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateInvoiceItemMutation, { data, loading, error }] = useDuplicateInvoiceItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useDuplicateInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DuplicateInvoiceItemMutation, Operation.DuplicateInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DuplicateInvoiceItemMutation, Operation.DuplicateInvoiceItemMutationVariables>(DuplicateInvoiceItemDocument, options);
      }
export type DuplicateInvoiceItemMutationHookResult = ReturnType<typeof useDuplicateInvoiceItemMutation>;
export type DuplicateInvoiceItemMutationResult = Apollo.MutationResult<Operation.DuplicateInvoiceItemMutation>;
export type DuplicateInvoiceItemMutationOptions = Apollo.BaseMutationOptions<Operation.DuplicateInvoiceItemMutation, Operation.DuplicateInvoiceItemMutationVariables>;
export const CreatePatientRecordItemDocument = gql`
    mutation CreatePatientRecordItem($input: CreatePatientRecordItemInput!) {
  createPatientRecordItem(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    patientRecordItem {
      ...patientRecordItemDetailed
    }
  }
}
    ${PatientRecordItemDetailedFragmentDoc}`;
export type CreatePatientRecordItemMutationFn = Apollo.MutationFunction<Operation.CreatePatientRecordItemMutation, Operation.CreatePatientRecordItemMutationVariables>;

/**
 * __useCreatePatientRecordItemMutation__
 *
 * To run a mutation, you first call `useCreatePatientRecordItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientRecordItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientRecordItemMutation, { data, loading, error }] = useCreatePatientRecordItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientRecordItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreatePatientRecordItemMutation, Operation.CreatePatientRecordItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreatePatientRecordItemMutation, Operation.CreatePatientRecordItemMutationVariables>(CreatePatientRecordItemDocument, options);
      }
export type CreatePatientRecordItemMutationHookResult = ReturnType<typeof useCreatePatientRecordItemMutation>;
export type CreatePatientRecordItemMutationResult = Apollo.MutationResult<Operation.CreatePatientRecordItemMutation>;
export type CreatePatientRecordItemMutationOptions = Apollo.BaseMutationOptions<Operation.CreatePatientRecordItemMutation, Operation.CreatePatientRecordItemMutationVariables>;
export const BulkUpdatePatientRecordItemsDocument = gql`
    mutation BulkUpdatePatientRecordItems($input: BulkUpdatePatientRecordItemsInput!) {
  bulkUpdatePatientRecordItems(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    patientRecordItems {
      ...patientRecordItemDetailed
    }
  }
}
    ${PatientRecordItemDetailedFragmentDoc}`;
export type BulkUpdatePatientRecordItemsMutationFn = Apollo.MutationFunction<Operation.BulkUpdatePatientRecordItemsMutation, Operation.BulkUpdatePatientRecordItemsMutationVariables>;

/**
 * __useBulkUpdatePatientRecordItemsMutation__
 *
 * To run a mutation, you first call `useBulkUpdatePatientRecordItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdatePatientRecordItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdatePatientRecordItemsMutation, { data, loading, error }] = useBulkUpdatePatientRecordItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdatePatientRecordItemsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.BulkUpdatePatientRecordItemsMutation, Operation.BulkUpdatePatientRecordItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.BulkUpdatePatientRecordItemsMutation, Operation.BulkUpdatePatientRecordItemsMutationVariables>(BulkUpdatePatientRecordItemsDocument, options);
      }
export type BulkUpdatePatientRecordItemsMutationHookResult = ReturnType<typeof useBulkUpdatePatientRecordItemsMutation>;
export type BulkUpdatePatientRecordItemsMutationResult = Apollo.MutationResult<Operation.BulkUpdatePatientRecordItemsMutation>;
export type BulkUpdatePatientRecordItemsMutationOptions = Apollo.BaseMutationOptions<Operation.BulkUpdatePatientRecordItemsMutation, Operation.BulkUpdatePatientRecordItemsMutationVariables>;
export const BulkDeletePatientRecordItemsDocument = gql`
    mutation BulkDeletePatientRecordItems($input: BulkDeletePatientRecordItemsInput!) {
  bulkDeletePatientRecordItems(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    patientRecordItems {
      ...patientRecordItemDetailed
    }
  }
}
    ${PatientRecordItemDetailedFragmentDoc}`;
export type BulkDeletePatientRecordItemsMutationFn = Apollo.MutationFunction<Operation.BulkDeletePatientRecordItemsMutation, Operation.BulkDeletePatientRecordItemsMutationVariables>;

/**
 * __useBulkDeletePatientRecordItemsMutation__
 *
 * To run a mutation, you first call `useBulkDeletePatientRecordItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeletePatientRecordItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeletePatientRecordItemsMutation, { data, loading, error }] = useBulkDeletePatientRecordItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkDeletePatientRecordItemsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.BulkDeletePatientRecordItemsMutation, Operation.BulkDeletePatientRecordItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.BulkDeletePatientRecordItemsMutation, Operation.BulkDeletePatientRecordItemsMutationVariables>(BulkDeletePatientRecordItemsDocument, options);
      }
export type BulkDeletePatientRecordItemsMutationHookResult = ReturnType<typeof useBulkDeletePatientRecordItemsMutation>;
export type BulkDeletePatientRecordItemsMutationResult = Apollo.MutationResult<Operation.BulkDeletePatientRecordItemsMutation>;
export type BulkDeletePatientRecordItemsMutationOptions = Apollo.BaseMutationOptions<Operation.BulkDeletePatientRecordItemsMutation, Operation.BulkDeletePatientRecordItemsMutationVariables>;
export const UpdatePatientRecordItemDocument = gql`
    mutation UpdatePatientRecordItem($input: UpdatePatientRecordItemInput!) {
  updatePatientRecordItem(input: $input) {
    errors
    fieldErrors {
      field
      message
      details
    }
    success
    patientRecordItem {
      ...patientRecordItemDetailed
    }
  }
}
    ${PatientRecordItemDetailedFragmentDoc}`;
export type UpdatePatientRecordItemMutationFn = Apollo.MutationFunction<Operation.UpdatePatientRecordItemMutation, Operation.UpdatePatientRecordItemMutationVariables>;

/**
 * __useUpdatePatientRecordItemMutation__
 *
 * To run a mutation, you first call `useUpdatePatientRecordItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientRecordItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientRecordItemMutation, { data, loading, error }] = useUpdatePatientRecordItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientRecordItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdatePatientRecordItemMutation, Operation.UpdatePatientRecordItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdatePatientRecordItemMutation, Operation.UpdatePatientRecordItemMutationVariables>(UpdatePatientRecordItemDocument, options);
      }
export type UpdatePatientRecordItemMutationHookResult = ReturnType<typeof useUpdatePatientRecordItemMutation>;
export type UpdatePatientRecordItemMutationResult = Apollo.MutationResult<Operation.UpdatePatientRecordItemMutation>;
export type UpdatePatientRecordItemMutationOptions = Apollo.BaseMutationOptions<Operation.UpdatePatientRecordItemMutation, Operation.UpdatePatientRecordItemMutationVariables>;
export const DeletePatientRecordItemDocument = gql`
    mutation DeletePatientRecordItem($input: DestroyPatientRecordItemInput!) {
  destroyPatientRecordItem(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    patientRecordItem {
      ...patientRecordItemDetailed
    }
  }
}
    ${PatientRecordItemDetailedFragmentDoc}`;
export type DeletePatientRecordItemMutationFn = Apollo.MutationFunction<Operation.DeletePatientRecordItemMutation, Operation.DeletePatientRecordItemMutationVariables>;

/**
 * __useDeletePatientRecordItemMutation__
 *
 * To run a mutation, you first call `useDeletePatientRecordItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientRecordItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientRecordItemMutation, { data, loading, error }] = useDeletePatientRecordItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePatientRecordItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeletePatientRecordItemMutation, Operation.DeletePatientRecordItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeletePatientRecordItemMutation, Operation.DeletePatientRecordItemMutationVariables>(DeletePatientRecordItemDocument, options);
      }
export type DeletePatientRecordItemMutationHookResult = ReturnType<typeof useDeletePatientRecordItemMutation>;
export type DeletePatientRecordItemMutationResult = Apollo.MutationResult<Operation.DeletePatientRecordItemMutation>;
export type DeletePatientRecordItemMutationOptions = Apollo.BaseMutationOptions<Operation.DeletePatientRecordItemMutation, Operation.DeletePatientRecordItemMutationVariables>;
export const DuplicatePatientRecordItemDocument = gql`
    mutation DuplicatePatientRecordItem($id: ID!, $amount: Int!) {
  duplicatePatientRecordItem(input: {id: $id, amount: $amount}) {
    errors
    fieldErrors {
      message
      field
    }
    success
    patientRecordItems {
      ...patientRecordItemDetailed
    }
  }
}
    ${PatientRecordItemDetailedFragmentDoc}`;
export type DuplicatePatientRecordItemMutationFn = Apollo.MutationFunction<Operation.DuplicatePatientRecordItemMutation, Operation.DuplicatePatientRecordItemMutationVariables>;

/**
 * __useDuplicatePatientRecordItemMutation__
 *
 * To run a mutation, you first call `useDuplicatePatientRecordItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePatientRecordItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePatientRecordItemMutation, { data, loading, error }] = useDuplicatePatientRecordItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useDuplicatePatientRecordItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DuplicatePatientRecordItemMutation, Operation.DuplicatePatientRecordItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DuplicatePatientRecordItemMutation, Operation.DuplicatePatientRecordItemMutationVariables>(DuplicatePatientRecordItemDocument, options);
      }
export type DuplicatePatientRecordItemMutationHookResult = ReturnType<typeof useDuplicatePatientRecordItemMutation>;
export type DuplicatePatientRecordItemMutationResult = Apollo.MutationResult<Operation.DuplicatePatientRecordItemMutation>;
export type DuplicatePatientRecordItemMutationOptions = Apollo.BaseMutationOptions<Operation.DuplicatePatientRecordItemMutation, Operation.DuplicatePatientRecordItemMutationVariables>;
export const TransitionPatientRecordItemDocument = gql`
    mutation TransitionPatientRecordItem($patientRecordItemId: ID!, $event: PatientRecordItemEvent!) {
  transitionPatientRecordItem(
    input: {patientRecordItemId: $patientRecordItemId, event: $event}
  ) {
    errors
    fieldErrors {
      field
      message
    }
    patientRecordItem {
      ...patientRecordItemDetailed
    }
  }
}
    ${PatientRecordItemDetailedFragmentDoc}`;
export type TransitionPatientRecordItemMutationFn = Apollo.MutationFunction<Operation.TransitionPatientRecordItemMutation, Operation.TransitionPatientRecordItemMutationVariables>;

/**
 * __useTransitionPatientRecordItemMutation__
 *
 * To run a mutation, you first call `useTransitionPatientRecordItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionPatientRecordItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionPatientRecordItemMutation, { data, loading, error }] = useTransitionPatientRecordItemMutation({
 *   variables: {
 *      patientRecordItemId: // value for 'patientRecordItemId'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionPatientRecordItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionPatientRecordItemMutation, Operation.TransitionPatientRecordItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionPatientRecordItemMutation, Operation.TransitionPatientRecordItemMutationVariables>(TransitionPatientRecordItemDocument, options);
      }
export type TransitionPatientRecordItemMutationHookResult = ReturnType<typeof useTransitionPatientRecordItemMutation>;
export type TransitionPatientRecordItemMutationResult = Apollo.MutationResult<Operation.TransitionPatientRecordItemMutation>;
export type TransitionPatientRecordItemMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionPatientRecordItemMutation, Operation.TransitionPatientRecordItemMutationVariables>;
export const ReorderPatientRecordItemDocument = gql`
    mutation ReorderPatientRecordItem($patientRecordItemId: ID!, $position: Int!) {
  reorderPatientRecordItem(
    input: {patientRecordItemId: $patientRecordItemId, position: $position}
  ) {
    errors
    fieldErrors {
      field
      message
    }
    patientRecordItems {
      id
      guid
      position
    }
  }
}
    `;
export type ReorderPatientRecordItemMutationFn = Apollo.MutationFunction<Operation.ReorderPatientRecordItemMutation, Operation.ReorderPatientRecordItemMutationVariables>;

/**
 * __useReorderPatientRecordItemMutation__
 *
 * To run a mutation, you first call `useReorderPatientRecordItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderPatientRecordItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderPatientRecordItemMutation, { data, loading, error }] = useReorderPatientRecordItemMutation({
 *   variables: {
 *      patientRecordItemId: // value for 'patientRecordItemId'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useReorderPatientRecordItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ReorderPatientRecordItemMutation, Operation.ReorderPatientRecordItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ReorderPatientRecordItemMutation, Operation.ReorderPatientRecordItemMutationVariables>(ReorderPatientRecordItemDocument, options);
      }
export type ReorderPatientRecordItemMutationHookResult = ReturnType<typeof useReorderPatientRecordItemMutation>;
export type ReorderPatientRecordItemMutationResult = Apollo.MutationResult<Operation.ReorderPatientRecordItemMutation>;
export type ReorderPatientRecordItemMutationOptions = Apollo.BaseMutationOptions<Operation.ReorderPatientRecordItemMutation, Operation.ReorderPatientRecordItemMutationVariables>;
export const PayInvoiceDocument = gql`
    mutation PayInvoice($input: PayInvoiceInput!) {
  payInvoice(input: $input) {
    errors
    fieldErrors {
      message
      field
    }
    success
    invoice {
      ...invoiceDetailed
      appointments {
        ...appointmentExhaustive
      }
    }
    transactions {
      ...invoiceTransactionEssentials
    }
  }
}
    ${InvoiceDetailedFragmentDoc}
${AppointmentExhaustiveFragmentDoc}
${InvoiceTransactionEssentialsFragmentDoc}`;
export type PayInvoiceMutationFn = Apollo.MutationFunction<Operation.PayInvoiceMutation, Operation.PayInvoiceMutationVariables>;

/**
 * __usePayInvoiceMutation__
 *
 * To run a mutation, you first call `usePayInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payInvoiceMutation, { data, loading, error }] = usePayInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.PayInvoiceMutation, Operation.PayInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.PayInvoiceMutation, Operation.PayInvoiceMutationVariables>(PayInvoiceDocument, options);
      }
export type PayInvoiceMutationHookResult = ReturnType<typeof usePayInvoiceMutation>;
export type PayInvoiceMutationResult = Apollo.MutationResult<Operation.PayInvoiceMutation>;
export type PayInvoiceMutationOptions = Apollo.BaseMutationOptions<Operation.PayInvoiceMutation, Operation.PayInvoiceMutationVariables>;
export const RequestStripePaymentIntentDocument = gql`
    mutation RequestStripePaymentIntent($attributes: PaymentIntentInput!) {
  requestStripePaymentIntent(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      message
      field
    }
    success
    paymentIntent {
      ...paymentIntent
    }
  }
}
    ${PaymentIntentFragmentDoc}`;
export type RequestStripePaymentIntentMutationFn = Apollo.MutationFunction<Operation.RequestStripePaymentIntentMutation, Operation.RequestStripePaymentIntentMutationVariables>;

/**
 * __useRequestStripePaymentIntentMutation__
 *
 * To run a mutation, you first call `useRequestStripePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestStripePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestStripePaymentIntentMutation, { data, loading, error }] = useRequestStripePaymentIntentMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useRequestStripePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RequestStripePaymentIntentMutation, Operation.RequestStripePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RequestStripePaymentIntentMutation, Operation.RequestStripePaymentIntentMutationVariables>(RequestStripePaymentIntentDocument, options);
      }
export type RequestStripePaymentIntentMutationHookResult = ReturnType<typeof useRequestStripePaymentIntentMutation>;
export type RequestStripePaymentIntentMutationResult = Apollo.MutationResult<Operation.RequestStripePaymentIntentMutation>;
export type RequestStripePaymentIntentMutationOptions = Apollo.BaseMutationOptions<Operation.RequestStripePaymentIntentMutation, Operation.RequestStripePaymentIntentMutationVariables>;
export const CreateStripeSetupIntentDocument = gql`
    mutation CreateStripeSetupIntent {
  createStripeSetupIntent(input: {}) {
    errors
    fieldErrors {
      message
      field
    }
    success
    clientSecret
  }
}
    `;
export type CreateStripeSetupIntentMutationFn = Apollo.MutationFunction<Operation.CreateStripeSetupIntentMutation, Operation.CreateStripeSetupIntentMutationVariables>;

/**
 * __useCreateStripeSetupIntentMutation__
 *
 * To run a mutation, you first call `useCreateStripeSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeSetupIntentMutation, { data, loading, error }] = useCreateStripeSetupIntentMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripeSetupIntentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateStripeSetupIntentMutation, Operation.CreateStripeSetupIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateStripeSetupIntentMutation, Operation.CreateStripeSetupIntentMutationVariables>(CreateStripeSetupIntentDocument, options);
      }
export type CreateStripeSetupIntentMutationHookResult = ReturnType<typeof useCreateStripeSetupIntentMutation>;
export type CreateStripeSetupIntentMutationResult = Apollo.MutationResult<Operation.CreateStripeSetupIntentMutation>;
export type CreateStripeSetupIntentMutationOptions = Apollo.BaseMutationOptions<Operation.CreateStripeSetupIntentMutation, Operation.CreateStripeSetupIntentMutationVariables>;
export const ConfirmStripePaymentDocument = gql`
    mutation ConfirmStripePayment($invoiceId: ID!, $paymentIntentId: ID!) {
  confirmStripePaymentIntent(
    input: {invoice: $invoiceId, paymentIntentId: $paymentIntentId}
  ) {
    errors
    fieldErrors {
      message
      field
    }
    success
    transactions {
      ...invoiceTransactionEssentials
    }
  }
}
    ${InvoiceTransactionEssentialsFragmentDoc}`;
export type ConfirmStripePaymentMutationFn = Apollo.MutationFunction<Operation.ConfirmStripePaymentMutation, Operation.ConfirmStripePaymentMutationVariables>;

/**
 * __useConfirmStripePaymentMutation__
 *
 * To run a mutation, you first call `useConfirmStripePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmStripePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmStripePaymentMutation, { data, loading, error }] = useConfirmStripePaymentMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      paymentIntentId: // value for 'paymentIntentId'
 *   },
 * });
 */
export function useConfirmStripePaymentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ConfirmStripePaymentMutation, Operation.ConfirmStripePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ConfirmStripePaymentMutation, Operation.ConfirmStripePaymentMutationVariables>(ConfirmStripePaymentDocument, options);
      }
export type ConfirmStripePaymentMutationHookResult = ReturnType<typeof useConfirmStripePaymentMutation>;
export type ConfirmStripePaymentMutationResult = Apollo.MutationResult<Operation.ConfirmStripePaymentMutation>;
export type ConfirmStripePaymentMutationOptions = Apollo.BaseMutationOptions<Operation.ConfirmStripePaymentMutation, Operation.ConfirmStripePaymentMutationVariables>;
export const ProcessStripeRefundDocument = gql`
    mutation ProcessStripeRefund($id: ID!, $amount: Int!) {
  processStripeRefund(input: {id: $id, amount: $amount}) {
    errors
    fieldErrors {
      message
      field
    }
    success
    stripeRefund {
      id
      object
      amount
      balanceTransaction
      charge
      created
      currency
      metadata
      paymentIntent
      reason
      receiptNumber
      sourceTransferReversal
      status
      transferReversal
    }
  }
}
    `;
export type ProcessStripeRefundMutationFn = Apollo.MutationFunction<Operation.ProcessStripeRefundMutation, Operation.ProcessStripeRefundMutationVariables>;

/**
 * __useProcessStripeRefundMutation__
 *
 * To run a mutation, you first call `useProcessStripeRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessStripeRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processStripeRefundMutation, { data, loading, error }] = useProcessStripeRefundMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useProcessStripeRefundMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ProcessStripeRefundMutation, Operation.ProcessStripeRefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ProcessStripeRefundMutation, Operation.ProcessStripeRefundMutationVariables>(ProcessStripeRefundDocument, options);
      }
export type ProcessStripeRefundMutationHookResult = ReturnType<typeof useProcessStripeRefundMutation>;
export type ProcessStripeRefundMutationResult = Apollo.MutationResult<Operation.ProcessStripeRefundMutation>;
export type ProcessStripeRefundMutationOptions = Apollo.BaseMutationOptions<Operation.ProcessStripeRefundMutation, Operation.ProcessStripeRefundMutationVariables>;
export const RemoveTyroTerminalDocument = gql`
    mutation RemoveTyroTerminal($input: RemoveTyroTerminalInput!) {
  removeTyroTerminal(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    paymentTerminal {
      ...paymentTerminalEssentials
    }
  }
}
    ${PaymentTerminalEssentialsFragmentDoc}`;
export type RemoveTyroTerminalMutationFn = Apollo.MutationFunction<Operation.RemoveTyroTerminalMutation, Operation.RemoveTyroTerminalMutationVariables>;

/**
 * __useRemoveTyroTerminalMutation__
 *
 * To run a mutation, you first call `useRemoveTyroTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTyroTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTyroTerminalMutation, { data, loading, error }] = useRemoveTyroTerminalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTyroTerminalMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RemoveTyroTerminalMutation, Operation.RemoveTyroTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RemoveTyroTerminalMutation, Operation.RemoveTyroTerminalMutationVariables>(RemoveTyroTerminalDocument, options);
      }
export type RemoveTyroTerminalMutationHookResult = ReturnType<typeof useRemoveTyroTerminalMutation>;
export type RemoveTyroTerminalMutationResult = Apollo.MutationResult<Operation.RemoveTyroTerminalMutation>;
export type RemoveTyroTerminalMutationOptions = Apollo.BaseMutationOptions<Operation.RemoveTyroTerminalMutation, Operation.RemoveTyroTerminalMutationVariables>;
export const ScheduleReminderInvoiceDocument = gql`
    mutation ScheduleReminderInvoice($id: ID!, $daysAfterDue: Int) {
  scheduleReminderInvoice(input: {id: $id, daysAfterDue: $daysAfterDue}) {
    errors
    fieldErrors {
      message
      field
    }
    success
    reminder {
      ...reminderDetailed
    }
  }
}
    ${ReminderDetailedFragmentDoc}`;
export type ScheduleReminderInvoiceMutationFn = Apollo.MutationFunction<Operation.ScheduleReminderInvoiceMutation, Operation.ScheduleReminderInvoiceMutationVariables>;

/**
 * __useScheduleReminderInvoiceMutation__
 *
 * To run a mutation, you first call `useScheduleReminderInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleReminderInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleReminderInvoiceMutation, { data, loading, error }] = useScheduleReminderInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      daysAfterDue: // value for 'daysAfterDue'
 *   },
 * });
 */
export function useScheduleReminderInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ScheduleReminderInvoiceMutation, Operation.ScheduleReminderInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ScheduleReminderInvoiceMutation, Operation.ScheduleReminderInvoiceMutationVariables>(ScheduleReminderInvoiceDocument, options);
      }
export type ScheduleReminderInvoiceMutationHookResult = ReturnType<typeof useScheduleReminderInvoiceMutation>;
export type ScheduleReminderInvoiceMutationResult = Apollo.MutationResult<Operation.ScheduleReminderInvoiceMutation>;
export type ScheduleReminderInvoiceMutationOptions = Apollo.BaseMutationOptions<Operation.ScheduleReminderInvoiceMutation, Operation.ScheduleReminderInvoiceMutationVariables>;
export const UpdateHicapsPaymentProviderDocument = gql`
    mutation UpdateHicapsPaymentProvider($attributes: HicapsPaymentProviderInput!) {
  updateHicapsPaymentProvider(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    paymentProvider {
      ...paymentProviderDetailed
    }
  }
}
    ${PaymentProviderDetailedFragmentDoc}`;
export type UpdateHicapsPaymentProviderMutationFn = Apollo.MutationFunction<Operation.UpdateHicapsPaymentProviderMutation, Operation.UpdateHicapsPaymentProviderMutationVariables>;

/**
 * __useUpdateHicapsPaymentProviderMutation__
 *
 * To run a mutation, you first call `useUpdateHicapsPaymentProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHicapsPaymentProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHicapsPaymentProviderMutation, { data, loading, error }] = useUpdateHicapsPaymentProviderMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateHicapsPaymentProviderMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateHicapsPaymentProviderMutation, Operation.UpdateHicapsPaymentProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateHicapsPaymentProviderMutation, Operation.UpdateHicapsPaymentProviderMutationVariables>(UpdateHicapsPaymentProviderDocument, options);
      }
export type UpdateHicapsPaymentProviderMutationHookResult = ReturnType<typeof useUpdateHicapsPaymentProviderMutation>;
export type UpdateHicapsPaymentProviderMutationResult = Apollo.MutationResult<Operation.UpdateHicapsPaymentProviderMutation>;
export type UpdateHicapsPaymentProviderMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateHicapsPaymentProviderMutation, Operation.UpdateHicapsPaymentProviderMutationVariables>;
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($id: ID!, $input: InvoiceUpdateInput!) {
  updateInvoice(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    invoice {
      ...invoiceDetailed
    }
  }
}
    ${InvoiceDetailedFragmentDoc}`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<Operation.UpdateInvoiceMutation, Operation.UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateInvoiceMutation, Operation.UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateInvoiceMutation, Operation.UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<Operation.UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateInvoiceMutation, Operation.UpdateInvoiceMutationVariables>;
export const UpdateInvoiceEssentialsDocument = gql`
    mutation UpdateInvoiceEssentials($id: ID!, $input: InvoiceUpdateInput!) {
  updateInvoice(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    invoice {
      ...invoiceEssentials
    }
  }
}
    ${InvoiceEssentialsFragmentDoc}`;
export type UpdateInvoiceEssentialsMutationFn = Apollo.MutationFunction<Operation.UpdateInvoiceEssentialsMutation, Operation.UpdateInvoiceEssentialsMutationVariables>;

/**
 * __useUpdateInvoiceEssentialsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceEssentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceEssentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceEssentialsMutation, { data, loading, error }] = useUpdateInvoiceEssentialsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceEssentialsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateInvoiceEssentialsMutation, Operation.UpdateInvoiceEssentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateInvoiceEssentialsMutation, Operation.UpdateInvoiceEssentialsMutationVariables>(UpdateInvoiceEssentialsDocument, options);
      }
export type UpdateInvoiceEssentialsMutationHookResult = ReturnType<typeof useUpdateInvoiceEssentialsMutation>;
export type UpdateInvoiceEssentialsMutationResult = Apollo.MutationResult<Operation.UpdateInvoiceEssentialsMutation>;
export type UpdateInvoiceEssentialsMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateInvoiceEssentialsMutation, Operation.UpdateInvoiceEssentialsMutationVariables>;
export const UpdateInvoiceItemDocument = gql`
    mutation UpdateInvoiceItem($id: ID!, $input: InvoiceItemUpdateInput!) {
  updateInvoiceItem(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
      details
    }
    success
    invoiceItem {
      ...invoiceItemEssentials
    }
  }
}
    ${InvoiceItemEssentialsFragmentDoc}`;
export type UpdateInvoiceItemMutationFn = Apollo.MutationFunction<Operation.UpdateInvoiceItemMutation, Operation.UpdateInvoiceItemMutationVariables>;

/**
 * __useUpdateInvoiceItemMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceItemMutation, { data, loading, error }] = useUpdateInvoiceItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceItemMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateInvoiceItemMutation, Operation.UpdateInvoiceItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateInvoiceItemMutation, Operation.UpdateInvoiceItemMutationVariables>(UpdateInvoiceItemDocument, options);
      }
export type UpdateInvoiceItemMutationHookResult = ReturnType<typeof useUpdateInvoiceItemMutation>;
export type UpdateInvoiceItemMutationResult = Apollo.MutationResult<Operation.UpdateInvoiceItemMutation>;
export type UpdateInvoiceItemMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateInvoiceItemMutation, Operation.UpdateInvoiceItemMutationVariables>;
export const UpdateInvoiceTransactionDocument = gql`
    mutation UpdateInvoiceTransaction($invoiceTransaction: ID!, $input: InvoiceTransactionUpdateInput!) {
  updateInvoiceTransaction(
    input: {invoiceTransaction: $invoiceTransaction, attributes: $input}
  ) {
    errors
    fieldErrors {
      field
      message
    }
    success
    invoiceTransaction {
      ...invoiceTransactionEssentials
    }
  }
}
    ${InvoiceTransactionEssentialsFragmentDoc}`;
export type UpdateInvoiceTransactionMutationFn = Apollo.MutationFunction<Operation.UpdateInvoiceTransactionMutation, Operation.UpdateInvoiceTransactionMutationVariables>;

/**
 * __useUpdateInvoiceTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceTransactionMutation, { data, loading, error }] = useUpdateInvoiceTransactionMutation({
 *   variables: {
 *      invoiceTransaction: // value for 'invoiceTransaction'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceTransactionMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateInvoiceTransactionMutation, Operation.UpdateInvoiceTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateInvoiceTransactionMutation, Operation.UpdateInvoiceTransactionMutationVariables>(UpdateInvoiceTransactionDocument, options);
      }
export type UpdateInvoiceTransactionMutationHookResult = ReturnType<typeof useUpdateInvoiceTransactionMutation>;
export type UpdateInvoiceTransactionMutationResult = Apollo.MutationResult<Operation.UpdateInvoiceTransactionMutation>;
export type UpdateInvoiceTransactionMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateInvoiceTransactionMutation, Operation.UpdateInvoiceTransactionMutationVariables>;
export const BackdateInvoiceTransactionDocument = gql`
    mutation BackdateInvoiceTransaction($input: BackdateInvoiceTransactionInput!) {
  backdateInvoiceTransaction(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    invoiceTransactions {
      ...invoiceTransactionMinimal
    }
    paymentTransaction {
      ...paymentTransactionEssentials
    }
  }
}
    ${InvoiceTransactionMinimalFragmentDoc}
${PaymentTransactionEssentialsFragmentDoc}`;
export type BackdateInvoiceTransactionMutationFn = Apollo.MutationFunction<Operation.BackdateInvoiceTransactionMutation, Operation.BackdateInvoiceTransactionMutationVariables>;

/**
 * __useBackdateInvoiceTransactionMutation__
 *
 * To run a mutation, you first call `useBackdateInvoiceTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackdateInvoiceTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backdateInvoiceTransactionMutation, { data, loading, error }] = useBackdateInvoiceTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackdateInvoiceTransactionMutation(baseOptions?: Apollo.MutationHookOptions<Operation.BackdateInvoiceTransactionMutation, Operation.BackdateInvoiceTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.BackdateInvoiceTransactionMutation, Operation.BackdateInvoiceTransactionMutationVariables>(BackdateInvoiceTransactionDocument, options);
      }
export type BackdateInvoiceTransactionMutationHookResult = ReturnType<typeof useBackdateInvoiceTransactionMutation>;
export type BackdateInvoiceTransactionMutationResult = Apollo.MutationResult<Operation.BackdateInvoiceTransactionMutation>;
export type BackdateInvoiceTransactionMutationOptions = Apollo.BaseMutationOptions<Operation.BackdateInvoiceTransactionMutation, Operation.BackdateInvoiceTransactionMutationVariables>;
export const ManageSurchargeDocument = gql`
    mutation ManageSurcharge($input: ManageSurchargeInput!) {
  manageSurcharge(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    invoiceTransaction {
      ...invoiceTransactionMinimal
    }
  }
}
    ${InvoiceTransactionMinimalFragmentDoc}`;
export type ManageSurchargeMutationFn = Apollo.MutationFunction<Operation.ManageSurchargeMutation, Operation.ManageSurchargeMutationVariables>;

/**
 * __useManageSurchargeMutation__
 *
 * To run a mutation, you first call `useManageSurchargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageSurchargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageSurchargeMutation, { data, loading, error }] = useManageSurchargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageSurchargeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ManageSurchargeMutation, Operation.ManageSurchargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ManageSurchargeMutation, Operation.ManageSurchargeMutationVariables>(ManageSurchargeDocument, options);
      }
export type ManageSurchargeMutationHookResult = ReturnType<typeof useManageSurchargeMutation>;
export type ManageSurchargeMutationResult = Apollo.MutationResult<Operation.ManageSurchargeMutation>;
export type ManageSurchargeMutationOptions = Apollo.BaseMutationOptions<Operation.ManageSurchargeMutation, Operation.ManageSurchargeMutationVariables>;
export const UpdateManualPaymentProviderDocument = gql`
    mutation UpdateManualPaymentProvider($attributes: ManualPaymentProviderInput!) {
  updateManualPaymentProvider(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    paymentProvider {
      ...paymentProviderDetailed
    }
  }
}
    ${PaymentProviderDetailedFragmentDoc}`;
export type UpdateManualPaymentProviderMutationFn = Apollo.MutationFunction<Operation.UpdateManualPaymentProviderMutation, Operation.UpdateManualPaymentProviderMutationVariables>;

/**
 * __useUpdateManualPaymentProviderMutation__
 *
 * To run a mutation, you first call `useUpdateManualPaymentProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManualPaymentProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManualPaymentProviderMutation, { data, loading, error }] = useUpdateManualPaymentProviderMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateManualPaymentProviderMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateManualPaymentProviderMutation, Operation.UpdateManualPaymentProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateManualPaymentProviderMutation, Operation.UpdateManualPaymentProviderMutationVariables>(UpdateManualPaymentProviderDocument, options);
      }
export type UpdateManualPaymentProviderMutationHookResult = ReturnType<typeof useUpdateManualPaymentProviderMutation>;
export type UpdateManualPaymentProviderMutationResult = Apollo.MutationResult<Operation.UpdateManualPaymentProviderMutation>;
export type UpdateManualPaymentProviderMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateManualPaymentProviderMutation, Operation.UpdateManualPaymentProviderMutationVariables>;
export const UpdateMedipassPaymentProviderDocument = gql`
    mutation UpdateMedipassPaymentProvider($attributes: MedipassPaymentProviderInput!) {
  updateMedipassPaymentProvider(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    paymentProvider {
      ...paymentProviderDetailed
    }
  }
}
    ${PaymentProviderDetailedFragmentDoc}`;
export type UpdateMedipassPaymentProviderMutationFn = Apollo.MutationFunction<Operation.UpdateMedipassPaymentProviderMutation, Operation.UpdateMedipassPaymentProviderMutationVariables>;

/**
 * __useUpdateMedipassPaymentProviderMutation__
 *
 * To run a mutation, you first call `useUpdateMedipassPaymentProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMedipassPaymentProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMedipassPaymentProviderMutation, { data, loading, error }] = useUpdateMedipassPaymentProviderMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateMedipassPaymentProviderMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateMedipassPaymentProviderMutation, Operation.UpdateMedipassPaymentProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateMedipassPaymentProviderMutation, Operation.UpdateMedipassPaymentProviderMutationVariables>(UpdateMedipassPaymentProviderDocument, options);
      }
export type UpdateMedipassPaymentProviderMutationHookResult = ReturnType<typeof useUpdateMedipassPaymentProviderMutation>;
export type UpdateMedipassPaymentProviderMutationResult = Apollo.MutationResult<Operation.UpdateMedipassPaymentProviderMutation>;
export type UpdateMedipassPaymentProviderMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateMedipassPaymentProviderMutation, Operation.UpdateMedipassPaymentProviderMutationVariables>;
export const UpdateStateInvoiceDocument = gql`
    mutation UpdateStateInvoice($id: ID!, $event: InvoiceStateEvent!) {
  updateStateInvoice(input: {id: $id, event: $event}) {
    errors
    fieldErrors {
      message
      field
    }
    success
    invoice {
      ...invoiceDetailed
    }
  }
}
    ${InvoiceDetailedFragmentDoc}`;
export type UpdateStateInvoiceMutationFn = Apollo.MutationFunction<Operation.UpdateStateInvoiceMutation, Operation.UpdateStateInvoiceMutationVariables>;

/**
 * __useUpdateStateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateStateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateInvoiceMutation, { data, loading, error }] = useUpdateStateInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateStateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateStateInvoiceMutation, Operation.UpdateStateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateStateInvoiceMutation, Operation.UpdateStateInvoiceMutationVariables>(UpdateStateInvoiceDocument, options);
      }
export type UpdateStateInvoiceMutationHookResult = ReturnType<typeof useUpdateStateInvoiceMutation>;
export type UpdateStateInvoiceMutationResult = Apollo.MutationResult<Operation.UpdateStateInvoiceMutation>;
export type UpdateStateInvoiceMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateStateInvoiceMutation, Operation.UpdateStateInvoiceMutationVariables>;
export const UpdateStripeProviderDocument = gql`
    mutation UpdateStripeProvider($attributes: StripePaymentProviderInput!) {
  updateStripePaymentProvider(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    paymentProvider {
      ...paymentProviderDetailed
    }
  }
}
    ${PaymentProviderDetailedFragmentDoc}`;
export type UpdateStripeProviderMutationFn = Apollo.MutationFunction<Operation.UpdateStripeProviderMutation, Operation.UpdateStripeProviderMutationVariables>;

/**
 * __useUpdateStripeProviderMutation__
 *
 * To run a mutation, you first call `useUpdateStripeProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripeProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripeProviderMutation, { data, loading, error }] = useUpdateStripeProviderMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateStripeProviderMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateStripeProviderMutation, Operation.UpdateStripeProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateStripeProviderMutation, Operation.UpdateStripeProviderMutationVariables>(UpdateStripeProviderDocument, options);
      }
export type UpdateStripeProviderMutationHookResult = ReturnType<typeof useUpdateStripeProviderMutation>;
export type UpdateStripeProviderMutationResult = Apollo.MutationResult<Operation.UpdateStripeProviderMutation>;
export type UpdateStripeProviderMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateStripeProviderMutation, Operation.UpdateStripeProviderMutationVariables>;
export const SyncConnectedAccountDocument = gql`
    mutation SyncConnectedAccount($id: ID!) {
  syncConnectedAccount(input: {id: $id}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    paymentProvider {
      ...paymentProviderDetailed
    }
  }
}
    ${PaymentProviderDetailedFragmentDoc}`;
export type SyncConnectedAccountMutationFn = Apollo.MutationFunction<Operation.SyncConnectedAccountMutation, Operation.SyncConnectedAccountMutationVariables>;

/**
 * __useSyncConnectedAccountMutation__
 *
 * To run a mutation, you first call `useSyncConnectedAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncConnectedAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncConnectedAccountMutation, { data, loading, error }] = useSyncConnectedAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyncConnectedAccountMutation(baseOptions?: Apollo.MutationHookOptions<Operation.SyncConnectedAccountMutation, Operation.SyncConnectedAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.SyncConnectedAccountMutation, Operation.SyncConnectedAccountMutationVariables>(SyncConnectedAccountDocument, options);
      }
export type SyncConnectedAccountMutationHookResult = ReturnType<typeof useSyncConnectedAccountMutation>;
export type SyncConnectedAccountMutationResult = Apollo.MutationResult<Operation.SyncConnectedAccountMutation>;
export type SyncConnectedAccountMutationOptions = Apollo.BaseMutationOptions<Operation.SyncConnectedAccountMutation, Operation.SyncConnectedAccountMutationVariables>;
export const UpdateTyroProviderDocument = gql`
    mutation UpdateTyroProvider($attributes: TyroPaymentProviderInput!) {
  updateTyroPaymentProvider(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    paymentProvider {
      ...paymentProviderDetailed
    }
  }
}
    ${PaymentProviderDetailedFragmentDoc}`;
export type UpdateTyroProviderMutationFn = Apollo.MutationFunction<Operation.UpdateTyroProviderMutation, Operation.UpdateTyroProviderMutationVariables>;

/**
 * __useUpdateTyroProviderMutation__
 *
 * To run a mutation, you first call `useUpdateTyroProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTyroProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTyroProviderMutation, { data, loading, error }] = useUpdateTyroProviderMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTyroProviderMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateTyroProviderMutation, Operation.UpdateTyroProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateTyroProviderMutation, Operation.UpdateTyroProviderMutationVariables>(UpdateTyroProviderDocument, options);
      }
export type UpdateTyroProviderMutationHookResult = ReturnType<typeof useUpdateTyroProviderMutation>;
export type UpdateTyroProviderMutationResult = Apollo.MutationResult<Operation.UpdateTyroProviderMutation>;
export type UpdateTyroProviderMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateTyroProviderMutation, Operation.UpdateTyroProviderMutationVariables>;
export const UpdateTyroTerminalDocument = gql`
    mutation UpdateTyroTerminal($input: UpdateTyroTerminalInput!) {
  updateTyroTerminal(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    paymentTerminal {
      ...paymentTerminalEssentials
    }
  }
}
    ${PaymentTerminalEssentialsFragmentDoc}`;
export type UpdateTyroTerminalMutationFn = Apollo.MutationFunction<Operation.UpdateTyroTerminalMutation, Operation.UpdateTyroTerminalMutationVariables>;

/**
 * __useUpdateTyroTerminalMutation__
 *
 * To run a mutation, you first call `useUpdateTyroTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTyroTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTyroTerminalMutation, { data, loading, error }] = useUpdateTyroTerminalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTyroTerminalMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateTyroTerminalMutation, Operation.UpdateTyroTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateTyroTerminalMutation, Operation.UpdateTyroTerminalMutationVariables>(UpdateTyroTerminalDocument, options);
      }
export type UpdateTyroTerminalMutationHookResult = ReturnType<typeof useUpdateTyroTerminalMutation>;
export type UpdateTyroTerminalMutationResult = Apollo.MutationResult<Operation.UpdateTyroTerminalMutation>;
export type UpdateTyroTerminalMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateTyroTerminalMutation, Operation.UpdateTyroTerminalMutationVariables>;
export const AssignAppointmentReasonsDocument = gql`
    mutation AssignAppointmentReasons($practitionerId: ID!, $appointmentReasonIds: [ID!]) {
  assignAppointmentReasons(
    input: {practitioner: $practitionerId, appointmentReasonIds: $appointmentReasonIds}
  ) {
    errors
    success
    fieldErrors {
      field
      message
    }
    userPracticeConnection {
      id
      guid
      appointmentReasons {
        id
        name
      }
    }
  }
}
    `;
export type AssignAppointmentReasonsMutationFn = Apollo.MutationFunction<Operation.AssignAppointmentReasonsMutation, Operation.AssignAppointmentReasonsMutationVariables>;

/**
 * __useAssignAppointmentReasonsMutation__
 *
 * To run a mutation, you first call `useAssignAppointmentReasonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAppointmentReasonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAppointmentReasonsMutation, { data, loading, error }] = useAssignAppointmentReasonsMutation({
 *   variables: {
 *      practitionerId: // value for 'practitionerId'
 *      appointmentReasonIds: // value for 'appointmentReasonIds'
 *   },
 * });
 */
export function useAssignAppointmentReasonsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AssignAppointmentReasonsMutation, Operation.AssignAppointmentReasonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AssignAppointmentReasonsMutation, Operation.AssignAppointmentReasonsMutationVariables>(AssignAppointmentReasonsDocument, options);
      }
export type AssignAppointmentReasonsMutationHookResult = ReturnType<typeof useAssignAppointmentReasonsMutation>;
export type AssignAppointmentReasonsMutationResult = Apollo.MutationResult<Operation.AssignAppointmentReasonsMutation>;
export type AssignAppointmentReasonsMutationOptions = Apollo.BaseMutationOptions<Operation.AssignAppointmentReasonsMutation, Operation.AssignAppointmentReasonsMutationVariables>;
export const CreatePracticeDocument = gql`
    mutation CreatePractice($industryId: ID!, $name: String!, $mobilePhone: String!, $mobileRegionCode: Int!, $mobileRegionShort: String!) {
  createPractice(
    input: {industryId: $industryId, name: $name, mobilePhone: $mobilePhone, mobileRegionCode: $mobileRegionCode, mobileRegionShort: $mobileRegionShort}
  ) {
    errors
    success
    fieldErrors {
      field
      message
    }
    practice {
      ...practiceDetailed
    }
  }
}
    ${PracticeDetailedFragmentDoc}`;
export type CreatePracticeMutationFn = Apollo.MutationFunction<Operation.CreatePracticeMutation, Operation.CreatePracticeMutationVariables>;

/**
 * __useCreatePracticeMutation__
 *
 * To run a mutation, you first call `useCreatePracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPracticeMutation, { data, loading, error }] = useCreatePracticeMutation({
 *   variables: {
 *      industryId: // value for 'industryId'
 *      name: // value for 'name'
 *      mobilePhone: // value for 'mobilePhone'
 *      mobileRegionCode: // value for 'mobileRegionCode'
 *      mobileRegionShort: // value for 'mobileRegionShort'
 *   },
 * });
 */
export function useCreatePracticeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreatePracticeMutation, Operation.CreatePracticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreatePracticeMutation, Operation.CreatePracticeMutationVariables>(CreatePracticeDocument, options);
      }
export type CreatePracticeMutationHookResult = ReturnType<typeof useCreatePracticeMutation>;
export type CreatePracticeMutationResult = Apollo.MutationResult<Operation.CreatePracticeMutation>;
export type CreatePracticeMutationOptions = Apollo.BaseMutationOptions<Operation.CreatePracticeMutation, Operation.CreatePracticeMutationVariables>;
export const UpdateInvoiceSettingDocument = gql`
    mutation UpdateInvoiceSetting($attributes: InvoiceSettingsInput!) {
  updateInvoiceSetting(input: {attributes: $attributes}) {
    fieldErrors {
      message
      field
    }
    errors
    success
    invoiceSetting {
      ...invoiceSettingsEssentials
    }
  }
}
    ${InvoiceSettingsEssentialsFragmentDoc}`;
export type UpdateInvoiceSettingMutationFn = Apollo.MutationFunction<Operation.UpdateInvoiceSettingMutation, Operation.UpdateInvoiceSettingMutationVariables>;

/**
 * __useUpdateInvoiceSettingMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceSettingMutation, { data, loading, error }] = useUpdateInvoiceSettingMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateInvoiceSettingMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateInvoiceSettingMutation, Operation.UpdateInvoiceSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateInvoiceSettingMutation, Operation.UpdateInvoiceSettingMutationVariables>(UpdateInvoiceSettingDocument, options);
      }
export type UpdateInvoiceSettingMutationHookResult = ReturnType<typeof useUpdateInvoiceSettingMutation>;
export type UpdateInvoiceSettingMutationResult = Apollo.MutationResult<Operation.UpdateInvoiceSettingMutation>;
export type UpdateInvoiceSettingMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateInvoiceSettingMutation, Operation.UpdateInvoiceSettingMutationVariables>;
export const CreateInvoiceSettingDocument = gql`
    mutation CreateInvoiceSetting($attributes: InvoiceSettingsInput!) {
  createInvoiceSetting(input: {attributes: $attributes}) {
    fieldErrors {
      message
      field
    }
    errors
    success
    invoiceSetting {
      ...invoiceSettingsEssentials
    }
  }
}
    ${InvoiceSettingsEssentialsFragmentDoc}`;
export type CreateInvoiceSettingMutationFn = Apollo.MutationFunction<Operation.CreateInvoiceSettingMutation, Operation.CreateInvoiceSettingMutationVariables>;

/**
 * __useCreateInvoiceSettingMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceSettingMutation, { data, loading, error }] = useCreateInvoiceSettingMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateInvoiceSettingMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateInvoiceSettingMutation, Operation.CreateInvoiceSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateInvoiceSettingMutation, Operation.CreateInvoiceSettingMutationVariables>(CreateInvoiceSettingDocument, options);
      }
export type CreateInvoiceSettingMutationHookResult = ReturnType<typeof useCreateInvoiceSettingMutation>;
export type CreateInvoiceSettingMutationResult = Apollo.MutationResult<Operation.CreateInvoiceSettingMutation>;
export type CreateInvoiceSettingMutationOptions = Apollo.BaseMutationOptions<Operation.CreateInvoiceSettingMutation, Operation.CreateInvoiceSettingMutationVariables>;
export const ConfigureMailSettingsDocument = gql`
    mutation ConfigureMailSettings($attributes: MailSettingsInput!) {
  configureMailSettings(input: {attributes: $attributes}) {
    errors
    success
    fieldErrors {
      field
      message
    }
    mailSettings {
      ...mailSettingsDetailed
    }
  }
}
    ${MailSettingsDetailedFragmentDoc}`;
export type ConfigureMailSettingsMutationFn = Apollo.MutationFunction<Operation.ConfigureMailSettingsMutation, Operation.ConfigureMailSettingsMutationVariables>;

/**
 * __useConfigureMailSettingsMutation__
 *
 * To run a mutation, you first call `useConfigureMailSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureMailSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureMailSettingsMutation, { data, loading, error }] = useConfigureMailSettingsMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useConfigureMailSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ConfigureMailSettingsMutation, Operation.ConfigureMailSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ConfigureMailSettingsMutation, Operation.ConfigureMailSettingsMutationVariables>(ConfigureMailSettingsDocument, options);
      }
export type ConfigureMailSettingsMutationHookResult = ReturnType<typeof useConfigureMailSettingsMutation>;
export type ConfigureMailSettingsMutationResult = Apollo.MutationResult<Operation.ConfigureMailSettingsMutation>;
export type ConfigureMailSettingsMutationOptions = Apollo.BaseMutationOptions<Operation.ConfigureMailSettingsMutation, Operation.ConfigureMailSettingsMutationVariables>;
export const DeleteMailSettingsDocument = gql`
    mutation DeleteMailSettings($practiceId: ID!) {
  deleteMailSettings(input: {practiceId: $practiceId}) {
    errors
    success
    practice {
      ...practiceDetailed
    }
  }
}
    ${PracticeDetailedFragmentDoc}`;
export type DeleteMailSettingsMutationFn = Apollo.MutationFunction<Operation.DeleteMailSettingsMutation, Operation.DeleteMailSettingsMutationVariables>;

/**
 * __useDeleteMailSettingsMutation__
 *
 * To run a mutation, you first call `useDeleteMailSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMailSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMailSettingsMutation, { data, loading, error }] = useDeleteMailSettingsMutation({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *   },
 * });
 */
export function useDeleteMailSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeleteMailSettingsMutation, Operation.DeleteMailSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeleteMailSettingsMutation, Operation.DeleteMailSettingsMutationVariables>(DeleteMailSettingsDocument, options);
      }
export type DeleteMailSettingsMutationHookResult = ReturnType<typeof useDeleteMailSettingsMutation>;
export type DeleteMailSettingsMutationResult = Apollo.MutationResult<Operation.DeleteMailSettingsMutation>;
export type DeleteMailSettingsMutationOptions = Apollo.BaseMutationOptions<Operation.DeleteMailSettingsMutation, Operation.DeleteMailSettingsMutationVariables>;
export const UpdatePracticeLocationDocument = gql`
    mutation UpdatePracticeLocation($input: UpdatePracticeLocationInput!) {
  updatePracticeLocation(input: $input) {
    fieldErrors {
      message
      field
    }
    errors
    success
    practiceLocation {
      ...practiceLocationEssentials
    }
  }
}
    ${PracticeLocationEssentialsFragmentDoc}`;
export type UpdatePracticeLocationMutationFn = Apollo.MutationFunction<Operation.UpdatePracticeLocationMutation, Operation.UpdatePracticeLocationMutationVariables>;

/**
 * __useUpdatePracticeLocationMutation__
 *
 * To run a mutation, you first call `useUpdatePracticeLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePracticeLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePracticeLocationMutation, { data, loading, error }] = useUpdatePracticeLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePracticeLocationMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdatePracticeLocationMutation, Operation.UpdatePracticeLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdatePracticeLocationMutation, Operation.UpdatePracticeLocationMutationVariables>(UpdatePracticeLocationDocument, options);
      }
export type UpdatePracticeLocationMutationHookResult = ReturnType<typeof useUpdatePracticeLocationMutation>;
export type UpdatePracticeLocationMutationResult = Apollo.MutationResult<Operation.UpdatePracticeLocationMutation>;
export type UpdatePracticeLocationMutationOptions = Apollo.BaseMutationOptions<Operation.UpdatePracticeLocationMutation, Operation.UpdatePracticeLocationMutationVariables>;
export const CreatePracticeLocationDocument = gql`
    mutation CreatePracticeLocation($input: CreatePracticeLocationInput!) {
  createPracticeLocation(input: $input) {
    fieldErrors {
      message
      field
    }
    errors
    success
    practiceLocation {
      ...practiceLocationEssentials
    }
  }
}
    ${PracticeLocationEssentialsFragmentDoc}`;
export type CreatePracticeLocationMutationFn = Apollo.MutationFunction<Operation.CreatePracticeLocationMutation, Operation.CreatePracticeLocationMutationVariables>;

/**
 * __useCreatePracticeLocationMutation__
 *
 * To run a mutation, you first call `useCreatePracticeLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePracticeLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPracticeLocationMutation, { data, loading, error }] = useCreatePracticeLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePracticeLocationMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreatePracticeLocationMutation, Operation.CreatePracticeLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreatePracticeLocationMutation, Operation.CreatePracticeLocationMutationVariables>(CreatePracticeLocationDocument, options);
      }
export type CreatePracticeLocationMutationHookResult = ReturnType<typeof useCreatePracticeLocationMutation>;
export type CreatePracticeLocationMutationResult = Apollo.MutationResult<Operation.CreatePracticeLocationMutation>;
export type CreatePracticeLocationMutationOptions = Apollo.BaseMutationOptions<Operation.CreatePracticeLocationMutation, Operation.CreatePracticeLocationMutationVariables>;
export const UpdateOnlineBookingSettingsDocument = gql`
    mutation UpdateOnlineBookingSettings($input: UpdateOnlineBookingSettingsInput!) {
  updateOnlineBookingSettings(input: $input) {
    fieldErrors {
      message
      field
    }
    errors
    success
    onlineBookingSetting {
      ...onlineBookingSettingsEssentials
    }
  }
}
    ${OnlineBookingSettingsEssentialsFragmentDoc}`;
export type UpdateOnlineBookingSettingsMutationFn = Apollo.MutationFunction<Operation.UpdateOnlineBookingSettingsMutation, Operation.UpdateOnlineBookingSettingsMutationVariables>;

/**
 * __useUpdateOnlineBookingSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOnlineBookingSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnlineBookingSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnlineBookingSettingsMutation, { data, loading, error }] = useUpdateOnlineBookingSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnlineBookingSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateOnlineBookingSettingsMutation, Operation.UpdateOnlineBookingSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateOnlineBookingSettingsMutation, Operation.UpdateOnlineBookingSettingsMutationVariables>(UpdateOnlineBookingSettingsDocument, options);
      }
export type UpdateOnlineBookingSettingsMutationHookResult = ReturnType<typeof useUpdateOnlineBookingSettingsMutation>;
export type UpdateOnlineBookingSettingsMutationResult = Apollo.MutationResult<Operation.UpdateOnlineBookingSettingsMutation>;
export type UpdateOnlineBookingSettingsMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateOnlineBookingSettingsMutation, Operation.UpdateOnlineBookingSettingsMutationVariables>;
export const UpdatePracticePreferenceDocument = gql`
    mutation UpdatePracticePreference($attributes: PreferenceInput!) {
  updatePracticePreference(input: {attributes: $attributes}) {
    errors
    success
    fieldErrors {
      field
      message
    }
    preference {
      ...practicePreference
    }
  }
}
    ${PracticePreferenceFragmentDoc}`;
export type UpdatePracticePreferenceMutationFn = Apollo.MutationFunction<Operation.UpdatePracticePreferenceMutation, Operation.UpdatePracticePreferenceMutationVariables>;

/**
 * __useUpdatePracticePreferenceMutation__
 *
 * To run a mutation, you first call `useUpdatePracticePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePracticePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePracticePreferenceMutation, { data, loading, error }] = useUpdatePracticePreferenceMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdatePracticePreferenceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdatePracticePreferenceMutation, Operation.UpdatePracticePreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdatePracticePreferenceMutation, Operation.UpdatePracticePreferenceMutationVariables>(UpdatePracticePreferenceDocument, options);
      }
export type UpdatePracticePreferenceMutationHookResult = ReturnType<typeof useUpdatePracticePreferenceMutation>;
export type UpdatePracticePreferenceMutationResult = Apollo.MutationResult<Operation.UpdatePracticePreferenceMutation>;
export type UpdatePracticePreferenceMutationOptions = Apollo.BaseMutationOptions<Operation.UpdatePracticePreferenceMutation, Operation.UpdatePracticePreferenceMutationVariables>;
export const UpdatePracticeDocument = gql`
    mutation UpdatePractice($attributes: PracticeInput!) {
  updatePractice(input: {attributes: $attributes}) {
    errors
    success
    fieldErrors {
      field
      message
    }
    practice {
      ...practiceDetailed
    }
  }
}
    ${PracticeDetailedFragmentDoc}`;
export type UpdatePracticeMutationFn = Apollo.MutationFunction<Operation.UpdatePracticeMutation, Operation.UpdatePracticeMutationVariables>;

/**
 * __useUpdatePracticeMutation__
 *
 * To run a mutation, you first call `useUpdatePracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePracticeMutation, { data, loading, error }] = useUpdatePracticeMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdatePracticeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdatePracticeMutation, Operation.UpdatePracticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdatePracticeMutation, Operation.UpdatePracticeMutationVariables>(UpdatePracticeDocument, options);
      }
export type UpdatePracticeMutationHookResult = ReturnType<typeof useUpdatePracticeMutation>;
export type UpdatePracticeMutationResult = Apollo.MutationResult<Operation.UpdatePracticeMutation>;
export type UpdatePracticeMutationOptions = Apollo.BaseMutationOptions<Operation.UpdatePracticeMutation, Operation.UpdatePracticeMutationVariables>;
export const UpdatePracticeDasboardConfigurationDocument = gql`
    mutation UpdatePracticeDasboardConfiguration($attributes: PracticeInput!) {
  updatePractice(input: {attributes: $attributes}) {
    errors
    success
    fieldErrors {
      field
      message
    }
    practice {
      id
      configuration {
        ...practiceConfigurationDashboard
      }
    }
  }
}
    ${PracticeConfigurationDashboardFragmentDoc}`;
export type UpdatePracticeDasboardConfigurationMutationFn = Apollo.MutationFunction<Operation.UpdatePracticeDasboardConfigurationMutation, Operation.UpdatePracticeDasboardConfigurationMutationVariables>;

/**
 * __useUpdatePracticeDasboardConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePracticeDasboardConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePracticeDasboardConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePracticeDasboardConfigurationMutation, { data, loading, error }] = useUpdatePracticeDasboardConfigurationMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdatePracticeDasboardConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdatePracticeDasboardConfigurationMutation, Operation.UpdatePracticeDasboardConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdatePracticeDasboardConfigurationMutation, Operation.UpdatePracticeDasboardConfigurationMutationVariables>(UpdatePracticeDasboardConfigurationDocument, options);
      }
export type UpdatePracticeDasboardConfigurationMutationHookResult = ReturnType<typeof useUpdatePracticeDasboardConfigurationMutation>;
export type UpdatePracticeDasboardConfigurationMutationResult = Apollo.MutationResult<Operation.UpdatePracticeDasboardConfigurationMutation>;
export type UpdatePracticeDasboardConfigurationMutationOptions = Apollo.BaseMutationOptions<Operation.UpdatePracticeDasboardConfigurationMutation, Operation.UpdatePracticeDasboardConfigurationMutationVariables>;
export const UpdateBillingAddressDocument = gql`
    mutation updateBillingAddress($attributes: LocationInput!) {
  updateBillingAddress(input: {attributes: $attributes}) {
    errors
    success
    fieldErrors {
      field
      message
    }
    location {
      ...locationEssentials
    }
  }
}
    ${LocationEssentialsFragmentDoc}`;
export type UpdateBillingAddressMutationFn = Apollo.MutationFunction<Operation.UpdateBillingAddressMutation, Operation.UpdateBillingAddressMutationVariables>;

/**
 * __useUpdateBillingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingAddressMutation, { data, loading, error }] = useUpdateBillingAddressMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateBillingAddressMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateBillingAddressMutation, Operation.UpdateBillingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateBillingAddressMutation, Operation.UpdateBillingAddressMutationVariables>(UpdateBillingAddressDocument, options);
      }
export type UpdateBillingAddressMutationHookResult = ReturnType<typeof useUpdateBillingAddressMutation>;
export type UpdateBillingAddressMutationResult = Apollo.MutationResult<Operation.UpdateBillingAddressMutation>;
export type UpdateBillingAddressMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateBillingAddressMutation, Operation.UpdateBillingAddressMutationVariables>;
export const AddCreditCardDocument = gql`
    mutation AddCreditCard($input: AddCreditCardInput!) {
  addCreditCard(input: $input) {
    errors
    success
    practice {
      ...practiceSubscription
    }
  }
}
    ${PracticeSubscriptionFragmentDoc}`;
export type AddCreditCardMutationFn = Apollo.MutationFunction<Operation.AddCreditCardMutation, Operation.AddCreditCardMutationVariables>;

/**
 * __useAddCreditCardMutation__
 *
 * To run a mutation, you first call `useAddCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCreditCardMutation, { data, loading, error }] = useAddCreditCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AddCreditCardMutation, Operation.AddCreditCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AddCreditCardMutation, Operation.AddCreditCardMutationVariables>(AddCreditCardDocument, options);
      }
export type AddCreditCardMutationHookResult = ReturnType<typeof useAddCreditCardMutation>;
export type AddCreditCardMutationResult = Apollo.MutationResult<Operation.AddCreditCardMutation>;
export type AddCreditCardMutationOptions = Apollo.BaseMutationOptions<Operation.AddCreditCardMutation, Operation.AddCreditCardMutationVariables>;
export const RemoveCreditCardDocument = gql`
    mutation RemoveCreditCard($practiceId: ID!) {
  removeCreditCard(input: {practiceId: $practiceId}) {
    errors
    success
    practice {
      ...practiceSubscription
    }
  }
}
    ${PracticeSubscriptionFragmentDoc}`;
export type RemoveCreditCardMutationFn = Apollo.MutationFunction<Operation.RemoveCreditCardMutation, Operation.RemoveCreditCardMutationVariables>;

/**
 * __useRemoveCreditCardMutation__
 *
 * To run a mutation, you first call `useRemoveCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCreditCardMutation, { data, loading, error }] = useRemoveCreditCardMutation({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *   },
 * });
 */
export function useRemoveCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RemoveCreditCardMutation, Operation.RemoveCreditCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RemoveCreditCardMutation, Operation.RemoveCreditCardMutationVariables>(RemoveCreditCardDocument, options);
      }
export type RemoveCreditCardMutationHookResult = ReturnType<typeof useRemoveCreditCardMutation>;
export type RemoveCreditCardMutationResult = Apollo.MutationResult<Operation.RemoveCreditCardMutation>;
export type RemoveCreditCardMutationOptions = Apollo.BaseMutationOptions<Operation.RemoveCreditCardMutation, Operation.RemoveCreditCardMutationVariables>;
export const PurchaseCreditsDocument = gql`
    mutation PurchaseCredits($product: String!, $couponCode: String) {
  purchaseCredits(input: {product: $product, couponCode: $couponCode}) {
    errors
    success
    practice {
      ...practiceSubscription
    }
  }
}
    ${PracticeSubscriptionFragmentDoc}`;
export type PurchaseCreditsMutationFn = Apollo.MutationFunction<Operation.PurchaseCreditsMutation, Operation.PurchaseCreditsMutationVariables>;

/**
 * __usePurchaseCreditsMutation__
 *
 * To run a mutation, you first call `usePurchaseCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseCreditsMutation, { data, loading, error }] = usePurchaseCreditsMutation({
 *   variables: {
 *      product: // value for 'product'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function usePurchaseCreditsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.PurchaseCreditsMutation, Operation.PurchaseCreditsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.PurchaseCreditsMutation, Operation.PurchaseCreditsMutationVariables>(PurchaseCreditsDocument, options);
      }
export type PurchaseCreditsMutationHookResult = ReturnType<typeof usePurchaseCreditsMutation>;
export type PurchaseCreditsMutationResult = Apollo.MutationResult<Operation.PurchaseCreditsMutation>;
export type PurchaseCreditsMutationOptions = Apollo.BaseMutationOptions<Operation.PurchaseCreditsMutation, Operation.PurchaseCreditsMutationVariables>;
export const CreateRecallDocument = gql`
    mutation CreateRecall($attributes: RecallInput!) {
  createRecall(input: {attributes: $attributes}) {
    fieldErrors {
      field
      message
    }
    errors
    success
    recall {
      ...recallEssentials
    }
  }
}
    ${RecallEssentialsFragmentDoc}`;
export type CreateRecallMutationFn = Apollo.MutationFunction<Operation.CreateRecallMutation, Operation.CreateRecallMutationVariables>;

/**
 * __useCreateRecallMutation__
 *
 * To run a mutation, you first call `useCreateRecallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecallMutation, { data, loading, error }] = useCreateRecallMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateRecallMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateRecallMutation, Operation.CreateRecallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateRecallMutation, Operation.CreateRecallMutationVariables>(CreateRecallDocument, options);
      }
export type CreateRecallMutationHookResult = ReturnType<typeof useCreateRecallMutation>;
export type CreateRecallMutationResult = Apollo.MutationResult<Operation.CreateRecallMutation>;
export type CreateRecallMutationOptions = Apollo.BaseMutationOptions<Operation.CreateRecallMutation, Operation.CreateRecallMutationVariables>;
export const CreateAutomaticRecallDocument = gql`
    mutation CreateAutomaticRecall($id: ID!) {
  createAutomaticRecall(input: {id: $id}) {
    fieldErrors {
      field
      message
    }
    errors
    success
    userPracticeConnection {
      ...userPracticeConnectionRecalls
    }
  }
}
    ${UserPracticeConnectionRecallsFragmentDoc}`;
export type CreateAutomaticRecallMutationFn = Apollo.MutationFunction<Operation.CreateAutomaticRecallMutation, Operation.CreateAutomaticRecallMutationVariables>;

/**
 * __useCreateAutomaticRecallMutation__
 *
 * To run a mutation, you first call `useCreateAutomaticRecallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutomaticRecallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutomaticRecallMutation, { data, loading, error }] = useCreateAutomaticRecallMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateAutomaticRecallMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateAutomaticRecallMutation, Operation.CreateAutomaticRecallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateAutomaticRecallMutation, Operation.CreateAutomaticRecallMutationVariables>(CreateAutomaticRecallDocument, options);
      }
export type CreateAutomaticRecallMutationHookResult = ReturnType<typeof useCreateAutomaticRecallMutation>;
export type CreateAutomaticRecallMutationResult = Apollo.MutationResult<Operation.CreateAutomaticRecallMutation>;
export type CreateAutomaticRecallMutationOptions = Apollo.BaseMutationOptions<Operation.CreateAutomaticRecallMutation, Operation.CreateAutomaticRecallMutationVariables>;
export const TransitionStateRecallDocument = gql`
    mutation TransitionStateRecall($id: ID!, $event: RecallStateEvent!) {
  transitionRecallState(input: {id: $id, event: $event}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    recall {
      ...recallEssentials
    }
  }
}
    ${RecallEssentialsFragmentDoc}`;
export type TransitionStateRecallMutationFn = Apollo.MutationFunction<Operation.TransitionStateRecallMutation, Operation.TransitionStateRecallMutationVariables>;

/**
 * __useTransitionStateRecallMutation__
 *
 * To run a mutation, you first call `useTransitionStateRecallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionStateRecallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionStateRecallMutation, { data, loading, error }] = useTransitionStateRecallMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionStateRecallMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionStateRecallMutation, Operation.TransitionStateRecallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionStateRecallMutation, Operation.TransitionStateRecallMutationVariables>(TransitionStateRecallDocument, options);
      }
export type TransitionStateRecallMutationHookResult = ReturnType<typeof useTransitionStateRecallMutation>;
export type TransitionStateRecallMutationResult = Apollo.MutationResult<Operation.TransitionStateRecallMutation>;
export type TransitionStateRecallMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionStateRecallMutation, Operation.TransitionStateRecallMutationVariables>;
export const TriggerAutoRecallDocument = gql`
    mutation TriggerAutoRecall($id: ID!, $startDate: ISO8601Date!, $endDate: ISO8601Date!) {
  triggerAutoRecall(input: {id: $id, startDate: $startDate, endDate: $endDate}) {
    success
    errors
    practice {
      ...practiceEssentials
    }
  }
}
    ${PracticeEssentialsFragmentDoc}`;
export type TriggerAutoRecallMutationFn = Apollo.MutationFunction<Operation.TriggerAutoRecallMutation, Operation.TriggerAutoRecallMutationVariables>;

/**
 * __useTriggerAutoRecallMutation__
 *
 * To run a mutation, you first call `useTriggerAutoRecallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerAutoRecallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerAutoRecallMutation, { data, loading, error }] = useTriggerAutoRecallMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTriggerAutoRecallMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TriggerAutoRecallMutation, Operation.TriggerAutoRecallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TriggerAutoRecallMutation, Operation.TriggerAutoRecallMutationVariables>(TriggerAutoRecallDocument, options);
      }
export type TriggerAutoRecallMutationHookResult = ReturnType<typeof useTriggerAutoRecallMutation>;
export type TriggerAutoRecallMutationResult = Apollo.MutationResult<Operation.TriggerAutoRecallMutation>;
export type TriggerAutoRecallMutationOptions = Apollo.BaseMutationOptions<Operation.TriggerAutoRecallMutation, Operation.TriggerAutoRecallMutationVariables>;
export const CreateRecurringTaskDocument = gql`
    mutation CreateRecurringTask($attributes: RecurringTaskInput!) {
  createRecurringTask(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    recurringTask {
      ...recurringTaskEssentials
    }
  }
}
    ${RecurringTaskEssentialsFragmentDoc}`;
export type CreateRecurringTaskMutationFn = Apollo.MutationFunction<Operation.CreateRecurringTaskMutation, Operation.CreateRecurringTaskMutationVariables>;

/**
 * __useCreateRecurringTaskMutation__
 *
 * To run a mutation, you first call `useCreateRecurringTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringTaskMutation, { data, loading, error }] = useCreateRecurringTaskMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateRecurringTaskMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateRecurringTaskMutation, Operation.CreateRecurringTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateRecurringTaskMutation, Operation.CreateRecurringTaskMutationVariables>(CreateRecurringTaskDocument, options);
      }
export type CreateRecurringTaskMutationHookResult = ReturnType<typeof useCreateRecurringTaskMutation>;
export type CreateRecurringTaskMutationResult = Apollo.MutationResult<Operation.CreateRecurringTaskMutation>;
export type CreateRecurringTaskMutationOptions = Apollo.BaseMutationOptions<Operation.CreateRecurringTaskMutation, Operation.CreateRecurringTaskMutationVariables>;
export const UpdateRecurringTaskDocument = gql`
    mutation UpdateRecurringTask($id: String!, $attributes: RecurringTaskInput!) {
  updateRecurringTask(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    recurringTask {
      ...recurringTaskEssentials
    }
  }
}
    ${RecurringTaskEssentialsFragmentDoc}`;
export type UpdateRecurringTaskMutationFn = Apollo.MutationFunction<Operation.UpdateRecurringTaskMutation, Operation.UpdateRecurringTaskMutationVariables>;

/**
 * __useUpdateRecurringTaskMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringTaskMutation, { data, loading, error }] = useUpdateRecurringTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateRecurringTaskMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateRecurringTaskMutation, Operation.UpdateRecurringTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateRecurringTaskMutation, Operation.UpdateRecurringTaskMutationVariables>(UpdateRecurringTaskDocument, options);
      }
export type UpdateRecurringTaskMutationHookResult = ReturnType<typeof useUpdateRecurringTaskMutation>;
export type UpdateRecurringTaskMutationResult = Apollo.MutationResult<Operation.UpdateRecurringTaskMutation>;
export type UpdateRecurringTaskMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateRecurringTaskMutation, Operation.UpdateRecurringTaskMutationVariables>;
export const CreateReferralDocument = gql`
    mutation CreateReferral($attributes: ReferralCreateInput!) {
  createReferral(input: {attributes: $attributes}) {
    success
    errors
    fieldErrors {
      field
      message
    }
    referral {
      ...referralDetailed
    }
  }
}
    ${ReferralDetailedFragmentDoc}`;
export type CreateReferralMutationFn = Apollo.MutationFunction<Operation.CreateReferralMutation, Operation.CreateReferralMutationVariables>;

/**
 * __useCreateReferralMutation__
 *
 * To run a mutation, you first call `useCreateReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferralMutation, { data, loading, error }] = useCreateReferralMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateReferralMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateReferralMutation, Operation.CreateReferralMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateReferralMutation, Operation.CreateReferralMutationVariables>(CreateReferralDocument, options);
      }
export type CreateReferralMutationHookResult = ReturnType<typeof useCreateReferralMutation>;
export type CreateReferralMutationResult = Apollo.MutationResult<Operation.CreateReferralMutation>;
export type CreateReferralMutationOptions = Apollo.BaseMutationOptions<Operation.CreateReferralMutation, Operation.CreateReferralMutationVariables>;
export const UpdateReferralDocument = gql`
    mutation UpdateReferral($input: UpdateReferralInput!) {
  updateReferral(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    referral {
      ...referralDetailed
    }
  }
}
    ${ReferralDetailedFragmentDoc}`;
export type UpdateReferralMutationFn = Apollo.MutationFunction<Operation.UpdateReferralMutation, Operation.UpdateReferralMutationVariables>;

/**
 * __useUpdateReferralMutation__
 *
 * To run a mutation, you first call `useUpdateReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralMutation, { data, loading, error }] = useUpdateReferralMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferralMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateReferralMutation, Operation.UpdateReferralMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateReferralMutation, Operation.UpdateReferralMutationVariables>(UpdateReferralDocument, options);
      }
export type UpdateReferralMutationHookResult = ReturnType<typeof useUpdateReferralMutation>;
export type UpdateReferralMutationResult = Apollo.MutationResult<Operation.UpdateReferralMutation>;
export type UpdateReferralMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateReferralMutation, Operation.UpdateReferralMutationVariables>;
export const TransitionReferralDocument = gql`
    mutation TransitionReferral($input: TransitionReferralInput!) {
  transitionReferral(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    referral {
      ...referralDetailed
    }
  }
}
    ${ReferralDetailedFragmentDoc}`;
export type TransitionReferralMutationFn = Apollo.MutationFunction<Operation.TransitionReferralMutation, Operation.TransitionReferralMutationVariables>;

/**
 * __useTransitionReferralMutation__
 *
 * To run a mutation, you first call `useTransitionReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionReferralMutation, { data, loading, error }] = useTransitionReferralMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransitionReferralMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionReferralMutation, Operation.TransitionReferralMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionReferralMutation, Operation.TransitionReferralMutationVariables>(TransitionReferralDocument, options);
      }
export type TransitionReferralMutationHookResult = ReturnType<typeof useTransitionReferralMutation>;
export type TransitionReferralMutationResult = Apollo.MutationResult<Operation.TransitionReferralMutation>;
export type TransitionReferralMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionReferralMutation, Operation.TransitionReferralMutationVariables>;
export const DiscardReferralDocument = gql`
    mutation DiscardReferral($input: DiscardReferralInput!) {
  discardReferral(input: $input) {
    success
    errors
    fieldErrors {
      field
      message
    }
    referral {
      ...referralDetailed
    }
  }
}
    ${ReferralDetailedFragmentDoc}`;
export type DiscardReferralMutationFn = Apollo.MutationFunction<Operation.DiscardReferralMutation, Operation.DiscardReferralMutationVariables>;

/**
 * __useDiscardReferralMutation__
 *
 * To run a mutation, you first call `useDiscardReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardReferralMutation, { data, loading, error }] = useDiscardReferralMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscardReferralMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DiscardReferralMutation, Operation.DiscardReferralMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DiscardReferralMutation, Operation.DiscardReferralMutationVariables>(DiscardReferralDocument, options);
      }
export type DiscardReferralMutationHookResult = ReturnType<typeof useDiscardReferralMutation>;
export type DiscardReferralMutationResult = Apollo.MutationResult<Operation.DiscardReferralMutation>;
export type DiscardReferralMutationOptions = Apollo.BaseMutationOptions<Operation.DiscardReferralMutation, Operation.DiscardReferralMutationVariables>;
export const CreateReferralSourceDocument = gql`
    mutation CreateReferralSource($name: String!, $categoryId: ID!) {
  createReferralSource(
    input: {attributes: {name: $name, categoryId: $categoryId}}
  ) {
    errors
    success
    fieldErrors {
      field
      message
    }
    referralSource {
      ...referralSourceEssentials
    }
  }
}
    ${ReferralSourceEssentialsFragmentDoc}`;
export type CreateReferralSourceMutationFn = Apollo.MutationFunction<Operation.CreateReferralSourceMutation, Operation.CreateReferralSourceMutationVariables>;

/**
 * __useCreateReferralSourceMutation__
 *
 * To run a mutation, you first call `useCreateReferralSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferralSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferralSourceMutation, { data, loading, error }] = useCreateReferralSourceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useCreateReferralSourceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateReferralSourceMutation, Operation.CreateReferralSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateReferralSourceMutation, Operation.CreateReferralSourceMutationVariables>(CreateReferralSourceDocument, options);
      }
export type CreateReferralSourceMutationHookResult = ReturnType<typeof useCreateReferralSourceMutation>;
export type CreateReferralSourceMutationResult = Apollo.MutationResult<Operation.CreateReferralSourceMutation>;
export type CreateReferralSourceMutationOptions = Apollo.BaseMutationOptions<Operation.CreateReferralSourceMutation, Operation.CreateReferralSourceMutationVariables>;
export const UpdateReferralSourceDocument = gql`
    mutation UpdateReferralSource($id: ID!, $name: String!, $categoryId: ID!) {
  updateReferralSource(
    input: {id: $id, attributes: {name: $name, categoryId: $categoryId}}
  ) {
    errors
    success
    fieldErrors {
      field
      message
    }
    referralSource {
      ...referralSourceEssentials
    }
  }
}
    ${ReferralSourceEssentialsFragmentDoc}`;
export type UpdateReferralSourceMutationFn = Apollo.MutationFunction<Operation.UpdateReferralSourceMutation, Operation.UpdateReferralSourceMutationVariables>;

/**
 * __useUpdateReferralSourceMutation__
 *
 * To run a mutation, you first call `useUpdateReferralSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralSourceMutation, { data, loading, error }] = useUpdateReferralSourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useUpdateReferralSourceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateReferralSourceMutation, Operation.UpdateReferralSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateReferralSourceMutation, Operation.UpdateReferralSourceMutationVariables>(UpdateReferralSourceDocument, options);
      }
export type UpdateReferralSourceMutationHookResult = ReturnType<typeof useUpdateReferralSourceMutation>;
export type UpdateReferralSourceMutationResult = Apollo.MutationResult<Operation.UpdateReferralSourceMutation>;
export type UpdateReferralSourceMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateReferralSourceMutation, Operation.UpdateReferralSourceMutationVariables>;
export const TransitionReferralSourceDocument = gql`
    mutation TransitionReferralSource($input: TransitionReferralSourceInput!) {
  transitionReferralSource(input: $input) {
    errors
    success
    fieldErrors {
      field
      message
    }
    referralSource {
      ...referralSourceEssentials
    }
  }
}
    ${ReferralSourceEssentialsFragmentDoc}`;
export type TransitionReferralSourceMutationFn = Apollo.MutationFunction<Operation.TransitionReferralSourceMutation, Operation.TransitionReferralSourceMutationVariables>;

/**
 * __useTransitionReferralSourceMutation__
 *
 * To run a mutation, you first call `useTransitionReferralSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionReferralSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionReferralSourceMutation, { data, loading, error }] = useTransitionReferralSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransitionReferralSourceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionReferralSourceMutation, Operation.TransitionReferralSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionReferralSourceMutation, Operation.TransitionReferralSourceMutationVariables>(TransitionReferralSourceDocument, options);
      }
export type TransitionReferralSourceMutationHookResult = ReturnType<typeof useTransitionReferralSourceMutation>;
export type TransitionReferralSourceMutationResult = Apollo.MutationResult<Operation.TransitionReferralSourceMutation>;
export type TransitionReferralSourceMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionReferralSourceMutation, Operation.TransitionReferralSourceMutationVariables>;
export const DiscardReferralSourceDocument = gql`
    mutation DiscardReferralSource($input: DiscardReferralSourceInput!) {
  discardReferralSource(input: $input) {
    errors
    success
    fieldErrors {
      field
      message
    }
    referralSource {
      ...referralSourceEssentials
    }
  }
}
    ${ReferralSourceEssentialsFragmentDoc}`;
export type DiscardReferralSourceMutationFn = Apollo.MutationFunction<Operation.DiscardReferralSourceMutation, Operation.DiscardReferralSourceMutationVariables>;

/**
 * __useDiscardReferralSourceMutation__
 *
 * To run a mutation, you first call `useDiscardReferralSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardReferralSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardReferralSourceMutation, { data, loading, error }] = useDiscardReferralSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscardReferralSourceMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DiscardReferralSourceMutation, Operation.DiscardReferralSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DiscardReferralSourceMutation, Operation.DiscardReferralSourceMutationVariables>(DiscardReferralSourceDocument, options);
      }
export type DiscardReferralSourceMutationHookResult = ReturnType<typeof useDiscardReferralSourceMutation>;
export type DiscardReferralSourceMutationResult = Apollo.MutationResult<Operation.DiscardReferralSourceMutation>;
export type DiscardReferralSourceMutationOptions = Apollo.BaseMutationOptions<Operation.DiscardReferralSourceMutation, Operation.DiscardReferralSourceMutationVariables>;
export const CreateReportsSavedReportDocument = gql`
    mutation CreateReportsSavedReport($input: CreateReportsSavedReportInput!) {
  createReportsSavedReport(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    reportsSavedReport {
      ...reportsSavedReportEssentials
    }
  }
}
    ${ReportsSavedReportEssentialsFragmentDoc}`;
export type CreateReportsSavedReportMutationFn = Apollo.MutationFunction<Operation.CreateReportsSavedReportMutation, Operation.CreateReportsSavedReportMutationVariables>;

/**
 * __useCreateReportsSavedReportMutation__
 *
 * To run a mutation, you first call `useCreateReportsSavedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportsSavedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportsSavedReportMutation, { data, loading, error }] = useCreateReportsSavedReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportsSavedReportMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateReportsSavedReportMutation, Operation.CreateReportsSavedReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateReportsSavedReportMutation, Operation.CreateReportsSavedReportMutationVariables>(CreateReportsSavedReportDocument, options);
      }
export type CreateReportsSavedReportMutationHookResult = ReturnType<typeof useCreateReportsSavedReportMutation>;
export type CreateReportsSavedReportMutationResult = Apollo.MutationResult<Operation.CreateReportsSavedReportMutation>;
export type CreateReportsSavedReportMutationOptions = Apollo.BaseMutationOptions<Operation.CreateReportsSavedReportMutation, Operation.CreateReportsSavedReportMutationVariables>;
export const UpdateReportsSavedReportDocument = gql`
    mutation UpdateReportsSavedReport($input: UpdateReportsSavedReportInput!) {
  updateReportsSavedReport(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    reportsSavedReport {
      ...reportsSavedReportEssentials
    }
  }
}
    ${ReportsSavedReportEssentialsFragmentDoc}`;
export type UpdateReportsSavedReportMutationFn = Apollo.MutationFunction<Operation.UpdateReportsSavedReportMutation, Operation.UpdateReportsSavedReportMutationVariables>;

/**
 * __useUpdateReportsSavedReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportsSavedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportsSavedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportsSavedReportMutation, { data, loading, error }] = useUpdateReportsSavedReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReportsSavedReportMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateReportsSavedReportMutation, Operation.UpdateReportsSavedReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateReportsSavedReportMutation, Operation.UpdateReportsSavedReportMutationVariables>(UpdateReportsSavedReportDocument, options);
      }
export type UpdateReportsSavedReportMutationHookResult = ReturnType<typeof useUpdateReportsSavedReportMutation>;
export type UpdateReportsSavedReportMutationResult = Apollo.MutationResult<Operation.UpdateReportsSavedReportMutation>;
export type UpdateReportsSavedReportMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateReportsSavedReportMutation, Operation.UpdateReportsSavedReportMutationVariables>;
export const CreateRoomDocument = gql`
    mutation CreateRoom($attributes: RoomInput!) {
  createRoom(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    room {
      ...roomEssentials
    }
  }
}
    ${RoomEssentialsFragmentDoc}`;
export type CreateRoomMutationFn = Apollo.MutationFunction<Operation.CreateRoomMutation, Operation.CreateRoomMutationVariables>;

/**
 * __useCreateRoomMutation__
 *
 * To run a mutation, you first call `useCreateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoomMutation, { data, loading, error }] = useCreateRoomMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateRoomMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateRoomMutation, Operation.CreateRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateRoomMutation, Operation.CreateRoomMutationVariables>(CreateRoomDocument, options);
      }
export type CreateRoomMutationHookResult = ReturnType<typeof useCreateRoomMutation>;
export type CreateRoomMutationResult = Apollo.MutationResult<Operation.CreateRoomMutation>;
export type CreateRoomMutationOptions = Apollo.BaseMutationOptions<Operation.CreateRoomMutation, Operation.CreateRoomMutationVariables>;
export const UpdateRoomDocument = gql`
    mutation UpdateRoom($id: ID!, $attributes: RoomInput!) {
  updateRoom(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    room {
      ...roomEssentials
    }
  }
}
    ${RoomEssentialsFragmentDoc}`;
export type UpdateRoomMutationFn = Apollo.MutationFunction<Operation.UpdateRoomMutation, Operation.UpdateRoomMutationVariables>;

/**
 * __useUpdateRoomMutation__
 *
 * To run a mutation, you first call `useUpdateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomMutation, { data, loading, error }] = useUpdateRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateRoomMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateRoomMutation, Operation.UpdateRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateRoomMutation, Operation.UpdateRoomMutationVariables>(UpdateRoomDocument, options);
      }
export type UpdateRoomMutationHookResult = ReturnType<typeof useUpdateRoomMutation>;
export type UpdateRoomMutationResult = Apollo.MutationResult<Operation.UpdateRoomMutation>;
export type UpdateRoomMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateRoomMutation, Operation.UpdateRoomMutationVariables>;
export const TransitionRoomStateDocument = gql`
    mutation TransitionRoomState($id: ID!, $event: RoomStateEvent!) {
  transitionRoomState(input: {id: $id, event: $event}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    room {
      ...roomEssentials
    }
  }
}
    ${RoomEssentialsFragmentDoc}`;
export type TransitionRoomStateMutationFn = Apollo.MutationFunction<Operation.TransitionRoomStateMutation, Operation.TransitionRoomStateMutationVariables>;

/**
 * __useTransitionRoomStateMutation__
 *
 * To run a mutation, you first call `useTransitionRoomStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionRoomStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionRoomStateMutation, { data, loading, error }] = useTransitionRoomStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionRoomStateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionRoomStateMutation, Operation.TransitionRoomStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionRoomStateMutation, Operation.TransitionRoomStateMutationVariables>(TransitionRoomStateDocument, options);
      }
export type TransitionRoomStateMutationHookResult = ReturnType<typeof useTransitionRoomStateMutation>;
export type TransitionRoomStateMutationResult = Apollo.MutationResult<Operation.TransitionRoomStateMutation>;
export type TransitionRoomStateMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionRoomStateMutation, Operation.TransitionRoomStateMutationVariables>;
export const CreateRosterEventDocument = gql`
    mutation CreateRosterEvent($connectionId: ID!, $input: EventInput!) {
  createRosterEvent(input: {connectionId: $connectionId, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    rosterEvent {
      ...rosterEventExhaustive
    }
  }
}
    ${RosterEventExhaustiveFragmentDoc}`;
export type CreateRosterEventMutationFn = Apollo.MutationFunction<Operation.CreateRosterEventMutation, Operation.CreateRosterEventMutationVariables>;

/**
 * __useCreateRosterEventMutation__
 *
 * To run a mutation, you first call `useCreateRosterEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRosterEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRosterEventMutation, { data, loading, error }] = useCreateRosterEventMutation({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRosterEventMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateRosterEventMutation, Operation.CreateRosterEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateRosterEventMutation, Operation.CreateRosterEventMutationVariables>(CreateRosterEventDocument, options);
      }
export type CreateRosterEventMutationHookResult = ReturnType<typeof useCreateRosterEventMutation>;
export type CreateRosterEventMutationResult = Apollo.MutationResult<Operation.CreateRosterEventMutation>;
export type CreateRosterEventMutationOptions = Apollo.BaseMutationOptions<Operation.CreateRosterEventMutation, Operation.CreateRosterEventMutationVariables>;
export const CreateShiftTypeDocument = gql`
    mutation CreateShiftType($input: ShiftTypeInput!) {
  createShiftType(input: {attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    shiftType {
      ...rosterShiftTypeExhaustive
    }
  }
}
    ${RosterShiftTypeExhaustiveFragmentDoc}`;
export type CreateShiftTypeMutationFn = Apollo.MutationFunction<Operation.CreateShiftTypeMutation, Operation.CreateShiftTypeMutationVariables>;

/**
 * __useCreateShiftTypeMutation__
 *
 * To run a mutation, you first call `useCreateShiftTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShiftTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShiftTypeMutation, { data, loading, error }] = useCreateShiftTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShiftTypeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateShiftTypeMutation, Operation.CreateShiftTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateShiftTypeMutation, Operation.CreateShiftTypeMutationVariables>(CreateShiftTypeDocument, options);
      }
export type CreateShiftTypeMutationHookResult = ReturnType<typeof useCreateShiftTypeMutation>;
export type CreateShiftTypeMutationResult = Apollo.MutationResult<Operation.CreateShiftTypeMutation>;
export type CreateShiftTypeMutationOptions = Apollo.BaseMutationOptions<Operation.CreateShiftTypeMutation, Operation.CreateShiftTypeMutationVariables>;
export const TransitionStateShiftTypeDocument = gql`
    mutation TransitionStateShiftType($id: ID!, $event: ShiftTypeStateEvent!) {
  transitionStateShiftType(input: {id: $id, event: $event}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    shiftType {
      ...rosterShiftTypeExhaustive
    }
  }
}
    ${RosterShiftTypeExhaustiveFragmentDoc}`;
export type TransitionStateShiftTypeMutationFn = Apollo.MutationFunction<Operation.TransitionStateShiftTypeMutation, Operation.TransitionStateShiftTypeMutationVariables>;

/**
 * __useTransitionStateShiftTypeMutation__
 *
 * To run a mutation, you first call `useTransitionStateShiftTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionStateShiftTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionStateShiftTypeMutation, { data, loading, error }] = useTransitionStateShiftTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionStateShiftTypeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionStateShiftTypeMutation, Operation.TransitionStateShiftTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionStateShiftTypeMutation, Operation.TransitionStateShiftTypeMutationVariables>(TransitionStateShiftTypeDocument, options);
      }
export type TransitionStateShiftTypeMutationHookResult = ReturnType<typeof useTransitionStateShiftTypeMutation>;
export type TransitionStateShiftTypeMutationResult = Apollo.MutationResult<Operation.TransitionStateShiftTypeMutation>;
export type TransitionStateShiftTypeMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionStateShiftTypeMutation, Operation.TransitionStateShiftTypeMutationVariables>;
export const UpdateShiftTypeDocument = gql`
    mutation UpdateShiftType($id: ID!, $input: ShiftTypeInput!) {
  updateShiftType(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    shiftType {
      ...rosterShiftTypeExhaustive
    }
  }
}
    ${RosterShiftTypeExhaustiveFragmentDoc}`;
export type UpdateShiftTypeMutationFn = Apollo.MutationFunction<Operation.UpdateShiftTypeMutation, Operation.UpdateShiftTypeMutationVariables>;

/**
 * __useUpdateShiftTypeMutation__
 *
 * To run a mutation, you first call `useUpdateShiftTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftTypeMutation, { data, loading, error }] = useUpdateShiftTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShiftTypeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateShiftTypeMutation, Operation.UpdateShiftTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateShiftTypeMutation, Operation.UpdateShiftTypeMutationVariables>(UpdateShiftTypeDocument, options);
      }
export type UpdateShiftTypeMutationHookResult = ReturnType<typeof useUpdateShiftTypeMutation>;
export type UpdateShiftTypeMutationResult = Apollo.MutationResult<Operation.UpdateShiftTypeMutation>;
export type UpdateShiftTypeMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateShiftTypeMutation, Operation.UpdateShiftTypeMutationVariables>;
export const TransitionStateSnippetDocument = gql`
    mutation TransitionStateSnippet($id: ID!, $event: SnippetStateEvent!) {
  transitionSnippetState(input: {id: $id, event: $event}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    snippet {
      ...snippetDetailed
    }
  }
}
    ${SnippetDetailedFragmentDoc}`;
export type TransitionStateSnippetMutationFn = Apollo.MutationFunction<Operation.TransitionStateSnippetMutation, Operation.TransitionStateSnippetMutationVariables>;

/**
 * __useTransitionStateSnippetMutation__
 *
 * To run a mutation, you first call `useTransitionStateSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionStateSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionStateSnippetMutation, { data, loading, error }] = useTransitionStateSnippetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionStateSnippetMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionStateSnippetMutation, Operation.TransitionStateSnippetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionStateSnippetMutation, Operation.TransitionStateSnippetMutationVariables>(TransitionStateSnippetDocument, options);
      }
export type TransitionStateSnippetMutationHookResult = ReturnType<typeof useTransitionStateSnippetMutation>;
export type TransitionStateSnippetMutationResult = Apollo.MutationResult<Operation.TransitionStateSnippetMutation>;
export type TransitionStateSnippetMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionStateSnippetMutation, Operation.TransitionStateSnippetMutationVariables>;
export const CreateSterilisationAutoclaveDocument = gql`
    mutation CreateSterilisationAutoclave($input: CreateSterilisationAutoclaveInput!) {
  createSterilisationAutoclave(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    sterilisationAutoclave {
      ...sterilisationAutoclaveDetailed
    }
  }
}
    ${SterilisationAutoclaveDetailedFragmentDoc}`;
export type CreateSterilisationAutoclaveMutationFn = Apollo.MutationFunction<Operation.CreateSterilisationAutoclaveMutation, Operation.CreateSterilisationAutoclaveMutationVariables>;

/**
 * __useCreateSterilisationAutoclaveMutation__
 *
 * To run a mutation, you first call `useCreateSterilisationAutoclaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSterilisationAutoclaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSterilisationAutoclaveMutation, { data, loading, error }] = useCreateSterilisationAutoclaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSterilisationAutoclaveMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateSterilisationAutoclaveMutation, Operation.CreateSterilisationAutoclaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateSterilisationAutoclaveMutation, Operation.CreateSterilisationAutoclaveMutationVariables>(CreateSterilisationAutoclaveDocument, options);
      }
export type CreateSterilisationAutoclaveMutationHookResult = ReturnType<typeof useCreateSterilisationAutoclaveMutation>;
export type CreateSterilisationAutoclaveMutationResult = Apollo.MutationResult<Operation.CreateSterilisationAutoclaveMutation>;
export type CreateSterilisationAutoclaveMutationOptions = Apollo.BaseMutationOptions<Operation.CreateSterilisationAutoclaveMutation, Operation.CreateSterilisationAutoclaveMutationVariables>;
export const UpdateSterilisationAutoclaveDocument = gql`
    mutation UpdateSterilisationAutoclave($input: UpdateSterilisationAutoclaveInput!) {
  updateSterilisationAutoclave(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    sterilisationAutoclave {
      ...sterilisationAutoclaveDetailed
    }
  }
}
    ${SterilisationAutoclaveDetailedFragmentDoc}`;
export type UpdateSterilisationAutoclaveMutationFn = Apollo.MutationFunction<Operation.UpdateSterilisationAutoclaveMutation, Operation.UpdateSterilisationAutoclaveMutationVariables>;

/**
 * __useUpdateSterilisationAutoclaveMutation__
 *
 * To run a mutation, you first call `useUpdateSterilisationAutoclaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSterilisationAutoclaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSterilisationAutoclaveMutation, { data, loading, error }] = useUpdateSterilisationAutoclaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSterilisationAutoclaveMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateSterilisationAutoclaveMutation, Operation.UpdateSterilisationAutoclaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateSterilisationAutoclaveMutation, Operation.UpdateSterilisationAutoclaveMutationVariables>(UpdateSterilisationAutoclaveDocument, options);
      }
export type UpdateSterilisationAutoclaveMutationHookResult = ReturnType<typeof useUpdateSterilisationAutoclaveMutation>;
export type UpdateSterilisationAutoclaveMutationResult = Apollo.MutationResult<Operation.UpdateSterilisationAutoclaveMutation>;
export type UpdateSterilisationAutoclaveMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateSterilisationAutoclaveMutation, Operation.UpdateSterilisationAutoclaveMutationVariables>;
export const DestroySterilisationAutoclaveDocument = gql`
    mutation DestroySterilisationAutoclave($input: DestroySterilisationAutoclaveInput!) {
  destroySterilisationAutoclave(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    sterilisationAutoclave {
      ...sterilisationAutoclaveDetailed
    }
  }
}
    ${SterilisationAutoclaveDetailedFragmentDoc}`;
export type DestroySterilisationAutoclaveMutationFn = Apollo.MutationFunction<Operation.DestroySterilisationAutoclaveMutation, Operation.DestroySterilisationAutoclaveMutationVariables>;

/**
 * __useDestroySterilisationAutoclaveMutation__
 *
 * To run a mutation, you first call `useDestroySterilisationAutoclaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroySterilisationAutoclaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroySterilisationAutoclaveMutation, { data, loading, error }] = useDestroySterilisationAutoclaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroySterilisationAutoclaveMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DestroySterilisationAutoclaveMutation, Operation.DestroySterilisationAutoclaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DestroySterilisationAutoclaveMutation, Operation.DestroySterilisationAutoclaveMutationVariables>(DestroySterilisationAutoclaveDocument, options);
      }
export type DestroySterilisationAutoclaveMutationHookResult = ReturnType<typeof useDestroySterilisationAutoclaveMutation>;
export type DestroySterilisationAutoclaveMutationResult = Apollo.MutationResult<Operation.DestroySterilisationAutoclaveMutation>;
export type DestroySterilisationAutoclaveMutationOptions = Apollo.BaseMutationOptions<Operation.DestroySterilisationAutoclaveMutation, Operation.DestroySterilisationAutoclaveMutationVariables>;
export const CreateSterilisationCycleTypeDocument = gql`
    mutation CreateSterilisationCycleType($input: CreateSterilisationCycleTypeInput!) {
  createSterilisationCycleType(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    sterilisationCycleType {
      ...sterilisationCycleTypeDetailed
    }
  }
}
    ${SterilisationCycleTypeDetailedFragmentDoc}`;
export type CreateSterilisationCycleTypeMutationFn = Apollo.MutationFunction<Operation.CreateSterilisationCycleTypeMutation, Operation.CreateSterilisationCycleTypeMutationVariables>;

/**
 * __useCreateSterilisationCycleTypeMutation__
 *
 * To run a mutation, you first call `useCreateSterilisationCycleTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSterilisationCycleTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSterilisationCycleTypeMutation, { data, loading, error }] = useCreateSterilisationCycleTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSterilisationCycleTypeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateSterilisationCycleTypeMutation, Operation.CreateSterilisationCycleTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateSterilisationCycleTypeMutation, Operation.CreateSterilisationCycleTypeMutationVariables>(CreateSterilisationCycleTypeDocument, options);
      }
export type CreateSterilisationCycleTypeMutationHookResult = ReturnType<typeof useCreateSterilisationCycleTypeMutation>;
export type CreateSterilisationCycleTypeMutationResult = Apollo.MutationResult<Operation.CreateSterilisationCycleTypeMutation>;
export type CreateSterilisationCycleTypeMutationOptions = Apollo.BaseMutationOptions<Operation.CreateSterilisationCycleTypeMutation, Operation.CreateSterilisationCycleTypeMutationVariables>;
export const UpdateSterilisationCycleTypeDocument = gql`
    mutation UpdateSterilisationCycleType($input: UpdateSterilisationCycleTypeInput!) {
  updateSterilisationCycleType(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    sterilisationCycleType {
      ...sterilisationCycleTypeDetailed
    }
  }
}
    ${SterilisationCycleTypeDetailedFragmentDoc}`;
export type UpdateSterilisationCycleTypeMutationFn = Apollo.MutationFunction<Operation.UpdateSterilisationCycleTypeMutation, Operation.UpdateSterilisationCycleTypeMutationVariables>;

/**
 * __useUpdateSterilisationCycleTypeMutation__
 *
 * To run a mutation, you first call `useUpdateSterilisationCycleTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSterilisationCycleTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSterilisationCycleTypeMutation, { data, loading, error }] = useUpdateSterilisationCycleTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSterilisationCycleTypeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateSterilisationCycleTypeMutation, Operation.UpdateSterilisationCycleTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateSterilisationCycleTypeMutation, Operation.UpdateSterilisationCycleTypeMutationVariables>(UpdateSterilisationCycleTypeDocument, options);
      }
export type UpdateSterilisationCycleTypeMutationHookResult = ReturnType<typeof useUpdateSterilisationCycleTypeMutation>;
export type UpdateSterilisationCycleTypeMutationResult = Apollo.MutationResult<Operation.UpdateSterilisationCycleTypeMutation>;
export type UpdateSterilisationCycleTypeMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateSterilisationCycleTypeMutation, Operation.UpdateSterilisationCycleTypeMutationVariables>;
export const DestroySterilisationCycleTypeDocument = gql`
    mutation DestroySterilisationCycleType($input: DestroySterilisationCycleTypeInput!) {
  destroySterilisationCycleType(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    sterilisationCycleType {
      ...sterilisationCycleTypeDetailed
    }
  }
}
    ${SterilisationCycleTypeDetailedFragmentDoc}`;
export type DestroySterilisationCycleTypeMutationFn = Apollo.MutationFunction<Operation.DestroySterilisationCycleTypeMutation, Operation.DestroySterilisationCycleTypeMutationVariables>;

/**
 * __useDestroySterilisationCycleTypeMutation__
 *
 * To run a mutation, you first call `useDestroySterilisationCycleTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroySterilisationCycleTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroySterilisationCycleTypeMutation, { data, loading, error }] = useDestroySterilisationCycleTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroySterilisationCycleTypeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DestroySterilisationCycleTypeMutation, Operation.DestroySterilisationCycleTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DestroySterilisationCycleTypeMutation, Operation.DestroySterilisationCycleTypeMutationVariables>(DestroySterilisationCycleTypeDocument, options);
      }
export type DestroySterilisationCycleTypeMutationHookResult = ReturnType<typeof useDestroySterilisationCycleTypeMutation>;
export type DestroySterilisationCycleTypeMutationResult = Apollo.MutationResult<Operation.DestroySterilisationCycleTypeMutation>;
export type DestroySterilisationCycleTypeMutationOptions = Apollo.BaseMutationOptions<Operation.DestroySterilisationCycleTypeMutation, Operation.DestroySterilisationCycleTypeMutationVariables>;
export const CreateSterilisationCycleDocument = gql`
    mutation CreateSterilisationCycle($input: CreateSterilisationCycleInput!) {
  createSterilisationCycle(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    sterilisationCycle {
      ...sterilisationCycleDetailed
    }
  }
}
    ${SterilisationCycleDetailedFragmentDoc}`;
export type CreateSterilisationCycleMutationFn = Apollo.MutationFunction<Operation.CreateSterilisationCycleMutation, Operation.CreateSterilisationCycleMutationVariables>;

/**
 * __useCreateSterilisationCycleMutation__
 *
 * To run a mutation, you first call `useCreateSterilisationCycleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSterilisationCycleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSterilisationCycleMutation, { data, loading, error }] = useCreateSterilisationCycleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSterilisationCycleMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateSterilisationCycleMutation, Operation.CreateSterilisationCycleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateSterilisationCycleMutation, Operation.CreateSterilisationCycleMutationVariables>(CreateSterilisationCycleDocument, options);
      }
export type CreateSterilisationCycleMutationHookResult = ReturnType<typeof useCreateSterilisationCycleMutation>;
export type CreateSterilisationCycleMutationResult = Apollo.MutationResult<Operation.CreateSterilisationCycleMutation>;
export type CreateSterilisationCycleMutationOptions = Apollo.BaseMutationOptions<Operation.CreateSterilisationCycleMutation, Operation.CreateSterilisationCycleMutationVariables>;
export const UpdateSterilisationCycleDocument = gql`
    mutation UpdateSterilisationCycle($input: UpdateSterilisationCycleInput!) {
  updateSterilisationCycle(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    sterilisationCycle {
      ...sterilisationCycleDetailed
    }
  }
}
    ${SterilisationCycleDetailedFragmentDoc}`;
export type UpdateSterilisationCycleMutationFn = Apollo.MutationFunction<Operation.UpdateSterilisationCycleMutation, Operation.UpdateSterilisationCycleMutationVariables>;

/**
 * __useUpdateSterilisationCycleMutation__
 *
 * To run a mutation, you first call `useUpdateSterilisationCycleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSterilisationCycleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSterilisationCycleMutation, { data, loading, error }] = useUpdateSterilisationCycleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSterilisationCycleMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateSterilisationCycleMutation, Operation.UpdateSterilisationCycleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateSterilisationCycleMutation, Operation.UpdateSterilisationCycleMutationVariables>(UpdateSterilisationCycleDocument, options);
      }
export type UpdateSterilisationCycleMutationHookResult = ReturnType<typeof useUpdateSterilisationCycleMutation>;
export type UpdateSterilisationCycleMutationResult = Apollo.MutationResult<Operation.UpdateSterilisationCycleMutation>;
export type UpdateSterilisationCycleMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateSterilisationCycleMutation, Operation.UpdateSterilisationCycleMutationVariables>;
export const CreateSterilisationTrackingDocument = gql`
    mutation CreateSterilisationTracking($input: CreateSterilisationTrackingInput!) {
  createSterilisationTracking(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    sterilisationTracking {
      ...sterilisationTrackingDetailed
    }
  }
}
    ${SterilisationTrackingDetailedFragmentDoc}`;
export type CreateSterilisationTrackingMutationFn = Apollo.MutationFunction<Operation.CreateSterilisationTrackingMutation, Operation.CreateSterilisationTrackingMutationVariables>;

/**
 * __useCreateSterilisationTrackingMutation__
 *
 * To run a mutation, you first call `useCreateSterilisationTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSterilisationTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSterilisationTrackingMutation, { data, loading, error }] = useCreateSterilisationTrackingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSterilisationTrackingMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateSterilisationTrackingMutation, Operation.CreateSterilisationTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateSterilisationTrackingMutation, Operation.CreateSterilisationTrackingMutationVariables>(CreateSterilisationTrackingDocument, options);
      }
export type CreateSterilisationTrackingMutationHookResult = ReturnType<typeof useCreateSterilisationTrackingMutation>;
export type CreateSterilisationTrackingMutationResult = Apollo.MutationResult<Operation.CreateSterilisationTrackingMutation>;
export type CreateSterilisationTrackingMutationOptions = Apollo.BaseMutationOptions<Operation.CreateSterilisationTrackingMutation, Operation.CreateSterilisationTrackingMutationVariables>;
export const UpdateSterilisationTrackingDocument = gql`
    mutation UpdateSterilisationTracking($input: UpdateSterilisationTrackingInput!) {
  updateSterilisationTracking(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    sterilisationTracking {
      ...sterilisationTrackingDetailed
    }
  }
}
    ${SterilisationTrackingDetailedFragmentDoc}`;
export type UpdateSterilisationTrackingMutationFn = Apollo.MutationFunction<Operation.UpdateSterilisationTrackingMutation, Operation.UpdateSterilisationTrackingMutationVariables>;

/**
 * __useUpdateSterilisationTrackingMutation__
 *
 * To run a mutation, you first call `useUpdateSterilisationTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSterilisationTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSterilisationTrackingMutation, { data, loading, error }] = useUpdateSterilisationTrackingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSterilisationTrackingMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateSterilisationTrackingMutation, Operation.UpdateSterilisationTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateSterilisationTrackingMutation, Operation.UpdateSterilisationTrackingMutationVariables>(UpdateSterilisationTrackingDocument, options);
      }
export type UpdateSterilisationTrackingMutationHookResult = ReturnType<typeof useUpdateSterilisationTrackingMutation>;
export type UpdateSterilisationTrackingMutationResult = Apollo.MutationResult<Operation.UpdateSterilisationTrackingMutation>;
export type UpdateSterilisationTrackingMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateSterilisationTrackingMutation, Operation.UpdateSterilisationTrackingMutationVariables>;
export const DestroySterilisationTrackingDocument = gql`
    mutation DestroySterilisationTracking($input: DestroySterilisationTrackingInput!) {
  destroySterilisationTracking(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    sterilisationTracking {
      ...sterilisationTrackingDetailed
    }
  }
}
    ${SterilisationTrackingDetailedFragmentDoc}`;
export type DestroySterilisationTrackingMutationFn = Apollo.MutationFunction<Operation.DestroySterilisationTrackingMutation, Operation.DestroySterilisationTrackingMutationVariables>;

/**
 * __useDestroySterilisationTrackingMutation__
 *
 * To run a mutation, you first call `useDestroySterilisationTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroySterilisationTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroySterilisationTrackingMutation, { data, loading, error }] = useDestroySterilisationTrackingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDestroySterilisationTrackingMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DestroySterilisationTrackingMutation, Operation.DestroySterilisationTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DestroySterilisationTrackingMutation, Operation.DestroySterilisationTrackingMutationVariables>(DestroySterilisationTrackingDocument, options);
      }
export type DestroySterilisationTrackingMutationHookResult = ReturnType<typeof useDestroySterilisationTrackingMutation>;
export type DestroySterilisationTrackingMutationResult = Apollo.MutationResult<Operation.DestroySterilisationTrackingMutation>;
export type DestroySterilisationTrackingMutationOptions = Apollo.BaseMutationOptions<Operation.DestroySterilisationTrackingMutation, Operation.DestroySterilisationTrackingMutationVariables>;
export const AcceptDocument = gql`
    mutation Accept($input: AcceptTreatmentPlanInput!) {
  acceptTreatmentPlan(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    treatmentPlan {
      ...treatmentPlanEssentials
    }
  }
}
    ${TreatmentPlanEssentialsFragmentDoc}`;
export type AcceptMutationFn = Apollo.MutationFunction<Operation.AcceptMutation, Operation.AcceptMutationVariables>;

/**
 * __useAcceptMutation__
 *
 * To run a mutation, you first call `useAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMutation, { data, loading, error }] = useAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AcceptMutation, Operation.AcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AcceptMutation, Operation.AcceptMutationVariables>(AcceptDocument, options);
      }
export type AcceptMutationHookResult = ReturnType<typeof useAcceptMutation>;
export type AcceptMutationResult = Apollo.MutationResult<Operation.AcceptMutation>;
export type AcceptMutationOptions = Apollo.BaseMutationOptions<Operation.AcceptMutation, Operation.AcceptMutationVariables>;
export const CreateTreatmentPlanDocument = gql`
    mutation CreateTreatmentPlan($input: CreateTreatmentPlanInput!) {
  createTreatmentPlan(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    treatmentPlan {
      ...treatmentPlanEssentials
    }
  }
}
    ${TreatmentPlanEssentialsFragmentDoc}`;
export type CreateTreatmentPlanMutationFn = Apollo.MutationFunction<Operation.CreateTreatmentPlanMutation, Operation.CreateTreatmentPlanMutationVariables>;

/**
 * __useCreateTreatmentPlanMutation__
 *
 * To run a mutation, you first call `useCreateTreatmentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTreatmentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTreatmentPlanMutation, { data, loading, error }] = useCreateTreatmentPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTreatmentPlanMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateTreatmentPlanMutation, Operation.CreateTreatmentPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateTreatmentPlanMutation, Operation.CreateTreatmentPlanMutationVariables>(CreateTreatmentPlanDocument, options);
      }
export type CreateTreatmentPlanMutationHookResult = ReturnType<typeof useCreateTreatmentPlanMutation>;
export type CreateTreatmentPlanMutationResult = Apollo.MutationResult<Operation.CreateTreatmentPlanMutation>;
export type CreateTreatmentPlanMutationOptions = Apollo.BaseMutationOptions<Operation.CreateTreatmentPlanMutation, Operation.CreateTreatmentPlanMutationVariables>;
export const EmailTreatmentPlanDocument = gql`
    mutation EmailTreatmentPlan($input: EmailTreatmentPlanInput!) {
  emailTreatmentPlan(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    treatmentPlan {
      ...treatmentPlanEssentials
    }
  }
}
    ${TreatmentPlanEssentialsFragmentDoc}`;
export type EmailTreatmentPlanMutationFn = Apollo.MutationFunction<Operation.EmailTreatmentPlanMutation, Operation.EmailTreatmentPlanMutationVariables>;

/**
 * __useEmailTreatmentPlanMutation__
 *
 * To run a mutation, you first call `useEmailTreatmentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailTreatmentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailTreatmentPlanMutation, { data, loading, error }] = useEmailTreatmentPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailTreatmentPlanMutation(baseOptions?: Apollo.MutationHookOptions<Operation.EmailTreatmentPlanMutation, Operation.EmailTreatmentPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.EmailTreatmentPlanMutation, Operation.EmailTreatmentPlanMutationVariables>(EmailTreatmentPlanDocument, options);
      }
export type EmailTreatmentPlanMutationHookResult = ReturnType<typeof useEmailTreatmentPlanMutation>;
export type EmailTreatmentPlanMutationResult = Apollo.MutationResult<Operation.EmailTreatmentPlanMutation>;
export type EmailTreatmentPlanMutationOptions = Apollo.BaseMutationOptions<Operation.EmailTreatmentPlanMutation, Operation.EmailTreatmentPlanMutationVariables>;
export const RejectTreatmentPlanDocument = gql`
    mutation RejectTreatmentPlan($input: RejectTreatmentPlanInput!) {
  rejectTreatmentPlan(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    treatmentPlan {
      ...treatmentPlanEssentials
    }
  }
}
    ${TreatmentPlanEssentialsFragmentDoc}`;
export type RejectTreatmentPlanMutationFn = Apollo.MutationFunction<Operation.RejectTreatmentPlanMutation, Operation.RejectTreatmentPlanMutationVariables>;

/**
 * __useRejectTreatmentPlanMutation__
 *
 * To run a mutation, you first call `useRejectTreatmentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectTreatmentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectTreatmentPlanMutation, { data, loading, error }] = useRejectTreatmentPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectTreatmentPlanMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RejectTreatmentPlanMutation, Operation.RejectTreatmentPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RejectTreatmentPlanMutation, Operation.RejectTreatmentPlanMutationVariables>(RejectTreatmentPlanDocument, options);
      }
export type RejectTreatmentPlanMutationHookResult = ReturnType<typeof useRejectTreatmentPlanMutation>;
export type RejectTreatmentPlanMutationResult = Apollo.MutationResult<Operation.RejectTreatmentPlanMutation>;
export type RejectTreatmentPlanMutationOptions = Apollo.BaseMutationOptions<Operation.RejectTreatmentPlanMutation, Operation.RejectTreatmentPlanMutationVariables>;
export const TransitionTreatmentPlanStateDocument = gql`
    mutation TransitionTreatmentPlanState($id: ID!, $event: TreatmentPlanStateEvent!) {
  transitionTreatmentPlanState(input: {id: $id, event: $event}) {
    fieldErrors {
      field
      message
    }
    errors
    treatmentPlan {
      ...treatmentPlanDetailed
    }
  }
}
    ${TreatmentPlanDetailedFragmentDoc}`;
export type TransitionTreatmentPlanStateMutationFn = Apollo.MutationFunction<Operation.TransitionTreatmentPlanStateMutation, Operation.TransitionTreatmentPlanStateMutationVariables>;

/**
 * __useTransitionTreatmentPlanStateMutation__
 *
 * To run a mutation, you first call `useTransitionTreatmentPlanStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionTreatmentPlanStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionTreatmentPlanStateMutation, { data, loading, error }] = useTransitionTreatmentPlanStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionTreatmentPlanStateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionTreatmentPlanStateMutation, Operation.TransitionTreatmentPlanStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionTreatmentPlanStateMutation, Operation.TransitionTreatmentPlanStateMutationVariables>(TransitionTreatmentPlanStateDocument, options);
      }
export type TransitionTreatmentPlanStateMutationHookResult = ReturnType<typeof useTransitionTreatmentPlanStateMutation>;
export type TransitionTreatmentPlanStateMutationResult = Apollo.MutationResult<Operation.TransitionTreatmentPlanStateMutation>;
export type TransitionTreatmentPlanStateMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionTreatmentPlanStateMutation, Operation.TransitionTreatmentPlanStateMutationVariables>;
export const UpdateTreatmentPlanDocument = gql`
    mutation UpdateTreatmentPlan($input: UpdateTreatmentPlanInput!) {
  updateTreatmentPlan(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    treatmentPlan {
      ...treatmentPlanEssentials
    }
  }
}
    ${TreatmentPlanEssentialsFragmentDoc}`;
export type UpdateTreatmentPlanMutationFn = Apollo.MutationFunction<Operation.UpdateTreatmentPlanMutation, Operation.UpdateTreatmentPlanMutationVariables>;

/**
 * __useUpdateTreatmentPlanMutation__
 *
 * To run a mutation, you first call `useUpdateTreatmentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreatmentPlanMutation, { data, loading, error }] = useUpdateTreatmentPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTreatmentPlanMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateTreatmentPlanMutation, Operation.UpdateTreatmentPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateTreatmentPlanMutation, Operation.UpdateTreatmentPlanMutationVariables>(UpdateTreatmentPlanDocument, options);
      }
export type UpdateTreatmentPlanMutationHookResult = ReturnType<typeof useUpdateTreatmentPlanMutation>;
export type UpdateTreatmentPlanMutationResult = Apollo.MutationResult<Operation.UpdateTreatmentPlanMutation>;
export type UpdateTreatmentPlanMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateTreatmentPlanMutation, Operation.UpdateTreatmentPlanMutationVariables>;
export const DiscardTreatmentPlanDocument = gql`
    mutation DiscardTreatmentPlan($input: DiscardTreatmentPlanInput!) {
  discardTreatmentPlan(input: $input) {
    success
    errors
    fieldErrors {
      field
      message
    }
    treatmentPlan {
      ...treatmentPlanEssentials
    }
  }
}
    ${TreatmentPlanEssentialsFragmentDoc}`;
export type DiscardTreatmentPlanMutationFn = Apollo.MutationFunction<Operation.DiscardTreatmentPlanMutation, Operation.DiscardTreatmentPlanMutationVariables>;

/**
 * __useDiscardTreatmentPlanMutation__
 *
 * To run a mutation, you first call `useDiscardTreatmentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardTreatmentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardTreatmentPlanMutation, { data, loading, error }] = useDiscardTreatmentPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscardTreatmentPlanMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DiscardTreatmentPlanMutation, Operation.DiscardTreatmentPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DiscardTreatmentPlanMutation, Operation.DiscardTreatmentPlanMutationVariables>(DiscardTreatmentPlanDocument, options);
      }
export type DiscardTreatmentPlanMutationHookResult = ReturnType<typeof useDiscardTreatmentPlanMutation>;
export type DiscardTreatmentPlanMutationResult = Apollo.MutationResult<Operation.DiscardTreatmentPlanMutation>;
export type DiscardTreatmentPlanMutationOptions = Apollo.BaseMutationOptions<Operation.DiscardTreatmentPlanMutation, Operation.DiscardTreatmentPlanMutationVariables>;
export const CreateTreatmentDocument = gql`
    mutation CreateTreatment($input: CreateTreatmentInput!) {
  createTreatment(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    treatment {
      ...treatmentDetailed
    }
  }
}
    ${TreatmentDetailedFragmentDoc}`;
export type CreateTreatmentMutationFn = Apollo.MutationFunction<Operation.CreateTreatmentMutation, Operation.CreateTreatmentMutationVariables>;

/**
 * __useCreateTreatmentMutation__
 *
 * To run a mutation, you first call `useCreateTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTreatmentMutation, { data, loading, error }] = useCreateTreatmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTreatmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateTreatmentMutation, Operation.CreateTreatmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateTreatmentMutation, Operation.CreateTreatmentMutationVariables>(CreateTreatmentDocument, options);
      }
export type CreateTreatmentMutationHookResult = ReturnType<typeof useCreateTreatmentMutation>;
export type CreateTreatmentMutationResult = Apollo.MutationResult<Operation.CreateTreatmentMutation>;
export type CreateTreatmentMutationOptions = Apollo.BaseMutationOptions<Operation.CreateTreatmentMutation, Operation.CreateTreatmentMutationVariables>;
export const TransitionTreatmentStateDocument = gql`
    mutation TransitionTreatmentState($id: ID!, $event: TreatmentStateEvent!) {
  transitionTreatmentState(input: {id: $id, event: $event}) {
    fieldErrors {
      field
      message
    }
    errors
    success
    treatment {
      ...treatmentDetailed
    }
  }
}
    ${TreatmentDetailedFragmentDoc}`;
export type TransitionTreatmentStateMutationFn = Apollo.MutationFunction<Operation.TransitionTreatmentStateMutation, Operation.TransitionTreatmentStateMutationVariables>;

/**
 * __useTransitionTreatmentStateMutation__
 *
 * To run a mutation, you first call `useTransitionTreatmentStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionTreatmentStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionTreatmentStateMutation, { data, loading, error }] = useTransitionTreatmentStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionTreatmentStateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionTreatmentStateMutation, Operation.TransitionTreatmentStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionTreatmentStateMutation, Operation.TransitionTreatmentStateMutationVariables>(TransitionTreatmentStateDocument, options);
      }
export type TransitionTreatmentStateMutationHookResult = ReturnType<typeof useTransitionTreatmentStateMutation>;
export type TransitionTreatmentStateMutationResult = Apollo.MutationResult<Operation.TransitionTreatmentStateMutation>;
export type TransitionTreatmentStateMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionTreatmentStateMutation, Operation.TransitionTreatmentStateMutationVariables>;
export const UpdateTreatmentDocument = gql`
    mutation UpdateTreatment($input: UpdateTreatmentInput!) {
  updateTreatment(input: $input) {
    errors
    fieldErrors {
      field
      message
    }
    success
    treatment {
      ...treatmentDetailed
    }
  }
}
    ${TreatmentDetailedFragmentDoc}`;
export type UpdateTreatmentMutationFn = Apollo.MutationFunction<Operation.UpdateTreatmentMutation, Operation.UpdateTreatmentMutationVariables>;

/**
 * __useUpdateTreatmentMutation__
 *
 * To run a mutation, you first call `useUpdateTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreatmentMutation, { data, loading, error }] = useUpdateTreatmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTreatmentMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateTreatmentMutation, Operation.UpdateTreatmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateTreatmentMutation, Operation.UpdateTreatmentMutationVariables>(UpdateTreatmentDocument, options);
      }
export type UpdateTreatmentMutationHookResult = ReturnType<typeof useUpdateTreatmentMutation>;
export type UpdateTreatmentMutationResult = Apollo.MutationResult<Operation.UpdateTreatmentMutation>;
export type UpdateTreatmentMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateTreatmentMutation, Operation.UpdateTreatmentMutationVariables>;
export const AddAccountCreditsDocument = gql`
    mutation AddAccountCredits($id: ID!, $attributes: AccountCreditInput!) {
  updateUserAccountCredit(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    patientRecordCredit {
      ...patientCreditHistoryEssentials
    }
  }
}
    ${PatientCreditHistoryEssentialsFragmentDoc}`;
export type AddAccountCreditsMutationFn = Apollo.MutationFunction<Operation.AddAccountCreditsMutation, Operation.AddAccountCreditsMutationVariables>;

/**
 * __useAddAccountCreditsMutation__
 *
 * To run a mutation, you first call `useAddAccountCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountCreditsMutation, { data, loading, error }] = useAddAccountCreditsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useAddAccountCreditsMutation(baseOptions?: Apollo.MutationHookOptions<Operation.AddAccountCreditsMutation, Operation.AddAccountCreditsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.AddAccountCreditsMutation, Operation.AddAccountCreditsMutationVariables>(AddAccountCreditsDocument, options);
      }
export type AddAccountCreditsMutationHookResult = ReturnType<typeof useAddAccountCreditsMutation>;
export type AddAccountCreditsMutationResult = Apollo.MutationResult<Operation.AddAccountCreditsMutation>;
export type AddAccountCreditsMutationOptions = Apollo.BaseMutationOptions<Operation.AddAccountCreditsMutation, Operation.AddAccountCreditsMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($attributes: ChangePasswordAttributes!) {
  changePassword(input: {attributes: $attributes}) {
    success
    fieldErrors {
      field
      message
    }
    errors
    user {
      ...userEssentials
    }
  }
}
    ${UserEssentialsFragmentDoc}`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<Operation.ChangePasswordMutation, Operation.ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ChangePasswordMutation, Operation.ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ChangePasswordMutation, Operation.ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<Operation.ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<Operation.ChangePasswordMutation, Operation.ChangePasswordMutationVariables>;
export const CopyPatientToPracticeDocument = gql`
    mutation CopyPatientToPractice($id: ID!, $practice: ID!) {
  copyPatientToPractice(input: {id: $id, practice: $practice}) {
    fieldErrors {
      field
      message
    }
    errors
    userPracticeConnection {
      ...userPracticeConnectionDetailed
    }
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;
export type CopyPatientToPracticeMutationFn = Apollo.MutationFunction<Operation.CopyPatientToPracticeMutation, Operation.CopyPatientToPracticeMutationVariables>;

/**
 * __useCopyPatientToPracticeMutation__
 *
 * To run a mutation, you first call `useCopyPatientToPracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyPatientToPracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyPatientToPracticeMutation, { data, loading, error }] = useCopyPatientToPracticeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      practice: // value for 'practice'
 *   },
 * });
 */
export function useCopyPatientToPracticeMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CopyPatientToPracticeMutation, Operation.CopyPatientToPracticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CopyPatientToPracticeMutation, Operation.CopyPatientToPracticeMutationVariables>(CopyPatientToPracticeDocument, options);
      }
export type CopyPatientToPracticeMutationHookResult = ReturnType<typeof useCopyPatientToPracticeMutation>;
export type CopyPatientToPracticeMutationResult = Apollo.MutationResult<Operation.CopyPatientToPracticeMutation>;
export type CopyPatientToPracticeMutationOptions = Apollo.BaseMutationOptions<Operation.CopyPatientToPracticeMutation, Operation.CopyPatientToPracticeMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($attributes: UserCreateInput!, $allowDuplicateEmail: Boolean) {
  createUser(
    input: {attributes: $attributes, allowDuplicateEmail: $allowDuplicateEmail}
  ) {
    errors
    fieldErrors {
      field
      message
    }
    userPracticeConnections {
      ...userPracticeConnectionEssentials
    }
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<Operation.CreateUserMutation, Operation.CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      allowDuplicateEmail: // value for 'allowDuplicateEmail'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateUserMutation, Operation.CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateUserMutation, Operation.CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<Operation.CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<Operation.CreateUserMutation, Operation.CreateUserMutationVariables>;
export const DeactivateAccountUserDocument = gql`
    mutation DeactivateAccountUser {
  deactivateAccountUser(input: {}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    accountUser {
      role
    }
  }
}
    `;
export type DeactivateAccountUserMutationFn = Apollo.MutationFunction<Operation.DeactivateAccountUserMutation, Operation.DeactivateAccountUserMutationVariables>;

/**
 * __useDeactivateAccountUserMutation__
 *
 * To run a mutation, you first call `useDeactivateAccountUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateAccountUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateAccountUserMutation, { data, loading, error }] = useDeactivateAccountUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeactivateAccountUserMutation(baseOptions?: Apollo.MutationHookOptions<Operation.DeactivateAccountUserMutation, Operation.DeactivateAccountUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.DeactivateAccountUserMutation, Operation.DeactivateAccountUserMutationVariables>(DeactivateAccountUserDocument, options);
      }
export type DeactivateAccountUserMutationHookResult = ReturnType<typeof useDeactivateAccountUserMutation>;
export type DeactivateAccountUserMutationResult = Apollo.MutationResult<Operation.DeactivateAccountUserMutation>;
export type DeactivateAccountUserMutationOptions = Apollo.BaseMutationOptions<Operation.DeactivateAccountUserMutation, Operation.DeactivateAccountUserMutationVariables>;
export const ResendInvitationDocument = gql`
    mutation ResendInvitation($guid: ID!) {
  resendInvitation(input: {guid: $guid}) {
    errors
    userPracticeConnection {
      ...userPracticeConnectionEssentials
    }
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}`;
export type ResendInvitationMutationFn = Apollo.MutationFunction<Operation.ResendInvitationMutation, Operation.ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ResendInvitationMutation, Operation.ResendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ResendInvitationMutation, Operation.ResendInvitationMutationVariables>(ResendInvitationDocument, options);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<Operation.ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<Operation.ResendInvitationMutation, Operation.ResendInvitationMutationVariables>;
export const CancelInvitationDocument = gql`
    mutation CancelInvitation($guid: ID!) {
  cancelInvitation(input: {guid: $guid}) {
    errors
    userPracticeConnection {
      ...userPracticeConnectionEssentials
    }
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}`;
export type CancelInvitationMutationFn = Apollo.MutationFunction<Operation.CancelInvitationMutation, Operation.CancelInvitationMutationVariables>;

/**
 * __useCancelInvitationMutation__
 *
 * To run a mutation, you first call `useCancelInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInvitationMutation, { data, loading, error }] = useCancelInvitationMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useCancelInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CancelInvitationMutation, Operation.CancelInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CancelInvitationMutation, Operation.CancelInvitationMutationVariables>(CancelInvitationDocument, options);
      }
export type CancelInvitationMutationHookResult = ReturnType<typeof useCancelInvitationMutation>;
export type CancelInvitationMutationResult = Apollo.MutationResult<Operation.CancelInvitationMutation>;
export type CancelInvitationMutationOptions = Apollo.BaseMutationOptions<Operation.CancelInvitationMutation, Operation.CancelInvitationMutationVariables>;
export const InviteToPatientAppDocument = gql`
    mutation InviteToPatientApp($upc: ID!) {
  inviteToPatientApp(input: {upc: $upc}) {
    fieldErrors {
      field
      message
    }
    errors
    patientAppConfig {
      ...patientAppConfigDetailed
    }
  }
}
    ${PatientAppConfigDetailedFragmentDoc}`;
export type InviteToPatientAppMutationFn = Apollo.MutationFunction<Operation.InviteToPatientAppMutation, Operation.InviteToPatientAppMutationVariables>;

/**
 * __useInviteToPatientAppMutation__
 *
 * To run a mutation, you first call `useInviteToPatientAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteToPatientAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteToPatientAppMutation, { data, loading, error }] = useInviteToPatientAppMutation({
 *   variables: {
 *      upc: // value for 'upc'
 *   },
 * });
 */
export function useInviteToPatientAppMutation(baseOptions?: Apollo.MutationHookOptions<Operation.InviteToPatientAppMutation, Operation.InviteToPatientAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.InviteToPatientAppMutation, Operation.InviteToPatientAppMutationVariables>(InviteToPatientAppDocument, options);
      }
export type InviteToPatientAppMutationHookResult = ReturnType<typeof useInviteToPatientAppMutation>;
export type InviteToPatientAppMutationResult = Apollo.MutationResult<Operation.InviteToPatientAppMutation>;
export type InviteToPatientAppMutationOptions = Apollo.BaseMutationOptions<Operation.InviteToPatientAppMutation, Operation.InviteToPatientAppMutationVariables>;
export const RemoveUserContactDocument = gql`
    mutation RemoveUserContact($input: RemoveUserContactInput!) {
  removeUserContact(input: $input) {
    fieldErrors {
      field
      message
    }
    errors
    userPracticeConnection {
      ...userPracticeConnectionEssentials
      contacts {
        ...contactEssentials
      }
    }
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}
${ContactEssentialsFragmentDoc}`;
export type RemoveUserContactMutationFn = Apollo.MutationFunction<Operation.RemoveUserContactMutation, Operation.RemoveUserContactMutationVariables>;

/**
 * __useRemoveUserContactMutation__
 *
 * To run a mutation, you first call `useRemoveUserContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserContactMutation, { data, loading, error }] = useRemoveUserContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserContactMutation(baseOptions?: Apollo.MutationHookOptions<Operation.RemoveUserContactMutation, Operation.RemoveUserContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.RemoveUserContactMutation, Operation.RemoveUserContactMutationVariables>(RemoveUserContactDocument, options);
      }
export type RemoveUserContactMutationHookResult = ReturnType<typeof useRemoveUserContactMutation>;
export type RemoveUserContactMutationResult = Apollo.MutationResult<Operation.RemoveUserContactMutation>;
export type RemoveUserContactMutationOptions = Apollo.BaseMutationOptions<Operation.RemoveUserContactMutation, Operation.RemoveUserContactMutationVariables>;
export const TransitionPatientAppDocument = gql`
    mutation TransitionPatientApp($id: ID!, $event: PatientAppStateEvent!) {
  transitionPatientAppState(input: {patientApp: $id, event: $event}) {
    fieldErrors {
      field
      message
    }
    errors
    patientAppConfig {
      ...patientAppConfigDetailed
    }
  }
}
    ${PatientAppConfigDetailedFragmentDoc}`;
export type TransitionPatientAppMutationFn = Apollo.MutationFunction<Operation.TransitionPatientAppMutation, Operation.TransitionPatientAppMutationVariables>;

/**
 * __useTransitionPatientAppMutation__
 *
 * To run a mutation, you first call `useTransitionPatientAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionPatientAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionPatientAppMutation, { data, loading, error }] = useTransitionPatientAppMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionPatientAppMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionPatientAppMutation, Operation.TransitionPatientAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionPatientAppMutation, Operation.TransitionPatientAppMutationVariables>(TransitionPatientAppDocument, options);
      }
export type TransitionPatientAppMutationHookResult = ReturnType<typeof useTransitionPatientAppMutation>;
export type TransitionPatientAppMutationResult = Apollo.MutationResult<Operation.TransitionPatientAppMutation>;
export type TransitionPatientAppMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionPatientAppMutation, Operation.TransitionPatientAppMutationVariables>;
export const TransitionUserStateDocument = gql`
    mutation transitionUserState($id: ID!, $event: UserPracticeConnectionStateEvent!) {
  transitionUserState(input: {id: $id, event: $event}) {
    success
    errors
    fieldErrors {
      field
      message
    }
    userPracticeConnection {
      ...userPracticeConnectionDetailed
    }
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;
export type TransitionUserStateMutationFn = Apollo.MutationFunction<Operation.TransitionUserStateMutation, Operation.TransitionUserStateMutationVariables>;

/**
 * __useTransitionUserStateMutation__
 *
 * To run a mutation, you first call `useTransitionUserStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionUserStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionUserStateMutation, { data, loading, error }] = useTransitionUserStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionUserStateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionUserStateMutation, Operation.TransitionUserStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionUserStateMutation, Operation.TransitionUserStateMutationVariables>(TransitionUserStateDocument, options);
      }
export type TransitionUserStateMutationHookResult = ReturnType<typeof useTransitionUserStateMutation>;
export type TransitionUserStateMutationResult = Apollo.MutationResult<Operation.TransitionUserStateMutation>;
export type TransitionUserStateMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionUserStateMutation, Operation.TransitionUserStateMutationVariables>;
export const UpdateActiveProfileDocument = gql`
    mutation UpdateActiveProfile($profile: ID!, $upc: ID!) {
  updateActiveProfile(input: {profile: $profile, upc: $upc}) {
    fieldErrors {
      field
      message
    }
    errors
    userPracticeConnection {
      ...userPracticeConnectionDetailed
    }
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;
export type UpdateActiveProfileMutationFn = Apollo.MutationFunction<Operation.UpdateActiveProfileMutation, Operation.UpdateActiveProfileMutationVariables>;

/**
 * __useUpdateActiveProfileMutation__
 *
 * To run a mutation, you first call `useUpdateActiveProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveProfileMutation, { data, loading, error }] = useUpdateActiveProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *      upc: // value for 'upc'
 *   },
 * });
 */
export function useUpdateActiveProfileMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateActiveProfileMutation, Operation.UpdateActiveProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateActiveProfileMutation, Operation.UpdateActiveProfileMutationVariables>(UpdateActiveProfileDocument, options);
      }
export type UpdateActiveProfileMutationHookResult = ReturnType<typeof useUpdateActiveProfileMutation>;
export type UpdateActiveProfileMutationResult = Apollo.MutationResult<Operation.UpdateActiveProfileMutation>;
export type UpdateActiveProfileMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateActiveProfileMutation, Operation.UpdateActiveProfileMutationVariables>;
export const UpdatePatientDocument = gql`
    mutation updatePatient($id: ID!, $attributes: PatientUpdateInput!) {
  updatePatient(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    userPracticeConnection {
      ...userPracticeConnectionDetailed
    }
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;
export type UpdatePatientMutationFn = Apollo.MutationFunction<Operation.UpdatePatientMutation, Operation.UpdatePatientMutationVariables>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdatePatientMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdatePatientMutation, Operation.UpdatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdatePatientMutation, Operation.UpdatePatientMutationVariables>(UpdatePatientDocument, options);
      }
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>;
export type UpdatePatientMutationResult = Apollo.MutationResult<Operation.UpdatePatientMutation>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<Operation.UpdatePatientMutation, Operation.UpdatePatientMutationVariables>;
export const TogglePatientAutoRecallDocument = gql`
    mutation togglePatientAutoRecall($id: ID!, $autoRecalls: Boolean!) {
  togglePatientAutoRecall(input: {id: $id, autoRecalls: $autoRecalls}) {
    errors
    success
    userPracticeConnection {
      ...userPracticeConnectionRecalls
    }
  }
}
    ${UserPracticeConnectionRecallsFragmentDoc}`;
export type TogglePatientAutoRecallMutationFn = Apollo.MutationFunction<Operation.TogglePatientAutoRecallMutation, Operation.TogglePatientAutoRecallMutationVariables>;

/**
 * __useTogglePatientAutoRecallMutation__
 *
 * To run a mutation, you first call `useTogglePatientAutoRecallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePatientAutoRecallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePatientAutoRecallMutation, { data, loading, error }] = useTogglePatientAutoRecallMutation({
 *   variables: {
 *      id: // value for 'id'
 *      autoRecalls: // value for 'autoRecalls'
 *   },
 * });
 */
export function useTogglePatientAutoRecallMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TogglePatientAutoRecallMutation, Operation.TogglePatientAutoRecallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TogglePatientAutoRecallMutation, Operation.TogglePatientAutoRecallMutationVariables>(TogglePatientAutoRecallDocument, options);
      }
export type TogglePatientAutoRecallMutationHookResult = ReturnType<typeof useTogglePatientAutoRecallMutation>;
export type TogglePatientAutoRecallMutationResult = Apollo.MutationResult<Operation.TogglePatientAutoRecallMutation>;
export type TogglePatientAutoRecallMutationOptions = Apollo.BaseMutationOptions<Operation.TogglePatientAutoRecallMutation, Operation.TogglePatientAutoRecallMutationVariables>;
export const ToggleReadMessagesDocument = gql`
    mutation toggleReadMessages($id: ID!, $unreadMessagesCount: Int!, $lastMessageReadAt: ISO8601DateTime!) {
  toggleReadMessages(
    input: {id: $id, unreadMessagesCount: $unreadMessagesCount, lastMessageReadAt: $lastMessageReadAt}
  ) {
    errors
    success
    userPracticeConnection {
      ...upcShortMessageFields
    }
  }
}
    ${UpcShortMessageFieldsFragmentDoc}`;
export type ToggleReadMessagesMutationFn = Apollo.MutationFunction<Operation.ToggleReadMessagesMutation, Operation.ToggleReadMessagesMutationVariables>;

/**
 * __useToggleReadMessagesMutation__
 *
 * To run a mutation, you first call `useToggleReadMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleReadMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleReadMessagesMutation, { data, loading, error }] = useToggleReadMessagesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unreadMessagesCount: // value for 'unreadMessagesCount'
 *      lastMessageReadAt: // value for 'lastMessageReadAt'
 *   },
 * });
 */
export function useToggleReadMessagesMutation(baseOptions?: Apollo.MutationHookOptions<Operation.ToggleReadMessagesMutation, Operation.ToggleReadMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.ToggleReadMessagesMutation, Operation.ToggleReadMessagesMutationVariables>(ToggleReadMessagesDocument, options);
      }
export type ToggleReadMessagesMutationHookResult = ReturnType<typeof useToggleReadMessagesMutation>;
export type ToggleReadMessagesMutationResult = Apollo.MutationResult<Operation.ToggleReadMessagesMutation>;
export type ToggleReadMessagesMutationOptions = Apollo.BaseMutationOptions<Operation.ToggleReadMessagesMutation, Operation.ToggleReadMessagesMutationVariables>;
export const UpdatePreferencesDocument = gql`
    mutation updatePreferences($attributes: UserUpdatePreferencesInput!) {
  updatePreferences(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    preferences {
      ...upcPreferences
    }
  }
}
    ${UpcPreferencesFragmentDoc}`;
export type UpdatePreferencesMutationFn = Apollo.MutationFunction<Operation.UpdatePreferencesMutation, Operation.UpdatePreferencesMutationVariables>;

/**
 * __useUpdatePreferencesMutation__
 *
 * To run a mutation, you first call `useUpdatePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferencesMutation, { data, loading, error }] = useUpdatePreferencesMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdatePreferencesMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdatePreferencesMutation, Operation.UpdatePreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdatePreferencesMutation, Operation.UpdatePreferencesMutationVariables>(UpdatePreferencesDocument, options);
      }
export type UpdatePreferencesMutationHookResult = ReturnType<typeof useUpdatePreferencesMutation>;
export type UpdatePreferencesMutationResult = Apollo.MutationResult<Operation.UpdatePreferencesMutation>;
export type UpdatePreferencesMutationOptions = Apollo.BaseMutationOptions<Operation.UpdatePreferencesMutation, Operation.UpdatePreferencesMutationVariables>;
export const UpdateDashboardPreferencesDocument = gql`
    mutation updateDashboardPreferences($attributes: UserUpdatePreferencesInput!) {
  updatePreferences(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    preferences {
      ...upcDashboardPreferences
    }
  }
}
    ${UpcDashboardPreferencesFragmentDoc}`;
export type UpdateDashboardPreferencesMutationFn = Apollo.MutationFunction<Operation.UpdateDashboardPreferencesMutation, Operation.UpdateDashboardPreferencesMutationVariables>;

/**
 * __useUpdateDashboardPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardPreferencesMutation, { data, loading, error }] = useUpdateDashboardPreferencesMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateDashboardPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateDashboardPreferencesMutation, Operation.UpdateDashboardPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateDashboardPreferencesMutation, Operation.UpdateDashboardPreferencesMutationVariables>(UpdateDashboardPreferencesDocument, options);
      }
export type UpdateDashboardPreferencesMutationHookResult = ReturnType<typeof useUpdateDashboardPreferencesMutation>;
export type UpdateDashboardPreferencesMutationResult = Apollo.MutationResult<Operation.UpdateDashboardPreferencesMutation>;
export type UpdateDashboardPreferencesMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateDashboardPreferencesMutation, Operation.UpdateDashboardPreferencesMutationVariables>;
export const UpdateStaffDocument = gql`
    mutation UpdateStaff($id: ID!, $input: UserUpdateInput!) {
  updateStaff(input: {id: $id, attributes: $input}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    userPracticeConnection {
      ...userPracticeConnectionDetailed
    }
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;
export type UpdateStaffMutationFn = Apollo.MutationFunction<Operation.UpdateStaffMutation, Operation.UpdateStaffMutationVariables>;

/**
 * __useUpdateStaffMutation__
 *
 * To run a mutation, you first call `useUpdateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffMutation, { data, loading, error }] = useUpdateStaffMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStaffMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateStaffMutation, Operation.UpdateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateStaffMutation, Operation.UpdateStaffMutationVariables>(UpdateStaffDocument, options);
      }
export type UpdateStaffMutationHookResult = ReturnType<typeof useUpdateStaffMutation>;
export type UpdateStaffMutationResult = Apollo.MutationResult<Operation.UpdateStaffMutation>;
export type UpdateStaffMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateStaffMutation, Operation.UpdateStaffMutationVariables>;
export const UpdateStatisticDocument = gql`
    mutation updateStatistic($id: ID!, $statistic: PatientTimestamps!, $date: ISO8601DateTime, $clearDate: Boolean) {
  updateStatistic(
    input: {id: $id, statistic: $statistic, date: $date, clearDate: $clearDate}
  ) {
    success
    errors
    fieldErrors {
      field
      message
    }
    userPracticeConnection {
      ...userPracticeConnectionDetailed
    }
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;
export type UpdateStatisticMutationFn = Apollo.MutationFunction<Operation.UpdateStatisticMutation, Operation.UpdateStatisticMutationVariables>;

/**
 * __useUpdateStatisticMutation__
 *
 * To run a mutation, you first call `useUpdateStatisticMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatisticMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatisticMutation, { data, loading, error }] = useUpdateStatisticMutation({
 *   variables: {
 *      id: // value for 'id'
 *      statistic: // value for 'statistic'
 *      date: // value for 'date'
 *      clearDate: // value for 'clearDate'
 *   },
 * });
 */
export function useUpdateStatisticMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateStatisticMutation, Operation.UpdateStatisticMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateStatisticMutation, Operation.UpdateStatisticMutationVariables>(UpdateStatisticDocument, options);
      }
export type UpdateStatisticMutationHookResult = ReturnType<typeof useUpdateStatisticMutation>;
export type UpdateStatisticMutationResult = Apollo.MutationResult<Operation.UpdateStatisticMutation>;
export type UpdateStatisticMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateStatisticMutation, Operation.UpdateStatisticMutationVariables>;
export const UpdateUserContactDocument = gql`
    mutation UpdateUserContact($input: UpdateUserContactInput!) {
  updateUserContact(input: $input) {
    fieldErrors {
      field
      message
    }
    errors
    userPracticeConnection {
      ...userPracticeConnectionEssentials
      contacts {
        ...contactEssentials
      }
    }
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}
${ContactEssentialsFragmentDoc}`;
export type UpdateUserContactMutationFn = Apollo.MutationFunction<Operation.UpdateUserContactMutation, Operation.UpdateUserContactMutationVariables>;

/**
 * __useUpdateUserContactMutation__
 *
 * To run a mutation, you first call `useUpdateUserContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserContactMutation, { data, loading, error }] = useUpdateUserContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserContactMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateUserContactMutation, Operation.UpdateUserContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateUserContactMutation, Operation.UpdateUserContactMutationVariables>(UpdateUserContactDocument, options);
      }
export type UpdateUserContactMutationHookResult = ReturnType<typeof useUpdateUserContactMutation>;
export type UpdateUserContactMutationResult = Apollo.MutationResult<Operation.UpdateUserContactMutation>;
export type UpdateUserContactMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateUserContactMutation, Operation.UpdateUserContactMutationVariables>;
export const UploadAvatarDocument = gql`
    mutation UploadAvatar($upc: ID!, $avatar: Upload) {
  uploadAvatar(input: {upc: $upc, avatar: $avatar}) {
    upc {
      ...userPracticeConnectionMinimal
    }
    fieldErrors {
      field
      message
    }
    errors
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;
export type UploadAvatarMutationFn = Apollo.MutationFunction<Operation.UploadAvatarMutation, Operation.UploadAvatarMutationVariables>;

/**
 * __useUploadAvatarMutation__
 *
 * To run a mutation, you first call `useUploadAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAvatarMutation, { data, loading, error }] = useUploadAvatarMutation({
 *   variables: {
 *      upc: // value for 'upc'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUploadAvatarMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UploadAvatarMutation, Operation.UploadAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UploadAvatarMutation, Operation.UploadAvatarMutationVariables>(UploadAvatarDocument, options);
      }
export type UploadAvatarMutationHookResult = ReturnType<typeof useUploadAvatarMutation>;
export type UploadAvatarMutationResult = Apollo.MutationResult<Operation.UploadAvatarMutation>;
export type UploadAvatarMutationOptions = Apollo.BaseMutationOptions<Operation.UploadAvatarMutation, Operation.UploadAvatarMutationVariables>;
export const OnboardingFinaliseDocument = gql`
    mutation onboardingFinalise($id: ID!) {
  onboardingFinalise(input: {id: $id}) {
    errors
    success
    userPracticeConnection {
      ...userPracticeConnectionEssentials
    }
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}`;
export type OnboardingFinaliseMutationFn = Apollo.MutationFunction<Operation.OnboardingFinaliseMutation, Operation.OnboardingFinaliseMutationVariables>;

/**
 * __useOnboardingFinaliseMutation__
 *
 * To run a mutation, you first call `useOnboardingFinaliseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingFinaliseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingFinaliseMutation, { data, loading, error }] = useOnboardingFinaliseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnboardingFinaliseMutation(baseOptions?: Apollo.MutationHookOptions<Operation.OnboardingFinaliseMutation, Operation.OnboardingFinaliseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.OnboardingFinaliseMutation, Operation.OnboardingFinaliseMutationVariables>(OnboardingFinaliseDocument, options);
      }
export type OnboardingFinaliseMutationHookResult = ReturnType<typeof useOnboardingFinaliseMutation>;
export type OnboardingFinaliseMutationResult = Apollo.MutationResult<Operation.OnboardingFinaliseMutation>;
export type OnboardingFinaliseMutationOptions = Apollo.BaseMutationOptions<Operation.OnboardingFinaliseMutation, Operation.OnboardingFinaliseMutationVariables>;
export const OnboardingUpdateProfileDocument = gql`
    mutation onboardingUpdateProfile($firstName: String!, $lastName: String!, $onboarded: Boolean!, $timezone: String) {
  onboardingUpdateProfile(
    input: {firstName: $firstName, lastName: $lastName, onboarded: $onboarded, timezone: $timezone}
  ) {
    errors
    success
    userPracticeConnection {
      ...userPracticeConnectionEssentials
    }
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}`;
export type OnboardingUpdateProfileMutationFn = Apollo.MutationFunction<Operation.OnboardingUpdateProfileMutation, Operation.OnboardingUpdateProfileMutationVariables>;

/**
 * __useOnboardingUpdateProfileMutation__
 *
 * To run a mutation, you first call `useOnboardingUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingUpdateProfileMutation, { data, loading, error }] = useOnboardingUpdateProfileMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      onboarded: // value for 'onboarded'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useOnboardingUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<Operation.OnboardingUpdateProfileMutation, Operation.OnboardingUpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.OnboardingUpdateProfileMutation, Operation.OnboardingUpdateProfileMutationVariables>(OnboardingUpdateProfileDocument, options);
      }
export type OnboardingUpdateProfileMutationHookResult = ReturnType<typeof useOnboardingUpdateProfileMutation>;
export type OnboardingUpdateProfileMutationResult = Apollo.MutationResult<Operation.OnboardingUpdateProfileMutation>;
export type OnboardingUpdateProfileMutationOptions = Apollo.BaseMutationOptions<Operation.OnboardingUpdateProfileMutation, Operation.OnboardingUpdateProfileMutationVariables>;
export const OnboardingInviteStaffDocument = gql`
    mutation onboardingInviteStaff($attributes: OnboardingInviteStaffInput!) {
  onboardingInviteStaff(input: {attributes: $attributes}) {
    errors
    success
    practice {
      ...practiceDetailed
    }
  }
}
    ${PracticeDetailedFragmentDoc}`;
export type OnboardingInviteStaffMutationFn = Apollo.MutationFunction<Operation.OnboardingInviteStaffMutation, Operation.OnboardingInviteStaffMutationVariables>;

/**
 * __useOnboardingInviteStaffMutation__
 *
 * To run a mutation, you first call `useOnboardingInviteStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingInviteStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingInviteStaffMutation, { data, loading, error }] = useOnboardingInviteStaffMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useOnboardingInviteStaffMutation(baseOptions?: Apollo.MutationHookOptions<Operation.OnboardingInviteStaffMutation, Operation.OnboardingInviteStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.OnboardingInviteStaffMutation, Operation.OnboardingInviteStaffMutationVariables>(OnboardingInviteStaffDocument, options);
      }
export type OnboardingInviteStaffMutationHookResult = ReturnType<typeof useOnboardingInviteStaffMutation>;
export type OnboardingInviteStaffMutationResult = Apollo.MutationResult<Operation.OnboardingInviteStaffMutation>;
export type OnboardingInviteStaffMutationOptions = Apollo.BaseMutationOptions<Operation.OnboardingInviteStaffMutation, Operation.OnboardingInviteStaffMutationVariables>;
export const CreateTaskDocument = gql`
    mutation CreateTask($attributes: TaskInput!) {
  createTask(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    task {
      ...taskEssentials
    }
  }
}
    ${TaskEssentialsFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<Operation.CreateTaskMutation, Operation.CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateTaskMutation, Operation.CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateTaskMutation, Operation.CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<Operation.CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<Operation.CreateTaskMutation, Operation.CreateTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($id: ID!, $attributes: TaskInput!) {
  updateTask(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    task {
      ...taskEssentials
    }
  }
}
    ${TaskEssentialsFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<Operation.UpdateTaskMutation, Operation.UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateTaskMutation, Operation.UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateTaskMutation, Operation.UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<Operation.UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateTaskMutation, Operation.UpdateTaskMutationVariables>;
export const TransitionTaskStateDocument = gql`
    mutation TransitionTaskState($ids: [ID!]!, $event: TaskStateEvent!) {
  transitionTaskState(input: {ids: $ids, event: $event}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    tasks {
      ...taskEssentials
    }
  }
}
    ${TaskEssentialsFragmentDoc}`;
export type TransitionTaskStateMutationFn = Apollo.MutationFunction<Operation.TransitionTaskStateMutation, Operation.TransitionTaskStateMutationVariables>;

/**
 * __useTransitionTaskStateMutation__
 *
 * To run a mutation, you first call `useTransitionTaskStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionTaskStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionTaskStateMutation, { data, loading, error }] = useTransitionTaskStateMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionTaskStateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionTaskStateMutation, Operation.TransitionTaskStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionTaskStateMutation, Operation.TransitionTaskStateMutationVariables>(TransitionTaskStateDocument, options);
      }
export type TransitionTaskStateMutationHookResult = ReturnType<typeof useTransitionTaskStateMutation>;
export type TransitionTaskStateMutationResult = Apollo.MutationResult<Operation.TransitionTaskStateMutation>;
export type TransitionTaskStateMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionTaskStateMutation, Operation.TransitionTaskStateMutationVariables>;
export const TransitionWorkflowStateDocument = gql`
    mutation TransitionWorkflowState($id: ID!, $event: WorkflowStateEvent!) {
  transitionWorkflowState(input: {id: $id, event: $event}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    workflow {
      ...workflowDetailed
    }
  }
}
    ${WorkflowDetailedFragmentDoc}`;
export type TransitionWorkflowStateMutationFn = Apollo.MutationFunction<Operation.TransitionWorkflowStateMutation, Operation.TransitionWorkflowStateMutationVariables>;

/**
 * __useTransitionWorkflowStateMutation__
 *
 * To run a mutation, you first call `useTransitionWorkflowStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionWorkflowStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionWorkflowStateMutation, { data, loading, error }] = useTransitionWorkflowStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useTransitionWorkflowStateMutation(baseOptions?: Apollo.MutationHookOptions<Operation.TransitionWorkflowStateMutation, Operation.TransitionWorkflowStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.TransitionWorkflowStateMutation, Operation.TransitionWorkflowStateMutationVariables>(TransitionWorkflowStateDocument, options);
      }
export type TransitionWorkflowStateMutationHookResult = ReturnType<typeof useTransitionWorkflowStateMutation>;
export type TransitionWorkflowStateMutationResult = Apollo.MutationResult<Operation.TransitionWorkflowStateMutation>;
export type TransitionWorkflowStateMutationOptions = Apollo.BaseMutationOptions<Operation.TransitionWorkflowStateMutation, Operation.TransitionWorkflowStateMutationVariables>;
export const CreateWorkflowDocument = gql`
    mutation CreateWorkflow($attributes: WorkflowInput!) {
  createWorkflow(input: {attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    workflow {
      ...workflowDetailed
    }
  }
}
    ${WorkflowDetailedFragmentDoc}`;
export type CreateWorkflowMutationFn = Apollo.MutationFunction<Operation.CreateWorkflowMutation, Operation.CreateWorkflowMutationVariables>;

/**
 * __useCreateWorkflowMutation__
 *
 * To run a mutation, you first call `useCreateWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkflowMutation, { data, loading, error }] = useCreateWorkflowMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreateWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<Operation.CreateWorkflowMutation, Operation.CreateWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.CreateWorkflowMutation, Operation.CreateWorkflowMutationVariables>(CreateWorkflowDocument, options);
      }
export type CreateWorkflowMutationHookResult = ReturnType<typeof useCreateWorkflowMutation>;
export type CreateWorkflowMutationResult = Apollo.MutationResult<Operation.CreateWorkflowMutation>;
export type CreateWorkflowMutationOptions = Apollo.BaseMutationOptions<Operation.CreateWorkflowMutation, Operation.CreateWorkflowMutationVariables>;
export const UpdateWorkflowDocument = gql`
    mutation UpdateWorkflow($id: ID!, $attributes: WorkflowInput!) {
  updateWorkflow(input: {id: $id, attributes: $attributes}) {
    errors
    fieldErrors {
      field
      message
    }
    success
    workflow {
      ...workflowDetailed
    }
  }
}
    ${WorkflowDetailedFragmentDoc}`;
export type UpdateWorkflowMutationFn = Apollo.MutationFunction<Operation.UpdateWorkflowMutation, Operation.UpdateWorkflowMutationVariables>;

/**
 * __useUpdateWorkflowMutation__
 *
 * To run a mutation, you first call `useUpdateWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkflowMutation, { data, loading, error }] = useUpdateWorkflowMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<Operation.UpdateWorkflowMutation, Operation.UpdateWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Operation.UpdateWorkflowMutation, Operation.UpdateWorkflowMutationVariables>(UpdateWorkflowDocument, options);
      }
export type UpdateWorkflowMutationHookResult = ReturnType<typeof useUpdateWorkflowMutation>;
export type UpdateWorkflowMutationResult = Apollo.MutationResult<Operation.UpdateWorkflowMutation>;
export type UpdateWorkflowMutationOptions = Apollo.BaseMutationOptions<Operation.UpdateWorkflowMutation, Operation.UpdateWorkflowMutationVariables>;
export const UuidDocument = gql`
    query UUID($uuid: ID!) {
  node(uuid: $uuid) {
    ...UUIDNode
  }
}
    ${UuidNodeFragmentDoc}`;

/**
 * __useUuidQuery__
 *
 * To run a query within a React component, call `useUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUuidQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUuidQuery(baseOptions: Apollo.QueryHookOptions<Operation.UuidQuery, Operation.UuidQueryVariables> & ({ variables: Operation.UuidQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UuidQuery, Operation.UuidQueryVariables>(UuidDocument, options);
      }
export function useUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UuidQuery, Operation.UuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UuidQuery, Operation.UuidQueryVariables>(UuidDocument, options);
        }
export function useUuidSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UuidQuery, Operation.UuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UuidQuery, Operation.UuidQueryVariables>(UuidDocument, options);
        }
export type UuidQueryHookResult = ReturnType<typeof useUuidQuery>;
export type UuidLazyQueryHookResult = ReturnType<typeof useUuidLazyQuery>;
export type UuidSuspenseQueryHookResult = ReturnType<typeof useUuidSuspenseQuery>;
export type UuidQueryResult = Apollo.QueryResult<Operation.UuidQuery, Operation.UuidQueryVariables>;
export const UnauthenticatedHealthFundsProvidersDocument = gql`
    query UnauthenticatedHealthFundsProviders($search: String, $guids: [String!], $country: Country, $gateway: HealthFundsGateway, $publicHealth: Boolean) {
  unauthenticatedHealthFunds(
    search: $search
    guids: $guids
    byCountry: $country
    byGateway: $gateway
    publicHealth: $publicHealth
  ) {
    ... on PublicHealthFundProvider {
      ...healthFundProviderBasics
    }
  }
}
    ${HealthFundProviderBasicsFragmentDoc}`;

/**
 * __useUnauthenticatedHealthFundsProvidersQuery__
 *
 * To run a query within a React component, call `useUnauthenticatedHealthFundsProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnauthenticatedHealthFundsProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnauthenticatedHealthFundsProvidersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      guids: // value for 'guids'
 *      country: // value for 'country'
 *      gateway: // value for 'gateway'
 *      publicHealth: // value for 'publicHealth'
 *   },
 * });
 */
export function useUnauthenticatedHealthFundsProvidersQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UnauthenticatedHealthFundsProvidersQuery, Operation.UnauthenticatedHealthFundsProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UnauthenticatedHealthFundsProvidersQuery, Operation.UnauthenticatedHealthFundsProvidersQueryVariables>(UnauthenticatedHealthFundsProvidersDocument, options);
      }
export function useUnauthenticatedHealthFundsProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UnauthenticatedHealthFundsProvidersQuery, Operation.UnauthenticatedHealthFundsProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UnauthenticatedHealthFundsProvidersQuery, Operation.UnauthenticatedHealthFundsProvidersQueryVariables>(UnauthenticatedHealthFundsProvidersDocument, options);
        }
export function useUnauthenticatedHealthFundsProvidersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UnauthenticatedHealthFundsProvidersQuery, Operation.UnauthenticatedHealthFundsProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UnauthenticatedHealthFundsProvidersQuery, Operation.UnauthenticatedHealthFundsProvidersQueryVariables>(UnauthenticatedHealthFundsProvidersDocument, options);
        }
export type UnauthenticatedHealthFundsProvidersQueryHookResult = ReturnType<typeof useUnauthenticatedHealthFundsProvidersQuery>;
export type UnauthenticatedHealthFundsProvidersLazyQueryHookResult = ReturnType<typeof useUnauthenticatedHealthFundsProvidersLazyQuery>;
export type UnauthenticatedHealthFundsProvidersSuspenseQueryHookResult = ReturnType<typeof useUnauthenticatedHealthFundsProvidersSuspenseQuery>;
export type UnauthenticatedHealthFundsProvidersQueryResult = Apollo.QueryResult<Operation.UnauthenticatedHealthFundsProvidersQuery, Operation.UnauthenticatedHealthFundsProvidersQueryVariables>;
export const HealthFundProvidersDocument = gql`
    query HealthFundProviders($search: String, $guids: [String!], $country: Country, $gateway: HealthFundsGateway, $publicHealth: Boolean) {
  healthFunds(
    search: $search
    guids: $guids
    byCountry: $country
    byGateway: $gateway
    publicHealth: $publicHealth
  ) {
    ... on HealthFundProvider {
      ...healthFundProviderEssentials
    }
  }
}
    ${HealthFundProviderEssentialsFragmentDoc}`;

/**
 * __useHealthFundProvidersQuery__
 *
 * To run a query within a React component, call `useHealthFundProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthFundProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthFundProvidersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      guids: // value for 'guids'
 *      country: // value for 'country'
 *      gateway: // value for 'gateway'
 *      publicHealth: // value for 'publicHealth'
 *   },
 * });
 */
export function useHealthFundProvidersQuery(baseOptions?: Apollo.QueryHookOptions<Operation.HealthFundProvidersQuery, Operation.HealthFundProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.HealthFundProvidersQuery, Operation.HealthFundProvidersQueryVariables>(HealthFundProvidersDocument, options);
      }
export function useHealthFundProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.HealthFundProvidersQuery, Operation.HealthFundProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.HealthFundProvidersQuery, Operation.HealthFundProvidersQueryVariables>(HealthFundProvidersDocument, options);
        }
export function useHealthFundProvidersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.HealthFundProvidersQuery, Operation.HealthFundProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.HealthFundProvidersQuery, Operation.HealthFundProvidersQueryVariables>(HealthFundProvidersDocument, options);
        }
export type HealthFundProvidersQueryHookResult = ReturnType<typeof useHealthFundProvidersQuery>;
export type HealthFundProvidersLazyQueryHookResult = ReturnType<typeof useHealthFundProvidersLazyQuery>;
export type HealthFundProvidersSuspenseQueryHookResult = ReturnType<typeof useHealthFundProvidersSuspenseQuery>;
export type HealthFundProvidersQueryResult = Apollo.QueryResult<Operation.HealthFundProvidersQuery, Operation.HealthFundProvidersQueryVariables>;
export const LabWorksDetailedDocument = gql`
    query LabWorksDetailed($first: Int, $after: String, $before: String, $search: String, $upcId: ID, $appointmentId: ID, $requiredById: ID, $state: [LabWorkState!], $order: OrderLabWorks) {
  labWorks(
    first: $first
    after: $after
    before: $before
    search: $search
    upcId: $upcId
    appointmentId: $appointmentId
    requiredById: $requiredById
    state: $state
    order: $order
  ) {
    totalCount
    edges {
      cursor
      node {
        ...labWorkDetailed
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${LabWorkDetailedFragmentDoc}`;

/**
 * __useLabWorksDetailedQuery__
 *
 * To run a query within a React component, call `useLabWorksDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabWorksDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabWorksDetailedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      upcId: // value for 'upcId'
 *      appointmentId: // value for 'appointmentId'
 *      requiredById: // value for 'requiredById'
 *      state: // value for 'state'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLabWorksDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.LabWorksDetailedQuery, Operation.LabWorksDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.LabWorksDetailedQuery, Operation.LabWorksDetailedQueryVariables>(LabWorksDetailedDocument, options);
      }
export function useLabWorksDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.LabWorksDetailedQuery, Operation.LabWorksDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.LabWorksDetailedQuery, Operation.LabWorksDetailedQueryVariables>(LabWorksDetailedDocument, options);
        }
export function useLabWorksDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.LabWorksDetailedQuery, Operation.LabWorksDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.LabWorksDetailedQuery, Operation.LabWorksDetailedQueryVariables>(LabWorksDetailedDocument, options);
        }
export type LabWorksDetailedQueryHookResult = ReturnType<typeof useLabWorksDetailedQuery>;
export type LabWorksDetailedLazyQueryHookResult = ReturnType<typeof useLabWorksDetailedLazyQuery>;
export type LabWorksDetailedSuspenseQueryHookResult = ReturnType<typeof useLabWorksDetailedSuspenseQuery>;
export type LabWorksDetailedQueryResult = Apollo.QueryResult<Operation.LabWorksDetailedQuery, Operation.LabWorksDetailedQueryVariables>;
export const LabWorkEssentialsDocument = gql`
    query LabWorkEssentials($id: ID!) {
  labWork(id: $id) {
    ...labWorkEssentials
  }
}
    ${LabWorkEssentialsFragmentDoc}`;

/**
 * __useLabWorkEssentialsQuery__
 *
 * To run a query within a React component, call `useLabWorkEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabWorkEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabWorkEssentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLabWorkEssentialsQuery(baseOptions: Apollo.QueryHookOptions<Operation.LabWorkEssentialsQuery, Operation.LabWorkEssentialsQueryVariables> & ({ variables: Operation.LabWorkEssentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.LabWorkEssentialsQuery, Operation.LabWorkEssentialsQueryVariables>(LabWorkEssentialsDocument, options);
      }
export function useLabWorkEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.LabWorkEssentialsQuery, Operation.LabWorkEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.LabWorkEssentialsQuery, Operation.LabWorkEssentialsQueryVariables>(LabWorkEssentialsDocument, options);
        }
export function useLabWorkEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.LabWorkEssentialsQuery, Operation.LabWorkEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.LabWorkEssentialsQuery, Operation.LabWorkEssentialsQueryVariables>(LabWorkEssentialsDocument, options);
        }
export type LabWorkEssentialsQueryHookResult = ReturnType<typeof useLabWorkEssentialsQuery>;
export type LabWorkEssentialsLazyQueryHookResult = ReturnType<typeof useLabWorkEssentialsLazyQuery>;
export type LabWorkEssentialsSuspenseQueryHookResult = ReturnType<typeof useLabWorkEssentialsSuspenseQuery>;
export type LabWorkEssentialsQueryResult = Apollo.QueryResult<Operation.LabWorkEssentialsQuery, Operation.LabWorkEssentialsQueryVariables>;
export const LabWorkDetailedDocument = gql`
    query LabWorkDetailed($id: ID!) {
  labWork(id: $id) {
    ...labWorkDetailed
  }
}
    ${LabWorkDetailedFragmentDoc}`;

/**
 * __useLabWorkDetailedQuery__
 *
 * To run a query within a React component, call `useLabWorkDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabWorkDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabWorkDetailedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLabWorkDetailedQuery(baseOptions: Apollo.QueryHookOptions<Operation.LabWorkDetailedQuery, Operation.LabWorkDetailedQueryVariables> & ({ variables: Operation.LabWorkDetailedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.LabWorkDetailedQuery, Operation.LabWorkDetailedQueryVariables>(LabWorkDetailedDocument, options);
      }
export function useLabWorkDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.LabWorkDetailedQuery, Operation.LabWorkDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.LabWorkDetailedQuery, Operation.LabWorkDetailedQueryVariables>(LabWorkDetailedDocument, options);
        }
export function useLabWorkDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.LabWorkDetailedQuery, Operation.LabWorkDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.LabWorkDetailedQuery, Operation.LabWorkDetailedQueryVariables>(LabWorkDetailedDocument, options);
        }
export type LabWorkDetailedQueryHookResult = ReturnType<typeof useLabWorkDetailedQuery>;
export type LabWorkDetailedLazyQueryHookResult = ReturnType<typeof useLabWorkDetailedLazyQuery>;
export type LabWorkDetailedSuspenseQueryHookResult = ReturnType<typeof useLabWorkDetailedSuspenseQuery>;
export type LabWorkDetailedQueryResult = Apollo.QueryResult<Operation.LabWorkDetailedQuery, Operation.LabWorkDetailedQueryVariables>;
export const AccessRestrictionDocument = gql`
    query AccessRestriction($restriction: String!) {
  accessRestriction(restriction: $restriction) {
    reference
    authenticationType
    redirectUrl
    acceptOtpEmail
    acceptOtpSms
    upcId
  }
}
    `;

/**
 * __useAccessRestrictionQuery__
 *
 * To run a query within a React component, call `useAccessRestrictionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessRestrictionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessRestrictionQuery({
 *   variables: {
 *      restriction: // value for 'restriction'
 *   },
 * });
 */
export function useAccessRestrictionQuery(baseOptions: Apollo.QueryHookOptions<Operation.AccessRestrictionQuery, Operation.AccessRestrictionQueryVariables> & ({ variables: Operation.AccessRestrictionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AccessRestrictionQuery, Operation.AccessRestrictionQueryVariables>(AccessRestrictionDocument, options);
      }
export function useAccessRestrictionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AccessRestrictionQuery, Operation.AccessRestrictionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AccessRestrictionQuery, Operation.AccessRestrictionQueryVariables>(AccessRestrictionDocument, options);
        }
export function useAccessRestrictionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AccessRestrictionQuery, Operation.AccessRestrictionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AccessRestrictionQuery, Operation.AccessRestrictionQueryVariables>(AccessRestrictionDocument, options);
        }
export type AccessRestrictionQueryHookResult = ReturnType<typeof useAccessRestrictionQuery>;
export type AccessRestrictionLazyQueryHookResult = ReturnType<typeof useAccessRestrictionLazyQuery>;
export type AccessRestrictionSuspenseQueryHookResult = ReturnType<typeof useAccessRestrictionSuspenseQuery>;
export type AccessRestrictionQueryResult = Apollo.QueryResult<Operation.AccessRestrictionQuery, Operation.AccessRestrictionQueryVariables>;
export const GroupDocument = gql`
    query Group($id: ID, $slug: String) {
  group(id: $id, slug: $slug) {
    ...groupDetails
  }
}
    ${GroupDetailsFragmentDoc}`;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGroupQuery(baseOptions?: Apollo.QueryHookOptions<Operation.GroupQuery, Operation.GroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.GroupQuery, Operation.GroupQueryVariables>(GroupDocument, options);
      }
export function useGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.GroupQuery, Operation.GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.GroupQuery, Operation.GroupQueryVariables>(GroupDocument, options);
        }
export function useGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.GroupQuery, Operation.GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.GroupQuery, Operation.GroupQueryVariables>(GroupDocument, options);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupSuspenseQueryHookResult = ReturnType<typeof useGroupSuspenseQuery>;
export type GroupQueryResult = Apollo.QueryResult<Operation.GroupQuery, Operation.GroupQueryVariables>;
export const GroupsDocument = gql`
    query Groups($search: String, $hierarchical: Boolean) {
  groups(search: $search, hierarchical: $hierarchical) {
    edges {
      cursor
      node {
        ...groupDetails
      }
    }
  }
}
    ${GroupDetailsFragmentDoc}`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      hierarchical: // value for 'hierarchical'
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.GroupsQuery, Operation.GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.GroupsQuery, Operation.GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.GroupsQuery, Operation.GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.GroupsQuery, Operation.GroupsQueryVariables>(GroupsDocument, options);
        }
export function useGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.GroupsQuery, Operation.GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.GroupsQuery, Operation.GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsSuspenseQueryHookResult = ReturnType<typeof useGroupsSuspenseQuery>;
export type GroupsQueryResult = Apollo.QueryResult<Operation.GroupsQuery, Operation.GroupsQueryVariables>;
export const ImportFilesDocument = gql`
    query ImportFiles($importType: String!) {
  importFiles(importType: $importType) {
    ...importFileEssentials
  }
}
    ${ImportFileEssentialsFragmentDoc}`;

/**
 * __useImportFilesQuery__
 *
 * To run a query within a React component, call `useImportFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportFilesQuery({
 *   variables: {
 *      importType: // value for 'importType'
 *   },
 * });
 */
export function useImportFilesQuery(baseOptions: Apollo.QueryHookOptions<Operation.ImportFilesQuery, Operation.ImportFilesQueryVariables> & ({ variables: Operation.ImportFilesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ImportFilesQuery, Operation.ImportFilesQueryVariables>(ImportFilesDocument, options);
      }
export function useImportFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ImportFilesQuery, Operation.ImportFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ImportFilesQuery, Operation.ImportFilesQueryVariables>(ImportFilesDocument, options);
        }
export function useImportFilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ImportFilesQuery, Operation.ImportFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ImportFilesQuery, Operation.ImportFilesQueryVariables>(ImportFilesDocument, options);
        }
export type ImportFilesQueryHookResult = ReturnType<typeof useImportFilesQuery>;
export type ImportFilesLazyQueryHookResult = ReturnType<typeof useImportFilesLazyQuery>;
export type ImportFilesSuspenseQueryHookResult = ReturnType<typeof useImportFilesSuspenseQuery>;
export type ImportFilesQueryResult = Apollo.QueryResult<Operation.ImportFilesQuery, Operation.ImportFilesQueryVariables>;
export const PermissionsDocument = gql`
    query Permissions($search: String!) {
  permissions(search: $search) {
    ...permissionEssentials
  }
}
    ${PermissionEssentialsFragmentDoc}`;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions: Apollo.QueryHookOptions<Operation.PermissionsQuery, Operation.PermissionsQueryVariables> & ({ variables: Operation.PermissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PermissionsQuery, Operation.PermissionsQueryVariables>(PermissionsDocument, options);
      }
export function usePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PermissionsQuery, Operation.PermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PermissionsQuery, Operation.PermissionsQueryVariables>(PermissionsDocument, options);
        }
export function usePermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PermissionsQuery, Operation.PermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PermissionsQuery, Operation.PermissionsQueryVariables>(PermissionsDocument, options);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsSuspenseQueryHookResult = ReturnType<typeof usePermissionsSuspenseQuery>;
export type PermissionsQueryResult = Apollo.QueryResult<Operation.PermissionsQuery, Operation.PermissionsQueryVariables>;
export const RoleDocument = gql`
    query Role($slug: PracticeRole!) {
  role(slug: $slug) {
    ...roleDetails
  }
}
    ${RoleDetailsFragmentDoc}`;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useRoleQuery(baseOptions: Apollo.QueryHookOptions<Operation.RoleQuery, Operation.RoleQueryVariables> & ({ variables: Operation.RoleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RoleQuery, Operation.RoleQueryVariables>(RoleDocument, options);
      }
export function useRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RoleQuery, Operation.RoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RoleQuery, Operation.RoleQueryVariables>(RoleDocument, options);
        }
export function useRoleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RoleQuery, Operation.RoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RoleQuery, Operation.RoleQueryVariables>(RoleDocument, options);
        }
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export type RoleSuspenseQueryHookResult = ReturnType<typeof useRoleSuspenseQuery>;
export type RoleQueryResult = Apollo.QueryResult<Operation.RoleQuery, Operation.RoleQueryVariables>;
export const RolesDocument = gql`
    query Roles($search: String, $slug: [PracticeRole!]) {
  roles(search: $search, slug: $slug) {
    edges {
      cursor
      node {
        id
        name
        slug
        permissions
      }
    }
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.RolesQuery, Operation.RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RolesQuery, Operation.RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RolesQuery, Operation.RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RolesQuery, Operation.RolesQueryVariables>(RolesDocument, options);
        }
export function useRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RolesQuery, Operation.RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RolesQuery, Operation.RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesSuspenseQueryHookResult = ReturnType<typeof useRolesSuspenseQuery>;
export type RolesQueryResult = Apollo.QueryResult<Operation.RolesQuery, Operation.RolesQueryVariables>;
export const SessionDocument = gql`
    query Session {
  session {
    ...session
  }
}
    ${SessionFragmentDoc}`;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionQuery(baseOptions?: Apollo.QueryHookOptions<Operation.SessionQuery, Operation.SessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.SessionQuery, Operation.SessionQueryVariables>(SessionDocument, options);
      }
export function useSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.SessionQuery, Operation.SessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.SessionQuery, Operation.SessionQueryVariables>(SessionDocument, options);
        }
export function useSessionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.SessionQuery, Operation.SessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.SessionQuery, Operation.SessionQueryVariables>(SessionDocument, options);
        }
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionSuspenseQueryHookResult = ReturnType<typeof useSessionSuspenseQuery>;
export type SessionQueryResult = Apollo.QueryResult<Operation.SessionQuery, Operation.SessionQueryVariables>;
export const SessionStatusDocument = gql`
    query SessionStatus {
  sessionStatus {
    id
    isValid
    tokenExpiry
    practiceConnection {
      id
      guid
    }
    accessRestriction {
      id
      reference
      name
      authenticationType
      upcId
    }
    env {
      environment
      commit
      version
      releaseTime
    }
  }
}
    `;

/**
 * __useSessionStatusQuery__
 *
 * To run a query within a React component, call `useSessionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionStatusQuery(baseOptions?: Apollo.QueryHookOptions<Operation.SessionStatusQuery, Operation.SessionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.SessionStatusQuery, Operation.SessionStatusQueryVariables>(SessionStatusDocument, options);
      }
export function useSessionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.SessionStatusQuery, Operation.SessionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.SessionStatusQuery, Operation.SessionStatusQueryVariables>(SessionStatusDocument, options);
        }
export function useSessionStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.SessionStatusQuery, Operation.SessionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.SessionStatusQuery, Operation.SessionStatusQueryVariables>(SessionStatusDocument, options);
        }
export type SessionStatusQueryHookResult = ReturnType<typeof useSessionStatusQuery>;
export type SessionStatusLazyQueryHookResult = ReturnType<typeof useSessionStatusLazyQuery>;
export type SessionStatusSuspenseQueryHookResult = ReturnType<typeof useSessionStatusSuspenseQuery>;
export type SessionStatusQueryResult = Apollo.QueryResult<Operation.SessionStatusQuery, Operation.SessionStatusQueryVariables>;
export const UserAuthenticatorDocument = gql`
    query UserAuthenticator {
  userAuthenticator {
    id
    uri
    secret
    enabled
    email
    mobile
  }
}
    `;

/**
 * __useUserAuthenticatorQuery__
 *
 * To run a query within a React component, call `useUserAuthenticatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAuthenticatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAuthenticatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAuthenticatorQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UserAuthenticatorQuery, Operation.UserAuthenticatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserAuthenticatorQuery, Operation.UserAuthenticatorQueryVariables>(UserAuthenticatorDocument, options);
      }
export function useUserAuthenticatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserAuthenticatorQuery, Operation.UserAuthenticatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserAuthenticatorQuery, Operation.UserAuthenticatorQueryVariables>(UserAuthenticatorDocument, options);
        }
export function useUserAuthenticatorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserAuthenticatorQuery, Operation.UserAuthenticatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserAuthenticatorQuery, Operation.UserAuthenticatorQueryVariables>(UserAuthenticatorDocument, options);
        }
export type UserAuthenticatorQueryHookResult = ReturnType<typeof useUserAuthenticatorQuery>;
export type UserAuthenticatorLazyQueryHookResult = ReturnType<typeof useUserAuthenticatorLazyQuery>;
export type UserAuthenticatorSuspenseQueryHookResult = ReturnType<typeof useUserAuthenticatorSuspenseQuery>;
export type UserAuthenticatorQueryResult = Apollo.QueryResult<Operation.UserAuthenticatorQuery, Operation.UserAuthenticatorQueryVariables>;
export const UserPermissionsDocument = gql`
    query UserPermissions {
  session {
    id
    practiceConnection {
      ...userPracticeConnectionDetailed
      permissions
    }
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;

/**
 * __useUserPermissionsQuery__
 *
 * To run a query within a React component, call `useUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UserPermissionsQuery, Operation.UserPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserPermissionsQuery, Operation.UserPermissionsQueryVariables>(UserPermissionsDocument, options);
      }
export function useUserPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserPermissionsQuery, Operation.UserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserPermissionsQuery, Operation.UserPermissionsQueryVariables>(UserPermissionsDocument, options);
        }
export function useUserPermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserPermissionsQuery, Operation.UserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserPermissionsQuery, Operation.UserPermissionsQueryVariables>(UserPermissionsDocument, options);
        }
export type UserPermissionsQueryHookResult = ReturnType<typeof useUserPermissionsQuery>;
export type UserPermissionsLazyQueryHookResult = ReturnType<typeof useUserPermissionsLazyQuery>;
export type UserPermissionsSuspenseQueryHookResult = ReturnType<typeof useUserPermissionsSuspenseQuery>;
export type UserPermissionsQueryResult = Apollo.QueryResult<Operation.UserPermissionsQuery, Operation.UserPermissionsQueryVariables>;
export const AccountsDetailedDocument = gql`
    query AccountsDetailed($subdomain: String, $first: Int, $after: String) {
  accounts(subdomain: $subdomain, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        ...accountDetailed
      }
    }
  }
}
    ${AccountDetailedFragmentDoc}`;

/**
 * __useAccountsDetailedQuery__
 *
 * To run a query within a React component, call `useAccountsDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsDetailedQuery({
 *   variables: {
 *      subdomain: // value for 'subdomain'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAccountsDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AccountsDetailedQuery, Operation.AccountsDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AccountsDetailedQuery, Operation.AccountsDetailedQueryVariables>(AccountsDetailedDocument, options);
      }
export function useAccountsDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AccountsDetailedQuery, Operation.AccountsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AccountsDetailedQuery, Operation.AccountsDetailedQueryVariables>(AccountsDetailedDocument, options);
        }
export function useAccountsDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AccountsDetailedQuery, Operation.AccountsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AccountsDetailedQuery, Operation.AccountsDetailedQueryVariables>(AccountsDetailedDocument, options);
        }
export type AccountsDetailedQueryHookResult = ReturnType<typeof useAccountsDetailedQuery>;
export type AccountsDetailedLazyQueryHookResult = ReturnType<typeof useAccountsDetailedLazyQuery>;
export type AccountsDetailedSuspenseQueryHookResult = ReturnType<typeof useAccountsDetailedSuspenseQuery>;
export type AccountsDetailedQueryResult = Apollo.QueryResult<Operation.AccountsDetailedQuery, Operation.AccountsDetailedQueryVariables>;
export const AdminBackgroundJobsDocument = gql`
    query AdminBackgroundJobs($id: String, $jid: String, $subdomain: String, $first: Int, $after: String) {
  backgroundJobs(
    id: $id
    jid: $jid
    subdomain: $subdomain
    first: $first
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...backgroundJobEssentials
      }
    }
  }
}
    ${BackgroundJobEssentialsFragmentDoc}`;

/**
 * __useAdminBackgroundJobsQuery__
 *
 * To run a query within a React component, call `useAdminBackgroundJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBackgroundJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBackgroundJobsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      jid: // value for 'jid'
 *      subdomain: // value for 'subdomain'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAdminBackgroundJobsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AdminBackgroundJobsQuery, Operation.AdminBackgroundJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AdminBackgroundJobsQuery, Operation.AdminBackgroundJobsQueryVariables>(AdminBackgroundJobsDocument, options);
      }
export function useAdminBackgroundJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AdminBackgroundJobsQuery, Operation.AdminBackgroundJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AdminBackgroundJobsQuery, Operation.AdminBackgroundJobsQueryVariables>(AdminBackgroundJobsDocument, options);
        }
export function useAdminBackgroundJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AdminBackgroundJobsQuery, Operation.AdminBackgroundJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AdminBackgroundJobsQuery, Operation.AdminBackgroundJobsQueryVariables>(AdminBackgroundJobsDocument, options);
        }
export type AdminBackgroundJobsQueryHookResult = ReturnType<typeof useAdminBackgroundJobsQuery>;
export type AdminBackgroundJobsLazyQueryHookResult = ReturnType<typeof useAdminBackgroundJobsLazyQuery>;
export type AdminBackgroundJobsSuspenseQueryHookResult = ReturnType<typeof useAdminBackgroundJobsSuspenseQuery>;
export type AdminBackgroundJobsQueryResult = Apollo.QueryResult<Operation.AdminBackgroundJobsQuery, Operation.AdminBackgroundJobsQueryVariables>;
export const CustomImportsDocument = gql`
    query CustomImports($subdomain: String!, $first: Int, $after: String) {
  customImports(subdomain: $subdomain, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        ...customImportDetailed
      }
    }
  }
}
    ${CustomImportDetailedFragmentDoc}`;

/**
 * __useCustomImportsQuery__
 *
 * To run a query within a React component, call `useCustomImportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomImportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomImportsQuery({
 *   variables: {
 *      subdomain: // value for 'subdomain'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCustomImportsQuery(baseOptions: Apollo.QueryHookOptions<Operation.CustomImportsQuery, Operation.CustomImportsQueryVariables> & ({ variables: Operation.CustomImportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CustomImportsQuery, Operation.CustomImportsQueryVariables>(CustomImportsDocument, options);
      }
export function useCustomImportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CustomImportsQuery, Operation.CustomImportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CustomImportsQuery, Operation.CustomImportsQueryVariables>(CustomImportsDocument, options);
        }
export function useCustomImportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CustomImportsQuery, Operation.CustomImportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CustomImportsQuery, Operation.CustomImportsQueryVariables>(CustomImportsDocument, options);
        }
export type CustomImportsQueryHookResult = ReturnType<typeof useCustomImportsQuery>;
export type CustomImportsLazyQueryHookResult = ReturnType<typeof useCustomImportsLazyQuery>;
export type CustomImportsSuspenseQueryHookResult = ReturnType<typeof useCustomImportsSuspenseQuery>;
export type CustomImportsQueryResult = Apollo.QueryResult<Operation.CustomImportsQuery, Operation.CustomImportsQueryVariables>;
export const DataMigrationsDocument = gql`
    query DataMigrations {
  dataMigrations {
    id
    version
    name
    status
    createdAt
  }
}
    `;

/**
 * __useDataMigrationsQuery__
 *
 * To run a query within a React component, call `useDataMigrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataMigrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataMigrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataMigrationsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DataMigrationsQuery, Operation.DataMigrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DataMigrationsQuery, Operation.DataMigrationsQueryVariables>(DataMigrationsDocument, options);
      }
export function useDataMigrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DataMigrationsQuery, Operation.DataMigrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DataMigrationsQuery, Operation.DataMigrationsQueryVariables>(DataMigrationsDocument, options);
        }
export function useDataMigrationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DataMigrationsQuery, Operation.DataMigrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DataMigrationsQuery, Operation.DataMigrationsQueryVariables>(DataMigrationsDocument, options);
        }
export type DataMigrationsQueryHookResult = ReturnType<typeof useDataMigrationsQuery>;
export type DataMigrationsLazyQueryHookResult = ReturnType<typeof useDataMigrationsLazyQuery>;
export type DataMigrationsSuspenseQueryHookResult = ReturnType<typeof useDataMigrationsSuspenseQuery>;
export type DataMigrationsQueryResult = Apollo.QueryResult<Operation.DataMigrationsQuery, Operation.DataMigrationsQueryVariables>;
export const AlertsDocument = gql`
    query Alerts($search: String, $byActive: Boolean, $byUpcSlug: String, $byUnassigned: String, $first: Int, $after: String) {
  alerts(
    search: $search
    byActive: $byActive
    byUpcSlug: $byUpcSlug
    byUnassigned: $byUnassigned
    first: $first
    after: $after
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...alertDetailed
      }
    }
  }
}
    ${AlertDetailedFragmentDoc}`;

/**
 * __useAlertsQuery__
 *
 * To run a query within a React component, call `useAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      byActive: // value for 'byActive'
 *      byUpcSlug: // value for 'byUpcSlug'
 *      byUnassigned: // value for 'byUnassigned'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAlertsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AlertsQuery, Operation.AlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AlertsQuery, Operation.AlertsQueryVariables>(AlertsDocument, options);
      }
export function useAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AlertsQuery, Operation.AlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AlertsQuery, Operation.AlertsQueryVariables>(AlertsDocument, options);
        }
export function useAlertsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AlertsQuery, Operation.AlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AlertsQuery, Operation.AlertsQueryVariables>(AlertsDocument, options);
        }
export type AlertsQueryHookResult = ReturnType<typeof useAlertsQuery>;
export type AlertsLazyQueryHookResult = ReturnType<typeof useAlertsLazyQuery>;
export type AlertsSuspenseQueryHookResult = ReturnType<typeof useAlertsSuspenseQuery>;
export type AlertsQueryResult = Apollo.QueryResult<Operation.AlertsQuery, Operation.AlertsQueryVariables>;
export const UserPracticeConnectionAlertsDocument = gql`
    query UserPracticeConnectionAlerts($byUpcId: ID!) {
  userPracticeConnectionAlerts(byUpcId: $byUpcId) {
    edges {
      cursor
      node {
        ...userPracticeConnectionAlertDetailed
      }
    }
  }
}
    ${UserPracticeConnectionAlertDetailedFragmentDoc}`;

/**
 * __useUserPracticeConnectionAlertsQuery__
 *
 * To run a query within a React component, call `useUserPracticeConnectionAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPracticeConnectionAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPracticeConnectionAlertsQuery({
 *   variables: {
 *      byUpcId: // value for 'byUpcId'
 *   },
 * });
 */
export function useUserPracticeConnectionAlertsQuery(baseOptions: Apollo.QueryHookOptions<Operation.UserPracticeConnectionAlertsQuery, Operation.UserPracticeConnectionAlertsQueryVariables> & ({ variables: Operation.UserPracticeConnectionAlertsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserPracticeConnectionAlertsQuery, Operation.UserPracticeConnectionAlertsQueryVariables>(UserPracticeConnectionAlertsDocument, options);
      }
export function useUserPracticeConnectionAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserPracticeConnectionAlertsQuery, Operation.UserPracticeConnectionAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserPracticeConnectionAlertsQuery, Operation.UserPracticeConnectionAlertsQueryVariables>(UserPracticeConnectionAlertsDocument, options);
        }
export function useUserPracticeConnectionAlertsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserPracticeConnectionAlertsQuery, Operation.UserPracticeConnectionAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserPracticeConnectionAlertsQuery, Operation.UserPracticeConnectionAlertsQueryVariables>(UserPracticeConnectionAlertsDocument, options);
        }
export type UserPracticeConnectionAlertsQueryHookResult = ReturnType<typeof useUserPracticeConnectionAlertsQuery>;
export type UserPracticeConnectionAlertsLazyQueryHookResult = ReturnType<typeof useUserPracticeConnectionAlertsLazyQuery>;
export type UserPracticeConnectionAlertsSuspenseQueryHookResult = ReturnType<typeof useUserPracticeConnectionAlertsSuspenseQuery>;
export type UserPracticeConnectionAlertsQueryResult = Apollo.QueryResult<Operation.UserPracticeConnectionAlertsQuery, Operation.UserPracticeConnectionAlertsQueryVariables>;
export const EnvironmentDocument = gql`
    query Environment {
  environment {
    ...appEnv
  }
}
    ${AppEnvFragmentDoc}`;

/**
 * __useEnvironmentQuery__
 *
 * To run a query within a React component, call `useEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnvironmentQuery(baseOptions?: Apollo.QueryHookOptions<Operation.EnvironmentQuery, Operation.EnvironmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.EnvironmentQuery, Operation.EnvironmentQueryVariables>(EnvironmentDocument, options);
      }
export function useEnvironmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.EnvironmentQuery, Operation.EnvironmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.EnvironmentQuery, Operation.EnvironmentQueryVariables>(EnvironmentDocument, options);
        }
export function useEnvironmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.EnvironmentQuery, Operation.EnvironmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.EnvironmentQuery, Operation.EnvironmentQueryVariables>(EnvironmentDocument, options);
        }
export type EnvironmentQueryHookResult = ReturnType<typeof useEnvironmentQuery>;
export type EnvironmentLazyQueryHookResult = ReturnType<typeof useEnvironmentLazyQuery>;
export type EnvironmentSuspenseQueryHookResult = ReturnType<typeof useEnvironmentSuspenseQuery>;
export type EnvironmentQueryResult = Apollo.QueryResult<Operation.EnvironmentQuery, Operation.EnvironmentQueryVariables>;
export const HealthStatusDocument = gql`
    query HealthStatus {
  health {
    bundleVersion
    commit
    buildTime
    version
    ready
    migrated
  }
}
    `;

/**
 * __useHealthStatusQuery__
 *
 * To run a query within a React component, call `useHealthStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthStatusQuery(baseOptions?: Apollo.QueryHookOptions<Operation.HealthStatusQuery, Operation.HealthStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.HealthStatusQuery, Operation.HealthStatusQueryVariables>(HealthStatusDocument, options);
      }
export function useHealthStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.HealthStatusQuery, Operation.HealthStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.HealthStatusQuery, Operation.HealthStatusQueryVariables>(HealthStatusDocument, options);
        }
export function useHealthStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.HealthStatusQuery, Operation.HealthStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.HealthStatusQuery, Operation.HealthStatusQueryVariables>(HealthStatusDocument, options);
        }
export type HealthStatusQueryHookResult = ReturnType<typeof useHealthStatusQuery>;
export type HealthStatusLazyQueryHookResult = ReturnType<typeof useHealthStatusLazyQuery>;
export type HealthStatusSuspenseQueryHookResult = ReturnType<typeof useHealthStatusSuspenseQuery>;
export type HealthStatusQueryResult = Apollo.QueryResult<Operation.HealthStatusQuery, Operation.HealthStatusQueryVariables>;
export const AvailabilityDocument = gql`
    query Availability($date: ISO8601Date!, $limit: Int, $days: [CalendarDay!], $searchDays: Int, $slotInterval: Int, $sessions: [CalendarSession!], $minGapBetweenAppointments: Int, $maxGapBetweenAppointments: Int, $availabilities: [CalendarAvailabilityInput!]) {
  calendarNextAvailability(
    date: $date
    limit: $limit
    searchDays: $searchDays
    slotInterval: $slotInterval
    days: $days
    sessions: $sessions
    minGapBetweenAppointments: $minGapBetweenAppointments
    maxGapBetweenAppointments: $maxGapBetweenAppointments
    availabilities: $availabilities
  ) {
    ...availabilityResult
  }
}
    ${AvailabilityResultFragmentDoc}`;

/**
 * __useAvailabilityQuery__
 *
 * To run a query within a React component, call `useAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityQuery({
 *   variables: {
 *      date: // value for 'date'
 *      limit: // value for 'limit'
 *      days: // value for 'days'
 *      searchDays: // value for 'searchDays'
 *      slotInterval: // value for 'slotInterval'
 *      sessions: // value for 'sessions'
 *      minGapBetweenAppointments: // value for 'minGapBetweenAppointments'
 *      maxGapBetweenAppointments: // value for 'maxGapBetweenAppointments'
 *      availabilities: // value for 'availabilities'
 *   },
 * });
 */
export function useAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<Operation.AvailabilityQuery, Operation.AvailabilityQueryVariables> & ({ variables: Operation.AvailabilityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AvailabilityQuery, Operation.AvailabilityQueryVariables>(AvailabilityDocument, options);
      }
export function useAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AvailabilityQuery, Operation.AvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AvailabilityQuery, Operation.AvailabilityQueryVariables>(AvailabilityDocument, options);
        }
export function useAvailabilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AvailabilityQuery, Operation.AvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AvailabilityQuery, Operation.AvailabilityQueryVariables>(AvailabilityDocument, options);
        }
export type AvailabilityQueryHookResult = ReturnType<typeof useAvailabilityQuery>;
export type AvailabilityLazyQueryHookResult = ReturnType<typeof useAvailabilityLazyQuery>;
export type AvailabilitySuspenseQueryHookResult = ReturnType<typeof useAvailabilitySuspenseQuery>;
export type AvailabilityQueryResult = Apollo.QueryResult<Operation.AvailabilityQuery, Operation.AvailabilityQueryVariables>;
export const BadgesMinimalDocument = gql`
    query BadgesMinimal($uuid: [ID!]!, $type: [BadgesTemplateType!]) {
  badges(uuid: $uuid, templateType: $type) {
    ...badgeLinkMinimal
  }
}
    ${BadgeLinkMinimalFragmentDoc}`;

/**
 * __useBadgesMinimalQuery__
 *
 * To run a query within a React component, call `useBadgesMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgesMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgesMinimalQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useBadgesMinimalQuery(baseOptions: Apollo.QueryHookOptions<Operation.BadgesMinimalQuery, Operation.BadgesMinimalQueryVariables> & ({ variables: Operation.BadgesMinimalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.BadgesMinimalQuery, Operation.BadgesMinimalQueryVariables>(BadgesMinimalDocument, options);
      }
export function useBadgesMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.BadgesMinimalQuery, Operation.BadgesMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.BadgesMinimalQuery, Operation.BadgesMinimalQueryVariables>(BadgesMinimalDocument, options);
        }
export function useBadgesMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.BadgesMinimalQuery, Operation.BadgesMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.BadgesMinimalQuery, Operation.BadgesMinimalQueryVariables>(BadgesMinimalDocument, options);
        }
export type BadgesMinimalQueryHookResult = ReturnType<typeof useBadgesMinimalQuery>;
export type BadgesMinimalLazyQueryHookResult = ReturnType<typeof useBadgesMinimalLazyQuery>;
export type BadgesMinimalSuspenseQueryHookResult = ReturnType<typeof useBadgesMinimalSuspenseQuery>;
export type BadgesMinimalQueryResult = Apollo.QueryResult<Operation.BadgesMinimalQuery, Operation.BadgesMinimalQueryVariables>;
export const BadgesDocument = gql`
    query Badges($uuid: [ID!]!, $type: [BadgesTemplateType!]) {
  badges(uuid: $uuid, templateType: $type) {
    ...badgeLinkEssentials
  }
}
    ${BadgeLinkEssentialsFragmentDoc}`;

/**
 * __useBadgesQuery__
 *
 * To run a query within a React component, call `useBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgesQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useBadgesQuery(baseOptions: Apollo.QueryHookOptions<Operation.BadgesQuery, Operation.BadgesQueryVariables> & ({ variables: Operation.BadgesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.BadgesQuery, Operation.BadgesQueryVariables>(BadgesDocument, options);
      }
export function useBadgesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.BadgesQuery, Operation.BadgesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.BadgesQuery, Operation.BadgesQueryVariables>(BadgesDocument, options);
        }
export function useBadgesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.BadgesQuery, Operation.BadgesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.BadgesQuery, Operation.BadgesQueryVariables>(BadgesDocument, options);
        }
export type BadgesQueryHookResult = ReturnType<typeof useBadgesQuery>;
export type BadgesLazyQueryHookResult = ReturnType<typeof useBadgesLazyQuery>;
export type BadgesSuspenseQueryHookResult = ReturnType<typeof useBadgesSuspenseQuery>;
export type BadgesQueryResult = Apollo.QueryResult<Operation.BadgesQuery, Operation.BadgesQueryVariables>;
export const BadgesTemplateTypesDocument = gql`
    query BadgesTemplateTypes {
  badgesTemplateTypes {
    ...badgesTemplatesTemplateType
  }
}
    ${BadgesTemplatesTemplateTypeFragmentDoc}`;

/**
 * __useBadgesTemplateTypesQuery__
 *
 * To run a query within a React component, call `useBadgesTemplateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgesTemplateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgesTemplateTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBadgesTemplateTypesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.BadgesTemplateTypesQuery, Operation.BadgesTemplateTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.BadgesTemplateTypesQuery, Operation.BadgesTemplateTypesQueryVariables>(BadgesTemplateTypesDocument, options);
      }
export function useBadgesTemplateTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.BadgesTemplateTypesQuery, Operation.BadgesTemplateTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.BadgesTemplateTypesQuery, Operation.BadgesTemplateTypesQueryVariables>(BadgesTemplateTypesDocument, options);
        }
export function useBadgesTemplateTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.BadgesTemplateTypesQuery, Operation.BadgesTemplateTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.BadgesTemplateTypesQuery, Operation.BadgesTemplateTypesQueryVariables>(BadgesTemplateTypesDocument, options);
        }
export type BadgesTemplateTypesQueryHookResult = ReturnType<typeof useBadgesTemplateTypesQuery>;
export type BadgesTemplateTypesLazyQueryHookResult = ReturnType<typeof useBadgesTemplateTypesLazyQuery>;
export type BadgesTemplateTypesSuspenseQueryHookResult = ReturnType<typeof useBadgesTemplateTypesSuspenseQuery>;
export type BadgesTemplateTypesQueryResult = Apollo.QueryResult<Operation.BadgesTemplateTypesQuery, Operation.BadgesTemplateTypesQueryVariables>;
export const BadgesTemplatesMinimalDocument = gql`
    query BadgesTemplatesMinimal($first: Int, $after: String, $before: String, $search: String, $slugs: [String!], $ids: [ID!], $templateTypes: [BadgesTemplateType!], $archived: Boolean) {
  badgesTemplates(
    first: $first
    after: $after
    before: $before
    search: $search
    slugs: $slugs
    ids: $ids
    templateTypes: $templateTypes
    archived: $archived
  ) {
    edges {
      node {
        ...badgesTemplateMinimal
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${BadgesTemplateMinimalFragmentDoc}`;

/**
 * __useBadgesTemplatesMinimalQuery__
 *
 * To run a query within a React component, call `useBadgesTemplatesMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgesTemplatesMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgesTemplatesMinimalQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      slugs: // value for 'slugs'
 *      ids: // value for 'ids'
 *      templateTypes: // value for 'templateTypes'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useBadgesTemplatesMinimalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.BadgesTemplatesMinimalQuery, Operation.BadgesTemplatesMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.BadgesTemplatesMinimalQuery, Operation.BadgesTemplatesMinimalQueryVariables>(BadgesTemplatesMinimalDocument, options);
      }
export function useBadgesTemplatesMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.BadgesTemplatesMinimalQuery, Operation.BadgesTemplatesMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.BadgesTemplatesMinimalQuery, Operation.BadgesTemplatesMinimalQueryVariables>(BadgesTemplatesMinimalDocument, options);
        }
export function useBadgesTemplatesMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.BadgesTemplatesMinimalQuery, Operation.BadgesTemplatesMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.BadgesTemplatesMinimalQuery, Operation.BadgesTemplatesMinimalQueryVariables>(BadgesTemplatesMinimalDocument, options);
        }
export type BadgesTemplatesMinimalQueryHookResult = ReturnType<typeof useBadgesTemplatesMinimalQuery>;
export type BadgesTemplatesMinimalLazyQueryHookResult = ReturnType<typeof useBadgesTemplatesMinimalLazyQuery>;
export type BadgesTemplatesMinimalSuspenseQueryHookResult = ReturnType<typeof useBadgesTemplatesMinimalSuspenseQuery>;
export type BadgesTemplatesMinimalQueryResult = Apollo.QueryResult<Operation.BadgesTemplatesMinimalQuery, Operation.BadgesTemplatesMinimalQueryVariables>;
export const BadgesTemplatesDocument = gql`
    query BadgesTemplates($first: Int, $after: String, $before: String, $search: String, $slugs: [String!], $ids: [ID!], $templateTypes: [BadgesTemplateType!], $archived: Boolean) {
  badgesTemplates(
    first: $first
    after: $after
    before: $before
    search: $search
    slugs: $slugs
    ids: $ids
    templateTypes: $templateTypes
    archived: $archived
  ) {
    edges {
      node {
        ...badgesTemplateEssentials
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${BadgesTemplateEssentialsFragmentDoc}`;

/**
 * __useBadgesTemplatesQuery__
 *
 * To run a query within a React component, call `useBadgesTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgesTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgesTemplatesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      slugs: // value for 'slugs'
 *      ids: // value for 'ids'
 *      templateTypes: // value for 'templateTypes'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useBadgesTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.BadgesTemplatesQuery, Operation.BadgesTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.BadgesTemplatesQuery, Operation.BadgesTemplatesQueryVariables>(BadgesTemplatesDocument, options);
      }
export function useBadgesTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.BadgesTemplatesQuery, Operation.BadgesTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.BadgesTemplatesQuery, Operation.BadgesTemplatesQueryVariables>(BadgesTemplatesDocument, options);
        }
export function useBadgesTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.BadgesTemplatesQuery, Operation.BadgesTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.BadgesTemplatesQuery, Operation.BadgesTemplatesQueryVariables>(BadgesTemplatesDocument, options);
        }
export type BadgesTemplatesQueryHookResult = ReturnType<typeof useBadgesTemplatesQuery>;
export type BadgesTemplatesLazyQueryHookResult = ReturnType<typeof useBadgesTemplatesLazyQuery>;
export type BadgesTemplatesSuspenseQueryHookResult = ReturnType<typeof useBadgesTemplatesSuspenseQuery>;
export type BadgesTemplatesQueryResult = Apollo.QueryResult<Operation.BadgesTemplatesQuery, Operation.BadgesTemplatesQueryVariables>;
export const BadgesTemplateEssentialsDocument = gql`
    query BadgesTemplateEssentials($id: ID!) {
  badgesTemplate(id: $id) {
    ...badgesTemplateEssentials
  }
}
    ${BadgesTemplateEssentialsFragmentDoc}`;

/**
 * __useBadgesTemplateEssentialsQuery__
 *
 * To run a query within a React component, call `useBadgesTemplateEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgesTemplateEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgesTemplateEssentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBadgesTemplateEssentialsQuery(baseOptions: Apollo.QueryHookOptions<Operation.BadgesTemplateEssentialsQuery, Operation.BadgesTemplateEssentialsQueryVariables> & ({ variables: Operation.BadgesTemplateEssentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.BadgesTemplateEssentialsQuery, Operation.BadgesTemplateEssentialsQueryVariables>(BadgesTemplateEssentialsDocument, options);
      }
export function useBadgesTemplateEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.BadgesTemplateEssentialsQuery, Operation.BadgesTemplateEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.BadgesTemplateEssentialsQuery, Operation.BadgesTemplateEssentialsQueryVariables>(BadgesTemplateEssentialsDocument, options);
        }
export function useBadgesTemplateEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.BadgesTemplateEssentialsQuery, Operation.BadgesTemplateEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.BadgesTemplateEssentialsQuery, Operation.BadgesTemplateEssentialsQueryVariables>(BadgesTemplateEssentialsDocument, options);
        }
export type BadgesTemplateEssentialsQueryHookResult = ReturnType<typeof useBadgesTemplateEssentialsQuery>;
export type BadgesTemplateEssentialsLazyQueryHookResult = ReturnType<typeof useBadgesTemplateEssentialsLazyQuery>;
export type BadgesTemplateEssentialsSuspenseQueryHookResult = ReturnType<typeof useBadgesTemplateEssentialsSuspenseQuery>;
export type BadgesTemplateEssentialsQueryResult = Apollo.QueryResult<Operation.BadgesTemplateEssentialsQuery, Operation.BadgesTemplateEssentialsQueryVariables>;
export const BadgesTemplateDetailedDocument = gql`
    query BadgesTemplateDetailed($id: ID!) {
  badgesTemplate(id: $id) {
    ...badgesTemplateDetailed
  }
}
    ${BadgesTemplateDetailedFragmentDoc}`;

/**
 * __useBadgesTemplateDetailedQuery__
 *
 * To run a query within a React component, call `useBadgesTemplateDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgesTemplateDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgesTemplateDetailedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBadgesTemplateDetailedQuery(baseOptions: Apollo.QueryHookOptions<Operation.BadgesTemplateDetailedQuery, Operation.BadgesTemplateDetailedQueryVariables> & ({ variables: Operation.BadgesTemplateDetailedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.BadgesTemplateDetailedQuery, Operation.BadgesTemplateDetailedQueryVariables>(BadgesTemplateDetailedDocument, options);
      }
export function useBadgesTemplateDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.BadgesTemplateDetailedQuery, Operation.BadgesTemplateDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.BadgesTemplateDetailedQuery, Operation.BadgesTemplateDetailedQueryVariables>(BadgesTemplateDetailedDocument, options);
        }
export function useBadgesTemplateDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.BadgesTemplateDetailedQuery, Operation.BadgesTemplateDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.BadgesTemplateDetailedQuery, Operation.BadgesTemplateDetailedQueryVariables>(BadgesTemplateDetailedDocument, options);
        }
export type BadgesTemplateDetailedQueryHookResult = ReturnType<typeof useBadgesTemplateDetailedQuery>;
export type BadgesTemplateDetailedLazyQueryHookResult = ReturnType<typeof useBadgesTemplateDetailedLazyQuery>;
export type BadgesTemplateDetailedSuspenseQueryHookResult = ReturnType<typeof useBadgesTemplateDetailedSuspenseQuery>;
export type BadgesTemplateDetailedQueryResult = Apollo.QueryResult<Operation.BadgesTemplateDetailedQuery, Operation.BadgesTemplateDetailedQueryVariables>;
export const AppointmentDocument = gql`
    query Appointment($id: ID, $slug: String) {
  appointment(id: $id, slug: $slug) {
    ...appointmentCalendarEventDetailed
  }
}
    ${AppointmentCalendarEventDetailedFragmentDoc}`;

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAppointmentQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AppointmentQuery, Operation.AppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentQuery, Operation.AppointmentQueryVariables>(AppointmentDocument, options);
      }
export function useAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentQuery, Operation.AppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentQuery, Operation.AppointmentQueryVariables>(AppointmentDocument, options);
        }
export function useAppointmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentQuery, Operation.AppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentQuery, Operation.AppointmentQueryVariables>(AppointmentDocument, options);
        }
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>;
export type AppointmentLazyQueryHookResult = ReturnType<typeof useAppointmentLazyQuery>;
export type AppointmentSuspenseQueryHookResult = ReturnType<typeof useAppointmentSuspenseQuery>;
export type AppointmentQueryResult = Apollo.QueryResult<Operation.AppointmentQuery, Operation.AppointmentQueryVariables>;
export const AppointmentGuestDetailsDocument = gql`
    query AppointmentGuestDetails($id: ID, $slug: String) {
  appointment(id: $id, slug: $slug) {
    ...appointmentGuestDetails
  }
}
    ${AppointmentGuestDetailsFragmentDoc}`;

/**
 * __useAppointmentGuestDetailsQuery__
 *
 * To run a query within a React component, call `useAppointmentGuestDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentGuestDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentGuestDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAppointmentGuestDetailsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AppointmentGuestDetailsQuery, Operation.AppointmentGuestDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentGuestDetailsQuery, Operation.AppointmentGuestDetailsQueryVariables>(AppointmentGuestDetailsDocument, options);
      }
export function useAppointmentGuestDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentGuestDetailsQuery, Operation.AppointmentGuestDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentGuestDetailsQuery, Operation.AppointmentGuestDetailsQueryVariables>(AppointmentGuestDetailsDocument, options);
        }
export function useAppointmentGuestDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentGuestDetailsQuery, Operation.AppointmentGuestDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentGuestDetailsQuery, Operation.AppointmentGuestDetailsQueryVariables>(AppointmentGuestDetailsDocument, options);
        }
export type AppointmentGuestDetailsQueryHookResult = ReturnType<typeof useAppointmentGuestDetailsQuery>;
export type AppointmentGuestDetailsLazyQueryHookResult = ReturnType<typeof useAppointmentGuestDetailsLazyQuery>;
export type AppointmentGuestDetailsSuspenseQueryHookResult = ReturnType<typeof useAppointmentGuestDetailsSuspenseQuery>;
export type AppointmentGuestDetailsQueryResult = Apollo.QueryResult<Operation.AppointmentGuestDetailsQuery, Operation.AppointmentGuestDetailsQueryVariables>;
export const AppointmentCalendarDisplayDocument = gql`
    query AppointmentCalendarDisplay($id: ID, $slug: String) {
  appointment(id: $id, slug: $slug) {
    ...appointmentExhaustive
  }
}
    ${AppointmentExhaustiveFragmentDoc}`;

/**
 * __useAppointmentCalendarDisplayQuery__
 *
 * To run a query within a React component, call `useAppointmentCalendarDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentCalendarDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentCalendarDisplayQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAppointmentCalendarDisplayQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AppointmentCalendarDisplayQuery, Operation.AppointmentCalendarDisplayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentCalendarDisplayQuery, Operation.AppointmentCalendarDisplayQueryVariables>(AppointmentCalendarDisplayDocument, options);
      }
export function useAppointmentCalendarDisplayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentCalendarDisplayQuery, Operation.AppointmentCalendarDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentCalendarDisplayQuery, Operation.AppointmentCalendarDisplayQueryVariables>(AppointmentCalendarDisplayDocument, options);
        }
export function useAppointmentCalendarDisplaySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentCalendarDisplayQuery, Operation.AppointmentCalendarDisplayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentCalendarDisplayQuery, Operation.AppointmentCalendarDisplayQueryVariables>(AppointmentCalendarDisplayDocument, options);
        }
export type AppointmentCalendarDisplayQueryHookResult = ReturnType<typeof useAppointmentCalendarDisplayQuery>;
export type AppointmentCalendarDisplayLazyQueryHookResult = ReturnType<typeof useAppointmentCalendarDisplayLazyQuery>;
export type AppointmentCalendarDisplaySuspenseQueryHookResult = ReturnType<typeof useAppointmentCalendarDisplaySuspenseQuery>;
export type AppointmentCalendarDisplayQueryResult = Apollo.QueryResult<Operation.AppointmentCalendarDisplayQuery, Operation.AppointmentCalendarDisplayQueryVariables>;
export const AppointmentExhaustiveDocument = gql`
    query AppointmentExhaustive($id: ID, $slug: String) {
  appointment(id: $id, slug: $slug) {
    ...appointmentExhaustive
  }
}
    ${AppointmentExhaustiveFragmentDoc}`;

/**
 * __useAppointmentExhaustiveQuery__
 *
 * To run a query within a React component, call `useAppointmentExhaustiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentExhaustiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentExhaustiveQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAppointmentExhaustiveQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AppointmentExhaustiveQuery, Operation.AppointmentExhaustiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentExhaustiveQuery, Operation.AppointmentExhaustiveQueryVariables>(AppointmentExhaustiveDocument, options);
      }
export function useAppointmentExhaustiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentExhaustiveQuery, Operation.AppointmentExhaustiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentExhaustiveQuery, Operation.AppointmentExhaustiveQueryVariables>(AppointmentExhaustiveDocument, options);
        }
export function useAppointmentExhaustiveSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentExhaustiveQuery, Operation.AppointmentExhaustiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentExhaustiveQuery, Operation.AppointmentExhaustiveQueryVariables>(AppointmentExhaustiveDocument, options);
        }
export type AppointmentExhaustiveQueryHookResult = ReturnType<typeof useAppointmentExhaustiveQuery>;
export type AppointmentExhaustiveLazyQueryHookResult = ReturnType<typeof useAppointmentExhaustiveLazyQuery>;
export type AppointmentExhaustiveSuspenseQueryHookResult = ReturnType<typeof useAppointmentExhaustiveSuspenseQuery>;
export type AppointmentExhaustiveQueryResult = Apollo.QueryResult<Operation.AppointmentExhaustiveQuery, Operation.AppointmentExhaustiveQueryVariables>;
export const AppointmentCommunicationSettingDocument = gql`
    query AppointmentCommunicationSetting($appointmentGuid: ID, $patientGuid: ID!, $startTime: ISO8601DateTime!) {
  appointmentCommunicationSetting(
    appointmentGuid: $appointmentGuid
    patientGuid: $patientGuid
    startTime: $startTime
  ) {
    ...appointmentCommunicationSetting
  }
}
    ${AppointmentCommunicationSettingFragmentDoc}`;

/**
 * __useAppointmentCommunicationSettingQuery__
 *
 * To run a query within a React component, call `useAppointmentCommunicationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentCommunicationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentCommunicationSettingQuery({
 *   variables: {
 *      appointmentGuid: // value for 'appointmentGuid'
 *      patientGuid: // value for 'patientGuid'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useAppointmentCommunicationSettingQuery(baseOptions: Apollo.QueryHookOptions<Operation.AppointmentCommunicationSettingQuery, Operation.AppointmentCommunicationSettingQueryVariables> & ({ variables: Operation.AppointmentCommunicationSettingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentCommunicationSettingQuery, Operation.AppointmentCommunicationSettingQueryVariables>(AppointmentCommunicationSettingDocument, options);
      }
export function useAppointmentCommunicationSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentCommunicationSettingQuery, Operation.AppointmentCommunicationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentCommunicationSettingQuery, Operation.AppointmentCommunicationSettingQueryVariables>(AppointmentCommunicationSettingDocument, options);
        }
export function useAppointmentCommunicationSettingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentCommunicationSettingQuery, Operation.AppointmentCommunicationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentCommunicationSettingQuery, Operation.AppointmentCommunicationSettingQueryVariables>(AppointmentCommunicationSettingDocument, options);
        }
export type AppointmentCommunicationSettingQueryHookResult = ReturnType<typeof useAppointmentCommunicationSettingQuery>;
export type AppointmentCommunicationSettingLazyQueryHookResult = ReturnType<typeof useAppointmentCommunicationSettingLazyQuery>;
export type AppointmentCommunicationSettingSuspenseQueryHookResult = ReturnType<typeof useAppointmentCommunicationSettingSuspenseQuery>;
export type AppointmentCommunicationSettingQueryResult = Apollo.QueryResult<Operation.AppointmentCommunicationSettingQuery, Operation.AppointmentCommunicationSettingQueryVariables>;
export const AppointmentGuestPublicPageDocument = gql`
    query AppointmentGuestPublicPage($token: String!) {
  appointmentGuest(token: $token) {
    ...appointmentGuestPublicPage
  }
}
    ${AppointmentGuestPublicPageFragmentDoc}`;

/**
 * __useAppointmentGuestPublicPageQuery__
 *
 * To run a query within a React component, call `useAppointmentGuestPublicPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentGuestPublicPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentGuestPublicPageQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAppointmentGuestPublicPageQuery(baseOptions: Apollo.QueryHookOptions<Operation.AppointmentGuestPublicPageQuery, Operation.AppointmentGuestPublicPageQueryVariables> & ({ variables: Operation.AppointmentGuestPublicPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentGuestPublicPageQuery, Operation.AppointmentGuestPublicPageQueryVariables>(AppointmentGuestPublicPageDocument, options);
      }
export function useAppointmentGuestPublicPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentGuestPublicPageQuery, Operation.AppointmentGuestPublicPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentGuestPublicPageQuery, Operation.AppointmentGuestPublicPageQueryVariables>(AppointmentGuestPublicPageDocument, options);
        }
export function useAppointmentGuestPublicPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentGuestPublicPageQuery, Operation.AppointmentGuestPublicPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentGuestPublicPageQuery, Operation.AppointmentGuestPublicPageQueryVariables>(AppointmentGuestPublicPageDocument, options);
        }
export type AppointmentGuestPublicPageQueryHookResult = ReturnType<typeof useAppointmentGuestPublicPageQuery>;
export type AppointmentGuestPublicPageLazyQueryHookResult = ReturnType<typeof useAppointmentGuestPublicPageLazyQuery>;
export type AppointmentGuestPublicPageSuspenseQueryHookResult = ReturnType<typeof useAppointmentGuestPublicPageSuspenseQuery>;
export type AppointmentGuestPublicPageQueryResult = Apollo.QueryResult<Operation.AppointmentGuestPublicPageQuery, Operation.AppointmentGuestPublicPageQueryVariables>;
export const AppointmentGuestsDocument = gql`
    query AppointmentGuests($patient: [ID!]!, $includeFamily: Boolean, $timePeriod: AppointmentTimePeriods, $withTreatment: Boolean, $state: [AppointmentGuestState!]) {
  appointmentGuests(
    upc: $patient
    includeFamily: $includeFamily
    timePeriod: $timePeriod
    withTreatment: $withTreatment
    state: $state
  ) {
    edges {
      node {
        ...appointmentGuestListItem
      }
    }
  }
}
    ${AppointmentGuestListItemFragmentDoc}`;

/**
 * __useAppointmentGuestsQuery__
 *
 * To run a query within a React component, call `useAppointmentGuestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentGuestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentGuestsQuery({
 *   variables: {
 *      patient: // value for 'patient'
 *      includeFamily: // value for 'includeFamily'
 *      timePeriod: // value for 'timePeriod'
 *      withTreatment: // value for 'withTreatment'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useAppointmentGuestsQuery(baseOptions: Apollo.QueryHookOptions<Operation.AppointmentGuestsQuery, Operation.AppointmentGuestsQueryVariables> & ({ variables: Operation.AppointmentGuestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentGuestsQuery, Operation.AppointmentGuestsQueryVariables>(AppointmentGuestsDocument, options);
      }
export function useAppointmentGuestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentGuestsQuery, Operation.AppointmentGuestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentGuestsQuery, Operation.AppointmentGuestsQueryVariables>(AppointmentGuestsDocument, options);
        }
export function useAppointmentGuestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentGuestsQuery, Operation.AppointmentGuestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentGuestsQuery, Operation.AppointmentGuestsQueryVariables>(AppointmentGuestsDocument, options);
        }
export type AppointmentGuestsQueryHookResult = ReturnType<typeof useAppointmentGuestsQuery>;
export type AppointmentGuestsLazyQueryHookResult = ReturnType<typeof useAppointmentGuestsLazyQuery>;
export type AppointmentGuestsSuspenseQueryHookResult = ReturnType<typeof useAppointmentGuestsSuspenseQuery>;
export type AppointmentGuestsQueryResult = Apollo.QueryResult<Operation.AppointmentGuestsQuery, Operation.AppointmentGuestsQueryVariables>;
export const AppointmentReasonsDocument = gql`
    query AppointmentReasons($online: Boolean, $after: String, $first: Int, $reasonableType: [String!], $practiceIds: [ID!], $practitionerIds: [ID!], $byState: [AppointmentReasonState!], $search: String, $visibility: [AppointmentReasonVisibility!]) {
  appointmentReasons(
    online: $online
    after: $after
    first: $first
    reasonableType: $reasonableType
    practiceIds: $practiceIds
    practitionerIds: $practitionerIds
    byState: $byState
    search: $search
    visibility: $visibility
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...appointmentReasonDetailed
      }
    }
  }
}
    ${AppointmentReasonDetailedFragmentDoc}`;

/**
 * __useAppointmentReasonsQuery__
 *
 * To run a query within a React component, call `useAppointmentReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentReasonsQuery({
 *   variables: {
 *      online: // value for 'online'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      reasonableType: // value for 'reasonableType'
 *      practiceIds: // value for 'practiceIds'
 *      practitionerIds: // value for 'practitionerIds'
 *      byState: // value for 'byState'
 *      search: // value for 'search'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useAppointmentReasonsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AppointmentReasonsQuery, Operation.AppointmentReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentReasonsQuery, Operation.AppointmentReasonsQueryVariables>(AppointmentReasonsDocument, options);
      }
export function useAppointmentReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentReasonsQuery, Operation.AppointmentReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentReasonsQuery, Operation.AppointmentReasonsQueryVariables>(AppointmentReasonsDocument, options);
        }
export function useAppointmentReasonsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentReasonsQuery, Operation.AppointmentReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentReasonsQuery, Operation.AppointmentReasonsQueryVariables>(AppointmentReasonsDocument, options);
        }
export type AppointmentReasonsQueryHookResult = ReturnType<typeof useAppointmentReasonsQuery>;
export type AppointmentReasonsLazyQueryHookResult = ReturnType<typeof useAppointmentReasonsLazyQuery>;
export type AppointmentReasonsSuspenseQueryHookResult = ReturnType<typeof useAppointmentReasonsSuspenseQuery>;
export type AppointmentReasonsQueryResult = Apollo.QueryResult<Operation.AppointmentReasonsQuery, Operation.AppointmentReasonsQueryVariables>;
export const AppointmentReasonsEssentialsDocument = gql`
    query AppointmentReasonsEssentials($online: Boolean, $after: String, $first: Int, $reasonableType: [String!], $practiceIds: [ID!], $practitionerIds: [ID!], $byState: [AppointmentReasonState!], $search: String, $visibility: [AppointmentReasonVisibility!], $priceListIds: [ID!]) {
  appointmentReasons(
    online: $online
    after: $after
    first: $first
    reasonableType: $reasonableType
    practiceIds: $practiceIds
    practitionerIds: $practitionerIds
    byState: $byState
    search: $search
    visibility: $visibility
    priceListIds: $priceListIds
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...appointmentReasonsSelect
      }
    }
  }
}
    ${AppointmentReasonsSelectFragmentDoc}`;

/**
 * __useAppointmentReasonsEssentialsQuery__
 *
 * To run a query within a React component, call `useAppointmentReasonsEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentReasonsEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentReasonsEssentialsQuery({
 *   variables: {
 *      online: // value for 'online'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      reasonableType: // value for 'reasonableType'
 *      practiceIds: // value for 'practiceIds'
 *      practitionerIds: // value for 'practitionerIds'
 *      byState: // value for 'byState'
 *      search: // value for 'search'
 *      visibility: // value for 'visibility'
 *      priceListIds: // value for 'priceListIds'
 *   },
 * });
 */
export function useAppointmentReasonsEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AppointmentReasonsEssentialsQuery, Operation.AppointmentReasonsEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentReasonsEssentialsQuery, Operation.AppointmentReasonsEssentialsQueryVariables>(AppointmentReasonsEssentialsDocument, options);
      }
export function useAppointmentReasonsEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentReasonsEssentialsQuery, Operation.AppointmentReasonsEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentReasonsEssentialsQuery, Operation.AppointmentReasonsEssentialsQueryVariables>(AppointmentReasonsEssentialsDocument, options);
        }
export function useAppointmentReasonsEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentReasonsEssentialsQuery, Operation.AppointmentReasonsEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentReasonsEssentialsQuery, Operation.AppointmentReasonsEssentialsQueryVariables>(AppointmentReasonsEssentialsDocument, options);
        }
export type AppointmentReasonsEssentialsQueryHookResult = ReturnType<typeof useAppointmentReasonsEssentialsQuery>;
export type AppointmentReasonsEssentialsLazyQueryHookResult = ReturnType<typeof useAppointmentReasonsEssentialsLazyQuery>;
export type AppointmentReasonsEssentialsSuspenseQueryHookResult = ReturnType<typeof useAppointmentReasonsEssentialsSuspenseQuery>;
export type AppointmentReasonsEssentialsQueryResult = Apollo.QueryResult<Operation.AppointmentReasonsEssentialsQuery, Operation.AppointmentReasonsEssentialsQueryVariables>;
export const AppointmentSummaryDocument = gql`
    query AppointmentSummary($userPracticeConnectionId: ID, $period: String) {
  appointmentSummary(
    userPracticeConnectionId: $userPracticeConnectionId
    period: $period
  ) {
    ...appointmentSummaryEssentials
  }
}
    ${AppointmentSummaryEssentialsFragmentDoc}`;

/**
 * __useAppointmentSummaryQuery__
 *
 * To run a query within a React component, call `useAppointmentSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentSummaryQuery({
 *   variables: {
 *      userPracticeConnectionId: // value for 'userPracticeConnectionId'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useAppointmentSummaryQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AppointmentSummaryQuery, Operation.AppointmentSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentSummaryQuery, Operation.AppointmentSummaryQueryVariables>(AppointmentSummaryDocument, options);
      }
export function useAppointmentSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentSummaryQuery, Operation.AppointmentSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentSummaryQuery, Operation.AppointmentSummaryQueryVariables>(AppointmentSummaryDocument, options);
        }
export function useAppointmentSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentSummaryQuery, Operation.AppointmentSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentSummaryQuery, Operation.AppointmentSummaryQueryVariables>(AppointmentSummaryDocument, options);
        }
export type AppointmentSummaryQueryHookResult = ReturnType<typeof useAppointmentSummaryQuery>;
export type AppointmentSummaryLazyQueryHookResult = ReturnType<typeof useAppointmentSummaryLazyQuery>;
export type AppointmentSummarySuspenseQueryHookResult = ReturnType<typeof useAppointmentSummarySuspenseQuery>;
export type AppointmentSummaryQueryResult = Apollo.QueryResult<Operation.AppointmentSummaryQuery, Operation.AppointmentSummaryQueryVariables>;
export const AppointmentsDocument = gql`
    query Appointments($attributes: CalendarSearchInput, $first: Int, $after: String) {
  appointments(attributes: $attributes, first: $first, after: $after) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
    edges {
      node {
        ...appointmentEssentials
      }
    }
  }
}
    ${AppointmentEssentialsFragmentDoc}`;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AppointmentsQuery, Operation.AppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentsQuery, Operation.AppointmentsQueryVariables>(AppointmentsDocument, options);
      }
export function useAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentsQuery, Operation.AppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentsQuery, Operation.AppointmentsQueryVariables>(AppointmentsDocument, options);
        }
export function useAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentsQuery, Operation.AppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentsQuery, Operation.AppointmentsQueryVariables>(AppointmentsDocument, options);
        }
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsSuspenseQueryHookResult = ReturnType<typeof useAppointmentsSuspenseQuery>;
export type AppointmentsQueryResult = Apollo.QueryResult<Operation.AppointmentsQuery, Operation.AppointmentsQueryVariables>;
export const AppointmentsExhaustiveDocument = gql`
    query AppointmentsExhaustive($attributes: CalendarSearchInput, $first: Int, $after: String) {
  appointments(attributes: $attributes, first: $first, after: $after) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
    edges {
      node {
        ...appointmentExhaustive
      }
    }
  }
}
    ${AppointmentExhaustiveFragmentDoc}`;

/**
 * __useAppointmentsExhaustiveQuery__
 *
 * To run a query within a React component, call `useAppointmentsExhaustiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsExhaustiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsExhaustiveQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAppointmentsExhaustiveQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AppointmentsExhaustiveQuery, Operation.AppointmentsExhaustiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentsExhaustiveQuery, Operation.AppointmentsExhaustiveQueryVariables>(AppointmentsExhaustiveDocument, options);
      }
export function useAppointmentsExhaustiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentsExhaustiveQuery, Operation.AppointmentsExhaustiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentsExhaustiveQuery, Operation.AppointmentsExhaustiveQueryVariables>(AppointmentsExhaustiveDocument, options);
        }
export function useAppointmentsExhaustiveSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentsExhaustiveQuery, Operation.AppointmentsExhaustiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentsExhaustiveQuery, Operation.AppointmentsExhaustiveQueryVariables>(AppointmentsExhaustiveDocument, options);
        }
export type AppointmentsExhaustiveQueryHookResult = ReturnType<typeof useAppointmentsExhaustiveQuery>;
export type AppointmentsExhaustiveLazyQueryHookResult = ReturnType<typeof useAppointmentsExhaustiveLazyQuery>;
export type AppointmentsExhaustiveSuspenseQueryHookResult = ReturnType<typeof useAppointmentsExhaustiveSuspenseQuery>;
export type AppointmentsExhaustiveQueryResult = Apollo.QueryResult<Operation.AppointmentsExhaustiveQuery, Operation.AppointmentsExhaustiveQueryVariables>;
export const AppointmentsAgGridDocument = gql`
    query AppointmentsAgGrid($attributes: CalendarSearchInput, $first: Int, $after: String) {
  appointments(attributes: $attributes, first: $first, after: $after) {
    pageInfo {
      ...pageInfo
    }
    edges {
      node {
        ...appointmentAgGrid
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${AppointmentAgGridFragmentDoc}`;

/**
 * __useAppointmentsAgGridQuery__
 *
 * To run a query within a React component, call `useAppointmentsAgGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsAgGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsAgGridQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAppointmentsAgGridQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AppointmentsAgGridQuery, Operation.AppointmentsAgGridQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentsAgGridQuery, Operation.AppointmentsAgGridQueryVariables>(AppointmentsAgGridDocument, options);
      }
export function useAppointmentsAgGridLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentsAgGridQuery, Operation.AppointmentsAgGridQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentsAgGridQuery, Operation.AppointmentsAgGridQueryVariables>(AppointmentsAgGridDocument, options);
        }
export function useAppointmentsAgGridSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentsAgGridQuery, Operation.AppointmentsAgGridQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentsAgGridQuery, Operation.AppointmentsAgGridQueryVariables>(AppointmentsAgGridDocument, options);
        }
export type AppointmentsAgGridQueryHookResult = ReturnType<typeof useAppointmentsAgGridQuery>;
export type AppointmentsAgGridLazyQueryHookResult = ReturnType<typeof useAppointmentsAgGridLazyQuery>;
export type AppointmentsAgGridSuspenseQueryHookResult = ReturnType<typeof useAppointmentsAgGridSuspenseQuery>;
export type AppointmentsAgGridQueryResult = Apollo.QueryResult<Operation.AppointmentsAgGridQuery, Operation.AppointmentsAgGridQueryVariables>;
export const AppointmentsHistoryDocument = gql`
    query AppointmentsHistory($attributes: CalendarSearchInput, $first: Int, $after: String) {
  appointments(attributes: $attributes, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...appointmentHistory
      }
    }
  }
}
    ${AppointmentHistoryFragmentDoc}`;

/**
 * __useAppointmentsHistoryQuery__
 *
 * To run a query within a React component, call `useAppointmentsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsHistoryQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAppointmentsHistoryQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AppointmentsHistoryQuery, Operation.AppointmentsHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentsHistoryQuery, Operation.AppointmentsHistoryQueryVariables>(AppointmentsHistoryDocument, options);
      }
export function useAppointmentsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentsHistoryQuery, Operation.AppointmentsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentsHistoryQuery, Operation.AppointmentsHistoryQueryVariables>(AppointmentsHistoryDocument, options);
        }
export function useAppointmentsHistorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentsHistoryQuery, Operation.AppointmentsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentsHistoryQuery, Operation.AppointmentsHistoryQueryVariables>(AppointmentsHistoryDocument, options);
        }
export type AppointmentsHistoryQueryHookResult = ReturnType<typeof useAppointmentsHistoryQuery>;
export type AppointmentsHistoryLazyQueryHookResult = ReturnType<typeof useAppointmentsHistoryLazyQuery>;
export type AppointmentsHistorySuspenseQueryHookResult = ReturnType<typeof useAppointmentsHistorySuspenseQuery>;
export type AppointmentsHistoryQueryResult = Apollo.QueryResult<Operation.AppointmentsHistoryQuery, Operation.AppointmentsHistoryQueryVariables>;
export const OnHoldAppointmentsDocument = gql`
    query OnHoldAppointments($first: Int, $after: String) {
  appointments(attributes: {state: [on_hold]}, first: $first, after: $after) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
    edges {
      node {
        ...appointmentsOnHold
      }
    }
  }
}
    ${AppointmentsOnHoldFragmentDoc}`;

/**
 * __useOnHoldAppointmentsQuery__
 *
 * To run a query within a React component, call `useOnHoldAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnHoldAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnHoldAppointmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useOnHoldAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.OnHoldAppointmentsQuery, Operation.OnHoldAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.OnHoldAppointmentsQuery, Operation.OnHoldAppointmentsQueryVariables>(OnHoldAppointmentsDocument, options);
      }
export function useOnHoldAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.OnHoldAppointmentsQuery, Operation.OnHoldAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.OnHoldAppointmentsQuery, Operation.OnHoldAppointmentsQueryVariables>(OnHoldAppointmentsDocument, options);
        }
export function useOnHoldAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.OnHoldAppointmentsQuery, Operation.OnHoldAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.OnHoldAppointmentsQuery, Operation.OnHoldAppointmentsQueryVariables>(OnHoldAppointmentsDocument, options);
        }
export type OnHoldAppointmentsQueryHookResult = ReturnType<typeof useOnHoldAppointmentsQuery>;
export type OnHoldAppointmentsLazyQueryHookResult = ReturnType<typeof useOnHoldAppointmentsLazyQuery>;
export type OnHoldAppointmentsSuspenseQueryHookResult = ReturnType<typeof useOnHoldAppointmentsSuspenseQuery>;
export type OnHoldAppointmentsQueryResult = Apollo.QueryResult<Operation.OnHoldAppointmentsQuery, Operation.OnHoldAppointmentsQueryVariables>;
export const ReadyToBookAppointmentsDocument = gql`
    query ReadyToBookAppointments($first: Int, $after: String) {
  appointments(attributes: {state: [ready_to_book]}, first: $first, after: $after) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
    edges {
      node {
        ...appointmentsReadyToBook
      }
    }
  }
}
    ${AppointmentsReadyToBookFragmentDoc}`;

/**
 * __useReadyToBookAppointmentsQuery__
 *
 * To run a query within a React component, call `useReadyToBookAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadyToBookAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadyToBookAppointmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useReadyToBookAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.ReadyToBookAppointmentsQuery, Operation.ReadyToBookAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ReadyToBookAppointmentsQuery, Operation.ReadyToBookAppointmentsQueryVariables>(ReadyToBookAppointmentsDocument, options);
      }
export function useReadyToBookAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ReadyToBookAppointmentsQuery, Operation.ReadyToBookAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ReadyToBookAppointmentsQuery, Operation.ReadyToBookAppointmentsQueryVariables>(ReadyToBookAppointmentsDocument, options);
        }
export function useReadyToBookAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ReadyToBookAppointmentsQuery, Operation.ReadyToBookAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ReadyToBookAppointmentsQuery, Operation.ReadyToBookAppointmentsQueryVariables>(ReadyToBookAppointmentsDocument, options);
        }
export type ReadyToBookAppointmentsQueryHookResult = ReturnType<typeof useReadyToBookAppointmentsQuery>;
export type ReadyToBookAppointmentsLazyQueryHookResult = ReturnType<typeof useReadyToBookAppointmentsLazyQuery>;
export type ReadyToBookAppointmentsSuspenseQueryHookResult = ReturnType<typeof useReadyToBookAppointmentsSuspenseQuery>;
export type ReadyToBookAppointmentsQueryResult = Apollo.QueryResult<Operation.ReadyToBookAppointmentsQuery, Operation.ReadyToBookAppointmentsQueryVariables>;
export const WaitlistAppointmentsDocument = gql`
    query WaitlistAppointments($first: Int, $after: String) {
  appointments(
    attributes: {state: [draft], withWaitlist: true}
    first: $first
    after: $after
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
    edges {
      node {
        ...appointmentsWaitlist
      }
    }
  }
}
    ${AppointmentsWaitlistFragmentDoc}`;

/**
 * __useWaitlistAppointmentsQuery__
 *
 * To run a query within a React component, call `useWaitlistAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaitlistAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitlistAppointmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useWaitlistAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.WaitlistAppointmentsQuery, Operation.WaitlistAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.WaitlistAppointmentsQuery, Operation.WaitlistAppointmentsQueryVariables>(WaitlistAppointmentsDocument, options);
      }
export function useWaitlistAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.WaitlistAppointmentsQuery, Operation.WaitlistAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.WaitlistAppointmentsQuery, Operation.WaitlistAppointmentsQueryVariables>(WaitlistAppointmentsDocument, options);
        }
export function useWaitlistAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.WaitlistAppointmentsQuery, Operation.WaitlistAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.WaitlistAppointmentsQuery, Operation.WaitlistAppointmentsQueryVariables>(WaitlistAppointmentsDocument, options);
        }
export type WaitlistAppointmentsQueryHookResult = ReturnType<typeof useWaitlistAppointmentsQuery>;
export type WaitlistAppointmentsLazyQueryHookResult = ReturnType<typeof useWaitlistAppointmentsLazyQuery>;
export type WaitlistAppointmentsSuspenseQueryHookResult = ReturnType<typeof useWaitlistAppointmentsSuspenseQuery>;
export type WaitlistAppointmentsQueryResult = Apollo.QueryResult<Operation.WaitlistAppointmentsQuery, Operation.WaitlistAppointmentsQueryVariables>;
export const CalendarEventsDocument = gql`
    query CalendarEvents($attributes: CalendarSearchInput) {
  calendarEvents(attributes: $attributes) {
    ...appointmentCalendarEvent
  }
}
    ${AppointmentCalendarEventFragmentDoc}`;

/**
 * __useCalendarEventsQuery__
 *
 * To run a query within a React component, call `useCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCalendarEventsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CalendarEventsQuery, Operation.CalendarEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CalendarEventsQuery, Operation.CalendarEventsQueryVariables>(CalendarEventsDocument, options);
      }
export function useCalendarEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CalendarEventsQuery, Operation.CalendarEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CalendarEventsQuery, Operation.CalendarEventsQueryVariables>(CalendarEventsDocument, options);
        }
export function useCalendarEventsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CalendarEventsQuery, Operation.CalendarEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CalendarEventsQuery, Operation.CalendarEventsQueryVariables>(CalendarEventsDocument, options);
        }
export type CalendarEventsQueryHookResult = ReturnType<typeof useCalendarEventsQuery>;
export type CalendarEventsLazyQueryHookResult = ReturnType<typeof useCalendarEventsLazyQuery>;
export type CalendarEventsSuspenseQueryHookResult = ReturnType<typeof useCalendarEventsSuspenseQuery>;
export type CalendarEventsQueryResult = Apollo.QueryResult<Operation.CalendarEventsQuery, Operation.CalendarEventsQueryVariables>;
export const CalendarDocument = gql`
    query Calendar($attributes: EventSearchInput!) {
  calendar(attributes: $attributes) {
    ...calendarEvent
  }
}
    ${CalendarEventFragmentDoc}`;

/**
 * __useCalendarQuery__
 *
 * To run a query within a React component, call `useCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCalendarQuery(baseOptions: Apollo.QueryHookOptions<Operation.CalendarQuery, Operation.CalendarQueryVariables> & ({ variables: Operation.CalendarQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CalendarQuery, Operation.CalendarQueryVariables>(CalendarDocument, options);
      }
export function useCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CalendarQuery, Operation.CalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CalendarQuery, Operation.CalendarQueryVariables>(CalendarDocument, options);
        }
export function useCalendarSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CalendarQuery, Operation.CalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CalendarQuery, Operation.CalendarQueryVariables>(CalendarDocument, options);
        }
export type CalendarQueryHookResult = ReturnType<typeof useCalendarQuery>;
export type CalendarLazyQueryHookResult = ReturnType<typeof useCalendarLazyQuery>;
export type CalendarSuspenseQueryHookResult = ReturnType<typeof useCalendarSuspenseQuery>;
export type CalendarQueryResult = Apollo.QueryResult<Operation.CalendarQuery, Operation.CalendarQueryVariables>;
export const CalendarRosterEventsDocument = gql`
    query CalendarRosterEvents($startDate: ISO8601Date!, $endDate: ISO8601Date!, $userPracticeConnectionIds: [ID!]!) {
  calendarRosterEvents(
    startDate: $startDate
    endDate: $endDate
    userPracticeConnectionIds: $userPracticeConnectionIds
  ) {
    ...calendarRosterDay
  }
}
    ${CalendarRosterDayFragmentDoc}`;

/**
 * __useCalendarRosterEventsQuery__
 *
 * To run a query within a React component, call `useCalendarRosterEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarRosterEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarRosterEventsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      userPracticeConnectionIds: // value for 'userPracticeConnectionIds'
 *   },
 * });
 */
export function useCalendarRosterEventsQuery(baseOptions: Apollo.QueryHookOptions<Operation.CalendarRosterEventsQuery, Operation.CalendarRosterEventsQueryVariables> & ({ variables: Operation.CalendarRosterEventsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CalendarRosterEventsQuery, Operation.CalendarRosterEventsQueryVariables>(CalendarRosterEventsDocument, options);
      }
export function useCalendarRosterEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CalendarRosterEventsQuery, Operation.CalendarRosterEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CalendarRosterEventsQuery, Operation.CalendarRosterEventsQueryVariables>(CalendarRosterEventsDocument, options);
        }
export function useCalendarRosterEventsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CalendarRosterEventsQuery, Operation.CalendarRosterEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CalendarRosterEventsQuery, Operation.CalendarRosterEventsQueryVariables>(CalendarRosterEventsDocument, options);
        }
export type CalendarRosterEventsQueryHookResult = ReturnType<typeof useCalendarRosterEventsQuery>;
export type CalendarRosterEventsLazyQueryHookResult = ReturnType<typeof useCalendarRosterEventsLazyQuery>;
export type CalendarRosterEventsSuspenseQueryHookResult = ReturnType<typeof useCalendarRosterEventsSuspenseQuery>;
export type CalendarRosterEventsQueryResult = Apollo.QueryResult<Operation.CalendarRosterEventsQuery, Operation.CalendarRosterEventsQueryVariables>;
export const CancellationReasonsDocument = gql`
    query CancellationReasons($byState: [CancellationReasonState!], $after: String, $limit: Int) {
  cancellationReasons(byState: $byState, after: $after, first: $limit) {
    totalCount
    edges {
      cursor
      node {
        ...cancellationReasonEssentials
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${CancellationReasonEssentialsFragmentDoc}`;

/**
 * __useCancellationReasonsQuery__
 *
 * To run a query within a React component, call `useCancellationReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancellationReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancellationReasonsQuery({
 *   variables: {
 *      byState: // value for 'byState'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCancellationReasonsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CancellationReasonsQuery, Operation.CancellationReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CancellationReasonsQuery, Operation.CancellationReasonsQueryVariables>(CancellationReasonsDocument, options);
      }
export function useCancellationReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CancellationReasonsQuery, Operation.CancellationReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CancellationReasonsQuery, Operation.CancellationReasonsQueryVariables>(CancellationReasonsDocument, options);
        }
export function useCancellationReasonsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CancellationReasonsQuery, Operation.CancellationReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CancellationReasonsQuery, Operation.CancellationReasonsQueryVariables>(CancellationReasonsDocument, options);
        }
export type CancellationReasonsQueryHookResult = ReturnType<typeof useCancellationReasonsQuery>;
export type CancellationReasonsLazyQueryHookResult = ReturnType<typeof useCancellationReasonsLazyQuery>;
export type CancellationReasonsSuspenseQueryHookResult = ReturnType<typeof useCancellationReasonsSuspenseQuery>;
export type CancellationReasonsQueryResult = Apollo.QueryResult<Operation.CancellationReasonsQuery, Operation.CancellationReasonsQueryVariables>;
export const HoverCardDocument = gql`
    query HoverCard($appointmentId: ID!) {
  appointment(id: $appointmentId) {
    ...appointmentHoverCard
  }
}
    ${AppointmentHoverCardFragmentDoc}`;

/**
 * __useHoverCardQuery__
 *
 * To run a query within a React component, call `useHoverCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useHoverCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHoverCardQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useHoverCardQuery(baseOptions: Apollo.QueryHookOptions<Operation.HoverCardQuery, Operation.HoverCardQueryVariables> & ({ variables: Operation.HoverCardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.HoverCardQuery, Operation.HoverCardQueryVariables>(HoverCardDocument, options);
      }
export function useHoverCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.HoverCardQuery, Operation.HoverCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.HoverCardQuery, Operation.HoverCardQueryVariables>(HoverCardDocument, options);
        }
export function useHoverCardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.HoverCardQuery, Operation.HoverCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.HoverCardQuery, Operation.HoverCardQueryVariables>(HoverCardDocument, options);
        }
export type HoverCardQueryHookResult = ReturnType<typeof useHoverCardQuery>;
export type HoverCardLazyQueryHookResult = ReturnType<typeof useHoverCardLazyQuery>;
export type HoverCardSuspenseQueryHookResult = ReturnType<typeof useHoverCardSuspenseQuery>;
export type HoverCardQueryResult = Apollo.QueryResult<Operation.HoverCardQuery, Operation.HoverCardQueryVariables>;
export const OnHoldCalendarAppointmentsDocument = gql`
    query OnHoldCalendarAppointments($attributes: CalendarSearchInput) {
  calendarEvents(attributes: $attributes) {
    ...appointmentCalendarEvent
  }
}
    ${AppointmentCalendarEventFragmentDoc}`;

/**
 * __useOnHoldCalendarAppointmentsQuery__
 *
 * To run a query within a React component, call `useOnHoldCalendarAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnHoldCalendarAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnHoldCalendarAppointmentsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useOnHoldCalendarAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.OnHoldCalendarAppointmentsQuery, Operation.OnHoldCalendarAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.OnHoldCalendarAppointmentsQuery, Operation.OnHoldCalendarAppointmentsQueryVariables>(OnHoldCalendarAppointmentsDocument, options);
      }
export function useOnHoldCalendarAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.OnHoldCalendarAppointmentsQuery, Operation.OnHoldCalendarAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.OnHoldCalendarAppointmentsQuery, Operation.OnHoldCalendarAppointmentsQueryVariables>(OnHoldCalendarAppointmentsDocument, options);
        }
export function useOnHoldCalendarAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.OnHoldCalendarAppointmentsQuery, Operation.OnHoldCalendarAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.OnHoldCalendarAppointmentsQuery, Operation.OnHoldCalendarAppointmentsQueryVariables>(OnHoldCalendarAppointmentsDocument, options);
        }
export type OnHoldCalendarAppointmentsQueryHookResult = ReturnType<typeof useOnHoldCalendarAppointmentsQuery>;
export type OnHoldCalendarAppointmentsLazyQueryHookResult = ReturnType<typeof useOnHoldCalendarAppointmentsLazyQuery>;
export type OnHoldCalendarAppointmentsSuspenseQueryHookResult = ReturnType<typeof useOnHoldCalendarAppointmentsSuspenseQuery>;
export type OnHoldCalendarAppointmentsQueryResult = Apollo.QueryResult<Operation.OnHoldCalendarAppointmentsQuery, Operation.OnHoldCalendarAppointmentsQueryVariables>;
export const OnlineBookingsAppointmentsDocument = gql`
    query OnlineBookingsAppointments {
  onlineBookingsAppointments {
    edges {
      node {
        ...appointmentEssentials
      }
    }
  }
}
    ${AppointmentEssentialsFragmentDoc}`;

/**
 * __useOnlineBookingsAppointmentsQuery__
 *
 * To run a query within a React component, call `useOnlineBookingsAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineBookingsAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineBookingsAppointmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnlineBookingsAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.OnlineBookingsAppointmentsQuery, Operation.OnlineBookingsAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.OnlineBookingsAppointmentsQuery, Operation.OnlineBookingsAppointmentsQueryVariables>(OnlineBookingsAppointmentsDocument, options);
      }
export function useOnlineBookingsAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.OnlineBookingsAppointmentsQuery, Operation.OnlineBookingsAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.OnlineBookingsAppointmentsQuery, Operation.OnlineBookingsAppointmentsQueryVariables>(OnlineBookingsAppointmentsDocument, options);
        }
export function useOnlineBookingsAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.OnlineBookingsAppointmentsQuery, Operation.OnlineBookingsAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.OnlineBookingsAppointmentsQuery, Operation.OnlineBookingsAppointmentsQueryVariables>(OnlineBookingsAppointmentsDocument, options);
        }
export type OnlineBookingsAppointmentsQueryHookResult = ReturnType<typeof useOnlineBookingsAppointmentsQuery>;
export type OnlineBookingsAppointmentsLazyQueryHookResult = ReturnType<typeof useOnlineBookingsAppointmentsLazyQuery>;
export type OnlineBookingsAppointmentsSuspenseQueryHookResult = ReturnType<typeof useOnlineBookingsAppointmentsSuspenseQuery>;
export type OnlineBookingsAppointmentsQueryResult = Apollo.QueryResult<Operation.OnlineBookingsAppointmentsQuery, Operation.OnlineBookingsAppointmentsQueryVariables>;
export const ReadyAppointmentsDocument = gql`
    query ReadyAppointments($attributes: CalendarSearchInput) {
  calendarEvents(attributes: $attributes) {
    ...readyToBookAppointmentCalendarEvent
  }
}
    ${ReadyToBookAppointmentCalendarEventFragmentDoc}`;

/**
 * __useReadyAppointmentsQuery__
 *
 * To run a query within a React component, call `useReadyAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadyAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadyAppointmentsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useReadyAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.ReadyAppointmentsQuery, Operation.ReadyAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ReadyAppointmentsQuery, Operation.ReadyAppointmentsQueryVariables>(ReadyAppointmentsDocument, options);
      }
export function useReadyAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ReadyAppointmentsQuery, Operation.ReadyAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ReadyAppointmentsQuery, Operation.ReadyAppointmentsQueryVariables>(ReadyAppointmentsDocument, options);
        }
export function useReadyAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ReadyAppointmentsQuery, Operation.ReadyAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ReadyAppointmentsQuery, Operation.ReadyAppointmentsQueryVariables>(ReadyAppointmentsDocument, options);
        }
export type ReadyAppointmentsQueryHookResult = ReturnType<typeof useReadyAppointmentsQuery>;
export type ReadyAppointmentsLazyQueryHookResult = ReturnType<typeof useReadyAppointmentsLazyQuery>;
export type ReadyAppointmentsSuspenseQueryHookResult = ReturnType<typeof useReadyAppointmentsSuspenseQuery>;
export type ReadyAppointmentsQueryResult = Apollo.QueryResult<Operation.ReadyAppointmentsQuery, Operation.ReadyAppointmentsQueryVariables>;
export const RoomsDocument = gql`
    query Rooms($byState: [RoomState!], $practiceLocationIds: [ID!], $after: String, $first: Int) {
  rooms(
    byState: $byState
    practiceLocationIds: $practiceLocationIds
    after: $after
    first: $first
  ) {
    edges {
      node {
        ...roomEssentials
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${RoomEssentialsFragmentDoc}`;

/**
 * __useRoomsQuery__
 *
 * To run a query within a React component, call `useRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomsQuery({
 *   variables: {
 *      byState: // value for 'byState'
 *      practiceLocationIds: // value for 'practiceLocationIds'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRoomsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.RoomsQuery, Operation.RoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RoomsQuery, Operation.RoomsQueryVariables>(RoomsDocument, options);
      }
export function useRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RoomsQuery, Operation.RoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RoomsQuery, Operation.RoomsQueryVariables>(RoomsDocument, options);
        }
export function useRoomsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RoomsQuery, Operation.RoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RoomsQuery, Operation.RoomsQueryVariables>(RoomsDocument, options);
        }
export type RoomsQueryHookResult = ReturnType<typeof useRoomsQuery>;
export type RoomsLazyQueryHookResult = ReturnType<typeof useRoomsLazyQuery>;
export type RoomsSuspenseQueryHookResult = ReturnType<typeof useRoomsSuspenseQuery>;
export type RoomsQueryResult = Apollo.QueryResult<Operation.RoomsQuery, Operation.RoomsQueryVariables>;
export const UnlinkedAppointmentsDocument = gql`
    query UnlinkedAppointments($attributes: CalendarSearchInput!, $first: Int, $after: String) {
  treatmentAppointments(attributes: $attributes, first: $first, after: $after) {
    pageInfo {
      ...pageInfo
    }
    totalCount
    edges {
      node {
        ...unlinkedAppointment
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${UnlinkedAppointmentFragmentDoc}`;

/**
 * __useUnlinkedAppointmentsQuery__
 *
 * To run a query within a React component, call `useUnlinkedAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnlinkedAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnlinkedAppointmentsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUnlinkedAppointmentsQuery(baseOptions: Apollo.QueryHookOptions<Operation.UnlinkedAppointmentsQuery, Operation.UnlinkedAppointmentsQueryVariables> & ({ variables: Operation.UnlinkedAppointmentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UnlinkedAppointmentsQuery, Operation.UnlinkedAppointmentsQueryVariables>(UnlinkedAppointmentsDocument, options);
      }
export function useUnlinkedAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UnlinkedAppointmentsQuery, Operation.UnlinkedAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UnlinkedAppointmentsQuery, Operation.UnlinkedAppointmentsQueryVariables>(UnlinkedAppointmentsDocument, options);
        }
export function useUnlinkedAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UnlinkedAppointmentsQuery, Operation.UnlinkedAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UnlinkedAppointmentsQuery, Operation.UnlinkedAppointmentsQueryVariables>(UnlinkedAppointmentsDocument, options);
        }
export type UnlinkedAppointmentsQueryHookResult = ReturnType<typeof useUnlinkedAppointmentsQuery>;
export type UnlinkedAppointmentsLazyQueryHookResult = ReturnType<typeof useUnlinkedAppointmentsLazyQuery>;
export type UnlinkedAppointmentsSuspenseQueryHookResult = ReturnType<typeof useUnlinkedAppointmentsSuspenseQuery>;
export type UnlinkedAppointmentsQueryResult = Apollo.QueryResult<Operation.UnlinkedAppointmentsQuery, Operation.UnlinkedAppointmentsQueryVariables>;
export const VerifySlotDocument = gql`
    query VerifySlot($appointments: [VerifySlotInput!], $skip: Boolean) {
  verifySlot(appointments: $appointments, skip: $skip) {
    ...verifySlot
  }
}
    ${VerifySlotFragmentDoc}`;

/**
 * __useVerifySlotQuery__
 *
 * To run a query within a React component, call `useVerifySlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifySlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifySlotQuery({
 *   variables: {
 *      appointments: // value for 'appointments'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useVerifySlotQuery(baseOptions?: Apollo.QueryHookOptions<Operation.VerifySlotQuery, Operation.VerifySlotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.VerifySlotQuery, Operation.VerifySlotQueryVariables>(VerifySlotDocument, options);
      }
export function useVerifySlotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.VerifySlotQuery, Operation.VerifySlotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.VerifySlotQuery, Operation.VerifySlotQueryVariables>(VerifySlotDocument, options);
        }
export function useVerifySlotSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.VerifySlotQuery, Operation.VerifySlotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.VerifySlotQuery, Operation.VerifySlotQueryVariables>(VerifySlotDocument, options);
        }
export type VerifySlotQueryHookResult = ReturnType<typeof useVerifySlotQuery>;
export type VerifySlotLazyQueryHookResult = ReturnType<typeof useVerifySlotLazyQuery>;
export type VerifySlotSuspenseQueryHookResult = ReturnType<typeof useVerifySlotSuspenseQuery>;
export type VerifySlotQueryResult = Apollo.QueryResult<Operation.VerifySlotQuery, Operation.VerifySlotQueryVariables>;
export const WaitlistDocument = gql`
    query Waitlist($id: ID!) {
  waitlist(id: $id) {
    ...waitlistDetailed
  }
}
    ${WaitlistDetailedFragmentDoc}`;

/**
 * __useWaitlistQuery__
 *
 * To run a query within a React component, call `useWaitlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaitlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitlistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWaitlistQuery(baseOptions: Apollo.QueryHookOptions<Operation.WaitlistQuery, Operation.WaitlistQueryVariables> & ({ variables: Operation.WaitlistQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.WaitlistQuery, Operation.WaitlistQueryVariables>(WaitlistDocument, options);
      }
export function useWaitlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.WaitlistQuery, Operation.WaitlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.WaitlistQuery, Operation.WaitlistQueryVariables>(WaitlistDocument, options);
        }
export function useWaitlistSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.WaitlistQuery, Operation.WaitlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.WaitlistQuery, Operation.WaitlistQueryVariables>(WaitlistDocument, options);
        }
export type WaitlistQueryHookResult = ReturnType<typeof useWaitlistQuery>;
export type WaitlistLazyQueryHookResult = ReturnType<typeof useWaitlistLazyQuery>;
export type WaitlistSuspenseQueryHookResult = ReturnType<typeof useWaitlistSuspenseQuery>;
export type WaitlistQueryResult = Apollo.QueryResult<Operation.WaitlistQuery, Operation.WaitlistQueryVariables>;
export const WaitlistsDocument = gql`
    query Waitlists($ids: [ID!], $byState: [WaitlistState!]!, $after: String, $first: Int) {
  waitlists(byState: $byState, ids: $ids, after: $after, first: $first) {
    edges {
      node {
        ...waitlistEssentials
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${WaitlistEssentialsFragmentDoc}`;

/**
 * __useWaitlistsQuery__
 *
 * To run a query within a React component, call `useWaitlistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaitlistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitlistsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      byState: // value for 'byState'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useWaitlistsQuery(baseOptions: Apollo.QueryHookOptions<Operation.WaitlistsQuery, Operation.WaitlistsQueryVariables> & ({ variables: Operation.WaitlistsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.WaitlistsQuery, Operation.WaitlistsQueryVariables>(WaitlistsDocument, options);
      }
export function useWaitlistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.WaitlistsQuery, Operation.WaitlistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.WaitlistsQuery, Operation.WaitlistsQueryVariables>(WaitlistsDocument, options);
        }
export function useWaitlistsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.WaitlistsQuery, Operation.WaitlistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.WaitlistsQuery, Operation.WaitlistsQueryVariables>(WaitlistsDocument, options);
        }
export type WaitlistsQueryHookResult = ReturnType<typeof useWaitlistsQuery>;
export type WaitlistsLazyQueryHookResult = ReturnType<typeof useWaitlistsLazyQuery>;
export type WaitlistsSuspenseQueryHookResult = ReturnType<typeof useWaitlistsSuspenseQuery>;
export type WaitlistsQueryResult = Apollo.QueryResult<Operation.WaitlistsQuery, Operation.WaitlistsQueryVariables>;
export const WaitlistsDetailedDocument = gql`
    query WaitlistsDetailed($ids: [ID!], $byState: [WaitlistState!]!, $after: String, $first: Int) {
  waitlists(byState: $byState, ids: $ids, after: $after, first: $first) {
    edges {
      node {
        ...waitlistDetailed
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${WaitlistDetailedFragmentDoc}`;

/**
 * __useWaitlistsDetailedQuery__
 *
 * To run a query within a React component, call `useWaitlistsDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaitlistsDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitlistsDetailedQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      byState: // value for 'byState'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useWaitlistsDetailedQuery(baseOptions: Apollo.QueryHookOptions<Operation.WaitlistsDetailedQuery, Operation.WaitlistsDetailedQueryVariables> & ({ variables: Operation.WaitlistsDetailedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.WaitlistsDetailedQuery, Operation.WaitlistsDetailedQueryVariables>(WaitlistsDetailedDocument, options);
      }
export function useWaitlistsDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.WaitlistsDetailedQuery, Operation.WaitlistsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.WaitlistsDetailedQuery, Operation.WaitlistsDetailedQueryVariables>(WaitlistsDetailedDocument, options);
        }
export function useWaitlistsDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.WaitlistsDetailedQuery, Operation.WaitlistsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.WaitlistsDetailedQuery, Operation.WaitlistsDetailedQueryVariables>(WaitlistsDetailedDocument, options);
        }
export type WaitlistsDetailedQueryHookResult = ReturnType<typeof useWaitlistsDetailedQuery>;
export type WaitlistsDetailedLazyQueryHookResult = ReturnType<typeof useWaitlistsDetailedLazyQuery>;
export type WaitlistsDetailedSuspenseQueryHookResult = ReturnType<typeof useWaitlistsDetailedSuspenseQuery>;
export type WaitlistsDetailedQueryResult = Apollo.QueryResult<Operation.WaitlistsDetailedQuery, Operation.WaitlistsDetailedQueryVariables>;
export const CatalogueItemModalitiesDocument = gql`
    query CatalogueItemModalities {
  catalogueItemModalities
}
    `;

/**
 * __useCatalogueItemModalitiesQuery__
 *
 * To run a query within a React component, call `useCatalogueItemModalitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemModalitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemModalitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCatalogueItemModalitiesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CatalogueItemModalitiesQuery, Operation.CatalogueItemModalitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CatalogueItemModalitiesQuery, Operation.CatalogueItemModalitiesQueryVariables>(CatalogueItemModalitiesDocument, options);
      }
export function useCatalogueItemModalitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CatalogueItemModalitiesQuery, Operation.CatalogueItemModalitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CatalogueItemModalitiesQuery, Operation.CatalogueItemModalitiesQueryVariables>(CatalogueItemModalitiesDocument, options);
        }
export function useCatalogueItemModalitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CatalogueItemModalitiesQuery, Operation.CatalogueItemModalitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CatalogueItemModalitiesQuery, Operation.CatalogueItemModalitiesQueryVariables>(CatalogueItemModalitiesDocument, options);
        }
export type CatalogueItemModalitiesQueryHookResult = ReturnType<typeof useCatalogueItemModalitiesQuery>;
export type CatalogueItemModalitiesLazyQueryHookResult = ReturnType<typeof useCatalogueItemModalitiesLazyQuery>;
export type CatalogueItemModalitiesSuspenseQueryHookResult = ReturnType<typeof useCatalogueItemModalitiesSuspenseQuery>;
export type CatalogueItemModalitiesQueryResult = Apollo.QueryResult<Operation.CatalogueItemModalitiesQuery, Operation.CatalogueItemModalitiesQueryVariables>;
export const CatalogueItemModalityOptionsDocument = gql`
    query CatalogueItemModalityOptions {
  catalogueItemModalityOptions {
    label
    value
  }
}
    `;

/**
 * __useCatalogueItemModalityOptionsQuery__
 *
 * To run a query within a React component, call `useCatalogueItemModalityOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemModalityOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemModalityOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCatalogueItemModalityOptionsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CatalogueItemModalityOptionsQuery, Operation.CatalogueItemModalityOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CatalogueItemModalityOptionsQuery, Operation.CatalogueItemModalityOptionsQueryVariables>(CatalogueItemModalityOptionsDocument, options);
      }
export function useCatalogueItemModalityOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CatalogueItemModalityOptionsQuery, Operation.CatalogueItemModalityOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CatalogueItemModalityOptionsQuery, Operation.CatalogueItemModalityOptionsQueryVariables>(CatalogueItemModalityOptionsDocument, options);
        }
export function useCatalogueItemModalityOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CatalogueItemModalityOptionsQuery, Operation.CatalogueItemModalityOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CatalogueItemModalityOptionsQuery, Operation.CatalogueItemModalityOptionsQueryVariables>(CatalogueItemModalityOptionsDocument, options);
        }
export type CatalogueItemModalityOptionsQueryHookResult = ReturnType<typeof useCatalogueItemModalityOptionsQuery>;
export type CatalogueItemModalityOptionsLazyQueryHookResult = ReturnType<typeof useCatalogueItemModalityOptionsLazyQuery>;
export type CatalogueItemModalityOptionsSuspenseQueryHookResult = ReturnType<typeof useCatalogueItemModalityOptionsSuspenseQuery>;
export type CatalogueItemModalityOptionsQueryResult = Apollo.QueryResult<Operation.CatalogueItemModalityOptionsQuery, Operation.CatalogueItemModalityOptionsQueryVariables>;
export const CatalogueItemsDocument = gql`
    query CatalogueItems($practiceId: ID, $state: CatalogueItemState, $appointmentReasonId: ID, $priceListId: ID, $search: String, $ids: [ID!], $dentalOnly: Boolean, $online: [Boolean!], $enableStock: [Boolean!], $updatesExam: [Boolean!], $taxCode: [Boolean!], $active: [Boolean!], $type: CatalogueItemType, $categoryIds: [ID!], $first: Int, $after: String, $order: String, $orderBy: CatalogueItemOrderInput) {
  catalogueItems(
    state: $state
    practiceId: $practiceId
    appointmentReasonId: $appointmentReasonId
    priceListId: $priceListId
    ids: $ids
    search: $search
    online: $online
    taxCode: $taxCode
    active: $active
    type: $type
    enableStock: $enableStock
    updatesExam: $updatesExam
    dentalOnly: $dentalOnly
    order: $order
    orderBy: $orderBy
    categoryIds: $categoryIds
    first: $first
    after: $after
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      cursor
      node {
        ...catalogueItemEssentials
      }
    }
  }
}
    ${CatalogueItemEssentialsFragmentDoc}`;

/**
 * __useCatalogueItemsQuery__
 *
 * To run a query within a React component, call `useCatalogueItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemsQuery({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *      state: // value for 'state'
 *      appointmentReasonId: // value for 'appointmentReasonId'
 *      priceListId: // value for 'priceListId'
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *      dentalOnly: // value for 'dentalOnly'
 *      online: // value for 'online'
 *      enableStock: // value for 'enableStock'
 *      updatesExam: // value for 'updatesExam'
 *      taxCode: // value for 'taxCode'
 *      active: // value for 'active'
 *      type: // value for 'type'
 *      categoryIds: // value for 'categoryIds'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      order: // value for 'order'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCatalogueItemsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CatalogueItemsQuery, Operation.CatalogueItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CatalogueItemsQuery, Operation.CatalogueItemsQueryVariables>(CatalogueItemsDocument, options);
      }
export function useCatalogueItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CatalogueItemsQuery, Operation.CatalogueItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CatalogueItemsQuery, Operation.CatalogueItemsQueryVariables>(CatalogueItemsDocument, options);
        }
export function useCatalogueItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CatalogueItemsQuery, Operation.CatalogueItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CatalogueItemsQuery, Operation.CatalogueItemsQueryVariables>(CatalogueItemsDocument, options);
        }
export type CatalogueItemsQueryHookResult = ReturnType<typeof useCatalogueItemsQuery>;
export type CatalogueItemsLazyQueryHookResult = ReturnType<typeof useCatalogueItemsLazyQuery>;
export type CatalogueItemsSuspenseQueryHookResult = ReturnType<typeof useCatalogueItemsSuspenseQuery>;
export type CatalogueItemsQueryResult = Apollo.QueryResult<Operation.CatalogueItemsQuery, Operation.CatalogueItemsQueryVariables>;
export const CatalogueItemsPricingDocument = gql`
    query CatalogueItemsPricing($practiceId: ID, $state: CatalogueItemState, $appointmentReasonId: ID, $priceListId: ID, $search: String, $categoryIds: [ID!], $first: Int, $after: String, $orderBy: CatalogueItemOrderInput, $order: String, $byFavorite: Boolean) {
  catalogueItems(
    state: $state
    practiceId: $practiceId
    priceListId: $priceListId
    appointmentReasonId: $appointmentReasonId
    search: $search
    categoryIds: $categoryIds
    orderBy: $orderBy
    order: $order
    byFavorite: $byFavorite
    first: $first
    after: $after
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      node {
        ...catalogueItemPricing
      }
    }
  }
}
    ${CatalogueItemPricingFragmentDoc}`;

/**
 * __useCatalogueItemsPricingQuery__
 *
 * To run a query within a React component, call `useCatalogueItemsPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemsPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemsPricingQuery({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *      state: // value for 'state'
 *      appointmentReasonId: // value for 'appointmentReasonId'
 *      priceListId: // value for 'priceListId'
 *      search: // value for 'search'
 *      categoryIds: // value for 'categoryIds'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      byFavorite: // value for 'byFavorite'
 *   },
 * });
 */
export function useCatalogueItemsPricingQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CatalogueItemsPricingQuery, Operation.CatalogueItemsPricingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CatalogueItemsPricingQuery, Operation.CatalogueItemsPricingQueryVariables>(CatalogueItemsPricingDocument, options);
      }
export function useCatalogueItemsPricingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CatalogueItemsPricingQuery, Operation.CatalogueItemsPricingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CatalogueItemsPricingQuery, Operation.CatalogueItemsPricingQueryVariables>(CatalogueItemsPricingDocument, options);
        }
export function useCatalogueItemsPricingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CatalogueItemsPricingQuery, Operation.CatalogueItemsPricingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CatalogueItemsPricingQuery, Operation.CatalogueItemsPricingQueryVariables>(CatalogueItemsPricingDocument, options);
        }
export type CatalogueItemsPricingQueryHookResult = ReturnType<typeof useCatalogueItemsPricingQuery>;
export type CatalogueItemsPricingLazyQueryHookResult = ReturnType<typeof useCatalogueItemsPricingLazyQuery>;
export type CatalogueItemsPricingSuspenseQueryHookResult = ReturnType<typeof useCatalogueItemsPricingSuspenseQuery>;
export type CatalogueItemsPricingQueryResult = Apollo.QueryResult<Operation.CatalogueItemsPricingQuery, Operation.CatalogueItemsPricingQueryVariables>;
export const CatalogueItemsPricingSettingsDocument = gql`
    query CatalogueItemsPricingSettings($search: String, $first: Int, $after: String, $orderBy: CatalogueItemOrderInput, $order: String, $state: CatalogueItemState, $priceListIds: [ID!]) {
  catalogueItems(
    search: $search
    orderBy: $orderBy
    order: $order
    first: $first
    after: $after
    state: $state
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      cursor
      node {
        ...catalogueItemPricingSettings
      }
    }
  }
}
    ${CatalogueItemPricingSettingsFragmentDoc}`;

/**
 * __useCatalogueItemsPricingSettingsQuery__
 *
 * To run a query within a React component, call `useCatalogueItemsPricingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemsPricingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemsPricingSettingsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      state: // value for 'state'
 *      priceListIds: // value for 'priceListIds'
 *   },
 * });
 */
export function useCatalogueItemsPricingSettingsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CatalogueItemsPricingSettingsQuery, Operation.CatalogueItemsPricingSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CatalogueItemsPricingSettingsQuery, Operation.CatalogueItemsPricingSettingsQueryVariables>(CatalogueItemsPricingSettingsDocument, options);
      }
export function useCatalogueItemsPricingSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CatalogueItemsPricingSettingsQuery, Operation.CatalogueItemsPricingSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CatalogueItemsPricingSettingsQuery, Operation.CatalogueItemsPricingSettingsQueryVariables>(CatalogueItemsPricingSettingsDocument, options);
        }
export function useCatalogueItemsPricingSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CatalogueItemsPricingSettingsQuery, Operation.CatalogueItemsPricingSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CatalogueItemsPricingSettingsQuery, Operation.CatalogueItemsPricingSettingsQueryVariables>(CatalogueItemsPricingSettingsDocument, options);
        }
export type CatalogueItemsPricingSettingsQueryHookResult = ReturnType<typeof useCatalogueItemsPricingSettingsQuery>;
export type CatalogueItemsPricingSettingsLazyQueryHookResult = ReturnType<typeof useCatalogueItemsPricingSettingsLazyQuery>;
export type CatalogueItemsPricingSettingsSuspenseQueryHookResult = ReturnType<typeof useCatalogueItemsPricingSettingsSuspenseQuery>;
export type CatalogueItemsPricingSettingsQueryResult = Apollo.QueryResult<Operation.CatalogueItemsPricingSettingsQuery, Operation.CatalogueItemsPricingSettingsQueryVariables>;
export const CatalogueItemDocument = gql`
    query CatalogueItem($id: ID, $sku: String) {
  catalogueItem(id: $id, sku: $sku) {
    ...catalogueItemDetailed
  }
}
    ${CatalogueItemDetailedFragmentDoc}`;

/**
 * __useCatalogueItemQuery__
 *
 * To run a query within a React component, call `useCatalogueItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useCatalogueItemQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CatalogueItemQuery, Operation.CatalogueItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CatalogueItemQuery, Operation.CatalogueItemQueryVariables>(CatalogueItemDocument, options);
      }
export function useCatalogueItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CatalogueItemQuery, Operation.CatalogueItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CatalogueItemQuery, Operation.CatalogueItemQueryVariables>(CatalogueItemDocument, options);
        }
export function useCatalogueItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CatalogueItemQuery, Operation.CatalogueItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CatalogueItemQuery, Operation.CatalogueItemQueryVariables>(CatalogueItemDocument, options);
        }
export type CatalogueItemQueryHookResult = ReturnType<typeof useCatalogueItemQuery>;
export type CatalogueItemLazyQueryHookResult = ReturnType<typeof useCatalogueItemLazyQuery>;
export type CatalogueItemSuspenseQueryHookResult = ReturnType<typeof useCatalogueItemSuspenseQuery>;
export type CatalogueItemQueryResult = Apollo.QueryResult<Operation.CatalogueItemQuery, Operation.CatalogueItemQueryVariables>;
export const CategoriesDocument = gql`
    query Categories($byType: [String!]!, $byState: [CategoryState!]!, $search: String) {
  categories(byType: $byType, byState: $byState, search: $search) {
    edges {
      node {
        ...categoryEssential
      }
    }
  }
}
    ${CategoryEssentialFragmentDoc}`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      byType: // value for 'byType'
 *      byState: // value for 'byState'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions: Apollo.QueryHookOptions<Operation.CategoriesQuery, Operation.CategoriesQueryVariables> & ({ variables: Operation.CategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CategoriesQuery, Operation.CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CategoriesQuery, Operation.CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CategoriesQuery, Operation.CategoriesQueryVariables>(CategoriesDocument, options);
        }
export function useCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CategoriesQuery, Operation.CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CategoriesQuery, Operation.CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesSuspenseQueryHookResult = ReturnType<typeof useCategoriesSuspenseQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<Operation.CategoriesQuery, Operation.CategoriesQueryVariables>;
export const MaterialsDocument = gql`
    query Materials($practiceId: [ID!], $treatments: [DentalTreatment!], $state: [MaterialState!], $search: String, $after: String, $first: Int) {
  materials(
    practiceId: $practiceId
    treatments: $treatments
    after: $after
    first: $first
    state: $state
    search: $search
  ) {
    pageInfo {
      ...pageInfo
    }
    edges {
      node {
        ...materialEssentials
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${MaterialEssentialsFragmentDoc}`;

/**
 * __useMaterialsQuery__
 *
 * To run a query within a React component, call `useMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialsQuery({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *      treatments: // value for 'treatments'
 *      state: // value for 'state'
 *      search: // value for 'search'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMaterialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.MaterialsQuery, Operation.MaterialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.MaterialsQuery, Operation.MaterialsQueryVariables>(MaterialsDocument, options);
      }
export function useMaterialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.MaterialsQuery, Operation.MaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.MaterialsQuery, Operation.MaterialsQueryVariables>(MaterialsDocument, options);
        }
export function useMaterialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.MaterialsQuery, Operation.MaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.MaterialsQuery, Operation.MaterialsQueryVariables>(MaterialsDocument, options);
        }
export type MaterialsQueryHookResult = ReturnType<typeof useMaterialsQuery>;
export type MaterialsLazyQueryHookResult = ReturnType<typeof useMaterialsLazyQuery>;
export type MaterialsSuspenseQueryHookResult = ReturnType<typeof useMaterialsSuspenseQuery>;
export type MaterialsQueryResult = Apollo.QueryResult<Operation.MaterialsQuery, Operation.MaterialsQueryVariables>;
export const MaterialDocument = gql`
    query Material($id: ID!) {
  material(id: $id) {
    ...materialEssentials
  }
}
    ${MaterialEssentialsFragmentDoc}`;

/**
 * __useMaterialQuery__
 *
 * To run a query within a React component, call `useMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaterialQuery(baseOptions: Apollo.QueryHookOptions<Operation.MaterialQuery, Operation.MaterialQueryVariables> & ({ variables: Operation.MaterialQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.MaterialQuery, Operation.MaterialQueryVariables>(MaterialDocument, options);
      }
export function useMaterialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.MaterialQuery, Operation.MaterialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.MaterialQuery, Operation.MaterialQueryVariables>(MaterialDocument, options);
        }
export function useMaterialSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.MaterialQuery, Operation.MaterialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.MaterialQuery, Operation.MaterialQueryVariables>(MaterialDocument, options);
        }
export type MaterialQueryHookResult = ReturnType<typeof useMaterialQuery>;
export type MaterialLazyQueryHookResult = ReturnType<typeof useMaterialLazyQuery>;
export type MaterialSuspenseQueryHookResult = ReturnType<typeof useMaterialSuspenseQuery>;
export type MaterialQueryResult = Apollo.QueryResult<Operation.MaterialQuery, Operation.MaterialQueryVariables>;
export const PriceListsDocument = gql`
    query PriceLists($practiceId: [ID!], $default: Boolean, $state: [PriceListState!], $first: Int, $after: String) {
  priceLists(
    practiceId: $practiceId
    default: $default
    state: $state
    first: $first
    after: $after
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        ...priceListEssentials
      }
    }
  }
}
    ${PriceListEssentialsFragmentDoc}`;

/**
 * __usePriceListsQuery__
 *
 * To run a query within a React component, call `usePriceListsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceListsQuery({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *      default: // value for 'default'
 *      state: // value for 'state'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePriceListsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PriceListsQuery, Operation.PriceListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PriceListsQuery, Operation.PriceListsQueryVariables>(PriceListsDocument, options);
      }
export function usePriceListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PriceListsQuery, Operation.PriceListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PriceListsQuery, Operation.PriceListsQueryVariables>(PriceListsDocument, options);
        }
export function usePriceListsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PriceListsQuery, Operation.PriceListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PriceListsQuery, Operation.PriceListsQueryVariables>(PriceListsDocument, options);
        }
export type PriceListsQueryHookResult = ReturnType<typeof usePriceListsQuery>;
export type PriceListsLazyQueryHookResult = ReturnType<typeof usePriceListsLazyQuery>;
export type PriceListsSuspenseQueryHookResult = ReturnType<typeof usePriceListsSuspenseQuery>;
export type PriceListsQueryResult = Apollo.QueryResult<Operation.PriceListsQuery, Operation.PriceListsQueryVariables>;
export const PriceListsDetailedDocument = gql`
    query PriceListsDetailed($practiceId: [ID!], $default: Boolean, $state: [PriceListState!]) {
  priceLists(practiceId: $practiceId, default: $default, state: $state) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        ...priceListDetailed
      }
    }
  }
}
    ${PriceListDetailedFragmentDoc}`;

/**
 * __usePriceListsDetailedQuery__
 *
 * To run a query within a React component, call `usePriceListsDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceListsDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceListsDetailedQuery({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *      default: // value for 'default'
 *      state: // value for 'state'
 *   },
 * });
 */
export function usePriceListsDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PriceListsDetailedQuery, Operation.PriceListsDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PriceListsDetailedQuery, Operation.PriceListsDetailedQueryVariables>(PriceListsDetailedDocument, options);
      }
export function usePriceListsDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PriceListsDetailedQuery, Operation.PriceListsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PriceListsDetailedQuery, Operation.PriceListsDetailedQueryVariables>(PriceListsDetailedDocument, options);
        }
export function usePriceListsDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PriceListsDetailedQuery, Operation.PriceListsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PriceListsDetailedQuery, Operation.PriceListsDetailedQueryVariables>(PriceListsDetailedDocument, options);
        }
export type PriceListsDetailedQueryHookResult = ReturnType<typeof usePriceListsDetailedQuery>;
export type PriceListsDetailedLazyQueryHookResult = ReturnType<typeof usePriceListsDetailedLazyQuery>;
export type PriceListsDetailedSuspenseQueryHookResult = ReturnType<typeof usePriceListsDetailedSuspenseQuery>;
export type PriceListsDetailedQueryResult = Apollo.QueryResult<Operation.PriceListsDetailedQuery, Operation.PriceListsDetailedQueryVariables>;
export const PriceListDetailedDocument = gql`
    query PriceListDetailed($id: ID!) {
  priceList(id: $id) {
    ...priceListDetailed
  }
}
    ${PriceListDetailedFragmentDoc}`;

/**
 * __usePriceListDetailedQuery__
 *
 * To run a query within a React component, call `usePriceListDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceListDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceListDetailedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePriceListDetailedQuery(baseOptions: Apollo.QueryHookOptions<Operation.PriceListDetailedQuery, Operation.PriceListDetailedQueryVariables> & ({ variables: Operation.PriceListDetailedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PriceListDetailedQuery, Operation.PriceListDetailedQueryVariables>(PriceListDetailedDocument, options);
      }
export function usePriceListDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PriceListDetailedQuery, Operation.PriceListDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PriceListDetailedQuery, Operation.PriceListDetailedQueryVariables>(PriceListDetailedDocument, options);
        }
export function usePriceListDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PriceListDetailedQuery, Operation.PriceListDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PriceListDetailedQuery, Operation.PriceListDetailedQueryVariables>(PriceListDetailedDocument, options);
        }
export type PriceListDetailedQueryHookResult = ReturnType<typeof usePriceListDetailedQuery>;
export type PriceListDetailedLazyQueryHookResult = ReturnType<typeof usePriceListDetailedLazyQuery>;
export type PriceListDetailedSuspenseQueryHookResult = ReturnType<typeof usePriceListDetailedSuspenseQuery>;
export type PriceListDetailedQueryResult = Apollo.QueryResult<Operation.PriceListDetailedQuery, Operation.PriceListDetailedQueryVariables>;
export const AttachmentDocument = gql`
    query Attachment($id: ID!) {
  attachment(id: $id) {
    ...attachmentEssentials
  }
}
    ${AttachmentEssentialsFragmentDoc}`;

/**
 * __useAttachmentQuery__
 *
 * To run a query within a React component, call `useAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttachmentQuery(baseOptions: Apollo.QueryHookOptions<Operation.AttachmentQuery, Operation.AttachmentQueryVariables> & ({ variables: Operation.AttachmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AttachmentQuery, Operation.AttachmentQueryVariables>(AttachmentDocument, options);
      }
export function useAttachmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AttachmentQuery, Operation.AttachmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AttachmentQuery, Operation.AttachmentQueryVariables>(AttachmentDocument, options);
        }
export function useAttachmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AttachmentQuery, Operation.AttachmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AttachmentQuery, Operation.AttachmentQueryVariables>(AttachmentDocument, options);
        }
export type AttachmentQueryHookResult = ReturnType<typeof useAttachmentQuery>;
export type AttachmentLazyQueryHookResult = ReturnType<typeof useAttachmentLazyQuery>;
export type AttachmentSuspenseQueryHookResult = ReturnType<typeof useAttachmentSuspenseQuery>;
export type AttachmentQueryResult = Apollo.QueryResult<Operation.AttachmentQuery, Operation.AttachmentQueryVariables>;
export const AttachmentsDocument = gql`
    query Attachments($ids: [ID!], $uuid: String, $patientId: [ID!], $practitionerId: [ID!], $filetype: [String!], $directoryContentType: [DocumentContentType!], $imagesOnly: Boolean, $first: Int, $after: String) {
  attachments(
    ids: $ids
    uuid: $uuid
    patientId: $patientId
    practitionerId: $practitionerId
    filetype: $filetype
    imagesOnly: $imagesOnly
    directoryContentType: $directoryContentType
    first: $first
    after: $after
  ) {
    totalCount
    edges {
      cursor
      node {
        ...attachmentEssentials
      }
    }
    pageInfo {
      ...pageInfo
    }
  }
}
    ${AttachmentEssentialsFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useAttachmentsQuery__
 *
 * To run a query within a React component, call `useAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      uuid: // value for 'uuid'
 *      patientId: // value for 'patientId'
 *      practitionerId: // value for 'practitionerId'
 *      filetype: // value for 'filetype'
 *      directoryContentType: // value for 'directoryContentType'
 *      imagesOnly: // value for 'imagesOnly'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AttachmentsQuery, Operation.AttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AttachmentsQuery, Operation.AttachmentsQueryVariables>(AttachmentsDocument, options);
      }
export function useAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AttachmentsQuery, Operation.AttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AttachmentsQuery, Operation.AttachmentsQueryVariables>(AttachmentsDocument, options);
        }
export function useAttachmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AttachmentsQuery, Operation.AttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AttachmentsQuery, Operation.AttachmentsQueryVariables>(AttachmentsDocument, options);
        }
export type AttachmentsQueryHookResult = ReturnType<typeof useAttachmentsQuery>;
export type AttachmentsLazyQueryHookResult = ReturnType<typeof useAttachmentsLazyQuery>;
export type AttachmentsSuspenseQueryHookResult = ReturnType<typeof useAttachmentsSuspenseQuery>;
export type AttachmentsQueryResult = Apollo.QueryResult<Operation.AttachmentsQuery, Operation.AttachmentsQueryVariables>;
export const TagsDocument = gql`
    query Tags($uuid: String!) {
  tags(uuid: $uuid) {
    ...tagEssentials
  }
}
    ${TagEssentialsFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useTagsQuery(baseOptions: Apollo.QueryHookOptions<Operation.TagsQuery, Operation.TagsQueryVariables> & ({ variables: Operation.TagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TagsQuery, Operation.TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TagsQuery, Operation.TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TagsQuery, Operation.TagsQueryVariables>(TagsDocument, options);
        }
export function useTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TagsQuery, Operation.TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TagsQuery, Operation.TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsSuspenseQueryHookResult = ReturnType<typeof useTagsSuspenseQuery>;
export type TagsQueryResult = Apollo.QueryResult<Operation.TagsQuery, Operation.TagsQueryVariables>;
export const ConversationsDocument = gql`
    query Conversations($participantIds: [ID!], $ids: [ID!], $after: String) {
  conversations(participants: $participantIds, ids: $ids, after: $after) {
    totalCount
    edges {
      cursor
      node {
        ...conversationEssentials
      }
    }
  }
}
    ${ConversationEssentialsFragmentDoc}`;

/**
 * __useConversationsQuery__
 *
 * To run a query within a React component, call `useConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsQuery({
 *   variables: {
 *      participantIds: // value for 'participantIds'
 *      ids: // value for 'ids'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useConversationsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.ConversationsQuery, Operation.ConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ConversationsQuery, Operation.ConversationsQueryVariables>(ConversationsDocument, options);
      }
export function useConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ConversationsQuery, Operation.ConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ConversationsQuery, Operation.ConversationsQueryVariables>(ConversationsDocument, options);
        }
export function useConversationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ConversationsQuery, Operation.ConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ConversationsQuery, Operation.ConversationsQueryVariables>(ConversationsDocument, options);
        }
export type ConversationsQueryHookResult = ReturnType<typeof useConversationsQuery>;
export type ConversationsLazyQueryHookResult = ReturnType<typeof useConversationsLazyQuery>;
export type ConversationsSuspenseQueryHookResult = ReturnType<typeof useConversationsSuspenseQuery>;
export type ConversationsQueryResult = Apollo.QueryResult<Operation.ConversationsQuery, Operation.ConversationsQueryVariables>;
export const ConversationDocument = gql`
    query Conversation($id: ID!, $before: String) {
  conversation(id: $id) {
    ...conversationDetailed
  }
}
    ${ConversationDetailedFragmentDoc}`;

/**
 * __useConversationQuery__
 *
 * To run a query within a React component, call `useConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useConversationQuery(baseOptions: Apollo.QueryHookOptions<Operation.ConversationQuery, Operation.ConversationQueryVariables> & ({ variables: Operation.ConversationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ConversationQuery, Operation.ConversationQueryVariables>(ConversationDocument, options);
      }
export function useConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ConversationQuery, Operation.ConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ConversationQuery, Operation.ConversationQueryVariables>(ConversationDocument, options);
        }
export function useConversationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ConversationQuery, Operation.ConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ConversationQuery, Operation.ConversationQueryVariables>(ConversationDocument, options);
        }
export type ConversationQueryHookResult = ReturnType<typeof useConversationQuery>;
export type ConversationLazyQueryHookResult = ReturnType<typeof useConversationLazyQuery>;
export type ConversationSuspenseQueryHookResult = ReturnType<typeof useConversationSuspenseQuery>;
export type ConversationQueryResult = Apollo.QueryResult<Operation.ConversationQuery, Operation.ConversationQueryVariables>;
export const MessagesDocument = gql`
    query Messages($appointmentId: ID, $conversationIds: [ID!], $participantIds: [ID!], $before: String, $state: [ShortMessageState!], $excludeState: [ShortMessageState!], $after: String) {
  messages(
    appointmentId: $appointmentId
    conversationIds: $conversationIds
    participantIds: $participantIds
    before: $before
    state: $state
    excludeState: $excludeState
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        ...messageEssentials
      }
    }
  }
}
    ${MessageEssentialsFragmentDoc}`;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      conversationIds: // value for 'conversationIds'
 *      participantIds: // value for 'participantIds'
 *      before: // value for 'before'
 *      state: // value for 'state'
 *      excludeState: // value for 'excludeState'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMessagesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.MessagesQuery, Operation.MessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.MessagesQuery, Operation.MessagesQueryVariables>(MessagesDocument, options);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.MessagesQuery, Operation.MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.MessagesQuery, Operation.MessagesQueryVariables>(MessagesDocument, options);
        }
export function useMessagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.MessagesQuery, Operation.MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.MessagesQuery, Operation.MessagesQueryVariables>(MessagesDocument, options);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesSuspenseQueryHookResult = ReturnType<typeof useMessagesSuspenseQuery>;
export type MessagesQueryResult = Apollo.QueryResult<Operation.MessagesQuery, Operation.MessagesQueryVariables>;
export const EmailsDocument = gql`
    query Emails($recipientId: ID, $guid: ID, $state: [EmailState!], $showCancelled: Boolean, $showScheduled: Boolean, $showFailed: Boolean, $after: String) {
  emails(
    recipientId: $recipientId
    recipientGuid: $guid
    state: $state
    showCancelled: $showCancelled
    showScheduled: $showScheduled
    showFailed: $showFailed
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        ...emailEssentials
      }
    }
  }
}
    ${EmailEssentialsFragmentDoc}`;

/**
 * __useEmailsQuery__
 *
 * To run a query within a React component, call `useEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailsQuery({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *      guid: // value for 'guid'
 *      state: // value for 'state'
 *      showCancelled: // value for 'showCancelled'
 *      showScheduled: // value for 'showScheduled'
 *      showFailed: // value for 'showFailed'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useEmailsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.EmailsQuery, Operation.EmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.EmailsQuery, Operation.EmailsQueryVariables>(EmailsDocument, options);
      }
export function useEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.EmailsQuery, Operation.EmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.EmailsQuery, Operation.EmailsQueryVariables>(EmailsDocument, options);
        }
export function useEmailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.EmailsQuery, Operation.EmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.EmailsQuery, Operation.EmailsQueryVariables>(EmailsDocument, options);
        }
export type EmailsQueryHookResult = ReturnType<typeof useEmailsQuery>;
export type EmailsLazyQueryHookResult = ReturnType<typeof useEmailsLazyQuery>;
export type EmailsSuspenseQueryHookResult = ReturnType<typeof useEmailsSuspenseQuery>;
export type EmailsQueryResult = Apollo.QueryResult<Operation.EmailsQuery, Operation.EmailsQueryVariables>;
export const FormTemplateDocument = gql`
    query FormTemplate($guid: ID!) {
  template(guid: $guid) {
    success
    messages {
      ...responseMessage
    }
    result {
      ...formTemplateDetailed
    }
  }
}
    ${ResponseMessageFragmentDoc}
${FormTemplateDetailedFragmentDoc}`;

/**
 * __useFormTemplateQuery__
 *
 * To run a query within a React component, call `useFormTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormTemplateQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useFormTemplateQuery(baseOptions: Apollo.QueryHookOptions<Operation.FormTemplateQuery, Operation.FormTemplateQueryVariables> & ({ variables: Operation.FormTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FormTemplateQuery, Operation.FormTemplateQueryVariables>(FormTemplateDocument, options);
      }
export function useFormTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FormTemplateQuery, Operation.FormTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FormTemplateQuery, Operation.FormTemplateQueryVariables>(FormTemplateDocument, options);
        }
export function useFormTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FormTemplateQuery, Operation.FormTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FormTemplateQuery, Operation.FormTemplateQueryVariables>(FormTemplateDocument, options);
        }
export type FormTemplateQueryHookResult = ReturnType<typeof useFormTemplateQuery>;
export type FormTemplateLazyQueryHookResult = ReturnType<typeof useFormTemplateLazyQuery>;
export type FormTemplateSuspenseQueryHookResult = ReturnType<typeof useFormTemplateSuspenseQuery>;
export type FormTemplateQueryResult = Apollo.QueryResult<Operation.FormTemplateQuery, Operation.FormTemplateQueryVariables>;
export const FormTemplatesDocument = gql`
    query FormTemplates($search: String, $type: [TemplateType!], $state: [TemplateState!], $limit: Int, $after: String) {
  formTemplates(
    variant: form
    search: $search
    type: $type
    state: $state
    first: $limit
    after: $after
  ) {
    edges {
      node {
        ...formTemplateEssentials
      }
    }
    pageInfo {
      ...pageInfo
    }
  }
}
    ${FormTemplateEssentialsFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useFormTemplatesQuery__
 *
 * To run a query within a React component, call `useFormTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormTemplatesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      type: // value for 'type'
 *      state: // value for 'state'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFormTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.FormTemplatesQuery, Operation.FormTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FormTemplatesQuery, Operation.FormTemplatesQueryVariables>(FormTemplatesDocument, options);
      }
export function useFormTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FormTemplatesQuery, Operation.FormTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FormTemplatesQuery, Operation.FormTemplatesQueryVariables>(FormTemplatesDocument, options);
        }
export function useFormTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FormTemplatesQuery, Operation.FormTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FormTemplatesQuery, Operation.FormTemplatesQueryVariables>(FormTemplatesDocument, options);
        }
export type FormTemplatesQueryHookResult = ReturnType<typeof useFormTemplatesQuery>;
export type FormTemplatesLazyQueryHookResult = ReturnType<typeof useFormTemplatesLazyQuery>;
export type FormTemplatesSuspenseQueryHookResult = ReturnType<typeof useFormTemplatesSuspenseQuery>;
export type FormTemplatesQueryResult = Apollo.QueryResult<Operation.FormTemplatesQuery, Operation.FormTemplatesQueryVariables>;
export const FormDocument = gql`
    query Form($id: ID!) {
  form(id: $id) {
    ...form
  }
}
    ${FormFragmentDoc}`;

/**
 * __useFormQuery__
 *
 * To run a query within a React component, call `useFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormQuery(baseOptions: Apollo.QueryHookOptions<Operation.FormQuery, Operation.FormQueryVariables> & ({ variables: Operation.FormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FormQuery, Operation.FormQueryVariables>(FormDocument, options);
      }
export function useFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FormQuery, Operation.FormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FormQuery, Operation.FormQueryVariables>(FormDocument, options);
        }
export function useFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FormQuery, Operation.FormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FormQuery, Operation.FormQueryVariables>(FormDocument, options);
        }
export type FormQueryHookResult = ReturnType<typeof useFormQuery>;
export type FormLazyQueryHookResult = ReturnType<typeof useFormLazyQuery>;
export type FormSuspenseQueryHookResult = ReturnType<typeof useFormSuspenseQuery>;
export type FormQueryResult = Apollo.QueryResult<Operation.FormQuery, Operation.FormQueryVariables>;
export const FormSectionsDocument = gql`
    query FormSections($template: ID, $form: ID) {
  formSections(template: $template, form: $form) {
    ...formSection
  }
}
    ${FormSectionFragmentDoc}`;

/**
 * __useFormSectionsQuery__
 *
 * To run a query within a React component, call `useFormSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormSectionsQuery({
 *   variables: {
 *      template: // value for 'template'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useFormSectionsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.FormSectionsQuery, Operation.FormSectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FormSectionsQuery, Operation.FormSectionsQueryVariables>(FormSectionsDocument, options);
      }
export function useFormSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FormSectionsQuery, Operation.FormSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FormSectionsQuery, Operation.FormSectionsQueryVariables>(FormSectionsDocument, options);
        }
export function useFormSectionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FormSectionsQuery, Operation.FormSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FormSectionsQuery, Operation.FormSectionsQueryVariables>(FormSectionsDocument, options);
        }
export type FormSectionsQueryHookResult = ReturnType<typeof useFormSectionsQuery>;
export type FormSectionsLazyQueryHookResult = ReturnType<typeof useFormSectionsLazyQuery>;
export type FormSectionsSuspenseQueryHookResult = ReturnType<typeof useFormSectionsSuspenseQuery>;
export type FormSectionsQueryResult = Apollo.QueryResult<Operation.FormSectionsQuery, Operation.FormSectionsQueryVariables>;
export const FormsDocument = gql`
    query Forms($ids: [ID!], $search: String, $template: ID, $orderBy: FormOrderInput, $recipients: [ID!]) {
  forms(
    ids: $ids
    search: $search
    template: $template
    orderBy: $orderBy
    recipients: $recipients
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...formListItem
      }
    }
  }
}
    ${FormListItemFragmentDoc}`;

/**
 * __useFormsQuery__
 *
 * To run a query within a React component, call `useFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      search: // value for 'search'
 *      template: // value for 'template'
 *      orderBy: // value for 'orderBy'
 *      recipients: // value for 'recipients'
 *   },
 * });
 */
export function useFormsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.FormsQuery, Operation.FormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FormsQuery, Operation.FormsQueryVariables>(FormsDocument, options);
      }
export function useFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FormsQuery, Operation.FormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FormsQuery, Operation.FormsQueryVariables>(FormsDocument, options);
        }
export function useFormsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FormsQuery, Operation.FormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FormsQuery, Operation.FormsQueryVariables>(FormsDocument, options);
        }
export type FormsQueryHookResult = ReturnType<typeof useFormsQuery>;
export type FormsLazyQueryHookResult = ReturnType<typeof useFormsLazyQuery>;
export type FormsSuspenseQueryHookResult = ReturnType<typeof useFormsSuspenseQuery>;
export type FormsQueryResult = Apollo.QueryResult<Operation.FormsQuery, Operation.FormsQueryVariables>;
export const LettersDocument = gql`
    query Letters($recipientId: ID, $slug: String, $state: [LetterState!], $after: String) {
  letters(
    recipientId: $recipientId
    recipientSlug: $slug
    state: $state
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        ...letterEssentials
      }
    }
  }
}
    ${LetterEssentialsFragmentDoc}`;

/**
 * __useLettersQuery__
 *
 * To run a query within a React component, call `useLettersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLettersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLettersQuery({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *      slug: // value for 'slug'
 *      state: // value for 'state'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useLettersQuery(baseOptions?: Apollo.QueryHookOptions<Operation.LettersQuery, Operation.LettersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.LettersQuery, Operation.LettersQueryVariables>(LettersDocument, options);
      }
export function useLettersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.LettersQuery, Operation.LettersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.LettersQuery, Operation.LettersQueryVariables>(LettersDocument, options);
        }
export function useLettersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.LettersQuery, Operation.LettersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.LettersQuery, Operation.LettersQueryVariables>(LettersDocument, options);
        }
export type LettersQueryHookResult = ReturnType<typeof useLettersQuery>;
export type LettersLazyQueryHookResult = ReturnType<typeof useLettersLazyQuery>;
export type LettersSuspenseQueryHookResult = ReturnType<typeof useLettersSuspenseQuery>;
export type LettersQueryResult = Apollo.QueryResult<Operation.LettersQuery, Operation.LettersQueryVariables>;
export const LetterDocument = gql`
    query Letter($id: ID, $guid: ID) {
  letter(id: $id, guid: $guid) {
    ...letterEssentials
  }
}
    ${LetterEssentialsFragmentDoc}`;

/**
 * __useLetterQuery__
 *
 * To run a query within a React component, call `useLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLetterQuery({
 *   variables: {
 *      id: // value for 'id'
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useLetterQuery(baseOptions?: Apollo.QueryHookOptions<Operation.LetterQuery, Operation.LetterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.LetterQuery, Operation.LetterQueryVariables>(LetterDocument, options);
      }
export function useLetterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.LetterQuery, Operation.LetterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.LetterQuery, Operation.LetterQueryVariables>(LetterDocument, options);
        }
export function useLetterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.LetterQuery, Operation.LetterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.LetterQuery, Operation.LetterQueryVariables>(LetterDocument, options);
        }
export type LetterQueryHookResult = ReturnType<typeof useLetterQuery>;
export type LetterLazyQueryHookResult = ReturnType<typeof useLetterLazyQuery>;
export type LetterSuspenseQueryHookResult = ReturnType<typeof useLetterSuspenseQuery>;
export type LetterQueryResult = Apollo.QueryResult<Operation.LetterQuery, Operation.LetterQueryVariables>;
export const NoteTypesDocument = gql`
    query NoteTypes {
  noteTypes {
    ...noteTypeEssentials
  }
}
    ${NoteTypeEssentialsFragmentDoc}`;

/**
 * __useNoteTypesQuery__
 *
 * To run a query within a React component, call `useNoteTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNoteTypesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.NoteTypesQuery, Operation.NoteTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.NoteTypesQuery, Operation.NoteTypesQueryVariables>(NoteTypesDocument, options);
      }
export function useNoteTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.NoteTypesQuery, Operation.NoteTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.NoteTypesQuery, Operation.NoteTypesQueryVariables>(NoteTypesDocument, options);
        }
export function useNoteTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.NoteTypesQuery, Operation.NoteTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.NoteTypesQuery, Operation.NoteTypesQueryVariables>(NoteTypesDocument, options);
        }
export type NoteTypesQueryHookResult = ReturnType<typeof useNoteTypesQuery>;
export type NoteTypesLazyQueryHookResult = ReturnType<typeof useNoteTypesLazyQuery>;
export type NoteTypesSuspenseQueryHookResult = ReturnType<typeof useNoteTypesSuspenseQuery>;
export type NoteTypesQueryResult = Apollo.QueryResult<Operation.NoteTypesQuery, Operation.NoteTypesQueryVariables>;
export const NotesDocument = gql`
    query Notes($authors: [ID!], $slug: String, $uuid: String, $patients: [ID!], $noteTypes: [NoteType!], $search: String, $sticky: Boolean, $states: [NoteState!], $fdi: [String!], $includeAutoNotes: Boolean, $createdAtTime: TimeRangeInput, $first: Int, $after: String) {
  notes(
    authors: $authors
    slug: $slug
    uuid: $uuid
    patients: $patients
    noteTypes: $noteTypes
    search: $search
    sticky: $sticky
    state: $states
    fdi: $fdi
    includeAutoNotes: $includeAutoNotes
    createdAtTime: $createdAtTime
    first: $first
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        ...noteEssentials
      }
    }
  }
}
    ${NoteEssentialsFragmentDoc}`;

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      authors: // value for 'authors'
 *      slug: // value for 'slug'
 *      uuid: // value for 'uuid'
 *      patients: // value for 'patients'
 *      noteTypes: // value for 'noteTypes'
 *      search: // value for 'search'
 *      sticky: // value for 'sticky'
 *      states: // value for 'states'
 *      fdi: // value for 'fdi'
 *      includeAutoNotes: // value for 'includeAutoNotes'
 *      createdAtTime: // value for 'createdAtTime'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useNotesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.NotesQuery, Operation.NotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.NotesQuery, Operation.NotesQueryVariables>(NotesDocument, options);
      }
export function useNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.NotesQuery, Operation.NotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.NotesQuery, Operation.NotesQueryVariables>(NotesDocument, options);
        }
export function useNotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.NotesQuery, Operation.NotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.NotesQuery, Operation.NotesQueryVariables>(NotesDocument, options);
        }
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesSuspenseQueryHookResult = ReturnType<typeof useNotesSuspenseQuery>;
export type NotesQueryResult = Apollo.QueryResult<Operation.NotesQuery, Operation.NotesQueryVariables>;
export const NotesCountDocument = gql`
    query NotesCount($authors: [ID!], $slug: String, $uuid: String, $patients: [ID!], $noteTypes: [NoteType!], $search: String, $sticky: Boolean, $states: [NoteState!], $first: Int, $after: String) {
  notes(
    authors: $authors
    slug: $slug
    uuid: $uuid
    patients: $patients
    noteTypes: $noteTypes
    search: $search
    sticky: $sticky
    state: $states
    first: $first
    after: $after
  ) {
    totalCount
  }
}
    `;

/**
 * __useNotesCountQuery__
 *
 * To run a query within a React component, call `useNotesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesCountQuery({
 *   variables: {
 *      authors: // value for 'authors'
 *      slug: // value for 'slug'
 *      uuid: // value for 'uuid'
 *      patients: // value for 'patients'
 *      noteTypes: // value for 'noteTypes'
 *      search: // value for 'search'
 *      sticky: // value for 'sticky'
 *      states: // value for 'states'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useNotesCountQuery(baseOptions?: Apollo.QueryHookOptions<Operation.NotesCountQuery, Operation.NotesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.NotesCountQuery, Operation.NotesCountQueryVariables>(NotesCountDocument, options);
      }
export function useNotesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.NotesCountQuery, Operation.NotesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.NotesCountQuery, Operation.NotesCountQueryVariables>(NotesCountDocument, options);
        }
export function useNotesCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.NotesCountQuery, Operation.NotesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.NotesCountQuery, Operation.NotesCountQueryVariables>(NotesCountDocument, options);
        }
export type NotesCountQueryHookResult = ReturnType<typeof useNotesCountQuery>;
export type NotesCountLazyQueryHookResult = ReturnType<typeof useNotesCountLazyQuery>;
export type NotesCountSuspenseQueryHookResult = ReturnType<typeof useNotesCountSuspenseQuery>;
export type NotesCountQueryResult = Apollo.QueryResult<Operation.NotesCountQuery, Operation.NotesCountQueryVariables>;
export const PdfInvoiceDocument = gql`
    query PdfInvoice($id: ID!, $includeTransactions: Boolean) {
  pdfInvoice(id: $id, includeTransactions: $includeTransactions) {
    ...base64Files
  }
}
    ${Base64FilesFragmentDoc}`;

/**
 * __usePdfInvoiceQuery__
 *
 * To run a query within a React component, call `usePdfInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeTransactions: // value for 'includeTransactions'
 *   },
 * });
 */
export function usePdfInvoiceQuery(baseOptions: Apollo.QueryHookOptions<Operation.PdfInvoiceQuery, Operation.PdfInvoiceQueryVariables> & ({ variables: Operation.PdfInvoiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PdfInvoiceQuery, Operation.PdfInvoiceQueryVariables>(PdfInvoiceDocument, options);
      }
export function usePdfInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PdfInvoiceQuery, Operation.PdfInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PdfInvoiceQuery, Operation.PdfInvoiceQueryVariables>(PdfInvoiceDocument, options);
        }
export function usePdfInvoiceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PdfInvoiceQuery, Operation.PdfInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PdfInvoiceQuery, Operation.PdfInvoiceQueryVariables>(PdfInvoiceDocument, options);
        }
export type PdfInvoiceQueryHookResult = ReturnType<typeof usePdfInvoiceQuery>;
export type PdfInvoiceLazyQueryHookResult = ReturnType<typeof usePdfInvoiceLazyQuery>;
export type PdfInvoiceSuspenseQueryHookResult = ReturnType<typeof usePdfInvoiceSuspenseQuery>;
export type PdfInvoiceQueryResult = Apollo.QueryResult<Operation.PdfInvoiceQuery, Operation.PdfInvoiceQueryVariables>;
export const PdfPaymentTransactionDocument = gql`
    query PdfPaymentTransaction($id: ID!) {
  pdfPaymentTransaction(id: $id) {
    ...base64Files
  }
}
    ${Base64FilesFragmentDoc}`;

/**
 * __usePdfPaymentTransactionQuery__
 *
 * To run a query within a React component, call `usePdfPaymentTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfPaymentTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfPaymentTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePdfPaymentTransactionQuery(baseOptions: Apollo.QueryHookOptions<Operation.PdfPaymentTransactionQuery, Operation.PdfPaymentTransactionQueryVariables> & ({ variables: Operation.PdfPaymentTransactionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PdfPaymentTransactionQuery, Operation.PdfPaymentTransactionQueryVariables>(PdfPaymentTransactionDocument, options);
      }
export function usePdfPaymentTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PdfPaymentTransactionQuery, Operation.PdfPaymentTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PdfPaymentTransactionQuery, Operation.PdfPaymentTransactionQueryVariables>(PdfPaymentTransactionDocument, options);
        }
export function usePdfPaymentTransactionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PdfPaymentTransactionQuery, Operation.PdfPaymentTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PdfPaymentTransactionQuery, Operation.PdfPaymentTransactionQueryVariables>(PdfPaymentTransactionDocument, options);
        }
export type PdfPaymentTransactionQueryHookResult = ReturnType<typeof usePdfPaymentTransactionQuery>;
export type PdfPaymentTransactionLazyQueryHookResult = ReturnType<typeof usePdfPaymentTransactionLazyQuery>;
export type PdfPaymentTransactionSuspenseQueryHookResult = ReturnType<typeof usePdfPaymentTransactionSuspenseQuery>;
export type PdfPaymentTransactionQueryResult = Apollo.QueryResult<Operation.PdfPaymentTransactionQuery, Operation.PdfPaymentTransactionQueryVariables>;
export const PdfLettersDocument = gql`
    query PdfLetters($ids: [ID!]) {
  pdfLetters(ids: $ids) {
    ...base64Files
  }
}
    ${Base64FilesFragmentDoc}`;

/**
 * __usePdfLettersQuery__
 *
 * To run a query within a React component, call `usePdfLettersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfLettersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfLettersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePdfLettersQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PdfLettersQuery, Operation.PdfLettersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PdfLettersQuery, Operation.PdfLettersQueryVariables>(PdfLettersDocument, options);
      }
export function usePdfLettersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PdfLettersQuery, Operation.PdfLettersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PdfLettersQuery, Operation.PdfLettersQueryVariables>(PdfLettersDocument, options);
        }
export function usePdfLettersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PdfLettersQuery, Operation.PdfLettersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PdfLettersQuery, Operation.PdfLettersQueryVariables>(PdfLettersDocument, options);
        }
export type PdfLettersQueryHookResult = ReturnType<typeof usePdfLettersQuery>;
export type PdfLettersLazyQueryHookResult = ReturnType<typeof usePdfLettersLazyQuery>;
export type PdfLettersSuspenseQueryHookResult = ReturnType<typeof usePdfLettersSuspenseQuery>;
export type PdfLettersQueryResult = Apollo.QueryResult<Operation.PdfLettersQuery, Operation.PdfLettersQueryVariables>;
export const PdfNotesDocument = gql`
    query PdfNotes($authors: [ID!], $patient: ID!, $noteTypes: [NoteType!], $search: String, $fdi: [String!]) {
  pdfNotes(
    authors: $authors
    patient: $patient
    noteTypes: $noteTypes
    search: $search
    fdi: $fdi
  ) {
    ...base64Files
  }
}
    ${Base64FilesFragmentDoc}`;

/**
 * __usePdfNotesQuery__
 *
 * To run a query within a React component, call `usePdfNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfNotesQuery({
 *   variables: {
 *      authors: // value for 'authors'
 *      patient: // value for 'patient'
 *      noteTypes: // value for 'noteTypes'
 *      search: // value for 'search'
 *      fdi: // value for 'fdi'
 *   },
 * });
 */
export function usePdfNotesQuery(baseOptions: Apollo.QueryHookOptions<Operation.PdfNotesQuery, Operation.PdfNotesQueryVariables> & ({ variables: Operation.PdfNotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PdfNotesQuery, Operation.PdfNotesQueryVariables>(PdfNotesDocument, options);
      }
export function usePdfNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PdfNotesQuery, Operation.PdfNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PdfNotesQuery, Operation.PdfNotesQueryVariables>(PdfNotesDocument, options);
        }
export function usePdfNotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PdfNotesQuery, Operation.PdfNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PdfNotesQuery, Operation.PdfNotesQueryVariables>(PdfNotesDocument, options);
        }
export type PdfNotesQueryHookResult = ReturnType<typeof usePdfNotesQuery>;
export type PdfNotesLazyQueryHookResult = ReturnType<typeof usePdfNotesLazyQuery>;
export type PdfNotesSuspenseQueryHookResult = ReturnType<typeof usePdfNotesSuspenseQuery>;
export type PdfNotesQueryResult = Apollo.QueryResult<Operation.PdfNotesQuery, Operation.PdfNotesQueryVariables>;
export const SmsDocument = gql`
    query Sms($upcGuid: String, $recipientId: ID, $guid: ID, $state: [ShortMessageState!], $showCancelled: Boolean, $showScheduled: Boolean, $showFailed: Boolean, $limit: Int, $after: String) {
  sms(
    upcGuid: $upcGuid
    recipientId: $recipientId
    recipientGuid: $guid
    state: $state
    showCancelled: $showCancelled
    showScheduled: $showScheduled
    showFailed: $showFailed
    first: $limit
    after: $after
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      node {
        ...smsEssentials
      }
    }
  }
}
    ${SmsEssentialsFragmentDoc}`;

/**
 * __useSmsQuery__
 *
 * To run a query within a React component, call `useSmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmsQuery({
 *   variables: {
 *      upcGuid: // value for 'upcGuid'
 *      recipientId: // value for 'recipientId'
 *      guid: // value for 'guid'
 *      state: // value for 'state'
 *      showCancelled: // value for 'showCancelled'
 *      showScheduled: // value for 'showScheduled'
 *      showFailed: // value for 'showFailed'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSmsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.SmsQuery, Operation.SmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.SmsQuery, Operation.SmsQueryVariables>(SmsDocument, options);
      }
export function useSmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.SmsQuery, Operation.SmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.SmsQuery, Operation.SmsQueryVariables>(SmsDocument, options);
        }
export function useSmsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.SmsQuery, Operation.SmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.SmsQuery, Operation.SmsQueryVariables>(SmsDocument, options);
        }
export type SmsQueryHookResult = ReturnType<typeof useSmsQuery>;
export type SmsLazyQueryHookResult = ReturnType<typeof useSmsLazyQuery>;
export type SmsSuspenseQueryHookResult = ReturnType<typeof useSmsSuspenseQuery>;
export type SmsQueryResult = Apollo.QueryResult<Operation.SmsQuery, Operation.SmsQueryVariables>;
export const InboxDocument = gql`
    query Inbox($recipientId: ID, $state: [ShortMessageState!], $limit: Int, $after: String) {
  sms(recipientId: $recipientId, state: $state, first: $limit, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      node {
        ...inboxMessage
      }
    }
  }
}
    ${InboxMessageFragmentDoc}`;

/**
 * __useInboxQuery__
 *
 * To run a query within a React component, call `useInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxQuery({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *      state: // value for 'state'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInboxQuery(baseOptions?: Apollo.QueryHookOptions<Operation.InboxQuery, Operation.InboxQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InboxQuery, Operation.InboxQueryVariables>(InboxDocument, options);
      }
export function useInboxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InboxQuery, Operation.InboxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InboxQuery, Operation.InboxQueryVariables>(InboxDocument, options);
        }
export function useInboxSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InboxQuery, Operation.InboxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InboxQuery, Operation.InboxQueryVariables>(InboxDocument, options);
        }
export type InboxQueryHookResult = ReturnType<typeof useInboxQuery>;
export type InboxLazyQueryHookResult = ReturnType<typeof useInboxLazyQuery>;
export type InboxSuspenseQueryHookResult = ReturnType<typeof useInboxSuspenseQuery>;
export type InboxQueryResult = Apollo.QueryResult<Operation.InboxQuery, Operation.InboxQueryVariables>;
export const TemplateDocument = gql`
    query Template($guid: ID!) {
  template(guid: $guid) {
    success
    messages {
      ...responseMessage
    }
    result {
      ...templateDetails
    }
  }
}
    ${ResponseMessageFragmentDoc}
${TemplateDetailsFragmentDoc}`;

/**
 * __useTemplateQuery__
 *
 * To run a query within a React component, call `useTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useTemplateQuery(baseOptions: Apollo.QueryHookOptions<Operation.TemplateQuery, Operation.TemplateQueryVariables> & ({ variables: Operation.TemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TemplateQuery, Operation.TemplateQueryVariables>(TemplateDocument, options);
      }
export function useTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TemplateQuery, Operation.TemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TemplateQuery, Operation.TemplateQueryVariables>(TemplateDocument, options);
        }
export function useTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TemplateQuery, Operation.TemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TemplateQuery, Operation.TemplateQueryVariables>(TemplateDocument, options);
        }
export type TemplateQueryHookResult = ReturnType<typeof useTemplateQuery>;
export type TemplateLazyQueryHookResult = ReturnType<typeof useTemplateLazyQuery>;
export type TemplateSuspenseQueryHookResult = ReturnType<typeof useTemplateSuspenseQuery>;
export type TemplateQueryResult = Apollo.QueryResult<Operation.TemplateQuery, Operation.TemplateQueryVariables>;
export const TemplateTypesDocument = gql`
    query TemplateTypes($groups: [TemplateGroup!], $variants: [TemplateVariant!], $communicationTypes: [CommunicationType!]) {
  templateTypes(
    groups: $groups
    variants: $variants
    communicationTypes: $communicationTypes
  ) {
    ...templateTypeTranslation
  }
}
    ${TemplateTypeTranslationFragmentDoc}`;

/**
 * __useTemplateTypesQuery__
 *
 * To run a query within a React component, call `useTemplateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateTypesQuery({
 *   variables: {
 *      groups: // value for 'groups'
 *      variants: // value for 'variants'
 *      communicationTypes: // value for 'communicationTypes'
 *   },
 * });
 */
export function useTemplateTypesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TemplateTypesQuery, Operation.TemplateTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TemplateTypesQuery, Operation.TemplateTypesQueryVariables>(TemplateTypesDocument, options);
      }
export function useTemplateTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TemplateTypesQuery, Operation.TemplateTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TemplateTypesQuery, Operation.TemplateTypesQueryVariables>(TemplateTypesDocument, options);
        }
export function useTemplateTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TemplateTypesQuery, Operation.TemplateTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TemplateTypesQuery, Operation.TemplateTypesQueryVariables>(TemplateTypesDocument, options);
        }
export type TemplateTypesQueryHookResult = ReturnType<typeof useTemplateTypesQuery>;
export type TemplateTypesLazyQueryHookResult = ReturnType<typeof useTemplateTypesLazyQuery>;
export type TemplateTypesSuspenseQueryHookResult = ReturnType<typeof useTemplateTypesSuspenseQuery>;
export type TemplateTypesQueryResult = Apollo.QueryResult<Operation.TemplateTypesQuery, Operation.TemplateTypesQueryVariables>;
export const TemplateDropsDocument = gql`
    query TemplateDrops($types: [TemplateType!], $drops: [LiquidDropType!]) {
  templateDrops(types: $types, drops: $drops) {
    ...templateDrop
  }
}
    ${TemplateDropFragmentDoc}`;

/**
 * __useTemplateDropsQuery__
 *
 * To run a query within a React component, call `useTemplateDropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateDropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateDropsQuery({
 *   variables: {
 *      types: // value for 'types'
 *      drops: // value for 'drops'
 *   },
 * });
 */
export function useTemplateDropsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TemplateDropsQuery, Operation.TemplateDropsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TemplateDropsQuery, Operation.TemplateDropsQueryVariables>(TemplateDropsDocument, options);
      }
export function useTemplateDropsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TemplateDropsQuery, Operation.TemplateDropsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TemplateDropsQuery, Operation.TemplateDropsQueryVariables>(TemplateDropsDocument, options);
        }
export function useTemplateDropsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TemplateDropsQuery, Operation.TemplateDropsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TemplateDropsQuery, Operation.TemplateDropsQueryVariables>(TemplateDropsDocument, options);
        }
export type TemplateDropsQueryHookResult = ReturnType<typeof useTemplateDropsQuery>;
export type TemplateDropsLazyQueryHookResult = ReturnType<typeof useTemplateDropsLazyQuery>;
export type TemplateDropsSuspenseQueryHookResult = ReturnType<typeof useTemplateDropsSuspenseQuery>;
export type TemplateDropsQueryResult = Apollo.QueryResult<Operation.TemplateDropsQuery, Operation.TemplateDropsQueryVariables>;
export const TemplateDropDocument = gql`
    query TemplateDrop($drop: LiquidDropType, $templateType: TemplateType) {
  templateDrop(drop: $drop, templateType: $templateType) {
    ...templateDrop
  }
}
    ${TemplateDropFragmentDoc}`;

/**
 * __useTemplateDropQuery__
 *
 * To run a query within a React component, call `useTemplateDropQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateDropQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateDropQuery({
 *   variables: {
 *      drop: // value for 'drop'
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useTemplateDropQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TemplateDropQuery, Operation.TemplateDropQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TemplateDropQuery, Operation.TemplateDropQueryVariables>(TemplateDropDocument, options);
      }
export function useTemplateDropLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TemplateDropQuery, Operation.TemplateDropQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TemplateDropQuery, Operation.TemplateDropQueryVariables>(TemplateDropDocument, options);
        }
export function useTemplateDropSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TemplateDropQuery, Operation.TemplateDropQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TemplateDropQuery, Operation.TemplateDropQueryVariables>(TemplateDropDocument, options);
        }
export type TemplateDropQueryHookResult = ReturnType<typeof useTemplateDropQuery>;
export type TemplateDropLazyQueryHookResult = ReturnType<typeof useTemplateDropLazyQuery>;
export type TemplateDropSuspenseQueryHookResult = ReturnType<typeof useTemplateDropSuspenseQuery>;
export type TemplateDropQueryResult = Apollo.QueryResult<Operation.TemplateDropQuery, Operation.TemplateDropQueryVariables>;
export const TemplatesDocument = gql`
    query Templates($type: [TemplateType!], $excludeType: [TemplateType!], $state: [TemplateState!], $search: String, $variant: TemplateVariant, $hasEmail: Boolean, $communicationType: [CommunicationType!], $hasSms: Boolean, $hasLetter: Boolean) {
  templates(
    search: $search
    type: $type
    excludeType: $excludeType
    communicationType: $communicationType
    state: $state
    variant: $variant
    hasEmail: $hasEmail
    hasLetter: $hasLetter
    hasSms: $hasSms
  ) {
    pageInfo {
      ...pageInfo
    }
    edges {
      cursor
      node {
        ...templateEssentials
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${TemplateEssentialsFragmentDoc}`;

/**
 * __useTemplatesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuery({
 *   variables: {
 *      type: // value for 'type'
 *      excludeType: // value for 'excludeType'
 *      state: // value for 'state'
 *      search: // value for 'search'
 *      variant: // value for 'variant'
 *      hasEmail: // value for 'hasEmail'
 *      communicationType: // value for 'communicationType'
 *      hasSms: // value for 'hasSms'
 *      hasLetter: // value for 'hasLetter'
 *   },
 * });
 */
export function useTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TemplatesQuery, Operation.TemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TemplatesQuery, Operation.TemplatesQueryVariables>(TemplatesDocument, options);
      }
export function useTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TemplatesQuery, Operation.TemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TemplatesQuery, Operation.TemplatesQueryVariables>(TemplatesDocument, options);
        }
export function useTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TemplatesQuery, Operation.TemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TemplatesQuery, Operation.TemplatesQueryVariables>(TemplatesDocument, options);
        }
export type TemplatesQueryHookResult = ReturnType<typeof useTemplatesQuery>;
export type TemplatesLazyQueryHookResult = ReturnType<typeof useTemplatesLazyQuery>;
export type TemplatesSuspenseQueryHookResult = ReturnType<typeof useTemplatesSuspenseQuery>;
export type TemplatesQueryResult = Apollo.QueryResult<Operation.TemplatesQuery, Operation.TemplatesQueryVariables>;
export const CommunicationTemplatesDocument = gql`
    query CommunicationTemplates($type: [TemplateType!], $excludeType: [TemplateType!], $state: [TemplateState!], $search: String, $communicationType: [CommunicationType!], $variant: TemplateVariant, $hasEmail: Boolean, $hasSms: Boolean, $hasLetter: Boolean) {
  communicationTemplates(
    search: $search
    type: $type
    communicationType: $communicationType
    excludeType: $excludeType
    state: $state
    variant: $variant
    hasEmail: $hasEmail
    hasLetter: $hasLetter
    hasSms: $hasSms
  ) {
    pageInfo {
      ...pageInfo
    }
    edges {
      cursor
      node {
        ...templateEssentials
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${TemplateEssentialsFragmentDoc}`;

/**
 * __useCommunicationTemplatesQuery__
 *
 * To run a query within a React component, call `useCommunicationTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunicationTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunicationTemplatesQuery({
 *   variables: {
 *      type: // value for 'type'
 *      excludeType: // value for 'excludeType'
 *      state: // value for 'state'
 *      search: // value for 'search'
 *      communicationType: // value for 'communicationType'
 *      variant: // value for 'variant'
 *      hasEmail: // value for 'hasEmail'
 *      hasSms: // value for 'hasSms'
 *      hasLetter: // value for 'hasLetter'
 *   },
 * });
 */
export function useCommunicationTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CommunicationTemplatesQuery, Operation.CommunicationTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CommunicationTemplatesQuery, Operation.CommunicationTemplatesQueryVariables>(CommunicationTemplatesDocument, options);
      }
export function useCommunicationTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CommunicationTemplatesQuery, Operation.CommunicationTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CommunicationTemplatesQuery, Operation.CommunicationTemplatesQueryVariables>(CommunicationTemplatesDocument, options);
        }
export function useCommunicationTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CommunicationTemplatesQuery, Operation.CommunicationTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CommunicationTemplatesQuery, Operation.CommunicationTemplatesQueryVariables>(CommunicationTemplatesDocument, options);
        }
export type CommunicationTemplatesQueryHookResult = ReturnType<typeof useCommunicationTemplatesQuery>;
export type CommunicationTemplatesLazyQueryHookResult = ReturnType<typeof useCommunicationTemplatesLazyQuery>;
export type CommunicationTemplatesSuspenseQueryHookResult = ReturnType<typeof useCommunicationTemplatesSuspenseQuery>;
export type CommunicationTemplatesQueryResult = Apollo.QueryResult<Operation.CommunicationTemplatesQuery, Operation.CommunicationTemplatesQueryVariables>;
export const DocumentTemplatesDocument = gql`
    query DocumentTemplates($type: [TemplateType!], $excludeType: [TemplateType!], $state: [TemplateState!], $search: String, $variant: TemplateVariant, $communicationType: [CommunicationType!], $hasEmail: Boolean, $hasSms: Boolean, $hasLetter: Boolean) {
  documentTemplates(
    search: $search
    type: $type
    excludeType: $excludeType
    communicationType: $communicationType
    state: $state
    variant: $variant
    hasEmail: $hasEmail
    hasLetter: $hasLetter
    hasSms: $hasSms
  ) {
    pageInfo {
      ...pageInfo
    }
    edges {
      cursor
      node {
        ...templateEssentials
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${TemplateEssentialsFragmentDoc}`;

/**
 * __useDocumentTemplatesQuery__
 *
 * To run a query within a React component, call `useDocumentTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTemplatesQuery({
 *   variables: {
 *      type: // value for 'type'
 *      excludeType: // value for 'excludeType'
 *      state: // value for 'state'
 *      search: // value for 'search'
 *      variant: // value for 'variant'
 *      communicationType: // value for 'communicationType'
 *      hasEmail: // value for 'hasEmail'
 *      hasSms: // value for 'hasSms'
 *      hasLetter: // value for 'hasLetter'
 *   },
 * });
 */
export function useDocumentTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DocumentTemplatesQuery, Operation.DocumentTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DocumentTemplatesQuery, Operation.DocumentTemplatesQueryVariables>(DocumentTemplatesDocument, options);
      }
export function useDocumentTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DocumentTemplatesQuery, Operation.DocumentTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DocumentTemplatesQuery, Operation.DocumentTemplatesQueryVariables>(DocumentTemplatesDocument, options);
        }
export function useDocumentTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DocumentTemplatesQuery, Operation.DocumentTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DocumentTemplatesQuery, Operation.DocumentTemplatesQueryVariables>(DocumentTemplatesDocument, options);
        }
export type DocumentTemplatesQueryHookResult = ReturnType<typeof useDocumentTemplatesQuery>;
export type DocumentTemplatesLazyQueryHookResult = ReturnType<typeof useDocumentTemplatesLazyQuery>;
export type DocumentTemplatesSuspenseQueryHookResult = ReturnType<typeof useDocumentTemplatesSuspenseQuery>;
export type DocumentTemplatesQueryResult = Apollo.QueryResult<Operation.DocumentTemplatesQuery, Operation.DocumentTemplatesQueryVariables>;
export const TemplatesDetailedDocument = gql`
    query TemplatesDetailed($search: String, $type: [TemplateType!], $excludeType: [TemplateType!], $communicationType: [CommunicationType!], $state: [TemplateState!], $variant: TemplateVariant, $hasEmail: Boolean, $hasSms: Boolean, $hasLetter: Boolean) {
  templates(
    search: $search
    type: $type
    excludeType: $excludeType
    communicationType: $communicationType
    state: $state
    variant: $variant
    hasEmail: $hasEmail
    hasLetter: $hasLetter
    hasSms: $hasSms
  ) {
    pageInfo {
      ...pageInfo
    }
    edges {
      cursor
      node {
        ...templateDetails
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${TemplateDetailsFragmentDoc}`;

/**
 * __useTemplatesDetailedQuery__
 *
 * To run a query within a React component, call `useTemplatesDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesDetailedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      type: // value for 'type'
 *      excludeType: // value for 'excludeType'
 *      communicationType: // value for 'communicationType'
 *      state: // value for 'state'
 *      variant: // value for 'variant'
 *      hasEmail: // value for 'hasEmail'
 *      hasSms: // value for 'hasSms'
 *      hasLetter: // value for 'hasLetter'
 *   },
 * });
 */
export function useTemplatesDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TemplatesDetailedQuery, Operation.TemplatesDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TemplatesDetailedQuery, Operation.TemplatesDetailedQueryVariables>(TemplatesDetailedDocument, options);
      }
export function useTemplatesDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TemplatesDetailedQuery, Operation.TemplatesDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TemplatesDetailedQuery, Operation.TemplatesDetailedQueryVariables>(TemplatesDetailedDocument, options);
        }
export function useTemplatesDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TemplatesDetailedQuery, Operation.TemplatesDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TemplatesDetailedQuery, Operation.TemplatesDetailedQueryVariables>(TemplatesDetailedDocument, options);
        }
export type TemplatesDetailedQueryHookResult = ReturnType<typeof useTemplatesDetailedQuery>;
export type TemplatesDetailedLazyQueryHookResult = ReturnType<typeof useTemplatesDetailedLazyQuery>;
export type TemplatesDetailedSuspenseQueryHookResult = ReturnType<typeof useTemplatesDetailedSuspenseQuery>;
export type TemplatesDetailedQueryResult = Apollo.QueryResult<Operation.TemplatesDetailedQuery, Operation.TemplatesDetailedQueryVariables>;
export const RenderVariablesDocument = gql`
    query RenderVariables($text: String!, $variables: [LiquidDropPrefillInput!]!) {
  renderVariables(text: $text, interpolation: $variables)
}
    `;

/**
 * __useRenderVariablesQuery__
 *
 * To run a query within a React component, call `useRenderVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRenderVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRenderVariablesQuery({
 *   variables: {
 *      text: // value for 'text'
 *      variables: // value for 'variables'
 *   },
 * });
 */
export function useRenderVariablesQuery(baseOptions: Apollo.QueryHookOptions<Operation.RenderVariablesQuery, Operation.RenderVariablesQueryVariables> & ({ variables: Operation.RenderVariablesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RenderVariablesQuery, Operation.RenderVariablesQueryVariables>(RenderVariablesDocument, options);
      }
export function useRenderVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RenderVariablesQuery, Operation.RenderVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RenderVariablesQuery, Operation.RenderVariablesQueryVariables>(RenderVariablesDocument, options);
        }
export function useRenderVariablesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RenderVariablesQuery, Operation.RenderVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RenderVariablesQuery, Operation.RenderVariablesQueryVariables>(RenderVariablesDocument, options);
        }
export type RenderVariablesQueryHookResult = ReturnType<typeof useRenderVariablesQuery>;
export type RenderVariablesLazyQueryHookResult = ReturnType<typeof useRenderVariablesLazyQuery>;
export type RenderVariablesSuspenseQueryHookResult = ReturnType<typeof useRenderVariablesSuspenseQuery>;
export type RenderVariablesQueryResult = Apollo.QueryResult<Operation.RenderVariablesQuery, Operation.RenderVariablesQueryVariables>;
export const CustomerRequestsDocument = gql`
    query CustomerRequests($state: [CustomerRequestState!], $search: String, $patientId: ID, $first: Int, $after: String, $order: OrderCustomerRequests, $showArchived: Boolean, $requestableUuid: String) {
  customerRequests(
    order: $order
    state: $state
    search: $search
    patientId: $patientId
    showArchived: $showArchived
    requestableUuid: $requestableUuid
    first: $first
    after: $after
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      node {
        ...customerRequestDetailed
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${CustomerRequestDetailedFragmentDoc}`;

/**
 * __useCustomerRequestsQuery__
 *
 * To run a query within a React component, call `useCustomerRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerRequestsQuery({
 *   variables: {
 *      state: // value for 'state'
 *      search: // value for 'search'
 *      patientId: // value for 'patientId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      order: // value for 'order'
 *      showArchived: // value for 'showArchived'
 *      requestableUuid: // value for 'requestableUuid'
 *   },
 * });
 */
export function useCustomerRequestsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CustomerRequestsQuery, Operation.CustomerRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CustomerRequestsQuery, Operation.CustomerRequestsQueryVariables>(CustomerRequestsDocument, options);
      }
export function useCustomerRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CustomerRequestsQuery, Operation.CustomerRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CustomerRequestsQuery, Operation.CustomerRequestsQueryVariables>(CustomerRequestsDocument, options);
        }
export function useCustomerRequestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CustomerRequestsQuery, Operation.CustomerRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CustomerRequestsQuery, Operation.CustomerRequestsQueryVariables>(CustomerRequestsDocument, options);
        }
export type CustomerRequestsQueryHookResult = ReturnType<typeof useCustomerRequestsQuery>;
export type CustomerRequestsLazyQueryHookResult = ReturnType<typeof useCustomerRequestsLazyQuery>;
export type CustomerRequestsSuspenseQueryHookResult = ReturnType<typeof useCustomerRequestsSuspenseQuery>;
export type CustomerRequestsQueryResult = Apollo.QueryResult<Operation.CustomerRequestsQuery, Operation.CustomerRequestsQueryVariables>;
export const CustomerRequestDocument = gql`
    query CustomerRequest($id: ID!) {
  customerRequest(id: $id) {
    ...customerRequestDetailed
  }
}
    ${CustomerRequestDetailedFragmentDoc}`;

/**
 * __useCustomerRequestQuery__
 *
 * To run a query within a React component, call `useCustomerRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerRequestQuery(baseOptions: Apollo.QueryHookOptions<Operation.CustomerRequestQuery, Operation.CustomerRequestQueryVariables> & ({ variables: Operation.CustomerRequestQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CustomerRequestQuery, Operation.CustomerRequestQueryVariables>(CustomerRequestDocument, options);
      }
export function useCustomerRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CustomerRequestQuery, Operation.CustomerRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CustomerRequestQuery, Operation.CustomerRequestQueryVariables>(CustomerRequestDocument, options);
        }
export function useCustomerRequestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CustomerRequestQuery, Operation.CustomerRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CustomerRequestQuery, Operation.CustomerRequestQueryVariables>(CustomerRequestDocument, options);
        }
export type CustomerRequestQueryHookResult = ReturnType<typeof useCustomerRequestQuery>;
export type CustomerRequestLazyQueryHookResult = ReturnType<typeof useCustomerRequestLazyQuery>;
export type CustomerRequestSuspenseQueryHookResult = ReturnType<typeof useCustomerRequestSuspenseQuery>;
export type CustomerRequestQueryResult = Apollo.QueryResult<Operation.CustomerRequestQuery, Operation.CustomerRequestQueryVariables>;
export const CustomerRequestPublicPageDocument = gql`
    query CustomerRequestPublicPage($id: ID!) {
  customerRequest(id: $id) {
    ...publicCustomerRequest
  }
}
    ${PublicCustomerRequestFragmentDoc}`;

/**
 * __useCustomerRequestPublicPageQuery__
 *
 * To run a query within a React component, call `useCustomerRequestPublicPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerRequestPublicPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerRequestPublicPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerRequestPublicPageQuery(baseOptions: Apollo.QueryHookOptions<Operation.CustomerRequestPublicPageQuery, Operation.CustomerRequestPublicPageQueryVariables> & ({ variables: Operation.CustomerRequestPublicPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CustomerRequestPublicPageQuery, Operation.CustomerRequestPublicPageQueryVariables>(CustomerRequestPublicPageDocument, options);
      }
export function useCustomerRequestPublicPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CustomerRequestPublicPageQuery, Operation.CustomerRequestPublicPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CustomerRequestPublicPageQuery, Operation.CustomerRequestPublicPageQueryVariables>(CustomerRequestPublicPageDocument, options);
        }
export function useCustomerRequestPublicPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CustomerRequestPublicPageQuery, Operation.CustomerRequestPublicPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CustomerRequestPublicPageQuery, Operation.CustomerRequestPublicPageQueryVariables>(CustomerRequestPublicPageDocument, options);
        }
export type CustomerRequestPublicPageQueryHookResult = ReturnType<typeof useCustomerRequestPublicPageQuery>;
export type CustomerRequestPublicPageLazyQueryHookResult = ReturnType<typeof useCustomerRequestPublicPageLazyQuery>;
export type CustomerRequestPublicPageSuspenseQueryHookResult = ReturnType<typeof useCustomerRequestPublicPageSuspenseQuery>;
export type CustomerRequestPublicPageQueryResult = Apollo.QueryResult<Operation.CustomerRequestPublicPageQuery, Operation.CustomerRequestPublicPageQueryVariables>;
export const DentalClassificationsDocument = gql`
    query DentalClassifications {
  dentalClassifications {
    ...dentalClassificationTranslation
  }
}
    ${DentalClassificationTranslationFragmentDoc}`;

/**
 * __useDentalClassificationsQuery__
 *
 * To run a query within a React component, call `useDentalClassificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDentalClassificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDentalClassificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDentalClassificationsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DentalClassificationsQuery, Operation.DentalClassificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DentalClassificationsQuery, Operation.DentalClassificationsQueryVariables>(DentalClassificationsDocument, options);
      }
export function useDentalClassificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DentalClassificationsQuery, Operation.DentalClassificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DentalClassificationsQuery, Operation.DentalClassificationsQueryVariables>(DentalClassificationsDocument, options);
        }
export function useDentalClassificationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DentalClassificationsQuery, Operation.DentalClassificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DentalClassificationsQuery, Operation.DentalClassificationsQueryVariables>(DentalClassificationsDocument, options);
        }
export type DentalClassificationsQueryHookResult = ReturnType<typeof useDentalClassificationsQuery>;
export type DentalClassificationsLazyQueryHookResult = ReturnType<typeof useDentalClassificationsLazyQuery>;
export type DentalClassificationsSuspenseQueryHookResult = ReturnType<typeof useDentalClassificationsSuspenseQuery>;
export type DentalClassificationsQueryResult = Apollo.QueryResult<Operation.DentalClassificationsQuery, Operation.DentalClassificationsQueryVariables>;
export const DentalPricingsDocument = gql`
    query DentalPricings {
  dentalPricings {
    ...dentalPricingTranslation
  }
}
    ${DentalPricingTranslationFragmentDoc}`;

/**
 * __useDentalPricingsQuery__
 *
 * To run a query within a React component, call `useDentalPricingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDentalPricingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDentalPricingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDentalPricingsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DentalPricingsQuery, Operation.DentalPricingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DentalPricingsQuery, Operation.DentalPricingsQueryVariables>(DentalPricingsDocument, options);
      }
export function useDentalPricingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DentalPricingsQuery, Operation.DentalPricingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DentalPricingsQuery, Operation.DentalPricingsQueryVariables>(DentalPricingsDocument, options);
        }
export function useDentalPricingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DentalPricingsQuery, Operation.DentalPricingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DentalPricingsQuery, Operation.DentalPricingsQueryVariables>(DentalPricingsDocument, options);
        }
export type DentalPricingsQueryHookResult = ReturnType<typeof useDentalPricingsQuery>;
export type DentalPricingsLazyQueryHookResult = ReturnType<typeof useDentalPricingsLazyQuery>;
export type DentalPricingsSuspenseQueryHookResult = ReturnType<typeof useDentalPricingsSuspenseQuery>;
export type DentalPricingsQueryResult = Apollo.QueryResult<Operation.DentalPricingsQuery, Operation.DentalPricingsQueryVariables>;
export const DentalJawsDocument = gql`
    query DentalJaws {
  dentalJaws {
    ...dentalJawTranslation
  }
}
    ${DentalJawTranslationFragmentDoc}`;

/**
 * __useDentalJawsQuery__
 *
 * To run a query within a React component, call `useDentalJawsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDentalJawsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDentalJawsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDentalJawsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DentalJawsQuery, Operation.DentalJawsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DentalJawsQuery, Operation.DentalJawsQueryVariables>(DentalJawsDocument, options);
      }
export function useDentalJawsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DentalJawsQuery, Operation.DentalJawsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DentalJawsQuery, Operation.DentalJawsQueryVariables>(DentalJawsDocument, options);
        }
export function useDentalJawsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DentalJawsQuery, Operation.DentalJawsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DentalJawsQuery, Operation.DentalJawsQueryVariables>(DentalJawsDocument, options);
        }
export type DentalJawsQueryHookResult = ReturnType<typeof useDentalJawsQuery>;
export type DentalJawsLazyQueryHookResult = ReturnType<typeof useDentalJawsLazyQuery>;
export type DentalJawsSuspenseQueryHookResult = ReturnType<typeof useDentalJawsSuspenseQuery>;
export type DentalJawsQueryResult = Apollo.QueryResult<Operation.DentalJawsQuery, Operation.DentalJawsQueryVariables>;
export const DentalTreatmentsDocument = gql`
    query DentalTreatments {
  dentalTreatments {
    ...dentalTreatmentTranslation
  }
}
    ${DentalTreatmentTranslationFragmentDoc}`;

/**
 * __useDentalTreatmentsQuery__
 *
 * To run a query within a React component, call `useDentalTreatmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDentalTreatmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDentalTreatmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDentalTreatmentsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DentalTreatmentsQuery, Operation.DentalTreatmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DentalTreatmentsQuery, Operation.DentalTreatmentsQueryVariables>(DentalTreatmentsDocument, options);
      }
export function useDentalTreatmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DentalTreatmentsQuery, Operation.DentalTreatmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DentalTreatmentsQuery, Operation.DentalTreatmentsQueryVariables>(DentalTreatmentsDocument, options);
        }
export function useDentalTreatmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DentalTreatmentsQuery, Operation.DentalTreatmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DentalTreatmentsQuery, Operation.DentalTreatmentsQueryVariables>(DentalTreatmentsDocument, options);
        }
export type DentalTreatmentsQueryHookResult = ReturnType<typeof useDentalTreatmentsQuery>;
export type DentalTreatmentsLazyQueryHookResult = ReturnType<typeof useDentalTreatmentsLazyQuery>;
export type DentalTreatmentsSuspenseQueryHookResult = ReturnType<typeof useDentalTreatmentsSuspenseQuery>;
export type DentalTreatmentsQueryResult = Apollo.QueryResult<Operation.DentalTreatmentsQuery, Operation.DentalTreatmentsQueryVariables>;
export const DentalTeethDocument = gql`
    query DentalTeeth {
  dentalTeeth {
    ...dentalTeethTranslation
  }
}
    ${DentalTeethTranslationFragmentDoc}`;

/**
 * __useDentalTeethQuery__
 *
 * To run a query within a React component, call `useDentalTeethQuery` and pass it any options that fit your needs.
 * When your component renders, `useDentalTeethQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDentalTeethQuery({
 *   variables: {
 *   },
 * });
 */
export function useDentalTeethQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DentalTeethQuery, Operation.DentalTeethQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DentalTeethQuery, Operation.DentalTeethQueryVariables>(DentalTeethDocument, options);
      }
export function useDentalTeethLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DentalTeethQuery, Operation.DentalTeethQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DentalTeethQuery, Operation.DentalTeethQueryVariables>(DentalTeethDocument, options);
        }
export function useDentalTeethSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DentalTeethQuery, Operation.DentalTeethQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DentalTeethQuery, Operation.DentalTeethQueryVariables>(DentalTeethDocument, options);
        }
export type DentalTeethQueryHookResult = ReturnType<typeof useDentalTeethQuery>;
export type DentalTeethLazyQueryHookResult = ReturnType<typeof useDentalTeethLazyQuery>;
export type DentalTeethSuspenseQueryHookResult = ReturnType<typeof useDentalTeethSuspenseQuery>;
export type DentalTeethQueryResult = Apollo.QueryResult<Operation.DentalTeethQuery, Operation.DentalTeethQueryVariables>;
export const PerioExamsDocument = gql`
    query PerioExams($upcId: ID, $date: ISO8601Date, $upc: [ID!]) {
  dentalPerioExams(byDate: $date, byPatient: $upcId, upc: $upc) {
    edges {
      node {
        ...dentalPerioExamMinimal
      }
    }
  }
}
    ${DentalPerioExamMinimalFragmentDoc}`;

/**
 * __usePerioExamsQuery__
 *
 * To run a query within a React component, call `usePerioExamsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerioExamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerioExamsQuery({
 *   variables: {
 *      upcId: // value for 'upcId'
 *      date: // value for 'date'
 *      upc: // value for 'upc'
 *   },
 * });
 */
export function usePerioExamsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PerioExamsQuery, Operation.PerioExamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PerioExamsQuery, Operation.PerioExamsQueryVariables>(PerioExamsDocument, options);
      }
export function usePerioExamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PerioExamsQuery, Operation.PerioExamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PerioExamsQuery, Operation.PerioExamsQueryVariables>(PerioExamsDocument, options);
        }
export function usePerioExamsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PerioExamsQuery, Operation.PerioExamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PerioExamsQuery, Operation.PerioExamsQueryVariables>(PerioExamsDocument, options);
        }
export type PerioExamsQueryHookResult = ReturnType<typeof usePerioExamsQuery>;
export type PerioExamsLazyQueryHookResult = ReturnType<typeof usePerioExamsLazyQuery>;
export type PerioExamsSuspenseQueryHookResult = ReturnType<typeof usePerioExamsSuspenseQuery>;
export type PerioExamsQueryResult = Apollo.QueryResult<Operation.PerioExamsQuery, Operation.PerioExamsQueryVariables>;
export const DirectoryDocument = gql`
    query Directory($patient: ID, $contentType: DocumentContentType, $directory: ID, $first: Int, $after: String, $contentFilter: ContentFilter) {
  directory(
    patient: $patient
    contentType: $contentType
    directory: $directory
    contentFilter: $contentFilter
  ) {
    ...paginatedDirectory
  }
}
    ${PaginatedDirectoryFragmentDoc}`;

/**
 * __useDirectoryQuery__
 *
 * To run a query within a React component, call `useDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectoryQuery({
 *   variables: {
 *      patient: // value for 'patient'
 *      contentType: // value for 'contentType'
 *      directory: // value for 'directory'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      contentFilter: // value for 'contentFilter'
 *   },
 * });
 */
export function useDirectoryQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DirectoryQuery, Operation.DirectoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DirectoryQuery, Operation.DirectoryQueryVariables>(DirectoryDocument, options);
      }
export function useDirectoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DirectoryQuery, Operation.DirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DirectoryQuery, Operation.DirectoryQueryVariables>(DirectoryDocument, options);
        }
export function useDirectorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DirectoryQuery, Operation.DirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DirectoryQuery, Operation.DirectoryQueryVariables>(DirectoryDocument, options);
        }
export type DirectoryQueryHookResult = ReturnType<typeof useDirectoryQuery>;
export type DirectoryLazyQueryHookResult = ReturnType<typeof useDirectoryLazyQuery>;
export type DirectorySuspenseQueryHookResult = ReturnType<typeof useDirectorySuspenseQuery>;
export type DirectoryQueryResult = Apollo.QueryResult<Operation.DirectoryQuery, Operation.DirectoryQueryVariables>;
export const DirectoryInfoDocument = gql`
    query DirectoryInfo($patient: ID, $contentType: DocumentContentType, $directory: ID) {
  directory(patient: $patient, contentType: $contentType, directory: $directory) {
    ...directoryInfo
  }
}
    ${DirectoryInfoFragmentDoc}`;

/**
 * __useDirectoryInfoQuery__
 *
 * To run a query within a React component, call `useDirectoryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectoryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectoryInfoQuery({
 *   variables: {
 *      patient: // value for 'patient'
 *      contentType: // value for 'contentType'
 *      directory: // value for 'directory'
 *   },
 * });
 */
export function useDirectoryInfoQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DirectoryInfoQuery, Operation.DirectoryInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DirectoryInfoQuery, Operation.DirectoryInfoQueryVariables>(DirectoryInfoDocument, options);
      }
export function useDirectoryInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DirectoryInfoQuery, Operation.DirectoryInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DirectoryInfoQuery, Operation.DirectoryInfoQueryVariables>(DirectoryInfoDocument, options);
        }
export function useDirectoryInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DirectoryInfoQuery, Operation.DirectoryInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DirectoryInfoQuery, Operation.DirectoryInfoQueryVariables>(DirectoryInfoDocument, options);
        }
export type DirectoryInfoQueryHookResult = ReturnType<typeof useDirectoryInfoQuery>;
export type DirectoryInfoLazyQueryHookResult = ReturnType<typeof useDirectoryInfoLazyQuery>;
export type DirectoryInfoSuspenseQueryHookResult = ReturnType<typeof useDirectoryInfoSuspenseQuery>;
export type DirectoryInfoQueryResult = Apollo.QueryResult<Operation.DirectoryInfoQuery, Operation.DirectoryInfoQueryVariables>;
export const DirectoryPathInfoDocument = gql`
    query DirectoryPathInfo($patient: ID, $contentType: DocumentContentType, $directory: ID) {
  directory(patient: $patient, contentType: $contentType, directory: $directory) {
    ...directoryPathInfo
  }
}
    ${DirectoryPathInfoFragmentDoc}`;

/**
 * __useDirectoryPathInfoQuery__
 *
 * To run a query within a React component, call `useDirectoryPathInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectoryPathInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectoryPathInfoQuery({
 *   variables: {
 *      patient: // value for 'patient'
 *      contentType: // value for 'contentType'
 *      directory: // value for 'directory'
 *   },
 * });
 */
export function useDirectoryPathInfoQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DirectoryPathInfoQuery, Operation.DirectoryPathInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DirectoryPathInfoQuery, Operation.DirectoryPathInfoQueryVariables>(DirectoryPathInfoDocument, options);
      }
export function useDirectoryPathInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DirectoryPathInfoQuery, Operation.DirectoryPathInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DirectoryPathInfoQuery, Operation.DirectoryPathInfoQueryVariables>(DirectoryPathInfoDocument, options);
        }
export function useDirectoryPathInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DirectoryPathInfoQuery, Operation.DirectoryPathInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DirectoryPathInfoQuery, Operation.DirectoryPathInfoQueryVariables>(DirectoryPathInfoDocument, options);
        }
export type DirectoryPathInfoQueryHookResult = ReturnType<typeof useDirectoryPathInfoQuery>;
export type DirectoryPathInfoLazyQueryHookResult = ReturnType<typeof useDirectoryPathInfoLazyQuery>;
export type DirectoryPathInfoSuspenseQueryHookResult = ReturnType<typeof useDirectoryPathInfoSuspenseQuery>;
export type DirectoryPathInfoQueryResult = Apollo.QueryResult<Operation.DirectoryPathInfoQuery, Operation.DirectoryPathInfoQueryVariables>;
export const DirectoryContentBadgeCountDocument = gql`
    query DirectoryContentBadgeCount($patient: ID, $contentType: DocumentContentType, $directory: ID, $contentFilter: ContentFilter) {
  directory(
    patient: $patient
    contentType: $contentType
    directory: $directory
    contentFilter: $contentFilter
  ) {
    id
    guid
    fileCount
  }
}
    `;

/**
 * __useDirectoryContentBadgeCountQuery__
 *
 * To run a query within a React component, call `useDirectoryContentBadgeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectoryContentBadgeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectoryContentBadgeCountQuery({
 *   variables: {
 *      patient: // value for 'patient'
 *      contentType: // value for 'contentType'
 *      directory: // value for 'directory'
 *      contentFilter: // value for 'contentFilter'
 *   },
 * });
 */
export function useDirectoryContentBadgeCountQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DirectoryContentBadgeCountQuery, Operation.DirectoryContentBadgeCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DirectoryContentBadgeCountQuery, Operation.DirectoryContentBadgeCountQueryVariables>(DirectoryContentBadgeCountDocument, options);
      }
export function useDirectoryContentBadgeCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DirectoryContentBadgeCountQuery, Operation.DirectoryContentBadgeCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DirectoryContentBadgeCountQuery, Operation.DirectoryContentBadgeCountQueryVariables>(DirectoryContentBadgeCountDocument, options);
        }
export function useDirectoryContentBadgeCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DirectoryContentBadgeCountQuery, Operation.DirectoryContentBadgeCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DirectoryContentBadgeCountQuery, Operation.DirectoryContentBadgeCountQueryVariables>(DirectoryContentBadgeCountDocument, options);
        }
export type DirectoryContentBadgeCountQueryHookResult = ReturnType<typeof useDirectoryContentBadgeCountQuery>;
export type DirectoryContentBadgeCountLazyQueryHookResult = ReturnType<typeof useDirectoryContentBadgeCountLazyQuery>;
export type DirectoryContentBadgeCountSuspenseQueryHookResult = ReturnType<typeof useDirectoryContentBadgeCountSuspenseQuery>;
export type DirectoryContentBadgeCountQueryResult = Apollo.QueryResult<Operation.DirectoryContentBadgeCountQuery, Operation.DirectoryContentBadgeCountQueryVariables>;
export const RichTextsDocument = gql`
    query RichTexts($params: RichTextSearchParameters!) {
  richTexts(params: $params) {
    pageInfo {
      ...pageInfo
    }
    edges {
      node {
        ...richTextMinimal
        badges {
          ...badgeLinkMinimal
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${RichTextMinimalFragmentDoc}
${BadgeLinkMinimalFragmentDoc}`;

/**
 * __useRichTextsQuery__
 *
 * To run a query within a React component, call `useRichTextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRichTextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRichTextsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRichTextsQuery(baseOptions: Apollo.QueryHookOptions<Operation.RichTextsQuery, Operation.RichTextsQueryVariables> & ({ variables: Operation.RichTextsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RichTextsQuery, Operation.RichTextsQueryVariables>(RichTextsDocument, options);
      }
export function useRichTextsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RichTextsQuery, Operation.RichTextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RichTextsQuery, Operation.RichTextsQueryVariables>(RichTextsDocument, options);
        }
export function useRichTextsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RichTextsQuery, Operation.RichTextsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RichTextsQuery, Operation.RichTextsQueryVariables>(RichTextsDocument, options);
        }
export type RichTextsQueryHookResult = ReturnType<typeof useRichTextsQuery>;
export type RichTextsLazyQueryHookResult = ReturnType<typeof useRichTextsLazyQuery>;
export type RichTextsSuspenseQueryHookResult = ReturnType<typeof useRichTextsSuspenseQuery>;
export type RichTextsQueryResult = Apollo.QueryResult<Operation.RichTextsQuery, Operation.RichTextsQueryVariables>;
export const RichTextDocument = gql`
    query RichText($id: ID!) {
  richText(id: $id) {
    ...richTextEssentials
  }
}
    ${RichTextEssentialsFragmentDoc}`;

/**
 * __useRichTextQuery__
 *
 * To run a query within a React component, call `useRichTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useRichTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRichTextQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRichTextQuery(baseOptions: Apollo.QueryHookOptions<Operation.RichTextQuery, Operation.RichTextQueryVariables> & ({ variables: Operation.RichTextQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RichTextQuery, Operation.RichTextQueryVariables>(RichTextDocument, options);
      }
export function useRichTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RichTextQuery, Operation.RichTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RichTextQuery, Operation.RichTextQueryVariables>(RichTextDocument, options);
        }
export function useRichTextSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RichTextQuery, Operation.RichTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RichTextQuery, Operation.RichTextQueryVariables>(RichTextDocument, options);
        }
export type RichTextQueryHookResult = ReturnType<typeof useRichTextQuery>;
export type RichTextLazyQueryHookResult = ReturnType<typeof useRichTextLazyQuery>;
export type RichTextSuspenseQueryHookResult = ReturnType<typeof useRichTextSuspenseQuery>;
export type RichTextQueryResult = Apollo.QueryResult<Operation.RichTextQuery, Operation.RichTextQueryVariables>;
export const RichTextClassesDocument = gql`
    query RichTextClasses {
  richTextClasses {
    ...richTextClass
  }
}
    ${RichTextClassFragmentDoc}`;

/**
 * __useRichTextClassesQuery__
 *
 * To run a query within a React component, call `useRichTextClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRichTextClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRichTextClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRichTextClassesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.RichTextClassesQuery, Operation.RichTextClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RichTextClassesQuery, Operation.RichTextClassesQueryVariables>(RichTextClassesDocument, options);
      }
export function useRichTextClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RichTextClassesQuery, Operation.RichTextClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RichTextClassesQuery, Operation.RichTextClassesQueryVariables>(RichTextClassesDocument, options);
        }
export function useRichTextClassesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RichTextClassesQuery, Operation.RichTextClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RichTextClassesQuery, Operation.RichTextClassesQueryVariables>(RichTextClassesDocument, options);
        }
export type RichTextClassesQueryHookResult = ReturnType<typeof useRichTextClassesQuery>;
export type RichTextClassesLazyQueryHookResult = ReturnType<typeof useRichTextClassesLazyQuery>;
export type RichTextClassesSuspenseQueryHookResult = ReturnType<typeof useRichTextClassesSuspenseQuery>;
export type RichTextClassesQueryResult = Apollo.QueryResult<Operation.RichTextClassesQuery, Operation.RichTextClassesQueryVariables>;
export const MergeFieldsDocument = gql`
    query MergeFields($uuid: ID!) {
  mergeFields(uuid: $uuid) {
    name
    value
  }
}
    `;

/**
 * __useMergeFieldsQuery__
 *
 * To run a query within a React component, call `useMergeFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMergeFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMergeFieldsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMergeFieldsQuery(baseOptions: Apollo.QueryHookOptions<Operation.MergeFieldsQuery, Operation.MergeFieldsQueryVariables> & ({ variables: Operation.MergeFieldsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.MergeFieldsQuery, Operation.MergeFieldsQueryVariables>(MergeFieldsDocument, options);
      }
export function useMergeFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.MergeFieldsQuery, Operation.MergeFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.MergeFieldsQuery, Operation.MergeFieldsQueryVariables>(MergeFieldsDocument, options);
        }
export function useMergeFieldsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.MergeFieldsQuery, Operation.MergeFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.MergeFieldsQuery, Operation.MergeFieldsQueryVariables>(MergeFieldsDocument, options);
        }
export type MergeFieldsQueryHookResult = ReturnType<typeof useMergeFieldsQuery>;
export type MergeFieldsLazyQueryHookResult = ReturnType<typeof useMergeFieldsLazyQuery>;
export type MergeFieldsSuspenseQueryHookResult = ReturnType<typeof useMergeFieldsSuspenseQuery>;
export type MergeFieldsQueryResult = Apollo.QueryResult<Operation.MergeFieldsQuery, Operation.MergeFieldsQueryVariables>;
export const CountriesDocument = gql`
    query Countries {
  countries {
    name
    value
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CountriesQuery, Operation.CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CountriesQuery, Operation.CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CountriesQuery, Operation.CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CountriesQuery, Operation.CountriesQueryVariables>(CountriesDocument, options);
        }
export function useCountriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CountriesQuery, Operation.CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CountriesQuery, Operation.CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesSuspenseQueryHookResult = ReturnType<typeof useCountriesSuspenseQuery>;
export type CountriesQueryResult = Apollo.QueryResult<Operation.CountriesQuery, Operation.CountriesQueryVariables>;
export const GendersDocument = gql`
    query Genders {
  genders
}
    `;

/**
 * __useGendersQuery__
 *
 * To run a query within a React component, call `useGendersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGendersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGendersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGendersQuery(baseOptions?: Apollo.QueryHookOptions<Operation.GendersQuery, Operation.GendersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.GendersQuery, Operation.GendersQueryVariables>(GendersDocument, options);
      }
export function useGendersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.GendersQuery, Operation.GendersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.GendersQuery, Operation.GendersQueryVariables>(GendersDocument, options);
        }
export function useGendersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.GendersQuery, Operation.GendersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.GendersQuery, Operation.GendersQueryVariables>(GendersDocument, options);
        }
export type GendersQueryHookResult = ReturnType<typeof useGendersQuery>;
export type GendersLazyQueryHookResult = ReturnType<typeof useGendersLazyQuery>;
export type GendersSuspenseQueryHookResult = ReturnType<typeof useGendersSuspenseQuery>;
export type GendersQueryResult = Apollo.QueryResult<Operation.GendersQuery, Operation.GendersQueryVariables>;
export const IconsDocument = gql`
    query Icons {
  icons {
    ...iconTranslation
  }
}
    ${IconTranslationFragmentDoc}`;

/**
 * __useIconsQuery__
 *
 * To run a query within a React component, call `useIconsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIconsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIconsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIconsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.IconsQuery, Operation.IconsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.IconsQuery, Operation.IconsQueryVariables>(IconsDocument, options);
      }
export function useIconsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.IconsQuery, Operation.IconsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.IconsQuery, Operation.IconsQueryVariables>(IconsDocument, options);
        }
export function useIconsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.IconsQuery, Operation.IconsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.IconsQuery, Operation.IconsQueryVariables>(IconsDocument, options);
        }
export type IconsQueryHookResult = ReturnType<typeof useIconsQuery>;
export type IconsLazyQueryHookResult = ReturnType<typeof useIconsLazyQuery>;
export type IconsSuspenseQueryHookResult = ReturnType<typeof useIconsSuspenseQuery>;
export type IconsQueryResult = Apollo.QueryResult<Operation.IconsQuery, Operation.IconsQueryVariables>;
export const IntercomConfigurationDocument = gql`
    query IntercomConfiguration {
  intercomConfiguration {
    ...IntercomConfigurationDetailed
  }
}
    ${IntercomConfigurationDetailedFragmentDoc}`;

/**
 * __useIntercomConfigurationQuery__
 *
 * To run a query within a React component, call `useIntercomConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntercomConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntercomConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntercomConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<Operation.IntercomConfigurationQuery, Operation.IntercomConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.IntercomConfigurationQuery, Operation.IntercomConfigurationQueryVariables>(IntercomConfigurationDocument, options);
      }
export function useIntercomConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.IntercomConfigurationQuery, Operation.IntercomConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.IntercomConfigurationQuery, Operation.IntercomConfigurationQueryVariables>(IntercomConfigurationDocument, options);
        }
export function useIntercomConfigurationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.IntercomConfigurationQuery, Operation.IntercomConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.IntercomConfigurationQuery, Operation.IntercomConfigurationQueryVariables>(IntercomConfigurationDocument, options);
        }
export type IntercomConfigurationQueryHookResult = ReturnType<typeof useIntercomConfigurationQuery>;
export type IntercomConfigurationLazyQueryHookResult = ReturnType<typeof useIntercomConfigurationLazyQuery>;
export type IntercomConfigurationSuspenseQueryHookResult = ReturnType<typeof useIntercomConfigurationSuspenseQuery>;
export type IntercomConfigurationQueryResult = Apollo.QueryResult<Operation.IntercomConfigurationQuery, Operation.IntercomConfigurationQueryVariables>;
export const LocationsDocument = gql`
    query Locations($search: String!, $country: String) {
  locations(search: $search, country: $country) {
    ...locationSearch
  }
}
    ${LocationSearchFragmentDoc}`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useLocationsQuery(baseOptions: Apollo.QueryHookOptions<Operation.LocationsQuery, Operation.LocationsQueryVariables> & ({ variables: Operation.LocationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.LocationsQuery, Operation.LocationsQueryVariables>(LocationsDocument, options);
      }
export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.LocationsQuery, Operation.LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.LocationsQuery, Operation.LocationsQueryVariables>(LocationsDocument, options);
        }
export function useLocationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.LocationsQuery, Operation.LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.LocationsQuery, Operation.LocationsQueryVariables>(LocationsDocument, options);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsSuspenseQueryHookResult = ReturnType<typeof useLocationsSuspenseQuery>;
export type LocationsQueryResult = Apollo.QueryResult<Operation.LocationsQuery, Operation.LocationsQueryVariables>;
export const LocationDocument = gql`
    query Location($guid: ID, $placeId: ID) {
  location(guid: $guid, placeId: $placeId) {
    ...locationEssentials
  }
}
    ${LocationEssentialsFragmentDoc}`;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useLocationQuery(baseOptions?: Apollo.QueryHookOptions<Operation.LocationQuery, Operation.LocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.LocationQuery, Operation.LocationQueryVariables>(LocationDocument, options);
      }
export function useLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.LocationQuery, Operation.LocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.LocationQuery, Operation.LocationQueryVariables>(LocationDocument, options);
        }
export function useLocationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.LocationQuery, Operation.LocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.LocationQuery, Operation.LocationQueryVariables>(LocationDocument, options);
        }
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>;
export type LocationSuspenseQueryHookResult = ReturnType<typeof useLocationSuspenseQuery>;
export type LocationQueryResult = Apollo.QueryResult<Operation.LocationQuery, Operation.LocationQueryVariables>;
export const VisibilityDocument = gql`
    query Visibility {
  visibility {
    ...visibility
  }
}
    ${VisibilityFragmentDoc}`;

/**
 * __useVisibilityQuery__
 *
 * To run a query within a React component, call `useVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisibilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useVisibilityQuery(baseOptions?: Apollo.QueryHookOptions<Operation.VisibilityQuery, Operation.VisibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.VisibilityQuery, Operation.VisibilityQueryVariables>(VisibilityDocument, options);
      }
export function useVisibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.VisibilityQuery, Operation.VisibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.VisibilityQuery, Operation.VisibilityQueryVariables>(VisibilityDocument, options);
        }
export function useVisibilitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.VisibilityQuery, Operation.VisibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.VisibilityQuery, Operation.VisibilityQueryVariables>(VisibilityDocument, options);
        }
export type VisibilityQueryHookResult = ReturnType<typeof useVisibilityQuery>;
export type VisibilityLazyQueryHookResult = ReturnType<typeof useVisibilityLazyQuery>;
export type VisibilitySuspenseQueryHookResult = ReturnType<typeof useVisibilitySuspenseQuery>;
export type VisibilityQueryResult = Apollo.QueryResult<Operation.VisibilityQuery, Operation.VisibilityQueryVariables>;
export const ContractIdentifiersDocument = gql`
    query ContractIdentifiers($guids: [String!], $byProviderId: String, $byPracticeGuid: ID, $byUpcId: ID, $byUpcSlug: String) {
  contractIdentifiers(
    guids: $guids
    byUpcId: $byUpcId
    byProviderId: $byProviderId
    byPracticeGuid: $byPracticeGuid
    byUpcSlug: $byUpcSlug
  ) {
    edges {
      cursor
      node {
        ...contractIdentifierDetailed
      }
    }
  }
}
    ${ContractIdentifierDetailedFragmentDoc}`;

/**
 * __useContractIdentifiersQuery__
 *
 * To run a query within a React component, call `useContractIdentifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractIdentifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractIdentifiersQuery({
 *   variables: {
 *      guids: // value for 'guids'
 *      byProviderId: // value for 'byProviderId'
 *      byPracticeGuid: // value for 'byPracticeGuid'
 *      byUpcId: // value for 'byUpcId'
 *      byUpcSlug: // value for 'byUpcSlug'
 *   },
 * });
 */
export function useContractIdentifiersQuery(baseOptions?: Apollo.QueryHookOptions<Operation.ContractIdentifiersQuery, Operation.ContractIdentifiersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ContractIdentifiersQuery, Operation.ContractIdentifiersQueryVariables>(ContractIdentifiersDocument, options);
      }
export function useContractIdentifiersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ContractIdentifiersQuery, Operation.ContractIdentifiersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ContractIdentifiersQuery, Operation.ContractIdentifiersQueryVariables>(ContractIdentifiersDocument, options);
        }
export function useContractIdentifiersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ContractIdentifiersQuery, Operation.ContractIdentifiersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ContractIdentifiersQuery, Operation.ContractIdentifiersQueryVariables>(ContractIdentifiersDocument, options);
        }
export type ContractIdentifiersQueryHookResult = ReturnType<typeof useContractIdentifiersQuery>;
export type ContractIdentifiersLazyQueryHookResult = ReturnType<typeof useContractIdentifiersLazyQuery>;
export type ContractIdentifiersSuspenseQueryHookResult = ReturnType<typeof useContractIdentifiersSuspenseQuery>;
export type ContractIdentifiersQueryResult = Apollo.QueryResult<Operation.ContractIdentifiersQuery, Operation.ContractIdentifiersQueryVariables>;
export const HealthFundIdentifiersDocument = gql`
    query HealthFundIdentifiers($byUpcGuids: [ID!], $isPublic: Boolean) {
  healthFundIdentifiers(byUpcGuids: $byUpcGuids, isPublic: $isPublic) {
    edges {
      cursor
      node {
        ...upcHealthFundIdentifier
      }
    }
  }
}
    ${UpcHealthFundIdentifierFragmentDoc}`;

/**
 * __useHealthFundIdentifiersQuery__
 *
 * To run a query within a React component, call `useHealthFundIdentifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthFundIdentifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthFundIdentifiersQuery({
 *   variables: {
 *      byUpcGuids: // value for 'byUpcGuids'
 *      isPublic: // value for 'isPublic'
 *   },
 * });
 */
export function useHealthFundIdentifiersQuery(baseOptions?: Apollo.QueryHookOptions<Operation.HealthFundIdentifiersQuery, Operation.HealthFundIdentifiersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.HealthFundIdentifiersQuery, Operation.HealthFundIdentifiersQueryVariables>(HealthFundIdentifiersDocument, options);
      }
export function useHealthFundIdentifiersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.HealthFundIdentifiersQuery, Operation.HealthFundIdentifiersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.HealthFundIdentifiersQuery, Operation.HealthFundIdentifiersQueryVariables>(HealthFundIdentifiersDocument, options);
        }
export function useHealthFundIdentifiersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.HealthFundIdentifiersQuery, Operation.HealthFundIdentifiersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.HealthFundIdentifiersQuery, Operation.HealthFundIdentifiersQueryVariables>(HealthFundIdentifiersDocument, options);
        }
export type HealthFundIdentifiersQueryHookResult = ReturnType<typeof useHealthFundIdentifiersQuery>;
export type HealthFundIdentifiersLazyQueryHookResult = ReturnType<typeof useHealthFundIdentifiersLazyQuery>;
export type HealthFundIdentifiersSuspenseQueryHookResult = ReturnType<typeof useHealthFundIdentifiersSuspenseQuery>;
export type HealthFundIdentifiersQueryResult = Apollo.QueryResult<Operation.HealthFundIdentifiersQuery, Operation.HealthFundIdentifiersQueryVariables>;
export const HealthFundsRequestsDocument = gql`
    query HealthFundsRequests($requestable: String, $patient: ID, $first: Int, $after: String) {
  healthFundsRequests(
    requestable: $requestable
    patient: $patient
    first: $first
    after: $after
  ) {
    edges {
      cursor
      node {
        ...healthFundsRequestExhaustive
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    ${HealthFundsRequestExhaustiveFragmentDoc}`;

/**
 * __useHealthFundsRequestsQuery__
 *
 * To run a query within a React component, call `useHealthFundsRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthFundsRequestsQuery({
 *   variables: {
 *      requestable: // value for 'requestable'
 *      patient: // value for 'patient'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useHealthFundsRequestsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.HealthFundsRequestsQuery, Operation.HealthFundsRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.HealthFundsRequestsQuery, Operation.HealthFundsRequestsQueryVariables>(HealthFundsRequestsDocument, options);
      }
export function useHealthFundsRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.HealthFundsRequestsQuery, Operation.HealthFundsRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.HealthFundsRequestsQuery, Operation.HealthFundsRequestsQueryVariables>(HealthFundsRequestsDocument, options);
        }
export function useHealthFundsRequestsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.HealthFundsRequestsQuery, Operation.HealthFundsRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.HealthFundsRequestsQuery, Operation.HealthFundsRequestsQueryVariables>(HealthFundsRequestsDocument, options);
        }
export type HealthFundsRequestsQueryHookResult = ReturnType<typeof useHealthFundsRequestsQuery>;
export type HealthFundsRequestsLazyQueryHookResult = ReturnType<typeof useHealthFundsRequestsLazyQuery>;
export type HealthFundsRequestsSuspenseQueryHookResult = ReturnType<typeof useHealthFundsRequestsSuspenseQuery>;
export type HealthFundsRequestsQueryResult = Apollo.QueryResult<Operation.HealthFundsRequestsQuery, Operation.HealthFundsRequestsQueryVariables>;
export const HealthFundsRequestExhastiveDocument = gql`
    query HealthFundsRequestExhastive($id: ID!) {
  healthFundsRequest(id: $id) {
    ...healthFundsRequestExhaustive
  }
}
    ${HealthFundsRequestExhaustiveFragmentDoc}`;

/**
 * __useHealthFundsRequestExhastiveQuery__
 *
 * To run a query within a React component, call `useHealthFundsRequestExhastiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsRequestExhastiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthFundsRequestExhastiveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthFundsRequestExhastiveQuery(baseOptions: Apollo.QueryHookOptions<Operation.HealthFundsRequestExhastiveQuery, Operation.HealthFundsRequestExhastiveQueryVariables> & ({ variables: Operation.HealthFundsRequestExhastiveQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.HealthFundsRequestExhastiveQuery, Operation.HealthFundsRequestExhastiveQueryVariables>(HealthFundsRequestExhastiveDocument, options);
      }
export function useHealthFundsRequestExhastiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.HealthFundsRequestExhastiveQuery, Operation.HealthFundsRequestExhastiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.HealthFundsRequestExhastiveQuery, Operation.HealthFundsRequestExhastiveQueryVariables>(HealthFundsRequestExhastiveDocument, options);
        }
export function useHealthFundsRequestExhastiveSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.HealthFundsRequestExhastiveQuery, Operation.HealthFundsRequestExhastiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.HealthFundsRequestExhastiveQuery, Operation.HealthFundsRequestExhastiveQueryVariables>(HealthFundsRequestExhastiveDocument, options);
        }
export type HealthFundsRequestExhastiveQueryHookResult = ReturnType<typeof useHealthFundsRequestExhastiveQuery>;
export type HealthFundsRequestExhastiveLazyQueryHookResult = ReturnType<typeof useHealthFundsRequestExhastiveLazyQuery>;
export type HealthFundsRequestExhastiveSuspenseQueryHookResult = ReturnType<typeof useHealthFundsRequestExhastiveSuspenseQuery>;
export type HealthFundsRequestExhastiveQueryResult = Apollo.QueryResult<Operation.HealthFundsRequestExhastiveQuery, Operation.HealthFundsRequestExhastiveQueryVariables>;
export const HealthFundsRequestReceiptDocument = gql`
    query HealthFundsRequestReceipt($id: ID!) {
  healthFundsRequest(id: $id) {
    ...healthFundsRequestReceipt
  }
}
    ${HealthFundsRequestReceiptFragmentDoc}`;

/**
 * __useHealthFundsRequestReceiptQuery__
 *
 * To run a query within a React component, call `useHealthFundsRequestReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsRequestReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthFundsRequestReceiptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthFundsRequestReceiptQuery(baseOptions: Apollo.QueryHookOptions<Operation.HealthFundsRequestReceiptQuery, Operation.HealthFundsRequestReceiptQueryVariables> & ({ variables: Operation.HealthFundsRequestReceiptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.HealthFundsRequestReceiptQuery, Operation.HealthFundsRequestReceiptQueryVariables>(HealthFundsRequestReceiptDocument, options);
      }
export function useHealthFundsRequestReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.HealthFundsRequestReceiptQuery, Operation.HealthFundsRequestReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.HealthFundsRequestReceiptQuery, Operation.HealthFundsRequestReceiptQueryVariables>(HealthFundsRequestReceiptDocument, options);
        }
export function useHealthFundsRequestReceiptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.HealthFundsRequestReceiptQuery, Operation.HealthFundsRequestReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.HealthFundsRequestReceiptQuery, Operation.HealthFundsRequestReceiptQueryVariables>(HealthFundsRequestReceiptDocument, options);
        }
export type HealthFundsRequestReceiptQueryHookResult = ReturnType<typeof useHealthFundsRequestReceiptQuery>;
export type HealthFundsRequestReceiptLazyQueryHookResult = ReturnType<typeof useHealthFundsRequestReceiptLazyQuery>;
export type HealthFundsRequestReceiptSuspenseQueryHookResult = ReturnType<typeof useHealthFundsRequestReceiptSuspenseQuery>;
export type HealthFundsRequestReceiptQueryResult = Apollo.QueryResult<Operation.HealthFundsRequestReceiptQuery, Operation.HealthFundsRequestReceiptQueryVariables>;
export const HealthFundsRequestWithReprintPatientReceiptDocument = gql`
    query HealthFundsRequestWithReprintPatientReceipt($id: ID!) {
  healthFundsRequest(id: $id) {
    ...healthFundsRequestWithReprintPatientReceipt
  }
}
    ${HealthFundsRequestWithReprintPatientReceiptFragmentDoc}`;

/**
 * __useHealthFundsRequestWithReprintPatientReceiptQuery__
 *
 * To run a query within a React component, call `useHealthFundsRequestWithReprintPatientReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsRequestWithReprintPatientReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthFundsRequestWithReprintPatientReceiptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthFundsRequestWithReprintPatientReceiptQuery(baseOptions: Apollo.QueryHookOptions<Operation.HealthFundsRequestWithReprintPatientReceiptQuery, Operation.HealthFundsRequestWithReprintPatientReceiptQueryVariables> & ({ variables: Operation.HealthFundsRequestWithReprintPatientReceiptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.HealthFundsRequestWithReprintPatientReceiptQuery, Operation.HealthFundsRequestWithReprintPatientReceiptQueryVariables>(HealthFundsRequestWithReprintPatientReceiptDocument, options);
      }
export function useHealthFundsRequestWithReprintPatientReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.HealthFundsRequestWithReprintPatientReceiptQuery, Operation.HealthFundsRequestWithReprintPatientReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.HealthFundsRequestWithReprintPatientReceiptQuery, Operation.HealthFundsRequestWithReprintPatientReceiptQueryVariables>(HealthFundsRequestWithReprintPatientReceiptDocument, options);
        }
export function useHealthFundsRequestWithReprintPatientReceiptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.HealthFundsRequestWithReprintPatientReceiptQuery, Operation.HealthFundsRequestWithReprintPatientReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.HealthFundsRequestWithReprintPatientReceiptQuery, Operation.HealthFundsRequestWithReprintPatientReceiptQueryVariables>(HealthFundsRequestWithReprintPatientReceiptDocument, options);
        }
export type HealthFundsRequestWithReprintPatientReceiptQueryHookResult = ReturnType<typeof useHealthFundsRequestWithReprintPatientReceiptQuery>;
export type HealthFundsRequestWithReprintPatientReceiptLazyQueryHookResult = ReturnType<typeof useHealthFundsRequestWithReprintPatientReceiptLazyQuery>;
export type HealthFundsRequestWithReprintPatientReceiptSuspenseQueryHookResult = ReturnType<typeof useHealthFundsRequestWithReprintPatientReceiptSuspenseQuery>;
export type HealthFundsRequestWithReprintPatientReceiptQueryResult = Apollo.QueryResult<Operation.HealthFundsRequestWithReprintPatientReceiptQuery, Operation.HealthFundsRequestWithReprintPatientReceiptQueryVariables>;
export const HealthFundsRequestWithReprintProviderReceiptDocument = gql`
    query HealthFundsRequestWithReprintProviderReceipt($id: ID!) {
  healthFundsRequest(id: $id) {
    ...healthFundsRequestWithReprintProviderReceipt
  }
}
    ${HealthFundsRequestWithReprintProviderReceiptFragmentDoc}`;

/**
 * __useHealthFundsRequestWithReprintProviderReceiptQuery__
 *
 * To run a query within a React component, call `useHealthFundsRequestWithReprintProviderReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsRequestWithReprintProviderReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthFundsRequestWithReprintProviderReceiptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthFundsRequestWithReprintProviderReceiptQuery(baseOptions: Apollo.QueryHookOptions<Operation.HealthFundsRequestWithReprintProviderReceiptQuery, Operation.HealthFundsRequestWithReprintProviderReceiptQueryVariables> & ({ variables: Operation.HealthFundsRequestWithReprintProviderReceiptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.HealthFundsRequestWithReprintProviderReceiptQuery, Operation.HealthFundsRequestWithReprintProviderReceiptQueryVariables>(HealthFundsRequestWithReprintProviderReceiptDocument, options);
      }
export function useHealthFundsRequestWithReprintProviderReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.HealthFundsRequestWithReprintProviderReceiptQuery, Operation.HealthFundsRequestWithReprintProviderReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.HealthFundsRequestWithReprintProviderReceiptQuery, Operation.HealthFundsRequestWithReprintProviderReceiptQueryVariables>(HealthFundsRequestWithReprintProviderReceiptDocument, options);
        }
export function useHealthFundsRequestWithReprintProviderReceiptSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.HealthFundsRequestWithReprintProviderReceiptQuery, Operation.HealthFundsRequestWithReprintProviderReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.HealthFundsRequestWithReprintProviderReceiptQuery, Operation.HealthFundsRequestWithReprintProviderReceiptQueryVariables>(HealthFundsRequestWithReprintProviderReceiptDocument, options);
        }
export type HealthFundsRequestWithReprintProviderReceiptQueryHookResult = ReturnType<typeof useHealthFundsRequestWithReprintProviderReceiptQuery>;
export type HealthFundsRequestWithReprintProviderReceiptLazyQueryHookResult = ReturnType<typeof useHealthFundsRequestWithReprintProviderReceiptLazyQuery>;
export type HealthFundsRequestWithReprintProviderReceiptSuspenseQueryHookResult = ReturnType<typeof useHealthFundsRequestWithReprintProviderReceiptSuspenseQuery>;
export type HealthFundsRequestWithReprintProviderReceiptQueryResult = Apollo.QueryResult<Operation.HealthFundsRequestWithReprintProviderReceiptQuery, Operation.HealthFundsRequestWithReprintProviderReceiptQueryVariables>;
export const HealthFundsPlannerClaimItemsDocument = gql`
    query HealthFundsPlannerClaimItems($guids: [ID!]) {
  patientRecordItems(guids: $guids) {
    edges {
      cursor
      node {
        ...healthFundsPlannerClaimItem
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    ${HealthFundsPlannerClaimItemFragmentDoc}`;

/**
 * __useHealthFundsPlannerClaimItemsQuery__
 *
 * To run a query within a React component, call `useHealthFundsPlannerClaimItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsPlannerClaimItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthFundsPlannerClaimItemsQuery({
 *   variables: {
 *      guids: // value for 'guids'
 *   },
 * });
 */
export function useHealthFundsPlannerClaimItemsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.HealthFundsPlannerClaimItemsQuery, Operation.HealthFundsPlannerClaimItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.HealthFundsPlannerClaimItemsQuery, Operation.HealthFundsPlannerClaimItemsQueryVariables>(HealthFundsPlannerClaimItemsDocument, options);
      }
export function useHealthFundsPlannerClaimItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.HealthFundsPlannerClaimItemsQuery, Operation.HealthFundsPlannerClaimItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.HealthFundsPlannerClaimItemsQuery, Operation.HealthFundsPlannerClaimItemsQueryVariables>(HealthFundsPlannerClaimItemsDocument, options);
        }
export function useHealthFundsPlannerClaimItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.HealthFundsPlannerClaimItemsQuery, Operation.HealthFundsPlannerClaimItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.HealthFundsPlannerClaimItemsQuery, Operation.HealthFundsPlannerClaimItemsQueryVariables>(HealthFundsPlannerClaimItemsDocument, options);
        }
export type HealthFundsPlannerClaimItemsQueryHookResult = ReturnType<typeof useHealthFundsPlannerClaimItemsQuery>;
export type HealthFundsPlannerClaimItemsLazyQueryHookResult = ReturnType<typeof useHealthFundsPlannerClaimItemsLazyQuery>;
export type HealthFundsPlannerClaimItemsSuspenseQueryHookResult = ReturnType<typeof useHealthFundsPlannerClaimItemsSuspenseQuery>;
export type HealthFundsPlannerClaimItemsQueryResult = Apollo.QueryResult<Operation.HealthFundsPlannerClaimItemsQuery, Operation.HealthFundsPlannerClaimItemsQueryVariables>;
export const HealthFundsInvoiceClaimItemsDocument = gql`
    query HealthFundsInvoiceClaimItems($guids: [ID!]) {
  invoiceItems(guids: $guids) {
    edges {
      cursor
      node {
        ...healthFundsInvoiceClaimItem
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    ${HealthFundsInvoiceClaimItemFragmentDoc}`;

/**
 * __useHealthFundsInvoiceClaimItemsQuery__
 *
 * To run a query within a React component, call `useHealthFundsInvoiceClaimItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthFundsInvoiceClaimItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthFundsInvoiceClaimItemsQuery({
 *   variables: {
 *      guids: // value for 'guids'
 *   },
 * });
 */
export function useHealthFundsInvoiceClaimItemsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.HealthFundsInvoiceClaimItemsQuery, Operation.HealthFundsInvoiceClaimItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.HealthFundsInvoiceClaimItemsQuery, Operation.HealthFundsInvoiceClaimItemsQueryVariables>(HealthFundsInvoiceClaimItemsDocument, options);
      }
export function useHealthFundsInvoiceClaimItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.HealthFundsInvoiceClaimItemsQuery, Operation.HealthFundsInvoiceClaimItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.HealthFundsInvoiceClaimItemsQuery, Operation.HealthFundsInvoiceClaimItemsQueryVariables>(HealthFundsInvoiceClaimItemsDocument, options);
        }
export function useHealthFundsInvoiceClaimItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.HealthFundsInvoiceClaimItemsQuery, Operation.HealthFundsInvoiceClaimItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.HealthFundsInvoiceClaimItemsQuery, Operation.HealthFundsInvoiceClaimItemsQueryVariables>(HealthFundsInvoiceClaimItemsDocument, options);
        }
export type HealthFundsInvoiceClaimItemsQueryHookResult = ReturnType<typeof useHealthFundsInvoiceClaimItemsQuery>;
export type HealthFundsInvoiceClaimItemsLazyQueryHookResult = ReturnType<typeof useHealthFundsInvoiceClaimItemsLazyQuery>;
export type HealthFundsInvoiceClaimItemsSuspenseQueryHookResult = ReturnType<typeof useHealthFundsInvoiceClaimItemsSuspenseQuery>;
export type HealthFundsInvoiceClaimItemsQueryResult = Apollo.QueryResult<Operation.HealthFundsInvoiceClaimItemsQuery, Operation.HealthFundsInvoiceClaimItemsQueryVariables>;
export const PractitionerProviderIdsDocument = gql`
    query PractitionerProviderIds($userPracticeConnectionId: ID, $userPracticeConnectionSlug: String) {
  contractIdentifiers(
    byUpcId: $userPracticeConnectionId
    byUpcSlug: $userPracticeConnectionSlug
  ) {
    edges {
      cursor
      node {
        ...contractIdentifierDetailed
      }
    }
  }
}
    ${ContractIdentifierDetailedFragmentDoc}`;

/**
 * __usePractitionerProviderIdsQuery__
 *
 * To run a query within a React component, call `usePractitionerProviderIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePractitionerProviderIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePractitionerProviderIdsQuery({
 *   variables: {
 *      userPracticeConnectionId: // value for 'userPracticeConnectionId'
 *      userPracticeConnectionSlug: // value for 'userPracticeConnectionSlug'
 *   },
 * });
 */
export function usePractitionerProviderIdsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PractitionerProviderIdsQuery, Operation.PractitionerProviderIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PractitionerProviderIdsQuery, Operation.PractitionerProviderIdsQueryVariables>(PractitionerProviderIdsDocument, options);
      }
export function usePractitionerProviderIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PractitionerProviderIdsQuery, Operation.PractitionerProviderIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PractitionerProviderIdsQuery, Operation.PractitionerProviderIdsQueryVariables>(PractitionerProviderIdsDocument, options);
        }
export function usePractitionerProviderIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PractitionerProviderIdsQuery, Operation.PractitionerProviderIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PractitionerProviderIdsQuery, Operation.PractitionerProviderIdsQueryVariables>(PractitionerProviderIdsDocument, options);
        }
export type PractitionerProviderIdsQueryHookResult = ReturnType<typeof usePractitionerProviderIdsQuery>;
export type PractitionerProviderIdsLazyQueryHookResult = ReturnType<typeof usePractitionerProviderIdsLazyQuery>;
export type PractitionerProviderIdsSuspenseQueryHookResult = ReturnType<typeof usePractitionerProviderIdsSuspenseQuery>;
export type PractitionerProviderIdsQueryResult = Apollo.QueryResult<Operation.PractitionerProviderIdsQuery, Operation.PractitionerProviderIdsQueryVariables>;
export const PrivateHealthcareProviderOptionsDocument = gql`
    query PrivateHealthcareProviderOptions {
  privateHealthcareProviderOptions {
    label
    value
  }
}
    `;

/**
 * __usePrivateHealthcareProviderOptionsQuery__
 *
 * To run a query within a React component, call `usePrivateHealthcareProviderOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivateHealthcareProviderOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivateHealthcareProviderOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivateHealthcareProviderOptionsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PrivateHealthcareProviderOptionsQuery, Operation.PrivateHealthcareProviderOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PrivateHealthcareProviderOptionsQuery, Operation.PrivateHealthcareProviderOptionsQueryVariables>(PrivateHealthcareProviderOptionsDocument, options);
      }
export function usePrivateHealthcareProviderOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PrivateHealthcareProviderOptionsQuery, Operation.PrivateHealthcareProviderOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PrivateHealthcareProviderOptionsQuery, Operation.PrivateHealthcareProviderOptionsQueryVariables>(PrivateHealthcareProviderOptionsDocument, options);
        }
export function usePrivateHealthcareProviderOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PrivateHealthcareProviderOptionsQuery, Operation.PrivateHealthcareProviderOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PrivateHealthcareProviderOptionsQuery, Operation.PrivateHealthcareProviderOptionsQueryVariables>(PrivateHealthcareProviderOptionsDocument, options);
        }
export type PrivateHealthcareProviderOptionsQueryHookResult = ReturnType<typeof usePrivateHealthcareProviderOptionsQuery>;
export type PrivateHealthcareProviderOptionsLazyQueryHookResult = ReturnType<typeof usePrivateHealthcareProviderOptionsLazyQuery>;
export type PrivateHealthcareProviderOptionsSuspenseQueryHookResult = ReturnType<typeof usePrivateHealthcareProviderOptionsSuspenseQuery>;
export type PrivateHealthcareProviderOptionsQueryResult = Apollo.QueryResult<Operation.PrivateHealthcareProviderOptionsQuery, Operation.PrivateHealthcareProviderOptionsQueryVariables>;
export const PrivateHealthcareProvidersDocument = gql`
    query PrivateHealthcareProviders {
  privateHealthcareProviders
}
    `;

/**
 * __usePrivateHealthcareProvidersQuery__
 *
 * To run a query within a React component, call `usePrivateHealthcareProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivateHealthcareProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivateHealthcareProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivateHealthcareProvidersQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PrivateHealthcareProvidersQuery, Operation.PrivateHealthcareProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PrivateHealthcareProvidersQuery, Operation.PrivateHealthcareProvidersQueryVariables>(PrivateHealthcareProvidersDocument, options);
      }
export function usePrivateHealthcareProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PrivateHealthcareProvidersQuery, Operation.PrivateHealthcareProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PrivateHealthcareProvidersQuery, Operation.PrivateHealthcareProvidersQueryVariables>(PrivateHealthcareProvidersDocument, options);
        }
export function usePrivateHealthcareProvidersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PrivateHealthcareProvidersQuery, Operation.PrivateHealthcareProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PrivateHealthcareProvidersQuery, Operation.PrivateHealthcareProvidersQueryVariables>(PrivateHealthcareProvidersDocument, options);
        }
export type PrivateHealthcareProvidersQueryHookResult = ReturnType<typeof usePrivateHealthcareProvidersQuery>;
export type PrivateHealthcareProvidersLazyQueryHookResult = ReturnType<typeof usePrivateHealthcareProvidersLazyQuery>;
export type PrivateHealthcareProvidersSuspenseQueryHookResult = ReturnType<typeof usePrivateHealthcareProvidersSuspenseQuery>;
export type PrivateHealthcareProvidersQueryResult = Apollo.QueryResult<Operation.PrivateHealthcareProvidersQuery, Operation.PrivateHealthcareProvidersQueryVariables>;
export const AccountPracticesDocument = gql`
    query AccountPractices($subdomain: String!) {
  accountPractices(subdomain: $subdomain) {
    ...practiceSelectEssentials
  }
}
    ${PracticeSelectEssentialsFragmentDoc}`;

/**
 * __useAccountPracticesQuery__
 *
 * To run a query within a React component, call `useAccountPracticesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPracticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPracticesQuery({
 *   variables: {
 *      subdomain: // value for 'subdomain'
 *   },
 * });
 */
export function useAccountPracticesQuery(baseOptions: Apollo.QueryHookOptions<Operation.AccountPracticesQuery, Operation.AccountPracticesQueryVariables> & ({ variables: Operation.AccountPracticesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AccountPracticesQuery, Operation.AccountPracticesQueryVariables>(AccountPracticesDocument, options);
      }
export function useAccountPracticesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AccountPracticesQuery, Operation.AccountPracticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AccountPracticesQuery, Operation.AccountPracticesQueryVariables>(AccountPracticesDocument, options);
        }
export function useAccountPracticesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AccountPracticesQuery, Operation.AccountPracticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AccountPracticesQuery, Operation.AccountPracticesQueryVariables>(AccountPracticesDocument, options);
        }
export type AccountPracticesQueryHookResult = ReturnType<typeof useAccountPracticesQuery>;
export type AccountPracticesLazyQueryHookResult = ReturnType<typeof useAccountPracticesLazyQuery>;
export type AccountPracticesSuspenseQueryHookResult = ReturnType<typeof useAccountPracticesSuspenseQuery>;
export type AccountPracticesQueryResult = Apollo.QueryResult<Operation.AccountPracticesQuery, Operation.AccountPracticesQueryVariables>;
export const AccountsDocument = gql`
    query Accounts($first: Int, $after: String, $subdomain: String) {
  accounts(first: $first, after: $after, subdomain: $subdomain) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        ...accountEssentials
      }
    }
  }
}
    ${AccountEssentialsFragmentDoc}`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      subdomain: // value for 'subdomain'
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AccountsQuery, Operation.AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AccountsQuery, Operation.AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AccountsQuery, Operation.AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AccountsQuery, Operation.AccountsQueryVariables>(AccountsDocument, options);
        }
export function useAccountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AccountsQuery, Operation.AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AccountsQuery, Operation.AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsSuspenseQueryHookResult = ReturnType<typeof useAccountsSuspenseQuery>;
export type AccountsQueryResult = Apollo.QueryResult<Operation.AccountsQuery, Operation.AccountsQueryVariables>;
export const AdminPracticeLocationsDocument = gql`
    query adminPracticeLocations($subdomain: String!, $practiceId: ID!) {
  adminPracticeLocations(subdomain: $subdomain, practiceId: $practiceId) {
    ...practiceLocationSelectEssentials
  }
}
    ${PracticeLocationSelectEssentialsFragmentDoc}`;

/**
 * __useAdminPracticeLocationsQuery__
 *
 * To run a query within a React component, call `useAdminPracticeLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPracticeLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPracticeLocationsQuery({
 *   variables: {
 *      subdomain: // value for 'subdomain'
 *      practiceId: // value for 'practiceId'
 *   },
 * });
 */
export function useAdminPracticeLocationsQuery(baseOptions: Apollo.QueryHookOptions<Operation.AdminPracticeLocationsQuery, Operation.AdminPracticeLocationsQueryVariables> & ({ variables: Operation.AdminPracticeLocationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AdminPracticeLocationsQuery, Operation.AdminPracticeLocationsQueryVariables>(AdminPracticeLocationsDocument, options);
      }
export function useAdminPracticeLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AdminPracticeLocationsQuery, Operation.AdminPracticeLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AdminPracticeLocationsQuery, Operation.AdminPracticeLocationsQueryVariables>(AdminPracticeLocationsDocument, options);
        }
export function useAdminPracticeLocationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AdminPracticeLocationsQuery, Operation.AdminPracticeLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AdminPracticeLocationsQuery, Operation.AdminPracticeLocationsQueryVariables>(AdminPracticeLocationsDocument, options);
        }
export type AdminPracticeLocationsQueryHookResult = ReturnType<typeof useAdminPracticeLocationsQuery>;
export type AdminPracticeLocationsLazyQueryHookResult = ReturnType<typeof useAdminPracticeLocationsLazyQuery>;
export type AdminPracticeLocationsSuspenseQueryHookResult = ReturnType<typeof useAdminPracticeLocationsSuspenseQuery>;
export type AdminPracticeLocationsQueryResult = Apollo.QueryResult<Operation.AdminPracticeLocationsQuery, Operation.AdminPracticeLocationsQueryVariables>;
export const InvoiceDocument = gql`
    query Invoice($id: ID!) {
  invoice(id: $id) {
    ...invoiceDetailed
  }
}
    ${InvoiceDetailedFragmentDoc}`;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(baseOptions: Apollo.QueryHookOptions<Operation.InvoiceQuery, Operation.InvoiceQueryVariables> & ({ variables: Operation.InvoiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoiceQuery, Operation.InvoiceQueryVariables>(InvoiceDocument, options);
      }
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoiceQuery, Operation.InvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoiceQuery, Operation.InvoiceQueryVariables>(InvoiceDocument, options);
        }
export function useInvoiceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoiceQuery, Operation.InvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoiceQuery, Operation.InvoiceQueryVariables>(InvoiceDocument, options);
        }
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceSuspenseQueryHookResult = ReturnType<typeof useInvoiceSuspenseQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<Operation.InvoiceQuery, Operation.InvoiceQueryVariables>;
export const InvoicePublicPageDocument = gql`
    query InvoicePublicPage($id: ID!) {
  invoice(id: $id) {
    ...invoicePublicDetailed
  }
}
    ${InvoicePublicDetailedFragmentDoc}`;

/**
 * __useInvoicePublicPageQuery__
 *
 * To run a query within a React component, call `useInvoicePublicPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicePublicPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicePublicPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoicePublicPageQuery(baseOptions: Apollo.QueryHookOptions<Operation.InvoicePublicPageQuery, Operation.InvoicePublicPageQueryVariables> & ({ variables: Operation.InvoicePublicPageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoicePublicPageQuery, Operation.InvoicePublicPageQueryVariables>(InvoicePublicPageDocument, options);
      }
export function useInvoicePublicPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoicePublicPageQuery, Operation.InvoicePublicPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoicePublicPageQuery, Operation.InvoicePublicPageQueryVariables>(InvoicePublicPageDocument, options);
        }
export function useInvoicePublicPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoicePublicPageQuery, Operation.InvoicePublicPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoicePublicPageQuery, Operation.InvoicePublicPageQueryVariables>(InvoicePublicPageDocument, options);
        }
export type InvoicePublicPageQueryHookResult = ReturnType<typeof useInvoicePublicPageQuery>;
export type InvoicePublicPageLazyQueryHookResult = ReturnType<typeof useInvoicePublicPageLazyQuery>;
export type InvoicePublicPageSuspenseQueryHookResult = ReturnType<typeof useInvoicePublicPageSuspenseQuery>;
export type InvoicePublicPageQueryResult = Apollo.QueryResult<Operation.InvoicePublicPageQuery, Operation.InvoicePublicPageQueryVariables>;
export const InvoiceMembersDocument = gql`
    query InvoiceMembers($guid: ID!) {
  invoice(guid: $guid) {
    patients {
      ...invoiceBillableUpc
    }
  }
}
    ${InvoiceBillableUpcFragmentDoc}`;

/**
 * __useInvoiceMembersQuery__
 *
 * To run a query within a React component, call `useInvoiceMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceMembersQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useInvoiceMembersQuery(baseOptions: Apollo.QueryHookOptions<Operation.InvoiceMembersQuery, Operation.InvoiceMembersQueryVariables> & ({ variables: Operation.InvoiceMembersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoiceMembersQuery, Operation.InvoiceMembersQueryVariables>(InvoiceMembersDocument, options);
      }
export function useInvoiceMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoiceMembersQuery, Operation.InvoiceMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoiceMembersQuery, Operation.InvoiceMembersQueryVariables>(InvoiceMembersDocument, options);
        }
export function useInvoiceMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoiceMembersQuery, Operation.InvoiceMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoiceMembersQuery, Operation.InvoiceMembersQueryVariables>(InvoiceMembersDocument, options);
        }
export type InvoiceMembersQueryHookResult = ReturnType<typeof useInvoiceMembersQuery>;
export type InvoiceMembersLazyQueryHookResult = ReturnType<typeof useInvoiceMembersLazyQuery>;
export type InvoiceMembersSuspenseQueryHookResult = ReturnType<typeof useInvoiceMembersSuspenseQuery>;
export type InvoiceMembersQueryResult = Apollo.QueryResult<Operation.InvoiceMembersQuery, Operation.InvoiceMembersQueryVariables>;
export const InvoiceFamilyMembersDocument = gql`
    query InvoiceFamilyMembers($id: ID!) {
  family(id: $id) {
    familyMembers {
      userPracticeConnection {
        ...invoiceBillableUpc
      }
    }
  }
}
    ${InvoiceBillableUpcFragmentDoc}`;

/**
 * __useInvoiceFamilyMembersQuery__
 *
 * To run a query within a React component, call `useInvoiceFamilyMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceFamilyMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceFamilyMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceFamilyMembersQuery(baseOptions: Apollo.QueryHookOptions<Operation.InvoiceFamilyMembersQuery, Operation.InvoiceFamilyMembersQueryVariables> & ({ variables: Operation.InvoiceFamilyMembersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoiceFamilyMembersQuery, Operation.InvoiceFamilyMembersQueryVariables>(InvoiceFamilyMembersDocument, options);
      }
export function useInvoiceFamilyMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoiceFamilyMembersQuery, Operation.InvoiceFamilyMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoiceFamilyMembersQuery, Operation.InvoiceFamilyMembersQueryVariables>(InvoiceFamilyMembersDocument, options);
        }
export function useInvoiceFamilyMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoiceFamilyMembersQuery, Operation.InvoiceFamilyMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoiceFamilyMembersQuery, Operation.InvoiceFamilyMembersQueryVariables>(InvoiceFamilyMembersDocument, options);
        }
export type InvoiceFamilyMembersQueryHookResult = ReturnType<typeof useInvoiceFamilyMembersQuery>;
export type InvoiceFamilyMembersLazyQueryHookResult = ReturnType<typeof useInvoiceFamilyMembersLazyQuery>;
export type InvoiceFamilyMembersSuspenseQueryHookResult = ReturnType<typeof useInvoiceFamilyMembersSuspenseQuery>;
export type InvoiceFamilyMembersQueryResult = Apollo.QueryResult<Operation.InvoiceFamilyMembersQuery, Operation.InvoiceFamilyMembersQueryVariables>;
export const InvoiceItemDocument = gql`
    query InvoiceItem($id: ID!) {
  invoiceItem(id: $id) {
    ...invoiceItemEssentials
  }
}
    ${InvoiceItemEssentialsFragmentDoc}`;

/**
 * __useInvoiceItemQuery__
 *
 * To run a query within a React component, call `useInvoiceItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceItemQuery(baseOptions: Apollo.QueryHookOptions<Operation.InvoiceItemQuery, Operation.InvoiceItemQueryVariables> & ({ variables: Operation.InvoiceItemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoiceItemQuery, Operation.InvoiceItemQueryVariables>(InvoiceItemDocument, options);
      }
export function useInvoiceItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoiceItemQuery, Operation.InvoiceItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoiceItemQuery, Operation.InvoiceItemQueryVariables>(InvoiceItemDocument, options);
        }
export function useInvoiceItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoiceItemQuery, Operation.InvoiceItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoiceItemQuery, Operation.InvoiceItemQueryVariables>(InvoiceItemDocument, options);
        }
export type InvoiceItemQueryHookResult = ReturnType<typeof useInvoiceItemQuery>;
export type InvoiceItemLazyQueryHookResult = ReturnType<typeof useInvoiceItemLazyQuery>;
export type InvoiceItemSuspenseQueryHookResult = ReturnType<typeof useInvoiceItemSuspenseQuery>;
export type InvoiceItemQueryResult = Apollo.QueryResult<Operation.InvoiceItemQuery, Operation.InvoiceItemQueryVariables>;
export const InvoiceItemsDetailedDocument = gql`
    query InvoiceItemsDetailed($first: Int, $after: String, $guids: [ID!], $invoiceId: ID, $patientId: ID) {
  invoiceItems(
    first: $first
    after: $after
    guids: $guids
    invoiceId: $invoiceId
    patientId: $patientId
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
    edges {
      node {
        ...invoiceItemDetailed
      }
    }
  }
}
    ${InvoiceItemDetailedFragmentDoc}`;

/**
 * __useInvoiceItemsDetailedQuery__
 *
 * To run a query within a React component, call `useInvoiceItemsDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceItemsDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceItemsDetailedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      guids: // value for 'guids'
 *      invoiceId: // value for 'invoiceId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useInvoiceItemsDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.InvoiceItemsDetailedQuery, Operation.InvoiceItemsDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoiceItemsDetailedQuery, Operation.InvoiceItemsDetailedQueryVariables>(InvoiceItemsDetailedDocument, options);
      }
export function useInvoiceItemsDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoiceItemsDetailedQuery, Operation.InvoiceItemsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoiceItemsDetailedQuery, Operation.InvoiceItemsDetailedQueryVariables>(InvoiceItemsDetailedDocument, options);
        }
export function useInvoiceItemsDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoiceItemsDetailedQuery, Operation.InvoiceItemsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoiceItemsDetailedQuery, Operation.InvoiceItemsDetailedQueryVariables>(InvoiceItemsDetailedDocument, options);
        }
export type InvoiceItemsDetailedQueryHookResult = ReturnType<typeof useInvoiceItemsDetailedQuery>;
export type InvoiceItemsDetailedLazyQueryHookResult = ReturnType<typeof useInvoiceItemsDetailedLazyQuery>;
export type InvoiceItemsDetailedSuspenseQueryHookResult = ReturnType<typeof useInvoiceItemsDetailedSuspenseQuery>;
export type InvoiceItemsDetailedQueryResult = Apollo.QueryResult<Operation.InvoiceItemsDetailedQuery, Operation.InvoiceItemsDetailedQueryVariables>;
export const InvoiceSettingDocument = gql`
    query InvoiceSetting($id: ID) {
  invoiceSetting(id: $id) {
    ...invoiceSettingsEssentials
  }
}
    ${InvoiceSettingsEssentialsFragmentDoc}`;

/**
 * __useInvoiceSettingQuery__
 *
 * To run a query within a React component, call `useInvoiceSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceSettingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceSettingQuery(baseOptions?: Apollo.QueryHookOptions<Operation.InvoiceSettingQuery, Operation.InvoiceSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoiceSettingQuery, Operation.InvoiceSettingQueryVariables>(InvoiceSettingDocument, options);
      }
export function useInvoiceSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoiceSettingQuery, Operation.InvoiceSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoiceSettingQuery, Operation.InvoiceSettingQueryVariables>(InvoiceSettingDocument, options);
        }
export function useInvoiceSettingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoiceSettingQuery, Operation.InvoiceSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoiceSettingQuery, Operation.InvoiceSettingQueryVariables>(InvoiceSettingDocument, options);
        }
export type InvoiceSettingQueryHookResult = ReturnType<typeof useInvoiceSettingQuery>;
export type InvoiceSettingLazyQueryHookResult = ReturnType<typeof useInvoiceSettingLazyQuery>;
export type InvoiceSettingSuspenseQueryHookResult = ReturnType<typeof useInvoiceSettingSuspenseQuery>;
export type InvoiceSettingQueryResult = Apollo.QueryResult<Operation.InvoiceSettingQuery, Operation.InvoiceSettingQueryVariables>;
export const InvoiceSettingsMinimalDocument = gql`
    query InvoiceSettingsMinimal($first: Int, $after: String, $default: Boolean, $archived: Boolean) {
  invoiceSettings(
    first: $first
    after: $after
    default: $default
    archived: $archived
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...invoiceSettingsMinimal
      }
    }
  }
}
    ${InvoiceSettingsMinimalFragmentDoc}`;

/**
 * __useInvoiceSettingsMinimalQuery__
 *
 * To run a query within a React component, call `useInvoiceSettingsMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceSettingsMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceSettingsMinimalQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      default: // value for 'default'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useInvoiceSettingsMinimalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.InvoiceSettingsMinimalQuery, Operation.InvoiceSettingsMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoiceSettingsMinimalQuery, Operation.InvoiceSettingsMinimalQueryVariables>(InvoiceSettingsMinimalDocument, options);
      }
export function useInvoiceSettingsMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoiceSettingsMinimalQuery, Operation.InvoiceSettingsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoiceSettingsMinimalQuery, Operation.InvoiceSettingsMinimalQueryVariables>(InvoiceSettingsMinimalDocument, options);
        }
export function useInvoiceSettingsMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoiceSettingsMinimalQuery, Operation.InvoiceSettingsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoiceSettingsMinimalQuery, Operation.InvoiceSettingsMinimalQueryVariables>(InvoiceSettingsMinimalDocument, options);
        }
export type InvoiceSettingsMinimalQueryHookResult = ReturnType<typeof useInvoiceSettingsMinimalQuery>;
export type InvoiceSettingsMinimalLazyQueryHookResult = ReturnType<typeof useInvoiceSettingsMinimalLazyQuery>;
export type InvoiceSettingsMinimalSuspenseQueryHookResult = ReturnType<typeof useInvoiceSettingsMinimalSuspenseQuery>;
export type InvoiceSettingsMinimalQueryResult = Apollo.QueryResult<Operation.InvoiceSettingsMinimalQuery, Operation.InvoiceSettingsMinimalQueryVariables>;
export const InvoiceSettingsEssentialsDocument = gql`
    query InvoiceSettingsEssentials($first: Int, $after: String, $default: Boolean, $archived: Boolean) {
  invoiceSettings(
    first: $first
    after: $after
    default: $default
    archived: $archived
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...invoiceSettingsEssentials
      }
    }
  }
}
    ${InvoiceSettingsEssentialsFragmentDoc}`;

/**
 * __useInvoiceSettingsEssentialsQuery__
 *
 * To run a query within a React component, call `useInvoiceSettingsEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceSettingsEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceSettingsEssentialsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      default: // value for 'default'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useInvoiceSettingsEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.InvoiceSettingsEssentialsQuery, Operation.InvoiceSettingsEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoiceSettingsEssentialsQuery, Operation.InvoiceSettingsEssentialsQueryVariables>(InvoiceSettingsEssentialsDocument, options);
      }
export function useInvoiceSettingsEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoiceSettingsEssentialsQuery, Operation.InvoiceSettingsEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoiceSettingsEssentialsQuery, Operation.InvoiceSettingsEssentialsQueryVariables>(InvoiceSettingsEssentialsDocument, options);
        }
export function useInvoiceSettingsEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoiceSettingsEssentialsQuery, Operation.InvoiceSettingsEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoiceSettingsEssentialsQuery, Operation.InvoiceSettingsEssentialsQueryVariables>(InvoiceSettingsEssentialsDocument, options);
        }
export type InvoiceSettingsEssentialsQueryHookResult = ReturnType<typeof useInvoiceSettingsEssentialsQuery>;
export type InvoiceSettingsEssentialsLazyQueryHookResult = ReturnType<typeof useInvoiceSettingsEssentialsLazyQuery>;
export type InvoiceSettingsEssentialsSuspenseQueryHookResult = ReturnType<typeof useInvoiceSettingsEssentialsSuspenseQuery>;
export type InvoiceSettingsEssentialsQueryResult = Apollo.QueryResult<Operation.InvoiceSettingsEssentialsQuery, Operation.InvoiceSettingsEssentialsQueryVariables>;
export const UpcInvoiceSummaryDocument = gql`
    query UPCInvoiceSummary($id: ID) {
  userPracticeConnection(id: $id) {
    ...upcInvoiceSummary
  }
}
    ${UpcInvoiceSummaryFragmentDoc}`;

/**
 * __useUpcInvoiceSummaryQuery__
 *
 * To run a query within a React component, call `useUpcInvoiceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcInvoiceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcInvoiceSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpcInvoiceSummaryQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcInvoiceSummaryQuery, Operation.UpcInvoiceSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcInvoiceSummaryQuery, Operation.UpcInvoiceSummaryQueryVariables>(UpcInvoiceSummaryDocument, options);
      }
export function useUpcInvoiceSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcInvoiceSummaryQuery, Operation.UpcInvoiceSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcInvoiceSummaryQuery, Operation.UpcInvoiceSummaryQueryVariables>(UpcInvoiceSummaryDocument, options);
        }
export function useUpcInvoiceSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcInvoiceSummaryQuery, Operation.UpcInvoiceSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcInvoiceSummaryQuery, Operation.UpcInvoiceSummaryQueryVariables>(UpcInvoiceSummaryDocument, options);
        }
export type UpcInvoiceSummaryQueryHookResult = ReturnType<typeof useUpcInvoiceSummaryQuery>;
export type UpcInvoiceSummaryLazyQueryHookResult = ReturnType<typeof useUpcInvoiceSummaryLazyQuery>;
export type UpcInvoiceSummarySuspenseQueryHookResult = ReturnType<typeof useUpcInvoiceSummarySuspenseQuery>;
export type UpcInvoiceSummaryQueryResult = Apollo.QueryResult<Operation.UpcInvoiceSummaryQuery, Operation.UpcInvoiceSummaryQueryVariables>;
export const InvoiceTransactionDocument = gql`
    query InvoiceTransaction($id: ID!) {
  invoiceTransaction(id: $id) {
    ...invoiceTransactionDetailed
  }
}
    ${InvoiceTransactionDetailedFragmentDoc}`;

/**
 * __useInvoiceTransactionQuery__
 *
 * To run a query within a React component, call `useInvoiceTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceTransactionQuery(baseOptions: Apollo.QueryHookOptions<Operation.InvoiceTransactionQuery, Operation.InvoiceTransactionQueryVariables> & ({ variables: Operation.InvoiceTransactionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoiceTransactionQuery, Operation.InvoiceTransactionQueryVariables>(InvoiceTransactionDocument, options);
      }
export function useInvoiceTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoiceTransactionQuery, Operation.InvoiceTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoiceTransactionQuery, Operation.InvoiceTransactionQueryVariables>(InvoiceTransactionDocument, options);
        }
export function useInvoiceTransactionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoiceTransactionQuery, Operation.InvoiceTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoiceTransactionQuery, Operation.InvoiceTransactionQueryVariables>(InvoiceTransactionDocument, options);
        }
export type InvoiceTransactionQueryHookResult = ReturnType<typeof useInvoiceTransactionQuery>;
export type InvoiceTransactionLazyQueryHookResult = ReturnType<typeof useInvoiceTransactionLazyQuery>;
export type InvoiceTransactionSuspenseQueryHookResult = ReturnType<typeof useInvoiceTransactionSuspenseQuery>;
export type InvoiceTransactionQueryResult = Apollo.QueryResult<Operation.InvoiceTransactionQuery, Operation.InvoiceTransactionQueryVariables>;
export const InvoiceTransactionSummaryDocument = gql`
    query InvoiceTransactionSummary($period: String!) {
  invoiceTransactionSummary(period: $period) {
    ...invoiceTransactionSummaryEssentials
  }
}
    ${InvoiceTransactionSummaryEssentialsFragmentDoc}`;

/**
 * __useInvoiceTransactionSummaryQuery__
 *
 * To run a query within a React component, call `useInvoiceTransactionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceTransactionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceTransactionSummaryQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useInvoiceTransactionSummaryQuery(baseOptions: Apollo.QueryHookOptions<Operation.InvoiceTransactionSummaryQuery, Operation.InvoiceTransactionSummaryQueryVariables> & ({ variables: Operation.InvoiceTransactionSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoiceTransactionSummaryQuery, Operation.InvoiceTransactionSummaryQueryVariables>(InvoiceTransactionSummaryDocument, options);
      }
export function useInvoiceTransactionSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoiceTransactionSummaryQuery, Operation.InvoiceTransactionSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoiceTransactionSummaryQuery, Operation.InvoiceTransactionSummaryQueryVariables>(InvoiceTransactionSummaryDocument, options);
        }
export function useInvoiceTransactionSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoiceTransactionSummaryQuery, Operation.InvoiceTransactionSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoiceTransactionSummaryQuery, Operation.InvoiceTransactionSummaryQueryVariables>(InvoiceTransactionSummaryDocument, options);
        }
export type InvoiceTransactionSummaryQueryHookResult = ReturnType<typeof useInvoiceTransactionSummaryQuery>;
export type InvoiceTransactionSummaryLazyQueryHookResult = ReturnType<typeof useInvoiceTransactionSummaryLazyQuery>;
export type InvoiceTransactionSummarySuspenseQueryHookResult = ReturnType<typeof useInvoiceTransactionSummarySuspenseQuery>;
export type InvoiceTransactionSummaryQueryResult = Apollo.QueryResult<Operation.InvoiceTransactionSummaryQuery, Operation.InvoiceTransactionSummaryQueryVariables>;
export const InvoiceTransactionsDocument = gql`
    query InvoiceTransactions($invoiceId: ID!, $byDateRange: DateRangeInput, $transactionTypes: [TransactionType!], $withBalance: Boolean, $first: Int, $after: String) {
  invoiceTransactions(
    invoiceId: $invoiceId
    byDateRange: $byDateRange
    transactionTypes: $transactionTypes
    withBalance: $withBalance
    first: $first
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ...invoiceTransactionEssentials
      }
    }
  }
}
    ${InvoiceTransactionEssentialsFragmentDoc}`;

/**
 * __useInvoiceTransactionsQuery__
 *
 * To run a query within a React component, call `useInvoiceTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceTransactionsQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      byDateRange: // value for 'byDateRange'
 *      transactionTypes: // value for 'transactionTypes'
 *      withBalance: // value for 'withBalance'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInvoiceTransactionsQuery(baseOptions: Apollo.QueryHookOptions<Operation.InvoiceTransactionsQuery, Operation.InvoiceTransactionsQueryVariables> & ({ variables: Operation.InvoiceTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoiceTransactionsQuery, Operation.InvoiceTransactionsQueryVariables>(InvoiceTransactionsDocument, options);
      }
export function useInvoiceTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoiceTransactionsQuery, Operation.InvoiceTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoiceTransactionsQuery, Operation.InvoiceTransactionsQueryVariables>(InvoiceTransactionsDocument, options);
        }
export function useInvoiceTransactionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoiceTransactionsQuery, Operation.InvoiceTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoiceTransactionsQuery, Operation.InvoiceTransactionsQueryVariables>(InvoiceTransactionsDocument, options);
        }
export type InvoiceTransactionsQueryHookResult = ReturnType<typeof useInvoiceTransactionsQuery>;
export type InvoiceTransactionsLazyQueryHookResult = ReturnType<typeof useInvoiceTransactionsLazyQuery>;
export type InvoiceTransactionsSuspenseQueryHookResult = ReturnType<typeof useInvoiceTransactionsSuspenseQuery>;
export type InvoiceTransactionsQueryResult = Apollo.QueryResult<Operation.InvoiceTransactionsQuery, Operation.InvoiceTransactionsQueryVariables>;
export const InvoicesDocument = gql`
    query Invoices($byDateRange: DateRangeInput, $order: InvoiceOrderInput, $isPaid: Boolean, $byState: [InvoiceState!], $byConnection: ID, $filter: InvoiceFilter, $first: Int, $after: String) {
  invoices(
    byDateRange: $byDateRange
    order: $order
    isPaid: $isPaid
    byState: $byState
    first: $first
    after: $after
    byConnection: $byConnection
    filter: $filter
  ) {
    pageInfo {
      ...pageInfo
    }
    edges {
      cursor
      node {
        ...invoiceEssentials
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${InvoiceEssentialsFragmentDoc}`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      byDateRange: // value for 'byDateRange'
 *      order: // value for 'order'
 *      isPaid: // value for 'isPaid'
 *      byState: // value for 'byState'
 *      byConnection: // value for 'byConnection'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.InvoicesQuery, Operation.InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoicesQuery, Operation.InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoicesQuery, Operation.InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoicesQuery, Operation.InvoicesQueryVariables>(InvoicesDocument, options);
        }
export function useInvoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoicesQuery, Operation.InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoicesQuery, Operation.InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesSuspenseQueryHookResult = ReturnType<typeof useInvoicesSuspenseQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<Operation.InvoicesQuery, Operation.InvoicesQueryVariables>;
export const InvoicesMinimalDocument = gql`
    query InvoicesMinimal($byDateRange: DateRangeInput, $order: InvoiceOrderInput, $isPaid: Boolean, $byState: [InvoiceState!], $byConnection: ID, $filter: InvoiceFilter, $first: Int, $after: String) {
  invoices(
    byDateRange: $byDateRange
    order: $order
    isPaid: $isPaid
    byState: $byState
    first: $first
    after: $after
    byConnection: $byConnection
    filter: $filter
  ) {
    pageInfo {
      ...pageInfo
    }
    totalCount
    edges {
      cursor
      node {
        ...invoiceMinimal
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${InvoiceMinimalFragmentDoc}`;

/**
 * __useInvoicesMinimalQuery__
 *
 * To run a query within a React component, call `useInvoicesMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesMinimalQuery({
 *   variables: {
 *      byDateRange: // value for 'byDateRange'
 *      order: // value for 'order'
 *      isPaid: // value for 'isPaid'
 *      byState: // value for 'byState'
 *      byConnection: // value for 'byConnection'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useInvoicesMinimalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.InvoicesMinimalQuery, Operation.InvoicesMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.InvoicesMinimalQuery, Operation.InvoicesMinimalQueryVariables>(InvoicesMinimalDocument, options);
      }
export function useInvoicesMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.InvoicesMinimalQuery, Operation.InvoicesMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.InvoicesMinimalQuery, Operation.InvoicesMinimalQueryVariables>(InvoicesMinimalDocument, options);
        }
export function useInvoicesMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.InvoicesMinimalQuery, Operation.InvoicesMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.InvoicesMinimalQuery, Operation.InvoicesMinimalQueryVariables>(InvoicesMinimalDocument, options);
        }
export type InvoicesMinimalQueryHookResult = ReturnType<typeof useInvoicesMinimalQuery>;
export type InvoicesMinimalLazyQueryHookResult = ReturnType<typeof useInvoicesMinimalLazyQuery>;
export type InvoicesMinimalSuspenseQueryHookResult = ReturnType<typeof useInvoicesMinimalSuspenseQuery>;
export type InvoicesMinimalQueryResult = Apollo.QueryResult<Operation.InvoicesMinimalQuery, Operation.InvoicesMinimalQueryVariables>;
export const PatientRecordItemsDocument = gql`
    query PatientRecordItems($first: Int, $after: String, $connectionId: ID, $patientRecordId: ID, $treatmentPlanId: ID, $appointmentId: ID, $state: [PatientRecordItemState!], $withAppointment: Boolean) {
  patientRecordItems(
    first: $first
    after: $after
    connectionId: $connectionId
    patientRecordId: $patientRecordId
    treatmentPlanId: $treatmentPlanId
    appointmentId: $appointmentId
    state: $state
    withAppointment: $withAppointment
  ) {
    pageInfo {
      ...pageInfo
    }
    totalCount
    edges {
      node {
        ...patientRecordItemMinimal
        discount
        minutes
        fdi
        surfaceCode
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${PatientRecordItemMinimalFragmentDoc}`;

/**
 * __usePatientRecordItemsQuery__
 *
 * To run a query within a React component, call `usePatientRecordItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientRecordItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientRecordItemsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      connectionId: // value for 'connectionId'
 *      patientRecordId: // value for 'patientRecordId'
 *      treatmentPlanId: // value for 'treatmentPlanId'
 *      appointmentId: // value for 'appointmentId'
 *      state: // value for 'state'
 *      withAppointment: // value for 'withAppointment'
 *   },
 * });
 */
export function usePatientRecordItemsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PatientRecordItemsQuery, Operation.PatientRecordItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientRecordItemsQuery, Operation.PatientRecordItemsQueryVariables>(PatientRecordItemsDocument, options);
      }
export function usePatientRecordItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientRecordItemsQuery, Operation.PatientRecordItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientRecordItemsQuery, Operation.PatientRecordItemsQueryVariables>(PatientRecordItemsDocument, options);
        }
export function usePatientRecordItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientRecordItemsQuery, Operation.PatientRecordItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientRecordItemsQuery, Operation.PatientRecordItemsQueryVariables>(PatientRecordItemsDocument, options);
        }
export type PatientRecordItemsQueryHookResult = ReturnType<typeof usePatientRecordItemsQuery>;
export type PatientRecordItemsLazyQueryHookResult = ReturnType<typeof usePatientRecordItemsLazyQuery>;
export type PatientRecordItemsSuspenseQueryHookResult = ReturnType<typeof usePatientRecordItemsSuspenseQuery>;
export type PatientRecordItemsQueryResult = Apollo.QueryResult<Operation.PatientRecordItemsQuery, Operation.PatientRecordItemsQueryVariables>;
export const PatientRecordItemsDetailedDocument = gql`
    query PatientRecordItemsDetailed($first: Int, $after: String, $connectionId: ID, $patientRecordId: ID, $treatmentPlanId: ID, $appointmentId: ID, $state: [PatientRecordItemState!], $withAppointment: Boolean) {
  patientRecordItems(
    first: $first
    after: $after
    connectionId: $connectionId
    patientRecordId: $patientRecordId
    treatmentPlanId: $treatmentPlanId
    appointmentId: $appointmentId
    state: $state
    withAppointment: $withAppointment
  ) {
    pageInfo {
      ...pageInfo
    }
    totalCount
    edges {
      node {
        ...patientRecordItemDetailed
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${PatientRecordItemDetailedFragmentDoc}`;

/**
 * __usePatientRecordItemsDetailedQuery__
 *
 * To run a query within a React component, call `usePatientRecordItemsDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientRecordItemsDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientRecordItemsDetailedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      connectionId: // value for 'connectionId'
 *      patientRecordId: // value for 'patientRecordId'
 *      treatmentPlanId: // value for 'treatmentPlanId'
 *      appointmentId: // value for 'appointmentId'
 *      state: // value for 'state'
 *      withAppointment: // value for 'withAppointment'
 *   },
 * });
 */
export function usePatientRecordItemsDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PatientRecordItemsDetailedQuery, Operation.PatientRecordItemsDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientRecordItemsDetailedQuery, Operation.PatientRecordItemsDetailedQueryVariables>(PatientRecordItemsDetailedDocument, options);
      }
export function usePatientRecordItemsDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientRecordItemsDetailedQuery, Operation.PatientRecordItemsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientRecordItemsDetailedQuery, Operation.PatientRecordItemsDetailedQueryVariables>(PatientRecordItemsDetailedDocument, options);
        }
export function usePatientRecordItemsDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientRecordItemsDetailedQuery, Operation.PatientRecordItemsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientRecordItemsDetailedQuery, Operation.PatientRecordItemsDetailedQueryVariables>(PatientRecordItemsDetailedDocument, options);
        }
export type PatientRecordItemsDetailedQueryHookResult = ReturnType<typeof usePatientRecordItemsDetailedQuery>;
export type PatientRecordItemsDetailedLazyQueryHookResult = ReturnType<typeof usePatientRecordItemsDetailedLazyQuery>;
export type PatientRecordItemsDetailedSuspenseQueryHookResult = ReturnType<typeof usePatientRecordItemsDetailedSuspenseQuery>;
export type PatientRecordItemsDetailedQueryResult = Apollo.QueryResult<Operation.PatientRecordItemsDetailedQuery, Operation.PatientRecordItemsDetailedQueryVariables>;
export const PaymentMethodsDocument = gql`
    query PaymentMethods($byProviderType: PaymentProviderType, $enabled: Boolean, $excludedMethods: [TransactionMethod!]) {
  paymentMethods(
    byProviderType: $byProviderType
    enabled: $enabled
    excludedMethods: $excludedMethods
  ) {
    ...paymentMethodEssentials
  }
}
    ${PaymentMethodEssentialsFragmentDoc}`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      byProviderType: // value for 'byProviderType'
 *      enabled: // value for 'enabled'
 *      excludedMethods: // value for 'excludedMethods'
 *   },
 * });
 */
export function usePaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PaymentMethodsQuery, Operation.PaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PaymentMethodsQuery, Operation.PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
      }
export function usePaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PaymentMethodsQuery, Operation.PaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PaymentMethodsQuery, Operation.PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
        }
export function usePaymentMethodsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PaymentMethodsQuery, Operation.PaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PaymentMethodsQuery, Operation.PaymentMethodsQueryVariables>(PaymentMethodsDocument, options);
        }
export type PaymentMethodsQueryHookResult = ReturnType<typeof usePaymentMethodsQuery>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<typeof usePaymentMethodsLazyQuery>;
export type PaymentMethodsSuspenseQueryHookResult = ReturnType<typeof usePaymentMethodsSuspenseQuery>;
export type PaymentMethodsQueryResult = Apollo.QueryResult<Operation.PaymentMethodsQuery, Operation.PaymentMethodsQueryVariables>;
export const PaymentProviderDocument = gql`
    query PaymentProvider($id: ID, $provider: PaymentProviderType) {
  paymentProvider(id: $id, providerType: $provider) {
    ...paymentProviderDetailed
  }
}
    ${PaymentProviderDetailedFragmentDoc}`;

/**
 * __usePaymentProviderQuery__
 *
 * To run a query within a React component, call `usePaymentProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentProviderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function usePaymentProviderQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PaymentProviderQuery, Operation.PaymentProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PaymentProviderQuery, Operation.PaymentProviderQueryVariables>(PaymentProviderDocument, options);
      }
export function usePaymentProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PaymentProviderQuery, Operation.PaymentProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PaymentProviderQuery, Operation.PaymentProviderQueryVariables>(PaymentProviderDocument, options);
        }
export function usePaymentProviderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PaymentProviderQuery, Operation.PaymentProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PaymentProviderQuery, Operation.PaymentProviderQueryVariables>(PaymentProviderDocument, options);
        }
export type PaymentProviderQueryHookResult = ReturnType<typeof usePaymentProviderQuery>;
export type PaymentProviderLazyQueryHookResult = ReturnType<typeof usePaymentProviderLazyQuery>;
export type PaymentProviderSuspenseQueryHookResult = ReturnType<typeof usePaymentProviderSuspenseQuery>;
export type PaymentProviderQueryResult = Apollo.QueryResult<Operation.PaymentProviderQuery, Operation.PaymentProviderQueryVariables>;
export const StripePaymentProviderDocument = gql`
    query StripePaymentProvider($practiceLocationId: ID!) {
  paymentProvider(providerType: stripe, practiceLocationId: $practiceLocationId) {
    ...paymentProviderStripeDetailed
  }
}
    ${PaymentProviderStripeDetailedFragmentDoc}`;

/**
 * __useStripePaymentProviderQuery__
 *
 * To run a query within a React component, call `useStripePaymentProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripePaymentProviderQuery({
 *   variables: {
 *      practiceLocationId: // value for 'practiceLocationId'
 *   },
 * });
 */
export function useStripePaymentProviderQuery(baseOptions: Apollo.QueryHookOptions<Operation.StripePaymentProviderQuery, Operation.StripePaymentProviderQueryVariables> & ({ variables: Operation.StripePaymentProviderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.StripePaymentProviderQuery, Operation.StripePaymentProviderQueryVariables>(StripePaymentProviderDocument, options);
      }
export function useStripePaymentProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.StripePaymentProviderQuery, Operation.StripePaymentProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.StripePaymentProviderQuery, Operation.StripePaymentProviderQueryVariables>(StripePaymentProviderDocument, options);
        }
export function useStripePaymentProviderSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.StripePaymentProviderQuery, Operation.StripePaymentProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.StripePaymentProviderQuery, Operation.StripePaymentProviderQueryVariables>(StripePaymentProviderDocument, options);
        }
export type StripePaymentProviderQueryHookResult = ReturnType<typeof useStripePaymentProviderQuery>;
export type StripePaymentProviderLazyQueryHookResult = ReturnType<typeof useStripePaymentProviderLazyQuery>;
export type StripePaymentProviderSuspenseQueryHookResult = ReturnType<typeof useStripePaymentProviderSuspenseQuery>;
export type StripePaymentProviderQueryResult = Apollo.QueryResult<Operation.StripePaymentProviderQuery, Operation.StripePaymentProviderQueryVariables>;
export const PaymentProvidersDocument = gql`
    query PaymentProviders($first: Int, $after: String, $provider: PaymentProviderType, $practiceLocationId: ID) {
  paymentProviders(
    first: $first
    after: $after
    providerType: $provider
    practiceLocationId: $practiceLocationId
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...paymentProviderDetailed
      }
    }
  }
}
    ${PaymentProviderDetailedFragmentDoc}`;

/**
 * __usePaymentProvidersQuery__
 *
 * To run a query within a React component, call `usePaymentProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentProvidersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      provider: // value for 'provider'
 *      practiceLocationId: // value for 'practiceLocationId'
 *   },
 * });
 */
export function usePaymentProvidersQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PaymentProvidersQuery, Operation.PaymentProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PaymentProvidersQuery, Operation.PaymentProvidersQueryVariables>(PaymentProvidersDocument, options);
      }
export function usePaymentProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PaymentProvidersQuery, Operation.PaymentProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PaymentProvidersQuery, Operation.PaymentProvidersQueryVariables>(PaymentProvidersDocument, options);
        }
export function usePaymentProvidersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PaymentProvidersQuery, Operation.PaymentProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PaymentProvidersQuery, Operation.PaymentProvidersQueryVariables>(PaymentProvidersDocument, options);
        }
export type PaymentProvidersQueryHookResult = ReturnType<typeof usePaymentProvidersQuery>;
export type PaymentProvidersLazyQueryHookResult = ReturnType<typeof usePaymentProvidersLazyQuery>;
export type PaymentProvidersSuspenseQueryHookResult = ReturnType<typeof usePaymentProvidersSuspenseQuery>;
export type PaymentProvidersQueryResult = Apollo.QueryResult<Operation.PaymentProvidersQuery, Operation.PaymentProvidersQueryVariables>;
export const PaymentProvidersEssentialsDocument = gql`
    query PaymentProvidersEssentials($first: Int, $after: String, $provider: PaymentProviderType, $practiceLocationId: ID) {
  paymentProviders(
    first: $first
    after: $after
    providerType: $provider
    practiceLocationId: $practiceLocationId
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...paymentProviderEssentials
      }
    }
  }
}
    ${PaymentProviderEssentialsFragmentDoc}`;

/**
 * __usePaymentProvidersEssentialsQuery__
 *
 * To run a query within a React component, call `usePaymentProvidersEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentProvidersEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentProvidersEssentialsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      provider: // value for 'provider'
 *      practiceLocationId: // value for 'practiceLocationId'
 *   },
 * });
 */
export function usePaymentProvidersEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PaymentProvidersEssentialsQuery, Operation.PaymentProvidersEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PaymentProvidersEssentialsQuery, Operation.PaymentProvidersEssentialsQueryVariables>(PaymentProvidersEssentialsDocument, options);
      }
export function usePaymentProvidersEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PaymentProvidersEssentialsQuery, Operation.PaymentProvidersEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PaymentProvidersEssentialsQuery, Operation.PaymentProvidersEssentialsQueryVariables>(PaymentProvidersEssentialsDocument, options);
        }
export function usePaymentProvidersEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PaymentProvidersEssentialsQuery, Operation.PaymentProvidersEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PaymentProvidersEssentialsQuery, Operation.PaymentProvidersEssentialsQueryVariables>(PaymentProvidersEssentialsDocument, options);
        }
export type PaymentProvidersEssentialsQueryHookResult = ReturnType<typeof usePaymentProvidersEssentialsQuery>;
export type PaymentProvidersEssentialsLazyQueryHookResult = ReturnType<typeof usePaymentProvidersEssentialsLazyQuery>;
export type PaymentProvidersEssentialsSuspenseQueryHookResult = ReturnType<typeof usePaymentProvidersEssentialsSuspenseQuery>;
export type PaymentProvidersEssentialsQueryResult = Apollo.QueryResult<Operation.PaymentProvidersEssentialsQuery, Operation.PaymentProvidersEssentialsQueryVariables>;
export const PaymentTerminalsDocument = gql`
    query PaymentTerminals($byProviderType: PaymentProviderType) {
  paymentTerminals(byProviderType: $byProviderType) {
    totalCount
    edges {
      cursor
      node {
        ...paymentTerminalEssentials
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${PaymentTerminalEssentialsFragmentDoc}`;

/**
 * __usePaymentTerminalsQuery__
 *
 * To run a query within a React component, call `usePaymentTerminalsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentTerminalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentTerminalsQuery({
 *   variables: {
 *      byProviderType: // value for 'byProviderType'
 *   },
 * });
 */
export function usePaymentTerminalsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PaymentTerminalsQuery, Operation.PaymentTerminalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PaymentTerminalsQuery, Operation.PaymentTerminalsQueryVariables>(PaymentTerminalsDocument, options);
      }
export function usePaymentTerminalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PaymentTerminalsQuery, Operation.PaymentTerminalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PaymentTerminalsQuery, Operation.PaymentTerminalsQueryVariables>(PaymentTerminalsDocument, options);
        }
export function usePaymentTerminalsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PaymentTerminalsQuery, Operation.PaymentTerminalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PaymentTerminalsQuery, Operation.PaymentTerminalsQueryVariables>(PaymentTerminalsDocument, options);
        }
export type PaymentTerminalsQueryHookResult = ReturnType<typeof usePaymentTerminalsQuery>;
export type PaymentTerminalsLazyQueryHookResult = ReturnType<typeof usePaymentTerminalsLazyQuery>;
export type PaymentTerminalsSuspenseQueryHookResult = ReturnType<typeof usePaymentTerminalsSuspenseQuery>;
export type PaymentTerminalsQueryResult = Apollo.QueryResult<Operation.PaymentTerminalsQuery, Operation.PaymentTerminalsQueryVariables>;
export const PaymentTerminalDocument = gql`
    query PaymentTerminal($id: ID) {
  paymentTerminal(id: $id) {
    ...paymentTerminalEssentials
  }
}
    ${PaymentTerminalEssentialsFragmentDoc}`;

/**
 * __usePaymentTerminalQuery__
 *
 * To run a query within a React component, call `usePaymentTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentTerminalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentTerminalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PaymentTerminalQuery, Operation.PaymentTerminalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PaymentTerminalQuery, Operation.PaymentTerminalQueryVariables>(PaymentTerminalDocument, options);
      }
export function usePaymentTerminalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PaymentTerminalQuery, Operation.PaymentTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PaymentTerminalQuery, Operation.PaymentTerminalQueryVariables>(PaymentTerminalDocument, options);
        }
export function usePaymentTerminalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PaymentTerminalQuery, Operation.PaymentTerminalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PaymentTerminalQuery, Operation.PaymentTerminalQueryVariables>(PaymentTerminalDocument, options);
        }
export type PaymentTerminalQueryHookResult = ReturnType<typeof usePaymentTerminalQuery>;
export type PaymentTerminalLazyQueryHookResult = ReturnType<typeof usePaymentTerminalLazyQuery>;
export type PaymentTerminalSuspenseQueryHookResult = ReturnType<typeof usePaymentTerminalSuspenseQuery>;
export type PaymentTerminalQueryResult = Apollo.QueryResult<Operation.PaymentTerminalQuery, Operation.PaymentTerminalQueryVariables>;
export const PaymentTransactionsDocument = gql`
    query PaymentTransactions($after: String, $limit: Int, $invoiceId: ID!) {
  paymentTransactions(after: $after, first: $limit, invoiceId: $invoiceId) {
    totalCount
    edges {
      cursor
      node {
        ...paymentTransactionDetailed
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${PaymentTransactionDetailedFragmentDoc}`;

/**
 * __usePaymentTransactionsQuery__
 *
 * To run a query within a React component, call `usePaymentTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentTransactionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function usePaymentTransactionsQuery(baseOptions: Apollo.QueryHookOptions<Operation.PaymentTransactionsQuery, Operation.PaymentTransactionsQueryVariables> & ({ variables: Operation.PaymentTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PaymentTransactionsQuery, Operation.PaymentTransactionsQueryVariables>(PaymentTransactionsDocument, options);
      }
export function usePaymentTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PaymentTransactionsQuery, Operation.PaymentTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PaymentTransactionsQuery, Operation.PaymentTransactionsQueryVariables>(PaymentTransactionsDocument, options);
        }
export function usePaymentTransactionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PaymentTransactionsQuery, Operation.PaymentTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PaymentTransactionsQuery, Operation.PaymentTransactionsQueryVariables>(PaymentTransactionsDocument, options);
        }
export type PaymentTransactionsQueryHookResult = ReturnType<typeof usePaymentTransactionsQuery>;
export type PaymentTransactionsLazyQueryHookResult = ReturnType<typeof usePaymentTransactionsLazyQuery>;
export type PaymentTransactionsSuspenseQueryHookResult = ReturnType<typeof usePaymentTransactionsSuspenseQuery>;
export type PaymentTransactionsQueryResult = Apollo.QueryResult<Operation.PaymentTransactionsQuery, Operation.PaymentTransactionsQueryVariables>;
export const CompleteTextDocument = gql`
    query CompleteText($text: String!, $maxTokens: Int) {
  completeText(text: $text, maxTokens: $maxTokens)
}
    `;

/**
 * __useCompleteTextQuery__
 *
 * To run a query within a React component, call `useCompleteTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompleteTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompleteTextQuery({
 *   variables: {
 *      text: // value for 'text'
 *      maxTokens: // value for 'maxTokens'
 *   },
 * });
 */
export function useCompleteTextQuery(baseOptions: Apollo.QueryHookOptions<Operation.CompleteTextQuery, Operation.CompleteTextQueryVariables> & ({ variables: Operation.CompleteTextQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CompleteTextQuery, Operation.CompleteTextQueryVariables>(CompleteTextDocument, options);
      }
export function useCompleteTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CompleteTextQuery, Operation.CompleteTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CompleteTextQuery, Operation.CompleteTextQueryVariables>(CompleteTextDocument, options);
        }
export function useCompleteTextSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CompleteTextQuery, Operation.CompleteTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CompleteTextQuery, Operation.CompleteTextQueryVariables>(CompleteTextDocument, options);
        }
export type CompleteTextQueryHookResult = ReturnType<typeof useCompleteTextQuery>;
export type CompleteTextLazyQueryHookResult = ReturnType<typeof useCompleteTextLazyQuery>;
export type CompleteTextSuspenseQueryHookResult = ReturnType<typeof useCompleteTextSuspenseQuery>;
export type CompleteTextQueryResult = Apollo.QueryResult<Operation.CompleteTextQuery, Operation.CompleteTextQueryVariables>;
export const CompleteMessageDocument = gql`
    query CompleteMessage($messageGuid: ID!) {
  completeMessage(message: $messageGuid)
}
    `;

/**
 * __useCompleteMessageQuery__
 *
 * To run a query within a React component, call `useCompleteMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompleteMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompleteMessageQuery({
 *   variables: {
 *      messageGuid: // value for 'messageGuid'
 *   },
 * });
 */
export function useCompleteMessageQuery(baseOptions: Apollo.QueryHookOptions<Operation.CompleteMessageQuery, Operation.CompleteMessageQueryVariables> & ({ variables: Operation.CompleteMessageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CompleteMessageQuery, Operation.CompleteMessageQueryVariables>(CompleteMessageDocument, options);
      }
export function useCompleteMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CompleteMessageQuery, Operation.CompleteMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CompleteMessageQuery, Operation.CompleteMessageQueryVariables>(CompleteMessageDocument, options);
        }
export function useCompleteMessageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CompleteMessageQuery, Operation.CompleteMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CompleteMessageQuery, Operation.CompleteMessageQueryVariables>(CompleteMessageDocument, options);
        }
export type CompleteMessageQueryHookResult = ReturnType<typeof useCompleteMessageQuery>;
export type CompleteMessageLazyQueryHookResult = ReturnType<typeof useCompleteMessageLazyQuery>;
export type CompleteMessageSuspenseQueryHookResult = ReturnType<typeof useCompleteMessageSuspenseQuery>;
export type CompleteMessageQueryResult = Apollo.QueryResult<Operation.CompleteMessageQuery, Operation.CompleteMessageQueryVariables>;
export const EditTextDocument = gql`
    query EditText($text: String!, $maxTokens: Int, $instruction: TextEditInstruction!) {
  editText(text: $text, maxTokens: $maxTokens, instruction: $instruction)
}
    `;

/**
 * __useEditTextQuery__
 *
 * To run a query within a React component, call `useEditTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTextQuery({
 *   variables: {
 *      text: // value for 'text'
 *      maxTokens: // value for 'maxTokens'
 *      instruction: // value for 'instruction'
 *   },
 * });
 */
export function useEditTextQuery(baseOptions: Apollo.QueryHookOptions<Operation.EditTextQuery, Operation.EditTextQueryVariables> & ({ variables: Operation.EditTextQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.EditTextQuery, Operation.EditTextQueryVariables>(EditTextDocument, options);
      }
export function useEditTextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.EditTextQuery, Operation.EditTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.EditTextQuery, Operation.EditTextQueryVariables>(EditTextDocument, options);
        }
export function useEditTextSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.EditTextQuery, Operation.EditTextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.EditTextQuery, Operation.EditTextQueryVariables>(EditTextDocument, options);
        }
export type EditTextQueryHookResult = ReturnType<typeof useEditTextQuery>;
export type EditTextLazyQueryHookResult = ReturnType<typeof useEditTextLazyQuery>;
export type EditTextSuspenseQueryHookResult = ReturnType<typeof useEditTextSuspenseQuery>;
export type EditTextQueryResult = Apollo.QueryResult<Operation.EditTextQuery, Operation.EditTextQueryVariables>;
export const CurrentAccountPlanDocument = gql`
    query CurrentAccountPlan {
  currentAccountPlan {
    ...accountPlanDetailed
  }
}
    ${AccountPlanDetailedFragmentDoc}`;

/**
 * __useCurrentAccountPlanQuery__
 *
 * To run a query within a React component, call `useCurrentAccountPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAccountPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAccountPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentAccountPlanQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CurrentAccountPlanQuery, Operation.CurrentAccountPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CurrentAccountPlanQuery, Operation.CurrentAccountPlanQueryVariables>(CurrentAccountPlanDocument, options);
      }
export function useCurrentAccountPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CurrentAccountPlanQuery, Operation.CurrentAccountPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CurrentAccountPlanQuery, Operation.CurrentAccountPlanQueryVariables>(CurrentAccountPlanDocument, options);
        }
export function useCurrentAccountPlanSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CurrentAccountPlanQuery, Operation.CurrentAccountPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CurrentAccountPlanQuery, Operation.CurrentAccountPlanQueryVariables>(CurrentAccountPlanDocument, options);
        }
export type CurrentAccountPlanQueryHookResult = ReturnType<typeof useCurrentAccountPlanQuery>;
export type CurrentAccountPlanLazyQueryHookResult = ReturnType<typeof useCurrentAccountPlanLazyQuery>;
export type CurrentAccountPlanSuspenseQueryHookResult = ReturnType<typeof useCurrentAccountPlanSuspenseQuery>;
export type CurrentAccountPlanQueryResult = Apollo.QueryResult<Operation.CurrentAccountPlanQuery, Operation.CurrentAccountPlanQueryVariables>;
export const PracticeDashboardConfigurationDocument = gql`
    query PracticeDashboardConfiguration {
  session {
    id
    practice {
      id
      configuration {
        ...practiceConfigurationDashboard
      }
    }
  }
}
    ${PracticeConfigurationDashboardFragmentDoc}`;

/**
 * __usePracticeDashboardConfigurationQuery__
 *
 * To run a query within a React component, call `usePracticeDashboardConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeDashboardConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeDashboardConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function usePracticeDashboardConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticeDashboardConfigurationQuery, Operation.PracticeDashboardConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticeDashboardConfigurationQuery, Operation.PracticeDashboardConfigurationQueryVariables>(PracticeDashboardConfigurationDocument, options);
      }
export function usePracticeDashboardConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticeDashboardConfigurationQuery, Operation.PracticeDashboardConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticeDashboardConfigurationQuery, Operation.PracticeDashboardConfigurationQueryVariables>(PracticeDashboardConfigurationDocument, options);
        }
export function usePracticeDashboardConfigurationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticeDashboardConfigurationQuery, Operation.PracticeDashboardConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticeDashboardConfigurationQuery, Operation.PracticeDashboardConfigurationQueryVariables>(PracticeDashboardConfigurationDocument, options);
        }
export type PracticeDashboardConfigurationQueryHookResult = ReturnType<typeof usePracticeDashboardConfigurationQuery>;
export type PracticeDashboardConfigurationLazyQueryHookResult = ReturnType<typeof usePracticeDashboardConfigurationLazyQuery>;
export type PracticeDashboardConfigurationSuspenseQueryHookResult = ReturnType<typeof usePracticeDashboardConfigurationSuspenseQuery>;
export type PracticeDashboardConfigurationQueryResult = Apollo.QueryResult<Operation.PracticeDashboardConfigurationQuery, Operation.PracticeDashboardConfigurationQueryVariables>;
export const IndustriesDocument = gql`
    query Industries {
  industries {
    id
    name
    slug
    sector
  }
}
    `;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.IndustriesQuery, Operation.IndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.IndustriesQuery, Operation.IndustriesQueryVariables>(IndustriesDocument, options);
      }
export function useIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.IndustriesQuery, Operation.IndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.IndustriesQuery, Operation.IndustriesQueryVariables>(IndustriesDocument, options);
        }
export function useIndustriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.IndustriesQuery, Operation.IndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.IndustriesQuery, Operation.IndustriesQueryVariables>(IndustriesDocument, options);
        }
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<typeof useIndustriesLazyQuery>;
export type IndustriesSuspenseQueryHookResult = ReturnType<typeof useIndustriesSuspenseQuery>;
export type IndustriesQueryResult = Apollo.QueryResult<Operation.IndustriesQuery, Operation.IndustriesQueryVariables>;
export const MailSettingsDocument = gql`
    query MailSettings {
  mailSettings {
    ...mailSettingsDetailed
  }
}
    ${MailSettingsDetailedFragmentDoc}`;

/**
 * __useMailSettingsQuery__
 *
 * To run a query within a React component, call `useMailSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMailSettingsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.MailSettingsQuery, Operation.MailSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.MailSettingsQuery, Operation.MailSettingsQueryVariables>(MailSettingsDocument, options);
      }
export function useMailSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.MailSettingsQuery, Operation.MailSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.MailSettingsQuery, Operation.MailSettingsQueryVariables>(MailSettingsDocument, options);
        }
export function useMailSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.MailSettingsQuery, Operation.MailSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.MailSettingsQuery, Operation.MailSettingsQueryVariables>(MailSettingsDocument, options);
        }
export type MailSettingsQueryHookResult = ReturnType<typeof useMailSettingsQuery>;
export type MailSettingsLazyQueryHookResult = ReturnType<typeof useMailSettingsLazyQuery>;
export type MailSettingsSuspenseQueryHookResult = ReturnType<typeof useMailSettingsSuspenseQuery>;
export type MailSettingsQueryResult = Apollo.QueryResult<Operation.MailSettingsQuery, Operation.MailSettingsQueryVariables>;
export const OnlineBookingSettingDocument = gql`
    query OnlineBookingSetting($id: ID) {
  onlineBookingSetting(id: $id) {
    ...onlineBookingSettingsEssentials
  }
}
    ${OnlineBookingSettingsEssentialsFragmentDoc}`;

/**
 * __useOnlineBookingSettingQuery__
 *
 * To run a query within a React component, call `useOnlineBookingSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineBookingSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineBookingSettingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnlineBookingSettingQuery(baseOptions?: Apollo.QueryHookOptions<Operation.OnlineBookingSettingQuery, Operation.OnlineBookingSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.OnlineBookingSettingQuery, Operation.OnlineBookingSettingQueryVariables>(OnlineBookingSettingDocument, options);
      }
export function useOnlineBookingSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.OnlineBookingSettingQuery, Operation.OnlineBookingSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.OnlineBookingSettingQuery, Operation.OnlineBookingSettingQueryVariables>(OnlineBookingSettingDocument, options);
        }
export function useOnlineBookingSettingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.OnlineBookingSettingQuery, Operation.OnlineBookingSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.OnlineBookingSettingQuery, Operation.OnlineBookingSettingQueryVariables>(OnlineBookingSettingDocument, options);
        }
export type OnlineBookingSettingQueryHookResult = ReturnType<typeof useOnlineBookingSettingQuery>;
export type OnlineBookingSettingLazyQueryHookResult = ReturnType<typeof useOnlineBookingSettingLazyQuery>;
export type OnlineBookingSettingSuspenseQueryHookResult = ReturnType<typeof useOnlineBookingSettingSuspenseQuery>;
export type OnlineBookingSettingQueryResult = Apollo.QueryResult<Operation.OnlineBookingSettingQuery, Operation.OnlineBookingSettingQueryVariables>;
export const OnlineBookingSettingsMinimalDocument = gql`
    query OnlineBookingSettingsMinimal($first: Int, $after: String, $practice: ID, $enabled: Boolean) {
  onlineBookingSettings(
    first: $first
    after: $after
    practice: $practice
    enabled: $enabled
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...onlineBookingSettingsMinimal
      }
    }
  }
}
    ${OnlineBookingSettingsMinimalFragmentDoc}`;

/**
 * __useOnlineBookingSettingsMinimalQuery__
 *
 * To run a query within a React component, call `useOnlineBookingSettingsMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineBookingSettingsMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineBookingSettingsMinimalQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      practice: // value for 'practice'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useOnlineBookingSettingsMinimalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.OnlineBookingSettingsMinimalQuery, Operation.OnlineBookingSettingsMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.OnlineBookingSettingsMinimalQuery, Operation.OnlineBookingSettingsMinimalQueryVariables>(OnlineBookingSettingsMinimalDocument, options);
      }
export function useOnlineBookingSettingsMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.OnlineBookingSettingsMinimalQuery, Operation.OnlineBookingSettingsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.OnlineBookingSettingsMinimalQuery, Operation.OnlineBookingSettingsMinimalQueryVariables>(OnlineBookingSettingsMinimalDocument, options);
        }
export function useOnlineBookingSettingsMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.OnlineBookingSettingsMinimalQuery, Operation.OnlineBookingSettingsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.OnlineBookingSettingsMinimalQuery, Operation.OnlineBookingSettingsMinimalQueryVariables>(OnlineBookingSettingsMinimalDocument, options);
        }
export type OnlineBookingSettingsMinimalQueryHookResult = ReturnType<typeof useOnlineBookingSettingsMinimalQuery>;
export type OnlineBookingSettingsMinimalLazyQueryHookResult = ReturnType<typeof useOnlineBookingSettingsMinimalLazyQuery>;
export type OnlineBookingSettingsMinimalSuspenseQueryHookResult = ReturnType<typeof useOnlineBookingSettingsMinimalSuspenseQuery>;
export type OnlineBookingSettingsMinimalQueryResult = Apollo.QueryResult<Operation.OnlineBookingSettingsMinimalQuery, Operation.OnlineBookingSettingsMinimalQueryVariables>;
export const OnlineBookingSettingsEssentialsDocument = gql`
    query OnlineBookingSettingsEssentials($first: Int, $after: String, $practice: ID, $enabled: Boolean) {
  onlineBookingSettings(
    first: $first
    after: $after
    practice: $practice
    enabled: $enabled
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...onlineBookingSettingsEssentials
      }
    }
  }
}
    ${OnlineBookingSettingsEssentialsFragmentDoc}`;

/**
 * __useOnlineBookingSettingsEssentialsQuery__
 *
 * To run a query within a React component, call `useOnlineBookingSettingsEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineBookingSettingsEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineBookingSettingsEssentialsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      practice: // value for 'practice'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useOnlineBookingSettingsEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.OnlineBookingSettingsEssentialsQuery, Operation.OnlineBookingSettingsEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.OnlineBookingSettingsEssentialsQuery, Operation.OnlineBookingSettingsEssentialsQueryVariables>(OnlineBookingSettingsEssentialsDocument, options);
      }
export function useOnlineBookingSettingsEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.OnlineBookingSettingsEssentialsQuery, Operation.OnlineBookingSettingsEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.OnlineBookingSettingsEssentialsQuery, Operation.OnlineBookingSettingsEssentialsQueryVariables>(OnlineBookingSettingsEssentialsDocument, options);
        }
export function useOnlineBookingSettingsEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.OnlineBookingSettingsEssentialsQuery, Operation.OnlineBookingSettingsEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.OnlineBookingSettingsEssentialsQuery, Operation.OnlineBookingSettingsEssentialsQueryVariables>(OnlineBookingSettingsEssentialsDocument, options);
        }
export type OnlineBookingSettingsEssentialsQueryHookResult = ReturnType<typeof useOnlineBookingSettingsEssentialsQuery>;
export type OnlineBookingSettingsEssentialsLazyQueryHookResult = ReturnType<typeof useOnlineBookingSettingsEssentialsLazyQuery>;
export type OnlineBookingSettingsEssentialsSuspenseQueryHookResult = ReturnType<typeof useOnlineBookingSettingsEssentialsSuspenseQuery>;
export type OnlineBookingSettingsEssentialsQueryResult = Apollo.QueryResult<Operation.OnlineBookingSettingsEssentialsQuery, Operation.OnlineBookingSettingsEssentialsQueryVariables>;
export const PracticePaymentMethodDocument = gql`
    query PracticePaymentMethod {
  practice {
    ...practiceSubscription
  }
}
    ${PracticeSubscriptionFragmentDoc}`;

/**
 * __usePracticePaymentMethodQuery__
 *
 * To run a query within a React component, call `usePracticePaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticePaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticePaymentMethodQuery({
 *   variables: {
 *   },
 * });
 */
export function usePracticePaymentMethodQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticePaymentMethodQuery, Operation.PracticePaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticePaymentMethodQuery, Operation.PracticePaymentMethodQueryVariables>(PracticePaymentMethodDocument, options);
      }
export function usePracticePaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticePaymentMethodQuery, Operation.PracticePaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticePaymentMethodQuery, Operation.PracticePaymentMethodQueryVariables>(PracticePaymentMethodDocument, options);
        }
export function usePracticePaymentMethodSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticePaymentMethodQuery, Operation.PracticePaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticePaymentMethodQuery, Operation.PracticePaymentMethodQueryVariables>(PracticePaymentMethodDocument, options);
        }
export type PracticePaymentMethodQueryHookResult = ReturnType<typeof usePracticePaymentMethodQuery>;
export type PracticePaymentMethodLazyQueryHookResult = ReturnType<typeof usePracticePaymentMethodLazyQuery>;
export type PracticePaymentMethodSuspenseQueryHookResult = ReturnType<typeof usePracticePaymentMethodSuspenseQuery>;
export type PracticePaymentMethodQueryResult = Apollo.QueryResult<Operation.PracticePaymentMethodQuery, Operation.PracticePaymentMethodQueryVariables>;
export const PracticePreferenceDocument = gql`
    query PracticePreference($name: String!) {
  practicePreference(name: $name) {
    ...practicePreference
  }
}
    ${PracticePreferenceFragmentDoc}`;

/**
 * __usePracticePreferenceQuery__
 *
 * To run a query within a React component, call `usePracticePreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticePreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticePreferenceQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePracticePreferenceQuery(baseOptions: Apollo.QueryHookOptions<Operation.PracticePreferenceQuery, Operation.PracticePreferenceQueryVariables> & ({ variables: Operation.PracticePreferenceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticePreferenceQuery, Operation.PracticePreferenceQueryVariables>(PracticePreferenceDocument, options);
      }
export function usePracticePreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticePreferenceQuery, Operation.PracticePreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticePreferenceQuery, Operation.PracticePreferenceQueryVariables>(PracticePreferenceDocument, options);
        }
export function usePracticePreferenceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticePreferenceQuery, Operation.PracticePreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticePreferenceQuery, Operation.PracticePreferenceQueryVariables>(PracticePreferenceDocument, options);
        }
export type PracticePreferenceQueryHookResult = ReturnType<typeof usePracticePreferenceQuery>;
export type PracticePreferenceLazyQueryHookResult = ReturnType<typeof usePracticePreferenceLazyQuery>;
export type PracticePreferenceSuspenseQueryHookResult = ReturnType<typeof usePracticePreferenceSuspenseQuery>;
export type PracticePreferenceQueryResult = Apollo.QueryResult<Operation.PracticePreferenceQuery, Operation.PracticePreferenceQueryVariables>;
export const PracticeDetailedDocument = gql`
    query PracticeDetailed($guid: ID) {
  practice(guid: $guid) {
    ...practiceDetailed
  }
}
    ${PracticeDetailedFragmentDoc}`;

/**
 * __usePracticeDetailedQuery__
 *
 * To run a query within a React component, call `usePracticeDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeDetailedQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function usePracticeDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticeDetailedQuery, Operation.PracticeDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticeDetailedQuery, Operation.PracticeDetailedQueryVariables>(PracticeDetailedDocument, options);
      }
export function usePracticeDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticeDetailedQuery, Operation.PracticeDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticeDetailedQuery, Operation.PracticeDetailedQueryVariables>(PracticeDetailedDocument, options);
        }
export function usePracticeDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticeDetailedQuery, Operation.PracticeDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticeDetailedQuery, Operation.PracticeDetailedQueryVariables>(PracticeDetailedDocument, options);
        }
export type PracticeDetailedQueryHookResult = ReturnType<typeof usePracticeDetailedQuery>;
export type PracticeDetailedLazyQueryHookResult = ReturnType<typeof usePracticeDetailedLazyQuery>;
export type PracticeDetailedSuspenseQueryHookResult = ReturnType<typeof usePracticeDetailedSuspenseQuery>;
export type PracticeDetailedQueryResult = Apollo.QueryResult<Operation.PracticeDetailedQuery, Operation.PracticeDetailedQueryVariables>;
export const PracticeEssentialsDocument = gql`
    query PracticeEssentials($guid: ID) {
  practice(guid: $guid) {
    ...practiceEssentials
  }
}
    ${PracticeEssentialsFragmentDoc}`;

/**
 * __usePracticeEssentialsQuery__
 *
 * To run a query within a React component, call `usePracticeEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeEssentialsQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function usePracticeEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticeEssentialsQuery, Operation.PracticeEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticeEssentialsQuery, Operation.PracticeEssentialsQueryVariables>(PracticeEssentialsDocument, options);
      }
export function usePracticeEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticeEssentialsQuery, Operation.PracticeEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticeEssentialsQuery, Operation.PracticeEssentialsQueryVariables>(PracticeEssentialsDocument, options);
        }
export function usePracticeEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticeEssentialsQuery, Operation.PracticeEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticeEssentialsQuery, Operation.PracticeEssentialsQueryVariables>(PracticeEssentialsDocument, options);
        }
export type PracticeEssentialsQueryHookResult = ReturnType<typeof usePracticeEssentialsQuery>;
export type PracticeEssentialsLazyQueryHookResult = ReturnType<typeof usePracticeEssentialsLazyQuery>;
export type PracticeEssentialsSuspenseQueryHookResult = ReturnType<typeof usePracticeEssentialsSuspenseQuery>;
export type PracticeEssentialsQueryResult = Apollo.QueryResult<Operation.PracticeEssentialsQuery, Operation.PracticeEssentialsQueryVariables>;
export const PracticePatientAppSettingsDocument = gql`
    query PracticePatientAppSettings($guid: ID) {
  practice(guid: $guid) {
    guid
    configuration {
      id
      patientAppPrimaryColor
      pushNotificationEvents
    }
  }
}
    `;

/**
 * __usePracticePatientAppSettingsQuery__
 *
 * To run a query within a React component, call `usePracticePatientAppSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticePatientAppSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticePatientAppSettingsQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function usePracticePatientAppSettingsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticePatientAppSettingsQuery, Operation.PracticePatientAppSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticePatientAppSettingsQuery, Operation.PracticePatientAppSettingsQueryVariables>(PracticePatientAppSettingsDocument, options);
      }
export function usePracticePatientAppSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticePatientAppSettingsQuery, Operation.PracticePatientAppSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticePatientAppSettingsQuery, Operation.PracticePatientAppSettingsQueryVariables>(PracticePatientAppSettingsDocument, options);
        }
export function usePracticePatientAppSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticePatientAppSettingsQuery, Operation.PracticePatientAppSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticePatientAppSettingsQuery, Operation.PracticePatientAppSettingsQueryVariables>(PracticePatientAppSettingsDocument, options);
        }
export type PracticePatientAppSettingsQueryHookResult = ReturnType<typeof usePracticePatientAppSettingsQuery>;
export type PracticePatientAppSettingsLazyQueryHookResult = ReturnType<typeof usePracticePatientAppSettingsLazyQuery>;
export type PracticePatientAppSettingsSuspenseQueryHookResult = ReturnType<typeof usePracticePatientAppSettingsSuspenseQuery>;
export type PracticePatientAppSettingsQueryResult = Apollo.QueryResult<Operation.PracticePatientAppSettingsQuery, Operation.PracticePatientAppSettingsQueryVariables>;
export const PracticePermissionsDocument = gql`
    query PracticePermissions {
  session {
    id
    practice {
      ...practiceEssentials
      features {
        id
        slug
        description
      }
      allFeatures
    }
  }
}
    ${PracticeEssentialsFragmentDoc}`;

/**
 * __usePracticePermissionsQuery__
 *
 * To run a query within a React component, call `usePracticePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePracticePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticePermissionsQuery, Operation.PracticePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticePermissionsQuery, Operation.PracticePermissionsQueryVariables>(PracticePermissionsDocument, options);
      }
export function usePracticePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticePermissionsQuery, Operation.PracticePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticePermissionsQuery, Operation.PracticePermissionsQueryVariables>(PracticePermissionsDocument, options);
        }
export function usePracticePermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticePermissionsQuery, Operation.PracticePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticePermissionsQuery, Operation.PracticePermissionsQueryVariables>(PracticePermissionsDocument, options);
        }
export type PracticePermissionsQueryHookResult = ReturnType<typeof usePracticePermissionsQuery>;
export type PracticePermissionsLazyQueryHookResult = ReturnType<typeof usePracticePermissionsLazyQuery>;
export type PracticePermissionsSuspenseQueryHookResult = ReturnType<typeof usePracticePermissionsSuspenseQuery>;
export type PracticePermissionsQueryResult = Apollo.QueryResult<Operation.PracticePermissionsQuery, Operation.PracticePermissionsQueryVariables>;
export const PracticeLocationDocument = gql`
    query PracticeLocation($id: ID) {
  practiceLocation(id: $id) {
    ...practiceLocationEssentials
  }
}
    ${PracticeLocationEssentialsFragmentDoc}`;

/**
 * __usePracticeLocationQuery__
 *
 * To run a query within a React component, call `usePracticeLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePracticeLocationQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticeLocationQuery, Operation.PracticeLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticeLocationQuery, Operation.PracticeLocationQueryVariables>(PracticeLocationDocument, options);
      }
export function usePracticeLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticeLocationQuery, Operation.PracticeLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticeLocationQuery, Operation.PracticeLocationQueryVariables>(PracticeLocationDocument, options);
        }
export function usePracticeLocationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticeLocationQuery, Operation.PracticeLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticeLocationQuery, Operation.PracticeLocationQueryVariables>(PracticeLocationDocument, options);
        }
export type PracticeLocationQueryHookResult = ReturnType<typeof usePracticeLocationQuery>;
export type PracticeLocationLazyQueryHookResult = ReturnType<typeof usePracticeLocationLazyQuery>;
export type PracticeLocationSuspenseQueryHookResult = ReturnType<typeof usePracticeLocationSuspenseQuery>;
export type PracticeLocationQueryResult = Apollo.QueryResult<Operation.PracticeLocationQuery, Operation.PracticeLocationQueryVariables>;
export const PracticeLocationsMinimalDocument = gql`
    query PracticeLocationsMinimal($first: Int, $after: String, $default: Boolean, $practice: ID, $archived: Boolean) {
  practiceLocations(
    first: $first
    after: $after
    default: $default
    practice: $practice
    archived: $archived
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...practiceLocationMinimal
      }
    }
  }
}
    ${PracticeLocationMinimalFragmentDoc}`;

/**
 * __usePracticeLocationsMinimalQuery__
 *
 * To run a query within a React component, call `usePracticeLocationsMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeLocationsMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeLocationsMinimalQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      default: // value for 'default'
 *      practice: // value for 'practice'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function usePracticeLocationsMinimalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticeLocationsMinimalQuery, Operation.PracticeLocationsMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticeLocationsMinimalQuery, Operation.PracticeLocationsMinimalQueryVariables>(PracticeLocationsMinimalDocument, options);
      }
export function usePracticeLocationsMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticeLocationsMinimalQuery, Operation.PracticeLocationsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticeLocationsMinimalQuery, Operation.PracticeLocationsMinimalQueryVariables>(PracticeLocationsMinimalDocument, options);
        }
export function usePracticeLocationsMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticeLocationsMinimalQuery, Operation.PracticeLocationsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticeLocationsMinimalQuery, Operation.PracticeLocationsMinimalQueryVariables>(PracticeLocationsMinimalDocument, options);
        }
export type PracticeLocationsMinimalQueryHookResult = ReturnType<typeof usePracticeLocationsMinimalQuery>;
export type PracticeLocationsMinimalLazyQueryHookResult = ReturnType<typeof usePracticeLocationsMinimalLazyQuery>;
export type PracticeLocationsMinimalSuspenseQueryHookResult = ReturnType<typeof usePracticeLocationsMinimalSuspenseQuery>;
export type PracticeLocationsMinimalQueryResult = Apollo.QueryResult<Operation.PracticeLocationsMinimalQuery, Operation.PracticeLocationsMinimalQueryVariables>;
export const PracticeLocationEssentialsDocument = gql`
    query PracticeLocationEssentials($first: Int, $after: String, $default: Boolean, $practice: ID, $archived: Boolean) {
  practiceLocations(
    first: $first
    after: $after
    default: $default
    practice: $practice
    archived: $archived
  ) {
    pageInfo {
      endCursor
      hasNextPage
      startCursor
      hasPreviousPage
    }
    totalCount
    edges {
      node {
        ...practiceLocationEssentials
      }
    }
  }
}
    ${PracticeLocationEssentialsFragmentDoc}`;

/**
 * __usePracticeLocationEssentialsQuery__
 *
 * To run a query within a React component, call `usePracticeLocationEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeLocationEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeLocationEssentialsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      default: // value for 'default'
 *      practice: // value for 'practice'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function usePracticeLocationEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticeLocationEssentialsQuery, Operation.PracticeLocationEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticeLocationEssentialsQuery, Operation.PracticeLocationEssentialsQueryVariables>(PracticeLocationEssentialsDocument, options);
      }
export function usePracticeLocationEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticeLocationEssentialsQuery, Operation.PracticeLocationEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticeLocationEssentialsQuery, Operation.PracticeLocationEssentialsQueryVariables>(PracticeLocationEssentialsDocument, options);
        }
export function usePracticeLocationEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticeLocationEssentialsQuery, Operation.PracticeLocationEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticeLocationEssentialsQuery, Operation.PracticeLocationEssentialsQueryVariables>(PracticeLocationEssentialsDocument, options);
        }
export type PracticeLocationEssentialsQueryHookResult = ReturnType<typeof usePracticeLocationEssentialsQuery>;
export type PracticeLocationEssentialsLazyQueryHookResult = ReturnType<typeof usePracticeLocationEssentialsLazyQuery>;
export type PracticeLocationEssentialsSuspenseQueryHookResult = ReturnType<typeof usePracticeLocationEssentialsSuspenseQuery>;
export type PracticeLocationEssentialsQueryResult = Apollo.QueryResult<Operation.PracticeLocationEssentialsQuery, Operation.PracticeLocationEssentialsQueryVariables>;
export const PracticesDocument = gql`
    query Practices($after: String, $limit: Int) {
  practices(after: $after, first: $limit) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...practiceEssentials
      }
    }
  }
}
    ${PracticeEssentialsFragmentDoc}`;

/**
 * __usePracticesQuery__
 *
 * To run a query within a React component, call `usePracticesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePracticesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticesQuery, Operation.PracticesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticesQuery, Operation.PracticesQueryVariables>(PracticesDocument, options);
      }
export function usePracticesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticesQuery, Operation.PracticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticesQuery, Operation.PracticesQueryVariables>(PracticesDocument, options);
        }
export function usePracticesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticesQuery, Operation.PracticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticesQuery, Operation.PracticesQueryVariables>(PracticesDocument, options);
        }
export type PracticesQueryHookResult = ReturnType<typeof usePracticesQuery>;
export type PracticesLazyQueryHookResult = ReturnType<typeof usePracticesLazyQuery>;
export type PracticesSuspenseQueryHookResult = ReturnType<typeof usePracticesSuspenseQuery>;
export type PracticesQueryResult = Apollo.QueryResult<Operation.PracticesQuery, Operation.PracticesQueryVariables>;
export const PracticesDetailedDocument = gql`
    query PracticesDetailed($after: String, $limit: Int, $name: String) {
  practices(after: $after, first: $limit, name: $name) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      node {
        ...practiceExhaustive
      }
    }
  }
}
    ${PracticeExhaustiveFragmentDoc}`;

/**
 * __usePracticesDetailedQuery__
 *
 * To run a query within a React component, call `usePracticesDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticesDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticesDetailedQuery({
 *   variables: {
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePracticesDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticesDetailedQuery, Operation.PracticesDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticesDetailedQuery, Operation.PracticesDetailedQueryVariables>(PracticesDetailedDocument, options);
      }
export function usePracticesDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticesDetailedQuery, Operation.PracticesDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticesDetailedQuery, Operation.PracticesDetailedQueryVariables>(PracticesDetailedDocument, options);
        }
export function usePracticesDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticesDetailedQuery, Operation.PracticesDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticesDetailedQuery, Operation.PracticesDetailedQueryVariables>(PracticesDetailedDocument, options);
        }
export type PracticesDetailedQueryHookResult = ReturnType<typeof usePracticesDetailedQuery>;
export type PracticesDetailedLazyQueryHookResult = ReturnType<typeof usePracticesDetailedLazyQuery>;
export type PracticesDetailedSuspenseQueryHookResult = ReturnType<typeof usePracticesDetailedSuspenseQuery>;
export type PracticesDetailedQueryResult = Apollo.QueryResult<Operation.PracticesDetailedQuery, Operation.PracticesDetailedQueryVariables>;
export const LinkedPracticesDocument = gql`
    query LinkedPractices($after: String, $limit: Int, $name: String) {
  linkedPractices(after: $after, first: $limit, name: $name) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      node {
        ...practiceExhaustive
      }
    }
  }
}
    ${PracticeExhaustiveFragmentDoc}`;

/**
 * __useLinkedPracticesQuery__
 *
 * To run a query within a React component, call `useLinkedPracticesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkedPracticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkedPracticesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useLinkedPracticesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.LinkedPracticesQuery, Operation.LinkedPracticesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.LinkedPracticesQuery, Operation.LinkedPracticesQueryVariables>(LinkedPracticesDocument, options);
      }
export function useLinkedPracticesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.LinkedPracticesQuery, Operation.LinkedPracticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.LinkedPracticesQuery, Operation.LinkedPracticesQueryVariables>(LinkedPracticesDocument, options);
        }
export function useLinkedPracticesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.LinkedPracticesQuery, Operation.LinkedPracticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.LinkedPracticesQuery, Operation.LinkedPracticesQueryVariables>(LinkedPracticesDocument, options);
        }
export type LinkedPracticesQueryHookResult = ReturnType<typeof useLinkedPracticesQuery>;
export type LinkedPracticesLazyQueryHookResult = ReturnType<typeof useLinkedPracticesLazyQuery>;
export type LinkedPracticesSuspenseQueryHookResult = ReturnType<typeof useLinkedPracticesSuspenseQuery>;
export type LinkedPracticesQueryResult = Apollo.QueryResult<Operation.LinkedPracticesQuery, Operation.LinkedPracticesQueryVariables>;
export const PracticeSidekiqJobsDocument = gql`
    query PracticeSidekiqJobs($jobType: String!, $workableId: ID!, $workableType: String!, $accountId: ID!, $first: Int, $after: String) {
  practiceSidekiqJobs(
    jobType: $jobType
    workableId: $workableId
    workableType: $workableType
    accountId: $accountId
    first: $first
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...practiceSidekiqJob
      }
    }
  }
}
    ${PracticeSidekiqJobFragmentDoc}`;

/**
 * __usePracticeSidekiqJobsQuery__
 *
 * To run a query within a React component, call `usePracticeSidekiqJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeSidekiqJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeSidekiqJobsQuery({
 *   variables: {
 *      jobType: // value for 'jobType'
 *      workableId: // value for 'workableId'
 *      workableType: // value for 'workableType'
 *      accountId: // value for 'accountId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePracticeSidekiqJobsQuery(baseOptions: Apollo.QueryHookOptions<Operation.PracticeSidekiqJobsQuery, Operation.PracticeSidekiqJobsQueryVariables> & ({ variables: Operation.PracticeSidekiqJobsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticeSidekiqJobsQuery, Operation.PracticeSidekiqJobsQueryVariables>(PracticeSidekiqJobsDocument, options);
      }
export function usePracticeSidekiqJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticeSidekiqJobsQuery, Operation.PracticeSidekiqJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticeSidekiqJobsQuery, Operation.PracticeSidekiqJobsQueryVariables>(PracticeSidekiqJobsDocument, options);
        }
export function usePracticeSidekiqJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticeSidekiqJobsQuery, Operation.PracticeSidekiqJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticeSidekiqJobsQuery, Operation.PracticeSidekiqJobsQueryVariables>(PracticeSidekiqJobsDocument, options);
        }
export type PracticeSidekiqJobsQueryHookResult = ReturnType<typeof usePracticeSidekiqJobsQuery>;
export type PracticeSidekiqJobsLazyQueryHookResult = ReturnType<typeof usePracticeSidekiqJobsLazyQuery>;
export type PracticeSidekiqJobsSuspenseQueryHookResult = ReturnType<typeof usePracticeSidekiqJobsSuspenseQuery>;
export type PracticeSidekiqJobsQueryResult = Apollo.QueryResult<Operation.PracticeSidekiqJobsQuery, Operation.PracticeSidekiqJobsQueryVariables>;
export const SnippetDocument = gql`
    query Snippet($id: ID!) {
  snippet(snippet: $id) {
    ...snippetDetailed
  }
}
    ${SnippetDetailedFragmentDoc}`;

/**
 * __useSnippetQuery__
 *
 * To run a query within a React component, call `useSnippetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnippetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnippetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSnippetQuery(baseOptions: Apollo.QueryHookOptions<Operation.SnippetQuery, Operation.SnippetQueryVariables> & ({ variables: Operation.SnippetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.SnippetQuery, Operation.SnippetQueryVariables>(SnippetDocument, options);
      }
export function useSnippetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.SnippetQuery, Operation.SnippetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.SnippetQuery, Operation.SnippetQueryVariables>(SnippetDocument, options);
        }
export function useSnippetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.SnippetQuery, Operation.SnippetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.SnippetQuery, Operation.SnippetQueryVariables>(SnippetDocument, options);
        }
export type SnippetQueryHookResult = ReturnType<typeof useSnippetQuery>;
export type SnippetLazyQueryHookResult = ReturnType<typeof useSnippetLazyQuery>;
export type SnippetSuspenseQueryHookResult = ReturnType<typeof useSnippetSuspenseQuery>;
export type SnippetQueryResult = Apollo.QueryResult<Operation.SnippetQuery, Operation.SnippetQueryVariables>;
export const SnippetsDocument = gql`
    query Snippets($search: String, $author: [ID!], $state: [SnippetState!], $type: [SnippetType!]) {
  snippets(search: $search, author: $author, state: $state, snippetType: $type) {
    ...snippetDetailed
  }
}
    ${SnippetDetailedFragmentDoc}`;

/**
 * __useSnippetsQuery__
 *
 * To run a query within a React component, call `useSnippetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnippetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnippetsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      author: // value for 'author'
 *      state: // value for 'state'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSnippetsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.SnippetsQuery, Operation.SnippetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.SnippetsQuery, Operation.SnippetsQueryVariables>(SnippetsDocument, options);
      }
export function useSnippetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.SnippetsQuery, Operation.SnippetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.SnippetsQuery, Operation.SnippetsQueryVariables>(SnippetsDocument, options);
        }
export function useSnippetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.SnippetsQuery, Operation.SnippetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.SnippetsQuery, Operation.SnippetsQueryVariables>(SnippetsDocument, options);
        }
export type SnippetsQueryHookResult = ReturnType<typeof useSnippetsQuery>;
export type SnippetsLazyQueryHookResult = ReturnType<typeof useSnippetsLazyQuery>;
export type SnippetsSuspenseQueryHookResult = ReturnType<typeof useSnippetsSuspenseQuery>;
export type SnippetsQueryResult = Apollo.QueryResult<Operation.SnippetsQuery, Operation.SnippetsQueryVariables>;
export const SubscriptionPaymentTransactionsDocument = gql`
    query SubscriptionPaymentTransactions {
  subscriptionPaymentTransactions {
    id
    created
    amount
    status
    currency
    failureMessage
    receiptUrl
    metadata
    paymentMethodDetails {
      card {
        brand
        last4
        expMonth
        expYear
      }
    }
    refunds {
      data {
        amount
      }
      url
    }
  }
}
    `;

/**
 * __useSubscriptionPaymentTransactions__
 *
 * To run a query within a React component, call `useSubscriptionPaymentTransactions` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPaymentTransactions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPaymentTransactions({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionPaymentTransactions(baseOptions?: Apollo.QueryHookOptions<Operation.SubscriptionPaymentTransactionsQuery, Operation.SubscriptionPaymentTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.SubscriptionPaymentTransactionsQuery, Operation.SubscriptionPaymentTransactionsQueryVariables>(SubscriptionPaymentTransactionsDocument, options);
      }
export function useSubscriptionPaymentTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.SubscriptionPaymentTransactionsQuery, Operation.SubscriptionPaymentTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.SubscriptionPaymentTransactionsQuery, Operation.SubscriptionPaymentTransactionsQueryVariables>(SubscriptionPaymentTransactionsDocument, options);
        }
export function useSubscriptionPaymentTransactionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.SubscriptionPaymentTransactionsQuery, Operation.SubscriptionPaymentTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.SubscriptionPaymentTransactionsQuery, Operation.SubscriptionPaymentTransactionsQueryVariables>(SubscriptionPaymentTransactionsDocument, options);
        }
export type SubscriptionPaymentTransactionsHookResult = ReturnType<typeof useSubscriptionPaymentTransactions>;
export type SubscriptionPaymentTransactionsLazyQueryHookResult = ReturnType<typeof useSubscriptionPaymentTransactionsLazyQuery>;
export type SubscriptionPaymentTransactionsSuspenseQueryHookResult = ReturnType<typeof useSubscriptionPaymentTransactionsSuspenseQuery>;
export type SubscriptionPaymentTransactionsQueryResult = Apollo.QueryResult<Operation.SubscriptionPaymentTransactionsQuery, Operation.SubscriptionPaymentTransactionsQueryVariables>;
export const PremiumProductsDocument = gql`
    query PremiumProducts {
  premiumProducts {
    id
    created
    unitAmount
    currency
    nickname
    product
  }
}
    `;

/**
 * __usePremiumProductsQuery__
 *
 * To run a query within a React component, call `usePremiumProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePremiumProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePremiumProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePremiumProductsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PremiumProductsQuery, Operation.PremiumProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PremiumProductsQuery, Operation.PremiumProductsQueryVariables>(PremiumProductsDocument, options);
      }
export function usePremiumProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PremiumProductsQuery, Operation.PremiumProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PremiumProductsQuery, Operation.PremiumProductsQueryVariables>(PremiumProductsDocument, options);
        }
export function usePremiumProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PremiumProductsQuery, Operation.PremiumProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PremiumProductsQuery, Operation.PremiumProductsQueryVariables>(PremiumProductsDocument, options);
        }
export type PremiumProductsQueryHookResult = ReturnType<typeof usePremiumProductsQuery>;
export type PremiumProductsLazyQueryHookResult = ReturnType<typeof usePremiumProductsLazyQuery>;
export type PremiumProductsSuspenseQueryHookResult = ReturnType<typeof usePremiumProductsSuspenseQuery>;
export type PremiumProductsQueryResult = Apollo.QueryResult<Operation.PremiumProductsQuery, Operation.PremiumProductsQueryVariables>;
export const CalculateProductPriceDocument = gql`
    query CalculateProductPrice($product: String!, $couponCode: String) {
  calculateProductPrice(product: $product, couponCode: $couponCode) {
    quantity
    price
    tax {
      displayName
      percentage
    }
    taxAmount
    amountIncludingTax
    amountExcludingTax
  }
}
    `;

/**
 * __useCalculateProductPriceQuery__
 *
 * To run a query within a React component, call `useCalculateProductPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateProductPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateProductPriceQuery({
 *   variables: {
 *      product: // value for 'product'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useCalculateProductPriceQuery(baseOptions: Apollo.QueryHookOptions<Operation.CalculateProductPriceQuery, Operation.CalculateProductPriceQueryVariables> & ({ variables: Operation.CalculateProductPriceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CalculateProductPriceQuery, Operation.CalculateProductPriceQueryVariables>(CalculateProductPriceDocument, options);
      }
export function useCalculateProductPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CalculateProductPriceQuery, Operation.CalculateProductPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CalculateProductPriceQuery, Operation.CalculateProductPriceQueryVariables>(CalculateProductPriceDocument, options);
        }
export function useCalculateProductPriceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CalculateProductPriceQuery, Operation.CalculateProductPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CalculateProductPriceQuery, Operation.CalculateProductPriceQueryVariables>(CalculateProductPriceDocument, options);
        }
export type CalculateProductPriceQueryHookResult = ReturnType<typeof useCalculateProductPriceQuery>;
export type CalculateProductPriceLazyQueryHookResult = ReturnType<typeof useCalculateProductPriceLazyQuery>;
export type CalculateProductPriceSuspenseQueryHookResult = ReturnType<typeof useCalculateProductPriceSuspenseQuery>;
export type CalculateProductPriceQueryResult = Apollo.QueryResult<Operation.CalculateProductPriceQuery, Operation.CalculateProductPriceQueryVariables>;
export const VerifyCouponCodeDocument = gql`
    query VerifyCouponCode($couponCode: String!) {
  verifyCouponCode(couponCode: $couponCode) {
    id
    name
    percentOff
    amountOff
    valid
  }
}
    `;

/**
 * __useVerifyCouponCodeQuery__
 *
 * To run a query within a React component, call `useVerifyCouponCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyCouponCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyCouponCodeQuery({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useVerifyCouponCodeQuery(baseOptions: Apollo.QueryHookOptions<Operation.VerifyCouponCodeQuery, Operation.VerifyCouponCodeQueryVariables> & ({ variables: Operation.VerifyCouponCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.VerifyCouponCodeQuery, Operation.VerifyCouponCodeQueryVariables>(VerifyCouponCodeDocument, options);
      }
export function useVerifyCouponCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.VerifyCouponCodeQuery, Operation.VerifyCouponCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.VerifyCouponCodeQuery, Operation.VerifyCouponCodeQueryVariables>(VerifyCouponCodeDocument, options);
        }
export function useVerifyCouponCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.VerifyCouponCodeQuery, Operation.VerifyCouponCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.VerifyCouponCodeQuery, Operation.VerifyCouponCodeQueryVariables>(VerifyCouponCodeDocument, options);
        }
export type VerifyCouponCodeQueryHookResult = ReturnType<typeof useVerifyCouponCodeQuery>;
export type VerifyCouponCodeLazyQueryHookResult = ReturnType<typeof useVerifyCouponCodeLazyQuery>;
export type VerifyCouponCodeSuspenseQueryHookResult = ReturnType<typeof useVerifyCouponCodeSuspenseQuery>;
export type VerifyCouponCodeQueryResult = Apollo.QueryResult<Operation.VerifyCouponCodeQuery, Operation.VerifyCouponCodeQueryVariables>;
export const AccountBillingAddressDocument = gql`
    query AccountBillingAddress {
  accountBillingAddress {
    ...locationEssentials
  }
}
    ${LocationEssentialsFragmentDoc}`;

/**
 * __useAccountBillingAddressQuery__
 *
 * To run a query within a React component, call `useAccountBillingAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountBillingAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountBillingAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountBillingAddressQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AccountBillingAddressQuery, Operation.AccountBillingAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AccountBillingAddressQuery, Operation.AccountBillingAddressQueryVariables>(AccountBillingAddressDocument, options);
      }
export function useAccountBillingAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AccountBillingAddressQuery, Operation.AccountBillingAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AccountBillingAddressQuery, Operation.AccountBillingAddressQueryVariables>(AccountBillingAddressDocument, options);
        }
export function useAccountBillingAddressSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AccountBillingAddressQuery, Operation.AccountBillingAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AccountBillingAddressQuery, Operation.AccountBillingAddressQueryVariables>(AccountBillingAddressDocument, options);
        }
export type AccountBillingAddressQueryHookResult = ReturnType<typeof useAccountBillingAddressQuery>;
export type AccountBillingAddressLazyQueryHookResult = ReturnType<typeof useAccountBillingAddressLazyQuery>;
export type AccountBillingAddressSuspenseQueryHookResult = ReturnType<typeof useAccountBillingAddressSuspenseQuery>;
export type AccountBillingAddressQueryResult = Apollo.QueryResult<Operation.AccountBillingAddressQuery, Operation.AccountBillingAddressQueryVariables>;
export const PatientRecallsDocument = gql`
    query PatientRecalls($upcPatientIds: [ID!], $state: RecallState, $first: Int, $after: String) {
  recalls(
    upcPatientIds: $upcPatientIds
    state: $state
    first: $first
    after: $after
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
    edges {
      node {
        ...recallDetailed
      }
    }
  }
}
    ${RecallDetailedFragmentDoc}`;

/**
 * __usePatientRecallsQuery__
 *
 * To run a query within a React component, call `usePatientRecallsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientRecallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientRecallsQuery({
 *   variables: {
 *      upcPatientIds: // value for 'upcPatientIds'
 *      state: // value for 'state'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePatientRecallsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PatientRecallsQuery, Operation.PatientRecallsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientRecallsQuery, Operation.PatientRecallsQueryVariables>(PatientRecallsDocument, options);
      }
export function usePatientRecallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientRecallsQuery, Operation.PatientRecallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientRecallsQuery, Operation.PatientRecallsQueryVariables>(PatientRecallsDocument, options);
        }
export function usePatientRecallsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientRecallsQuery, Operation.PatientRecallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientRecallsQuery, Operation.PatientRecallsQueryVariables>(PatientRecallsDocument, options);
        }
export type PatientRecallsQueryHookResult = ReturnType<typeof usePatientRecallsQuery>;
export type PatientRecallsLazyQueryHookResult = ReturnType<typeof usePatientRecallsLazyQuery>;
export type PatientRecallsSuspenseQueryHookResult = ReturnType<typeof usePatientRecallsSuspenseQuery>;
export type PatientRecallsQueryResult = Apollo.QueryResult<Operation.PatientRecallsQuery, Operation.PatientRecallsQueryVariables>;
export const RecallsDocument = gql`
    query Recalls($afterDate: ISO8601DateTime, $dropOffExpired: Boolean, $userPracticeConnectionIds: [ID!], $dynamicDate: Boolean, $state: RecallState, $template: Boolean, $byRecallableType: String, $byRecallableId: ID, $upcPatientIds: [ID!], $dateRange: TimeRangeInput, $after: String, $limit: Int) {
  recalls(
    afterDate: $afterDate
    dropOffExpired: $dropOffExpired
    userPracticeConnectionIds: $userPracticeConnectionIds
    template: $template
    dynamicDate: $dynamicDate
    state: $state
    after: $after
    first: $limit
    byRecallableType: $byRecallableType
    byRecallableId: $byRecallableId
    upcPatientIds: $upcPatientIds
    dateRange: $dateRange
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...recallDetailed
      }
    }
  }
}
    ${RecallDetailedFragmentDoc}`;

/**
 * __useRecallsQuery__
 *
 * To run a query within a React component, call `useRecallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecallsQuery({
 *   variables: {
 *      afterDate: // value for 'afterDate'
 *      dropOffExpired: // value for 'dropOffExpired'
 *      userPracticeConnectionIds: // value for 'userPracticeConnectionIds'
 *      dynamicDate: // value for 'dynamicDate'
 *      state: // value for 'state'
 *      template: // value for 'template'
 *      byRecallableType: // value for 'byRecallableType'
 *      byRecallableId: // value for 'byRecallableId'
 *      upcPatientIds: // value for 'upcPatientIds'
 *      dateRange: // value for 'dateRange'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRecallsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.RecallsQuery, Operation.RecallsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RecallsQuery, Operation.RecallsQueryVariables>(RecallsDocument, options);
      }
export function useRecallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RecallsQuery, Operation.RecallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RecallsQuery, Operation.RecallsQueryVariables>(RecallsDocument, options);
        }
export function useRecallsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RecallsQuery, Operation.RecallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RecallsQuery, Operation.RecallsQueryVariables>(RecallsDocument, options);
        }
export type RecallsQueryHookResult = ReturnType<typeof useRecallsQuery>;
export type RecallsLazyQueryHookResult = ReturnType<typeof useRecallsLazyQuery>;
export type RecallsSuspenseQueryHookResult = ReturnType<typeof useRecallsSuspenseQuery>;
export type RecallsQueryResult = Apollo.QueryResult<Operation.RecallsQuery, Operation.RecallsQueryVariables>;
export const RecurringTasksDocument = gql`
    query RecurringTasks($byState: [RecurringTaskState!], $after: String, $limit: Int) {
  recurringTasks(byState: $byState, after: $after, first: $limit) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      node {
        ...recurringTaskEssentials
      }
    }
  }
}
    ${RecurringTaskEssentialsFragmentDoc}`;

/**
 * __useRecurringTasksQuery__
 *
 * To run a query within a React component, call `useRecurringTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecurringTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringTasksQuery({
 *   variables: {
 *      byState: // value for 'byState'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRecurringTasksQuery(baseOptions?: Apollo.QueryHookOptions<Operation.RecurringTasksQuery, Operation.RecurringTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RecurringTasksQuery, Operation.RecurringTasksQueryVariables>(RecurringTasksDocument, options);
      }
export function useRecurringTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RecurringTasksQuery, Operation.RecurringTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RecurringTasksQuery, Operation.RecurringTasksQueryVariables>(RecurringTasksDocument, options);
        }
export function useRecurringTasksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RecurringTasksQuery, Operation.RecurringTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RecurringTasksQuery, Operation.RecurringTasksQueryVariables>(RecurringTasksDocument, options);
        }
export type RecurringTasksQueryHookResult = ReturnType<typeof useRecurringTasksQuery>;
export type RecurringTasksLazyQueryHookResult = ReturnType<typeof useRecurringTasksLazyQuery>;
export type RecurringTasksSuspenseQueryHookResult = ReturnType<typeof useRecurringTasksSuspenseQuery>;
export type RecurringTasksQueryResult = Apollo.QueryResult<Operation.RecurringTasksQuery, Operation.RecurringTasksQueryVariables>;
export const RecurringTaskDocument = gql`
    query RecurringTask($id: String!) {
  recurringTask(id: $id) {
    ...recurringTaskEssentials
  }
}
    ${RecurringTaskEssentialsFragmentDoc}`;

/**
 * __useRecurringTaskQuery__
 *
 * To run a query within a React component, call `useRecurringTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecurringTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecurringTaskQuery(baseOptions: Apollo.QueryHookOptions<Operation.RecurringTaskQuery, Operation.RecurringTaskQueryVariables> & ({ variables: Operation.RecurringTaskQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RecurringTaskQuery, Operation.RecurringTaskQueryVariables>(RecurringTaskDocument, options);
      }
export function useRecurringTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RecurringTaskQuery, Operation.RecurringTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RecurringTaskQuery, Operation.RecurringTaskQueryVariables>(RecurringTaskDocument, options);
        }
export function useRecurringTaskSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RecurringTaskQuery, Operation.RecurringTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RecurringTaskQuery, Operation.RecurringTaskQueryVariables>(RecurringTaskDocument, options);
        }
export type RecurringTaskQueryHookResult = ReturnType<typeof useRecurringTaskQuery>;
export type RecurringTaskLazyQueryHookResult = ReturnType<typeof useRecurringTaskLazyQuery>;
export type RecurringTaskSuspenseQueryHookResult = ReturnType<typeof useRecurringTaskSuspenseQuery>;
export type RecurringTaskQueryResult = Apollo.QueryResult<Operation.RecurringTaskQuery, Operation.RecurringTaskQueryVariables>;
export const ReferralSourcesDocument = gql`
    query ReferralSources($categoryId: ID, $byState: [ReferralSourceState!]) {
  referralSources(categoryId: $categoryId, byState: $byState) {
    edges {
      node {
        ...referralSourceEssentials
      }
    }
  }
}
    ${ReferralSourceEssentialsFragmentDoc}`;

/**
 * __useReferralSourcesQuery__
 *
 * To run a query within a React component, call `useReferralSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralSourcesQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      byState: // value for 'byState'
 *   },
 * });
 */
export function useReferralSourcesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.ReferralSourcesQuery, Operation.ReferralSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ReferralSourcesQuery, Operation.ReferralSourcesQueryVariables>(ReferralSourcesDocument, options);
      }
export function useReferralSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ReferralSourcesQuery, Operation.ReferralSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ReferralSourcesQuery, Operation.ReferralSourcesQueryVariables>(ReferralSourcesDocument, options);
        }
export function useReferralSourcesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ReferralSourcesQuery, Operation.ReferralSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ReferralSourcesQuery, Operation.ReferralSourcesQueryVariables>(ReferralSourcesDocument, options);
        }
export type ReferralSourcesQueryHookResult = ReturnType<typeof useReferralSourcesQuery>;
export type ReferralSourcesLazyQueryHookResult = ReturnType<typeof useReferralSourcesLazyQuery>;
export type ReferralSourcesSuspenseQueryHookResult = ReturnType<typeof useReferralSourcesSuspenseQuery>;
export type ReferralSourcesQueryResult = Apollo.QueryResult<Operation.ReferralSourcesQuery, Operation.ReferralSourcesQueryVariables>;
export const ReferralsMinimalDocument = gql`
    query ReferralsMinimal($byState: ReferralState, $referrer: ReferralInput, $referent: ReferralInput, $referralType: ReferralType, $referralDateRange: TimeRangeInput, $initialSource: Boolean) {
  referrals(
    byState: $byState
    referrer: $referrer
    referent: $referent
    referralType: $referralType
    referralDateRange: $referralDateRange
    initialSource: $initialSource
  ) {
    edges {
      node {
        ...referralMinimal
      }
    }
  }
}
    ${ReferralMinimalFragmentDoc}`;

/**
 * __useReferralsMinimalQuery__
 *
 * To run a query within a React component, call `useReferralsMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsMinimalQuery({
 *   variables: {
 *      byState: // value for 'byState'
 *      referrer: // value for 'referrer'
 *      referent: // value for 'referent'
 *      referralType: // value for 'referralType'
 *      referralDateRange: // value for 'referralDateRange'
 *      initialSource: // value for 'initialSource'
 *   },
 * });
 */
export function useReferralsMinimalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.ReferralsMinimalQuery, Operation.ReferralsMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ReferralsMinimalQuery, Operation.ReferralsMinimalQueryVariables>(ReferralsMinimalDocument, options);
      }
export function useReferralsMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ReferralsMinimalQuery, Operation.ReferralsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ReferralsMinimalQuery, Operation.ReferralsMinimalQueryVariables>(ReferralsMinimalDocument, options);
        }
export function useReferralsMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ReferralsMinimalQuery, Operation.ReferralsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ReferralsMinimalQuery, Operation.ReferralsMinimalQueryVariables>(ReferralsMinimalDocument, options);
        }
export type ReferralsMinimalQueryHookResult = ReturnType<typeof useReferralsMinimalQuery>;
export type ReferralsMinimalLazyQueryHookResult = ReturnType<typeof useReferralsMinimalLazyQuery>;
export type ReferralsMinimalSuspenseQueryHookResult = ReturnType<typeof useReferralsMinimalSuspenseQuery>;
export type ReferralsMinimalQueryResult = Apollo.QueryResult<Operation.ReferralsMinimalQuery, Operation.ReferralsMinimalQueryVariables>;
export const ReferralsDocument = gql`
    query Referrals($byState: ReferralState, $referrer: ReferralInput, $referent: ReferralInput, $referralDateRange: TimeRangeInput, $initialSource: Boolean) {
  referrals(
    byState: $byState
    referrer: $referrer
    referent: $referent
    referralDateRange: $referralDateRange
    initialSource: $initialSource
  ) {
    edges {
      node {
        ...referralDetailed
      }
    }
  }
}
    ${ReferralDetailedFragmentDoc}`;

/**
 * __useReferralsQuery__
 *
 * To run a query within a React component, call `useReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralsQuery({
 *   variables: {
 *      byState: // value for 'byState'
 *      referrer: // value for 'referrer'
 *      referent: // value for 'referent'
 *      referralDateRange: // value for 'referralDateRange'
 *      initialSource: // value for 'initialSource'
 *   },
 * });
 */
export function useReferralsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.ReferralsQuery, Operation.ReferralsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ReferralsQuery, Operation.ReferralsQueryVariables>(ReferralsDocument, options);
      }
export function useReferralsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ReferralsQuery, Operation.ReferralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ReferralsQuery, Operation.ReferralsQueryVariables>(ReferralsDocument, options);
        }
export function useReferralsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ReferralsQuery, Operation.ReferralsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ReferralsQuery, Operation.ReferralsQueryVariables>(ReferralsDocument, options);
        }
export type ReferralsQueryHookResult = ReturnType<typeof useReferralsQuery>;
export type ReferralsLazyQueryHookResult = ReturnType<typeof useReferralsLazyQuery>;
export type ReferralsSuspenseQueryHookResult = ReturnType<typeof useReferralsSuspenseQuery>;
export type ReferralsQueryResult = Apollo.QueryResult<Operation.ReferralsQuery, Operation.ReferralsQueryVariables>;
export const DashboardWidgetsDocument = gql`
    query DashboardWidgets($reportType: ReportType!, $byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!, $byDateInterval: ReportDateMode) {
  dashboards(
    reportType: $reportType
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
    byDateInterval: $byDateInterval
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useDashboardWidgetsQuery__
 *
 * To run a query within a React component, call `useDashboardWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardWidgetsQuery({
 *   variables: {
 *      reportType: // value for 'reportType'
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *      byDateInterval: // value for 'byDateInterval'
 *   },
 * });
 */
export function useDashboardWidgetsQuery(baseOptions: Apollo.QueryHookOptions<Operation.DashboardWidgetsQuery, Operation.DashboardWidgetsQueryVariables> & ({ variables: Operation.DashboardWidgetsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DashboardWidgetsQuery, Operation.DashboardWidgetsQueryVariables>(DashboardWidgetsDocument, options);
      }
export function useDashboardWidgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DashboardWidgetsQuery, Operation.DashboardWidgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DashboardWidgetsQuery, Operation.DashboardWidgetsQueryVariables>(DashboardWidgetsDocument, options);
        }
export function useDashboardWidgetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DashboardWidgetsQuery, Operation.DashboardWidgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DashboardWidgetsQuery, Operation.DashboardWidgetsQueryVariables>(DashboardWidgetsDocument, options);
        }
export type DashboardWidgetsQueryHookResult = ReturnType<typeof useDashboardWidgetsQuery>;
export type DashboardWidgetsLazyQueryHookResult = ReturnType<typeof useDashboardWidgetsLazyQuery>;
export type DashboardWidgetsSuspenseQueryHookResult = ReturnType<typeof useDashboardWidgetsSuspenseQuery>;
export type DashboardWidgetsQueryResult = Apollo.QueryResult<Operation.DashboardWidgetsQuery, Operation.DashboardWidgetsQueryVariables>;
export const FinanceSummaryDocument = gql`
    query FinanceSummary($period: String!) {
  financeSummary(period: $period) {
    ...financeSummaryEssentials
  }
}
    ${FinanceSummaryEssentialsFragmentDoc}`;

/**
 * __useFinanceSummaryQuery__
 *
 * To run a query within a React component, call `useFinanceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceSummaryQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useFinanceSummaryQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceSummaryQuery, Operation.FinanceSummaryQueryVariables> & ({ variables: Operation.FinanceSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceSummaryQuery, Operation.FinanceSummaryQueryVariables>(FinanceSummaryDocument, options);
      }
export function useFinanceSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceSummaryQuery, Operation.FinanceSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceSummaryQuery, Operation.FinanceSummaryQueryVariables>(FinanceSummaryDocument, options);
        }
export function useFinanceSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceSummaryQuery, Operation.FinanceSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceSummaryQuery, Operation.FinanceSummaryQueryVariables>(FinanceSummaryDocument, options);
        }
export type FinanceSummaryQueryHookResult = ReturnType<typeof useFinanceSummaryQuery>;
export type FinanceSummaryLazyQueryHookResult = ReturnType<typeof useFinanceSummaryLazyQuery>;
export type FinanceSummarySuspenseQueryHookResult = ReturnType<typeof useFinanceSummarySuspenseQuery>;
export type FinanceSummaryQueryResult = Apollo.QueryResult<Operation.FinanceSummaryQuery, Operation.FinanceSummaryQueryVariables>;
export const ReferralSourceStatisticsDocument = gql`
    query ReferralSourceStatistics($referralDateRange: TimeRangeInput, $referrer: ReferralInput!, $referent: ReferralInput) {
  referralSourceStatistics(
    referralDateRange: $referralDateRange
    referrer: $referrer
    referent: $referent
  ) {
    ...referralSourceStatisticsEssentials
  }
}
    ${ReferralSourceStatisticsEssentialsFragmentDoc}`;

/**
 * __useReferralSourceStatisticsQuery__
 *
 * To run a query within a React component, call `useReferralSourceStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralSourceStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralSourceStatisticsQuery({
 *   variables: {
 *      referralDateRange: // value for 'referralDateRange'
 *      referrer: // value for 'referrer'
 *      referent: // value for 'referent'
 *   },
 * });
 */
export function useReferralSourceStatisticsQuery(baseOptions: Apollo.QueryHookOptions<Operation.ReferralSourceStatisticsQuery, Operation.ReferralSourceStatisticsQueryVariables> & ({ variables: Operation.ReferralSourceStatisticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ReferralSourceStatisticsQuery, Operation.ReferralSourceStatisticsQueryVariables>(ReferralSourceStatisticsDocument, options);
      }
export function useReferralSourceStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ReferralSourceStatisticsQuery, Operation.ReferralSourceStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ReferralSourceStatisticsQuery, Operation.ReferralSourceStatisticsQueryVariables>(ReferralSourceStatisticsDocument, options);
        }
export function useReferralSourceStatisticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ReferralSourceStatisticsQuery, Operation.ReferralSourceStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ReferralSourceStatisticsQuery, Operation.ReferralSourceStatisticsQueryVariables>(ReferralSourceStatisticsDocument, options);
        }
export type ReferralSourceStatisticsQueryHookResult = ReturnType<typeof useReferralSourceStatisticsQuery>;
export type ReferralSourceStatisticsLazyQueryHookResult = ReturnType<typeof useReferralSourceStatisticsLazyQuery>;
export type ReferralSourceStatisticsSuspenseQueryHookResult = ReturnType<typeof useReferralSourceStatisticsSuspenseQuery>;
export type ReferralSourceStatisticsQueryResult = Apollo.QueryResult<Operation.ReferralSourceStatisticsQuery, Operation.ReferralSourceStatisticsQueryVariables>;
export const ReferralSourcesSummaryDocument = gql`
    query ReferralSourcesSummary($referralDateRange: TimeRangeInput) {
  referralSourcesSummary(referralDateRange: $referralDateRange) {
    ...referralSourcesSummaryEssentials
  }
}
    ${ReferralSourcesSummaryEssentialsFragmentDoc}`;

/**
 * __useReferralSourcesSummaryQuery__
 *
 * To run a query within a React component, call `useReferralSourcesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralSourcesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralSourcesSummaryQuery({
 *   variables: {
 *      referralDateRange: // value for 'referralDateRange'
 *   },
 * });
 */
export function useReferralSourcesSummaryQuery(baseOptions?: Apollo.QueryHookOptions<Operation.ReferralSourcesSummaryQuery, Operation.ReferralSourcesSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ReferralSourcesSummaryQuery, Operation.ReferralSourcesSummaryQueryVariables>(ReferralSourcesSummaryDocument, options);
      }
export function useReferralSourcesSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ReferralSourcesSummaryQuery, Operation.ReferralSourcesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ReferralSourcesSummaryQuery, Operation.ReferralSourcesSummaryQueryVariables>(ReferralSourcesSummaryDocument, options);
        }
export function useReferralSourcesSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ReferralSourcesSummaryQuery, Operation.ReferralSourcesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ReferralSourcesSummaryQuery, Operation.ReferralSourcesSummaryQueryVariables>(ReferralSourcesSummaryDocument, options);
        }
export type ReferralSourcesSummaryQueryHookResult = ReturnType<typeof useReferralSourcesSummaryQuery>;
export type ReferralSourcesSummaryLazyQueryHookResult = ReturnType<typeof useReferralSourcesSummaryLazyQuery>;
export type ReferralSourcesSummarySuspenseQueryHookResult = ReturnType<typeof useReferralSourcesSummarySuspenseQuery>;
export type ReferralSourcesSummaryQueryResult = Apollo.QueryResult<Operation.ReferralSourcesSummaryQuery, Operation.ReferralSourcesSummaryQueryVariables>;
export const MarketingStatisticsDocument = gql`
    query MarketingStatistics($referralDateRange: TimeRangeInput) {
  marketingStatistics(referralDateRange: $referralDateRange) {
    ...marketingStatisticsEssentials
  }
}
    ${MarketingStatisticsEssentialsFragmentDoc}`;

/**
 * __useMarketingStatisticsQuery__
 *
 * To run a query within a React component, call `useMarketingStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingStatisticsQuery({
 *   variables: {
 *      referralDateRange: // value for 'referralDateRange'
 *   },
 * });
 */
export function useMarketingStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.MarketingStatisticsQuery, Operation.MarketingStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.MarketingStatisticsQuery, Operation.MarketingStatisticsQueryVariables>(MarketingStatisticsDocument, options);
      }
export function useMarketingStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.MarketingStatisticsQuery, Operation.MarketingStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.MarketingStatisticsQuery, Operation.MarketingStatisticsQueryVariables>(MarketingStatisticsDocument, options);
        }
export function useMarketingStatisticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.MarketingStatisticsQuery, Operation.MarketingStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.MarketingStatisticsQuery, Operation.MarketingStatisticsQueryVariables>(MarketingStatisticsDocument, options);
        }
export type MarketingStatisticsQueryHookResult = ReturnType<typeof useMarketingStatisticsQuery>;
export type MarketingStatisticsLazyQueryHookResult = ReturnType<typeof useMarketingStatisticsLazyQuery>;
export type MarketingStatisticsSuspenseQueryHookResult = ReturnType<typeof useMarketingStatisticsSuspenseQuery>;
export type MarketingStatisticsQueryResult = Apollo.QueryResult<Operation.MarketingStatisticsQuery, Operation.MarketingStatisticsQueryVariables>;
export const AppointmentStatusReportDocument = gql`
    query AppointmentStatusReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: appointment_status
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useAppointmentStatusReportQuery__
 *
 * To run a query within a React component, call `useAppointmentStatusReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentStatusReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentStatusReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useAppointmentStatusReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.AppointmentStatusReportQuery, Operation.AppointmentStatusReportQueryVariables> & ({ variables: Operation.AppointmentStatusReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentStatusReportQuery, Operation.AppointmentStatusReportQueryVariables>(AppointmentStatusReportDocument, options);
      }
export function useAppointmentStatusReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentStatusReportQuery, Operation.AppointmentStatusReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentStatusReportQuery, Operation.AppointmentStatusReportQueryVariables>(AppointmentStatusReportDocument, options);
        }
export function useAppointmentStatusReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentStatusReportQuery, Operation.AppointmentStatusReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentStatusReportQuery, Operation.AppointmentStatusReportQueryVariables>(AppointmentStatusReportDocument, options);
        }
export type AppointmentStatusReportQueryHookResult = ReturnType<typeof useAppointmentStatusReportQuery>;
export type AppointmentStatusReportLazyQueryHookResult = ReturnType<typeof useAppointmentStatusReportLazyQuery>;
export type AppointmentStatusReportSuspenseQueryHookResult = ReturnType<typeof useAppointmentStatusReportSuspenseQuery>;
export type AppointmentStatusReportQueryResult = Apollo.QueryResult<Operation.AppointmentStatusReportQuery, Operation.AppointmentStatusReportQueryVariables>;
export const AppointmentOnlineBookingsReportDocument = gql`
    query AppointmentOnlineBookingsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: appointment_online_bookings
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useAppointmentOnlineBookingsReportQuery__
 *
 * To run a query within a React component, call `useAppointmentOnlineBookingsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentOnlineBookingsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentOnlineBookingsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useAppointmentOnlineBookingsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.AppointmentOnlineBookingsReportQuery, Operation.AppointmentOnlineBookingsReportQueryVariables> & ({ variables: Operation.AppointmentOnlineBookingsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentOnlineBookingsReportQuery, Operation.AppointmentOnlineBookingsReportQueryVariables>(AppointmentOnlineBookingsReportDocument, options);
      }
export function useAppointmentOnlineBookingsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentOnlineBookingsReportQuery, Operation.AppointmentOnlineBookingsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentOnlineBookingsReportQuery, Operation.AppointmentOnlineBookingsReportQueryVariables>(AppointmentOnlineBookingsReportDocument, options);
        }
export function useAppointmentOnlineBookingsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentOnlineBookingsReportQuery, Operation.AppointmentOnlineBookingsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentOnlineBookingsReportQuery, Operation.AppointmentOnlineBookingsReportQueryVariables>(AppointmentOnlineBookingsReportDocument, options);
        }
export type AppointmentOnlineBookingsReportQueryHookResult = ReturnType<typeof useAppointmentOnlineBookingsReportQuery>;
export type AppointmentOnlineBookingsReportLazyQueryHookResult = ReturnType<typeof useAppointmentOnlineBookingsReportLazyQuery>;
export type AppointmentOnlineBookingsReportSuspenseQueryHookResult = ReturnType<typeof useAppointmentOnlineBookingsReportSuspenseQuery>;
export type AppointmentOnlineBookingsReportQueryResult = Apollo.QueryResult<Operation.AppointmentOnlineBookingsReportQuery, Operation.AppointmentOnlineBookingsReportQueryVariables>;
export const AppointmentFirstVisitKpiReportDocument = gql`
    query AppointmentFirstVisitKpiReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: appointment_first_visit_kpi
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useAppointmentFirstVisitKpiReportQuery__
 *
 * To run a query within a React component, call `useAppointmentFirstVisitKpiReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentFirstVisitKpiReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentFirstVisitKpiReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useAppointmentFirstVisitKpiReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.AppointmentFirstVisitKpiReportQuery, Operation.AppointmentFirstVisitKpiReportQueryVariables> & ({ variables: Operation.AppointmentFirstVisitKpiReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentFirstVisitKpiReportQuery, Operation.AppointmentFirstVisitKpiReportQueryVariables>(AppointmentFirstVisitKpiReportDocument, options);
      }
export function useAppointmentFirstVisitKpiReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentFirstVisitKpiReportQuery, Operation.AppointmentFirstVisitKpiReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentFirstVisitKpiReportQuery, Operation.AppointmentFirstVisitKpiReportQueryVariables>(AppointmentFirstVisitKpiReportDocument, options);
        }
export function useAppointmentFirstVisitKpiReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentFirstVisitKpiReportQuery, Operation.AppointmentFirstVisitKpiReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentFirstVisitKpiReportQuery, Operation.AppointmentFirstVisitKpiReportQueryVariables>(AppointmentFirstVisitKpiReportDocument, options);
        }
export type AppointmentFirstVisitKpiReportQueryHookResult = ReturnType<typeof useAppointmentFirstVisitKpiReportQuery>;
export type AppointmentFirstVisitKpiReportLazyQueryHookResult = ReturnType<typeof useAppointmentFirstVisitKpiReportLazyQuery>;
export type AppointmentFirstVisitKpiReportSuspenseQueryHookResult = ReturnType<typeof useAppointmentFirstVisitKpiReportSuspenseQuery>;
export type AppointmentFirstVisitKpiReportQueryResult = Apollo.QueryResult<Operation.AppointmentFirstVisitKpiReportQuery, Operation.AppointmentFirstVisitKpiReportQueryVariables>;
export const AppointmentRebookRateReportDocument = gql`
    query AppointmentRebookRateReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: appointment_rebook_rate
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useAppointmentRebookRateReportQuery__
 *
 * To run a query within a React component, call `useAppointmentRebookRateReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentRebookRateReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentRebookRateReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useAppointmentRebookRateReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.AppointmentRebookRateReportQuery, Operation.AppointmentRebookRateReportQueryVariables> & ({ variables: Operation.AppointmentRebookRateReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentRebookRateReportQuery, Operation.AppointmentRebookRateReportQueryVariables>(AppointmentRebookRateReportDocument, options);
      }
export function useAppointmentRebookRateReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentRebookRateReportQuery, Operation.AppointmentRebookRateReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentRebookRateReportQuery, Operation.AppointmentRebookRateReportQueryVariables>(AppointmentRebookRateReportDocument, options);
        }
export function useAppointmentRebookRateReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentRebookRateReportQuery, Operation.AppointmentRebookRateReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentRebookRateReportQuery, Operation.AppointmentRebookRateReportQueryVariables>(AppointmentRebookRateReportDocument, options);
        }
export type AppointmentRebookRateReportQueryHookResult = ReturnType<typeof useAppointmentRebookRateReportQuery>;
export type AppointmentRebookRateReportLazyQueryHookResult = ReturnType<typeof useAppointmentRebookRateReportLazyQuery>;
export type AppointmentRebookRateReportSuspenseQueryHookResult = ReturnType<typeof useAppointmentRebookRateReportSuspenseQuery>;
export type AppointmentRebookRateReportQueryResult = Apollo.QueryResult<Operation.AppointmentRebookRateReportQuery, Operation.AppointmentRebookRateReportQueryVariables>;
export const AppointmentOccupancyReportDocument = gql`
    query AppointmentOccupancyReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: appointment_occupancy
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useAppointmentOccupancyReportQuery__
 *
 * To run a query within a React component, call `useAppointmentOccupancyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentOccupancyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentOccupancyReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useAppointmentOccupancyReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.AppointmentOccupancyReportQuery, Operation.AppointmentOccupancyReportQueryVariables> & ({ variables: Operation.AppointmentOccupancyReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AppointmentOccupancyReportQuery, Operation.AppointmentOccupancyReportQueryVariables>(AppointmentOccupancyReportDocument, options);
      }
export function useAppointmentOccupancyReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AppointmentOccupancyReportQuery, Operation.AppointmentOccupancyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AppointmentOccupancyReportQuery, Operation.AppointmentOccupancyReportQueryVariables>(AppointmentOccupancyReportDocument, options);
        }
export function useAppointmentOccupancyReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AppointmentOccupancyReportQuery, Operation.AppointmentOccupancyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AppointmentOccupancyReportQuery, Operation.AppointmentOccupancyReportQueryVariables>(AppointmentOccupancyReportDocument, options);
        }
export type AppointmentOccupancyReportQueryHookResult = ReturnType<typeof useAppointmentOccupancyReportQuery>;
export type AppointmentOccupancyReportLazyQueryHookResult = ReturnType<typeof useAppointmentOccupancyReportLazyQuery>;
export type AppointmentOccupancyReportSuspenseQueryHookResult = ReturnType<typeof useAppointmentOccupancyReportSuspenseQuery>;
export type AppointmentOccupancyReportQueryResult = Apollo.QueryResult<Operation.AppointmentOccupancyReportQuery, Operation.AppointmentOccupancyReportQueryVariables>;
export const FinanceInvoiceTransactionsReportDocument = gql`
    query FinanceInvoiceTransactionsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_invoice_transactions
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceInvoiceTransactionsReportQuery__
 *
 * To run a query within a React component, call `useFinanceInvoiceTransactionsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceInvoiceTransactionsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceInvoiceTransactionsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinanceInvoiceTransactionsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceInvoiceTransactionsReportQuery, Operation.FinanceInvoiceTransactionsReportQueryVariables> & ({ variables: Operation.FinanceInvoiceTransactionsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceInvoiceTransactionsReportQuery, Operation.FinanceInvoiceTransactionsReportQueryVariables>(FinanceInvoiceTransactionsReportDocument, options);
      }
export function useFinanceInvoiceTransactionsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceInvoiceTransactionsReportQuery, Operation.FinanceInvoiceTransactionsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceInvoiceTransactionsReportQuery, Operation.FinanceInvoiceTransactionsReportQueryVariables>(FinanceInvoiceTransactionsReportDocument, options);
        }
export function useFinanceInvoiceTransactionsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceInvoiceTransactionsReportQuery, Operation.FinanceInvoiceTransactionsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceInvoiceTransactionsReportQuery, Operation.FinanceInvoiceTransactionsReportQueryVariables>(FinanceInvoiceTransactionsReportDocument, options);
        }
export type FinanceInvoiceTransactionsReportQueryHookResult = ReturnType<typeof useFinanceInvoiceTransactionsReportQuery>;
export type FinanceInvoiceTransactionsReportLazyQueryHookResult = ReturnType<typeof useFinanceInvoiceTransactionsReportLazyQuery>;
export type FinanceInvoiceTransactionsReportSuspenseQueryHookResult = ReturnType<typeof useFinanceInvoiceTransactionsReportSuspenseQuery>;
export type FinanceInvoiceTransactionsReportQueryResult = Apollo.QueryResult<Operation.FinanceInvoiceTransactionsReportQuery, Operation.FinanceInvoiceTransactionsReportQueryVariables>;
export const FinanceUninvoicedItemsReportDocument = gql`
    query FinanceUninvoicedItemsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_uninvoiced_items
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceUninvoicedItemsReportQuery__
 *
 * To run a query within a React component, call `useFinanceUninvoicedItemsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceUninvoicedItemsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceUninvoicedItemsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinanceUninvoicedItemsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceUninvoicedItemsReportQuery, Operation.FinanceUninvoicedItemsReportQueryVariables> & ({ variables: Operation.FinanceUninvoicedItemsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceUninvoicedItemsReportQuery, Operation.FinanceUninvoicedItemsReportQueryVariables>(FinanceUninvoicedItemsReportDocument, options);
      }
export function useFinanceUninvoicedItemsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceUninvoicedItemsReportQuery, Operation.FinanceUninvoicedItemsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceUninvoicedItemsReportQuery, Operation.FinanceUninvoicedItemsReportQueryVariables>(FinanceUninvoicedItemsReportDocument, options);
        }
export function useFinanceUninvoicedItemsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceUninvoicedItemsReportQuery, Operation.FinanceUninvoicedItemsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceUninvoicedItemsReportQuery, Operation.FinanceUninvoicedItemsReportQueryVariables>(FinanceUninvoicedItemsReportDocument, options);
        }
export type FinanceUninvoicedItemsReportQueryHookResult = ReturnType<typeof useFinanceUninvoicedItemsReportQuery>;
export type FinanceUninvoicedItemsReportLazyQueryHookResult = ReturnType<typeof useFinanceUninvoicedItemsReportLazyQuery>;
export type FinanceUninvoicedItemsReportSuspenseQueryHookResult = ReturnType<typeof useFinanceUninvoicedItemsReportSuspenseQuery>;
export type FinanceUninvoicedItemsReportQueryResult = Apollo.QueryResult<Operation.FinanceUninvoicedItemsReportQuery, Operation.FinanceUninvoicedItemsReportQueryVariables>;
export const FinanceInvoiceItemTransactionsReportDocument = gql`
    query FinanceInvoiceItemTransactionsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_invoice_item_transactions
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceInvoiceItemTransactionsReportQuery__
 *
 * To run a query within a React component, call `useFinanceInvoiceItemTransactionsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceInvoiceItemTransactionsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceInvoiceItemTransactionsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinanceInvoiceItemTransactionsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceInvoiceItemTransactionsReportQuery, Operation.FinanceInvoiceItemTransactionsReportQueryVariables> & ({ variables: Operation.FinanceInvoiceItemTransactionsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceInvoiceItemTransactionsReportQuery, Operation.FinanceInvoiceItemTransactionsReportQueryVariables>(FinanceInvoiceItemTransactionsReportDocument, options);
      }
export function useFinanceInvoiceItemTransactionsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceInvoiceItemTransactionsReportQuery, Operation.FinanceInvoiceItemTransactionsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceInvoiceItemTransactionsReportQuery, Operation.FinanceInvoiceItemTransactionsReportQueryVariables>(FinanceInvoiceItemTransactionsReportDocument, options);
        }
export function useFinanceInvoiceItemTransactionsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceInvoiceItemTransactionsReportQuery, Operation.FinanceInvoiceItemTransactionsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceInvoiceItemTransactionsReportQuery, Operation.FinanceInvoiceItemTransactionsReportQueryVariables>(FinanceInvoiceItemTransactionsReportDocument, options);
        }
export type FinanceInvoiceItemTransactionsReportQueryHookResult = ReturnType<typeof useFinanceInvoiceItemTransactionsReportQuery>;
export type FinanceInvoiceItemTransactionsReportLazyQueryHookResult = ReturnType<typeof useFinanceInvoiceItemTransactionsReportLazyQuery>;
export type FinanceInvoiceItemTransactionsReportSuspenseQueryHookResult = ReturnType<typeof useFinanceInvoiceItemTransactionsReportSuspenseQuery>;
export type FinanceInvoiceItemTransactionsReportQueryResult = Apollo.QueryResult<Operation.FinanceInvoiceItemTransactionsReportQuery, Operation.FinanceInvoiceItemTransactionsReportQueryVariables>;
export const FinanceInvoicesReportDocument = gql`
    query FinanceInvoicesReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_invoices
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceInvoicesReportQuery__
 *
 * To run a query within a React component, call `useFinanceInvoicesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceInvoicesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceInvoicesReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinanceInvoicesReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceInvoicesReportQuery, Operation.FinanceInvoicesReportQueryVariables> & ({ variables: Operation.FinanceInvoicesReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceInvoicesReportQuery, Operation.FinanceInvoicesReportQueryVariables>(FinanceInvoicesReportDocument, options);
      }
export function useFinanceInvoicesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceInvoicesReportQuery, Operation.FinanceInvoicesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceInvoicesReportQuery, Operation.FinanceInvoicesReportQueryVariables>(FinanceInvoicesReportDocument, options);
        }
export function useFinanceInvoicesReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceInvoicesReportQuery, Operation.FinanceInvoicesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceInvoicesReportQuery, Operation.FinanceInvoicesReportQueryVariables>(FinanceInvoicesReportDocument, options);
        }
export type FinanceInvoicesReportQueryHookResult = ReturnType<typeof useFinanceInvoicesReportQuery>;
export type FinanceInvoicesReportLazyQueryHookResult = ReturnType<typeof useFinanceInvoicesReportLazyQuery>;
export type FinanceInvoicesReportSuspenseQueryHookResult = ReturnType<typeof useFinanceInvoicesReportSuspenseQuery>;
export type FinanceInvoicesReportQueryResult = Apollo.QueryResult<Operation.FinanceInvoicesReportQuery, Operation.FinanceInvoicesReportQueryVariables>;
export const FinanceOutstandingInvoicesReportDocument = gql`
    query FinanceOutstandingInvoicesReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_outstanding_invoices
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceOutstandingInvoicesReportQuery__
 *
 * To run a query within a React component, call `useFinanceOutstandingInvoicesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceOutstandingInvoicesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceOutstandingInvoicesReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinanceOutstandingInvoicesReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceOutstandingInvoicesReportQuery, Operation.FinanceOutstandingInvoicesReportQueryVariables> & ({ variables: Operation.FinanceOutstandingInvoicesReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceOutstandingInvoicesReportQuery, Operation.FinanceOutstandingInvoicesReportQueryVariables>(FinanceOutstandingInvoicesReportDocument, options);
      }
export function useFinanceOutstandingInvoicesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceOutstandingInvoicesReportQuery, Operation.FinanceOutstandingInvoicesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceOutstandingInvoicesReportQuery, Operation.FinanceOutstandingInvoicesReportQueryVariables>(FinanceOutstandingInvoicesReportDocument, options);
        }
export function useFinanceOutstandingInvoicesReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceOutstandingInvoicesReportQuery, Operation.FinanceOutstandingInvoicesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceOutstandingInvoicesReportQuery, Operation.FinanceOutstandingInvoicesReportQueryVariables>(FinanceOutstandingInvoicesReportDocument, options);
        }
export type FinanceOutstandingInvoicesReportQueryHookResult = ReturnType<typeof useFinanceOutstandingInvoicesReportQuery>;
export type FinanceOutstandingInvoicesReportLazyQueryHookResult = ReturnType<typeof useFinanceOutstandingInvoicesReportLazyQuery>;
export type FinanceOutstandingInvoicesReportSuspenseQueryHookResult = ReturnType<typeof useFinanceOutstandingInvoicesReportSuspenseQuery>;
export type FinanceOutstandingInvoicesReportQueryResult = Apollo.QueryResult<Operation.FinanceOutstandingInvoicesReportQuery, Operation.FinanceOutstandingInvoicesReportQueryVariables>;
export const FinanceInvoiceDiscountsReportDocument = gql`
    query FinanceInvoiceDiscountsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_invoice_discounts
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceInvoiceDiscountsReportQuery__
 *
 * To run a query within a React component, call `useFinanceInvoiceDiscountsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceInvoiceDiscountsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceInvoiceDiscountsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinanceInvoiceDiscountsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceInvoiceDiscountsReportQuery, Operation.FinanceInvoiceDiscountsReportQueryVariables> & ({ variables: Operation.FinanceInvoiceDiscountsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceInvoiceDiscountsReportQuery, Operation.FinanceInvoiceDiscountsReportQueryVariables>(FinanceInvoiceDiscountsReportDocument, options);
      }
export function useFinanceInvoiceDiscountsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceInvoiceDiscountsReportQuery, Operation.FinanceInvoiceDiscountsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceInvoiceDiscountsReportQuery, Operation.FinanceInvoiceDiscountsReportQueryVariables>(FinanceInvoiceDiscountsReportDocument, options);
        }
export function useFinanceInvoiceDiscountsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceInvoiceDiscountsReportQuery, Operation.FinanceInvoiceDiscountsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceInvoiceDiscountsReportQuery, Operation.FinanceInvoiceDiscountsReportQueryVariables>(FinanceInvoiceDiscountsReportDocument, options);
        }
export type FinanceInvoiceDiscountsReportQueryHookResult = ReturnType<typeof useFinanceInvoiceDiscountsReportQuery>;
export type FinanceInvoiceDiscountsReportLazyQueryHookResult = ReturnType<typeof useFinanceInvoiceDiscountsReportLazyQuery>;
export type FinanceInvoiceDiscountsReportSuspenseQueryHookResult = ReturnType<typeof useFinanceInvoiceDiscountsReportSuspenseQuery>;
export type FinanceInvoiceDiscountsReportQueryResult = Apollo.QueryResult<Operation.FinanceInvoiceDiscountsReportQuery, Operation.FinanceInvoiceDiscountsReportQueryVariables>;
export const FinanceCustomersInCreditReportDocument = gql`
    query FinanceCustomersInCreditReport($byPracticeLocations: [ID!]!) {
  reports(
    reportType: finance_customers_in_credit
    byPracticeLocations: $byPracticeLocations
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceCustomersInCreditReportQuery__
 *
 * To run a query within a React component, call `useFinanceCustomersInCreditReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceCustomersInCreditReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceCustomersInCreditReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *   },
 * });
 */
export function useFinanceCustomersInCreditReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceCustomersInCreditReportQuery, Operation.FinanceCustomersInCreditReportQueryVariables> & ({ variables: Operation.FinanceCustomersInCreditReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceCustomersInCreditReportQuery, Operation.FinanceCustomersInCreditReportQueryVariables>(FinanceCustomersInCreditReportDocument, options);
      }
export function useFinanceCustomersInCreditReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceCustomersInCreditReportQuery, Operation.FinanceCustomersInCreditReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceCustomersInCreditReportQuery, Operation.FinanceCustomersInCreditReportQueryVariables>(FinanceCustomersInCreditReportDocument, options);
        }
export function useFinanceCustomersInCreditReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceCustomersInCreditReportQuery, Operation.FinanceCustomersInCreditReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceCustomersInCreditReportQuery, Operation.FinanceCustomersInCreditReportQueryVariables>(FinanceCustomersInCreditReportDocument, options);
        }
export type FinanceCustomersInCreditReportQueryHookResult = ReturnType<typeof useFinanceCustomersInCreditReportQuery>;
export type FinanceCustomersInCreditReportLazyQueryHookResult = ReturnType<typeof useFinanceCustomersInCreditReportLazyQuery>;
export type FinanceCustomersInCreditReportSuspenseQueryHookResult = ReturnType<typeof useFinanceCustomersInCreditReportSuspenseQuery>;
export type FinanceCustomersInCreditReportQueryResult = Apollo.QueryResult<Operation.FinanceCustomersInCreditReportQuery, Operation.FinanceCustomersInCreditReportQueryVariables>;
export const FinanceCustomerCollectionsReportDocument = gql`
    query FinanceCustomerCollectionsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_customer_collections
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceCustomerCollectionsReportQuery__
 *
 * To run a query within a React component, call `useFinanceCustomerCollectionsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceCustomerCollectionsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceCustomerCollectionsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinanceCustomerCollectionsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceCustomerCollectionsReportQuery, Operation.FinanceCustomerCollectionsReportQueryVariables> & ({ variables: Operation.FinanceCustomerCollectionsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceCustomerCollectionsReportQuery, Operation.FinanceCustomerCollectionsReportQueryVariables>(FinanceCustomerCollectionsReportDocument, options);
      }
export function useFinanceCustomerCollectionsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceCustomerCollectionsReportQuery, Operation.FinanceCustomerCollectionsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceCustomerCollectionsReportQuery, Operation.FinanceCustomerCollectionsReportQueryVariables>(FinanceCustomerCollectionsReportDocument, options);
        }
export function useFinanceCustomerCollectionsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceCustomerCollectionsReportQuery, Operation.FinanceCustomerCollectionsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceCustomerCollectionsReportQuery, Operation.FinanceCustomerCollectionsReportQueryVariables>(FinanceCustomerCollectionsReportDocument, options);
        }
export type FinanceCustomerCollectionsReportQueryHookResult = ReturnType<typeof useFinanceCustomerCollectionsReportQuery>;
export type FinanceCustomerCollectionsReportLazyQueryHookResult = ReturnType<typeof useFinanceCustomerCollectionsReportLazyQuery>;
export type FinanceCustomerCollectionsReportSuspenseQueryHookResult = ReturnType<typeof useFinanceCustomerCollectionsReportSuspenseQuery>;
export type FinanceCustomerCollectionsReportQueryResult = Apollo.QueryResult<Operation.FinanceCustomerCollectionsReportQuery, Operation.FinanceCustomerCollectionsReportQueryVariables>;
export const FinancePerformanceReportDocument = gql`
    query FinancePerformanceReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_performance
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinancePerformanceReportQuery__
 *
 * To run a query within a React component, call `useFinancePerformanceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancePerformanceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancePerformanceReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinancePerformanceReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinancePerformanceReportQuery, Operation.FinancePerformanceReportQueryVariables> & ({ variables: Operation.FinancePerformanceReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinancePerformanceReportQuery, Operation.FinancePerformanceReportQueryVariables>(FinancePerformanceReportDocument, options);
      }
export function useFinancePerformanceReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinancePerformanceReportQuery, Operation.FinancePerformanceReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinancePerformanceReportQuery, Operation.FinancePerformanceReportQueryVariables>(FinancePerformanceReportDocument, options);
        }
export function useFinancePerformanceReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinancePerformanceReportQuery, Operation.FinancePerformanceReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinancePerformanceReportQuery, Operation.FinancePerformanceReportQueryVariables>(FinancePerformanceReportDocument, options);
        }
export type FinancePerformanceReportQueryHookResult = ReturnType<typeof useFinancePerformanceReportQuery>;
export type FinancePerformanceReportLazyQueryHookResult = ReturnType<typeof useFinancePerformanceReportLazyQuery>;
export type FinancePerformanceReportSuspenseQueryHookResult = ReturnType<typeof useFinancePerformanceReportSuspenseQuery>;
export type FinancePerformanceReportQueryResult = Apollo.QueryResult<Operation.FinancePerformanceReportQuery, Operation.FinancePerformanceReportQueryVariables>;
export const FinanceProductionReportDocument = gql`
    query FinanceProductionReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!, $byDateInterval: ReportDateMode!) {
  reports(
    reportType: finance_production
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
    byDateInterval: $byDateInterval
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceProductionReportQuery__
 *
 * To run a query within a React component, call `useFinanceProductionReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceProductionReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceProductionReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *      byDateInterval: // value for 'byDateInterval'
 *   },
 * });
 */
export function useFinanceProductionReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceProductionReportQuery, Operation.FinanceProductionReportQueryVariables> & ({ variables: Operation.FinanceProductionReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceProductionReportQuery, Operation.FinanceProductionReportQueryVariables>(FinanceProductionReportDocument, options);
      }
export function useFinanceProductionReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceProductionReportQuery, Operation.FinanceProductionReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceProductionReportQuery, Operation.FinanceProductionReportQueryVariables>(FinanceProductionReportDocument, options);
        }
export function useFinanceProductionReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceProductionReportQuery, Operation.FinanceProductionReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceProductionReportQuery, Operation.FinanceProductionReportQueryVariables>(FinanceProductionReportDocument, options);
        }
export type FinanceProductionReportQueryHookResult = ReturnType<typeof useFinanceProductionReportQuery>;
export type FinanceProductionReportLazyQueryHookResult = ReturnType<typeof useFinanceProductionReportLazyQuery>;
export type FinanceProductionReportSuspenseQueryHookResult = ReturnType<typeof useFinanceProductionReportSuspenseQuery>;
export type FinanceProductionReportQueryResult = Apollo.QueryResult<Operation.FinanceProductionReportQuery, Operation.FinanceProductionReportQueryVariables>;
export const FinanceAccountCreditsReportDocument = gql`
    query FinanceAccountCreditsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_account_credits
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceAccountCreditsReportQuery__
 *
 * To run a query within a React component, call `useFinanceAccountCreditsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceAccountCreditsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceAccountCreditsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinanceAccountCreditsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceAccountCreditsReportQuery, Operation.FinanceAccountCreditsReportQueryVariables> & ({ variables: Operation.FinanceAccountCreditsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceAccountCreditsReportQuery, Operation.FinanceAccountCreditsReportQueryVariables>(FinanceAccountCreditsReportDocument, options);
      }
export function useFinanceAccountCreditsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceAccountCreditsReportQuery, Operation.FinanceAccountCreditsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceAccountCreditsReportQuery, Operation.FinanceAccountCreditsReportQueryVariables>(FinanceAccountCreditsReportDocument, options);
        }
export function useFinanceAccountCreditsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceAccountCreditsReportQuery, Operation.FinanceAccountCreditsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceAccountCreditsReportQuery, Operation.FinanceAccountCreditsReportQueryVariables>(FinanceAccountCreditsReportDocument, options);
        }
export type FinanceAccountCreditsReportQueryHookResult = ReturnType<typeof useFinanceAccountCreditsReportQuery>;
export type FinanceAccountCreditsReportLazyQueryHookResult = ReturnType<typeof useFinanceAccountCreditsReportLazyQuery>;
export type FinanceAccountCreditsReportSuspenseQueryHookResult = ReturnType<typeof useFinanceAccountCreditsReportSuspenseQuery>;
export type FinanceAccountCreditsReportQueryResult = Apollo.QueryResult<Operation.FinanceAccountCreditsReportQuery, Operation.FinanceAccountCreditsReportQueryVariables>;
export const FinanceProductsAndServicesReportDocument = gql`
    query FinanceProductsAndServicesReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_products_and_services
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceProductsAndServicesReportQuery__
 *
 * To run a query within a React component, call `useFinanceProductsAndServicesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceProductsAndServicesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceProductsAndServicesReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinanceProductsAndServicesReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceProductsAndServicesReportQuery, Operation.FinanceProductsAndServicesReportQueryVariables> & ({ variables: Operation.FinanceProductsAndServicesReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceProductsAndServicesReportQuery, Operation.FinanceProductsAndServicesReportQueryVariables>(FinanceProductsAndServicesReportDocument, options);
      }
export function useFinanceProductsAndServicesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceProductsAndServicesReportQuery, Operation.FinanceProductsAndServicesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceProductsAndServicesReportQuery, Operation.FinanceProductsAndServicesReportQueryVariables>(FinanceProductsAndServicesReportDocument, options);
        }
export function useFinanceProductsAndServicesReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceProductsAndServicesReportQuery, Operation.FinanceProductsAndServicesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceProductsAndServicesReportQuery, Operation.FinanceProductsAndServicesReportQueryVariables>(FinanceProductsAndServicesReportDocument, options);
        }
export type FinanceProductsAndServicesReportQueryHookResult = ReturnType<typeof useFinanceProductsAndServicesReportQuery>;
export type FinanceProductsAndServicesReportLazyQueryHookResult = ReturnType<typeof useFinanceProductsAndServicesReportLazyQuery>;
export type FinanceProductsAndServicesReportSuspenseQueryHookResult = ReturnType<typeof useFinanceProductsAndServicesReportSuspenseQuery>;
export type FinanceProductsAndServicesReportQueryResult = Apollo.QueryResult<Operation.FinanceProductsAndServicesReportQuery, Operation.FinanceProductsAndServicesReportQueryVariables>;
export const FinanceGstReportDocument = gql`
    query FinanceGstReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_gst
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceGstReportQuery__
 *
 * To run a query within a React component, call `useFinanceGstReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceGstReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceGstReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinanceGstReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceGstReportQuery, Operation.FinanceGstReportQueryVariables> & ({ variables: Operation.FinanceGstReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceGstReportQuery, Operation.FinanceGstReportQueryVariables>(FinanceGstReportDocument, options);
      }
export function useFinanceGstReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceGstReportQuery, Operation.FinanceGstReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceGstReportQuery, Operation.FinanceGstReportQueryVariables>(FinanceGstReportDocument, options);
        }
export function useFinanceGstReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceGstReportQuery, Operation.FinanceGstReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceGstReportQuery, Operation.FinanceGstReportQueryVariables>(FinanceGstReportDocument, options);
        }
export type FinanceGstReportQueryHookResult = ReturnType<typeof useFinanceGstReportQuery>;
export type FinanceGstReportLazyQueryHookResult = ReturnType<typeof useFinanceGstReportLazyQuery>;
export type FinanceGstReportSuspenseQueryHookResult = ReturnType<typeof useFinanceGstReportSuspenseQuery>;
export type FinanceGstReportQueryResult = Apollo.QueryResult<Operation.FinanceGstReportQuery, Operation.FinanceGstReportQueryVariables>;
export const FinanceAgingReportDocument = gql`
    query FinanceAgingReport($byPracticeLocations: [ID!]!) {
  reports(reportType: finance_aging, byPracticeLocations: $byPracticeLocations) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceAgingReportQuery__
 *
 * To run a query within a React component, call `useFinanceAgingReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceAgingReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceAgingReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *   },
 * });
 */
export function useFinanceAgingReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceAgingReportQuery, Operation.FinanceAgingReportQueryVariables> & ({ variables: Operation.FinanceAgingReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceAgingReportQuery, Operation.FinanceAgingReportQueryVariables>(FinanceAgingReportDocument, options);
      }
export function useFinanceAgingReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceAgingReportQuery, Operation.FinanceAgingReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceAgingReportQuery, Operation.FinanceAgingReportQueryVariables>(FinanceAgingReportDocument, options);
        }
export function useFinanceAgingReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceAgingReportQuery, Operation.FinanceAgingReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceAgingReportQuery, Operation.FinanceAgingReportQueryVariables>(FinanceAgingReportDocument, options);
        }
export type FinanceAgingReportQueryHookResult = ReturnType<typeof useFinanceAgingReportQuery>;
export type FinanceAgingReportLazyQueryHookResult = ReturnType<typeof useFinanceAgingReportLazyQuery>;
export type FinanceAgingReportSuspenseQueryHookResult = ReturnType<typeof useFinanceAgingReportSuspenseQuery>;
export type FinanceAgingReportQueryResult = Apollo.QueryResult<Operation.FinanceAgingReportQuery, Operation.FinanceAgingReportQueryVariables>;
export const FinanceLabFeesReportDocument = gql`
    query FinanceLabFeesReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: finance_lab_fees
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useFinanceLabFeesReportQuery__
 *
 * To run a query within a React component, call `useFinanceLabFeesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceLabFeesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceLabFeesReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useFinanceLabFeesReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.FinanceLabFeesReportQuery, Operation.FinanceLabFeesReportQueryVariables> & ({ variables: Operation.FinanceLabFeesReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FinanceLabFeesReportQuery, Operation.FinanceLabFeesReportQueryVariables>(FinanceLabFeesReportDocument, options);
      }
export function useFinanceLabFeesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FinanceLabFeesReportQuery, Operation.FinanceLabFeesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FinanceLabFeesReportQuery, Operation.FinanceLabFeesReportQueryVariables>(FinanceLabFeesReportDocument, options);
        }
export function useFinanceLabFeesReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FinanceLabFeesReportQuery, Operation.FinanceLabFeesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FinanceLabFeesReportQuery, Operation.FinanceLabFeesReportQueryVariables>(FinanceLabFeesReportDocument, options);
        }
export type FinanceLabFeesReportQueryHookResult = ReturnType<typeof useFinanceLabFeesReportQuery>;
export type FinanceLabFeesReportLazyQueryHookResult = ReturnType<typeof useFinanceLabFeesReportLazyQuery>;
export type FinanceLabFeesReportSuspenseQueryHookResult = ReturnType<typeof useFinanceLabFeesReportSuspenseQuery>;
export type FinanceLabFeesReportQueryResult = Apollo.QueryResult<Operation.FinanceLabFeesReportQuery, Operation.FinanceLabFeesReportQueryVariables>;
export const PatientTreatmentPlansReportDocument = gql`
    query PatientTreatmentPlansReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: patient_treatment_plans
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __usePatientTreatmentPlansReportQuery__
 *
 * To run a query within a React component, call `usePatientTreatmentPlansReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientTreatmentPlansReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientTreatmentPlansReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function usePatientTreatmentPlansReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientTreatmentPlansReportQuery, Operation.PatientTreatmentPlansReportQueryVariables> & ({ variables: Operation.PatientTreatmentPlansReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientTreatmentPlansReportQuery, Operation.PatientTreatmentPlansReportQueryVariables>(PatientTreatmentPlansReportDocument, options);
      }
export function usePatientTreatmentPlansReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientTreatmentPlansReportQuery, Operation.PatientTreatmentPlansReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientTreatmentPlansReportQuery, Operation.PatientTreatmentPlansReportQueryVariables>(PatientTreatmentPlansReportDocument, options);
        }
export function usePatientTreatmentPlansReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientTreatmentPlansReportQuery, Operation.PatientTreatmentPlansReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientTreatmentPlansReportQuery, Operation.PatientTreatmentPlansReportQueryVariables>(PatientTreatmentPlansReportDocument, options);
        }
export type PatientTreatmentPlansReportQueryHookResult = ReturnType<typeof usePatientTreatmentPlansReportQuery>;
export type PatientTreatmentPlansReportLazyQueryHookResult = ReturnType<typeof usePatientTreatmentPlansReportLazyQuery>;
export type PatientTreatmentPlansReportSuspenseQueryHookResult = ReturnType<typeof usePatientTreatmentPlansReportSuspenseQuery>;
export type PatientTreatmentPlansReportQueryResult = Apollo.QueryResult<Operation.PatientTreatmentPlansReportQuery, Operation.PatientTreatmentPlansReportQueryVariables>;
export const PatientOutstandingTreatmentPlansReportDocument = gql`
    query PatientOutstandingTreatmentPlansReport($byPracticeLocations: [ID!]!) {
  reports(
    reportType: patient_outstanding_treatment_plans
    byPracticeLocations: $byPracticeLocations
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __usePatientOutstandingTreatmentPlansReportQuery__
 *
 * To run a query within a React component, call `usePatientOutstandingTreatmentPlansReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientOutstandingTreatmentPlansReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientOutstandingTreatmentPlansReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *   },
 * });
 */
export function usePatientOutstandingTreatmentPlansReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientOutstandingTreatmentPlansReportQuery, Operation.PatientOutstandingTreatmentPlansReportQueryVariables> & ({ variables: Operation.PatientOutstandingTreatmentPlansReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientOutstandingTreatmentPlansReportQuery, Operation.PatientOutstandingTreatmentPlansReportQueryVariables>(PatientOutstandingTreatmentPlansReportDocument, options);
      }
export function usePatientOutstandingTreatmentPlansReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientOutstandingTreatmentPlansReportQuery, Operation.PatientOutstandingTreatmentPlansReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientOutstandingTreatmentPlansReportQuery, Operation.PatientOutstandingTreatmentPlansReportQueryVariables>(PatientOutstandingTreatmentPlansReportDocument, options);
        }
export function usePatientOutstandingTreatmentPlansReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientOutstandingTreatmentPlansReportQuery, Operation.PatientOutstandingTreatmentPlansReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientOutstandingTreatmentPlansReportQuery, Operation.PatientOutstandingTreatmentPlansReportQueryVariables>(PatientOutstandingTreatmentPlansReportDocument, options);
        }
export type PatientOutstandingTreatmentPlansReportQueryHookResult = ReturnType<typeof usePatientOutstandingTreatmentPlansReportQuery>;
export type PatientOutstandingTreatmentPlansReportLazyQueryHookResult = ReturnType<typeof usePatientOutstandingTreatmentPlansReportLazyQuery>;
export type PatientOutstandingTreatmentPlansReportSuspenseQueryHookResult = ReturnType<typeof usePatientOutstandingTreatmentPlansReportSuspenseQuery>;
export type PatientOutstandingTreatmentPlansReportQueryResult = Apollo.QueryResult<Operation.PatientOutstandingTreatmentPlansReportQuery, Operation.PatientOutstandingTreatmentPlansReportQueryVariables>;
export const PatientNewPatientsReportDocument = gql`
    query PatientNewPatientsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: patient_new_patients
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __usePatientNewPatientsReportQuery__
 *
 * To run a query within a React component, call `usePatientNewPatientsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientNewPatientsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientNewPatientsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function usePatientNewPatientsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientNewPatientsReportQuery, Operation.PatientNewPatientsReportQueryVariables> & ({ variables: Operation.PatientNewPatientsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientNewPatientsReportQuery, Operation.PatientNewPatientsReportQueryVariables>(PatientNewPatientsReportDocument, options);
      }
export function usePatientNewPatientsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientNewPatientsReportQuery, Operation.PatientNewPatientsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientNewPatientsReportQuery, Operation.PatientNewPatientsReportQueryVariables>(PatientNewPatientsReportDocument, options);
        }
export function usePatientNewPatientsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientNewPatientsReportQuery, Operation.PatientNewPatientsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientNewPatientsReportQuery, Operation.PatientNewPatientsReportQueryVariables>(PatientNewPatientsReportDocument, options);
        }
export type PatientNewPatientsReportQueryHookResult = ReturnType<typeof usePatientNewPatientsReportQuery>;
export type PatientNewPatientsReportLazyQueryHookResult = ReturnType<typeof usePatientNewPatientsReportLazyQuery>;
export type PatientNewPatientsReportSuspenseQueryHookResult = ReturnType<typeof usePatientNewPatientsReportSuspenseQuery>;
export type PatientNewPatientsReportQueryResult = Apollo.QueryResult<Operation.PatientNewPatientsReportQuery, Operation.PatientNewPatientsReportQueryVariables>;
export const PatientDropoffsReportDocument = gql`
    query PatientDropoffsReport($byPracticeLocations: [ID!]!) {
  reports(reportType: patient_dropoffs, byPracticeLocations: $byPracticeLocations) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __usePatientDropoffsReportQuery__
 *
 * To run a query within a React component, call `usePatientDropoffsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDropoffsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDropoffsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *   },
 * });
 */
export function usePatientDropoffsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientDropoffsReportQuery, Operation.PatientDropoffsReportQueryVariables> & ({ variables: Operation.PatientDropoffsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientDropoffsReportQuery, Operation.PatientDropoffsReportQueryVariables>(PatientDropoffsReportDocument, options);
      }
export function usePatientDropoffsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientDropoffsReportQuery, Operation.PatientDropoffsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientDropoffsReportQuery, Operation.PatientDropoffsReportQueryVariables>(PatientDropoffsReportDocument, options);
        }
export function usePatientDropoffsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientDropoffsReportQuery, Operation.PatientDropoffsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientDropoffsReportQuery, Operation.PatientDropoffsReportQueryVariables>(PatientDropoffsReportDocument, options);
        }
export type PatientDropoffsReportQueryHookResult = ReturnType<typeof usePatientDropoffsReportQuery>;
export type PatientDropoffsReportLazyQueryHookResult = ReturnType<typeof usePatientDropoffsReportLazyQuery>;
export type PatientDropoffsReportSuspenseQueryHookResult = ReturnType<typeof usePatientDropoffsReportSuspenseQuery>;
export type PatientDropoffsReportQueryResult = Apollo.QueryResult<Operation.PatientDropoffsReportQuery, Operation.PatientDropoffsReportQueryVariables>;
export const PatientRecallsDueReportDocument = gql`
    query PatientRecallsDueReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: patient_recalls_due
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __usePatientRecallsDueReportQuery__
 *
 * To run a query within a React component, call `usePatientRecallsDueReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientRecallsDueReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientRecallsDueReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function usePatientRecallsDueReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientRecallsDueReportQuery, Operation.PatientRecallsDueReportQueryVariables> & ({ variables: Operation.PatientRecallsDueReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientRecallsDueReportQuery, Operation.PatientRecallsDueReportQueryVariables>(PatientRecallsDueReportDocument, options);
      }
export function usePatientRecallsDueReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientRecallsDueReportQuery, Operation.PatientRecallsDueReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientRecallsDueReportQuery, Operation.PatientRecallsDueReportQueryVariables>(PatientRecallsDueReportDocument, options);
        }
export function usePatientRecallsDueReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientRecallsDueReportQuery, Operation.PatientRecallsDueReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientRecallsDueReportQuery, Operation.PatientRecallsDueReportQueryVariables>(PatientRecallsDueReportDocument, options);
        }
export type PatientRecallsDueReportQueryHookResult = ReturnType<typeof usePatientRecallsDueReportQuery>;
export type PatientRecallsDueReportLazyQueryHookResult = ReturnType<typeof usePatientRecallsDueReportLazyQuery>;
export type PatientRecallsDueReportSuspenseQueryHookResult = ReturnType<typeof usePatientRecallsDueReportSuspenseQuery>;
export type PatientRecallsDueReportQueryResult = Apollo.QueryResult<Operation.PatientRecallsDueReportQuery, Operation.PatientRecallsDueReportQueryVariables>;
export const PatientRecallsReportDocument = gql`
    query PatientRecallsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: patient_recalls
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __usePatientRecallsReportQuery__
 *
 * To run a query within a React component, call `usePatientRecallsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientRecallsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientRecallsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function usePatientRecallsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientRecallsReportQuery, Operation.PatientRecallsReportQueryVariables> & ({ variables: Operation.PatientRecallsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientRecallsReportQuery, Operation.PatientRecallsReportQueryVariables>(PatientRecallsReportDocument, options);
      }
export function usePatientRecallsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientRecallsReportQuery, Operation.PatientRecallsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientRecallsReportQuery, Operation.PatientRecallsReportQueryVariables>(PatientRecallsReportDocument, options);
        }
export function usePatientRecallsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientRecallsReportQuery, Operation.PatientRecallsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientRecallsReportQuery, Operation.PatientRecallsReportQueryVariables>(PatientRecallsReportDocument, options);
        }
export type PatientRecallsReportQueryHookResult = ReturnType<typeof usePatientRecallsReportQuery>;
export type PatientRecallsReportLazyQueryHookResult = ReturnType<typeof usePatientRecallsReportLazyQuery>;
export type PatientRecallsReportSuspenseQueryHookResult = ReturnType<typeof usePatientRecallsReportSuspenseQuery>;
export type PatientRecallsReportQueryResult = Apollo.QueryResult<Operation.PatientRecallsReportQuery, Operation.PatientRecallsReportQueryVariables>;
export const PatientRequestsReportDocument = gql`
    query PatientRequestsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: deposit_requests
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __usePatientRequestsReportQuery__
 *
 * To run a query within a React component, call `usePatientRequestsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientRequestsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientRequestsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function usePatientRequestsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientRequestsReportQuery, Operation.PatientRequestsReportQueryVariables> & ({ variables: Operation.PatientRequestsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientRequestsReportQuery, Operation.PatientRequestsReportQueryVariables>(PatientRequestsReportDocument, options);
      }
export function usePatientRequestsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientRequestsReportQuery, Operation.PatientRequestsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientRequestsReportQuery, Operation.PatientRequestsReportQueryVariables>(PatientRequestsReportDocument, options);
        }
export function usePatientRequestsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientRequestsReportQuery, Operation.PatientRequestsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientRequestsReportQuery, Operation.PatientRequestsReportQueryVariables>(PatientRequestsReportDocument, options);
        }
export type PatientRequestsReportQueryHookResult = ReturnType<typeof usePatientRequestsReportQuery>;
export type PatientRequestsReportLazyQueryHookResult = ReturnType<typeof usePatientRequestsReportLazyQuery>;
export type PatientRequestsReportSuspenseQueryHookResult = ReturnType<typeof usePatientRequestsReportSuspenseQuery>;
export type PatientRequestsReportQueryResult = Apollo.QueryResult<Operation.PatientRequestsReportQuery, Operation.PatientRequestsReportQueryVariables>;
export const PatientNewPatientsKpiReportDocument = gql`
    query PatientNewPatientsKpiReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: patient_new_patients_kpi
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __usePatientNewPatientsKpiReportQuery__
 *
 * To run a query within a React component, call `usePatientNewPatientsKpiReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientNewPatientsKpiReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientNewPatientsKpiReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function usePatientNewPatientsKpiReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientNewPatientsKpiReportQuery, Operation.PatientNewPatientsKpiReportQueryVariables> & ({ variables: Operation.PatientNewPatientsKpiReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientNewPatientsKpiReportQuery, Operation.PatientNewPatientsKpiReportQueryVariables>(PatientNewPatientsKpiReportDocument, options);
      }
export function usePatientNewPatientsKpiReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientNewPatientsKpiReportQuery, Operation.PatientNewPatientsKpiReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientNewPatientsKpiReportQuery, Operation.PatientNewPatientsKpiReportQueryVariables>(PatientNewPatientsKpiReportDocument, options);
        }
export function usePatientNewPatientsKpiReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientNewPatientsKpiReportQuery, Operation.PatientNewPatientsKpiReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientNewPatientsKpiReportQuery, Operation.PatientNewPatientsKpiReportQueryVariables>(PatientNewPatientsKpiReportDocument, options);
        }
export type PatientNewPatientsKpiReportQueryHookResult = ReturnType<typeof usePatientNewPatientsKpiReportQuery>;
export type PatientNewPatientsKpiReportLazyQueryHookResult = ReturnType<typeof usePatientNewPatientsKpiReportLazyQuery>;
export type PatientNewPatientsKpiReportSuspenseQueryHookResult = ReturnType<typeof usePatientNewPatientsKpiReportSuspenseQuery>;
export type PatientNewPatientsKpiReportQueryResult = Apollo.QueryResult<Operation.PatientNewPatientsKpiReportQuery, Operation.PatientNewPatientsKpiReportQueryVariables>;
export const PatientBirthdaysReportDocument = gql`
    query PatientBirthdaysReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: patient_birthdays
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __usePatientBirthdaysReportQuery__
 *
 * To run a query within a React component, call `usePatientBirthdaysReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientBirthdaysReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientBirthdaysReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function usePatientBirthdaysReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientBirthdaysReportQuery, Operation.PatientBirthdaysReportQueryVariables> & ({ variables: Operation.PatientBirthdaysReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientBirthdaysReportQuery, Operation.PatientBirthdaysReportQueryVariables>(PatientBirthdaysReportDocument, options);
      }
export function usePatientBirthdaysReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientBirthdaysReportQuery, Operation.PatientBirthdaysReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientBirthdaysReportQuery, Operation.PatientBirthdaysReportQueryVariables>(PatientBirthdaysReportDocument, options);
        }
export function usePatientBirthdaysReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientBirthdaysReportQuery, Operation.PatientBirthdaysReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientBirthdaysReportQuery, Operation.PatientBirthdaysReportQueryVariables>(PatientBirthdaysReportDocument, options);
        }
export type PatientBirthdaysReportQueryHookResult = ReturnType<typeof usePatientBirthdaysReportQuery>;
export type PatientBirthdaysReportLazyQueryHookResult = ReturnType<typeof usePatientBirthdaysReportLazyQuery>;
export type PatientBirthdaysReportSuspenseQueryHookResult = ReturnType<typeof usePatientBirthdaysReportSuspenseQuery>;
export type PatientBirthdaysReportQueryResult = Apollo.QueryResult<Operation.PatientBirthdaysReportQuery, Operation.PatientBirthdaysReportQueryVariables>;
export const ContactsReportDocument = gql`
    query ContactsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: contacts
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useContactsReportQuery__
 *
 * To run a query within a React component, call `useContactsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useContactsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.ContactsReportQuery, Operation.ContactsReportQueryVariables> & ({ variables: Operation.ContactsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ContactsReportQuery, Operation.ContactsReportQueryVariables>(ContactsReportDocument, options);
      }
export function useContactsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ContactsReportQuery, Operation.ContactsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ContactsReportQuery, Operation.ContactsReportQueryVariables>(ContactsReportDocument, options);
        }
export function useContactsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ContactsReportQuery, Operation.ContactsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ContactsReportQuery, Operation.ContactsReportQueryVariables>(ContactsReportDocument, options);
        }
export type ContactsReportQueryHookResult = ReturnType<typeof useContactsReportQuery>;
export type ContactsReportLazyQueryHookResult = ReturnType<typeof useContactsReportLazyQuery>;
export type ContactsReportSuspenseQueryHookResult = ReturnType<typeof useContactsReportSuspenseQuery>;
export type ContactsReportQueryResult = Apollo.QueryResult<Operation.ContactsReportQuery, Operation.ContactsReportQueryVariables>;
export const CommunicationEmailsReportDocument = gql`
    query CommunicationEmailsReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: communication_emails
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useCommunicationEmailsReportQuery__
 *
 * To run a query within a React component, call `useCommunicationEmailsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunicationEmailsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunicationEmailsReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useCommunicationEmailsReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.CommunicationEmailsReportQuery, Operation.CommunicationEmailsReportQueryVariables> & ({ variables: Operation.CommunicationEmailsReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CommunicationEmailsReportQuery, Operation.CommunicationEmailsReportQueryVariables>(CommunicationEmailsReportDocument, options);
      }
export function useCommunicationEmailsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CommunicationEmailsReportQuery, Operation.CommunicationEmailsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CommunicationEmailsReportQuery, Operation.CommunicationEmailsReportQueryVariables>(CommunicationEmailsReportDocument, options);
        }
export function useCommunicationEmailsReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CommunicationEmailsReportQuery, Operation.CommunicationEmailsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CommunicationEmailsReportQuery, Operation.CommunicationEmailsReportQueryVariables>(CommunicationEmailsReportDocument, options);
        }
export type CommunicationEmailsReportQueryHookResult = ReturnType<typeof useCommunicationEmailsReportQuery>;
export type CommunicationEmailsReportLazyQueryHookResult = ReturnType<typeof useCommunicationEmailsReportLazyQuery>;
export type CommunicationEmailsReportSuspenseQueryHookResult = ReturnType<typeof useCommunicationEmailsReportSuspenseQuery>;
export type CommunicationEmailsReportQueryResult = Apollo.QueryResult<Operation.CommunicationEmailsReportQuery, Operation.CommunicationEmailsReportQueryVariables>;
export const CommunicationShortMessagesReportDocument = gql`
    query CommunicationShortMessagesReport($byPracticeLocations: [ID!]!, $byDateRange: DateRangeInput!) {
  reports(
    reportType: communication_short_messages
    byPracticeLocations: $byPracticeLocations
    byDateRange: $byDateRange
  ) {
    id
    reportType
    data
    errors
  }
}
    `;

/**
 * __useCommunicationShortMessagesReportQuery__
 *
 * To run a query within a React component, call `useCommunicationShortMessagesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunicationShortMessagesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunicationShortMessagesReportQuery({
 *   variables: {
 *      byPracticeLocations: // value for 'byPracticeLocations'
 *      byDateRange: // value for 'byDateRange'
 *   },
 * });
 */
export function useCommunicationShortMessagesReportQuery(baseOptions: Apollo.QueryHookOptions<Operation.CommunicationShortMessagesReportQuery, Operation.CommunicationShortMessagesReportQueryVariables> & ({ variables: Operation.CommunicationShortMessagesReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CommunicationShortMessagesReportQuery, Operation.CommunicationShortMessagesReportQueryVariables>(CommunicationShortMessagesReportDocument, options);
      }
export function useCommunicationShortMessagesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CommunicationShortMessagesReportQuery, Operation.CommunicationShortMessagesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CommunicationShortMessagesReportQuery, Operation.CommunicationShortMessagesReportQueryVariables>(CommunicationShortMessagesReportDocument, options);
        }
export function useCommunicationShortMessagesReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CommunicationShortMessagesReportQuery, Operation.CommunicationShortMessagesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CommunicationShortMessagesReportQuery, Operation.CommunicationShortMessagesReportQueryVariables>(CommunicationShortMessagesReportDocument, options);
        }
export type CommunicationShortMessagesReportQueryHookResult = ReturnType<typeof useCommunicationShortMessagesReportQuery>;
export type CommunicationShortMessagesReportLazyQueryHookResult = ReturnType<typeof useCommunicationShortMessagesReportLazyQuery>;
export type CommunicationShortMessagesReportSuspenseQueryHookResult = ReturnType<typeof useCommunicationShortMessagesReportSuspenseQuery>;
export type CommunicationShortMessagesReportQueryResult = Apollo.QueryResult<Operation.CommunicationShortMessagesReportQuery, Operation.CommunicationShortMessagesReportQueryVariables>;
export const SavedReportsEssentialDocument = gql`
    query SavedReportsEssential($first: Int, $after: String, $before: String, $archived: Boolean, $reportType: ReportType) {
  reportsSavedReports(
    first: $first
    after: $after
    before: $before
    archived: $archived
    reportType: $reportType
  ) {
    edges {
      cursor
      node {
        ...reportsSavedReportEssentials
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${ReportsSavedReportEssentialsFragmentDoc}`;

/**
 * __useSavedReportsEssentialQuery__
 *
 * To run a query within a React component, call `useSavedReportsEssentialQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedReportsEssentialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedReportsEssentialQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      archived: // value for 'archived'
 *      reportType: // value for 'reportType'
 *   },
 * });
 */
export function useSavedReportsEssentialQuery(baseOptions?: Apollo.QueryHookOptions<Operation.SavedReportsEssentialQuery, Operation.SavedReportsEssentialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.SavedReportsEssentialQuery, Operation.SavedReportsEssentialQueryVariables>(SavedReportsEssentialDocument, options);
      }
export function useSavedReportsEssentialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.SavedReportsEssentialQuery, Operation.SavedReportsEssentialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.SavedReportsEssentialQuery, Operation.SavedReportsEssentialQueryVariables>(SavedReportsEssentialDocument, options);
        }
export function useSavedReportsEssentialSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.SavedReportsEssentialQuery, Operation.SavedReportsEssentialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.SavedReportsEssentialQuery, Operation.SavedReportsEssentialQueryVariables>(SavedReportsEssentialDocument, options);
        }
export type SavedReportsEssentialQueryHookResult = ReturnType<typeof useSavedReportsEssentialQuery>;
export type SavedReportsEssentialLazyQueryHookResult = ReturnType<typeof useSavedReportsEssentialLazyQuery>;
export type SavedReportsEssentialSuspenseQueryHookResult = ReturnType<typeof useSavedReportsEssentialSuspenseQuery>;
export type SavedReportsEssentialQueryResult = Apollo.QueryResult<Operation.SavedReportsEssentialQuery, Operation.SavedReportsEssentialQueryVariables>;
export const RosterEventDocument = gql`
    query RosterEvent($id: ID!) {
  rosterEvent(id: $id) {
    ...rosterEventExhaustive
  }
}
    ${RosterEventExhaustiveFragmentDoc}`;

/**
 * __useRosterEventQuery__
 *
 * To run a query within a React component, call `useRosterEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useRosterEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRosterEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRosterEventQuery(baseOptions: Apollo.QueryHookOptions<Operation.RosterEventQuery, Operation.RosterEventQueryVariables> & ({ variables: Operation.RosterEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RosterEventQuery, Operation.RosterEventQueryVariables>(RosterEventDocument, options);
      }
export function useRosterEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RosterEventQuery, Operation.RosterEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RosterEventQuery, Operation.RosterEventQueryVariables>(RosterEventDocument, options);
        }
export function useRosterEventSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RosterEventQuery, Operation.RosterEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RosterEventQuery, Operation.RosterEventQueryVariables>(RosterEventDocument, options);
        }
export type RosterEventQueryHookResult = ReturnType<typeof useRosterEventQuery>;
export type RosterEventLazyQueryHookResult = ReturnType<typeof useRosterEventLazyQuery>;
export type RosterEventSuspenseQueryHookResult = ReturnType<typeof useRosterEventSuspenseQuery>;
export type RosterEventQueryResult = Apollo.QueryResult<Operation.RosterEventQuery, Operation.RosterEventQueryVariables>;
export const ShiftTypesDocument = gql`
    query ShiftTypes($byState: ShiftTypeState, $ids: [ID!], $excludeBlocked: Boolean) {
  rosterShiftTypes(byState: $byState, ids: $ids, excludeBlocked: $excludeBlocked) {
    pageInfo {
      ...pageInfo
    }
    edges {
      cursor
      node {
        ...rosterShiftTypeExhaustive
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${RosterShiftTypeExhaustiveFragmentDoc}`;

/**
 * __useShiftTypesQuery__
 *
 * To run a query within a React component, call `useShiftTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftTypesQuery({
 *   variables: {
 *      byState: // value for 'byState'
 *      ids: // value for 'ids'
 *      excludeBlocked: // value for 'excludeBlocked'
 *   },
 * });
 */
export function useShiftTypesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.ShiftTypesQuery, Operation.ShiftTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ShiftTypesQuery, Operation.ShiftTypesQueryVariables>(ShiftTypesDocument, options);
      }
export function useShiftTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ShiftTypesQuery, Operation.ShiftTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ShiftTypesQuery, Operation.ShiftTypesQueryVariables>(ShiftTypesDocument, options);
        }
export function useShiftTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ShiftTypesQuery, Operation.ShiftTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ShiftTypesQuery, Operation.ShiftTypesQueryVariables>(ShiftTypesDocument, options);
        }
export type ShiftTypesQueryHookResult = ReturnType<typeof useShiftTypesQuery>;
export type ShiftTypesLazyQueryHookResult = ReturnType<typeof useShiftTypesLazyQuery>;
export type ShiftTypesSuspenseQueryHookResult = ReturnType<typeof useShiftTypesSuspenseQuery>;
export type ShiftTypesQueryResult = Apollo.QueryResult<Operation.ShiftTypesQuery, Operation.ShiftTypesQueryVariables>;
export const AutoclavesMinimalDocument = gql`
    query AutoclavesMinimal($first: Int, $after: String, $before: String, $search: String, $archived: Boolean, $excludeIds: [String!]) {
  sterilisationAutoclaves(
    first: $first
    after: $after
    before: $before
    search: $search
    archived: $archived
    excludeIds: $excludeIds
  ) {
    edges {
      cursor
      node {
        ...sterilisationAutoclaveMinimal
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${SterilisationAutoclaveMinimalFragmentDoc}`;

/**
 * __useAutoclavesMinimalQuery__
 *
 * To run a query within a React component, call `useAutoclavesMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoclavesMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoclavesMinimalQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      archived: // value for 'archived'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useAutoclavesMinimalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AutoclavesMinimalQuery, Operation.AutoclavesMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AutoclavesMinimalQuery, Operation.AutoclavesMinimalQueryVariables>(AutoclavesMinimalDocument, options);
      }
export function useAutoclavesMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AutoclavesMinimalQuery, Operation.AutoclavesMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AutoclavesMinimalQuery, Operation.AutoclavesMinimalQueryVariables>(AutoclavesMinimalDocument, options);
        }
export function useAutoclavesMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AutoclavesMinimalQuery, Operation.AutoclavesMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AutoclavesMinimalQuery, Operation.AutoclavesMinimalQueryVariables>(AutoclavesMinimalDocument, options);
        }
export type AutoclavesMinimalQueryHookResult = ReturnType<typeof useAutoclavesMinimalQuery>;
export type AutoclavesMinimalLazyQueryHookResult = ReturnType<typeof useAutoclavesMinimalLazyQuery>;
export type AutoclavesMinimalSuspenseQueryHookResult = ReturnType<typeof useAutoclavesMinimalSuspenseQuery>;
export type AutoclavesMinimalQueryResult = Apollo.QueryResult<Operation.AutoclavesMinimalQuery, Operation.AutoclavesMinimalQueryVariables>;
export const AutoclavesEssentialsDocument = gql`
    query AutoclavesEssentials($first: Int, $after: String, $before: String, $search: String, $archived: Boolean, $excludeIds: [String!]) {
  sterilisationAutoclaves(
    first: $first
    after: $after
    before: $before
    search: $search
    archived: $archived
    excludeIds: $excludeIds
  ) {
    edges {
      cursor
      node {
        ...sterilisationAutoclaveEssentials
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${SterilisationAutoclaveEssentialsFragmentDoc}`;

/**
 * __useAutoclavesEssentialsQuery__
 *
 * To run a query within a React component, call `useAutoclavesEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoclavesEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoclavesEssentialsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      archived: // value for 'archived'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useAutoclavesEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AutoclavesEssentialsQuery, Operation.AutoclavesEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AutoclavesEssentialsQuery, Operation.AutoclavesEssentialsQueryVariables>(AutoclavesEssentialsDocument, options);
      }
export function useAutoclavesEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AutoclavesEssentialsQuery, Operation.AutoclavesEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AutoclavesEssentialsQuery, Operation.AutoclavesEssentialsQueryVariables>(AutoclavesEssentialsDocument, options);
        }
export function useAutoclavesEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AutoclavesEssentialsQuery, Operation.AutoclavesEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AutoclavesEssentialsQuery, Operation.AutoclavesEssentialsQueryVariables>(AutoclavesEssentialsDocument, options);
        }
export type AutoclavesEssentialsQueryHookResult = ReturnType<typeof useAutoclavesEssentialsQuery>;
export type AutoclavesEssentialsLazyQueryHookResult = ReturnType<typeof useAutoclavesEssentialsLazyQuery>;
export type AutoclavesEssentialsSuspenseQueryHookResult = ReturnType<typeof useAutoclavesEssentialsSuspenseQuery>;
export type AutoclavesEssentialsQueryResult = Apollo.QueryResult<Operation.AutoclavesEssentialsQuery, Operation.AutoclavesEssentialsQueryVariables>;
export const AutoclaveEssentialsDocument = gql`
    query AutoclaveEssentials($id: String!) {
  sterilisationAutoclave(id: $id) {
    ...sterilisationAutoclaveEssentials
  }
}
    ${SterilisationAutoclaveEssentialsFragmentDoc}`;

/**
 * __useAutoclaveEssentialsQuery__
 *
 * To run a query within a React component, call `useAutoclaveEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoclaveEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoclaveEssentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAutoclaveEssentialsQuery(baseOptions: Apollo.QueryHookOptions<Operation.AutoclaveEssentialsQuery, Operation.AutoclaveEssentialsQueryVariables> & ({ variables: Operation.AutoclaveEssentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AutoclaveEssentialsQuery, Operation.AutoclaveEssentialsQueryVariables>(AutoclaveEssentialsDocument, options);
      }
export function useAutoclaveEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AutoclaveEssentialsQuery, Operation.AutoclaveEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AutoclaveEssentialsQuery, Operation.AutoclaveEssentialsQueryVariables>(AutoclaveEssentialsDocument, options);
        }
export function useAutoclaveEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AutoclaveEssentialsQuery, Operation.AutoclaveEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AutoclaveEssentialsQuery, Operation.AutoclaveEssentialsQueryVariables>(AutoclaveEssentialsDocument, options);
        }
export type AutoclaveEssentialsQueryHookResult = ReturnType<typeof useAutoclaveEssentialsQuery>;
export type AutoclaveEssentialsLazyQueryHookResult = ReturnType<typeof useAutoclaveEssentialsLazyQuery>;
export type AutoclaveEssentialsSuspenseQueryHookResult = ReturnType<typeof useAutoclaveEssentialsSuspenseQuery>;
export type AutoclaveEssentialsQueryResult = Apollo.QueryResult<Operation.AutoclaveEssentialsQuery, Operation.AutoclaveEssentialsQueryVariables>;
export const AutoclaveDetailedDocument = gql`
    query AutoclaveDetailed($id: String!) {
  sterilisationAutoclave(id: $id) {
    ...sterilisationAutoclaveDetailed
  }
}
    ${SterilisationAutoclaveDetailedFragmentDoc}`;

/**
 * __useAutoclaveDetailedQuery__
 *
 * To run a query within a React component, call `useAutoclaveDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoclaveDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoclaveDetailedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAutoclaveDetailedQuery(baseOptions: Apollo.QueryHookOptions<Operation.AutoclaveDetailedQuery, Operation.AutoclaveDetailedQueryVariables> & ({ variables: Operation.AutoclaveDetailedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AutoclaveDetailedQuery, Operation.AutoclaveDetailedQueryVariables>(AutoclaveDetailedDocument, options);
      }
export function useAutoclaveDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AutoclaveDetailedQuery, Operation.AutoclaveDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AutoclaveDetailedQuery, Operation.AutoclaveDetailedQueryVariables>(AutoclaveDetailedDocument, options);
        }
export function useAutoclaveDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AutoclaveDetailedQuery, Operation.AutoclaveDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AutoclaveDetailedQuery, Operation.AutoclaveDetailedQueryVariables>(AutoclaveDetailedDocument, options);
        }
export type AutoclaveDetailedQueryHookResult = ReturnType<typeof useAutoclaveDetailedQuery>;
export type AutoclaveDetailedLazyQueryHookResult = ReturnType<typeof useAutoclaveDetailedLazyQuery>;
export type AutoclaveDetailedSuspenseQueryHookResult = ReturnType<typeof useAutoclaveDetailedSuspenseQuery>;
export type AutoclaveDetailedQueryResult = Apollo.QueryResult<Operation.AutoclaveDetailedQuery, Operation.AutoclaveDetailedQueryVariables>;
export const CycleTypesMinimalDocument = gql`
    query CycleTypesMinimal($first: Int, $after: String, $before: String, $search: String, $archived: Boolean, $excludeIds: [String!]) {
  sterilisationCycleTypes(
    first: $first
    after: $after
    before: $before
    search: $search
    archived: $archived
    excludeIds: $excludeIds
  ) {
    edges {
      cursor
      node {
        ...sterilisationCycleTypeMinimal
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${SterilisationCycleTypeMinimalFragmentDoc}`;

/**
 * __useCycleTypesMinimalQuery__
 *
 * To run a query within a React component, call `useCycleTypesMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCycleTypesMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCycleTypesMinimalQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      archived: // value for 'archived'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useCycleTypesMinimalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CycleTypesMinimalQuery, Operation.CycleTypesMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CycleTypesMinimalQuery, Operation.CycleTypesMinimalQueryVariables>(CycleTypesMinimalDocument, options);
      }
export function useCycleTypesMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CycleTypesMinimalQuery, Operation.CycleTypesMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CycleTypesMinimalQuery, Operation.CycleTypesMinimalQueryVariables>(CycleTypesMinimalDocument, options);
        }
export function useCycleTypesMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CycleTypesMinimalQuery, Operation.CycleTypesMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CycleTypesMinimalQuery, Operation.CycleTypesMinimalQueryVariables>(CycleTypesMinimalDocument, options);
        }
export type CycleTypesMinimalQueryHookResult = ReturnType<typeof useCycleTypesMinimalQuery>;
export type CycleTypesMinimalLazyQueryHookResult = ReturnType<typeof useCycleTypesMinimalLazyQuery>;
export type CycleTypesMinimalSuspenseQueryHookResult = ReturnType<typeof useCycleTypesMinimalSuspenseQuery>;
export type CycleTypesMinimalQueryResult = Apollo.QueryResult<Operation.CycleTypesMinimalQuery, Operation.CycleTypesMinimalQueryVariables>;
export const CycleTypesEssentialsDocument = gql`
    query CycleTypesEssentials($first: Int, $after: String, $before: String, $search: String, $archived: Boolean, $excludeIds: [String!]) {
  sterilisationCycleTypes(
    first: $first
    after: $after
    before: $before
    search: $search
    archived: $archived
    excludeIds: $excludeIds
  ) {
    edges {
      cursor
      node {
        ...sterilisationCycleTypeEssentials
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${SterilisationCycleTypeEssentialsFragmentDoc}`;

/**
 * __useCycleTypesEssentialsQuery__
 *
 * To run a query within a React component, call `useCycleTypesEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCycleTypesEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCycleTypesEssentialsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      archived: // value for 'archived'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useCycleTypesEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CycleTypesEssentialsQuery, Operation.CycleTypesEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CycleTypesEssentialsQuery, Operation.CycleTypesEssentialsQueryVariables>(CycleTypesEssentialsDocument, options);
      }
export function useCycleTypesEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CycleTypesEssentialsQuery, Operation.CycleTypesEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CycleTypesEssentialsQuery, Operation.CycleTypesEssentialsQueryVariables>(CycleTypesEssentialsDocument, options);
        }
export function useCycleTypesEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CycleTypesEssentialsQuery, Operation.CycleTypesEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CycleTypesEssentialsQuery, Operation.CycleTypesEssentialsQueryVariables>(CycleTypesEssentialsDocument, options);
        }
export type CycleTypesEssentialsQueryHookResult = ReturnType<typeof useCycleTypesEssentialsQuery>;
export type CycleTypesEssentialsLazyQueryHookResult = ReturnType<typeof useCycleTypesEssentialsLazyQuery>;
export type CycleTypesEssentialsSuspenseQueryHookResult = ReturnType<typeof useCycleTypesEssentialsSuspenseQuery>;
export type CycleTypesEssentialsQueryResult = Apollo.QueryResult<Operation.CycleTypesEssentialsQuery, Operation.CycleTypesEssentialsQueryVariables>;
export const CycleTypeEssentialsDocument = gql`
    query CycleTypeEssentials($id: String!) {
  sterilisationCycleType(id: $id) {
    ...sterilisationCycleTypeEssentials
  }
}
    ${SterilisationCycleTypeEssentialsFragmentDoc}`;

/**
 * __useCycleTypeEssentialsQuery__
 *
 * To run a query within a React component, call `useCycleTypeEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCycleTypeEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCycleTypeEssentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCycleTypeEssentialsQuery(baseOptions: Apollo.QueryHookOptions<Operation.CycleTypeEssentialsQuery, Operation.CycleTypeEssentialsQueryVariables> & ({ variables: Operation.CycleTypeEssentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CycleTypeEssentialsQuery, Operation.CycleTypeEssentialsQueryVariables>(CycleTypeEssentialsDocument, options);
      }
export function useCycleTypeEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CycleTypeEssentialsQuery, Operation.CycleTypeEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CycleTypeEssentialsQuery, Operation.CycleTypeEssentialsQueryVariables>(CycleTypeEssentialsDocument, options);
        }
export function useCycleTypeEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CycleTypeEssentialsQuery, Operation.CycleTypeEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CycleTypeEssentialsQuery, Operation.CycleTypeEssentialsQueryVariables>(CycleTypeEssentialsDocument, options);
        }
export type CycleTypeEssentialsQueryHookResult = ReturnType<typeof useCycleTypeEssentialsQuery>;
export type CycleTypeEssentialsLazyQueryHookResult = ReturnType<typeof useCycleTypeEssentialsLazyQuery>;
export type CycleTypeEssentialsSuspenseQueryHookResult = ReturnType<typeof useCycleTypeEssentialsSuspenseQuery>;
export type CycleTypeEssentialsQueryResult = Apollo.QueryResult<Operation.CycleTypeEssentialsQuery, Operation.CycleTypeEssentialsQueryVariables>;
export const CycleTypeDetailedDocument = gql`
    query CycleTypeDetailed($id: String!) {
  sterilisationCycleType(id: $id) {
    ...sterilisationCycleTypeDetailed
  }
}
    ${SterilisationCycleTypeDetailedFragmentDoc}`;

/**
 * __useCycleTypeDetailedQuery__
 *
 * To run a query within a React component, call `useCycleTypeDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCycleTypeDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCycleTypeDetailedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCycleTypeDetailedQuery(baseOptions: Apollo.QueryHookOptions<Operation.CycleTypeDetailedQuery, Operation.CycleTypeDetailedQueryVariables> & ({ variables: Operation.CycleTypeDetailedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CycleTypeDetailedQuery, Operation.CycleTypeDetailedQueryVariables>(CycleTypeDetailedDocument, options);
      }
export function useCycleTypeDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CycleTypeDetailedQuery, Operation.CycleTypeDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CycleTypeDetailedQuery, Operation.CycleTypeDetailedQueryVariables>(CycleTypeDetailedDocument, options);
        }
export function useCycleTypeDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CycleTypeDetailedQuery, Operation.CycleTypeDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CycleTypeDetailedQuery, Operation.CycleTypeDetailedQueryVariables>(CycleTypeDetailedDocument, options);
        }
export type CycleTypeDetailedQueryHookResult = ReturnType<typeof useCycleTypeDetailedQuery>;
export type CycleTypeDetailedLazyQueryHookResult = ReturnType<typeof useCycleTypeDetailedLazyQuery>;
export type CycleTypeDetailedSuspenseQueryHookResult = ReturnType<typeof useCycleTypeDetailedSuspenseQuery>;
export type CycleTypeDetailedQueryResult = Apollo.QueryResult<Operation.CycleTypeDetailedQuery, Operation.CycleTypeDetailedQueryVariables>;
export const CyclesEssentialsDocument = gql`
    query CyclesEssentials($first: Int, $after: String, $before: String, $search: String, $barcode: String, $autoclaveId: String, $date: ISO8601Date, $archived: Boolean, $excludeIds: [String!]) {
  sterilisationCycles(
    first: $first
    after: $after
    before: $before
    search: $search
    barcode: $barcode
    autoclaveId: $autoclaveId
    date: $date
    archived: $archived
    excludeIds: $excludeIds
  ) {
    edges {
      cursor
      node {
        ...sterilisationCycleEssentials
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${SterilisationCycleEssentialsFragmentDoc}`;

/**
 * __useCyclesEssentialsQuery__
 *
 * To run a query within a React component, call `useCyclesEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCyclesEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCyclesEssentialsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      barcode: // value for 'barcode'
 *      autoclaveId: // value for 'autoclaveId'
 *      date: // value for 'date'
 *      archived: // value for 'archived'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useCyclesEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.CyclesEssentialsQuery, Operation.CyclesEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CyclesEssentialsQuery, Operation.CyclesEssentialsQueryVariables>(CyclesEssentialsDocument, options);
      }
export function useCyclesEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CyclesEssentialsQuery, Operation.CyclesEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CyclesEssentialsQuery, Operation.CyclesEssentialsQueryVariables>(CyclesEssentialsDocument, options);
        }
export function useCyclesEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CyclesEssentialsQuery, Operation.CyclesEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CyclesEssentialsQuery, Operation.CyclesEssentialsQueryVariables>(CyclesEssentialsDocument, options);
        }
export type CyclesEssentialsQueryHookResult = ReturnType<typeof useCyclesEssentialsQuery>;
export type CyclesEssentialsLazyQueryHookResult = ReturnType<typeof useCyclesEssentialsLazyQuery>;
export type CyclesEssentialsSuspenseQueryHookResult = ReturnType<typeof useCyclesEssentialsSuspenseQuery>;
export type CyclesEssentialsQueryResult = Apollo.QueryResult<Operation.CyclesEssentialsQuery, Operation.CyclesEssentialsQueryVariables>;
export const CycleEssentialsDocument = gql`
    query CycleEssentials($id: String!) {
  sterilisationCycle(id: $id) {
    ...sterilisationCycleEssentials
  }
}
    ${SterilisationCycleEssentialsFragmentDoc}`;

/**
 * __useCycleEssentialsQuery__
 *
 * To run a query within a React component, call `useCycleEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCycleEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCycleEssentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCycleEssentialsQuery(baseOptions: Apollo.QueryHookOptions<Operation.CycleEssentialsQuery, Operation.CycleEssentialsQueryVariables> & ({ variables: Operation.CycleEssentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CycleEssentialsQuery, Operation.CycleEssentialsQueryVariables>(CycleEssentialsDocument, options);
      }
export function useCycleEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CycleEssentialsQuery, Operation.CycleEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CycleEssentialsQuery, Operation.CycleEssentialsQueryVariables>(CycleEssentialsDocument, options);
        }
export function useCycleEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CycleEssentialsQuery, Operation.CycleEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CycleEssentialsQuery, Operation.CycleEssentialsQueryVariables>(CycleEssentialsDocument, options);
        }
export type CycleEssentialsQueryHookResult = ReturnType<typeof useCycleEssentialsQuery>;
export type CycleEssentialsLazyQueryHookResult = ReturnType<typeof useCycleEssentialsLazyQuery>;
export type CycleEssentialsSuspenseQueryHookResult = ReturnType<typeof useCycleEssentialsSuspenseQuery>;
export type CycleEssentialsQueryResult = Apollo.QueryResult<Operation.CycleEssentialsQuery, Operation.CycleEssentialsQueryVariables>;
export const CycleDetailedDocument = gql`
    query CycleDetailed($id: String!) {
  sterilisationCycle(id: $id) {
    ...sterilisationCycleDetailed
  }
}
    ${SterilisationCycleDetailedFragmentDoc}`;

/**
 * __useCycleDetailedQuery__
 *
 * To run a query within a React component, call `useCycleDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCycleDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCycleDetailedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCycleDetailedQuery(baseOptions: Apollo.QueryHookOptions<Operation.CycleDetailedQuery, Operation.CycleDetailedQueryVariables> & ({ variables: Operation.CycleDetailedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.CycleDetailedQuery, Operation.CycleDetailedQueryVariables>(CycleDetailedDocument, options);
      }
export function useCycleDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.CycleDetailedQuery, Operation.CycleDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.CycleDetailedQuery, Operation.CycleDetailedQueryVariables>(CycleDetailedDocument, options);
        }
export function useCycleDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.CycleDetailedQuery, Operation.CycleDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.CycleDetailedQuery, Operation.CycleDetailedQueryVariables>(CycleDetailedDocument, options);
        }
export type CycleDetailedQueryHookResult = ReturnType<typeof useCycleDetailedQuery>;
export type CycleDetailedLazyQueryHookResult = ReturnType<typeof useCycleDetailedLazyQuery>;
export type CycleDetailedSuspenseQueryHookResult = ReturnType<typeof useCycleDetailedSuspenseQuery>;
export type CycleDetailedQueryResult = Apollo.QueryResult<Operation.CycleDetailedQuery, Operation.CycleDetailedQueryVariables>;
export const TrackingsEssentialsDocument = gql`
    query TrackingsEssentials($first: Int, $after: String, $before: String, $search: String, $archived: Boolean, $excludeIds: [String!], $patientGuid: String, $trackableUuid: String) {
  sterilisationTrackings(
    first: $first
    after: $after
    before: $before
    search: $search
    archived: $archived
    patientGuid: $patientGuid
    trackableUuid: $trackableUuid
    excludeIds: $excludeIds
  ) {
    edges {
      cursor
      node {
        ...sterilisationTrackingEssentials
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${SterilisationTrackingEssentialsFragmentDoc}`;

/**
 * __useTrackingsEssentialsQuery__
 *
 * To run a query within a React component, call `useTrackingsEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingsEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingsEssentialsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      archived: // value for 'archived'
 *      excludeIds: // value for 'excludeIds'
 *      patientGuid: // value for 'patientGuid'
 *      trackableUuid: // value for 'trackableUuid'
 *   },
 * });
 */
export function useTrackingsEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TrackingsEssentialsQuery, Operation.TrackingsEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TrackingsEssentialsQuery, Operation.TrackingsEssentialsQueryVariables>(TrackingsEssentialsDocument, options);
      }
export function useTrackingsEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TrackingsEssentialsQuery, Operation.TrackingsEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TrackingsEssentialsQuery, Operation.TrackingsEssentialsQueryVariables>(TrackingsEssentialsDocument, options);
        }
export function useTrackingsEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TrackingsEssentialsQuery, Operation.TrackingsEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TrackingsEssentialsQuery, Operation.TrackingsEssentialsQueryVariables>(TrackingsEssentialsDocument, options);
        }
export type TrackingsEssentialsQueryHookResult = ReturnType<typeof useTrackingsEssentialsQuery>;
export type TrackingsEssentialsLazyQueryHookResult = ReturnType<typeof useTrackingsEssentialsLazyQuery>;
export type TrackingsEssentialsSuspenseQueryHookResult = ReturnType<typeof useTrackingsEssentialsSuspenseQuery>;
export type TrackingsEssentialsQueryResult = Apollo.QueryResult<Operation.TrackingsEssentialsQuery, Operation.TrackingsEssentialsQueryVariables>;
export const TrackingEssentialsDocument = gql`
    query TrackingEssentials($id: String!) {
  sterilisationTracking(id: $id) {
    ...sterilisationTrackingEssentials
  }
}
    ${SterilisationTrackingEssentialsFragmentDoc}`;

/**
 * __useTrackingEssentialsQuery__
 *
 * To run a query within a React component, call `useTrackingEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingEssentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrackingEssentialsQuery(baseOptions: Apollo.QueryHookOptions<Operation.TrackingEssentialsQuery, Operation.TrackingEssentialsQueryVariables> & ({ variables: Operation.TrackingEssentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TrackingEssentialsQuery, Operation.TrackingEssentialsQueryVariables>(TrackingEssentialsDocument, options);
      }
export function useTrackingEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TrackingEssentialsQuery, Operation.TrackingEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TrackingEssentialsQuery, Operation.TrackingEssentialsQueryVariables>(TrackingEssentialsDocument, options);
        }
export function useTrackingEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TrackingEssentialsQuery, Operation.TrackingEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TrackingEssentialsQuery, Operation.TrackingEssentialsQueryVariables>(TrackingEssentialsDocument, options);
        }
export type TrackingEssentialsQueryHookResult = ReturnType<typeof useTrackingEssentialsQuery>;
export type TrackingEssentialsLazyQueryHookResult = ReturnType<typeof useTrackingEssentialsLazyQuery>;
export type TrackingEssentialsSuspenseQueryHookResult = ReturnType<typeof useTrackingEssentialsSuspenseQuery>;
export type TrackingEssentialsQueryResult = Apollo.QueryResult<Operation.TrackingEssentialsQuery, Operation.TrackingEssentialsQueryVariables>;
export const TrackingDetailedDocument = gql`
    query TrackingDetailed($id: String!) {
  sterilisationTracking(id: $id) {
    ...sterilisationTrackingDetailed
  }
}
    ${SterilisationTrackingDetailedFragmentDoc}`;

/**
 * __useTrackingDetailedQuery__
 *
 * To run a query within a React component, call `useTrackingDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingDetailedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrackingDetailedQuery(baseOptions: Apollo.QueryHookOptions<Operation.TrackingDetailedQuery, Operation.TrackingDetailedQueryVariables> & ({ variables: Operation.TrackingDetailedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TrackingDetailedQuery, Operation.TrackingDetailedQueryVariables>(TrackingDetailedDocument, options);
      }
export function useTrackingDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TrackingDetailedQuery, Operation.TrackingDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TrackingDetailedQuery, Operation.TrackingDetailedQueryVariables>(TrackingDetailedDocument, options);
        }
export function useTrackingDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TrackingDetailedQuery, Operation.TrackingDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TrackingDetailedQuery, Operation.TrackingDetailedQueryVariables>(TrackingDetailedDocument, options);
        }
export type TrackingDetailedQueryHookResult = ReturnType<typeof useTrackingDetailedQuery>;
export type TrackingDetailedLazyQueryHookResult = ReturnType<typeof useTrackingDetailedLazyQuery>;
export type TrackingDetailedSuspenseQueryHookResult = ReturnType<typeof useTrackingDetailedSuspenseQuery>;
export type TrackingDetailedQueryResult = Apollo.QueryResult<Operation.TrackingDetailedQuery, Operation.TrackingDetailedQueryVariables>;
export const TreatmentPlanAppointmentDocument = gql`
    query TreatmentPlanAppointment($id: ID, $slug: String) {
  appointment(id: $id, slug: $slug) {
    ...treatmentPlanAppointment
  }
}
    ${TreatmentPlanAppointmentFragmentDoc}`;

/**
 * __useTreatmentPlanAppointmentQuery__
 *
 * To run a query within a React component, call `useTreatmentPlanAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentPlanAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentPlanAppointmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTreatmentPlanAppointmentQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentPlanAppointmentQuery, Operation.TreatmentPlanAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentPlanAppointmentQuery, Operation.TreatmentPlanAppointmentQueryVariables>(TreatmentPlanAppointmentDocument, options);
      }
export function useTreatmentPlanAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentPlanAppointmentQuery, Operation.TreatmentPlanAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentPlanAppointmentQuery, Operation.TreatmentPlanAppointmentQueryVariables>(TreatmentPlanAppointmentDocument, options);
        }
export function useTreatmentPlanAppointmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentPlanAppointmentQuery, Operation.TreatmentPlanAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentPlanAppointmentQuery, Operation.TreatmentPlanAppointmentQueryVariables>(TreatmentPlanAppointmentDocument, options);
        }
export type TreatmentPlanAppointmentQueryHookResult = ReturnType<typeof useTreatmentPlanAppointmentQuery>;
export type TreatmentPlanAppointmentLazyQueryHookResult = ReturnType<typeof useTreatmentPlanAppointmentLazyQuery>;
export type TreatmentPlanAppointmentSuspenseQueryHookResult = ReturnType<typeof useTreatmentPlanAppointmentSuspenseQuery>;
export type TreatmentPlanAppointmentQueryResult = Apollo.QueryResult<Operation.TreatmentPlanAppointmentQuery, Operation.TreatmentPlanAppointmentQueryVariables>;
export const TreatmentDocument = gql`
    query Treatment($id: ID, $slug: String) {
  treatment(id: $id, slug: $slug) {
    ...treatmentDetailed
  }
}
    ${TreatmentDetailedFragmentDoc}`;

/**
 * __useTreatmentQuery__
 *
 * To run a query within a React component, call `useTreatmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTreatmentQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentQuery, Operation.TreatmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentQuery, Operation.TreatmentQueryVariables>(TreatmentDocument, options);
      }
export function useTreatmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentQuery, Operation.TreatmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentQuery, Operation.TreatmentQueryVariables>(TreatmentDocument, options);
        }
export function useTreatmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentQuery, Operation.TreatmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentQuery, Operation.TreatmentQueryVariables>(TreatmentDocument, options);
        }
export type TreatmentQueryHookResult = ReturnType<typeof useTreatmentQuery>;
export type TreatmentLazyQueryHookResult = ReturnType<typeof useTreatmentLazyQuery>;
export type TreatmentSuspenseQueryHookResult = ReturnType<typeof useTreatmentSuspenseQuery>;
export type TreatmentQueryResult = Apollo.QueryResult<Operation.TreatmentQuery, Operation.TreatmentQueryVariables>;
export const TreatmentMinimalDocument = gql`
    query TreatmentMinimal($id: ID, $slug: String) {
  treatment(id: $id, slug: $slug) {
    ...treatmentMinimal
  }
}
    ${TreatmentMinimalFragmentDoc}`;

/**
 * __useTreatmentMinimalQuery__
 *
 * To run a query within a React component, call `useTreatmentMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentMinimalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTreatmentMinimalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentMinimalQuery, Operation.TreatmentMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentMinimalQuery, Operation.TreatmentMinimalQueryVariables>(TreatmentMinimalDocument, options);
      }
export function useTreatmentMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentMinimalQuery, Operation.TreatmentMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentMinimalQuery, Operation.TreatmentMinimalQueryVariables>(TreatmentMinimalDocument, options);
        }
export function useTreatmentMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentMinimalQuery, Operation.TreatmentMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentMinimalQuery, Operation.TreatmentMinimalQueryVariables>(TreatmentMinimalDocument, options);
        }
export type TreatmentMinimalQueryHookResult = ReturnType<typeof useTreatmentMinimalQuery>;
export type TreatmentMinimalLazyQueryHookResult = ReturnType<typeof useTreatmentMinimalLazyQuery>;
export type TreatmentMinimalSuspenseQueryHookResult = ReturnType<typeof useTreatmentMinimalSuspenseQuery>;
export type TreatmentMinimalQueryResult = Apollo.QueryResult<Operation.TreatmentMinimalQuery, Operation.TreatmentMinimalQueryVariables>;
export const TreatmentPdfDocument = gql`
    query TreatmentPDF($id: ID, $guid: ID, $slug: String, $treatmentPlan: ID) {
  treatment(treatmentPlanGuid: $treatmentPlan, id: $id, guid: $guid, slug: $slug) {
    ...treatmentPDF
  }
}
    ${TreatmentPdfFragmentDoc}`;

/**
 * __useTreatmentPdfQuery__
 *
 * To run a query within a React component, call `useTreatmentPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentPdfQuery({
 *   variables: {
 *      id: // value for 'id'
 *      guid: // value for 'guid'
 *      slug: // value for 'slug'
 *      treatmentPlan: // value for 'treatmentPlan'
 *   },
 * });
 */
export function useTreatmentPdfQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentPdfQuery, Operation.TreatmentPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentPdfQuery, Operation.TreatmentPdfQueryVariables>(TreatmentPdfDocument, options);
      }
export function useTreatmentPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentPdfQuery, Operation.TreatmentPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentPdfQuery, Operation.TreatmentPdfQueryVariables>(TreatmentPdfDocument, options);
        }
export function useTreatmentPdfSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentPdfQuery, Operation.TreatmentPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentPdfQuery, Operation.TreatmentPdfQueryVariables>(TreatmentPdfDocument, options);
        }
export type TreatmentPdfQueryHookResult = ReturnType<typeof useTreatmentPdfQuery>;
export type TreatmentPdfLazyQueryHookResult = ReturnType<typeof useTreatmentPdfLazyQuery>;
export type TreatmentPdfSuspenseQueryHookResult = ReturnType<typeof useTreatmentPdfSuspenseQuery>;
export type TreatmentPdfQueryResult = Apollo.QueryResult<Operation.TreatmentPdfQuery, Operation.TreatmentPdfQueryVariables>;
export const TreatmentEssentialsDocument = gql`
    query TreatmentEssentials($id: ID, $slug: String) {
  treatment(id: $id, slug: $slug) {
    ...treatmentEssentials
  }
}
    ${TreatmentEssentialsFragmentDoc}`;

/**
 * __useTreatmentEssentialsQuery__
 *
 * To run a query within a React component, call `useTreatmentEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentEssentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTreatmentEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentEssentialsQuery, Operation.TreatmentEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentEssentialsQuery, Operation.TreatmentEssentialsQueryVariables>(TreatmentEssentialsDocument, options);
      }
export function useTreatmentEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentEssentialsQuery, Operation.TreatmentEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentEssentialsQuery, Operation.TreatmentEssentialsQueryVariables>(TreatmentEssentialsDocument, options);
        }
export function useTreatmentEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentEssentialsQuery, Operation.TreatmentEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentEssentialsQuery, Operation.TreatmentEssentialsQueryVariables>(TreatmentEssentialsDocument, options);
        }
export type TreatmentEssentialsQueryHookResult = ReturnType<typeof useTreatmentEssentialsQuery>;
export type TreatmentEssentialsLazyQueryHookResult = ReturnType<typeof useTreatmentEssentialsLazyQuery>;
export type TreatmentEssentialsSuspenseQueryHookResult = ReturnType<typeof useTreatmentEssentialsSuspenseQuery>;
export type TreatmentEssentialsQueryResult = Apollo.QueryResult<Operation.TreatmentEssentialsQuery, Operation.TreatmentEssentialsQueryVariables>;
export const TreatmentForDentalDocument = gql`
    query TreatmentForDental($id: ID, $slug: String) {
  treatment(id: $id, slug: $slug) {
    ...treatmentEssentials
    chartImage {
      url
      coverUrl
      previewUrl
    }
  }
}
    ${TreatmentEssentialsFragmentDoc}`;

/**
 * __useTreatmentForDentalQuery__
 *
 * To run a query within a React component, call `useTreatmentForDentalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentForDentalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentForDentalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTreatmentForDentalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentForDentalQuery, Operation.TreatmentForDentalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentForDentalQuery, Operation.TreatmentForDentalQueryVariables>(TreatmentForDentalDocument, options);
      }
export function useTreatmentForDentalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentForDentalQuery, Operation.TreatmentForDentalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentForDentalQuery, Operation.TreatmentForDentalQueryVariables>(TreatmentForDentalDocument, options);
        }
export function useTreatmentForDentalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentForDentalQuery, Operation.TreatmentForDentalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentForDentalQuery, Operation.TreatmentForDentalQueryVariables>(TreatmentForDentalDocument, options);
        }
export type TreatmentForDentalQueryHookResult = ReturnType<typeof useTreatmentForDentalQuery>;
export type TreatmentForDentalLazyQueryHookResult = ReturnType<typeof useTreatmentForDentalLazyQuery>;
export type TreatmentForDentalSuspenseQueryHookResult = ReturnType<typeof useTreatmentForDentalSuspenseQuery>;
export type TreatmentForDentalQueryResult = Apollo.QueryResult<Operation.TreatmentForDentalQuery, Operation.TreatmentForDentalQueryVariables>;
export const TreatmentAppointmentsDocument = gql`
    query TreatmentAppointments($attributes: CalendarSearchInput, $first: Int, $after: String) {
  treatmentAppointments(attributes: $attributes, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      node {
        ...appointmentList
      }
    }
  }
}
    ${AppointmentListFragmentDoc}`;

/**
 * __useTreatmentAppointmentsQuery__
 *
 * To run a query within a React component, call `useTreatmentAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentAppointmentsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTreatmentAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentAppointmentsQuery, Operation.TreatmentAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentAppointmentsQuery, Operation.TreatmentAppointmentsQueryVariables>(TreatmentAppointmentsDocument, options);
      }
export function useTreatmentAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentAppointmentsQuery, Operation.TreatmentAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentAppointmentsQuery, Operation.TreatmentAppointmentsQueryVariables>(TreatmentAppointmentsDocument, options);
        }
export function useTreatmentAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentAppointmentsQuery, Operation.TreatmentAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentAppointmentsQuery, Operation.TreatmentAppointmentsQueryVariables>(TreatmentAppointmentsDocument, options);
        }
export type TreatmentAppointmentsQueryHookResult = ReturnType<typeof useTreatmentAppointmentsQuery>;
export type TreatmentAppointmentsLazyQueryHookResult = ReturnType<typeof useTreatmentAppointmentsLazyQuery>;
export type TreatmentAppointmentsSuspenseQueryHookResult = ReturnType<typeof useTreatmentAppointmentsSuspenseQuery>;
export type TreatmentAppointmentsQueryResult = Apollo.QueryResult<Operation.TreatmentAppointmentsQuery, Operation.TreatmentAppointmentsQueryVariables>;
export const TreatmentPlanDocument = gql`
    query TreatmentPlan($id: ID, $slug: String) {
  treatmentPlan(id: $id, slug: $slug) {
    ...treatmentPlanDetailed
  }
}
    ${TreatmentPlanDetailedFragmentDoc}`;

/**
 * __useTreatmentPlanQuery__
 *
 * To run a query within a React component, call `useTreatmentPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTreatmentPlanQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentPlanQuery, Operation.TreatmentPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentPlanQuery, Operation.TreatmentPlanQueryVariables>(TreatmentPlanDocument, options);
      }
export function useTreatmentPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentPlanQuery, Operation.TreatmentPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentPlanQuery, Operation.TreatmentPlanQueryVariables>(TreatmentPlanDocument, options);
        }
export function useTreatmentPlanSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentPlanQuery, Operation.TreatmentPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentPlanQuery, Operation.TreatmentPlanQueryVariables>(TreatmentPlanDocument, options);
        }
export type TreatmentPlanQueryHookResult = ReturnType<typeof useTreatmentPlanQuery>;
export type TreatmentPlanLazyQueryHookResult = ReturnType<typeof useTreatmentPlanLazyQuery>;
export type TreatmentPlanSuspenseQueryHookResult = ReturnType<typeof useTreatmentPlanSuspenseQuery>;
export type TreatmentPlanQueryResult = Apollo.QueryResult<Operation.TreatmentPlanQuery, Operation.TreatmentPlanQueryVariables>;
export const TreatmentPlansMinimalDocument = gql`
    query TreatmentPlansMinimal($state: [TreatmentPlanState!], $patientIds: [ID!], $treatmentIds: [ID!], $orderBy: TreatmentPlanOrderInput, $first: Int) {
  treatmentPlans(
    state: $state
    patientIds: $patientIds
    treatmentIds: $treatmentIds
    orderBy: $orderBy
    first: $first
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
    edges {
      node {
        ...treatmentPlanMinimal
      }
    }
  }
}
    ${TreatmentPlanMinimalFragmentDoc}`;

/**
 * __useTreatmentPlansMinimalQuery__
 *
 * To run a query within a React component, call `useTreatmentPlansMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentPlansMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentPlansMinimalQuery({
 *   variables: {
 *      state: // value for 'state'
 *      patientIds: // value for 'patientIds'
 *      treatmentIds: // value for 'treatmentIds'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTreatmentPlansMinimalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentPlansMinimalQuery, Operation.TreatmentPlansMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentPlansMinimalQuery, Operation.TreatmentPlansMinimalQueryVariables>(TreatmentPlansMinimalDocument, options);
      }
export function useTreatmentPlansMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentPlansMinimalQuery, Operation.TreatmentPlansMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentPlansMinimalQuery, Operation.TreatmentPlansMinimalQueryVariables>(TreatmentPlansMinimalDocument, options);
        }
export function useTreatmentPlansMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentPlansMinimalQuery, Operation.TreatmentPlansMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentPlansMinimalQuery, Operation.TreatmentPlansMinimalQueryVariables>(TreatmentPlansMinimalDocument, options);
        }
export type TreatmentPlansMinimalQueryHookResult = ReturnType<typeof useTreatmentPlansMinimalQuery>;
export type TreatmentPlansMinimalLazyQueryHookResult = ReturnType<typeof useTreatmentPlansMinimalLazyQuery>;
export type TreatmentPlansMinimalSuspenseQueryHookResult = ReturnType<typeof useTreatmentPlansMinimalSuspenseQuery>;
export type TreatmentPlansMinimalQueryResult = Apollo.QueryResult<Operation.TreatmentPlansMinimalQuery, Operation.TreatmentPlansMinimalQueryVariables>;
export const TreatmentPlanEssentialsDocument = gql`
    query TreatmentPlanEssentials($id: ID, $slug: String) {
  treatmentPlan(id: $id, slug: $slug) {
    ...treatmentPlanEssentials
  }
}
    ${TreatmentPlanEssentialsFragmentDoc}`;

/**
 * __useTreatmentPlanEssentialsQuery__
 *
 * To run a query within a React component, call `useTreatmentPlanEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentPlanEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentPlanEssentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTreatmentPlanEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentPlanEssentialsQuery, Operation.TreatmentPlanEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentPlanEssentialsQuery, Operation.TreatmentPlanEssentialsQueryVariables>(TreatmentPlanEssentialsDocument, options);
      }
export function useTreatmentPlanEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentPlanEssentialsQuery, Operation.TreatmentPlanEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentPlanEssentialsQuery, Operation.TreatmentPlanEssentialsQueryVariables>(TreatmentPlanEssentialsDocument, options);
        }
export function useTreatmentPlanEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentPlanEssentialsQuery, Operation.TreatmentPlanEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentPlanEssentialsQuery, Operation.TreatmentPlanEssentialsQueryVariables>(TreatmentPlanEssentialsDocument, options);
        }
export type TreatmentPlanEssentialsQueryHookResult = ReturnType<typeof useTreatmentPlanEssentialsQuery>;
export type TreatmentPlanEssentialsLazyQueryHookResult = ReturnType<typeof useTreatmentPlanEssentialsLazyQuery>;
export type TreatmentPlanEssentialsSuspenseQueryHookResult = ReturnType<typeof useTreatmentPlanEssentialsSuspenseQuery>;
export type TreatmentPlanEssentialsQueryResult = Apollo.QueryResult<Operation.TreatmentPlanEssentialsQuery, Operation.TreatmentPlanEssentialsQueryVariables>;
export const TreatmentPlansDetailedDocument = gql`
    query TreatmentPlansDetailed($state: [TreatmentPlanState!], $patientIds: [ID!], $treatmentIds: [ID!]) {
  treatmentPlans(
    state: $state
    patientIds: $patientIds
    treatmentIds: $treatmentIds
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    totalCount
    edges {
      node {
        ...treatmentPlanDetailed
      }
    }
  }
}
    ${TreatmentPlanDetailedFragmentDoc}`;

/**
 * __useTreatmentPlansDetailedQuery__
 *
 * To run a query within a React component, call `useTreatmentPlansDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentPlansDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentPlansDetailedQuery({
 *   variables: {
 *      state: // value for 'state'
 *      patientIds: // value for 'patientIds'
 *      treatmentIds: // value for 'treatmentIds'
 *   },
 * });
 */
export function useTreatmentPlansDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentPlansDetailedQuery, Operation.TreatmentPlansDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentPlansDetailedQuery, Operation.TreatmentPlansDetailedQueryVariables>(TreatmentPlansDetailedDocument, options);
      }
export function useTreatmentPlansDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentPlansDetailedQuery, Operation.TreatmentPlansDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentPlansDetailedQuery, Operation.TreatmentPlansDetailedQueryVariables>(TreatmentPlansDetailedDocument, options);
        }
export function useTreatmentPlansDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentPlansDetailedQuery, Operation.TreatmentPlansDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentPlansDetailedQuery, Operation.TreatmentPlansDetailedQueryVariables>(TreatmentPlansDetailedDocument, options);
        }
export type TreatmentPlansDetailedQueryHookResult = ReturnType<typeof useTreatmentPlansDetailedQuery>;
export type TreatmentPlansDetailedLazyQueryHookResult = ReturnType<typeof useTreatmentPlansDetailedLazyQuery>;
export type TreatmentPlansDetailedSuspenseQueryHookResult = ReturnType<typeof useTreatmentPlansDetailedSuspenseQuery>;
export type TreatmentPlansDetailedQueryResult = Apollo.QueryResult<Operation.TreatmentPlansDetailedQuery, Operation.TreatmentPlansDetailedQueryVariables>;
export const TreatmentsDocument = gql`
    query Treatments($active: Boolean, $inProgress: Boolean, $appointmentReasonId: ID, $userPracticeConnectionId: [ID!], $state: [TreatmentState!], $withActiveAppointments: Boolean, $after: String, $first: Int, $upc: [ID!]) {
  treatments(
    upc: $upc
    active: $active
    inProgress: $inProgress
    appointmentReasonId: $appointmentReasonId
    state: $state
    withActiveAppointments: $withActiveAppointments
    userPracticeConnectionId: $userPracticeConnectionId
    first: $first
    after: $after
  ) {
    edges {
      node {
        ...treatmentEssentials
      }
    }
  }
}
    ${TreatmentEssentialsFragmentDoc}`;

/**
 * __useTreatmentsQuery__
 *
 * To run a query within a React component, call `useTreatmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentsQuery({
 *   variables: {
 *      active: // value for 'active'
 *      inProgress: // value for 'inProgress'
 *      appointmentReasonId: // value for 'appointmentReasonId'
 *      userPracticeConnectionId: // value for 'userPracticeConnectionId'
 *      state: // value for 'state'
 *      withActiveAppointments: // value for 'withActiveAppointments'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      upc: // value for 'upc'
 *   },
 * });
 */
export function useTreatmentsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentsQuery, Operation.TreatmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentsQuery, Operation.TreatmentsQueryVariables>(TreatmentsDocument, options);
      }
export function useTreatmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentsQuery, Operation.TreatmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentsQuery, Operation.TreatmentsQueryVariables>(TreatmentsDocument, options);
        }
export function useTreatmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentsQuery, Operation.TreatmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentsQuery, Operation.TreatmentsQueryVariables>(TreatmentsDocument, options);
        }
export type TreatmentsQueryHookResult = ReturnType<typeof useTreatmentsQuery>;
export type TreatmentsLazyQueryHookResult = ReturnType<typeof useTreatmentsLazyQuery>;
export type TreatmentsSuspenseQueryHookResult = ReturnType<typeof useTreatmentsSuspenseQuery>;
export type TreatmentsQueryResult = Apollo.QueryResult<Operation.TreatmentsQuery, Operation.TreatmentsQueryVariables>;
export const TreatmentsListDocument = gql`
    query TreatmentsList($active: Boolean, $inProgress: Boolean, $appointmentReasonId: ID, $userPracticeConnectionId: [ID!], $state: [TreatmentState!], $withActiveAppointments: Boolean, $after: String, $first: Int, $upc: [ID!]) {
  treatments(
    upc: $upc
    active: $active
    inProgress: $inProgress
    appointmentReasonId: $appointmentReasonId
    state: $state
    withActiveAppointments: $withActiveAppointments
    userPracticeConnectionId: $userPracticeConnectionId
    first: $first
    after: $after
  ) {
    edges {
      node {
        ...treatmentListItem
      }
    }
  }
}
    ${TreatmentListItemFragmentDoc}`;

/**
 * __useTreatmentsListQuery__
 *
 * To run a query within a React component, call `useTreatmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentsListQuery({
 *   variables: {
 *      active: // value for 'active'
 *      inProgress: // value for 'inProgress'
 *      appointmentReasonId: // value for 'appointmentReasonId'
 *      userPracticeConnectionId: // value for 'userPracticeConnectionId'
 *      state: // value for 'state'
 *      withActiveAppointments: // value for 'withActiveAppointments'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      upc: // value for 'upc'
 *   },
 * });
 */
export function useTreatmentsListQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentsListQuery, Operation.TreatmentsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentsListQuery, Operation.TreatmentsListQueryVariables>(TreatmentsListDocument, options);
      }
export function useTreatmentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentsListQuery, Operation.TreatmentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentsListQuery, Operation.TreatmentsListQueryVariables>(TreatmentsListDocument, options);
        }
export function useTreatmentsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentsListQuery, Operation.TreatmentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentsListQuery, Operation.TreatmentsListQueryVariables>(TreatmentsListDocument, options);
        }
export type TreatmentsListQueryHookResult = ReturnType<typeof useTreatmentsListQuery>;
export type TreatmentsListLazyQueryHookResult = ReturnType<typeof useTreatmentsListLazyQuery>;
export type TreatmentsListSuspenseQueryHookResult = ReturnType<typeof useTreatmentsListSuspenseQuery>;
export type TreatmentsListQueryResult = Apollo.QueryResult<Operation.TreatmentsListQuery, Operation.TreatmentsListQueryVariables>;
export const TreatmentsDetailedDocument = gql`
    query TreatmentsDetailed($active: Boolean, $inProgress: Boolean, $appointmentReasonId: ID, $upc: [ID!], $userPracticeConnectionId: [ID!], $state: [TreatmentState!], $withActiveAppointments: Boolean, $after: String, $first: Int) {
  treatments(
    active: $active
    inProgress: $inProgress
    upc: $upc
    appointmentReasonId: $appointmentReasonId
    state: $state
    withActiveAppointments: $withActiveAppointments
    userPracticeConnectionId: $userPracticeConnectionId
    first: $first
    after: $after
  ) {
    edges {
      node {
        ...treatmentDetailed
      }
    }
  }
}
    ${TreatmentDetailedFragmentDoc}`;

/**
 * __useTreatmentsDetailedQuery__
 *
 * To run a query within a React component, call `useTreatmentsDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentsDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentsDetailedQuery({
 *   variables: {
 *      active: // value for 'active'
 *      inProgress: // value for 'inProgress'
 *      appointmentReasonId: // value for 'appointmentReasonId'
 *      upc: // value for 'upc'
 *      userPracticeConnectionId: // value for 'userPracticeConnectionId'
 *      state: // value for 'state'
 *      withActiveAppointments: // value for 'withActiveAppointments'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTreatmentsDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.TreatmentsDetailedQuery, Operation.TreatmentsDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TreatmentsDetailedQuery, Operation.TreatmentsDetailedQueryVariables>(TreatmentsDetailedDocument, options);
      }
export function useTreatmentsDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TreatmentsDetailedQuery, Operation.TreatmentsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TreatmentsDetailedQuery, Operation.TreatmentsDetailedQueryVariables>(TreatmentsDetailedDocument, options);
        }
export function useTreatmentsDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TreatmentsDetailedQuery, Operation.TreatmentsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TreatmentsDetailedQuery, Operation.TreatmentsDetailedQueryVariables>(TreatmentsDetailedDocument, options);
        }
export type TreatmentsDetailedQueryHookResult = ReturnType<typeof useTreatmentsDetailedQuery>;
export type TreatmentsDetailedLazyQueryHookResult = ReturnType<typeof useTreatmentsDetailedLazyQuery>;
export type TreatmentsDetailedSuspenseQueryHookResult = ReturnType<typeof useTreatmentsDetailedSuspenseQuery>;
export type TreatmentsDetailedQueryResult = Apollo.QueryResult<Operation.TreatmentsDetailedQuery, Operation.TreatmentsDetailedQueryVariables>;
export const AvailableAccountsDocument = gql`
    query AvailableAccounts($subdomain: String) {
  availableAccounts(subdomain: $subdomain) {
    id
    accountName
    userPracticeConnections {
      id
      guid
      state
      accountId
      accountName
      userName
      practiceId
      practiceName
      practiceLocation {
        ...practiceLocationMinimal
      }
      role {
        id
        slug
        name
      }
    }
  }
}
    ${PracticeLocationMinimalFragmentDoc}`;

/**
 * __useAvailableAccountsQuery__
 *
 * To run a query within a React component, call `useAvailableAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableAccountsQuery({
 *   variables: {
 *      subdomain: // value for 'subdomain'
 *   },
 * });
 */
export function useAvailableAccountsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.AvailableAccountsQuery, Operation.AvailableAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.AvailableAccountsQuery, Operation.AvailableAccountsQueryVariables>(AvailableAccountsDocument, options);
      }
export function useAvailableAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.AvailableAccountsQuery, Operation.AvailableAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.AvailableAccountsQuery, Operation.AvailableAccountsQueryVariables>(AvailableAccountsDocument, options);
        }
export function useAvailableAccountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.AvailableAccountsQuery, Operation.AvailableAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.AvailableAccountsQuery, Operation.AvailableAccountsQueryVariables>(AvailableAccountsDocument, options);
        }
export type AvailableAccountsQueryHookResult = ReturnType<typeof useAvailableAccountsQuery>;
export type AvailableAccountsLazyQueryHookResult = ReturnType<typeof useAvailableAccountsLazyQuery>;
export type AvailableAccountsSuspenseQueryHookResult = ReturnType<typeof useAvailableAccountsSuspenseQuery>;
export type AvailableAccountsQueryResult = Apollo.QueryResult<Operation.AvailableAccountsQuery, Operation.AvailableAccountsQueryVariables>;
export const UpcCommunicationSettingsDocument = gql`
    query UPCCommunicationSettings($id: ID, $slug: String) {
  userPracticeConnection(id: $id, slug: $slug) {
    ...userPracticeConnectionCommunicationSettings
  }
}
    ${UserPracticeConnectionCommunicationSettingsFragmentDoc}`;

/**
 * __useUpcCommunicationSettingsQuery__
 *
 * To run a query within a React component, call `useUpcCommunicationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcCommunicationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcCommunicationSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUpcCommunicationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcCommunicationSettingsQuery, Operation.UpcCommunicationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcCommunicationSettingsQuery, Operation.UpcCommunicationSettingsQueryVariables>(UpcCommunicationSettingsDocument, options);
      }
export function useUpcCommunicationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcCommunicationSettingsQuery, Operation.UpcCommunicationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcCommunicationSettingsQuery, Operation.UpcCommunicationSettingsQueryVariables>(UpcCommunicationSettingsDocument, options);
        }
export function useUpcCommunicationSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcCommunicationSettingsQuery, Operation.UpcCommunicationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcCommunicationSettingsQuery, Operation.UpcCommunicationSettingsQueryVariables>(UpcCommunicationSettingsDocument, options);
        }
export type UpcCommunicationSettingsQueryHookResult = ReturnType<typeof useUpcCommunicationSettingsQuery>;
export type UpcCommunicationSettingsLazyQueryHookResult = ReturnType<typeof useUpcCommunicationSettingsLazyQuery>;
export type UpcCommunicationSettingsSuspenseQueryHookResult = ReturnType<typeof useUpcCommunicationSettingsSuspenseQuery>;
export type UpcCommunicationSettingsQueryResult = Apollo.QueryResult<Operation.UpcCommunicationSettingsQuery, Operation.UpcCommunicationSettingsQueryVariables>;
export const ContactsDocument = gql`
    query Contacts($guid: ID!) {
  contacts(guid: $guid) {
    ...contactEssentials
  }
}
    ${ContactEssentialsFragmentDoc}`;

/**
 * __useContactsQuery__
 *
 * To run a query within a React component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useContactsQuery(baseOptions: Apollo.QueryHookOptions<Operation.ContactsQuery, Operation.ContactsQueryVariables> & ({ variables: Operation.ContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.ContactsQuery, Operation.ContactsQueryVariables>(ContactsDocument, options);
      }
export function useContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.ContactsQuery, Operation.ContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.ContactsQuery, Operation.ContactsQueryVariables>(ContactsDocument, options);
        }
export function useContactsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.ContactsQuery, Operation.ContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.ContactsQuery, Operation.ContactsQueryVariables>(ContactsDocument, options);
        }
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<typeof useContactsLazyQuery>;
export type ContactsSuspenseQueryHookResult = ReturnType<typeof useContactsSuspenseQuery>;
export type ContactsQueryResult = Apollo.QueryResult<Operation.ContactsQuery, Operation.ContactsQueryVariables>;
export const UpcEmergencyContactsDocument = gql`
    query UPCEmergencyContacts($id: ID, $slug: String) {
  userPracticeConnection(id: $id, slug: $slug) {
    ...userPracticeConnectionEmergencyContacts
  }
}
    ${UserPracticeConnectionEmergencyContactsFragmentDoc}`;

/**
 * __useUpcEmergencyContactsQuery__
 *
 * To run a query within a React component, call `useUpcEmergencyContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcEmergencyContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcEmergencyContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUpcEmergencyContactsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcEmergencyContactsQuery, Operation.UpcEmergencyContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcEmergencyContactsQuery, Operation.UpcEmergencyContactsQueryVariables>(UpcEmergencyContactsDocument, options);
      }
export function useUpcEmergencyContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcEmergencyContactsQuery, Operation.UpcEmergencyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcEmergencyContactsQuery, Operation.UpcEmergencyContactsQueryVariables>(UpcEmergencyContactsDocument, options);
        }
export function useUpcEmergencyContactsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcEmergencyContactsQuery, Operation.UpcEmergencyContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcEmergencyContactsQuery, Operation.UpcEmergencyContactsQueryVariables>(UpcEmergencyContactsDocument, options);
        }
export type UpcEmergencyContactsQueryHookResult = ReturnType<typeof useUpcEmergencyContactsQuery>;
export type UpcEmergencyContactsLazyQueryHookResult = ReturnType<typeof useUpcEmergencyContactsLazyQuery>;
export type UpcEmergencyContactsSuspenseQueryHookResult = ReturnType<typeof useUpcEmergencyContactsSuspenseQuery>;
export type UpcEmergencyContactsQueryResult = Apollo.QueryResult<Operation.UpcEmergencyContactsQuery, Operation.UpcEmergencyContactsQueryVariables>;
export const PatientDocument = gql`
    query Patient($practiceId: ID, $slug: String!) {
  practice(id: $practiceId) {
    ...practiceEssentials
    patient(slug: $slug) {
      ...patientEssentials
    }
  }
}
    ${PracticeEssentialsFragmentDoc}
${PatientEssentialsFragmentDoc}`;

/**
 * __usePatientQuery__
 *
 * To run a query within a React component, call `usePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuery({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePatientQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientQuery, Operation.PatientQueryVariables> & ({ variables: Operation.PatientQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientQuery, Operation.PatientQueryVariables>(PatientDocument, options);
      }
export function usePatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientQuery, Operation.PatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientQuery, Operation.PatientQueryVariables>(PatientDocument, options);
        }
export function usePatientSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientQuery, Operation.PatientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientQuery, Operation.PatientQueryVariables>(PatientDocument, options);
        }
export type PatientQueryHookResult = ReturnType<typeof usePatientQuery>;
export type PatientLazyQueryHookResult = ReturnType<typeof usePatientLazyQuery>;
export type PatientSuspenseQueryHookResult = ReturnType<typeof usePatientSuspenseQuery>;
export type PatientQueryResult = Apollo.QueryResult<Operation.PatientQuery, Operation.PatientQueryVariables>;
export const PatientDetailedDocument = gql`
    query PatientDetailed($practiceId: ID, $slug: String!) {
  practice(id: $practiceId) {
    ...practiceEssentials
    patient(slug: $slug) {
      ...patientDetailed
    }
  }
}
    ${PracticeEssentialsFragmentDoc}
${PatientDetailedFragmentDoc}`;

/**
 * __usePatientDetailedQuery__
 *
 * To run a query within a React component, call `usePatientDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDetailedQuery({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePatientDetailedQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientDetailedQuery, Operation.PatientDetailedQueryVariables> & ({ variables: Operation.PatientDetailedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientDetailedQuery, Operation.PatientDetailedQueryVariables>(PatientDetailedDocument, options);
      }
export function usePatientDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientDetailedQuery, Operation.PatientDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientDetailedQuery, Operation.PatientDetailedQueryVariables>(PatientDetailedDocument, options);
        }
export function usePatientDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientDetailedQuery, Operation.PatientDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientDetailedQuery, Operation.PatientDetailedQueryVariables>(PatientDetailedDocument, options);
        }
export type PatientDetailedQueryHookResult = ReturnType<typeof usePatientDetailedQuery>;
export type PatientDetailedLazyQueryHookResult = ReturnType<typeof usePatientDetailedLazyQuery>;
export type PatientDetailedSuspenseQueryHookResult = ReturnType<typeof usePatientDetailedSuspenseQuery>;
export type PatientDetailedQueryResult = Apollo.QueryResult<Operation.PatientDetailedQuery, Operation.PatientDetailedQueryVariables>;
export const PatientAppConfigDocument = gql`
    query PatientAppConfig($upc: ID!) {
  patientAppConfig(upc: $upc) {
    ...patientAppConfigDetailed
  }
}
    ${PatientAppConfigDetailedFragmentDoc}`;

/**
 * __usePatientAppConfigQuery__
 *
 * To run a query within a React component, call `usePatientAppConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientAppConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientAppConfigQuery({
 *   variables: {
 *      upc: // value for 'upc'
 *   },
 * });
 */
export function usePatientAppConfigQuery(baseOptions: Apollo.QueryHookOptions<Operation.PatientAppConfigQuery, Operation.PatientAppConfigQueryVariables> & ({ variables: Operation.PatientAppConfigQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientAppConfigQuery, Operation.PatientAppConfigQueryVariables>(PatientAppConfigDocument, options);
      }
export function usePatientAppConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientAppConfigQuery, Operation.PatientAppConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientAppConfigQuery, Operation.PatientAppConfigQueryVariables>(PatientAppConfigDocument, options);
        }
export function usePatientAppConfigSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientAppConfigQuery, Operation.PatientAppConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientAppConfigQuery, Operation.PatientAppConfigQueryVariables>(PatientAppConfigDocument, options);
        }
export type PatientAppConfigQueryHookResult = ReturnType<typeof usePatientAppConfigQuery>;
export type PatientAppConfigLazyQueryHookResult = ReturnType<typeof usePatientAppConfigLazyQuery>;
export type PatientAppConfigSuspenseQueryHookResult = ReturnType<typeof usePatientAppConfigSuspenseQuery>;
export type PatientAppConfigQueryResult = Apollo.QueryResult<Operation.PatientAppConfigQuery, Operation.PatientAppConfigQueryVariables>;
export const PatientAppInviteDocument = gql`
    query PatientAppInvite($code: ID) {
  patientAppInvite(inviteCode: $code) {
    ...patientAppConfigInvite
  }
}
    ${PatientAppConfigInviteFragmentDoc}`;

/**
 * __usePatientAppInviteQuery__
 *
 * To run a query within a React component, call `usePatientAppInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientAppInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientAppInviteQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePatientAppInviteQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PatientAppInviteQuery, Operation.PatientAppInviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PatientAppInviteQuery, Operation.PatientAppInviteQueryVariables>(PatientAppInviteDocument, options);
      }
export function usePatientAppInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PatientAppInviteQuery, Operation.PatientAppInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PatientAppInviteQuery, Operation.PatientAppInviteQueryVariables>(PatientAppInviteDocument, options);
        }
export function usePatientAppInviteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PatientAppInviteQuery, Operation.PatientAppInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PatientAppInviteQuery, Operation.PatientAppInviteQueryVariables>(PatientAppInviteDocument, options);
        }
export type PatientAppInviteQueryHookResult = ReturnType<typeof usePatientAppInviteQuery>;
export type PatientAppInviteLazyQueryHookResult = ReturnType<typeof usePatientAppInviteLazyQuery>;
export type PatientAppInviteSuspenseQueryHookResult = ReturnType<typeof usePatientAppInviteSuspenseQuery>;
export type PatientAppInviteQueryResult = Apollo.QueryResult<Operation.PatientAppInviteQuery, Operation.PatientAppInviteQueryVariables>;
export const PracticeProfessionalsDocument = gql`
    query PracticeProfessionals($practiceId: ID, $state: String) {
  practiceProfessionals(practiceId: $practiceId, state: $state) {
    edges {
      node {
        ...practiceProfessionalsEssentials
      }
    }
  }
}
    ${PracticeProfessionalsEssentialsFragmentDoc}`;

/**
 * __usePracticeProfessionalsQuery__
 *
 * To run a query within a React component, call `usePracticeProfessionalsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeProfessionalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeProfessionalsQuery({
 *   variables: {
 *      practiceId: // value for 'practiceId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function usePracticeProfessionalsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PracticeProfessionalsQuery, Operation.PracticeProfessionalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PracticeProfessionalsQuery, Operation.PracticeProfessionalsQueryVariables>(PracticeProfessionalsDocument, options);
      }
export function usePracticeProfessionalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PracticeProfessionalsQuery, Operation.PracticeProfessionalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PracticeProfessionalsQuery, Operation.PracticeProfessionalsQueryVariables>(PracticeProfessionalsDocument, options);
        }
export function usePracticeProfessionalsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PracticeProfessionalsQuery, Operation.PracticeProfessionalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PracticeProfessionalsQuery, Operation.PracticeProfessionalsQueryVariables>(PracticeProfessionalsDocument, options);
        }
export type PracticeProfessionalsQueryHookResult = ReturnType<typeof usePracticeProfessionalsQuery>;
export type PracticeProfessionalsLazyQueryHookResult = ReturnType<typeof usePracticeProfessionalsLazyQuery>;
export type PracticeProfessionalsSuspenseQueryHookResult = ReturnType<typeof usePracticeProfessionalsSuspenseQuery>;
export type PracticeProfessionalsQueryResult = Apollo.QueryResult<Operation.PracticeProfessionalsQuery, Operation.PracticeProfessionalsQueryVariables>;
export const PreferencesDocument = gql`
    query Preferences {
  currentUserPreferences {
    ...upcPreferences
  }
}
    ${UpcPreferencesFragmentDoc}`;

/**
 * __usePreferencesQuery__
 *
 * To run a query within a React component, call `usePreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreferencesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.PreferencesQuery, Operation.PreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.PreferencesQuery, Operation.PreferencesQueryVariables>(PreferencesDocument, options);
      }
export function usePreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.PreferencesQuery, Operation.PreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.PreferencesQuery, Operation.PreferencesQueryVariables>(PreferencesDocument, options);
        }
export function usePreferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.PreferencesQuery, Operation.PreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.PreferencesQuery, Operation.PreferencesQueryVariables>(PreferencesDocument, options);
        }
export type PreferencesQueryHookResult = ReturnType<typeof usePreferencesQuery>;
export type PreferencesLazyQueryHookResult = ReturnType<typeof usePreferencesLazyQuery>;
export type PreferencesSuspenseQueryHookResult = ReturnType<typeof usePreferencesSuspenseQuery>;
export type PreferencesQueryResult = Apollo.QueryResult<Operation.PreferencesQuery, Operation.PreferencesQueryVariables>;
export const DashboardPreferencesDocument = gql`
    query DashboardPreferences {
  currentUserPreferences {
    ...upcDashboardPreferences
  }
}
    ${UpcDashboardPreferencesFragmentDoc}`;

/**
 * __useDashboardPreferencesQuery__
 *
 * To run a query within a React component, call `useDashboardPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.DashboardPreferencesQuery, Operation.DashboardPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.DashboardPreferencesQuery, Operation.DashboardPreferencesQueryVariables>(DashboardPreferencesDocument, options);
      }
export function useDashboardPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.DashboardPreferencesQuery, Operation.DashboardPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.DashboardPreferencesQuery, Operation.DashboardPreferencesQueryVariables>(DashboardPreferencesDocument, options);
        }
export function useDashboardPreferencesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.DashboardPreferencesQuery, Operation.DashboardPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.DashboardPreferencesQuery, Operation.DashboardPreferencesQueryVariables>(DashboardPreferencesDocument, options);
        }
export type DashboardPreferencesQueryHookResult = ReturnType<typeof useDashboardPreferencesQuery>;
export type DashboardPreferencesLazyQueryHookResult = ReturnType<typeof useDashboardPreferencesLazyQuery>;
export type DashboardPreferencesSuspenseQueryHookResult = ReturnType<typeof useDashboardPreferencesSuspenseQuery>;
export type DashboardPreferencesQueryResult = Apollo.QueryResult<Operation.DashboardPreferencesQuery, Operation.DashboardPreferencesQueryVariables>;
export const UserPracticeConnectionRecallsDocument = gql`
    query UserPracticeConnectionRecalls($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...userPracticeConnectionRecalls
      }
    }
  }
}
    ${UserPracticeConnectionRecallsFragmentDoc}`;

/**
 * __useUserPracticeConnectionRecallsQuery__
 *
 * To run a query within a React component, call `useUserPracticeConnectionRecallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPracticeConnectionRecallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPracticeConnectionRecallsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserPracticeConnectionRecallsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UserPracticeConnectionRecallsQuery, Operation.UserPracticeConnectionRecallsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserPracticeConnectionRecallsQuery, Operation.UserPracticeConnectionRecallsQueryVariables>(UserPracticeConnectionRecallsDocument, options);
      }
export function useUserPracticeConnectionRecallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserPracticeConnectionRecallsQuery, Operation.UserPracticeConnectionRecallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserPracticeConnectionRecallsQuery, Operation.UserPracticeConnectionRecallsQueryVariables>(UserPracticeConnectionRecallsDocument, options);
        }
export function useUserPracticeConnectionRecallsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserPracticeConnectionRecallsQuery, Operation.UserPracticeConnectionRecallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserPracticeConnectionRecallsQuery, Operation.UserPracticeConnectionRecallsQueryVariables>(UserPracticeConnectionRecallsDocument, options);
        }
export type UserPracticeConnectionRecallsQueryHookResult = ReturnType<typeof useUserPracticeConnectionRecallsQuery>;
export type UserPracticeConnectionRecallsLazyQueryHookResult = ReturnType<typeof useUserPracticeConnectionRecallsLazyQuery>;
export type UserPracticeConnectionRecallsSuspenseQueryHookResult = ReturnType<typeof useUserPracticeConnectionRecallsSuspenseQuery>;
export type UserPracticeConnectionRecallsQueryResult = Apollo.QueryResult<Operation.UserPracticeConnectionRecallsQuery, Operation.UserPracticeConnectionRecallsQueryVariables>;
export const RelationshipsDocument = gql`
    query Relationships {
  relationships
}
    `;

/**
 * __useRelationshipsQuery__
 *
 * To run a query within a React component, call `useRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRelationshipsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.RelationshipsQuery, Operation.RelationshipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.RelationshipsQuery, Operation.RelationshipsQueryVariables>(RelationshipsDocument, options);
      }
export function useRelationshipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.RelationshipsQuery, Operation.RelationshipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.RelationshipsQuery, Operation.RelationshipsQueryVariables>(RelationshipsDocument, options);
        }
export function useRelationshipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.RelationshipsQuery, Operation.RelationshipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.RelationshipsQuery, Operation.RelationshipsQueryVariables>(RelationshipsDocument, options);
        }
export type RelationshipsQueryHookResult = ReturnType<typeof useRelationshipsQuery>;
export type RelationshipsLazyQueryHookResult = ReturnType<typeof useRelationshipsLazyQuery>;
export type RelationshipsSuspenseQueryHookResult = ReturnType<typeof useRelationshipsSuspenseQuery>;
export type RelationshipsQueryResult = Apollo.QueryResult<Operation.RelationshipsQuery, Operation.RelationshipsQueryVariables>;
export const StaffDocument = gql`
    query Staff($search: String, $ids: [ID!], $calendarEnabled: Boolean, $practiceIds: [ID!], $practiceLocationIds: [ID!], $state: [UserPracticeConnectionState!], $excludeRole: [PracticeRole!], $roles: [PracticeRole!], $after: String, $first: Int) {
  userPracticeConnections(
    attributes: {ids: $ids, search: $search, excludeRole: $excludeRole, roles: $roles, calendarEnabled: $calendarEnabled, practiceIds: $practiceIds, practiceLocationIds: $practiceLocationIds, state: $state}
    first: $first
    after: $after
  ) {
    edges {
      node {
        ...userPracticeConnectionStaffMinimal
      }
    }
    pageInfo {
      ...pageInfo
    }
  }
}
    ${UserPracticeConnectionStaffMinimalFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *      calendarEnabled: // value for 'calendarEnabled'
 *      practiceIds: // value for 'practiceIds'
 *      practiceLocationIds: // value for 'practiceLocationIds'
 *      state: // value for 'state'
 *      excludeRole: // value for 'excludeRole'
 *      roles: // value for 'roles'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useStaffQuery(baseOptions?: Apollo.QueryHookOptions<Operation.StaffQuery, Operation.StaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.StaffQuery, Operation.StaffQueryVariables>(StaffDocument, options);
      }
export function useStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.StaffQuery, Operation.StaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.StaffQuery, Operation.StaffQueryVariables>(StaffDocument, options);
        }
export function useStaffSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.StaffQuery, Operation.StaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.StaffQuery, Operation.StaffQueryVariables>(StaffDocument, options);
        }
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffSuspenseQueryHookResult = ReturnType<typeof useStaffSuspenseQuery>;
export type StaffQueryResult = Apollo.QueryResult<Operation.StaffQuery, Operation.StaffQueryVariables>;
export const StaffListDocument = gql`
    query StaffList($search: String, $practiceIds: [ID!], $practiceLocationIds: [ID!], $state: [UserPracticeConnectionState!], $calendarEnabled: Boolean, $roles: [PracticeRole!], $excludeRole: [PracticeRole!], $after: String, $first: Int) {
  userPracticeConnections(
    attributes: {search: $search, roles: $roles, excludeRole: $excludeRole, calendarEnabled: $calendarEnabled, practiceIds: $practiceIds, practiceLocationIds: $practiceLocationIds, state: $state}
    after: $after
    first: $first
  ) {
    edges {
      node {
        ...userPracticeConnectionStaffList
      }
    }
    pageInfo {
      ...pageInfo
    }
  }
}
    ${UserPracticeConnectionStaffListFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useStaffListQuery__
 *
 * To run a query within a React component, call `useStaffListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffListQuery({
 *   variables: {
 *      search: // value for 'search'
 *      practiceIds: // value for 'practiceIds'
 *      practiceLocationIds: // value for 'practiceLocationIds'
 *      state: // value for 'state'
 *      calendarEnabled: // value for 'calendarEnabled'
 *      roles: // value for 'roles'
 *      excludeRole: // value for 'excludeRole'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useStaffListQuery(baseOptions?: Apollo.QueryHookOptions<Operation.StaffListQuery, Operation.StaffListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.StaffListQuery, Operation.StaffListQueryVariables>(StaffListDocument, options);
      }
export function useStaffListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.StaffListQuery, Operation.StaffListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.StaffListQuery, Operation.StaffListQueryVariables>(StaffListDocument, options);
        }
export function useStaffListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.StaffListQuery, Operation.StaffListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.StaffListQuery, Operation.StaffListQueryVariables>(StaffListDocument, options);
        }
export type StaffListQueryHookResult = ReturnType<typeof useStaffListQuery>;
export type StaffListLazyQueryHookResult = ReturnType<typeof useStaffListLazyQuery>;
export type StaffListSuspenseQueryHookResult = ReturnType<typeof useStaffListSuspenseQuery>;
export type StaffListQueryResult = Apollo.QueryResult<Operation.StaffListQuery, Operation.StaffListQueryVariables>;
export const StaffSettingsListDocument = gql`
    query StaffSettingsList($search: String, $practiceIds: [ID!], $practiceLocationIds: [ID!], $state: [UserPracticeConnectionState!], $calendarEnabled: Boolean, $roles: [PracticeRole!], $excludeRole: [PracticeRole!], $after: String, $first: Int) {
  userPracticeConnections(
    attributes: {search: $search, roles: $roles, excludeRole: $excludeRole, calendarEnabled: $calendarEnabled, practiceIds: $practiceIds, practiceLocationIds: $practiceLocationIds, state: $state}
    after: $after
    first: $first
  ) {
    edges {
      node {
        ...upcStaffList
      }
    }
    pageInfo {
      ...pageInfo
    }
  }
}
    ${UpcStaffListFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useStaffSettingsListQuery__
 *
 * To run a query within a React component, call `useStaffSettingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffSettingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffSettingsListQuery({
 *   variables: {
 *      search: // value for 'search'
 *      practiceIds: // value for 'practiceIds'
 *      practiceLocationIds: // value for 'practiceLocationIds'
 *      state: // value for 'state'
 *      calendarEnabled: // value for 'calendarEnabled'
 *      roles: // value for 'roles'
 *      excludeRole: // value for 'excludeRole'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useStaffSettingsListQuery(baseOptions?: Apollo.QueryHookOptions<Operation.StaffSettingsListQuery, Operation.StaffSettingsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.StaffSettingsListQuery, Operation.StaffSettingsListQueryVariables>(StaffSettingsListDocument, options);
      }
export function useStaffSettingsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.StaffSettingsListQuery, Operation.StaffSettingsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.StaffSettingsListQuery, Operation.StaffSettingsListQueryVariables>(StaffSettingsListDocument, options);
        }
export function useStaffSettingsListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.StaffSettingsListQuery, Operation.StaffSettingsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.StaffSettingsListQuery, Operation.StaffSettingsListQueryVariables>(StaffSettingsListDocument, options);
        }
export type StaffSettingsListQueryHookResult = ReturnType<typeof useStaffSettingsListQuery>;
export type StaffSettingsListLazyQueryHookResult = ReturnType<typeof useStaffSettingsListLazyQuery>;
export type StaffSettingsListSuspenseQueryHookResult = ReturnType<typeof useStaffSettingsListSuspenseQuery>;
export type StaffSettingsListQueryResult = Apollo.QueryResult<Operation.StaffSettingsListQuery, Operation.StaffSettingsListQueryVariables>;
export const StaffRosterDocument = gql`
    query StaffRoster($search: String, $ids: [ID!], $calendarEnabled: Boolean, $startDate: ISO8601Date, $endDate: ISO8601Date, $practiceIds: [ID!], $practiceLocationIds: [ID!], $state: [UserPracticeConnectionState!], $excludeRole: [PracticeRole!], $roles: [PracticeRole!], $after: String, $limit: Int) {
  userPracticeConnections(
    attributes: {ids: $ids, search: $search, excludeRole: $excludeRole, roles: $roles, calendarEnabled: $calendarEnabled, practiceIds: $practiceIds, practiceLocationIds: $practiceLocationIds, state: $state}
    after: $after
    first: $limit
  ) {
    pageInfo {
      ...pageInfo
    }
    edges {
      node {
        ...userPracticeConnectionStaffRoster
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${UserPracticeConnectionStaffRosterFragmentDoc}`;

/**
 * __useStaffRosterQuery__
 *
 * To run a query within a React component, call `useStaffRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffRosterQuery({
 *   variables: {
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *      calendarEnabled: // value for 'calendarEnabled'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      practiceIds: // value for 'practiceIds'
 *      practiceLocationIds: // value for 'practiceLocationIds'
 *      state: // value for 'state'
 *      excludeRole: // value for 'excludeRole'
 *      roles: // value for 'roles'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useStaffRosterQuery(baseOptions?: Apollo.QueryHookOptions<Operation.StaffRosterQuery, Operation.StaffRosterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.StaffRosterQuery, Operation.StaffRosterQueryVariables>(StaffRosterDocument, options);
      }
export function useStaffRosterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.StaffRosterQuery, Operation.StaffRosterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.StaffRosterQuery, Operation.StaffRosterQueryVariables>(StaffRosterDocument, options);
        }
export function useStaffRosterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.StaffRosterQuery, Operation.StaffRosterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.StaffRosterQuery, Operation.StaffRosterQueryVariables>(StaffRosterDocument, options);
        }
export type StaffRosterQueryHookResult = ReturnType<typeof useStaffRosterQuery>;
export type StaffRosterLazyQueryHookResult = ReturnType<typeof useStaffRosterLazyQuery>;
export type StaffRosterSuspenseQueryHookResult = ReturnType<typeof useStaffRosterSuspenseQuery>;
export type StaffRosterQueryResult = Apollo.QueryResult<Operation.StaffRosterQuery, Operation.StaffRosterQueryVariables>;
export const UpcAccountCreditsDocument = gql`
    query UPCAccountCredits($byPatientIds: [ID!], $byPractitionerIds: [ID!], $after: String, $limit: Int) {
  patientRecordCredits(
    after: $after
    first: $limit
    byPatientIds: $byPatientIds
    byPractitionerIds: $byPractitionerIds
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...patientCreditHistoryEssentials
      }
    }
  }
}
    ${PatientCreditHistoryEssentialsFragmentDoc}`;

/**
 * __useUpcAccountCreditsQuery__
 *
 * To run a query within a React component, call `useUpcAccountCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcAccountCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcAccountCreditsQuery({
 *   variables: {
 *      byPatientIds: // value for 'byPatientIds'
 *      byPractitionerIds: // value for 'byPractitionerIds'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUpcAccountCreditsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcAccountCreditsQuery, Operation.UpcAccountCreditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcAccountCreditsQuery, Operation.UpcAccountCreditsQueryVariables>(UpcAccountCreditsDocument, options);
      }
export function useUpcAccountCreditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcAccountCreditsQuery, Operation.UpcAccountCreditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcAccountCreditsQuery, Operation.UpcAccountCreditsQueryVariables>(UpcAccountCreditsDocument, options);
        }
export function useUpcAccountCreditsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcAccountCreditsQuery, Operation.UpcAccountCreditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcAccountCreditsQuery, Operation.UpcAccountCreditsQueryVariables>(UpcAccountCreditsDocument, options);
        }
export type UpcAccountCreditsQueryHookResult = ReturnType<typeof useUpcAccountCreditsQuery>;
export type UpcAccountCreditsLazyQueryHookResult = ReturnType<typeof useUpcAccountCreditsLazyQuery>;
export type UpcAccountCreditsSuspenseQueryHookResult = ReturnType<typeof useUpcAccountCreditsSuspenseQuery>;
export type UpcAccountCreditsQueryResult = Apollo.QueryResult<Operation.UpcAccountCreditsQuery, Operation.UpcAccountCreditsQueryVariables>;
export const UpcAccountsDocument = gql`
    query UPCAccounts($state: [UserPracticeConnectionState!], $roles: [PracticeRole!]) {
  session {
    id
    user {
      id
      guid
      userPracticeConnections(state: $state, roles: $roles) {
        id
        slug
        guid
        account {
          subdomain
        }
        role {
          name
          slug
        }
        practiceLocation {
          ...practiceLocationMinimal
        }
        profile {
          fullNameWithTitle
        }
        practice {
          name
        }
      }
    }
  }
}
    ${PracticeLocationMinimalFragmentDoc}`;

/**
 * __useUpcAccountsQuery__
 *
 * To run a query within a React component, call `useUpcAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcAccountsQuery({
 *   variables: {
 *      state: // value for 'state'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useUpcAccountsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcAccountsQuery, Operation.UpcAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcAccountsQuery, Operation.UpcAccountsQueryVariables>(UpcAccountsDocument, options);
      }
export function useUpcAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcAccountsQuery, Operation.UpcAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcAccountsQuery, Operation.UpcAccountsQueryVariables>(UpcAccountsDocument, options);
        }
export function useUpcAccountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcAccountsQuery, Operation.UpcAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcAccountsQuery, Operation.UpcAccountsQueryVariables>(UpcAccountsDocument, options);
        }
export type UpcAccountsQueryHookResult = ReturnType<typeof useUpcAccountsQuery>;
export type UpcAccountsLazyQueryHookResult = ReturnType<typeof useUpcAccountsLazyQuery>;
export type UpcAccountsSuspenseQueryHookResult = ReturnType<typeof useUpcAccountsSuspenseQuery>;
export type UpcAccountsQueryResult = Apollo.QueryResult<Operation.UpcAccountsQuery, Operation.UpcAccountsQueryVariables>;
export const UpcSlugToIdDocument = gql`
    query UPCSlugToId($slug: String) {
  userPracticeConnection(slug: $slug) {
    id
    guid
    slug
  }
}
    `;

/**
 * __useUpcSlugToIdQuery__
 *
 * To run a query within a React component, call `useUpcSlugToIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcSlugToIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcSlugToIdQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUpcSlugToIdQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcSlugToIdQuery, Operation.UpcSlugToIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcSlugToIdQuery, Operation.UpcSlugToIdQueryVariables>(UpcSlugToIdDocument, options);
      }
export function useUpcSlugToIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcSlugToIdQuery, Operation.UpcSlugToIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcSlugToIdQuery, Operation.UpcSlugToIdQueryVariables>(UpcSlugToIdDocument, options);
        }
export function useUpcSlugToIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcSlugToIdQuery, Operation.UpcSlugToIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcSlugToIdQuery, Operation.UpcSlugToIdQueryVariables>(UpcSlugToIdDocument, options);
        }
export type UpcSlugToIdQueryHookResult = ReturnType<typeof useUpcSlugToIdQuery>;
export type UpcSlugToIdLazyQueryHookResult = ReturnType<typeof useUpcSlugToIdLazyQuery>;
export type UpcSlugToIdSuspenseQueryHookResult = ReturnType<typeof useUpcSlugToIdSuspenseQuery>;
export type UpcSlugToIdQueryResult = Apollo.QueryResult<Operation.UpcSlugToIdQuery, Operation.UpcSlugToIdQueryVariables>;
export const UpcToPatientRecordsDocument = gql`
    query UPCToPatientRecords($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    edges {
      node {
        id
        guid
        patientRecord {
          ...patientRecordEssentials
        }
      }
    }
  }
}
    ${PatientRecordEssentialsFragmentDoc}`;

/**
 * __useUpcToPatientRecordsQuery__
 *
 * To run a query within a React component, call `useUpcToPatientRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcToPatientRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcToPatientRecordsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUpcToPatientRecordsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcToPatientRecordsQuery, Operation.UpcToPatientRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcToPatientRecordsQuery, Operation.UpcToPatientRecordsQueryVariables>(UpcToPatientRecordsDocument, options);
      }
export function useUpcToPatientRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcToPatientRecordsQuery, Operation.UpcToPatientRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcToPatientRecordsQuery, Operation.UpcToPatientRecordsQueryVariables>(UpcToPatientRecordsDocument, options);
        }
export function useUpcToPatientRecordsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcToPatientRecordsQuery, Operation.UpcToPatientRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcToPatientRecordsQuery, Operation.UpcToPatientRecordsQueryVariables>(UpcToPatientRecordsDocument, options);
        }
export type UpcToPatientRecordsQueryHookResult = ReturnType<typeof useUpcToPatientRecordsQuery>;
export type UpcToPatientRecordsLazyQueryHookResult = ReturnType<typeof useUpcToPatientRecordsLazyQuery>;
export type UpcToPatientRecordsSuspenseQueryHookResult = ReturnType<typeof useUpcToPatientRecordsSuspenseQuery>;
export type UpcToPatientRecordsQueryResult = Apollo.QueryResult<Operation.UpcToPatientRecordsQuery, Operation.UpcToPatientRecordsQueryVariables>;
export const UserPracticeConnectionDocument = gql`
    query UserPracticeConnection($id: ID, $guid: ID) {
  userPracticeConnection(id: $id, guid: $guid) {
    ...userPracticeConnectionDetailed
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;

/**
 * __useUserPracticeConnectionQuery__
 *
 * To run a query within a React component, call `useUserPracticeConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPracticeConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPracticeConnectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useUserPracticeConnectionQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UserPracticeConnectionQuery, Operation.UserPracticeConnectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserPracticeConnectionQuery, Operation.UserPracticeConnectionQueryVariables>(UserPracticeConnectionDocument, options);
      }
export function useUserPracticeConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserPracticeConnectionQuery, Operation.UserPracticeConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserPracticeConnectionQuery, Operation.UserPracticeConnectionQueryVariables>(UserPracticeConnectionDocument, options);
        }
export function useUserPracticeConnectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserPracticeConnectionQuery, Operation.UserPracticeConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserPracticeConnectionQuery, Operation.UserPracticeConnectionQueryVariables>(UserPracticeConnectionDocument, options);
        }
export type UserPracticeConnectionQueryHookResult = ReturnType<typeof useUserPracticeConnectionQuery>;
export type UserPracticeConnectionLazyQueryHookResult = ReturnType<typeof useUserPracticeConnectionLazyQuery>;
export type UserPracticeConnectionSuspenseQueryHookResult = ReturnType<typeof useUserPracticeConnectionSuspenseQuery>;
export type UserPracticeConnectionQueryResult = Apollo.QueryResult<Operation.UserPracticeConnectionQuery, Operation.UserPracticeConnectionQueryVariables>;
export const UpcMinimalDocument = gql`
    query UPCMinimal($id: ID, $slug: String, $guid: ID) {
  userPracticeConnection(id: $id, slug: $slug, guid: $guid) {
    ...userPracticeConnectionMinimal
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;

/**
 * __useUpcMinimalQuery__
 *
 * To run a query within a React component, call `useUpcMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcMinimalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useUpcMinimalQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcMinimalQuery, Operation.UpcMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcMinimalQuery, Operation.UpcMinimalQueryVariables>(UpcMinimalDocument, options);
      }
export function useUpcMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcMinimalQuery, Operation.UpcMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcMinimalQuery, Operation.UpcMinimalQueryVariables>(UpcMinimalDocument, options);
        }
export function useUpcMinimalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcMinimalQuery, Operation.UpcMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcMinimalQuery, Operation.UpcMinimalQueryVariables>(UpcMinimalDocument, options);
        }
export type UpcMinimalQueryHookResult = ReturnType<typeof useUpcMinimalQuery>;
export type UpcMinimalLazyQueryHookResult = ReturnType<typeof useUpcMinimalLazyQuery>;
export type UpcMinimalSuspenseQueryHookResult = ReturnType<typeof useUpcMinimalSuspenseQuery>;
export type UpcMinimalQueryResult = Apollo.QueryResult<Operation.UpcMinimalQuery, Operation.UpcMinimalQueryVariables>;
export const UpcDetailedDocument = gql`
    query UPCDetailed($id: ID, $slug: String, $guid: ID) {
  userPracticeConnection(id: $id, slug: $slug, guid: $guid) {
    ...userPracticeConnectionDetailed
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;

/**
 * __useUpcDetailedQuery__
 *
 * To run a query within a React component, call `useUpcDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcDetailedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useUpcDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcDetailedQuery, Operation.UpcDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcDetailedQuery, Operation.UpcDetailedQueryVariables>(UpcDetailedDocument, options);
      }
export function useUpcDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcDetailedQuery, Operation.UpcDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcDetailedQuery, Operation.UpcDetailedQueryVariables>(UpcDetailedDocument, options);
        }
export function useUpcDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcDetailedQuery, Operation.UpcDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcDetailedQuery, Operation.UpcDetailedQueryVariables>(UpcDetailedDocument, options);
        }
export type UpcDetailedQueryHookResult = ReturnType<typeof useUpcDetailedQuery>;
export type UpcDetailedLazyQueryHookResult = ReturnType<typeof useUpcDetailedLazyQuery>;
export type UpcDetailedSuspenseQueryHookResult = ReturnType<typeof useUpcDetailedSuspenseQuery>;
export type UpcDetailedQueryResult = Apollo.QueryResult<Operation.UpcDetailedQuery, Operation.UpcDetailedQueryVariables>;
export const UpcEssentialsDocument = gql`
    query UPCEssentials($id: ID, $slug: String, $guid: ID) {
  userPracticeConnection(id: $id, slug: $slug, guid: $guid) {
    ...userPracticeConnectionEssentials
  }
}
    ${UserPracticeConnectionEssentialsFragmentDoc}`;

/**
 * __useUpcEssentialsQuery__
 *
 * To run a query within a React component, call `useUpcEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcEssentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useUpcEssentialsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcEssentialsQuery, Operation.UpcEssentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcEssentialsQuery, Operation.UpcEssentialsQueryVariables>(UpcEssentialsDocument, options);
      }
export function useUpcEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcEssentialsQuery, Operation.UpcEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcEssentialsQuery, Operation.UpcEssentialsQueryVariables>(UpcEssentialsDocument, options);
        }
export function useUpcEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcEssentialsQuery, Operation.UpcEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcEssentialsQuery, Operation.UpcEssentialsQueryVariables>(UpcEssentialsDocument, options);
        }
export type UpcEssentialsQueryHookResult = ReturnType<typeof useUpcEssentialsQuery>;
export type UpcEssentialsLazyQueryHookResult = ReturnType<typeof useUpcEssentialsLazyQuery>;
export type UpcEssentialsSuspenseQueryHookResult = ReturnType<typeof useUpcEssentialsSuspenseQuery>;
export type UpcEssentialsQueryResult = Apollo.QueryResult<Operation.UpcEssentialsQuery, Operation.UpcEssentialsQueryVariables>;
export const StaffUpcDocument = gql`
    query StaffUPC($id: ID, $guid: ID) {
  userPracticeConnection(id: $id, guid: $guid) {
    ...userPracticeConnectionPractitionerEssentials
  }
}
    ${UserPracticeConnectionPractitionerEssentialsFragmentDoc}`;

/**
 * __useStaffUpcQuery__
 *
 * To run a query within a React component, call `useStaffUpcQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffUpcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffUpcQuery({
 *   variables: {
 *      id: // value for 'id'
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useStaffUpcQuery(baseOptions?: Apollo.QueryHookOptions<Operation.StaffUpcQuery, Operation.StaffUpcQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.StaffUpcQuery, Operation.StaffUpcQueryVariables>(StaffUpcDocument, options);
      }
export function useStaffUpcLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.StaffUpcQuery, Operation.StaffUpcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.StaffUpcQuery, Operation.StaffUpcQueryVariables>(StaffUpcDocument, options);
        }
export function useStaffUpcSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.StaffUpcQuery, Operation.StaffUpcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.StaffUpcQuery, Operation.StaffUpcQueryVariables>(StaffUpcDocument, options);
        }
export type StaffUpcQueryHookResult = ReturnType<typeof useStaffUpcQuery>;
export type StaffUpcLazyQueryHookResult = ReturnType<typeof useStaffUpcLazyQuery>;
export type StaffUpcSuspenseQueryHookResult = ReturnType<typeof useStaffUpcSuspenseQuery>;
export type StaffUpcQueryResult = Apollo.QueryResult<Operation.StaffUpcQuery, Operation.StaffUpcQueryVariables>;
export const UpcDefaultHealthFundsDocument = gql`
    query UPCDefaultHealthFunds($guid: ID) {
  userPracticeConnection(guid: $guid) {
    ...userPracticeConnectionDefaultHealthFunds
  }
}
    ${UserPracticeConnectionDefaultHealthFundsFragmentDoc}`;

/**
 * __useUpcDefaultHealthFundsQuery__
 *
 * To run a query within a React component, call `useUpcDefaultHealthFundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcDefaultHealthFundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcDefaultHealthFundsQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useUpcDefaultHealthFundsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcDefaultHealthFundsQuery, Operation.UpcDefaultHealthFundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcDefaultHealthFundsQuery, Operation.UpcDefaultHealthFundsQueryVariables>(UpcDefaultHealthFundsDocument, options);
      }
export function useUpcDefaultHealthFundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcDefaultHealthFundsQuery, Operation.UpcDefaultHealthFundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcDefaultHealthFundsQuery, Operation.UpcDefaultHealthFundsQueryVariables>(UpcDefaultHealthFundsDocument, options);
        }
export function useUpcDefaultHealthFundsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcDefaultHealthFundsQuery, Operation.UpcDefaultHealthFundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcDefaultHealthFundsQuery, Operation.UpcDefaultHealthFundsQueryVariables>(UpcDefaultHealthFundsDocument, options);
        }
export type UpcDefaultHealthFundsQueryHookResult = ReturnType<typeof useUpcDefaultHealthFundsQuery>;
export type UpcDefaultHealthFundsLazyQueryHookResult = ReturnType<typeof useUpcDefaultHealthFundsLazyQuery>;
export type UpcDefaultHealthFundsSuspenseQueryHookResult = ReturnType<typeof useUpcDefaultHealthFundsSuspenseQuery>;
export type UpcDefaultHealthFundsQueryResult = Apollo.QueryResult<Operation.UpcDefaultHealthFundsQuery, Operation.UpcDefaultHealthFundsQueryVariables>;
export const UserPracticeConnectionDetailedDocument = gql`
    query UserPracticeConnectionDetailed($id: ID, $guid: ID) {
  userPracticeConnection(id: $id, guid: $guid) {
    ...userPracticeConnectionDetailed
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;

/**
 * __useUserPracticeConnectionDetailedQuery__
 *
 * To run a query within a React component, call `useUserPracticeConnectionDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPracticeConnectionDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPracticeConnectionDetailedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useUserPracticeConnectionDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UserPracticeConnectionDetailedQuery, Operation.UserPracticeConnectionDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserPracticeConnectionDetailedQuery, Operation.UserPracticeConnectionDetailedQueryVariables>(UserPracticeConnectionDetailedDocument, options);
      }
export function useUserPracticeConnectionDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserPracticeConnectionDetailedQuery, Operation.UserPracticeConnectionDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserPracticeConnectionDetailedQuery, Operation.UserPracticeConnectionDetailedQueryVariables>(UserPracticeConnectionDetailedDocument, options);
        }
export function useUserPracticeConnectionDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserPracticeConnectionDetailedQuery, Operation.UserPracticeConnectionDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserPracticeConnectionDetailedQuery, Operation.UserPracticeConnectionDetailedQueryVariables>(UserPracticeConnectionDetailedDocument, options);
        }
export type UserPracticeConnectionDetailedQueryHookResult = ReturnType<typeof useUserPracticeConnectionDetailedQuery>;
export type UserPracticeConnectionDetailedLazyQueryHookResult = ReturnType<typeof useUserPracticeConnectionDetailedLazyQuery>;
export type UserPracticeConnectionDetailedSuspenseQueryHookResult = ReturnType<typeof useUserPracticeConnectionDetailedSuspenseQuery>;
export type UserPracticeConnectionDetailedQueryResult = Apollo.QueryResult<Operation.UserPracticeConnectionDetailedQuery, Operation.UserPracticeConnectionDetailedQueryVariables>;
export const UserPracticeConnectionCommunicationsDocument = gql`
    query UserPracticeConnectionCommunications($id: ID, $guid: ID) {
  userPracticeConnection(id: $id, guid: $guid) {
    ...userPracticeConnectionCommunicationEssentials
  }
}
    ${UserPracticeConnectionCommunicationEssentialsFragmentDoc}`;

/**
 * __useUserPracticeConnectionCommunicationsQuery__
 *
 * To run a query within a React component, call `useUserPracticeConnectionCommunicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPracticeConnectionCommunicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPracticeConnectionCommunicationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useUserPracticeConnectionCommunicationsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UserPracticeConnectionCommunicationsQuery, Operation.UserPracticeConnectionCommunicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserPracticeConnectionCommunicationsQuery, Operation.UserPracticeConnectionCommunicationsQueryVariables>(UserPracticeConnectionCommunicationsDocument, options);
      }
export function useUserPracticeConnectionCommunicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserPracticeConnectionCommunicationsQuery, Operation.UserPracticeConnectionCommunicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserPracticeConnectionCommunicationsQuery, Operation.UserPracticeConnectionCommunicationsQueryVariables>(UserPracticeConnectionCommunicationsDocument, options);
        }
export function useUserPracticeConnectionCommunicationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserPracticeConnectionCommunicationsQuery, Operation.UserPracticeConnectionCommunicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserPracticeConnectionCommunicationsQuery, Operation.UserPracticeConnectionCommunicationsQueryVariables>(UserPracticeConnectionCommunicationsDocument, options);
        }
export type UserPracticeConnectionCommunicationsQueryHookResult = ReturnType<typeof useUserPracticeConnectionCommunicationsQuery>;
export type UserPracticeConnectionCommunicationsLazyQueryHookResult = ReturnType<typeof useUserPracticeConnectionCommunicationsLazyQuery>;
export type UserPracticeConnectionCommunicationsSuspenseQueryHookResult = ReturnType<typeof useUserPracticeConnectionCommunicationsSuspenseQuery>;
export type UserPracticeConnectionCommunicationsQueryResult = Apollo.QueryResult<Operation.UserPracticeConnectionCommunicationsQuery, Operation.UserPracticeConnectionCommunicationsQueryVariables>;
export const UserPracticeConnectionScanningIntegrationsDocument = gql`
    query UserPracticeConnectionScanningIntegrations($id: ID, $guid: ID) {
  userPracticeConnection(id: $id, guid: $guid) {
    ...userPracticeConnectionScanningIntegrations
  }
}
    ${UserPracticeConnectionScanningIntegrationsFragmentDoc}`;

/**
 * __useUserPracticeConnectionScanningIntegrationsQuery__
 *
 * To run a query within a React component, call `useUserPracticeConnectionScanningIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPracticeConnectionScanningIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPracticeConnectionScanningIntegrationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useUserPracticeConnectionScanningIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UserPracticeConnectionScanningIntegrationsQuery, Operation.UserPracticeConnectionScanningIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserPracticeConnectionScanningIntegrationsQuery, Operation.UserPracticeConnectionScanningIntegrationsQueryVariables>(UserPracticeConnectionScanningIntegrationsDocument, options);
      }
export function useUserPracticeConnectionScanningIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserPracticeConnectionScanningIntegrationsQuery, Operation.UserPracticeConnectionScanningIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserPracticeConnectionScanningIntegrationsQuery, Operation.UserPracticeConnectionScanningIntegrationsQueryVariables>(UserPracticeConnectionScanningIntegrationsDocument, options);
        }
export function useUserPracticeConnectionScanningIntegrationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserPracticeConnectionScanningIntegrationsQuery, Operation.UserPracticeConnectionScanningIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserPracticeConnectionScanningIntegrationsQuery, Operation.UserPracticeConnectionScanningIntegrationsQueryVariables>(UserPracticeConnectionScanningIntegrationsDocument, options);
        }
export type UserPracticeConnectionScanningIntegrationsQueryHookResult = ReturnType<typeof useUserPracticeConnectionScanningIntegrationsQuery>;
export type UserPracticeConnectionScanningIntegrationsLazyQueryHookResult = ReturnType<typeof useUserPracticeConnectionScanningIntegrationsLazyQuery>;
export type UserPracticeConnectionScanningIntegrationsSuspenseQueryHookResult = ReturnType<typeof useUserPracticeConnectionScanningIntegrationsSuspenseQuery>;
export type UserPracticeConnectionScanningIntegrationsQueryResult = Apollo.QueryResult<Operation.UserPracticeConnectionScanningIntegrationsQuery, Operation.UserPracticeConnectionScanningIntegrationsQueryVariables>;
export const UserPracticeConnectionsDocument = gql`
    query UserPracticeConnections($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...userPracticeConnectionMinimal
      }
    }
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;

/**
 * __useUserPracticeConnectionsQuery__
 *
 * To run a query within a React component, call `useUserPracticeConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPracticeConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPracticeConnectionsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserPracticeConnectionsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UserPracticeConnectionsQuery, Operation.UserPracticeConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserPracticeConnectionsQuery, Operation.UserPracticeConnectionsQueryVariables>(UserPracticeConnectionsDocument, options);
      }
export function useUserPracticeConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserPracticeConnectionsQuery, Operation.UserPracticeConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserPracticeConnectionsQuery, Operation.UserPracticeConnectionsQueryVariables>(UserPracticeConnectionsDocument, options);
        }
export function useUserPracticeConnectionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserPracticeConnectionsQuery, Operation.UserPracticeConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserPracticeConnectionsQuery, Operation.UserPracticeConnectionsQueryVariables>(UserPracticeConnectionsDocument, options);
        }
export type UserPracticeConnectionsQueryHookResult = ReturnType<typeof useUserPracticeConnectionsQuery>;
export type UserPracticeConnectionsLazyQueryHookResult = ReturnType<typeof useUserPracticeConnectionsLazyQuery>;
export type UserPracticeConnectionsSuspenseQueryHookResult = ReturnType<typeof useUserPracticeConnectionsSuspenseQuery>;
export type UserPracticeConnectionsQueryResult = Apollo.QueryResult<Operation.UserPracticeConnectionsQuery, Operation.UserPracticeConnectionsQueryVariables>;
export const UpcAppointmentGuestSearchDocument = gql`
    query UPCAppointmentGuestSearch($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    pageInfo {
      ...pageInfo
    }
    edges {
      cursor
      node {
        ...userPracticeConnectionMinimal
        priceList {
          ...priceListEssentials
        }
        metadata {
          hasPublicHealthCare
          hasPrivateHealthCare
        }
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${UserPracticeConnectionMinimalFragmentDoc}
${PriceListEssentialsFragmentDoc}`;

/**
 * __useUpcAppointmentGuestSearchQuery__
 *
 * To run a query within a React component, call `useUpcAppointmentGuestSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcAppointmentGuestSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcAppointmentGuestSearchQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUpcAppointmentGuestSearchQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcAppointmentGuestSearchQuery, Operation.UpcAppointmentGuestSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcAppointmentGuestSearchQuery, Operation.UpcAppointmentGuestSearchQueryVariables>(UpcAppointmentGuestSearchDocument, options);
      }
export function useUpcAppointmentGuestSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcAppointmentGuestSearchQuery, Operation.UpcAppointmentGuestSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcAppointmentGuestSearchQuery, Operation.UpcAppointmentGuestSearchQueryVariables>(UpcAppointmentGuestSearchDocument, options);
        }
export function useUpcAppointmentGuestSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcAppointmentGuestSearchQuery, Operation.UpcAppointmentGuestSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcAppointmentGuestSearchQuery, Operation.UpcAppointmentGuestSearchQueryVariables>(UpcAppointmentGuestSearchDocument, options);
        }
export type UpcAppointmentGuestSearchQueryHookResult = ReturnType<typeof useUpcAppointmentGuestSearchQuery>;
export type UpcAppointmentGuestSearchLazyQueryHookResult = ReturnType<typeof useUpcAppointmentGuestSearchLazyQuery>;
export type UpcAppointmentGuestSearchSuspenseQueryHookResult = ReturnType<typeof useUpcAppointmentGuestSearchSuspenseQuery>;
export type UpcAppointmentGuestSearchQueryResult = Apollo.QueryResult<Operation.UpcAppointmentGuestSearchQuery, Operation.UpcAppointmentGuestSearchQueryVariables>;
export const UpcMinimalSearchDocument = gql`
    query UPCMinimalSearch($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    ids
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...userPracticeConnectionMinimal
      }
    }
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;

/**
 * __useUpcMinimalSearchQuery__
 *
 * To run a query within a React component, call `useUpcMinimalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcMinimalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcMinimalSearchQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUpcMinimalSearchQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcMinimalSearchQuery, Operation.UpcMinimalSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcMinimalSearchQuery, Operation.UpcMinimalSearchQueryVariables>(UpcMinimalSearchDocument, options);
      }
export function useUpcMinimalSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcMinimalSearchQuery, Operation.UpcMinimalSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcMinimalSearchQuery, Operation.UpcMinimalSearchQueryVariables>(UpcMinimalSearchDocument, options);
        }
export function useUpcMinimalSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcMinimalSearchQuery, Operation.UpcMinimalSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcMinimalSearchQuery, Operation.UpcMinimalSearchQueryVariables>(UpcMinimalSearchDocument, options);
        }
export type UpcMinimalSearchQueryHookResult = ReturnType<typeof useUpcMinimalSearchQuery>;
export type UpcMinimalSearchLazyQueryHookResult = ReturnType<typeof useUpcMinimalSearchLazyQuery>;
export type UpcMinimalSearchSuspenseQueryHookResult = ReturnType<typeof useUpcMinimalSearchSuspenseQuery>;
export type UpcMinimalSearchQueryResult = Apollo.QueryResult<Operation.UpcMinimalSearchQuery, Operation.UpcMinimalSearchQueryVariables>;
export const UserSearchDocument = gql`
    query UserSearch($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    ids
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...userPracticeConnectionSearch
      }
    }
  }
}
    ${UserPracticeConnectionSearchFragmentDoc}`;

/**
 * __useUserSearchQuery__
 *
 * To run a query within a React component, call `useUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSearchQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserSearchQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UserSearchQuery, Operation.UserSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserSearchQuery, Operation.UserSearchQueryVariables>(UserSearchDocument, options);
      }
export function useUserSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserSearchQuery, Operation.UserSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserSearchQuery, Operation.UserSearchQueryVariables>(UserSearchDocument, options);
        }
export function useUserSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserSearchQuery, Operation.UserSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserSearchQuery, Operation.UserSearchQueryVariables>(UserSearchDocument, options);
        }
export type UserSearchQueryHookResult = ReturnType<typeof useUserSearchQuery>;
export type UserSearchLazyQueryHookResult = ReturnType<typeof useUserSearchLazyQuery>;
export type UserSearchSuspenseQueryHookResult = ReturnType<typeof useUserSearchSuspenseQuery>;
export type UserSearchQueryResult = Apollo.QueryResult<Operation.UserSearchQuery, Operation.UserSearchQueryVariables>;
export const UserPracticeConnectionsDetailedDocument = gql`
    query UserPracticeConnectionsDetailed($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    edges {
      cursor
      node {
        ...userPracticeConnectionDetailed
      }
    }
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;

/**
 * __useUserPracticeConnectionsDetailedQuery__
 *
 * To run a query within a React component, call `useUserPracticeConnectionsDetailedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPracticeConnectionsDetailedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPracticeConnectionsDetailedQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserPracticeConnectionsDetailedQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UserPracticeConnectionsDetailedQuery, Operation.UserPracticeConnectionsDetailedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserPracticeConnectionsDetailedQuery, Operation.UserPracticeConnectionsDetailedQueryVariables>(UserPracticeConnectionsDetailedDocument, options);
      }
export function useUserPracticeConnectionsDetailedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserPracticeConnectionsDetailedQuery, Operation.UserPracticeConnectionsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserPracticeConnectionsDetailedQuery, Operation.UserPracticeConnectionsDetailedQueryVariables>(UserPracticeConnectionsDetailedDocument, options);
        }
export function useUserPracticeConnectionsDetailedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserPracticeConnectionsDetailedQuery, Operation.UserPracticeConnectionsDetailedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserPracticeConnectionsDetailedQuery, Operation.UserPracticeConnectionsDetailedQueryVariables>(UserPracticeConnectionsDetailedDocument, options);
        }
export type UserPracticeConnectionsDetailedQueryHookResult = ReturnType<typeof useUserPracticeConnectionsDetailedQuery>;
export type UserPracticeConnectionsDetailedLazyQueryHookResult = ReturnType<typeof useUserPracticeConnectionsDetailedLazyQuery>;
export type UserPracticeConnectionsDetailedSuspenseQueryHookResult = ReturnType<typeof useUserPracticeConnectionsDetailedSuspenseQuery>;
export type UserPracticeConnectionsDetailedQueryResult = Apollo.QueryResult<Operation.UserPracticeConnectionsDetailedQuery, Operation.UserPracticeConnectionsDetailedQueryVariables>;
export const UserPracticeConnectionsCommunicationsDocument = gql`
    query UserPracticeConnectionsCommunications($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...userPracticeConnectionCommunicationEssentials
      }
    }
  }
}
    ${UserPracticeConnectionCommunicationEssentialsFragmentDoc}`;

/**
 * __useUserPracticeConnectionsCommunicationsQuery__
 *
 * To run a query within a React component, call `useUserPracticeConnectionsCommunicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPracticeConnectionsCommunicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPracticeConnectionsCommunicationsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserPracticeConnectionsCommunicationsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UserPracticeConnectionsCommunicationsQuery, Operation.UserPracticeConnectionsCommunicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UserPracticeConnectionsCommunicationsQuery, Operation.UserPracticeConnectionsCommunicationsQueryVariables>(UserPracticeConnectionsCommunicationsDocument, options);
      }
export function useUserPracticeConnectionsCommunicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UserPracticeConnectionsCommunicationsQuery, Operation.UserPracticeConnectionsCommunicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UserPracticeConnectionsCommunicationsQuery, Operation.UserPracticeConnectionsCommunicationsQueryVariables>(UserPracticeConnectionsCommunicationsDocument, options);
        }
export function useUserPracticeConnectionsCommunicationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UserPracticeConnectionsCommunicationsQuery, Operation.UserPracticeConnectionsCommunicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UserPracticeConnectionsCommunicationsQuery, Operation.UserPracticeConnectionsCommunicationsQueryVariables>(UserPracticeConnectionsCommunicationsDocument, options);
        }
export type UserPracticeConnectionsCommunicationsQueryHookResult = ReturnType<typeof useUserPracticeConnectionsCommunicationsQuery>;
export type UserPracticeConnectionsCommunicationsLazyQueryHookResult = ReturnType<typeof useUserPracticeConnectionsCommunicationsLazyQuery>;
export type UserPracticeConnectionsCommunicationsSuspenseQueryHookResult = ReturnType<typeof useUserPracticeConnectionsCommunicationsSuspenseQuery>;
export type UserPracticeConnectionsCommunicationsQueryResult = Apollo.QueryResult<Operation.UserPracticeConnectionsCommunicationsQuery, Operation.UserPracticeConnectionsCommunicationsQueryVariables>;
export const UpcCommunicationRecipientsDocument = gql`
    query UpcCommunicationRecipients($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...upcCommunicationRecipientFields
      }
    }
  }
}
    ${UpcCommunicationRecipientFieldsFragmentDoc}`;

/**
 * __useUpcCommunicationRecipientsQuery__
 *
 * To run a query within a React component, call `useUpcCommunicationRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcCommunicationRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcCommunicationRecipientsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUpcCommunicationRecipientsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcCommunicationRecipientsQuery, Operation.UpcCommunicationRecipientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcCommunicationRecipientsQuery, Operation.UpcCommunicationRecipientsQueryVariables>(UpcCommunicationRecipientsDocument, options);
      }
export function useUpcCommunicationRecipientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcCommunicationRecipientsQuery, Operation.UpcCommunicationRecipientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcCommunicationRecipientsQuery, Operation.UpcCommunicationRecipientsQueryVariables>(UpcCommunicationRecipientsDocument, options);
        }
export function useUpcCommunicationRecipientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcCommunicationRecipientsQuery, Operation.UpcCommunicationRecipientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcCommunicationRecipientsQuery, Operation.UpcCommunicationRecipientsQueryVariables>(UpcCommunicationRecipientsDocument, options);
        }
export type UpcCommunicationRecipientsQueryHookResult = ReturnType<typeof useUpcCommunicationRecipientsQuery>;
export type UpcCommunicationRecipientsLazyQueryHookResult = ReturnType<typeof useUpcCommunicationRecipientsLazyQuery>;
export type UpcCommunicationRecipientsSuspenseQueryHookResult = ReturnType<typeof useUpcCommunicationRecipientsSuspenseQuery>;
export type UpcCommunicationRecipientsQueryResult = Apollo.QueryResult<Operation.UpcCommunicationRecipientsQuery, Operation.UpcCommunicationRecipientsQueryVariables>;
export const UpcShortMessagesDocument = gql`
    query UpcShortMessages($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...upcShortMessageFields
      }
    }
  }
}
    ${UpcShortMessageFieldsFragmentDoc}`;

/**
 * __useUpcShortMessagesQuery__
 *
 * To run a query within a React component, call `useUpcShortMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcShortMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcShortMessagesQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUpcShortMessagesQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcShortMessagesQuery, Operation.UpcShortMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcShortMessagesQuery, Operation.UpcShortMessagesQueryVariables>(UpcShortMessagesDocument, options);
      }
export function useUpcShortMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcShortMessagesQuery, Operation.UpcShortMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcShortMessagesQuery, Operation.UpcShortMessagesQueryVariables>(UpcShortMessagesDocument, options);
        }
export function useUpcShortMessagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcShortMessagesQuery, Operation.UpcShortMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcShortMessagesQuery, Operation.UpcShortMessagesQueryVariables>(UpcShortMessagesDocument, options);
        }
export type UpcShortMessagesQueryHookResult = ReturnType<typeof useUpcShortMessagesQuery>;
export type UpcShortMessagesLazyQueryHookResult = ReturnType<typeof useUpcShortMessagesLazyQuery>;
export type UpcShortMessagesSuspenseQueryHookResult = ReturnType<typeof useUpcShortMessagesSuspenseQuery>;
export type UpcShortMessagesQueryResult = Apollo.QueryResult<Operation.UpcShortMessagesQuery, Operation.UpcShortMessagesQueryVariables>;
export const UpcUnreadShortMessageCountDocument = gql`
    query UpcUnreadShortMessageCount($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        id
        guid
        unreadMessageCount
      }
    }
  }
}
    `;

/**
 * __useUpcUnreadShortMessageCountQuery__
 *
 * To run a query within a React component, call `useUpcUnreadShortMessageCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcUnreadShortMessageCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcUnreadShortMessageCountQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUpcUnreadShortMessageCountQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UpcUnreadShortMessageCountQuery, Operation.UpcUnreadShortMessageCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UpcUnreadShortMessageCountQuery, Operation.UpcUnreadShortMessageCountQueryVariables>(UpcUnreadShortMessageCountDocument, options);
      }
export function useUpcUnreadShortMessageCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UpcUnreadShortMessageCountQuery, Operation.UpcUnreadShortMessageCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UpcUnreadShortMessageCountQuery, Operation.UpcUnreadShortMessageCountQueryVariables>(UpcUnreadShortMessageCountDocument, options);
        }
export function useUpcUnreadShortMessageCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UpcUnreadShortMessageCountQuery, Operation.UpcUnreadShortMessageCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UpcUnreadShortMessageCountQuery, Operation.UpcUnreadShortMessageCountQueryVariables>(UpcUnreadShortMessageCountDocument, options);
        }
export type UpcUnreadShortMessageCountQueryHookResult = ReturnType<typeof useUpcUnreadShortMessageCountQuery>;
export type UpcUnreadShortMessageCountLazyQueryHookResult = ReturnType<typeof useUpcUnreadShortMessageCountLazyQuery>;
export type UpcUnreadShortMessageCountSuspenseQueryHookResult = ReturnType<typeof useUpcUnreadShortMessageCountSuspenseQuery>;
export type UpcUnreadShortMessageCountQueryResult = Apollo.QueryResult<Operation.UpcUnreadShortMessageCountQuery, Operation.UpcUnreadShortMessageCountQueryVariables>;
export const UsersDocument = gql`
    query Users($search: String, $first: Int, $after: String) {
  users(search: $search, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      node {
        ...userEssentials
      }
    }
  }
}
    ${UserEssentialsFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<Operation.UsersQuery, Operation.UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.UsersQuery, Operation.UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.UsersQuery, Operation.UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.UsersQuery, Operation.UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.UsersQuery, Operation.UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.UsersQuery, Operation.UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<Operation.UsersQuery, Operation.UsersQueryVariables>;
export const FamiliesExhaustiveDocument = gql`
    query FamiliesExhaustive($search: String) {
  families(search: $search) {
    edges {
      node {
        ...familyExhaustive
      }
    }
  }
}
    ${FamilyExhaustiveFragmentDoc}`;

/**
 * __useFamiliesExhaustiveQuery__
 *
 * To run a query within a React component, call `useFamiliesExhaustiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamiliesExhaustiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamiliesExhaustiveQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFamiliesExhaustiveQuery(baseOptions?: Apollo.QueryHookOptions<Operation.FamiliesExhaustiveQuery, Operation.FamiliesExhaustiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FamiliesExhaustiveQuery, Operation.FamiliesExhaustiveQueryVariables>(FamiliesExhaustiveDocument, options);
      }
export function useFamiliesExhaustiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FamiliesExhaustiveQuery, Operation.FamiliesExhaustiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FamiliesExhaustiveQuery, Operation.FamiliesExhaustiveQueryVariables>(FamiliesExhaustiveDocument, options);
        }
export function useFamiliesExhaustiveSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FamiliesExhaustiveQuery, Operation.FamiliesExhaustiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FamiliesExhaustiveQuery, Operation.FamiliesExhaustiveQueryVariables>(FamiliesExhaustiveDocument, options);
        }
export type FamiliesExhaustiveQueryHookResult = ReturnType<typeof useFamiliesExhaustiveQuery>;
export type FamiliesExhaustiveLazyQueryHookResult = ReturnType<typeof useFamiliesExhaustiveLazyQuery>;
export type FamiliesExhaustiveSuspenseQueryHookResult = ReturnType<typeof useFamiliesExhaustiveSuspenseQuery>;
export type FamiliesExhaustiveQueryResult = Apollo.QueryResult<Operation.FamiliesExhaustiveQuery, Operation.FamiliesExhaustiveQueryVariables>;
export const FamilyDocument = gql`
    query Family($id: ID!) {
  family(id: $id) {
    ...familyExhaustive
  }
}
    ${FamilyExhaustiveFragmentDoc}`;

/**
 * __useFamilyQuery__
 *
 * To run a query within a React component, call `useFamilyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamilyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamilyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFamilyQuery(baseOptions: Apollo.QueryHookOptions<Operation.FamilyQuery, Operation.FamilyQueryVariables> & ({ variables: Operation.FamilyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.FamilyQuery, Operation.FamilyQueryVariables>(FamilyDocument, options);
      }
export function useFamilyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.FamilyQuery, Operation.FamilyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.FamilyQuery, Operation.FamilyQueryVariables>(FamilyDocument, options);
        }
export function useFamilySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.FamilyQuery, Operation.FamilyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.FamilyQuery, Operation.FamilyQueryVariables>(FamilyDocument, options);
        }
export type FamilyQueryHookResult = ReturnType<typeof useFamilyQuery>;
export type FamilyLazyQueryHookResult = ReturnType<typeof useFamilyLazyQuery>;
export type FamilySuspenseQueryHookResult = ReturnType<typeof useFamilySuspenseQuery>;
export type FamilyQueryResult = Apollo.QueryResult<Operation.FamilyQuery, Operation.FamilyQueryVariables>;
export const SearchNewFamilyMembersDocument = gql`
    query SearchNewFamilyMembers($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    edges {
      node {
        ...newFamilyMember
      }
    }
  }
}
    ${NewFamilyMemberFragmentDoc}`;

/**
 * __useSearchNewFamilyMembersQuery__
 *
 * To run a query within a React component, call `useSearchNewFamilyMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNewFamilyMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNewFamilyMembersQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchNewFamilyMembersQuery(baseOptions?: Apollo.QueryHookOptions<Operation.SearchNewFamilyMembersQuery, Operation.SearchNewFamilyMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.SearchNewFamilyMembersQuery, Operation.SearchNewFamilyMembersQueryVariables>(SearchNewFamilyMembersDocument, options);
      }
export function useSearchNewFamilyMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.SearchNewFamilyMembersQuery, Operation.SearchNewFamilyMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.SearchNewFamilyMembersQuery, Operation.SearchNewFamilyMembersQueryVariables>(SearchNewFamilyMembersDocument, options);
        }
export function useSearchNewFamilyMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.SearchNewFamilyMembersQuery, Operation.SearchNewFamilyMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.SearchNewFamilyMembersQuery, Operation.SearchNewFamilyMembersQueryVariables>(SearchNewFamilyMembersDocument, options);
        }
export type SearchNewFamilyMembersQueryHookResult = ReturnType<typeof useSearchNewFamilyMembersQuery>;
export type SearchNewFamilyMembersLazyQueryHookResult = ReturnType<typeof useSearchNewFamilyMembersLazyQuery>;
export type SearchNewFamilyMembersSuspenseQueryHookResult = ReturnType<typeof useSearchNewFamilyMembersSuspenseQuery>;
export type SearchNewFamilyMembersQueryResult = Apollo.QueryResult<Operation.SearchNewFamilyMembersQuery, Operation.SearchNewFamilyMembersQueryVariables>;
export const SearchNewContactsDocument = gql`
    query SearchNewContacts($attributes: UpcSearchInput, $first: Int, $after: String) {
  userPracticeConnections(after: $after, first: $first, attributes: $attributes) {
    edges {
      node {
        ...newContactConnection
      }
    }
  }
}
    ${NewContactConnectionFragmentDoc}`;

/**
 * __useSearchNewContactsQuery__
 *
 * To run a query within a React component, call `useSearchNewContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNewContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNewContactsQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchNewContactsQuery(baseOptions?: Apollo.QueryHookOptions<Operation.SearchNewContactsQuery, Operation.SearchNewContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.SearchNewContactsQuery, Operation.SearchNewContactsQueryVariables>(SearchNewContactsDocument, options);
      }
export function useSearchNewContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.SearchNewContactsQuery, Operation.SearchNewContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.SearchNewContactsQuery, Operation.SearchNewContactsQueryVariables>(SearchNewContactsDocument, options);
        }
export function useSearchNewContactsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.SearchNewContactsQuery, Operation.SearchNewContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.SearchNewContactsQuery, Operation.SearchNewContactsQueryVariables>(SearchNewContactsDocument, options);
        }
export type SearchNewContactsQueryHookResult = ReturnType<typeof useSearchNewContactsQuery>;
export type SearchNewContactsLazyQueryHookResult = ReturnType<typeof useSearchNewContactsLazyQuery>;
export type SearchNewContactsSuspenseQueryHookResult = ReturnType<typeof useSearchNewContactsSuspenseQuery>;
export type SearchNewContactsQueryResult = Apollo.QueryResult<Operation.SearchNewContactsQuery, Operation.SearchNewContactsQueryVariables>;
export const VersionHistoryDocument = gql`
    query VersionHistory($attributes: VersionSearchInput) {
  versions(attributes: $attributes) {
    ...versionHistory
  }
}
    ${VersionHistoryFragmentDoc}`;

/**
 * __useVersionHistoryQuery__
 *
 * To run a query within a React component, call `useVersionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionHistoryQuery({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useVersionHistoryQuery(baseOptions?: Apollo.QueryHookOptions<Operation.VersionHistoryQuery, Operation.VersionHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.VersionHistoryQuery, Operation.VersionHistoryQueryVariables>(VersionHistoryDocument, options);
      }
export function useVersionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.VersionHistoryQuery, Operation.VersionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.VersionHistoryQuery, Operation.VersionHistoryQueryVariables>(VersionHistoryDocument, options);
        }
export function useVersionHistorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.VersionHistoryQuery, Operation.VersionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.VersionHistoryQuery, Operation.VersionHistoryQueryVariables>(VersionHistoryDocument, options);
        }
export type VersionHistoryQueryHookResult = ReturnType<typeof useVersionHistoryQuery>;
export type VersionHistoryLazyQueryHookResult = ReturnType<typeof useVersionHistoryLazyQuery>;
export type VersionHistorySuspenseQueryHookResult = ReturnType<typeof useVersionHistorySuspenseQuery>;
export type VersionHistoryQueryResult = Apollo.QueryResult<Operation.VersionHistoryQuery, Operation.VersionHistoryQueryVariables>;
export const TasksDocument = gql`
    query Tasks($byState: [TaskState!]!, $taskAssigneeIds: [ID!], $showRecallTasks: Boolean, $after: String, $limit: Int) {
  tasks(
    byState: $byState
    taskAssigneeIds: $taskAssigneeIds
    showRecallTasks: $showRecallTasks
    after: $after
    first: $limit
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...taskEssentials
      }
    }
  }
}
    ${TaskEssentialsFragmentDoc}`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      byState: // value for 'byState'
 *      taskAssigneeIds: // value for 'taskAssigneeIds'
 *      showRecallTasks: // value for 'showRecallTasks'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTasksQuery(baseOptions: Apollo.QueryHookOptions<Operation.TasksQuery, Operation.TasksQueryVariables> & ({ variables: Operation.TasksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TasksQuery, Operation.TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TasksQuery, Operation.TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TasksQuery, Operation.TasksQueryVariables>(TasksDocument, options);
        }
export function useTasksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TasksQuery, Operation.TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TasksQuery, Operation.TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksSuspenseQueryHookResult = ReturnType<typeof useTasksSuspenseQuery>;
export type TasksQueryResult = Apollo.QueryResult<Operation.TasksQuery, Operation.TasksQueryVariables>;
export const TasksCountDocument = gql`
    query TasksCount($byState: [TaskState!]!, $taskAssigneeIds: [ID!], $showRecallTasks: Boolean, $after: String, $limit: Int) {
  tasks(
    byState: $byState
    taskAssigneeIds: $taskAssigneeIds
    showRecallTasks: $showRecallTasks
    after: $after
    first: $limit
  ) {
    totalCount
  }
}
    `;

/**
 * __useTasksCountQuery__
 *
 * To run a query within a React component, call `useTasksCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksCountQuery({
 *   variables: {
 *      byState: // value for 'byState'
 *      taskAssigneeIds: // value for 'taskAssigneeIds'
 *      showRecallTasks: // value for 'showRecallTasks'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTasksCountQuery(baseOptions: Apollo.QueryHookOptions<Operation.TasksCountQuery, Operation.TasksCountQueryVariables> & ({ variables: Operation.TasksCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TasksCountQuery, Operation.TasksCountQueryVariables>(TasksCountDocument, options);
      }
export function useTasksCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TasksCountQuery, Operation.TasksCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TasksCountQuery, Operation.TasksCountQueryVariables>(TasksCountDocument, options);
        }
export function useTasksCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TasksCountQuery, Operation.TasksCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TasksCountQuery, Operation.TasksCountQueryVariables>(TasksCountDocument, options);
        }
export type TasksCountQueryHookResult = ReturnType<typeof useTasksCountQuery>;
export type TasksCountLazyQueryHookResult = ReturnType<typeof useTasksCountLazyQuery>;
export type TasksCountSuspenseQueryHookResult = ReturnType<typeof useTasksCountSuspenseQuery>;
export type TasksCountQueryResult = Apollo.QueryResult<Operation.TasksCountQuery, Operation.TasksCountQueryVariables>;
export const TaskEssentialsDocument = gql`
    query TaskEssentials($id: ID!) {
  task(id: $id) {
    ...taskEssentials
  }
}
    ${TaskEssentialsFragmentDoc}`;

/**
 * __useTaskEssentialsQuery__
 *
 * To run a query within a React component, call `useTaskEssentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskEssentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskEssentialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskEssentialsQuery(baseOptions: Apollo.QueryHookOptions<Operation.TaskEssentialsQuery, Operation.TaskEssentialsQueryVariables> & ({ variables: Operation.TaskEssentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.TaskEssentialsQuery, Operation.TaskEssentialsQueryVariables>(TaskEssentialsDocument, options);
      }
export function useTaskEssentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.TaskEssentialsQuery, Operation.TaskEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.TaskEssentialsQuery, Operation.TaskEssentialsQueryVariables>(TaskEssentialsDocument, options);
        }
export function useTaskEssentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.TaskEssentialsQuery, Operation.TaskEssentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.TaskEssentialsQuery, Operation.TaskEssentialsQueryVariables>(TaskEssentialsDocument, options);
        }
export type TaskEssentialsQueryHookResult = ReturnType<typeof useTaskEssentialsQuery>;
export type TaskEssentialsLazyQueryHookResult = ReturnType<typeof useTaskEssentialsLazyQuery>;
export type TaskEssentialsSuspenseQueryHookResult = ReturnType<typeof useTaskEssentialsSuspenseQuery>;
export type TaskEssentialsQueryResult = Apollo.QueryResult<Operation.TaskEssentialsQuery, Operation.TaskEssentialsQueryVariables>;
export const WorkflowDocument = gql`
    query Workflow($id: ID!) {
  workflow(id: $id) {
    ...workflowDetailed
  }
}
    ${WorkflowDetailedFragmentDoc}`;

/**
 * __useWorkflowQuery__
 *
 * To run a query within a React component, call `useWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkflowQuery(baseOptions: Apollo.QueryHookOptions<Operation.WorkflowQuery, Operation.WorkflowQueryVariables> & ({ variables: Operation.WorkflowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.WorkflowQuery, Operation.WorkflowQueryVariables>(WorkflowDocument, options);
      }
export function useWorkflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.WorkflowQuery, Operation.WorkflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.WorkflowQuery, Operation.WorkflowQueryVariables>(WorkflowDocument, options);
        }
export function useWorkflowSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.WorkflowQuery, Operation.WorkflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.WorkflowQuery, Operation.WorkflowQueryVariables>(WorkflowDocument, options);
        }
export type WorkflowQueryHookResult = ReturnType<typeof useWorkflowQuery>;
export type WorkflowLazyQueryHookResult = ReturnType<typeof useWorkflowLazyQuery>;
export type WorkflowSuspenseQueryHookResult = ReturnType<typeof useWorkflowSuspenseQuery>;
export type WorkflowQueryResult = Apollo.QueryResult<Operation.WorkflowQuery, Operation.WorkflowQueryVariables>;
export const WorkflowsDocument = gql`
    query Workflows($byState: [WorkflowState!]!, $byType: WorkflowType!, $after: String, $limit: Int) {
  workflows(byState: $byState, byType: $byType, after: $after, first: $limit) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
    edges {
      cursor
      node {
        ...workflowDetailed
      }
    }
  }
}
    ${WorkflowDetailedFragmentDoc}`;

/**
 * __useWorkflowsQuery__
 *
 * To run a query within a React component, call `useWorkflowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowsQuery({
 *   variables: {
 *      byState: // value for 'byState'
 *      byType: // value for 'byType'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useWorkflowsQuery(baseOptions: Apollo.QueryHookOptions<Operation.WorkflowsQuery, Operation.WorkflowsQueryVariables> & ({ variables: Operation.WorkflowsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Operation.WorkflowsQuery, Operation.WorkflowsQueryVariables>(WorkflowsDocument, options);
      }
export function useWorkflowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Operation.WorkflowsQuery, Operation.WorkflowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Operation.WorkflowsQuery, Operation.WorkflowsQueryVariables>(WorkflowsDocument, options);
        }
export function useWorkflowsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Operation.WorkflowsQuery, Operation.WorkflowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Operation.WorkflowsQuery, Operation.WorkflowsQueryVariables>(WorkflowsDocument, options);
        }
export type WorkflowsQueryHookResult = ReturnType<typeof useWorkflowsQuery>;
export type WorkflowsLazyQueryHookResult = ReturnType<typeof useWorkflowsLazyQuery>;
export type WorkflowsSuspenseQueryHookResult = ReturnType<typeof useWorkflowsSuspenseQuery>;
export type WorkflowsQueryResult = Apollo.QueryResult<Operation.WorkflowsQuery, Operation.WorkflowsQueryVariables>;
export const AccountPlanUpdatedDocument = gql`
    subscription AccountPlanUpdated {
  accountPlanUpdated {
    accountPlan {
      ...accountPlanDetailed
    }
  }
}
    ${AccountPlanDetailedFragmentDoc}`;

/**
 * __useAccountPlanUpdatedSubscription__
 *
 * To run a query within a React component, call `useAccountPlanUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAccountPlanUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPlanUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAccountPlanUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.AccountPlanUpdatedSubscription, Operation.AccountPlanUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.AccountPlanUpdatedSubscription, Operation.AccountPlanUpdatedSubscriptionVariables>(AccountPlanUpdatedDocument, options);
      }
export type AccountPlanUpdatedSubscriptionHookResult = ReturnType<typeof useAccountPlanUpdatedSubscription>;
export type AccountPlanUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.AccountPlanUpdatedSubscription>;
export const SessionUpdatedDocument = gql`
    subscription SessionUpdated {
  sessionUpdated {
    session {
      id
      practiceConnection {
        guid
      }
      accessRestriction {
        id
      }
    }
  }
}
    `;

/**
 * __useSessionUpdatedSubscription__
 *
 * To run a query within a React component, call `useSessionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSessionUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.SessionUpdatedSubscription, Operation.SessionUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.SessionUpdatedSubscription, Operation.SessionUpdatedSubscriptionVariables>(SessionUpdatedDocument, options);
      }
export type SessionUpdatedSubscriptionHookResult = ReturnType<typeof useSessionUpdatedSubscription>;
export type SessionUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.SessionUpdatedSubscription>;
export const HealthDocument = gql`
    subscription Health {
  health {
    status {
      commit
      buildTime
      bundleVersion
      version
      ready
      migrated
    }
  }
}
    `;

/**
 * __useHealthSubscription__
 *
 * To run a query within a React component, call `useHealthSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHealthSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthSubscription({
 *   variables: {
 *   },
 * });
 */
export function useHealthSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.HealthSubscription, Operation.HealthSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.HealthSubscription, Operation.HealthSubscriptionVariables>(HealthDocument, options);
      }
export type HealthSubscriptionHookResult = ReturnType<typeof useHealthSubscription>;
export type HealthSubscriptionResult = Apollo.SubscriptionResult<Operation.HealthSubscription>;
export const CalendarEventDetailedUpdatedDocument = gql`
    subscription CalendarEventDetailedUpdated {
  appointmentUpdated {
    appointment {
      ...appointmentCalendarEventDetailed
    }
  }
}
    ${AppointmentCalendarEventDetailedFragmentDoc}`;

/**
 * __useCalendarEventDetailedUpdatedSubscription__
 *
 * To run a query within a React component, call `useCalendarEventDetailedUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventDetailedUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventDetailedUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCalendarEventDetailedUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.CalendarEventDetailedUpdatedSubscription, Operation.CalendarEventDetailedUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.CalendarEventDetailedUpdatedSubscription, Operation.CalendarEventDetailedUpdatedSubscriptionVariables>(CalendarEventDetailedUpdatedDocument, options);
      }
export type CalendarEventDetailedUpdatedSubscriptionHookResult = ReturnType<typeof useCalendarEventDetailedUpdatedSubscription>;
export type CalendarEventDetailedUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.CalendarEventDetailedUpdatedSubscription>;
export const AppointmentUpdatedDocument = gql`
    subscription AppointmentUpdated {
  appointmentUpdated {
    appointment {
      ...appointmentExhaustive
    }
  }
}
    ${AppointmentExhaustiveFragmentDoc}`;

/**
 * __useAppointmentUpdatedSubscription__
 *
 * To run a query within a React component, call `useAppointmentUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAppointmentUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.AppointmentUpdatedSubscription, Operation.AppointmentUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.AppointmentUpdatedSubscription, Operation.AppointmentUpdatedSubscriptionVariables>(AppointmentUpdatedDocument, options);
      }
export type AppointmentUpdatedSubscriptionHookResult = ReturnType<typeof useAppointmentUpdatedSubscription>;
export type AppointmentUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.AppointmentUpdatedSubscription>;
export const CalendarEventUpdatedDocument = gql`
    subscription CalendarEventUpdated {
  appointmentUpdated {
    appointment {
      ...appointmentCalendarEvent
    }
  }
}
    ${AppointmentCalendarEventFragmentDoc}`;

/**
 * __useCalendarEventUpdatedSubscription__
 *
 * To run a query within a React component, call `useCalendarEventUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCalendarEventUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.CalendarEventUpdatedSubscription, Operation.CalendarEventUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.CalendarEventUpdatedSubscription, Operation.CalendarEventUpdatedSubscriptionVariables>(CalendarEventUpdatedDocument, options);
      }
export type CalendarEventUpdatedSubscriptionHookResult = ReturnType<typeof useCalendarEventUpdatedSubscription>;
export type CalendarEventUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.CalendarEventUpdatedSubscription>;
export const AttachmentMinimalUpdatedDocument = gql`
    subscription AttachmentMinimalUpdated {
  attachmentUpdated {
    attachment {
      ...attachmentMinimal
    }
  }
}
    ${AttachmentMinimalFragmentDoc}`;

/**
 * __useAttachmentMinimalUpdatedSubscription__
 *
 * To run a query within a React component, call `useAttachmentMinimalUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentMinimalUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentMinimalUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAttachmentMinimalUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.AttachmentMinimalUpdatedSubscription, Operation.AttachmentMinimalUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.AttachmentMinimalUpdatedSubscription, Operation.AttachmentMinimalUpdatedSubscriptionVariables>(AttachmentMinimalUpdatedDocument, options);
      }
export type AttachmentMinimalUpdatedSubscriptionHookResult = ReturnType<typeof useAttachmentMinimalUpdatedSubscription>;
export type AttachmentMinimalUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.AttachmentMinimalUpdatedSubscription>;
export const AttachmentEssentialsUpdatedDocument = gql`
    subscription AttachmentEssentialsUpdated {
  attachmentUpdated {
    attachment {
      ...attachmentEssentials
    }
  }
}
    ${AttachmentEssentialsFragmentDoc}`;

/**
 * __useAttachmentEssentialsUpdatedSubscription__
 *
 * To run a query within a React component, call `useAttachmentEssentialsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentEssentialsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentEssentialsUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAttachmentEssentialsUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.AttachmentEssentialsUpdatedSubscription, Operation.AttachmentEssentialsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.AttachmentEssentialsUpdatedSubscription, Operation.AttachmentEssentialsUpdatedSubscriptionVariables>(AttachmentEssentialsUpdatedDocument, options);
      }
export type AttachmentEssentialsUpdatedSubscriptionHookResult = ReturnType<typeof useAttachmentEssentialsUpdatedSubscription>;
export type AttachmentEssentialsUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.AttachmentEssentialsUpdatedSubscription>;
export const DirectoryUpdatedDocument = gql`
    subscription DirectoryUpdated($id: ID!) {
  directoryUpdated(id: $id) {
    directory {
      ...directoryInfo
    }
  }
}
    ${DirectoryInfoFragmentDoc}`;

/**
 * __useDirectoryUpdatedSubscription__
 *
 * To run a query within a React component, call `useDirectoryUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDirectoryUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectoryUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDirectoryUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<Operation.DirectoryUpdatedSubscription, Operation.DirectoryUpdatedSubscriptionVariables> & ({ variables: Operation.DirectoryUpdatedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.DirectoryUpdatedSubscription, Operation.DirectoryUpdatedSubscriptionVariables>(DirectoryUpdatedDocument, options);
      }
export type DirectoryUpdatedSubscriptionHookResult = ReturnType<typeof useDirectoryUpdatedSubscription>;
export type DirectoryUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.DirectoryUpdatedSubscription>;
export const EmailUpdatedDocument = gql`
    subscription EmailUpdated {
  emailUpdated {
    email {
      ...emailEssentials
    }
  }
}
    ${EmailEssentialsFragmentDoc}`;

/**
 * __useEmailUpdatedSubscription__
 *
 * To run a query within a React component, call `useEmailUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEmailUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useEmailUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.EmailUpdatedSubscription, Operation.EmailUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.EmailUpdatedSubscription, Operation.EmailUpdatedSubscriptionVariables>(EmailUpdatedDocument, options);
      }
export type EmailUpdatedSubscriptionHookResult = ReturnType<typeof useEmailUpdatedSubscription>;
export type EmailUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.EmailUpdatedSubscription>;
export const InvoiceUpdatedDocument = gql`
    subscription InvoiceUpdated($guid: ID) {
  invoiceUpdated(guid: $guid) {
    invoice {
      ...invoiceDetailed
    }
  }
}
    ${InvoiceDetailedFragmentDoc}`;

/**
 * __useInvoiceUpdatedSubscription__
 *
 * To run a query within a React component, call `useInvoiceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceUpdatedSubscription({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useInvoiceUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.InvoiceUpdatedSubscription, Operation.InvoiceUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.InvoiceUpdatedSubscription, Operation.InvoiceUpdatedSubscriptionVariables>(InvoiceUpdatedDocument, options);
      }
export type InvoiceUpdatedSubscriptionHookResult = ReturnType<typeof useInvoiceUpdatedSubscription>;
export type InvoiceUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.InvoiceUpdatedSubscription>;
export const InvoiceTransactionsUpdatedDocument = gql`
    subscription InvoiceTransactionsUpdated($guid: ID) {
  invoiceTransactionsUpdated(guid: $guid) {
    invoice {
      guid
    }
  }
}
    `;

/**
 * __useInvoiceTransactionsUpdatedSubscription__
 *
 * To run a query within a React component, call `useInvoiceTransactionsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceTransactionsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceTransactionsUpdatedSubscription({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useInvoiceTransactionsUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.InvoiceTransactionsUpdatedSubscription, Operation.InvoiceTransactionsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.InvoiceTransactionsUpdatedSubscription, Operation.InvoiceTransactionsUpdatedSubscriptionVariables>(InvoiceTransactionsUpdatedDocument, options);
      }
export type InvoiceTransactionsUpdatedSubscriptionHookResult = ReturnType<typeof useInvoiceTransactionsUpdatedSubscription>;
export type InvoiceTransactionsUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.InvoiceTransactionsUpdatedSubscription>;
export const MessageUpdatedDocument = gql`
    subscription MessageUpdated {
  messageUpdated {
    sms {
      ...smsEssentials
    }
  }
}
    ${SmsEssentialsFragmentDoc}`;

/**
 * __useMessageUpdatedSubscription__
 *
 * To run a query within a React component, call `useMessageUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMessageUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.MessageUpdatedSubscription, Operation.MessageUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.MessageUpdatedSubscription, Operation.MessageUpdatedSubscriptionVariables>(MessageUpdatedDocument, options);
      }
export type MessageUpdatedSubscriptionHookResult = ReturnType<typeof useMessageUpdatedSubscription>;
export type MessageUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.MessageUpdatedSubscription>;
export const UserUpdatedDocument = gql`
    subscription UserUpdated($guid: ID!) {
  userUpdated(guid: $guid) {
    userPracticeConnection {
      ...userPracticeConnectionDetailed
    }
  }
}
    ${UserPracticeConnectionDetailedFragmentDoc}`;

/**
 * __useUserUpdatedSubscription__
 *
 * To run a query within a React component, call `useUserUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUpdatedSubscription({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useUserUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<Operation.UserUpdatedSubscription, Operation.UserUpdatedSubscriptionVariables> & ({ variables: Operation.UserUpdatedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.UserUpdatedSubscription, Operation.UserUpdatedSubscriptionVariables>(UserUpdatedDocument, options);
      }
export type UserUpdatedSubscriptionHookResult = ReturnType<typeof useUserUpdatedSubscription>;
export type UserUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.UserUpdatedSubscription>;
export const UserUpdatedMinimalDocument = gql`
    subscription UserUpdatedMinimal($guid: ID!) {
  userUpdated(guid: $guid) {
    userPracticeConnection {
      ...userPracticeConnectionMinimal
    }
  }
}
    ${UserPracticeConnectionMinimalFragmentDoc}`;

/**
 * __useUserUpdatedMinimalSubscription__
 *
 * To run a query within a React component, call `useUserUpdatedMinimalSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatedMinimalSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUpdatedMinimalSubscription({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useUserUpdatedMinimalSubscription(baseOptions: Apollo.SubscriptionHookOptions<Operation.UserUpdatedMinimalSubscription, Operation.UserUpdatedMinimalSubscriptionVariables> & ({ variables: Operation.UserUpdatedMinimalSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.UserUpdatedMinimalSubscription, Operation.UserUpdatedMinimalSubscriptionVariables>(UserUpdatedMinimalDocument, options);
      }
export type UserUpdatedMinimalSubscriptionHookResult = ReturnType<typeof useUserUpdatedMinimalSubscription>;
export type UserUpdatedMinimalSubscriptionResult = Apollo.SubscriptionResult<Operation.UserUpdatedMinimalSubscription>;
export const AvatarUpdatedDocument = gql`
    subscription AvatarUpdated {
  avatarUpdated {
    avatar {
      ...avatar
    }
  }
}
    ${AvatarFragmentDoc}`;

/**
 * __useAvatarUpdatedSubscription__
 *
 * To run a query within a React component, call `useAvatarUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAvatarUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvatarUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAvatarUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.AvatarUpdatedSubscription, Operation.AvatarUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.AvatarUpdatedSubscription, Operation.AvatarUpdatedSubscriptionVariables>(AvatarUpdatedDocument, options);
      }
export type AvatarUpdatedSubscriptionHookResult = ReturnType<typeof useAvatarUpdatedSubscription>;
export type AvatarUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.AvatarUpdatedSubscription>;
export const WaitlistUpdatedDocument = gql`
    subscription WaitlistUpdated {
  waitlistUpdated {
    waitlist {
      ...waitlistDetailed
    }
  }
}
    ${WaitlistDetailedFragmentDoc}`;

/**
 * __useWaitlistUpdatedSubscription__
 *
 * To run a query within a React component, call `useWaitlistUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWaitlistUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitlistUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWaitlistUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Operation.WaitlistUpdatedSubscription, Operation.WaitlistUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Operation.WaitlistUpdatedSubscription, Operation.WaitlistUpdatedSubscriptionVariables>(WaitlistUpdatedDocument, options);
      }
export type WaitlistUpdatedSubscriptionHookResult = ReturnType<typeof useWaitlistUpdatedSubscription>;
export type WaitlistUpdatedSubscriptionResult = Apollo.SubscriptionResult<Operation.WaitlistUpdatedSubscription>;